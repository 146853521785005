/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import WysiwygEditor from '../WysiwygEditor';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../Reports.redux';
import { useParams } from 'react-router-dom';
import TemplateReplacementModal from '../TemplateReplacementModal';
import useNavigationWarning from "../../../hooks/useNavigationWarning";
import api from "../../../api";
import { Button, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';



const InputMarginWrapper = styled('div')({
    marginTop: 16,
});

const EditContentBlock = () => {
    const dispatch           = useDispatch(),
          { contentBlockID } = useParams(),
          contentBlock       = useSelector(state => state.reports.contentBlock),
          editorContent      = useSelector(state => state.reports.editorContent),
          templates          = useSelector(state => state.reports.editorTemplates),
          autofill           = useSelector(state => state.reports.autofill),
          [
              saving,
              setSaving
          ]                  = useState(false),
          [
              Prompt,
              setDirty,
              setClean
          ]                  = useNavigationWarning();

    const handleChange = (newValue, editor) => {
        setDirty();
        dispatch(actions.setState({ editorContent: newValue }));
    };

    const handleChangeTitle = (e) => {
        setDirty();
        dispatch(actions.setSubState('contentBlock', { string_name: e.target.value }));
    };


    const handleChangeAutofillShortcut = (e) => {
        setDirty();
        dispatch(actions.setSubState('contentBlock', { autofill_shortcut: e.target.value.replace(/\W/g, "") }));
    };


    const handleSave = async () => {
        setSaving(true);
        try {
            await api.put('/reports/content-blocks', {
                string_id: contentBlock.string_id,
                string_content: editorContent,
                string_name: contentBlock.string_name,
                autofill_shortcut: contentBlock.autofill_shortcut,
            });

            setClean();
        } catch (err) {
            console.error(err);
        }

        setSaving(false);
    };


    // Load template on initial load
    useEffect(() => {
        dispatch(actions.load_content_block(contentBlockID));
    }, [ dispatch, contentBlockID ]);

    // Reset state when leaving the page
    useEffect(() => {
        // do nothing
        return () => dispatch(actions.resetAll());
    }, [ dispatch ]);

    // TinyMCE isn't great at updating with new props.
    // Don't render the editor until we've fetched everything.
    if(contentBlock.string_content === undefined) {
        return null;
    }



    return (
        <div>
            <div>
                <TextField
                    label="Content Block Title"
                    value={contentBlock.string_name ?? ''}
                    onChange={handleChangeTitle}
                />
            </div>

            <InputMarginWrapper>
                <TextField
                    label="Autofill Shortcut"
                    value={contentBlock.autofill_shortcut ?? ''}
                    onChange={handleChangeAutofillShortcut}
                />
            </InputMarginWrapper>

            <WysiwygEditor
                value={editorContent}
                onChange={handleChange}
                templates={templates}
                showReplacementButton={true}
                autofill={autofill}
            />

            <TemplateReplacementModal />

            {Prompt}

            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={saving}
            >
                Save
            </Button>
        </div>
    );
};


export default EditContentBlock;
