import React, { useEffect, useState } from 'react';
import WysiwygEditor from '../WysiwygEditor';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../Reports.redux';
import { useParams } from 'react-router-dom';
import TemplateReplacementModal from '../TemplateReplacementModal';
import useNavigationWarning from "../../../hooks/useNavigationWarning";
import api from "../../../api";
import { Button, TextField } from "@mui/material";



const EditTemplate = () => {
    const dispatch       = useDispatch(),
          { templateID } = useParams(),
          template       = useSelector(state => state.reports.template),
          editorContent  = useSelector(state => state.reports.editorContent),
          templates      = useSelector(state => state.reports.editorTemplates),
          autofill       = useSelector(state => state.reports.autofill),
          [
              saving,
              setSaving
          ]              = useState(false),
          [
              Prompt,
              setDirty,
              setClean
          ]              = useNavigationWarning();

    const handleChange = (newValue, editor) => {
        setDirty();
        dispatch(actions.setState({ editorContent: newValue }));
    };


    const handleChangeTitle = (e) => {
        setDirty();
        dispatch(actions.setSubState('template', { template_name: e.target.value }));
    };


    const handleSave = async () => {
        setSaving(true);
        try {
            await api.put('/reports/templates', {
                template_id: template.template_id,
                template_config: {
                    main: editorContent,
                },
                template_name: template.template_name,
            });

            setClean();
        } catch (err) {
            console.error(err);
        }

        setSaving(false);
    };


    // Load template on initial load
    useEffect(() => {
        dispatch(actions.load_template(templateID));
    }, [ dispatch, templateID ]);

    // Reset state when leaving the page
    useEffect(() => {
        // do nothing
        return () => dispatch(actions.resetAll());
    }, [ dispatch ]);

    // TinyMCE isn't great at updating with new props.
    // Don't render the editor until we've fetched everything.
    if(!template.template_config) {
        return null;
    }



    return (
        <div>
            <div>
                <TextField
                    label="Layout Title"
                    value={template.template_name ?? ''}
                    onChange={handleChangeTitle}
                />
            </div>

            <WysiwygEditor
                value={editorContent}
                onChange={handleChange}
                templates={templates}
                showReplacementButton={true}
                autofill={autofill}
            />

            <TemplateReplacementModal />

            {Prompt}

            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={saving}
            >
                Save
            </Button>
        </div>
    );
};


export default EditTemplate;
