import api from 'api';



const baseType = 'CLIENTVIEW';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	RESET_ALL: `${baseType}/RESET_ALL`,
	SET_PROFILE_VALUE: `${baseType}/SET_PROFILE_VALUE`,
	CANCEL_EDIT: `${baseType}/CANCEL_EDIT`,
};

const initialState = {
	tab: 'patients',
	displayModal: null,

	profile: {
		client_name: '',
		last_visit: '',
		client_external_id: '',
		primary_vet: '',
		primary_clinic: '',
	},
	profileOriginal: {
		client_name: '',
		last_visit: '',
		client_external_id: '',
		primary_vet: '',
		primary_clinic: '',
	},
	profileLoading: false,
	profileLoaded: false,
	profileSaving: false,
	profileEditing: false,
	profileErrors: [],

	contactMethods: [],
	contactMethodsLoading: false,
	contactMethodsPreviouslyLoaded: false,
	contactMethodsEditData: {
		cm_id: null,
		cm_type: '',
		cm_name: '',
		cm_note: '',
		cm_value: '',
	},
	contactMethodsEditLoading: false,
	contactMethodsEditErrors: [],

	addresses: [],
	addressesLoading: false,
	addressesPreviouslyLoaded: false,
	addressesEditData: {
		address_id: null,
		address_name: '',
		address_line_1: '',
		address_line_2: '',
		address_city: '',
		address_state: '',
		address_postal_code: '',
		address_country: '',
	},
	addressEditLoading: false,
	addressEditErrors: [],

	patients: [],
	patientsLoading: false,
	patientsPreviouslyLoaded: false,
	patientsEditData: {
		patient_name: '',
		gender: '',
		species: '',
		breed: '',
		date_of_birth: '',
		primary_vet: '',
		primary_clinic: '',
		is_neutered: '',
	},

	newPatientLoading: false,
	newPatientErrors: [],

	reports: [],
	reportsLoading: false,
	reportsPreviouslyLoaded: false,

	charts: [],
	chartsLoading: false,
	chartsPreviouslyLoaded: false,

	errors: [],

};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };

		case types.SET_PROFILE_VALUE:
			return {
				...state,
				profile: {
					...state.profile,
					[ action.key ]: action.value,
				},
			};

		case types.RESET_ALL:
			return { ...initialState };

		case types.CANCEL_EDIT:
			return { ...state, profile: { ...state.profileOriginal }, profileEditing: false };

		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	resetAll: () => ({ type: types.RESET_ALL }),


	cancelEdit: () => ({ type: types.CANCEL_EDIT }),


	setProfileValue: (key, value) => ({ type: types.SET_PROFILE_VALUE, key, value }),


	loadProfile: (clientID) => async (dispatch, getState) => {
		dispatch(actions.setState({
			profileLoading: true,
		}));

		try {
			let results = await api.get(`/clients/profile/${clientID}`);
			dispatch(actions.setState({
				profileLoading: false,
				profileLoaded: true,
				profile: { ...initialState.profile, ...results.data.profile },
				profileOriginal: { ...initialState.profile, ...results.data.profile },
			}));
		} catch(err) {
			dispatch(actions.setState({
				loading: false,
				profileLoaded: true,
				profileErrors: [ 'There was an error loading the requested client.' ],
			}));
		}
	},


	saveProfile: (clientID) => async (dispatch, getState) => {
		dispatch(actions.setState({
			profileSaving: true,
		}));

		try {
			let results = await api.put(`/clients/profile/${clientID}`, { ...getState().clientView.profile });
			dispatch(actions.setState({
				profileSaving: false,
				profileEditing: false,
				profile: { ...getState().clientView.profile, ...results.data.profile },
				profileOriginal: { ...getState().clientView.profile, ...results.data.profile },
			}));
		} catch(err) {
			dispatch(actions.setState({
				profileSaving: false,
				profileErrors: [ err.response.data.errors || [ 'An error occurred while saving the client profile' ] ],
			}));
		}
	},


	loadReports: (clientID) => async (dispatch, getState) => {
		dispatch(actions.setState({
			reportsLoading: true,
			reports: [],
		}));

		try {
			let results = await api.get(`/clients/reports/${clientID}`);
			dispatch(actions.setState({
				reports: results.data.reports,
				reportsPreviouslyLoaded: true,
				reportsLoading: false,
			}));
		} catch(err) {
			dispatch(actions.setState({
				reports: [],
				reportsPreviouslyLoaded: true,
				reportsLoading: false,
			}));
		}
	},


	loadPatients: (clientID) => async (dispatch, getState) => {
		dispatch(actions.setState({
			patientsLoading: true,
			patients: [],
		}));

		try {
			let results = await api.get(`/clients/patients/${clientID}`);
			dispatch(actions.setState({
				patients: results.data.patients,
				patientsPreviouslyLoaded: true,
				patientsLoading: false,
			}));
		} catch(err) {
			dispatch(actions.setState({
				patients: [],
				patientsPreviouslyLoaded: true,
				patientsLoading: false,
			}));
		}
	},


	loadCharts: (clientID) => async (dispatch, getState) => {
		dispatch(actions.setState({
			chartsLoading: true,
			charts: [],
		}));

		try {
			let results = await api.get(`/clients/charts/${clientID}`);
			dispatch(actions.setState({
				charts: results.data.charts,
				chartsPreviouslyLoaded: true,
				chartsLoading: false,
			}));
		} catch(err) {
			dispatch(actions.setState({
				charts: [],
				chartsPreviouslyLoaded: true,
				chartsLoading: false,
			}));
		}
	},


	loadContactMethods: (clientID) => async (dispatch, getState) => {
		dispatch(actions.setState({
			contactMethodsLoading: true,
			contactMethods: [],
		}));

		try {
			let results = await api.get(`/clients/contact-methods/${clientID}`);
			dispatch(actions.setState({
				contactMethods: results.data.contact_methods,
				contactMethodsPreviouslyLoaded: true,
				contactMethodsLoading: false,
			}));
		} catch(err) {
			dispatch(actions.setState({
				contactMethods: [],
				contactMethodsPreviouslyLoaded: true,
				contactMethodsLoading: false,
			}));
		}
	},



	loadAddresses: (clientID) => async (dispatch, getState) => {
		dispatch(actions.setState({
			addressesLoading: true,
			addresses: [],
		}));

		try {
			let results = await api.get(`/clients/addresses/${clientID}`);
			dispatch(actions.setState({
				addresses: results.data.addresses,
				addressesPreviouslyLoaded: true,
				addressesLoading: false,
			}));
		} catch(err) {
			dispatch(actions.setState({
				addresses: [],
				addressesPreviouslyLoaded: true,
				addressesLoading: false,
			}));
		}
	},



	closeModal: () => (dispatch) => {
		dispatch(actions.setState({
			displayModal: null,
			contactMethodsEditData: { ...initialState.contactMethodsEditData },
			addressesEditData: { ...initialState.addressesEditData },
			patientsEditData: { ...initialState.patientsEditData },
			contactMethodsEditErrors: [],
			addressEditErrors: [],
			newPatientErrors: [],
		}));
	},


	openContactMethodEditModal: (id) => (dispatch, getState) => {
		if(id === null) {
			return dispatch(actions.setState({
				displayModal: 'contactMethods',
				contactMethodsEditData: { ...initialState.contactMethodsEditData },
			}));
		}

		dispatch(actions.setState({
			displayModal: 'contactMethods',
			contactMethodsEditData: { ...getState().clientView.contactMethods[ id ] },
		}));
	},



	editContactMethodData: (key, value) => (dispatch, getState) => {
		dispatch(actions.setState({
			contactMethodsEditData: {
				...getState().clientView.contactMethodsEditData,
				[ key ]: value,
			},
		}));
	},



	openPatientsEditModal: () => (dispatch, getState) => {
		dispatch(actions.setState({
			displayModal: 'patients',
			patientsEditData: { ...initialState.patientsEditData },
		}));
	},



	editPatientsData: (key, value) => (dispatch, getState) => {
		dispatch(actions.setState({
			patientsEditData: {
				...getState().clientView.patientsEditData,
				[ key ]: value,
			},
		}));
	},



	openAddressEditModal: (id) => (dispatch, getState) => {
		if(id === null) {
			return dispatch(actions.setState({
				displayModal: 'addresses',
				addressesEditData: { ...initialState.addressesEditData },
			}));
		}

		dispatch(actions.setState({
			displayModal: 'addresses',
			addressesEditData: { ...getState().clientView.addresses[ id ] },
		}));
	},



	editAddressData: (key, value) => (dispatch, getState) => {
		dispatch(actions.setState({
			addressesEditData: {
				...getState().clientView.addressesEditData,
				[ key ]: value,
			},
		}));
	},



	submitNewPatient: (data) => async (dispatch, getState) => {
		dispatch(actions.setState({
			newPatientLoading: true,
			newPatientErrors: [],
		}));

		try {
			await api.post(`/patients`, data);
			//let patientID = results.data.patient_id;

			dispatch(actions.loadPatients(data.client_id));
			dispatch(actions.setState({
				newPatientLoading: false,
				newPatientErrors: [],
			}));
			dispatch(actions.closeModal());
		} catch(err) {
			console.error(err);
			dispatch(actions.setState({
				newPatientLoading: false,
				newPatientErrors: err.response.data.errors,
			}));
		}
	},



	submitAddress: (data) => async (dispatch, getState) => {
		dispatch(actions.setState({
			addressEditLoading: true,
			addressEditErrors: [],
		}));

		let method = data.address_id === null ? 'post' : 'put';

		try {
			await api[method](`/clients/addresses`, data);
			//let addressID = results.data.address_id;

			dispatch(actions.loadAddresses(data.client_id));
			dispatch(actions.setState({
				addressEditLoading: false,
				addressEditErrors: [],
			}));
			dispatch(actions.closeModal());
		} catch(err) {
			console.error(err);
			dispatch(actions.setState({
				addressEditLoading: false,
				addressEditErrors: err.response.data.errors,
			}));
		}
	},



	submitContactMethod: (data) => async (dispatch, getState) => {
		dispatch(actions.setState({
			contactMethodsEditLoading: true,
			contactMethodsEditErrors: [],
		}));

		let method = data.cm_id === null ? 'post' : 'put';

		try {
			await api[method](`/clients/contact-methods`, data);
			//let cmID = results.data.cm_id;

			dispatch(actions.loadContactMethods(data.client_id));
			dispatch(actions.setState({
				contactMethodsEditLoading: false,
				contactMethodsEditErrors: [],
			}));
			dispatch(actions.closeModal());
		} catch(err) {
			console.error(err);
			dispatch(actions.setState({
				contactMethodsEditLoading: false,
				contactMethodsEditErrors: err.response.data.errors,
			}));
		}
	},


};