module.exports = {
	"d501": {
		"paths": [
			"M266.8,99.8c1.4-5.8,8.6-6,9.6-5.7s8.9-0.1,11,5c2.2,5.2,4.1,2.8,0.2,5.5c-3.8,2.8,1.3,3.6-0.9,6.1      c-2.2,2.4-7.9,6-17.7,3.6c-3.8-9.2-6.1-7.5-6.5-8.8C262,104.2,262.9,99.2,266.8,99.8z",
			"M265.4,99.8c0,0,4.1,2.5,3.6,4.7c2.3-4.7-2.2-4.7-2.2-4.7H265.4z"
		]
	},
	"d502": {
		"paths": [
			"M227.7,130.3c-1.4-8,0-15.2,1-15.2c1,0.1,8.5,0.8,12.8,6.8s5.9,0.6,5.1,5.7c-0.6,3.9,2.2,5.8,1.5,9.6      c-0.8,3.8-6.6,6.8-10.1,5.9c-5.7-5.9-8.6-4.4-9.7-5.9C227.1,135.6,224,130.7,227.7,130.3z",
			"M227.2,130.3c0,0,3.6,1.5,3.5,3.3c1.2-4.1-2.4-3.5-2.4-3.5L227.2,130.3z"
		]
	},
	"d503": {
		"paths": [
			"M210.7,157c-4.9-5.7-14.9-13.7-17.7-12s7.8,25.5,6.8,32.5c-6.8,2.3-5.2,18.5,5.6,21.2      c10.8,2.6,18.1-5.7,16.6-22.5C221.9,174.8,215.6,162.7,210.7,157z"
		]
	},
	"d504": {
		"paths": [
			"M147,162c-3.3,1.5,25.5,47.5,29.5,56.7c3.9,9,9.5,37.3,22.3,34c8.9-2.3,0.3-34.5-10.1-52.2      C169.8,168.5,150.3,160.5,147,162z"
		]
	},
	"d506": {
		"paths": [
			"M199.7,420.5c-1.9,6.6-11.5,6.1-16.5,3c-5.7-4.2-2.7-11.3-5.9-14.9c-3.2-3.7-3.5-11.6-1.7-26.2      c2.4-9.9,4.9-15.2,7.7-19.7c3.2-4,5.6-5.2,10.8-3c4.1,1.7,6.9,15,7.5,17.2C202.1,379.1,202.5,410.9,199.7,420.5z",
			"M183.4,409l0.5,1.1l1.6,4c0,0,8.5,1.4,12.8-1.8C191.2,412.4,187.5,414,183.4,409z",
			"M195.8,374.6l-0.5-0.7l-1.5-2.3c0,0-5.6,0-7.8,2.4C190.6,373.3,192.7,371.9,195.8,374.6z"
		]
	},
	"d507": {
		"paths": [
			"M167.2,476.4c8-12.1,32.1-17.1,37.8,11.4c0,14-12.6,9.1-13.9,14.2c-1.3,5-7.4,27.1-9.6,34.7      c-3.6,13.1-0.7,27.2-7,31.4c-6.2,4.2-14,7.8-18.1,2.8s-7.4-7.5-6.6-17.2c-0.6-7.9,3.6-21.5,4.5-33.4      C155.2,500,163.5,481.5,167.2,476.4z",
			"M154.7,527l0.7,1.7l2.7,6.1c0,0,12.3,1.4,18.1-3.6C165.9,531.8,160.9,534.6,154.7,527z",
			"M183.2,492.4l-0.6-1l-2.2-3.6c0,0-7.9,0.1-11.2,3.7C175.7,490.3,178.7,488.1,183.2,492.4z"
		]
	},
	"d508": {
		"paths": [
			"M140.4,619c2.2-8.9,5.4-13.2,12.4-12.8c7,0.5,10.6,6,14.6,8c9.4,8.9,16.8,8.8,16.6,20.7      c-1.9,7.6-6.7,13.4-19.8,14c-11.5,0.5-5.9,4.3-16.8,4.4c-10,0-12.5-7.3-10.6-13.9c1.9-6.6,6.1-6.6,8.7-9.9      C145.5,623.8,139,625.2,140.4,619z",
			"M165,629.1c-0.1,0.1-2.8,3-1.8,6.6c-5.4-4.6-17.8-6.1-17.8-6.1l0.3-2.5C156.6,634.6,165,629.1,165,629.1z",
			"M174.9,625.2c-0.6,0.7-6.2,12.1,1.6,13c-3.4,0.5-5.1-1.8-5.1-1.8C170.2,633.8,172.3,627.8,174.9,625.2z"
		]
	},
	"d601": {
		"paths": [
			"M345.7,105.5c-0.4,1.3-2.7-0.4-6.5,8.8c-9.8,2.4-15.5-1.1-17.7-3.6c-2.2-2.4,2.9-3.3-0.9-6.1      c-3.9-2.7-2-0.3,0.2-5.5c2.1-5.1,10-4.7,11-5s8.2-0.1,9.6,5.7C345.3,99.2,346.2,104.2,345.7,105.5z",
			"M342.8,99.8c0,0-4.1,2.5-3.6,4.7c-2.3-4.7,2.2-4.7,2.2-4.7H342.8z"
		]
	},
	"d602": {
		"paths": [
			"M380,137.1c-1.1,1.5-4.1,0.1-9.7,5.9c-3.6,0.9-9.4-2.1-10.1-5.9c-0.8-3.8,2-5.6,1.5-9.6      c-0.8-5.1,0.9,0.3,5.1-5.7s11.8-6.7,12.8-6.8c1,0,2.4,7.2,1,15.2C384.2,130.7,381.1,135.6,380,137.1z",
			"M381,130.3c0,0-3.6,1.5-3.5,3.3c-1.2-4.1,2.4-3.5,2.4-3.5L381,130.3z"
		]
	},
	"d603": {
		"paths": [
			"M386.2,176.2c-1.5,16.8,5.8,25.1,16.6,22.5c10.8-2.7,12.4-19,5.6-21.2c-1-7,9.5-30.8,6.8-32.5      s-12.8,6.3-17.7,12C392.6,162.7,386.3,174.8,386.2,176.2z"
		]
	},
	"d604": {
		"paths": [
			"M419.5,200.5c-10.4,17.7-19,49.9-10.1,52.2c12.8,3.3,18.4-25,22.3-34c4-9.2,32.8-55.2,29.5-56.7      S438.4,168.5,419.5,200.5z"
		]
	},
	"d606": {
		"paths": [
			"M406.7,376.9c0.5-2.2,3.4-15.5,7.5-17.2c5.2-2.2,7.6-1,10.8,3c2.7,4.5,5.3,9.8,7.7,19.7      c1.8,14.6,1.5,22.5-1.7,26.2c-3.2,3.6-0.2,10.7-5.9,14.9c-5,3.1-14.7,3.6-16.5-3C405.7,410.9,406.1,379.1,406.7,376.9z",
			"M409.9,412.4c4.2,3.1,12.8,1.8,12.8,1.8l1.6-4l0.5-1.1C420.7,414,417,412.4,409.9,412.4z",
			"M422.2,374c-2.3-2.4-7.8-2.4-7.8-2.4l-1.5,2.3l-0.5,0.7C415.5,371.9,417.6,373.3,422.2,374z"
		]
	},
	"d607": {
		"paths": [
			"M453.7,520.1c0.9,12,5.1,25.5,4.5,33.4c0.9,9.7-2.4,12.2-6.6,17.2c-4.1,5-11.9,1.5-18.1-2.8      c-6.2-4.2-3.3-18.3-7-31.4c-2.1-7.5-8.3-29.6-9.6-34.7c-1.3-5-13.9-0.2-13.9-14.2c5.7-28.5,29.8-23.5,37.8-11.4      C444.5,481.5,452.9,500,453.7,520.1z",
			"M431.8,531.3c5.8,4.9,18.1,3.6,18.1,3.6l2.7-6.1l0.7-1.7C447.2,534.6,442.2,531.8,431.8,531.3z",
			"M438.9,491.5c-3.2-3.6-11.2-3.7-11.2-3.7l-2.2,3.6l-0.6,1C429.4,488.1,432.4,490.3,438.9,491.5z"
		]
	},
	"d608": {
		"paths": [
			"M462.8,629.5c2.5,3.3,6.8,3.3,8.7,9.9c1.9,6.6-0.6,13.9-10.6,13.9c-10.9-0.1-5.3-3.9-16.8-4.4      c-13.1-0.6-17.9-6.3-19.8-14c-0.2-11.9,7.2-11.8,16.6-20.7c3.9-2,7.5-7.5,14.6-8c7-0.5,10.2,3.9,12.4,12.8      C469.1,625.2,462.6,623.8,462.8,629.5z",
			"M462.6,627l0.3,2.5c0,0-12.4,1.5-17.8,6.1c0.9-3.5-1.7-6.5-1.8-6.6C443.1,629.1,451.5,634.6,462.6,627z",
			"M436.6,636.5c0,0-1.7,2.3-5.1,1.8c7.8-1,2.2-12.4,1.6-13C435.8,627.8,437.9,633.8,436.6,636.5z"
		]
	},
	"d701": {
		"paths": [
			"M340.4,1366.2c0.9,3.1-0.3,2.6-1.7,4.2s-1,3.7-5.4,6.8c-4.4,3.1-10.1-0.5-12.1-2.2s-1-1.8-3.4-5.5      c0.9-2.5,0.9-3.1,1.1-6.1c0.3-3.1,6.8-5.5,13.9-5.6C336.9,1361.7,339.5,1363.1,340.4,1366.2z",
			"M338.5,1370.8c0,0-2.7-1.7-2.4-3.2c-1.9,2.3,1.4,4.5,1.4,4.5L338.5,1370.8z"
		]
	},
	"d702": {
		"paths": [
			"M381.7,1332.4c2.4,4,1.2,3.7,0.7,6.5s1,5.5-1.1,11.6c-2.1,6-8.8,3.1-11.4,1.5c-2.5-1.7-1.7-2.1-5.7-6.4      c0.1-2.9-0.9-4.5-2.2-8.9c-1.3-4.5,3.6-9.1,8.9-13.1C375,1329.5,379.4,1328.2,381.7,1332.4z",
			"M382.4,1338.9c0,0-3.3-0.6-3.9-2.3c-0.3,3.3,3.8,4.1,3.8,4.1L382.4,1338.9z"
		]
	},
	"d703": {
		"paths": [
			"M404.4,1284.6c5,3,3.5,3.4,4.8,6.7c1.2,3.3,4.4,5.4,5.8,13.1c1.5,7.7-7.6,8.5-11.3,8.2      c-3.7-0.3-3.1-1.3-9.9-3.5c-1.7-3.2-3.7-4.4-7.8-8.4c-4.1-4-1.6-11.9,1.6-19.3C395.5,1285.4,399.3,1281.5,404.4,1284.6z",
			"M409.2,1291.5c0,0-3.8,1.6-5.4,0.3c1.9,3.5,6.6,1.7,6.6,1.7L409.2,1291.5z"
		]
	},
	"d704": {
		"paths": [
			"M422.7,1245.3c1.7,5,15.6,18.2,27,25.3c10.7,5.7,17.9,11.3,17.7,16.4c-0.2,5.2-11.6,2.5-29-1.4      c-19-4.3-37.5-12.9-43.4-17.3c-5.9-4.3-10.1-10.5-9.6-25.7c0.5-15.1,8-15.3,13.7-15.3C410.8,1227.3,420.8,1240.2,422.7,1245.3z"
		]
	},
	"d706": {
		"paths": [
			"M425.8,1124.7c-6.1,1.8-9.7-2-12.2-7.1s-1.4-18.7-3.5-21.4s-2.5-15.1-1.9-18.8c0.6-3.7,0.8-8.2,2.5-15      c1.6-6.9,13.3-4.4,15.5-2.9s2.9,5.5,4,10.1c1.2,4.7,5.4,11.3,6.7,18.5c1.2,7.1-2.7,19-4.3,26.7      C432.5,1120.6,431.8,1122.8,425.8,1124.7z",
			"M420.8,1073c2.3-1.2,10.3-0.2,10.3-0.2l-1.1-3.9C423,1070,420.8,1073,420.8,1073z"
		]
	},
	"d707": {
		"paths": [
			"M432.8,980.3c0.3,8.8,4.8,18.8,6.2,25.6c1.4,6.8-5.4,24.4-8.5,33.8c1.1,6.2-3.3,11.4-11.7,10.5      c-8.4-0.9-7.9-6.5-9.4-10.4c-1.6-3.9-0.3-13.9-0.2-17.5c0-3.6-2-11.4-2.2-15.8c-0.1-4.4,4.7-30.7,6.7-34.2      c1.9-3.4,4.9-5.4,11.4-3.6C430,970,432.4,971.5,432.8,980.3z",
			"M421.1,984.6c3-1,12.1,1.6,12.1,1.6l-0.5-4.6C424.1,981.8,421.1,984.7,421.1,984.6z"
		]
	},
	"d708": {
		"paths": [
			"M415.7,952c7.1,2.2,7,1,13.2-1.8c7.2-3.2,12.6-15.9,13.9-23.4s7-25.5,7.4-38c0.5-12.5-1.6-38.8-6.2-43.5      c-4.6-4.6-12.6-3.8-12.6-3.8c-7.9,0.9-16.2,1.3-14.8,17c1.4,15.8-1.5,20.1-2.2,24.5c-0.5,3.2-2.1,41.5-4.9,50.6      C408.2,937.6,410.4,950.2,415.7,952z",
			"M430.7,911.1c-0.6-0.8-4.2,1-12.4,4.1c1.6-2.1,0.2-3,0.8-8.3c0.8,2.7,6.9,3,12.1,2.4      C431.2,909.3,431.4,912,430.7,911.1z",
			"M446.4,878.7c-3-6.8-25.4-5.8-25.4-5.8s1.6-0.5,1-4.2c6.4,7.4,23.4,0.4,23.4,0.4S445.6,874.2,446.4,878.7z",
			"M432.1,846.8l-2.7-5.3l3.6-0.3C430.3,844.3,432.1,846.8,432.1,846.8z"
		]
	},
	"d801": {
		"paths": [
			"M275,1357.9c7.1,0.1,13.6,2.5,13.9,5.6s0.2,3.6,1.1,6.1c-2.4,3.7-1.5,3.8-3.4,5.5c-2,1.6-7.7,5.3-12.1,2.2      c-4.4-3.1-3.9-5.2-5.4-6.8c-1.5-1.6-2.7-1.1-1.7-4.2S270.9,1361.7,275,1357.9z",
			"M269.3,1370.8c0,0,2.7-1.7,2.4-3.2c1.9,2.3-1.4,4.5-1.4,4.5L269.3,1370.8z"
		]
	},
	"d802": {
		"paths": [
			"M236.9,1323.6c5.3,4,10.2,8.6,8.9,13.1c-1.3,4.4-2.3,6-2.2,8.9c-4,4.2-3.2,4.7-5.7,6.4      c-2.6,1.7-9.3,4.5-11.4-1.5c-2.1-6-0.6-8.8-1.1-11.6c-0.4-2.8-1.7-2.6,0.7-6.5C228.4,1328.2,232.8,1329.5,236.9,1323.6z",
			"M225.4,1338.9c0,0,3.3-0.6,3.9-2.3c0.3,3.3-3.8,4.1-3.8,4.1L225.4,1338.9z"
		]
	},
	"d803": {
		"paths": [
			"M220.3,1281.4c3.2,7.4,5.7,15.3,1.6,19.3c-4.1,4-6.1,5.2-7.8,8.4c-6.8,2.2-6.3,3.2-9.9,3.5      s-12.7-0.5-11.3-8.2c1.5-7.7,4.6-9.8,5.8-13.1c1.3-3.3-0.2-3.7,4.8-6.7C208.6,1281.5,212.4,1285.4,220.3,1281.4z",
			"M198.8,1291.5c0,0,3.8,1.6,5.4,0.3c-1.9,3.5-6.6,1.7-6.6,1.7L198.8,1291.5z"
		]
	},
	"d804": {
		"paths": [
			"M208.7,1227.3c5.7,0,13.2,0.2,13.7,15.3c0.5,15.2-3.7,21.4-9.6,25.7c-5.9,4.4-24.4,13-43.4,17.3      c-17.4,3.9-28.8,6.6-29,1.4c-0.2-5.1,7-10.7,17.7-16.4c11.4-7.1,25.3-20.3,27-25.3C187,1240.2,197,1227.3,208.7,1227.3z"
		]
	},
	"d806": {
		"paths": [
			"M175.2,1114.9c-1.6-7.8-5.5-19.7-4.3-26.7c1.4-7.1,5.6-13.8,6.7-18.5c1.1-4.6,1.8-8.6,4-10.1      s13.8-4,15.5,2.9c1.6,6.9,1.8,11.4,2.5,15c0.6,3.7,0.2,16.1-1.9,18.8c-2.1,2.6-1,16.3-3.5,21.4s-6.1,8.9-12.2,7.1      C176.1,1122.8,175.4,1120.6,175.2,1114.9z",
			"M177.9,1068.8l-1.1,3.9c0,0,8-1,10.3,0.2C187.1,1073,184.9,1070,177.9,1068.8z"
		]
	},
	"d807": {
		"paths": [
			"M182.8,968.8c6.5-1.7,9.5,0.2,11.4,3.6c1.9,3.4,6.7,29.8,6.7,34.2c-0.2,4.4-2.3,12.2-2.2,15.8      c0.1,3.6,1.4,13.6-0.2,17.5c-1.5,4-1,9.5-9.4,10.4s-12.8-4.3-11.7-10.5c-3.1-9.5-9.9-27.1-8.5-33.8c1.4-6.8,5.9-16.7,6.2-25.6      C175.5,971.5,177.9,970,182.8,968.8z",
			"M175.3,981.7l-0.5,4.6c0,0,9.1-2.6,12.1-1.6C186.8,984.7,183.8,981.8,175.3,981.7z"
		]
	},
	"d808": {
		"paths": [
			"M198.5,933.7c-2.8-9.2-4.4-47.5-4.9-50.6c-0.7-4.4-3.6-8.6-2.2-24.5c1.4-15.7-6.9-16.1-14.8-17      c0,0-8-0.9-12.6,3.8s-6.7,31-6.2,43.5c0.4,12.5,6.1,30.5,7.4,38c1.3,7.6,6.7,20.2,13.9,23.4c6.1,2.8,6.1,4,13.2,1.8      C197.5,950.2,199.7,937.6,198.5,933.7z",
			"M176.7,909.3c5.2,0.5,11.3,0.3,12.1-2.4c0.6,5.3-0.8,6.2,0.8,8.3c-8.2-3.1-11.9-4.9-12.4-4.1      C176.5,912,176.7,909.3,176.7,909.3z",
			"M162.5,869.1c0,0,17,7,23.4-0.4c-0.6,3.8,1,4.2,1,4.2s-22.4-1-25.4,5.8      C162.3,874.2,162.5,869.1,162.5,869.1z",
			"M175,841.2l3.6,0.3l-2.7,5.3C175.8,846.8,177.6,844.3,175,841.2z"
		]
	}
};