import React, { useEffect } from 'react';
import { IntegratedPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import ClientSearch from './ClientSearch';
import { useDispatch, useSelector } from "react-redux";
import { actions } from './Clients.redux';
import DashboardCard from "components/DashboardCard";
import { Add, People } from "@mui/icons-material";
import { Button, Grid as MUIGrid } from '@mui/material';
import ClientTableRow from './ClientTableRow';
import ClientAddModal from './ClientAddModal';


const columns = [
	{ name: 'client_name', title: 'Name' },
	{ name: 'client_external_id', title: 'External ID' },
	{ name: 'last_visit', title: 'Last Visit' },
	{ name: 'address_city', title: 'City' },
	{ name: 'address_state', title: 'State' },
];


const Clients = () => {

	const clientList   = useSelector(state => state.clients.clients),
		  dispatch     = useDispatch(),
		  handleSearch = () => dispatch(actions.search()),
		  showAddNew   = () => dispatch(actions.showAddNew()),
		  resetAll     = () => dispatch(actions.resetAll());

	useEffect(() => {
		handleSearch();
		return () => {
			resetAll();
		};
	}, []);


	return (
		<MUIGrid container spacing={4}>
			<MUIGrid item xs={12} lg={3} xl={4}>
				<ClientSearch />
			</MUIGrid>

			<MUIGrid item xs={12} lg={9} xl={8}>
				<DashboardCard
					color={'deepOrange'}
					icon={<People />}
					topContent={"Clients"}
					topLabel={<>
						<div>Click a client to view their profile or</div>
						<div>
							<Button variant="contained" size="small" color="primary" onClick={showAddNew}>
								<Add /> Add New
							</Button>
						</div>
					</>}
					size="xl"
				>

					<Grid
						rows={clientList}
						columns={columns}
					>
						<PagingState
							defaultCurrentPage={0}
							defaultPageSize={10}
						/>
						<IntegratedPaging />
						<Table rowComponent={ClientTableRow} />
						<TableHeaderRow />
						<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
					</Grid>
				</DashboardCard>
			</MUIGrid>

			<ClientAddModal />
		</MUIGrid>
	);

};



export default Clients;