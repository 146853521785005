import React, { memo } from 'react';
import classnames from 'classnames';
import DashboardCardIconHeader from './DashboardCardIconHeader';
import useTheme from './DashboardCard.styles';



const DashboardCard = memo(
	({
		size = 'xl',
		color = 'grey',
		variant = 'icon',
		headerFullWidth = false,
		headerContent,
		icon = <div />,
		topContent = '',
		topLabel,
		children,
		...props
	}) => {
		let fullWidth = variant === 'full';
		const classes = useTheme();

		return (
			<div
				className={classnames({
					[ classes.container ]: true,
					[ classes.containerXSmall ]: size === 'xs',
					[ classes.containerSmall ]: size === 'sm',
					[ classes.containerMedium ]: size === 'md',
					[ classes.containerLarge ]: size === 'lg',
					[ classes.containerXLarge ]: size === 'xl',
				})}
			>
				<div className={classes.innerContainer}>
					<div className={classes.topContentRightAlign}>
						<div
							className={classnames({
								[ classes.headerContainer ]: true,
								[ classes.iconFloat ]: !fullWidth,
								[ classes.fullWidth ]: fullWidth,
								[ classes.labelMargin ]: fullWidth,
								[ classes.backgroundGrey ]: color === 'grey',
								[ classes.backgroundGrey ]: color === 'gray',
								[ classes.backgroundBlue ]: color === 'blue',
								[ classes.backgroundOrange ]: color === 'orange',
								[ classes.backgroundIndigo ]: color === 'indigo',
								[ classes.backgroundRed ]: color === 'red',
								[ classes.backgroundPink ]: color === 'pink',
								[ classes.backgroundPurple ]: color === 'purple',
								[ classes.backgroundDeepPurple ]: color === 'deepPurple',
								[ classes.backgroundLightBlue ]: color === 'lightBlue',
								[ classes.backgroundCyan ]: color === 'cyan',
								[ classes.backgroundTeal ]: color === 'teal',
								[ classes.backgroundGreen ]: color === 'green',
								[ classes.backgroundLightGreen ]: color === 'lightGreen',
								[ classes.backgroundLime ]: color === 'lime',
								[ classes.backgroundYellow ]: color === 'yellow',
								[ classes.backgroundAmber ]: color === 'amber',
								[ classes.backgroundDeepOrange ]: color === 'deepOrange',
								[ classes.backgroundBrown ]: color === 'brown',
								[ classes.backgroundBlueGrey ]: color === 'blueGrey',
							})}
						>
							{!fullWidth &&
								<DashboardCardIconHeader icon={icon} />
							}

							{fullWidth && headerContent}
						</div>

						{!fullWidth &&
							<div>
								<h3 className={classes.topContentRightAlignText}>
									{topContent}
								</h3>
								{(Boolean(topLabel) && typeof topLabel === 'string') &&
									<p className={classes.topContentRightAlignLabel}>
										{topLabel}
									</p>
								}

								{(Boolean(topLabel) && typeof topLabel !== 'string') &&
									<span className={classes.topContentRightAlignLabel}>
								{topLabel}
							</span>
								}
							</div>
						}

						<div className={classnames(classes.contentWrapper, classes.contentSeparator)}>
							<div className={classes.content}>
								{children}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	},
);


/*
DashboardCard.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
	topLabel: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
	topContent: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
	headerContent: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
	variant: PropTypes.oneOf([ 'icon', 'full' ]).isRequired,
	icon: PropTypes.PropTypes.node,
	color: PropTypes.oneOf([
		'grey',
		'blue',
		'orange',
		'indigo',
		'red',
		'pink',
		'purple',
		'deepPurple',
		'lightBlue',
		'cyan',
		'teal',
		'green',
		'lightGreen',
		'lime',
		'yellow',
		'amber',
		'deepOrange',
		'brown',
		'blueGrey',
	]).isRequired,
};
*/


export default DashboardCard;
