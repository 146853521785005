import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from "redux-thunk";

import layout, { actions as layoutActions } from 'Layout/Layout.redux';
import auth, { actions as authActions } from './auth.redux';

import clients, { actions as clientActions } from 'pages/Clients/Clients.redux';
import clientView, { actions as clientViewActions } from 'pages/ClientView/ClientView.redux';

import patients, { actions as patientActions } from 'pages/Patients/Patients.redux';
import patientView, { actions as patientViewActions } from 'pages/PatientView/PatientView.redux';

import chart, { actions as chartActions } from 'pages/Chart/Chart.redux';

import chartingDefaults, {
	actions as chartingDefaultsActions,
} from 'pages/ClinicSettings/DefaultCodes/ChartingDefaults.redux';
import customCodes, { actions as customCodesActions } from 'pages/ClinicSettings/CustomCodes/CustomCodes.redux';
import users, { actions as usersActions } from 'pages/ClinicSettings/UserManagement/UserManagement.redux';

import reports, { actions as reportsActions } from 'pages/Reports/Reports.redux';

import files, { actions as filesActions } from 'components/FileManager/FileManager.redux';



export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	auth,
	chart,
	chartingDefaults,
	clients,
	clientView,
	customCodes,
	files,
	layout,
	patients,
	patientView,
	reports,
	users,
});


export const actions = {
	auth: authActions,
	chart: chartActions,
	chartingDefaults: chartingDefaultsActions,
	clients: clientActions,
	clientView: clientViewActions,
	customCodes: customCodesActions,
	files: filesActions,
	layout: layoutActions,
	patients: patientActions,
	patientView: patientViewActions,
	reports: reportsActions,
	users: usersActions,
};


const store = createStore(
	createRootReducer(history),
	composeEnhancers(
		applyMiddleware(
			routerMiddleware(history),
			thunkMiddleware,
		),
	),
);


export default store;
