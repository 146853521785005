import test from './test';
import gingivalRecession from './gingivalRecession';
import diagDefault from './diagDefault';
import treatmentDefault from './treatmentDefault';
import treatmentMultipleChoice from './treatmentMultipleChoice';
import diagMultipleChoice from './diagMultipleChoice';
import rootCanal from './rootCanal';


export default {
	diagDefault,
	treatmentDefault,
	gingivalRecession,
	test,
	treatmentMultipleChoice,
	diagMultipleChoice,
	rootCanal,
};