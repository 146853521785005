import React, { useCallback, useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Button } from "@mui/material";
import { Add, ContactMail } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { actions } from '../ClientView.redux';
import ClientContactMethodsCell from './ClientContactMethodsCell';
import ClientContactMethodsEditModal from './ClientContactMethodsEditModal';



const columns = [
	{ name: 'cm_edit', title: 'Edit' },
	{ name: 'cm_type', title: 'Type' },
	{ name: 'cm_name', title: 'Name' },
	{ name: 'cm_value', title: 'Value' },
	{ name: 'cm_note', title: 'Note' },
	{ name: 'date_created', title: 'Date Added' },
];

const columnExtensions = [
	{ columnName: 'cm_edit', width: 100 },
];


const ClientCharts = () => {
	const dispatch           = useDispatch(),
		  { client_id }      = useParams(),
		  hasLoaded          = useSelector(state => state.clientView.contactMethodsPreviouslyLoaded),
		  loading            = useSelector(state => state.clientView.contactMethodsLoading),
		  contactMethods     = useSelector(state => state.clientView.contactMethods),
		  loadContactMethods = (clientID) => dispatch(actions.loadContactMethods(clientID));

	const handleAddNew = useCallback(() => {
		dispatch(actions.openContactMethodEditModal(null));
	}, [ dispatch ]);


	useEffect(() => {
		if(!hasLoaded && !loading) {
			loadContactMethods(client_id);
		}

	}, [ loadContactMethods, client_id, hasLoaded, loading ]);


	return (
		<DashboardCard
			color={'blueGrey'}
			icon={<ContactMail />}
			topContent={"Contact Methods"}
			topLabel={<div>
				<Button
					variant="contained"
					color="primary"
					size="small"
					startIcon={<Add />}
					onClick={handleAddNew}
				>
					Add New
				</Button>
			</div>}
			size="xl"
		>
			<Grid
				rows={contactMethods}
				columns={columns}
			>
				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table
					columnExtensions={columnExtensions}
					cellComponent={ClientContactMethodsCell}
				/>
				<TableHeaderRow />
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>

			<ClientContactMethodsEditModal />
		</DashboardCard>
	);
};


export default ClientCharts;