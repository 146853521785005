import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import DashboardCard from "components/DashboardCard";
import Pets from '@mui/icons-material/Pets';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import api from 'api';
import { Link } from 'react-router-dom';



const styles = theme => ({
	fullWidth: {
		width: '100%',
	},
});

const pageSize = 10;
const RecentPatients = ({ size, classes }) => {

	const [ page, setPage ] = useState(0);
	const [ rows, setRows ] = useState([]);

	useEffect(() => {
		async function fetchCharts() {
			let result = await api.get('/charts/search', {
				params: {
					page_number: 0,
					page_size: pageSize,
					patient_name: '',
					client_name: '',
				},
			});

			setRows(result.data.charts);
		}

		fetchCharts().catch(err => console.log(err));

	}, [ page, pageSize, setRows ]);

	return (<DashboardCard
		color={'green'}
		icon={<Pets />}
		topContent={"Recent Dental Charts"}
		topLabel={`Showing the ${rows.length} most recent reports`}
		size={size}
	>
		<div className={classes.fullWidth}>

			<Table className={classes.fullWidth} size="small">

				<TableHead>
					<TableRow>
						<TableCell align="left">Patient Name</TableCell>
						<TableCell align="left">Client Name</TableCell>
						<TableCell align="right">Chart Date</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{rows.map((row) => (<TableRow key={row.visit_id}>
						<TableCell component="th" scope="row">
							<Link to={`/chart/${row.visit_id}`}>{row.patient_name}</Link>
						</TableCell>
						<TableCell align="left">
							{row.client_name}
						</TableCell>
						<TableCell align="right">{row.date_created}</TableCell>
					</TableRow>))}
				</TableBody>

			</Table>

		</div>
	</DashboardCard>);
};



RecentPatients.defaultProps = {
	size: 'md',
};

RecentPatients.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
};


export default withStyles(styles)(RecentPatients);
