import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { Title } from '@mui/icons-material';
import DashboardCard from 'components/DashboardCard';



const NameAndLogo = () => {
	return (
		<DashboardCard
			color={'orange'}
			icon={<Title />}
			topContent={"Clinic Name and Logo"}
			size="xl"
		>
			<Grid container spacing={2} sx={{ marginTop: 1 }}>
				<Grid item xs={12} lg={6}>
					<Typography variant="body2">
						Please enter the name of your clinic as you would like it to appear on reports and in
						communication with our support team.
					</Typography>
					<TextField placeholder="Enter the name of your clinic." label="Clinic name" />
					<Button variant="contained" sx={{ marginLeft: 2 }}>Save</Button>
				</Grid>
				<Grid item xs={12} lg={6}>
					<Typography variant="body2">
						Click in the box below, or drag and drop an image to upload your clinic logo. Uploading
						a logo allows its use in reports.
					</Typography>
					<Box sx={{ width: '400px', height: '300px', border: '3px dashed #858585', borderRadius: '12px' }} />
				</Grid>
			</Grid>

		</DashboardCard>
	);
};

export default NameAndLogo;
