module.exports = {
	"d501": {
		"paths": [
			"M837.7,117.9c-2.5,5.8-6.9,13.2-5.5,19.5c1,2.7,3.2,1.4,5,0.8c3.7,4.4,1,14.1,6.6,13.6     c5.7-0.5,6.9-8.6,6.7-14.1c4.2,3.2,6.9-2,6.2-5.9c-0.7-4-4.2-13.1-4.2-13.1s1.2-22.5,0-35.3s-1.3-16.1-4-16.1s-6.2,5.7-7.2,16.3     S839.2,110.7,837.7,117.9z"
		]
	},
	"d502": {
		"paths": [
			"M777.3,117.9c-5.2,5.4-8.4,14.3-5.2,17.8s3.5-3.5,4.4,2.2c1,5.7,2.2,17.1,7.9,16.6s8.9-12.3,8.6-17.8     c3.7,0.3,3.7-5.7,3-9.7s-4.5-9.1-4.5-9.1s1.9-22.1,0.7-34.9s0.9-17-4.5-17s-4.7,8.5-5.7,19.2S778.8,110.7,777.3,117.9z"
		]
	},
	"d503": {
		"paths": [
			"M712.9,118.9c-2.5,4.1-7,14-4.9,16.1s3.7,1.7,2.1,11.5s-5.1,18.4,1.4,20.3s17-19.6,19.7-35.4     c0.6-2.3,2.9,0,3-6.1s-2.2-3.1-2.3-7.2s2.9-32.7,2.8-39.9s0.4-22.4-6.1-22.4c-10.5,0.2-10.7,22-10.6,26.8     S713.5,115.7,712.9,118.9z"
		]
	},
	"d504": {
		"paths": [
			"M621.5,118.2c3.7,17,19.4,31.6,24.1,44.8c6.3,17.7,8.7,31,8.6,36.8s0.2,14.6,3.4,14.3     c2.1-0.2,2.5-1.5,7.9-13.6c5.4-12.2,1.8-45.9-4.1-62.1c-6-16.4-19.6-47.1-27.5-59.3C624.4,64.5,616,50,599.3,33.3     c-11.1-10.5-23.3-16.8-27.1-13.8C565.3,24.5,614.6,86.1,621.5,118.2z"
		]
	},
	"d506": {
		"paths": [
			"M503.6,118.3c-4.2,9-3.4,16.9,0.5,17.3c3.9,0.4,9.4,25.2,15.9,24.9c4.7-0.2,19-20.3,17.4-30.6     c-0.6-4.1-0.6-4.1,1-6.2c2-2.6-2.6-5.7-2.6-5.7s7.2-16.1,7.9-25s6.8-27.5,2-31s-10.6,23-10.6,23s-5.2,32.6-15.5,30.8     c-10.3-1.7-10.2-20.8-13.2-30.8c-1.3-9-5.2-21.5-5.2-21.5s-5.9-6.9-6,3.3c-0.1,12.3,0.6,9.2,1.6,23     C497.8,103.5,503,114.8,503.6,118.3z"
		]
	},
	"d507": {
		"paths": [
			"M426.7,126.3c0,0,3.2-51.7,5-60.5s0.7-10.7,4.7-10.7s10,8.2,8.3,29s-1.8,41.3-1.8,41.3",
			"M375,120.7c-13.3,8.8-2.8,20.2,3,19.8c1.7,14.2,14.3,16.8,19.7,7.3c-3.3,11.5,6.1,39.8,15.8,40.3     c16.7-5.3,38.2-45.7,52.2-46.2c6.3-2.3,6.2-9.7,9.7-16c1.8-4.8-4.5-3.5-6.3-7.5s6.7-29.3,6.3-37.3c-0.3-8-0.5-24.5-6.5-24.8     s-7.8,6.5-9.5,14.3s-1.3,21.6-5.8,28.2c-4.6,6.6-8.1,12.8-31.4,15.7c-23.3,2.8-31.1-35-31.8-43.5c-0.7-8.5,0.2-24.3-9-23.7     c-9.2,0.7-8.7,13.8-8.4,17.6S370.2,95.8,375,120.7z"
		]
	},
	"d508": {
		"paths": [
			"M289.8,156.8c0,0,10.5,10.8,16.9,11.2c6.4,0.3,19.4-8.5,19.8-9.5s-14.3-18.8-14.3-18.8l-15.2,0.8     L289.8,156.8z",
			"M299.7,120.3c3.7-33.8-0.8-74.7,8.5-74.5c9.3,0.2,11,62.8,7.2,76.3",
			"M348.8,127.7c-0.9,7.3-13.3,37.7-17.5,37.7s-14.3-3.7-17.8-9.8s-2-9.3-5.3-9s-1.7,5.2-3.2,8.2     s-19.2,10.2-22.5,8.7s-20.2-27.8-19.2-33.8s4.3-5.5,5-9.7s-2.5-50.2,0-59.3c2.5-9.2,3.9-19.6,8.7-20.2c9-1.2,8.7,33,9.2,46.3     s11.7,30.3,19.9,29.2c8.3-1.2,21.6-1.2,25.9-34s5.9-41.5,11.2-41.6c10.2,0,6.5,37.2,5.7,51.4c-0.6,11.3-6.7,23.2-4.4,26.3     C344.5,118,349.4,123.1,348.8,127.7z"
		]
	},
	"d601": {
		"paths": [
			"M909,83.6c-1-10.6-4.5-16.3-7.2-16.3s-2.8,3.3-4,16.1s0,35.3,0,35.3s-3.5,9.1-4.2,13.1     c-0.7,3.9,2,9.1,6.2,5.9c-0.2,5.5,1,13.6,6.7,14.1c5.6,0.5,2.9-9.2,6.6-13.6c1.8,0.7,4.1,2,5-0.8c1.4-6.3-3.1-13.8-5.5-19.5     C911.2,110.6,910,94.2,909,83.6z"
		]
	},
	"d602": {
		"paths": [
			"M968.4,85.1c-1-10.7-0.3-19.2-5.7-19.2s-3.3,4.2-4.5,17s0.7,34.9,0.7,34.9s-3.8,5.1-4.5,9.1s-0.7,10,3,9.7     c-0.3,5.5,2.9,17.3,8.6,17.8s6.9-10.9,7.9-16.6c0.9-5.7,1.2,1.3,4.4-2.2s0-12.4-5.2-17.8C971.6,110.6,969.4,95.8,968.4,85.1z"
		]
	},
	"d603": {
		"paths": [
			"M1032.5,82.6c0.1-4.8-0.1-26.5-10.6-26.8c-6.5,0-6,15.3-6.1,22.4s2.9,35.8,2.8,39.9s-2.4,1.1-2.3,7.2     c0.1,6,2.4,3.7,3,6.1c2.7,15.8,13.2,37.3,19.7,35.4s3-10.5,1.4-20.3s0-9.4,2.1-11.5s-2.4-12-4.9-16.1     C1036.9,115.6,1032.4,87.3,1032.5,82.6z"
		]
	},
	"d604": {
		"paths": [
			"M1178.6,19.5c-3.8-3-16,3.3-27.1,13.8c-16.7,16.7-25.1,31.2-34.6,45.8c-7.9,12.2-21.5,42.9-27.5,59.3     c-5.9,16.2-9.5,49.9-4.1,62.1c5.4,12.1,5.8,13.4,7.9,13.6c3.2,0.3,3.5-8.5,3.4-14.3s2.3-19.1,8.6-36.8     c4.7-13.2,20.4-27.8,24.1-44.8C1136.2,86.1,1185.6,24.5,1178.6,19.5z"
		]
	},
	"d606": {
		"paths": [
			"M1254,89.8c1-13.8,1.7-10.7,1.6-23c-0.1-10.2-6-3.3-6-3.3s-3.9,12.5-5.2,21.5c-2.9,10-2.9,29.1-13.2,30.8     c-10.3,1.8-15.5-30.8-15.5-30.8s-5.9-26.6-10.6-23.1s1.3,22.1,2,31s7.9,25,7.9,25s-4.6,3.2-2.6,5.7c1.6,2.1,1.6,2.1,1,6.2     c-1.5,10.3,12.7,30.4,17.4,30.6c6.4,0.3,12-24.5,15.9-24.9s4.7-8.3,0.5-17.3C1247.9,114.8,1253,103.5,1254,89.8z"
		]
	},
	"d607": {
		"paths": [
			"M1323.7,126.3c0,0-3.2-51.7-5-60.5s-0.7-10.7-4.7-10.7s-10,8.2-8.3,29s1.8,41.3,1.8,41.3",
			"M1377.4,64.9c0.4-3.8,0.8-17-8.4-17.6c-9.2-0.7-8.3,15.2-9,23.7s-8.4,46.3-31.8,43.5s-26.8-9.1-31.4-15.7     s-4.2-20.3-5.8-28.2s-3.5-14.7-9.5-14.3c-6,0.3-6.2,16.8-6.5,24.8s8.2,33.3,6.3,37.3c-1.8,4-8.2,2.7-6.3,7.5     c3.5,6.3,3.3,13.7,9.7,16c14,0.5,35.5,40.8,52.2,46.2c9.8-0.6,19.2-28.8,15.8-40.3c5.3,9.5,18,6.8,19.7-7.3     c5.8,0.3,16.3-11,3-19.8C1380.2,95.8,1377,68.8,1377.4,64.9z"
		]
	},
	"d608": {
		"paths": [
			"M1460.5,156.8c0,0-10.5,10.8-16.9,11.2c-6.4,0.3-19.4-8.5-19.8-9.5c-0.3-1,14.3-18.8,14.3-18.8l15.2,0.8     L1460.5,156.8z",
			"M1450.7,120.3c-3.7-33.8,0.8-74.7-8.5-74.5c-9.3,0.2-11,62.8-7.2,76.3",
			"M1401.5,127.6c0.9,7.3,13.3,37.7,17.5,37.7s14.3-3.7,17.8-9.8s2-9.3,5.3-9s1.7,5.2,3.2,8.2     s19.2,10.2,22.5,8.7s20.2-27.8,19.2-33.8s-4.3-5.5-5-9.7s2.5-50.2,0-59.3s-3.9-19.6-8.7-20.2c-9-1.2-8.7,33-9.2,46.3     s-11.7,30.3-19.9,29.2c-8.3-1.2-21.6-1.2-25.9-34s-5.9-41.5-11.2-41.6c-10.2,0-6.5,37.2-5.7,51.4c0.6,11.3,6.7,23.2,4.4,26.3     C1405.9,117.9,1401,123,1401.5,127.6z"
		]
	},
	"d701": {
		"paths": [
			"M913.8,382.2c0,7.3-0.8,10.4-5.3,11.7s-4.2-7.5-7-16.3s-0.4-30.7-1.2-31.2s-3-8.1-3.8-13     c-1.8-3.2-0.7-8.8,1.8-7.3c-0.2-6,1.2-10.9,7.2-10.9c4.5,0,5.3,9,6.3,12.3c1-2.7,3.3-2.8,5.1-1.3c1.3,1.1,1.2,5.9-1.4,10.6     c0,3.1,0.2,6.1-1.1,8.9C913.3,351.2,913.8,374.9,913.8,382.2z"
		]
	},
	"d702": {
		"paths": [
			"M975.3,382.6c0.7,10.3-3,12.2-6.7,12.2s-6.8-9.3-7.4-17.8c-0.6-9.5-1-26.6-0.5-31.1     c-3.3-3.4-0.4-8.5-4.7-11.8c-2.8-1.5-2.5-10.8,3.5-8.3c-1-3.5,0.7-8.4,1.8-10.7c1.3-2.5,2.3-3.8,5.8-3.8s7,11.5,7.3,15     c1-2.3,3.7-1.1,5.1,0c3.5,2.8-4.2,9.7-3.8,19.3C974,357.3,974.6,372.2,975.3,382.6z"
		]
	},
	"d703": {
		"paths": [
			"M1029.4,376.7c0.2,14-2.8,19.9-7.3,19.4s-6.1-15.5-5.8-19.3s-2.9-24.4-4.4-30.1c-2.3-2.5-1-7.2-3.2-10.1     c-3.1-4.2-2.5-10.3,1.6-11c1.7-0.7,3.8-23.1,9.6-21.3c5.6,2.3,6.3,20.2,6.3,22.2c1-2,2.7-2.1,5.2-0.6c2,1.4-0.4,13.9-4.3,19.6     C1027.7,353,1029.1,362.6,1029.4,376.7z"
		]
	},
	"d704": {
		"paths": [
			"M1051,302c-0.9,11,2.2,27.6,5.8,43.4c4.7,21,15.4,33.7,27.7,45.6c15,14.5,26.2,27.7,41.1,30     c8.1-9.8-43.3-55.5-41-75.1c0.7-4.3,2.1-5.8,0.8-7.8c-6.1-9-9.9-19.6-13.1-31s-11.8-55.9-16.6-55.9     C1051,251.2,1051.9,291,1051,302z"
		]
	},
	"d706": {
		"paths": [
			"M1231.6,374.6c-0.2,6.5,0.6,19.9-2.3,25.2s-6.1,2.9-8.8,0.9c-2.7-2-2-5-3-12.5s-2.8-20.5-2.8-23.5     s-2.6-14-5.3-14.5c-3.1-0.5-8.1,11.9-8.3,13.8c-0.2,1.9-2,21.6-3.8,25.8s-2.1,14-7.7,13s-5.3-7.8-5.1-16.4s5.1-40.6,5.1-40.6     s-0.2-4.7-1-9c-0.8-4.2,2.3-4.2,3.3-5.3c1-1.1,16.1-26,18.7-26c2.5,0,4.7,2.3,6.1,9s8.2,17,9.5,17c1.2,0,1.4,1.6,2.7,3.1     c1.3,1.4,0.8-1.5,3.3,0.7c2.6,2.3,2.8,5.3-0.7,12.8C1233.2,354.6,1231.9,367.8,1231.6,374.6z"
		]
	},
	"d707": {
		"paths": [
			"M1312.4,375.9c-0.8,15.7,3.6,24.3-1.4,30.1c-5.1,5.8-9.1-0.8-10-3.4c-0.9-2.4-5.9-19.6-6.5-25.7     c-0.5-6.1-6.8-29-10.6-29c-3.9,0-4.6,8.5-7,21.3c-2.4,12.9-8.1,29.1-8.1,33.7c0,4.4-5.5,5.1-11.5,3.5s-0.6-22.2,0-31.6     s1.9-30.2,2.2-33.1c0.3-2.9-2.9-10.8-2.8-14.1c0.2-3.3,1.9-3.7,6.8-5.3c5.1-1.7,20.4-28.1,24.1-27s7.9,20.2,8.5,24.3     c0.5-1.2,4.3-3.3,5.4-3.3c1.2,0,2.9,1.8,2,9.4c2.1-0.3,4.8,2.2,6,5.8c1.2,3.6-0.2,7.9-3.2,12.3     C1308.2,351.2,1313.1,360.2,1312.4,375.9z"
		]
	},
	"d708": {
		"paths": [
			"M1410.8,343.2l-17.1,1.3c0,0-9.1-22.5-6.1-21.8s9-7.1,13.4-7.9s9.8,10.3,9.8,10.3L1410.8,343.2L1410.8,343.2     z",
			"M1373.6,349.8c7.1,0,15.9,6.6,20.5,19c6.1,16.3,9.4,49.9,15.2,49.9c10.2,0,8.3-39.9,7.6-51.4     c-0.7-11.6-1.5-24.6-1.5-24.6c6.4-6.8,6.5-5.9,3.9-14.2c-2.5-8.3-5.6-12.1-11.1-7.6s-8.1,2.5-9.1,5c-1.1,2.5-1.9,5.4-3.2,5.4     s-1.5-0.8-5.2-6.8c-3.6-6-8.7-24.3-13.5-31.5s-8.8-5.3-11.5,1.2s-7.6,24.2-11.4,25.9c-3.8,1.7-12.8-9.9-14.6-9.9     c-1.8,0-9,21.8-7.7,29.8s3.9,7.3,5.7,8.6c-0.4,7.8-4.8,20.3-1,43.9c1.1,8.6,4.9,26,11.7,25.3s3.2-21.7,4.2-32.8     C1354.8,357.7,1366.1,349.7,1373.6,349.8z"
		]
	},
	"d801": {
		"paths": [
			"M834.6,345.5c-1.3-2.8-1.1-5.8-1.1-8.9c-2.6-4.7-2.7-9.5-1.4-10.6c1.8-1.5,4.1-1.4,5.1,1.3     c1-3.3,1.8-12.3,6.3-12.3c6,0,7.4,4.9,7.2,10.9c2.5-1.5,3.6,4.1,1.8,7.3c-0.8,4.9-3,12.5-3.8,13s1.6,22.4-1.2,31.2     c-2.8,8.8-2.5,17.6-7,16.3s-5.3-4.4-5.3-11.7S835.8,351.2,834.6,345.5z"
		]
	},
	"d802": {
		"paths": [
			"M773.3,345.5c0.4-9.6-7.3-16.5-3.8-19.3c1.4-1.1,4.1-2.3,5.1,0c0.3-3.5,3.8-15,7.3-15s4.5,1.3,5.8,3.8     c1.1,2.2,2.8,7.2,1.8,10.7c6-2.5,6.3,6.8,3.5,8.3c-4.3,3.3-1.3,8.3-4.7,11.8c0.5,4.5,0.1,21.6-0.5,31.1     c-0.5,8.5-3.7,17.8-7.4,17.8s-7.4-1.9-6.7-12.2C774.5,372.2,775,357.3,773.3,345.5z"
		]
	},
	"d803": {
		"paths": [
			"M722,345.4c-3.8-5.8-6.3-18.2-4.3-19.6c2.5-1.5,4.2-1.4,5.2,0.6c0-2,0.8-19.8,6.3-22.2     c5.8-1.8,7.9,20.6,9.6,21.3c4.1,0.8,4.7,6.8,1.6,11c-2.2,2.9-0.9,7.6-3.2,10.1c-1.5,5.8-4.7,26.3-4.4,30.1     c0.3,3.8-1.3,18.8-5.8,19.3s-7.5-5.4-7.3-19.4C720,362.6,721.4,353,722,345.4z"
		]
	},
	"d804": {
		"paths": [
			"M694.8,251.2c-4.8,0-14.4,44.5-17.6,55.9s-7,22-13.1,31c-1.3,2,0.1,3.5,0.8,7.8c2.3,19.6-49.1,65.3-41,75.1     c14.9-2.3,26.1-15.5,41.1-30c12.3-11.9,23-24.6,27.7-45.6c3.6-15.8,6.7-32.4,5.8-43.4S699.5,251.2,694.8,251.2z"
		]
	},
	"d806": {
		"paths": [
			"M517.3,347.9c-3.5-7.5-3.3-10.6-0.7-12.8c2.5-2.2,2,0.7,3.3-0.7c1.3-1.5,1.5-3.1,2.7-3.1     c1.3,0,8-10.3,9.5-17s3.6-9,6.1-9s17.7,24.9,18.7,26s4.1,1.1,3.3,5.3s-1,9-1,9s4.8,32,5.1,40.6s0.5,15.4-5.1,16.4     s-5.9-8.9-7.7-13s-3.6-23.9-3.8-25.8s-5.1-14.2-8.3-13.8c-2.7,0.5-5.3,11.5-5.3,14.5s-1.8,16-2.8,23.5s-0.3,10.5-3,12.5     s-5.9,4.4-8.8-0.9s-2.1-18.6-2.3-25.2C517.2,367.8,515.9,354.6,517.3,347.9z"
		]
	},
	"d807": {
		"paths": [
			"M442.7,343.8c-3-4.3-4.5-8.7-3.2-12.3s3.9-6.1,6-5.8c-0.9-7.6,0.8-9.4,2-9.4s4.9,2.1,5.4,3.3     c0.6-4,4.8-23.1,8.5-24.3c3.7-1.1,19.1,25.3,24.1,27c5,1.6,6.7,2,6.8,5.3c0.2,3.3-3,11.2-2.8,14.1c0.3,2.9,1.6,23.7,2.2,33.1     s6,30,0,31.6s-11.5,0.9-11.5-3.5c0-4.6-5.7-20.8-8.1-33.7c-2.4-12.8-3.1-21.3-7-21.3c-3.8,0-10.1,22.9-10.6,29     s-5.6,23.3-6.5,25.7c-0.9,2.5-5,9.2-10,3.4c-5.1-5.8-0.6-14.4-1.4-30.1C436,360.2,440.9,351.2,442.7,343.8z"
		]
	},
	"d808": {
		"paths": [
			"M338.3,325.2c0,0,5.5-11.1,9.8-10.3c4.4,0.8,10.4,8.6,13.4,7.9s-6.1,21.8-6.1,21.8l-17.1-1.3L338.3,325.2     L338.3,325.2z",
			"M396.7,384.8c1,11.1-2.6,32.1,4.2,32.8c6.8,0.7,10.6-16.7,11.7-25.3c3.8-23.7-0.6-36.1-1-43.9     c1.9-1.3,4.5-0.6,5.7-8.6s-6-29.8-7.7-29.8s-10.8,11.5-14.6,9.9c-3.8-1.7-8.7-19.4-11.4-25.9s-6.7-8.3-11.5-1.2     c-4.8,7.2-9.9,25.5-13.5,31.5s-3.9,6.8-5.2,6.8c-1.3,0-2.1-2.9-3.2-5.4s-3.6-0.5-9.1-5s-8.6-0.7-11.1,7.6     c-2.6,8.3-2.5,7.4,3.9,14.2c0,0-0.8,13-1.5,24.6c-0.7,11.5-2.6,51.4,7.6,51.4c5.8,0,9.1-33.6,15.2-49.9c4.6-12.4,13.4-19,20.5-19     C383,349.7,394.3,357.7,396.7,384.8z"
		]
	}
};