import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	perioContainer: {
		width: '100%',
		height: '100%',
		minHeight: '100%',
		//display: 'table',
	},
}));



export default useStyles;