import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { SettingsApplications } from '@mui/icons-material';
import DashboardCard from 'components/DashboardCard';
import { deepOrange, indigo, pink, teal } from '@mui/material/colors';



const ClinicSettingsHome = () => {
	return (
		<DashboardCard
			color={'orange'}
			icon={<SettingsApplications />}
			topContent={"Clinic Settings"}
			size="xl"
		>
			<Grid container spacing={[ 2, 6 ]} sx={{ marginTop: 1 }}>
				<Grid item xs={12} md={6} xl={4}>
					<Card raised sx={{ height: '200px' }}>
						<Box
							sx={{
								width: '100%',
								height: '80px',
								backgroundColor: indigo[ 900 ],
								lineHeight: '80px',
								textAlign: 'center',
								color: '#ffffff',
								fontSize: '24px',
							}}
						>
							Clinic Details
						</Box>
						<CardContent>
							<Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
								<Link to="/clinic-settings/name-and-logo">Name and Logo</Link>
							</Typography>

							<Typography variant="body2" color="text.secondary">
								<Link to="/clinic-settings/contact-info">Address and Contact Info</Link>
							</Typography>
						</CardContent>
					</Card>
				</Grid>


				<Grid item xs={12} md={6} xl={4}>
					<Card raised sx={{ height: '200px' }}>
						<Box
							sx={{
								width: '100%',
								height: '80px',
								backgroundColor: deepOrange[ 800 ],
								lineHeight: '80px',
								textAlign: 'center',
								color: '#ffffff',
								fontSize: '24px',
							}}
						>
							Charting Options
						</Box>
						<CardContent>
							<Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
								<Link to="/clinic-settings/custom-codes">Customs Codes</Link>
							</Typography>

							<Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
								<Link to="/clinic-settings/default-codes">Default Codes</Link>
							</Typography>

							<Typography variant="body2" color="text.secondary">
								<Link to="/clinic-settings/chart-appearance">Appearance</Link>
							</Typography>
						</CardContent>
					</Card>
				</Grid>


				<Grid item xs={12} md={6} xl={4}>
					<Card raised sx={{ height: '200px' }}>
						<Box
							sx={{
								width: '100%',
								height: '80px',
								backgroundColor: teal[ 800 ],
								lineHeight: '80px',
								textAlign: 'center',
								color: '#ffffff',
								fontSize: '24px',
							}}
						>
							User Management
						</Box>
						<CardContent>
							<Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
								<Link to="/clinic-settings/users">Users</Link>
							</Typography>

							<Typography variant="body2" color="text.secondary">
								<Link to="/clinic-settings/permissions">Permissions</Link>
							</Typography>
						</CardContent>
					</Card>
				</Grid>


				<Grid item xs={12} md={6} xl={4}>
					<Card raised sx={{ height: '200px' }}>
						<Box
							sx={{
								width: '100%',
								height: '80px',
								backgroundColor: pink[ 900 ],
								lineHeight: '80px',
								textAlign: 'center',
								color: '#ffffff',
								fontSize: '24px',
							}}
						>
							Billing
						</Box>
						<CardContent>
							<Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
								<Link to="/clinic-settings/billing">Billing Settings</Link>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>

		</DashboardCard>
	);
};

export default ClinicSettingsHome;
