export default [
	[
		"dictionary",
		{
			"#5": [
				"Gradient",
				[
					[
						[
							0.87843,
							0.87843,
							0.87843,
						],
						0,
					],
					[
						[
							0.94902,
							0.94902,
							0.94902,
						],
						0.5231,
					],
					[
						[
							0.87843,
							0.87843,
							0.87843,
						],
						1,
					],
				],
				false,
			],
			"#7": [
				"Gradient",
				[
					[
						[
							0.87843,
							0.87843,
							0.87843,
						],
						0,
					],
					[
						[
							0.94902,
							0.94902,
							0.94902,
						],
						0.5231,
					],
					[
						[
							0.87843,
							0.87843,
							0.87843,
						],
						1,
					],
				],
				false,
			],
		},
	],
	[
		"Group",
		{
			"name": "canine_occlusal",
			"applyMatrix": true,
			"children": [
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							1,
							0,
							0,
							1,
							303.35,
							736.2,
						],
						"clipMask": true,
						"type": "rectangle",
						"size": [
							606.7,
							1472.4,
						],
						"radius": [
							0,
							0,
						],
					},
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									538.3,
									568.5,
								],
								[
									4.7,
									63.2,
								],
								[
									-7.4,
									-97.3,
								],
							],
							[
								[
									487.1,
									366,
								],
								[
									34.3,
									52.2,
								],
								[
									-23.8,
									-36.2,
								],
							],
							[
								[
									457,
									173.7,
								],
								[
									-32,
									75.7,
								],
								[
									4.4,
									-10.4,
								],
							],
							[
								[
									437.9,
									119.8,
								],
								[
									9.6,
									7,
								],
								[
									-21.4,
									-15.6,
								],
							],
							[
								[
									303.4,
									24.5,
								],
								[
									111.2,
									2.1,
								],
								[
									0,
									0,
								],
							],
							[
								303.4,
								24.5,
							],
							[
								303.4,
								24.5,
							],
							[
								303.4,
								24.5,
							],
							[
								[
									303.4,
									24.5,
								],
								[
									0,
									0,
								],
								[
									-111.2,
									2,
								],
							],
							[
								[
									168.9,
									119.8,
								],
								[
									21.4,
									-15.7,
								],
								[
									-9.6,
									7,
								],
							],
							[
								[
									149.8,
									173.7,
								],
								[
									-4.4,
									-10.4,
								],
								[
									32,
									75.7,
								],
							],
							[
								[
									119.7,
									366,
								],
								[
									23.8,
									-36.2,
								],
								[
									-34.3,
									52.2,
								],
							],
							[
								[
									68.5,
									568.5,
								],
								[
									7.3,
									-97.3,
								],
								[
									-4.8,
									63.2,
								],
							],
							[
								[
									158.7,
									696.2,
								],
								[
									-38.2,
									-8.5,
								],
								[
									69.1,
									7.7,
								],
							],
							[
								[
									227.8,
									783.2,
								],
								[
									-2.3,
									-1.7,
								],
								[
									2.3,
									1.7,
								],
							],
							[
								[
									236.2,
									782.1,
								],
								[
									0,
									2.1,
								],
								[
									-0.6,
									-55.5,
								],
							],
							[
								[
									303.5,
									727.1,
								],
								[
									-2.7,
									0,
								],
								[
									0,
									0,
								],
							],
							[
								303.5,
								727.1,
							],
							[
								303.5,
								727.1,
							],
							[
								303.5,
								727.1,
							],
							[
								[
									303.5,
									727.1,
								],
								[
									0,
									0,
								],
								[
									2.7,
									0,
								],
							],
							[
								[
									370.8,
									782.1,
								],
								[
									0.6,
									-55.4,
								],
								[
									0,
									2.1,
								],
							],
							[
								[
									379.2,
									783.2,
								],
								[
									-2.3,
									1.7,
								],
								[
									2.3,
									-1.7,
								],
							],
							[
								[
									448.3,
									696.2,
								],
								[
									-69.1,
									7.7,
								],
								[
									38,
									-8.6,
								],
							],
						],
						"closed": true,
						"fillColor": [
							0.87843,
							0.87843,
							0.87843,
						],
						"strokeColor": [
							0,
							0,
							0,
						],
					},
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									516.5,
									831.3,
								],
								[
									20,
									83.6,
								],
								[
									-4.8,
									-20.1,
								],
							],
							[
								509,
								796,
							],
							[
								[
									467.4,
									801.5,
								],
								[
									7.6,
									-12,
								],
								[
									-7.6,
									12,
								],
							],
							[
								[
									451,
									952.7,
								],
								[
									24.4,
									-109.5,
								],
								[
									-13.4,
									60.4,
								],
							],
							[
								[
									376.7,
									1253.4,
								],
								[
									36.4,
									-80.2,
								],
								[
									-29.2,
									64.5,
								],
							],
							[
								[
									303.3,
									1335.6,
								],
								[
									1,
									-39.9,
								],
								[
									-1,
									-39.9,
								],
							],
							[
								[
									229.9,
									1253.4,
								],
								[
									29.2,
									64.5,
								],
								[
									-36.3,
									-80.2,
								],
							],
							[
								[
									155.6,
									952.7,
								],
								[
									13.4,
									60.4,
								],
								[
									-24.3,
									-109.5,
								],
							],
							[
								[
									139.2,
									801.5,
								],
								[
									7.6,
									12,
								],
								[
									-7.6,
									-12,
								],
							],
							[
								97.7,
								796,
							],
							[
								[
									90.2,
									831.3,
								],
								[
									4.8,
									-20.1,
								],
								[
									-20,
									83.6,
								],
							],
							[
								[
									156.8,
									1192.5,
								],
								[
									-51.8,
									-219.3,
								],
								[
									51.8,
									219.3,
								],
							],
							[
								[
									172.2,
									1368.4,
								],
								[
									0,
									-43,
								],
								[
									0,
									79.3,
								],
							],
							[
								[
									303.3,
									1449.2,
								],
								[
									-2.1,
									0,
								],
								[
									0,
									0,
								],
							],
							[
								303.3,
								1449.2,
							],
							[
								303.3,
								1449.2,
							],
							[
								303.3,
								1449.2,
							],
							[
								[
									303.3,
									1449.2,
								],
								[
									0,
									0,
								],
								[
									2.1,
									0,
								],
							],
							[
								[
									434.4,
									1368.4,
								],
								[
									0,
									79.2,
								],
								[
									0,
									-42.9,
								],
							],
							[
								[
									449.8,
									1192.5,
								],
								[
									-51.8,
									219.3,
								],
								[
									51.8,
									-219.3,
								],
							],
						],
						"closed": true,
						"fillColor": [
							0.87843,
							0.87843,
							0.87843,
						],
						"strokeColor": [
							0,
							0,
							0,
						],
					},
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								262.4,
								137.7,
							],
							[
								193.5,
								510.6,
							],
							[
								219.5,
								512,
							],
							[
								289.9,
								156,
							],
						],
						"fillColor": [
							"gradient",
							[
								"#5",
							],
							[
								226.1967,
								323.7664,
							],
							[
								256.1114,
								329.5812,
							],
						],
					},
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								345.5,
								137.7,
							],
							[
								414.4,
								510.6,
							],
							[
								388.4,
								512,
							],
							[
								318,
								156,
							],
						],
						"fillColor": [
							"gradient",
							[
								"#7",
							],
							[
								381.7033,
								323.7664,
							],
							[
								351.7887,
								329.5812,
							],
						],
					},
				],
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							0.9985,
							-0.0543,
							0.0543,
							0.9985,
							330.59178,
							148.39562,
						],
						"type": "ellipse",
						"size": [
							27.4,
							73.6,
						],
						"radius": [
							13.7,
							36.8,
						],
						"fillColor": [
							1,
							1,
							1,
						],
					},
				],
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							0.9985,
							-0.0543,
							0.0543,
							0.9985,
							400.19213,
							511.28815,
						],
						"type": "ellipse",
						"size": [
							26,
							18,
						],
						"radius": [
							13,
							9,
						],
						"fillColor": [
							1,
							1,
							1,
						],
					},
				],
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							1,
							0,
							0,
							1,
							398.9,
							596.7,
						],
						"type": "circle",
						"size": [
							5.2,
							5.2,
						],
						"radius": 2.6,
						"fillColor": [
							1,
							1,
							1,
						],
					},
				],
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							0.0543,
							-0.9985,
							0.9985,
							0.0543,
							276.10872,
							148.41993,
						],
						"type": "ellipse",
						"size": [
							73.6,
							27.4,
						],
						"radius": [
							36.8,
							13.7,
						],
						"fillColor": [
							1,
							1,
							1,
						],
					},
				],
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							0.0543,
							-0.9985,
							0.9985,
							0.0543,
							206.51015,
							511.25288,
						],
						"type": "ellipse",
						"size": [
							18,
							26,
						],
						"radius": [
							9,
							13,
						],
						"fillColor": [
							1,
							1,
							1,
						],
					},
				],
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							1,
							0,
							0,
							1,
							207.8,
							596.7,
						],
						"type": "circle",
						"size": [
							5.2,
							5.2,
						],
						"radius": 2.6,
						"fillColor": [
							1,
							1,
							1,
						],
					},
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								303.3,
								1335.5,
							],
							[
								306,
								1338,
							],
							[
								303.3,
								1339.7,
							],
							[
								304.6,
								1341.4,
							],
							[
								301.3,
								1346.7,
							],
							[
								306,
								1346.7,
							],
							[
								[
									306,
									1354,
								],
								[
									0.3,
									-3,
								],
								[
									-0.3,
									3,
								],
							],
							[
								303.3,
								1358.7,
							],
							[
								[
									303.3,
									1360.7,
								],
								[
									-0.3,
									-1.7,
								],
								[
									0.3,
									1.7,
								],
							],
							[
								306.1,
								1365.7,
							],
							[
								306.1,
								1369,
							],
							[
								303.7,
								1371,
							],
							[
								303.7,
								1373,
							],
							[
								306.1,
								1381,
							],
							[
								[
									301.3,
									1385,
								],
								[
									1,
									-1.3,
								],
								[
									-1,
									1.3,
								],
							],
							[
								303.3,
								1390,
							],
							[
								306.1,
								1392.3,
							],
							[
								303.3,
								1395.3,
							],
							[
								[
									301.1,
									1398.6,
								],
								[
									-0.5,
									-2.4,
								],
								[
									0.5,
									2.4,
								],
							],
							[
								303.6,
								1406.3,
							],
							[
								306.1,
								1408.6,
							],
							[
								303.6,
								1412.1,
							],
							[
								303.6,
								1416.9,
							],
							[
								301.1,
								1420.3,
							],
							[
								303.6,
								1423.7,
							],
							[
								303.3,
								1430.6,
							],
							[
								301,
								1433,
							],
							[
								303.3,
								1437.2,
							],
							[
								303.6,
								1439.8,
							],
							[
								301.1,
								1441.5,
							],
							[
								301.1,
								1444,
							],
							[
								303.4,
								1444,
							],
							[
								303.4,
								1449,
							],
						],
						"strokeColor": [
							0,
							0,
							0,
						],
					},
				],
			],
		},
	],

];

export const canineBoneConfig = {
	scaling: [ 1.53, 1.37 ],
	position: [ 550, 1100 ],
	opacity: 0.6,
};