import React, { useEffect, useState } from 'react';
import DashboardCard from "components/DashboardCard";
import { Add, Description } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from './Reports.redux';
import { CustomPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { push } from 'connected-react-router';
import ModalTemplateAddNew from './ModalTemplateAddNew'



const RowComponent = (props) => {
    const dispatch = useDispatch();

    return (
        <Table.Row
            {...props}
            onClick={() => dispatch(push(`/reports/edit-layout/${props.row.template_id}`))}
        />
    );
};


const TemplatesList = () => {

    const dispatch    = useDispatch(),
          loading     = useSelector(state => state.reports.loading),
          {
              rows,
              totalRows,
              lastQuery,
              pageSize,
              pageNumber,
          }           = useSelector(state => state.reports.templates),
          [ columns ] = useState([
              { name: 'template_name', title: 'Layout Name' },
              { name: 'date_created', title: 'Date Created' },
          ]);


    const setCurrentPage = (pageNum) => {
        if(loading) {
            return false;
        }

        dispatch(actions.setSubState('templates', { pageNumber: pageNum }));
        dispatch(actions.loadTemplates());
    };


    const setPageSize = (value) => {
        console.log(value);
        if(loading) {
            return false;
        }

        dispatch(actions.setSubState('templates', { pageSize: value, pageNumber: 0 }));
        dispatch(actions.loadTemplates());
    };


    useEffect(() => {
        if(lastQuery === '' && loading === false) {
            dispatch(actions.loadTemplates());
        }
    }, [ lastQuery, loading, dispatch ]);



    return (
        <DashboardCard
            color={'amber'}
            icon={<Description />}
            topContent={"Layouts"}
            topLabel={<div>
                Select template to edit, or &nbsp;
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Add />}
                    onClick={() => dispatch(actions.setState({ showNewTemplateModal: true }))}
                >
                    Add New
                </Button>
            </div>}
            size="xl"
        >
            <Grid
                rows={rows}
                columns={columns}
            >
                <PagingState
                    currentPage={pageNumber}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <CustomPaging
                    totalCount={totalRows}
                />
                <Table
                    rowComponent={RowComponent}
                />
                <TableHeaderRow />
                <PagingPanel
                    pageSizes={[ 5, 10, 20, 30 ]}
                />
            </Grid>

            <ModalTemplateAddNew />
        </DashboardCard>

    );
};


export default TemplatesList;
