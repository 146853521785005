import store from 'store';

// Canine
import canineBuccalPaths from '../paths/canine_buccal';
import canineBuccalLayout from './svglayout/layout_canine_buccal';
import canineBuccalPathsDeciduous from '../paths/canine_buccal_deciduous';
import canineBuccalLayoutDeciduous from './svglayout/layout_canine_buccal_deciduous';
import canineLingualPaths from '../paths/canine_lingualpalatal';
import canineLingualLayout from './svglayout/layout_canine_lingualpalatal';
import canineChartingConfig from './svglayout/layout_canine_charting_work';
import canineToothOrder from './ChartingView/canine_tooth_order';
import canineToothOrderDeciduous from './ChartingView/canine_tooth_order_deciduous';
import canineChartingOptions from './ChartingView/CanineToothChartOptions';
import canineOcclusalPaths from '../paths/canine_occlusal';
import canineOcclusalLayout from './svglayout/layout_canine_occlusal';
import canineOcclusalPathsDeciduous from '../paths/canine_occlusal_deciduous';
import canineOcclusalLayoutDeciduous from './svglayout/layout_canine_occlusal_deciduous';
import canineSoftTissue, {canineTissueConfig} from '../paths/canine_softtissue';
import canineBoneView, {canineBoneConfig} from '../paths/canine_boneview';

// Feline
import felineBuccalPaths from '../paths/feline_buccal';
import felineBuccalLayout from './svglayout/layout_feline_buccal';
import felineBuccalPathsDeciduous from '../paths/feline_buccal_deciduous';
import felineBuccalLayoutDeciduous from './svglayout/layout_feline_buccal_deciduous';
import felineLingualPaths from '../paths/feline_lingualpalatal';
import felineLingualLayout from './svglayout/layout_feline_lingualpalatal';
import felineChartingConfig from './svglayout/layout_feline_charting_work';
import felineChartingOptions from './ChartingView/FelineToothChartOptions';
import felineToothOrder from './ChartingView/feline_tooth_order';
import felineToothOrderDeciduous from './ChartingView/feline_tooth_order_deciduous';
import felineOcclusalPaths from '../paths/feline_occlusal';
import felineOcclusalPathsDeciduous from '../paths/feline_occlusal_deciduous';
import felineOcclusalLayout from './svglayout/layout_feline_occlusal';
import felineOcclusalLayoutDeciduous from './svglayout/layout_feline_occlusal_deciduous';
import felineSoftTissue, {felineTissueConfig} from '../paths/feline_softTissue';
import felineBoneView, {felineBoneConfig} from '../paths/feline_boneview';



let lastSpecies = null;
let lastData = null;

function getToothInfo(species) {
	let state = store.getState();
	if(!species) species = state.ActiveChart.Species;

	if(!species) return lastData;
	species = species.toLowerCase();

	if(lastSpecies === species && lastData) {
		return lastData;
	}

	let data = {
		buccalPaths: null,
		buccalLayout: null,
		buccalDeciduousPaths: null,
		buccalDeciduousLayout: null,
		softTissuePaths: null,
		softTissueConfig: null,
		lingualPaths: null,
		lingualLayout: null,
		occlusalPaths: null,
		occlusalLayout: null,
		occlusalDeciduousPaths: null,
		occlusalDeciduousLayout: null,
		chartingConfig: null,
		chartingOptions: null,
		order: null,
	};

	switch(species) {
		case 'canine':
			data = {
				buccalPaths: canineBuccalPaths,
				buccalLayout: canineBuccalLayout,
				buccalDeciduousPaths: canineBuccalPathsDeciduous,
				buccalDeciduousLayout: canineBuccalLayoutDeciduous,
				softTissuePaths: canineSoftTissue,
				softTissueConfig: canineTissueConfig,
				lingualPaths: canineLingualPaths,
				lingualLayout: canineLingualLayout,
				occlusalPaths: canineOcclusalPaths,
				occlusalLayout: canineOcclusalLayout,
				occlusalDeciduousPaths: canineOcclusalPathsDeciduous,
				occlusalDeciduousLayout: canineOcclusalLayoutDeciduous,
				chartingConfig: canineChartingConfig,
				chartingOptions: canineChartingOptions,
				order: canineToothOrder,
				orderDeciduous: canineToothOrderDeciduous,
				boneView: canineBoneView,
				boneConfig: canineBoneConfig,
			};
			break;

		case 'feline':
			data = {
				buccalPaths: felineBuccalPaths,
				buccalLayout: felineBuccalLayout,
				buccalDeciduousPaths: felineBuccalPathsDeciduous,
				buccalDeciduousLayout: felineBuccalLayoutDeciduous,
				softTissuePaths: felineSoftTissue,
				softTissueConfig: felineTissueConfig,
				lingualPaths: felineLingualPaths,
				lingualLayout: felineLingualLayout,
				occlusalPaths: felineOcclusalPaths,
				occlusalLayout: felineOcclusalLayout,
				occlusalDeciduousPaths: felineOcclusalPathsDeciduous,
				occlusalDeciduousLayout: felineOcclusalLayoutDeciduous,
				chartingConfig: felineChartingConfig,
				chartingOptions: felineChartingOptions,
				order: felineToothOrder,
				orderDeciduous: felineToothOrderDeciduous,
				boneView: felineBoneView,
				boneConfig: felineBoneConfig,
			};
			break;


		default:
			data = null;
	}


	lastSpecies = species;
	lastData = data;

	return lastData;
}



export default getToothInfo;