import React, { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { actions } from '../../Chart.redux';



const SymbolInputInteger = ({ inputConf }) => {
    const dispatch = useDispatch(),
          value    = useSelector(state => state.chart.symbolHandlerData[inputConf.valueName]);

    const handleSetValue = useCallback(e => {
        let newValue = parseInt(e.target.value);

        if(isNaN(newValue)) {
            newValue = value;
        }

        if(typeof newValue === 'number') {
            if(newValue > inputConf.maxNumber) {
                newValue = inputConf.maxNumber;
            }

            if(newValue < inputConf.minNumber) {
                newValue = inputConf.minNumber;
            }
        }

        dispatch(actions.setSymbolHandlerValue(inputConf.valueName, newValue));
    }, [ dispatch, inputConf ]);


    return (
        <TextField
            fullWidth
            value={String(value)}
            label={inputConf.label}
            onChange={handleSetValue}
            type="number"
            autoFocus
        />
    );
};


export default SymbolInputInteger;