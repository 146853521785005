import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ClientAddressesEditButton from './ClientAddressesEditButton';



const ClientAddressesCell = (props) => {
	const id = props.tableRow.rowId;

	switch(props.column.name) {
		case 'address_edit':
			return <ClientAddressesEditButton id={id} />;
		default:
			return <Table.Cell {...props} />;
	}
};


export default ClientAddressesCell;