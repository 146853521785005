import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from './UserManagement.redux';



const UserInviteModal = () => {
	const dispatch = useDispatch(),
		  open     = useSelector(state => state.users.showInviteModal),
		  email    = useSelector(state => state.users.inviteEmail),
		  error    = useSelector(state => state.users.inviteError),
		  loading  = useSelector(state => state.users.inviteLoading);

	const handleEmailChange = e => dispatch(actions.setState({ inviteEmail: e.target.value }));

	return (
		<Dialog open={open}>
			<DialogTitle>
				Invite User
			</DialogTitle>


			<DialogContent>
				<div>
					Enter an email address below to invite them to your clinic. The email address entered must
					already have an account at Sabertooth Charting. The invitation will be valid for 7 days, after
					which point it must be re-sent.
				</div>

				<div>
					<TextField
						variant="outlined"
						label="Email Address To Invite"
						disabled={loading}
						value={email}
						onChange={handleEmailChange}
					/>
				</div>

				{error !== null &&
				<div>
					{error}
				</div>}
			</DialogContent>


			<DialogActions>
				<Button
					variant="contained"
					color="primary"
					onClick={() => dispatch(actions.inviteUser(email))}
					disabled={loading}
				>
					Invite
				</Button>


				<Button
					variant="outlined"
					color="secondary"
					onClick={() => dispatch(actions.resetInvite())}
					disabled={loading}
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};


export default UserInviteModal;
