import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import menuTheme from 'styles/theme_menu';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { AccountCircle, Description, ExitToApp, Home, Person, Pets, SettingsApplications } from '@mui/icons-material';
import { actions } from "store";
import { useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import useStyles from './Navigation.styles';



const Navigation = () => {
	const classes            = useStyles(),
		  dispatch           = useDispatch(),
		  [
			  clinicSettingsOpen,
			  setClinicSettingsOpen,
		  ]                  = useState(false),
		  menuOpenTemporary  = () => dispatch(actions.layout.menuOpenTemporary()),
		  menuCloseTemporary = () => dispatch(actions.layout.menuCloseTemporary()),
		  pushUrl            = url => dispatch(push(url));

	const setRoute = (url) => e => {
		pushUrl(url);
	};


	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={menuTheme}>
				<div
					className={classes.root}
					onMouseEnter={menuOpenTemporary}
					onMouseLeave={menuCloseTemporary}
				>
					<List>
						<ListItem component={Link} to="/" className={classes.link}>
							<ListItemIcon><Home style={{ color: '#a7a6ff' }} /></ListItemIcon>
							<ListItemText primary={'Home'} />
						</ListItem>

						<ListItem component={Link} to="/clients" className={classes.link}>
							<ListItemIcon><Person style={{ color: '#68cbff' }} /></ListItemIcon>
							<ListItemText primary={'Clients'} />
						</ListItem>

						<ListItem component={Link} to="/patients" className={classes.link}>
							<ListItemIcon><Pets style={{ color: '#83ff6b' }} /></ListItemIcon>
							<ListItemText primary={'Patients'} />
						</ListItem>

						<ListItem component={Link} to="/reports" className={classes.link}>
							<ListItemIcon><Description style={{ color: '#ffeb37' }} /></ListItemIcon>
							<ListItemText primary={'Reports'} />
						</ListItem>

						<Divider />


						<ListItem component={Link} to="/account" className={classes.link}>
							<ListItemIcon><AccountCircle /></ListItemIcon>
							<ListItemText primary={'My Account'} />
						</ListItem>


						<ListItem component={Link} to="/clinic-settings" className={classes.link}>
							<ListItemIcon>
								<SettingsApplications style={{ color: '#ffa749' }} />
							</ListItemIcon>
							<ListItemText primary="Clinic Settings" />
						</ListItem>


						<Divider />


						<ListItem
							component={Link}
							to="/logout"
							className={classes.link}
						>
							<ListItemIcon><ExitToApp style={{ color: '#ff7070' }} /></ListItemIcon>
							<ListItemText primary={'Log Out'} />
						</ListItem>

						{/*
                            <ListItem button onClick={setRoute('/_symboltest')}>
                                <ListItemIcon><ExitToApp style={{ color: '#ffffff' }} /></ListItemIcon>
                                <ListItemText primary={'Symbol Test'} />
                            </ListItem>
                        */}
					</List>
				</div>
			</ThemeProvider>
		</StyledEngineProvider>
	);

};



export default Navigation;
