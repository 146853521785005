import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		//minHeight: 64,
	},
	grow: {
		flexGrow: 1,
	},
	closeButton: {
		marginLeft: 0,
		marginRight: 20,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		//minHeight: 56,
	},
	content: {
		flex: 1,
		flexGrow: 1,
		padding: theme.spacing(2),
		overflowY: 'auto',
	},
	fullHeight: {
		height: '100%',
	},
}));



export default useStyles;
