import React, { useCallback, useRef } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	Paper,
	Select,
} from '@mui/material';
import { Clear, Delete, Save } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../ClientView.redux';
import useStyles from './ClientContactMethods.styles';
import { useParams } from "react-router-dom";



const ClientContactMethodsEditModal = () => {
	const dispatch      = useDispatch(),
		  open          = useSelector(state => (state.clientView.displayModal === 'contactMethods')),
		  data          = useSelector(state => state.clientView.contactMethodsEditData),
		  errors        = useSelector(state => state.clientView.contactMethodsEditErrors),
		  loading       = useSelector(state => state.clientView.contactMethodsEditLoading),
		  handleClose   = () => dispatch(actions.closeModal()),
		  isNew         = data.cm_id === null,
		  formRef       = useRef(),
		  { client_id } = useParams(),
		  classes       = useStyles();


	const updateData = useCallback((event) => {
		dispatch(actions.editContactMethodData(event.target.name, event.target.value));
	}, [ dispatch ]);


	const handleSubmit = useCallback((event) => {
		event.preventDefault();
		dispatch(actions.submitContactMethod({
			...data,
			client_id: client_id,
		}));
	}, [ dispatch, data, client_id ]);


	return (
		<Dialog
			open={open}
		>
			<DialogTitle>{isNew ? 'New Contact Method' : 'Edit Contact Method'}</DialogTitle>

			<form ref={formRef} method="post" onSubmit={handleSubmit}>
				<DialogContent>
					<FormControl fullWidth margin="normal">
						<InputLabel>Type</InputLabel>
						<Select
							value={data.cm_type}
							onChange={updateData}
							name="cm_type"
						>
							<MenuItem value={''}>Select One</MenuItem>
							<MenuItem value={'Phone'}>Phone</MenuItem>
							<MenuItem value={'Mobile'}>Mobile</MenuItem>
							<MenuItem value={'Fax'}>Fax</MenuItem>
							<MenuItem value={'Email'}>Email</MenuItem>
							<MenuItem value={'Other'}>Other</MenuItem>
						</Select>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>Name/Label</InputLabel>
						<Input
							type="text"
							placeholder="Name/Label"
							name="cm_name"
							onChange={updateData}
							value={data.cm_name}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>Value (Phone number, email address, etc)</InputLabel>
						<Input
							type="text"
							placeholder="Value (Phone number, email address, etc)"
							name="cm_value"
							onChange={updateData}
							value={data.cm_value}
						/>
					</FormControl>


					<FormControl fullWidth margin="normal">
						<InputLabel>Note</InputLabel>
						<Input
							type="text"
							placeholder="Note"
							name="cm_note"
							onChange={updateData}
							value={data.cm_note}
						/>
					</FormControl>

					{errors.map((err, index) => (
						<Paper key={index} variant="outlined" elevation={2} className={classes.errorContainer}>
							{err}
						</Paper>
					))}
				</DialogContent>

				<DialogActions>
					<Button
						startIcon={<Save />}
						variant="contained"
						color="primary"
						type="submit"
						disabled={loading}
					>
						Save
					</Button>

					{data.cm_id !== null &&
					<Button
						startIcon={<Delete />}
						variant="contained"
						color="secondary"
						disabled={loading}
					>
						Delete
					</Button>
					}

					<Button
						startIcon={<Clear />}
						variant="outlined"
						onClick={handleClose}
						disabled={loading}
					>
						Cancel
					</Button>


				</DialogActions>
			</form>
		</Dialog>
	);

};


export default ClientContactMethodsEditModal;