import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';



const useStyles = makeStyles(theme => ({
	dropZone: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: 20,
		borderWidth: 2,
		borderRadius: 8,
		borderColor: '#c4c4c4',
		borderStyle: 'dashed',
		backgroundColor: '#f5f5f5',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .25s ease-in-out',
		height: 300,
		'&:hover': {
			borderColor: theme.palette.primary[ 500 ],
		},
	},
	dropZoneText: {
		color: '#313131',
	},
	preview: {
		maxHeight: 350,
		maxWidth: '100%',
		margin: '16px auto',
		display: 'block',
		borderRadius: 8,
	},
	gridRoot: {
		padding: 8,
	},
	card: {
		margin: '16px auto 16px auto',
		width: '100%',
		background: theme.palette.grey[ 100 ],
	},
	icon: {
		marginRight: theme.spacing(2),
	},
	uploadButton: {
		background: green[ 'A100' ],
		marginTop: theme.spacing(2),
		'&:hover': {
			background: green[ 'A200' ],
		},
	},
}));



export default useStyles;
