import React from 'react';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../CustomCodes.redux';



const SelectCategory = () => {
	const dispatch = useDispatch(),
		  value    = useSelector(state => state.customCodes.category),
		  step     = useSelector(state => state.customCodes.step);

	const handleChange = (e) => {
		dispatch(actions.setState({ category: e.target.value }));
	};

	const handleNext = () => {
		dispatch(actions.setState({ step: step + 1 }));
	};


	return (
		<div>
			<div>
				<FormControl component="fieldset">
					<RadioGroup name="codeType" value={value} onChange={handleChange}>
						<FormControlLabel value="dx" control={<Radio />} label="Diagnostic" />
						<FormControlLabel value="tx" control={<Radio />} label="Treatment" />
						<FormControlLabel value="other" control={<Radio />} label="Other" />
					</RadioGroup>
				</FormControl>
			</div>
			<div>
				<Button
					variant="contained"
					disabled={value === null}
					color="primary"
					onClick={handleNext}
				>
					Next
				</Button>
			</div>
		</div>
	);
};


export default SelectCategory;