import React, { useRef, useState } from 'react';
import useStyles from './ForgotPassword.styles';
import { Button, Card, FormControl, Grid, Hidden, Input, Link, Typography } from "@mui/material";
import { EnhancedEncryptionOutlined } from "@mui/icons-material";
import { Link as RouterLink } from 'react-router-dom';



const ForgotPassword = () => {
	const classes = useStyles(),
		  formRef = useRef(null);
	const [ email, setEmail ]               = useState(''),
		  [ submitted, setSubmitted ]       = useState(false);


	const handleChangeEmail = e => {
		setEmail(e.target.value);
	};


	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitted(true);
	};

	return (
        <div className={classes.root}>
			<div className={classes.child}>
				<Grid container spacing={0} className={classes.fullHeightWidth}>
					<Hidden mdDown>
						<Grid item md={5} className={classes.leftCardWrapper}>
							<Card className={classes.leftCard} raised>
								<div className={classes.leftCardInner}>
									<div>
										<EnhancedEncryptionOutlined className={classes.leftCardIcon} />
									</div>
									<div>
										<Typography variant="h4" className={classes.marginBottom}>Forgot
											Password</Typography>
									</div>
									<div>
										<Typography>
											Hey, it happens to the best of us. Enter your email address in the form
											to the right and we'll send you an email to reset your password so you can
											get back up and running. If you want to get back to the homepage, click
											<Link
												component={RouterLink}
												to="/"
												color="inherit"
											> here</Link>.
										</Typography>
									</div>

								</div>
							</Card>
						</Grid>
					</Hidden>


					<Grid item xs={12} md={7} className={classes.rightCardWrapper}>
						<Card className={classes.rightCard} raised>
							{!submitted && <>
								<Hidden mdUp>
									<div>
										<Typography>
											Hey, it happens to the best of us. Enter your email address in the form and
											we'll send you a link to reset your password so you can get back up and
											running. If you want to get back to the homepage, click
											<Link
												component={RouterLink}
												to="/"
												color="inherit"
											> here</Link>.
										</Typography>
									</div>
								</Hidden>
								<form onSubmit={handleSubmit} ref={formRef}>

									<FormControl fullWidth margin="normal">
										<Input
											type="text"
											placeholder="Email Address"
											onChange={handleChangeEmail}
											value={email}
											className={classes.inputs}
										/>
									</FormControl>

									<FormControl fullWidth margin="normal">

										<Button
											variant="contained"
											color="primary"
											className={classes.inputs}
											type="submit"
										>
											Submit
										</Button>
									</FormControl>
								</form>
							</>}

							{submitted &&
							<>
								<Typography className={classes.marginBottom}>
									We've received your request. For security purposes, we can't confirm that the
									email address you entered does or does not have an account, but if it does, you'll
									receive an email with instructions on resetting your password.
								</Typography>

								<Typography>
									If you want to get back to the homepage, click <Link
										component={RouterLink}
										to="/"
										color="inherit"
									>
										here
									</Link>.
								</Typography>
							</>
							}

						</Card>
					</Grid>
				</Grid>
			</div>
		</div>
    );

};


export default ForgotPassword;