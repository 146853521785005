import React, { useCallback } from 'react';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../PatientView.redux';



const DateOfBirth = () => {
	const dispatch = useDispatch(),
		  dob      = useSelector(state => (state.patientView.profile.date_of_birth || ''));


	const setProfileValue = useCallback((e) => {
		const { value } = e.target;
		console.log('value', value);
		dispatch(actions.setProfileValue('date_of_birth', value));
	}, [ dispatch ]);

	return (
		<TextField
			type="date"
			value={dob}
			onChange={setProfileValue}
			fullWidth
		/>
	);
};


export default DateOfBirth;
