import React, { useEffect, useState } from 'react';
import DashboardCard from "components/DashboardCard";
import { Add, Description } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from './Reports.redux';
import { CustomPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { push } from 'connected-react-router';
import ModalContentBlockAddNew from './ModalContentBlockAddNew';



const RowComponent = (props) => {
    const dispatch = useDispatch();

    return (
        <Table.Row
            {...props}
            onClick={() => dispatch(push(`/reports/edit-content-block/${props.row.string_id}`))}
        />
    );
};


const CellComponent = (props) => {
    switch(props.column.name) {
        case 'string_advanced':
            return <Table.Cell {...props}>{props.value ? 'Advanced' : 'Simple'}</Table.Cell>;

        default:
            return <Table.Cell {...props} />;
    }
};

const ContentBlocksList = () => {
    const dispatch    = useDispatch(),
          loading     = useSelector(state => state.reports.loading),
          {
              rows,
              totalRows,
              pageSize,
              pageNumber,
          }           = useSelector(state => state.reports.contentBlocks),
          [ columns ] = useState([
              { name: 'string_name', title: 'Content Block Title' },
              { name: 'autofill_shortcut', title: 'Autofill Shortcut' },
              { name: 'date_created', title: 'Created' },
              { name: 'date_updated', title: 'Updated' },
          ]);


    const setCurrentPage = (pageNum) => {
        if(loading) {
            return false;
        }

        dispatch(actions.setSubState('contentBlocks', { pageNumber: pageNum }));
        dispatch(actions.loadContentBlocks());
    };


    const setPageSize = (value) => {
        console.log(value);
        if(loading) {
            return false;
        }

        dispatch(actions.setSubState('contentBlocks', { pageSize: value, pageNumber: 0 }));
        dispatch(actions.loadContentBlocks());
    };


    useEffect(() => {
        if(loading === false) {
            dispatch(actions.loadContentBlocks());
        }
    }, [ loading, dispatch ]);

    return (
        <DashboardCard
            color={'amber'}
            icon={<Description />}
            topContent={"Content Blocks"}
            topLabel={<div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Add />}
                    onClick={() => dispatch(actions.setState({ showNewContentBlockModal: true }))}
                >
                    Add New
                </Button>
            </div>}
            size="xl"
        >
            <Grid
                rows={rows}
                columns={columns}
            >
                <PagingState
                    currentPage={pageNumber}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <CustomPaging
                    totalCount={totalRows}
                />
                <Table
                    rowComponent={RowComponent}
                    cellComponent={CellComponent}
                />
                <TableHeaderRow />
                <PagingPanel
                    pageSizes={[ 5, 10, 20, 30 ]}
                />
            </Grid>

            <ModalContentBlockAddNew />
        </DashboardCard>

    );
};


export default ContentBlocksList;
