module.exports = {
	"d501": {
		"paths": [
			"M272.6,142.3l-7-14.6c0,0-0.2-9.9,1.1-12.2c1.4-2.3,4.3-5.4,8.6-5.2c4.3,0.2,10.6-1.8,14.2,6.8     c3.6,8.6,1.8,11-0.7,15.5c-4.1,8.8-4.1,8.8-4.1,8.8L272.6,142.3z"
		]
	},
	"d502": {
		"paths": [
			"M228.7,144.1c0,0-9.7-18.2-6.8-27.9c19.6-2.9,23.4-4.1,28.6-0.7c5.2,3.4,2.3,11.3,0.2,16.2c-2,5-7,14.6-14,14     C229.8,145,228.7,144.1,228.7,144.1z"
		]
	},
	"d503": {
		"paths": [
			"M178.7,137.1l2.5,9.7c0,0,0.7,9.2,13.5,8.1c12.8-1.1,11,0.2,15.3-5.6c4.3-5.9,5.4-6.5,3.4-13.1     c-2-6.5-1.6-5.6-2.9-9.5c-1.4-3.8-3.8-9.2-12.2-8.1c-8.3,1.1-13.7,4.1-16.7,7C178.7,128.5,175.8,130.8,178.7,137.1z"
		]
	},
	"d504": {
		"paths": [
			"M99.4,201.8c-1.5-33.3,1.9-56.3,4.1-63.1c2.3-7.2,15.8-36.7,22.3-37.2c6.1-0.4,8.8,38.7,10.7,47.8     c2.5,12,7,39,14.3,57.2c7.2,18,12.8,53.2-17.1,51.6c-23.4-1.3-27.3-24.6-28.8-30.6C103.2,221.4,99.6,206.7,99.4,201.8z"
		]
	},
	"d506": {
		"paths": [
			"M123.5,336.9c0,0-13.3-3.6-9-14.4c0-11.5,8.8-18,16.7-18c7.9,0,22.1,2.7,23,11.5c0.9,8.8-4.7,15.5-11,19.8     C136.8,340.1,123.5,336.9,123.5,336.9z",
			"M141.5,306.1c0,0,3.8,7.4,2.5,10.6c3.4-6.8,2.3-8.3,1.6-9.2C144.9,306.5,141.5,306.1,141.5,306.1z",
			"M129.4,337.4c0,0,8.3-2.9,9.9-6.5c-1.4,5.9-4.3,7.9-4.3,7.9L129.4,337.4z"
		]
	},
	"d507": {
		"paths": [
			"M28.7,609.8c8.6,4.5,15.1,11.7,33.3-11.7c18.2-23.4,23.7-45.5,28.8-56.3c5.2-10.8,9.5-17.1,19.2-21.4     c9.7-4.3,9-12.2,7.7-15.8c-1.4-3.6-5-11.3-28.4-9.2c-8.1-10.6-22.5-17.8-29.3-9.2s-7.9,18-8.1,20.1c-2,2-18.7,34.8-21.4,46.5     S15.4,602.8,28.7,609.8z",
			"M97.4,529.3c0,0-8.7-12-12-12.9c9.3,3.9,11.1,9.6,16.5,9.6C98.3,528.7,97.4,529.3,97.4,529.3z",
			"M52.3,503.1c0,0,7,5.6,22.5,7.7c-16.4-0.1-22.6-2.5-24.7-0.9C51.7,505.2,52.3,503.1,52.3,503.1z",
			"M87.3,493.5l1.9,1.8l3.3-0.5c0,0-4.8,4.2-5.7,8.2C87.8,495.6,88.1,496,87.3,493.5z",
			"M42.7,559.8c0,0,1.1,6.5-6.4,13.9c15.8-2.4,20.8-0.6,23,2.6c0-3.9,1.7-4.5,1.7-4.5S46.6,570.1,42.7,559.8z"
		]
	},
	"d508": {
		"paths": [
			"M40.2,643.2c1,6.6,14.7,11.2,19.9,10c5.3-1.2,6.4-2.5,12-3.7c5.6-1.2,16.6-7.4,11.6-13.4     c-3.2-6.2-14-3.3-17.6-4.9c-3.6-1.5-9.8-0.5-12.2,1.2c-2.4,1.7-5.9,2.9-8.6,4C42.6,637.4,39.4,638.2,40.2,643.2z"
		]
	},
	"d601": {
		"paths": [
			"M334.1,142.3l7-14.6c0,0,0.2-9.9-1.1-12.2s-4.3-5.4-8.6-5.2c-4.3,0.2-10.6-1.8-14.2,6.8     c-3.6,8.6-1.8,11,0.7,15.5c4.1,8.8,4.1,8.8,4.1,8.8L334.1,142.3z"
		]
	},
	"d602": {
		"paths": [
			"M378,144.1c0,0,9.7-18.2,6.8-27.9c-19.6-2.9-23.4-4.1-28.6-0.7c-5.2,3.4-2.3,11.3-0.2,16.2c2,5,7,14.6,14,14     C376.9,145,378,144.1,378,144.1z"
		]
	},
	"d603": {
		"paths": [
			"M428,137.1l-2.5,9.7c0,0-0.7,9.2-13.5,8.1c-12.8-1.1-11,0.2-15.3-5.6c-4.3-5.9-5.4-6.5-3.4-13.1     c2-6.5,1.6-5.6,2.9-9.5c1.4-3.8,3.8-9.2,12.2-8.1c8.3,1.1,13.7,4.1,16.7,7C428,128.5,431,130.8,428,137.1z"
		]
	},
	"d604": {
		"paths": [
			"M507.3,201.8c1.5-33.3-1.9-56.3-4.1-63.1c-2.3-7.2-15.8-36.7-22.3-37.2c-6.1-0.4-8.8,38.7-10.7,47.8     c-2.5,12-7,39-14.3,57.2c-7.2,18-12.8,53.2,17.1,51.6c23.4-1.3,27.3-24.6,28.8-30.6C503.5,221.4,507.1,206.7,507.3,201.8z"
		]
	},
	"d606": {
		"paths": [
			"M483.2,336.9c0,0,13.3-3.6,9-14.4c0-11.5-8.8-18-16.7-18c-7.9,0-22.1,2.7-23,11.5c-0.9,8.8,4.7,15.5,11,19.8     C469.9,340.1,483.2,336.9,483.2,336.9z",
			"M465.2,306.1c0,0-3.8,7.4-2.5,10.6c-3.4-6.8-2.3-8.3-1.6-9.2C461.8,306.5,465.2,306.1,465.2,306.1z",
			"M477.4,337.4c0,0-8.3-2.9-9.9-6.5c1.4,5.9,4.3,7.9,4.3,7.9L477.4,337.4z"
		]
	},
	"d607": {
		"paths": [
			"M578.1,609.8c-8.6,4.5-15.1,11.7-33.3-11.7c-18.2-23.4-23.7-45.5-28.8-56.3c-5.2-10.8-9.5-17.1-19.2-21.4     c-9.7-4.3-9-12.2-7.7-15.8c1.4-3.6,5-11.3,28.4-9.2c8.1-10.6,22.5-17.8,29.3-9.2s7.9,18,8.1,20.1c2,2,18.7,34.8,21.4,46.5     S591.4,602.8,578.1,609.8z",
			"M509.3,529.3c0,0,8.7-12,12-12.9c-9.3,3.9-11.1,9.6-16.5,9.6C508.4,528.7,509.3,529.3,509.3,529.3z",
			"M554.4,503.1c0,0-7,5.6-22.5,7.7c16.4-0.1,22.6-2.5,24.7-0.9C555,505.2,554.4,503.1,554.4,503.1z",
			"M519.4,493.5l-1.9,1.8l-3.3-0.5c0,0,4.8,4.2,5.7,8.2C518.9,495.6,518.6,496,519.4,493.5z",
			"M564.1,559.8c0,0-1.1,6.5,6.4,13.9c-15.8-2.4-20.8-0.6-23,2.6c0-3.9-1.7-4.5-1.7-4.5S560.1,570.1,564.1,559.8     z"
		]
	},
	"d608": {
		"paths": [
			"M566.5,643.2c-1,6.6-14.7,11.2-19.9,10c-5.3-1.2-6.4-2.5-12-3.7C529,648.2,518,642,523,636     c3.2-6.2,14-3.3,17.6-4.9c3.6-1.5,9.8-0.5,12.2,1.2c2.4,1.7,5.9,2.9,8.6,4C564.1,637.4,567.3,638.2,566.5,643.2z"
		]
	},
	"d701": {
		"paths": [
			"M330.6,1344.2c-9.6-0.6-9.6-0.6-9.6-0.6c-4.8,12-7.2,7.8-6,15c1.2,7.2-2.4,6.6,6,10.2s9.6,1.8,12-1.2     C335.4,1364.7,338.4,1356.9,330.6,1344.2z"
		]
	},
	"d702": {
		"paths": [
			"M379.8,1364.1c-10.8,2.4-23.4,5.4-24.6-0.6c-1.2-6,2.4-20.4,7.2-22.2c4.8-1.8,10.2-2.4,11.4,0     C375,1343.6,382.8,1359.9,379.8,1364.1z"
		]
	},
	"d703": {
		"paths": [
			"M423.7,1356.9c-1.5,4.6-21,6.6-24.6,0.6c-3.6-6-3.6-13.2-1.2-18c2.4-4.8,7.8-7.8,10.8-7.8s16.2,1.2,16.2,6     C424.9,1342.4,425.5,1351.5,423.7,1356.9z"
		]
	},
	"d704": {
		"paths": [
			"M488,1287.8c2.1,4.5-6,6,6,11.4c12,5.4,33.6,4.8,36,13.8c3.3,12.2-18,28.6-31.2,33.4s-24.9,5.3-39.7-2.8     c-10.5-5.7-21-24.6-20.4-34.2c0.6-9.6,6.6-27,13.8-29.4C459.7,1277.6,477.7,1266.1,488,1287.8z"
		]
	},
	"d707": {
		"paths": [
			"M499.4,1044.4c-11-4.9-14.4-16.2-12.6-26.4c1.8-10.2,7.8-15.6,10.2-30s8.4-35.4,18-43.3     c9.6-7.8,28.2-5.4,33.6,1.2c5.4,6.6,11.4,19.2,4.8,34.8c-6.6,15.6-10.2,30-18,36C531.2,1038.4,515.6,1051.7,499.4,1044.4z",
			"M545.1,973.9c-10.2,0-15.6-1.8-19.8-4.8c0.6,5.4-1.8,6-2.4,6.6c10.8-0.6,18-0.6,21.6,3.6     C542.9,975.2,545.1,973.9,545.1,973.9z",
			"M520.6,1026.1c-2,0.7-1.8,2.9-1.8,2.9s-16.7-1.8-20.1-8.8c1.6-1.4,1.8-2.9,1.8-2.9     C503.5,1023.6,508,1028.6,520.6,1026.1z"
		]
	},
	"d708": {
		"paths": [
			"M536,871.1c-14.4,17.4-23.4,35.4-15.6,45.7s19.8,15.6,33.6,9.6s18-23.4,22.8-31.2s26.4-43.9,21-65.5     c-5.4-21.6-16.8-25.2-30-15.6c-13.2,9.6-15,15-18,29.4S536,871.1,536,871.1z",
			"M550.1,866.3c-1.8,6.9-9,14.7-9,14.7c7-1.4,18.2-4.5,23.1,2.7c0.3-3,0.7-3,4.5-4.2     C556.9,881.5,555.2,875.3,550.1,866.3z"
		]
	},
	"d801": {
		"paths": [
			"M276.2,1344.2c9.6-0.6,9.6-0.6,9.6-0.6c4.8,12,7.2,7.8,6,15s2.4,6.6-6,10.2s-9.6,1.8-12-1.2     C271.4,1364.7,268.4,1356.9,276.2,1344.2z"
		]
	},
	"d802": {
		"paths": [
			"M226.9,1364.1c10.8,2.4,23.4,5.4,24.6-0.6c1.2-6-2.4-20.4-7.2-22.2c-4.8-1.8-10.2-2.4-11.4,0     C231.7,1343.6,223.9,1359.9,226.9,1364.1z"
		]
	},
	"d803": {
		"paths": [
			"M183.1,1356.9c1.5,4.6,21,6.6,24.6,0.6c3.6-6,3.6-13.2,1.2-18c-2.4-4.8-7.8-7.8-10.8-7.8     c-3,0-16.2,1.2-16.2,6C181.9,1342.4,181.3,1351.5,183.1,1356.9z"
		]
	},
	"d804": {
		"paths": [
			"M118.8,1287.8c-2.1,4.5,6,6-6,11.4s-33.6,4.8-36,13.8c-3.3,12.2,18,28.6,31.2,33.4s24.9,5.3,39.7-2.8     c10.5-5.7,21-24.6,20.4-34.2c-0.6-9.6-6.6-27-13.8-29.4C147,1277.6,129,1266.1,118.8,1287.8z"
		]
	},
	"d807": {
		"paths": [
			"M107.4,1044.4c11-4.9,14.4-16.2,12.6-26.4c-1.8-10.2-7.8-15.6-10.2-30s-8.4-35.4-18-43.3     c-9.6-7.8-28.2-5.4-33.6,1.2s-11.4,19.2-4.8,34.8c6.6,15.6,10.2,30,18,36C75.5,1038.4,91.1,1051.7,107.4,1044.4z",
			"M61.6,973.9c10.2,0,15.6-1.8,19.8-4.8c-0.6,5.4,1.8,6,2.4,6.6c-10.8-0.6-18-0.6-21.6,3.6     C63.8,975.2,61.6,973.9,61.6,973.9z",
			"M86.1,1026.1c2,0.7,1.8,2.9,1.8,2.9s16.7-1.8,20.1-8.8c-1.6-1.4-1.8-2.9-1.8-2.9     C103.2,1023.6,98.7,1028.6,86.1,1026.1z"
		]
	},
	"d808": {
		"paths": [
			"M70.7,871.1c14.4,17.4,23.4,35.4,15.6,45.7s-19.8,15.6-33.6,9.6s-18-23.4-22.8-31.2s-26.4-43.9-21-65.5     c5.4-21.6,16.8-25.2,30-15.6c13.2,9.6,15,15,18,29.4C59.9,857.9,70.7,871.1,70.7,871.1z",
			"M56.6,866.3c1.8,6.9,9,14.7,9,14.7c-7-1.4-18.2-4.5-23.1,2.7c-0.3-3-0.7-3-4.5-4.2     C49.8,881.5,51.5,875.3,56.6,866.3z"
		]
	}
};