import React, { useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Description } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import ClientReportTableRow from "./ClientReportTableRow";
import { actions } from '../ClientView.redux';



const columns = [
	{ name: 'patient_name', title: 'Patient' },
	{ name: 'date_updated', title: 'Update Date' },
	{ name: 'date_created', title: 'Create Date' },
	{ name: 'urpt_name', title: 'Report Name' },
	{ name: 'template_name', title: 'Template' },
	{ name: 'created_by', title: 'Created By' },
	{ name: 'updated_by', title: 'Updated by' },
];


const ClientReports = () => {
	const dispatch      = useDispatch(),
		  { client_id } = useParams(),
		  hasLoaded     = useSelector(state => state.clientView.reportsPreviouslyLoaded),
		  loading       = useSelector(state => state.clientView.reportsLoading),
		  reports       = useSelector(state => state.clientView.reports),
		  loadReports   = (clientID) => dispatch(actions.loadReports(clientID));


	useEffect(() => {
		if(!hasLoaded && !loading) {
			loadReports(client_id);
		}

	}, [ loadReports, client_id, hasLoaded, loading ]);


	return (
		<DashboardCard
			color={'amber'}
			icon={<Description />}
			topContent={"Reports"}
			topLabel="Click a report to view or edit"
			size="xl"
		>
			<Grid
				rows={reports}
				columns={columns}
			>
				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table rowComponent={ClientReportTableRow} />
				<TableHeaderRow />
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>
		</DashboardCard>
	);
};


export default ClientReports;