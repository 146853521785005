import React, { useCallback } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { push } from 'connected-react-router';
import { useDispatch } from "react-redux";



const PatientChartsTableRow = (props) => {
	const id = props.row.visit_id,
		  dispatch = useDispatch();

	const handleNavigate = useCallback(() => {
		dispatch(push(`/chart/${id}`));
	}, [ dispatch, id ]);


	return (
		<Table.Row
			{...props}
			style={{
				cursor: 'pointer',
			}}
			onClick={handleNavigate}
		/>
	);
};


export default PatientChartsTableRow;