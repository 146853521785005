import { createTheme } from '@mui/material/styles';

import {
    indigo as primaryColor,
    deepOrange as secondaryColor,
    red as errorColor,
    grey,
} from '@mui/material/colors';



const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: primaryColor,
        secondary: secondaryColor,
        error: errorColor,
    },
    typography: {
        useNextVariants: true,
    },
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: grey[300],
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                gutters: {
                    paddingLeft: 18,
                    paddingRight: 16,
                    paddingTop: 12,
                    paddingBottom: 12,
                    marginLeft: 8,
                    borderRadius: 8,
                    width: 'calc(100% - 16px)',
                },
            },
        },
    },
});


export default theme;