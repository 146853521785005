export default {
	t101: {
		rotation: 180,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t102: {
		rotation: 180,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t103: {
		rotation: -160,
		occlusalValueRotation: -55,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t104: {
		rotation: -70,
		occlusalValueRotation: -90,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t105: {
		rotation: -90,
		data: { flipHorizontal: true, flipVertical: true, },
		occlusalFlipHorizontal: true,
		occlusalValueRotation: -90,
	},
	t106: {
		rotation: -95,
		occlusalValueRotation: -90,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t107: {
		rotation: -120,
		occlusalValueRotation: -70,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t108: {
		rotation: -96,
		occlusalValueRotation: -90,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t109: {
		rotation: -75,
		occlusalValueRotation: -90,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t110: {
		rotation: -60,
		occlusalValueRotation: -135,
		occlusalFlipHorizontal: true,
		data: { flipVertical: true },
	},
	t201: { rotation: 0 },
	t202: { rotation: 0 },
	t203: {
		rotation: -30,
		occlusalValueRotation: 35,
	},
	t204: {
		rotation: -110,
		occlusalValueRotation: 90,
	},
	t205: {
		rotation: -90,
		data: { flipHorizontal: true },
		occlusalValueRotation: 90,
	},
	t206: {
		rotation: -80,
		occlusalValueRotation: 90,
	},
	t207: {
		rotation: -60,
		occlusalValueRotation: 50,
	},
	t208: {
		rotation: -84,
		occlusalValueRotation: 90,
	},
	t209: {
		rotation: -105,
		occlusalValueRotation: 90,
	},
	t210: {
		rotation: -120,
		occlusalValueRotation: 110,
	},
	t301: {
		rotation: 0,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t302: {
		rotation: 0,
		occlusalValueRotation: -25,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t303: {
		rotation: 70,
		occlusalValueRotation: -55,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t304: {
		rotation: 95,
		occlusalValueRotation: 265,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t305: {
		rotation: 90,
		occlusalValueRotation: -90,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t306: {
		rotation: 80,
		occlusalValueRotation: -75,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t307: {
		rotation: 90,
		data: { flipVertical: true },
		occlusalValueRotation: -90,
		occlusalFlipVertical: true,
	},
	t308: {
		rotation: 80,
		data: { flipVertical: true },
		occlusalValueRotation: -90,
		occlusalFlipVertical: true,
	},
	t309: {
		rotation: 70,
		occlusalValueRotation: -80,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t310: {
		rotation: 90,
		occlusalValueRotation: -90,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t311: {
		rotation: 90,
		occlusalValueRotation: -90,
		occlusalFlipVertical: true,
		data: { flipVertical: false },
	},
	t401: {
		rotation: 180,
		occlusalValueRotation: 180,
	},
	t402: {
		rotation: 180,
		occlusalValueRotation: 210,
	},
	t403: {
		rotation: 110,
		occlusalValueRotation: 225,
	},
	t404: {
		rotation: 85,
		occlusalValueRotation: 290,
	},
	t405: {
		rotation: 90,
		occlusalValueRotation: 270,
	},
	t406: {
		rotation: 100,
		occlusalValueRotation: 270,
	},
	t407: {
		rotation: 90,
		data: { flipVertical: false },
		occlusalValueRotation: 270,
	},
	t408: {
		rotation: 100,
		data: { flipVertical: false },
		occlusalValueRotation: 270,
	},
	t409: {
		rotation: 110,
		occlusalValueRotation: 250,
	},
	t410: {
		rotation: -90,
		occlusalValueRotation: 270,
		data: { flipHorizontal: true, flipVertical: true },
	},
	t411: {
		rotation: 90,
		occlusalValueRotation: 270,
		data: { flipVertical: true },
	},
};