import api from 'api';
import _ from 'lodash';
import { enqueueSnackbar } from 'notistack';



const baseType = 'CHART';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	SET_STATE_WITH_HISTORY: `${baseType}/SET_STATE_WITH_HISTORY`,
	UNDO: `${baseType}/UNDO`,
	REDO: `${baseType}/REDO`,
	RESET_ALL: `${baseType}/RESET_ALL`,
	RESET_SYMBOL_OPTIONS: `${baseType}/RESET_SYMBOL_OPTIONS`,
	TOGGLE_LAYER: `${baseType}/TOGGLE_LAYER`,
	SET_TOOLBAR: `${baseType}/SET_TOOLBAR`,
	RESET_TEXT: `${baseType}/RESET_TEXT`,
};

const initialState = {
	teeth: null, // []
	teethLP: null, // []
	teethDeciduous: null, // []
	teethOcclusal: null, // []
	teethOcclusalDeciduous: null, // []
	occlusalSoftTissue: null, // {},
	occlusalBone: null, // {}
	occlusalSublingual: null, // {}
	colors: [],
	colorPicker: [],
	order: [],
	chart: null, // {}
	chart_symbols_buccal: null, // []
	chart_symbols_occlusal: null, // []
	missingTeeth: null, // []
	pocketDepths: null, // []
	chartConfig: null, // []
	drawings_buccal: null, // []
	drawings_occlusal: null, // []
	shapes_buccal: null, // []
	shapes_occlusal: null, // []
	text_buccal: null, // [],
	text_occlusal: null, // [],
	symbols: null, // []
	symbolsMenu: [],
	symbolMultiDialogOptions: {
		tab: 'dx',
		requireConfirmation: false,
		displayMode: 'list',
		searchName: true,
		selectedCode: null,
		stayOnTab: false,
		defaultCodes: [],
	},
	symbolOptions: {
		symbol: null,
		canvasDown: null,
		canvasUp: null,
		sourceType: null,
		tooth: null,
		screenPoint: null,
		canvas: null,
		layer: null,
		handlerConf: null,
	},
	textOptions: {
		canvasPosition: null, // [ 1500, 500 ]
		screenPosition: null, // [ 1500, 500 ]
		canvas: null,
	},
	symbolHandlerData: {},
	indices: {
		plaque: 0,
		calculus: 0,
		gingival: 0,
	},
	visitInfo: {
		weight: 0,
		weightUnit: 'lb',
		referringVet: '',
		notes: '',
	},
	probeOptions: {
		activeTooth: 't101',
		activePocket: 0,
		enteredValue: 0,
		instantEntry: true,
	},
	options: {
		codeFontSize: 48,
		dxColorAdult: 'blue',
		dxColorDeciduous: 'blue',
		txColorAdult: 'red',
		txColorDeciduous: 'red',
		abnormalPocketDepth: 2,
	},
	toolbar: {
		selectedButton: null,
		selectedTool: '',
		color: '#3F51B5',
		lineSize: 8,
		fontSize: null,
		symbolShowDx: true,
		symbolShowTx: true,
		symbolShowOther: true,
		showAllSymbolsDialog: false,
	},
	layers: {
		adultTeeth: true,
		deciduousTeeth: false,
		diagnostics: true,
		treatments: true,
		text: true,
		drawings: true,
		shapes: true,
		softTissue: true,
		boneView: false,
		sublingual: false,
		probingValues: true,
		missingTeethAdult: true,
		missingTeethDeciduous: true,
	},
	history: {
		available: [],
		present: 0,
	},
	visit_id: null,
	patient_id: null,
	patient_name: null,
	client_id: null,
	client_name: null,
	visit_date: null,
	saving: false,
	autoSaveInterval: 30,
	autoSaveEnabled: false,
	reportModalStatus: null, // null (not showing), 'list', 'new'
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			//console.log(action);
			return { ...state, ...action.data };

		case types.SET_STATE_WITH_HISTORY:
			//console.log(action);
			return { ...state, ...handleHistory(state, action.data) };

		case types.UNDO:
			//console.log(action);
			return handleUndoRedo(state, 'UNDO');

		case types.REDO:
			//console.log(action);
			return handleUndoRedo(state, 'REDO');

		case types.RESET_ALL:
			return { ...initialState };

		case types.RESET_TEXT:
			return { ...state, textOptions: { ...initialState.textOptions } };

		case types.RESET_SYMBOL_OPTIONS:
			return {
				...state,
				symbolOptions: {
					...initialState.symbolOptions,
					...action.symbolOptions,
				},
				symbolMultiDialogOptions: {
					...initialState.symbolMultiDialogOptions,
					requireConfirmation: state.symbolMultiDialogOptions.requireConfirmation,
					displayMode: state.symbolMultiDialogOptions.displayMode,
					searchName: state.symbolMultiDialogOptions.searchName,
					tab: state.symbolMultiDialogOptions.tab,
					stayOnTab: state.symbolMultiDialogOptions.stayOnTab,
					defaultCodes: state.symbolMultiDialogOptions.defaultCodes,
				},
				symbolHandlerData: {},
			};

		case types.TOGGLE_LAYER:
			return {
				...state,
				layers: {
					...state.layers,
					[ action.name ]: !state.layers[ action.name ],
				},
			};

		case types.SET_TOOLBAR:
			return {
				...state,
				toolbar: {
					...state.toolbar,
					...action.data,
				},
			};

		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	setStateWithHistory: (data) => ({ type: types.SET_STATE_WITH_HISTORY, data }),

	setToolbar: (data) => ({ type: types.SET_TOOLBAR, data }),



	resetAll: () => ({ type: types.RESET_ALL }),


	toggleLayer: (name) => ({ type: types.TOGGLE_LAYER, name }),


	resetText: () => ({ type: types.RESET_TEXT }),



	getChart: (chart_id) => async (dispatch, getState) => {
		try {
			const loaded = await api.get(`charts/view/${chart_id}`);
			const data = loaded.data.chart;
			const masterData = data.master_data;
			const { Visibility } = masterData;
			const layers = getState().chart.layers;

			console.log(data);


			let diagBuccal        = Array.isArray(masterData.Diagnostics.buccal)
					? masterData.Diagnostics.buccal
					: objectToArray(masterData.Diagnostics.buccal),
				diagOcclusal      = Array.isArray(masterData.Diagnostics.occlusal)
					? masterData.Diagnostics.occlusal
					: objectToArray(masterData.Diagnostics.occlusal),
				treatmentBuccal   = Array.isArray(masterData.Treatments.buccal)
					? masterData.Treatments.buccal
					: objectToArray(masterData.Treatments.buccal),
				treatmentOcclusal = Array.isArray(masterData.Treatments.occlusal)
					? masterData.Treatments.occlusal
					: objectToArray(masterData.Treatments.occlusal);

			dispatch(actions.setState({
				teeth: data.teeth_adult_buccal,
				teethLP: data.teeth_adult_lingual_palatal,
				teethDeciduous: data.teeth_deciduous_buccal || [],
				teethOcclusal: data.teeth_adult_occlusal,
				teethOcclusalDeciduous: data.teeth_deciduous_occlusal,
				occlusalSoftTissue: data.chart_layout_soft_tissue,
				occlusalBone: data.chart_layout_bone_view,
				occlusalSublingual: data.chart_layout_sublingual,
				order: data.chart_layout_adult_order.sort(),
				colors: data.chart_layout_colors,
				chart: masterData,
				chartConfig: data.species_chart_config,
				chart_symbols_buccal: [ ...diagBuccal, ...treatmentBuccal ],
				chart_symbols_occlusal: [ ...diagOcclusal, ...treatmentOcclusal ],

				text_buccal: Array.isArray(masterData.Text.buccal)
					? masterData.Text.buccal
					: [],
				text_occlusal: Array.isArray(masterData.Text.occlusal)
					? masterData.Text.occlusal
					: [],
				drawings_buccal: Array.isArray(masterData.Drawings.buccal)
					? masterData.Drawings.buccal
					: [],
				drawings_occlusal: Array.isArray(masterData.Drawings.occlusal)
					? masterData.Drawings.occlusal
					: [],
				shapes_buccal: Array.isArray(masterData.Shapes.buccal)
					? masterData.Shapes.buccal
					: [],
				shapes_occlusal: Array.isArray(masterData.Shapes.occlusal)
					? masterData.Shapes.occlusal
					: [],
				missingTeeth: Array.isArray(masterData.MissingTeeth)
					? masterData.MissingTeeth
					: [],
				pocketDepths: Object.keys(masterData.PocketDepths || {})
					.map(tooth => ({
						name: tooth,
						values: masterData.PocketDepths[ tooth ],
					})),
				layers: {
					...layers,
					adultTeeth: Visibility?.Adult?.buccal ?? layers.adultTeeth,
					deciduousTeeth: Visibility?.Deciduous?.buccal ?? layers.deciduousTeeth,
					boneView: Visibility?.BoneView?.occlusal ?? layers.boneView,
					softTissue: Visibility?.SoftTissue?.occlusal ?? layers.softTissue,
					sublingual: Visibility?.sublingual?.occlusal ?? Visibility?.Sublingual?.occlusal ?? layers.sublingal,
					diagnostics: Visibility?.Diagnostics?.buccal ?? layers.diagnostics,
					treatments: Visibility?.Treatments?.buccal ?? layers.treatments,
					text: Visibility?.Text?.buccal ?? layers.text,
					shapes: Visibility?.Shapes?.buccal ?? layers.shapes,
					drawings: Visibility?.Drawings?.buccal ?? layers.drawings,
					probingValues: Visibility?.Probing?.occlusal ?? layers.probingValues,
				},
				visit_id: data.visit_id,
				patient_id: data.patient_id,
				patient_name: data.patient_name || null,
				client_id: data.client_id,
				client_name: data.client_name || null,
				visit_date: data.date_created || null,
			}));

			dispatch(actions.getSymbols());
			dispatch(actions.getPreferences());
		} catch(err) {
			console.error(err);
		}
	},



	saveChart: (isAutoSave = false) => async (dispatch, getState) => {
		dispatch(actions.setState({
			saving: true,
		}));

		try {
			let state   = getState().chart,
				master  = state.chart,
				payload = {
					...master,
					Diagnostics: {
						buccal: state.chart_symbols_buccal.filter(symbol => symbol.symbol_type === 'dx'),
						occlusal: state.chart_symbols_occlusal.filter(symbol => symbol.symbol_type === 'dx'),
					},
					Treatments: {
						buccal: state.chart_symbols_buccal.filter(symbol => symbol.symbol_type === 'tx'),
						occlusal: state.chart_symbols_occlusal.filter(symbol => symbol.symbol_type === 'tx'),
					},
					Text: {
						buccal: state.text_buccal,
						occlusal: state.text_occlusal,
					},
					Drawings: {
						buccal: state.drawings_buccal,
						occlusal: state.drawings_occlusal,
					},
					Shapes: {
						buccal: state.shapes_buccal,
						occlusal: state.shapes_occlusal,
					},
					MissingTeeth: state.missingTeeth,
					PocketDepths: state.pocketDepths.reduce((all, current) => {
						all[ current.name ] = current.values;
						return all;
					}, {}),
					Visibility: {
						"Adult": {
							"buccal": state.layers.adultTeeth,
							"charting": state.layers.adultTeeth,
							"occlusal": state.layers.adultTeeth,
						},
						"Deciduous": {
							"buccal": state.layers.deciduousTeeth,
							"charting": state.layers.deciduousTeeth,
							"occlusal": state.layers.deciduousTeeth,
						},
						"BoneView": {
							"occlusal": state.layers.boneView,
						},
						"Diagnostics": {
							"buccal": state.layers.diagnostics,
							"charting": state.layers.diagnostics,
							"occlusal": state.layers.diagnostics,
						},
						"Treatments": {
							"buccal": state.layers.treatments,
							"charting": state.layers.treatments,
							"occlusal": state.layers.treatments,
						},
						"Text": {
							"buccal": state.layers.text,
							"charting": state.layers.text,
							"occlusal": state.layers.text,
						},
						"SoftTissue": {
							"occlusal": state.layers.softTissue,
						},
						"sublingual": {
							"occlusal": state.layers.sublingual,
						},
						"Shapes": {
							"buccal": state.layers.shapes,
							"charting": state.layers.shapes,
							"occlusal": state.layers.shapes,
						},
						"Probing": {
							"occlusal": state.layers.probingValues,
						},
						"Drawings": {
							"buccal": state.layers.drawings,
							"charting": state.layers.drawings,
							"occlusal": state.layers.drawings,
						},
					},
				};


			await api.put('/charts', {
				visit_id: state.visit_id,
				master_data: payload,
			});

			if(!isAutoSave) {
				enqueueSnackbar('Chart saved successfully.', { variant: 'success' });
			}

		} catch(err) {
			console.error(err);
			enqueueSnackbar('An error occurred while saving the chart. Please try again.', { variant: 'error' });
		}

		dispatch(actions.setState({
			saving: false,
		}));
	},



	getSymbols: () => async (dispatch, getState) => {
		try {
			let loaded = await api.get(`resources/charting-symbols`),
				prefs  = await api.get(`/clinic-settings/charting-defaults`);



			let data    = loaded.data,
				symbols = [ ...data.diagnostics, ...data.treatments, ...data.other ];

			console.log(prefs.data);
			const preferences = prefs.data.preferences;

			dispatch(actions.setState({
				symbols: symbols,
				symbolMultiDialogOptions: {
					...getState().chart.symbolMultiDialogOptions,
					defaultCodes: preferences.chart_quick_symbols,
				},
				options: {
					...getState().chart.options,
					codeFontSize: preferences.chart_font_size,
					dxColorAdult: preferences.chart_dx_color_adult,
					dxColorDeciduous: preferences.chart_dx_color_deciduous,
					txColorAdult: preferences.chart_tx_color_adult,
					txColorDeciduous: preferences.chart_tx_color_deciduous,
				},
				symbolsMenu: prefs.data.preferences.chart_quick_symbols
					.map(symbol_id => symbols.find(row => row.symbol_id === symbol_id)),

			}));
		} catch(err) {
			console.error(err);
		}
	},


	getPreferences: () => async (dispatch, getState) => {
		try {
			let loaded = await api.get(`resources/charting-symbols`);
			let data = loaded.data;

			dispatch(actions.setState({
				symbols: [ ...data.diagnostics, ...data.treatments ],
			}));
		} catch(err) {
			console.error(err);
		}
	},



	setSymbolOptions: (data = {}) => async (dispatch, getState) => {
		dispatch(actions.setState({
			symbolOptions: {
				...getState().chart.symbolOptions,
				...data,
			},
		}));
	},



	markAllMissing: (name) => (dispatch, getState) => {
		let teeth;

		if(name === 'deciduous') {
			teeth = getState().chart.teethDeciduous;
		} else {
			teeth = getState().chart.teeth;
		}

		if(Array.isArray(teeth)) {
			let newMissing = new Set([
				...(getState().chart.missingTeeth || []),
			]);

			teeth.forEach(tooth => newMissing.add(tooth.name));

			dispatch(actions.setStateWithHistory({
				missingTeeth: [ ...newMissing ],
			}));
		}
	},



	toggleMissing: (tooth) => (dispatch, getState) => {
		let missing = new Set(getState().chart.missingTeeth || []);
		if(missing.has(tooth)) {
			missing.delete(tooth);
		} else {
			missing.add(tooth);
		}

		dispatch(actions.setStateWithHistory({ missingTeeth: [ ...missing ] }));
	},



	increaseFontSize: (amount) => (dispatch, getState) => {
		let size = getState().chart.options.codeFontSize + amount;

		if(size < 24) {
			size = 24;
		}

		if(size > 96) {
			size = 96;
		}

		dispatch(actions.setState({
			options: {
				...getState().chart.options,
				codeFontSize: size,
			},
		}));
	},


	setProbingOptions: (data) => (dispatch, getState) => {
		dispatch(actions.setState({
			probeOptions: {
				...getState().chart.probeOptions,
				...data,
			},
		}));
	},


	setSymbolMultiDialog: (data) => (dispatch, getState) => {
		dispatch(actions.setState({
			symbolMultiDialogOptions: {
				...getState().chart.symbolMultiDialogOptions,
				...data,
			},
		}));
	},


	setProbingValue: (value, override = false) => (dispatch, getState) => {
		if(typeof value !== 'number') {
			console.log('expecting number');
			return false;
		}

		let state   = getState().chart,
			options = { ...state.probeOptions };

		if(!options.instantEntry && !override) {
			options.enteredValue = Number(`${options.enteredValue}${value}`);

			dispatch(actions.setState({
				probeOptions: options,
			}));

			return true;
		}

		let valuesIndex = state.pocketDepths.findIndex(row => row.name === options.activeTooth),
			values      = valuesIndex !== -1
				? [ ...state.pocketDepths[ valuesIndex ].values ]
				: [];

		values[ options.activePocket ] = value;
		options.enteredValue = 0;

		if(valuesIndex !== -1) {
			dispatch(actions.setStateWithHistory({
				pocketDepths: [
					...state.pocketDepths.slice(0, valuesIndex),
					{ name: options.activeTooth, values: values },
					...state.pocketDepths.slice(valuesIndex + 1),
				],
				probeOptions: options,
			}));
		} else {
			dispatch(actions.setStateWithHistory({
				pocketDepths: [
					...state.pocketDepths,
					{ name: options.activeTooth, values: values },
				],
				probeOptions: options,
			}));
		}

		dispatch(actions.advanceProbing());
	},


	confirmProbingValue: () => (dispatch, getState) => {
		let state   = getState().chart,
			options = { ...state.probeOptions };

		if(options.instantEntry) {
			return false;
		}

		dispatch(actions.setProbingValue(options.enteredValue, true));
	},


	advanceProbing: () => (dispatch, getState) => {
		const state        = getState().chart,
			  options      = { ...state.probeOptions },
			  missing      = state.missingTeeth ?? [],
			  order        = state.order.filter(tooth => !missing.includes(tooth)),
			  config       = state.chartConfig,
			  activeTooth  = options.activeTooth,
			  activePocket = options.activePocket,
			  toothConf    = config[ activeTooth ];

		const orderIndex = order.indexOf(activeTooth);
		let nextIndex  = orderIndex,
			nextPocket = activePocket + 1;

		if(nextPocket >= toothConf.pockets) {
			nextIndex += 1;
			nextPocket = 0;
		}

		if(nextIndex >= order.length) {
			nextIndex = 0;
		}

		let enteredValue = 0;

		if(nextIndex === orderIndex && !options.instantEntry) {
			let existingValues = state.pocketDepths.find(row => row.name === order[ nextIndex ]);
			if(existingValues) {
				enteredValue = existingValues.values[ nextPocket ] || 0;
			}
		}

		dispatch(actions.setProbingOptions({
			activePocket: nextPocket,
			activeTooth: order[ nextIndex ],
			enteredValue: enteredValue,
		}));

	},



	setSymbolHandlerValue: (key, value) => (dispatch, getState) => {
		let values = getState().chart.symbolHandlerData;

		dispatch(actions.setState({
			symbolHandlerData: {
				...values,
				[ key ]: value,
			},
		}));

	},



	resetSymbolOptions: (symbolOptions = {}) => ({ type: types.RESET_SYMBOL_OPTIONS, symbolOptions }),



	handleSymbolDispatch: (symbol_id, canvas) => (dispatch, getState) => {
		let
			state               = getState().chart,
			options             = getState().chart.symbolOptions,
			missingTeeth        = getState().chart.missingTeeth,
			layers              = getState().chart.layers,
			symbol              = state.symbols.find(sym => sym.symbol_id === symbol_id),
			property            = `chart_symbols_${canvas}`,
			existingSymbols     = getState().chart[ property ],
			existingSymbolCodes = existingSymbols.map(row => row.symbol_id);

		if(!symbol) {
			console.warn('Could not find symbol', symbol_id);
			return false;
		}

		let conf              = symbol.symbol_chart_config,
			disallowLayer     = conf.disallowLayer || [],
			disallowWithCode  = conf.disallowWithCode || [],
			disallowSaveLayer = disallowLayer.find(row => (row.canvas === canvas && (row.layer === '*' || row.layer === options.layer))),
			disallowSaveCode  = disallowWithCode.some(code => {
					if(conf.attachment === 'tooth') {
						let found = existingSymbols.find(existing => String(existing.symbol_id) === String(code) && existing.tooth === options.tooth)
							|| (code === "SELF" && existingSymbols.find(existing => String(existing.symbol_id) === String(symbol.symbol_id) && existing.tooth === options.tooth));

						return Boolean(found);
					} else {
						return existingSymbolCodes.includes(code) || (code === "SELF" && existingSymbolCodes.includes(symbol.symbol_id));
					}
				},
			);

		if(!layers.missingTeethAdult && options.tooth && options.tooth.substr(0, 1) === 't' && missingTeeth.includes(options.tooth)) {
			return false;
		}

		if(!layers.missingTeethDeciduous && options.tooth && options.tooth.substr(0, 1) === 'd' && missingTeeth.includes(options.tooth)) {
			return false;
		}


		if(disallowSaveLayer) {
			enqueueSnackbar('This code cannot be applied here.', { variant: 'error' });
			console.log(`Can't save to this canvas and/or layer`);
			return false;
		}

		if(disallowSaveCode) {
			enqueueSnackbar(
				`This tooth has a code which conflicts with the code you're trying to add.`,
				{ variant: 'error' },
			);
			console.log(`Tooth has code which blocks this code.`);
			return false;
		}


		if(conf.type === 'single') {
			dispatch(actions.saveSymbol(symbol, options, canvas));
		} else {
			dispatch(actions.setSymbolOptions({ handlerConf: conf, symbol: symbol_id }));
		}

	},


	saveSymbol: (symbol, options, canvas) => (dispatch, getState) => {
		let property     = `chart_symbols_${canvas}`,
			name         = `${symbol.symbol_id}_${Date.now()}`,
			conf         = symbol.symbol_chart_config,
			inputValues  = getState().chart.symbolHandlerData,
			shouldDelete = false,
			setPositions = [
				'TL', 'TC', 'TR',
				'CL', 'CC', 'CR',
				'BL', 'BC', 'BR',
			],
			renderValue  = conf.render.defaultValue,
			newOptions   = {
				symbol: getState().chart.toolbar.selectedTool === 'symbolSingle'
					? options.symbol
					: null,
			};


		if(conf.attachment === 'tooth' && !options.tooth) {
			dispatch(actions.resetSymbolOptions(newOptions));
			return false;
		}


		if(conf.deleteIf) {
			let deleteKeys = Object.keys(conf.deleteIf);
			for(let i = 0; i < deleteKeys.length; i++) {
				let key          = deleteKeys[ i ],
					deleteValues = conf.deleteIf[ key ];

				if(deleteValues.includes[ inputValues[ key ] ]) {
					shouldDelete = true;
					break;
				}
			}
		}

		if(shouldDelete) {
			dispatch(actions.resetSymbolOptions(newOptions));
			return false;
		}

		let origin   = options.canvasDown,
			position = options.canvasUp;

		if(setPositions.includes(conf.render.position)) {
			//origin = [ 200, 200 ];
			//position = origin;
		}

		// Add suffixes or prefixes as necessary
		if(conf.render.suffix) {
			renderValue = `${renderValue}${inputValues[ conf.render.suffix ]}`;
		}

		if(conf.render.prefix) {
			renderValue = `${inputValues[ conf.render.prefix ]}${renderValue}`;
		}

		dispatch(actions.setStateWithHistory({
			[ property ]: [
				...getState().chart[ property ],
				{
					symbol_id: symbol.symbol_id,
					symbol_type: symbol.symbol_category,
					symbol_name: symbol.symbol_name,
					symbol_description: symbol.symbol_description,
					name: name,
					attachment: conf.attachment,
					origin: origin,
					position: position,
					tooth: conf.attachment === 'tooth'
						? options.tooth
						: null,
					canvas: canvas,
					render_type: conf.render.type,
					render_value: renderValue,
					adjust_line: conf.render.adjust_line || null,
					adjust_line_multiplier: conf.render.adjust_line_multiplier || null,
					adjust_line_value: inputValues[ conf.render.adjust_line_property ] || null,
				},
			],
		}));


		dispatch(actions.resetSymbolOptions(newOptions));
	},



	saveText: (value) => (dispatch, getState) => {

		if(typeof value !== 'string' || value.trim().length === 0) {
			return false;
		}

		let chart       = getState().chart,
			color       = chart.toolbar.color || '#000000',
			textOptions = chart.textOptions,
			unique      = Date.now().toString() + Math.floor(Math.random() * 1000).toString(),
			property    = `text_${textOptions.canvas}`,
			textConfig  = {
				name: `text_${unique}`,
				content: value.trim(),
				color: color,
				position: textOptions.canvasPosition,
			};


		dispatch(actions.setStateWithHistory({
			[ property ]: [
				...getState().chart[ property ],
				textConfig,
			],
		}));


		dispatch(actions.resetText());
	},



	undoRedo: (method) => ({ type: types[ method ] }),


	_ADMINsetToothData: (tooth, data) => (dispatch, getState) => {
		const config = getState().chart.chartConfig;

		dispatch(actions.setState({
			chartConfig: {
				...config,
				[ tooth ]: { ...data },
			},
		}));
	},

};


function handleHistory(state, data) {
	const maxHistoryItems = 20,
		  present         = state.history.present,
		  updateKeys      = Object.keys(data);

	let oldState = updateKeys.reduce((accumulator, key) => {
		accumulator[ key ] = _.cloneDeep(state[ key ]);
		return accumulator;
	}, {});

	let newAvailable = [
		...state.history.available.slice(0, present + 1),
		oldState,
	]
		.slice(maxHistoryItems * -1);

	return {
		...data,
		history: {
			available: newAvailable,
			present: newAvailable.length,
		},
	};
}


function handleUndoRedo(state, action) {
	let history   = state.history,
		available = history.available,
		present   = history.present;

	if(available.length === 0) {
		return state;
	}

	if(action === 'UNDO') {
		if(present === 0) {
			return state;
		}

		return {
			...state,
			...history.available[ present - 1 ],
			history: {
				...history,
				present: present - 1,
			},
		};
	}

	if(action === 'REDO') {
		if(present >= available.length) {
			return state;
		}

		return {
			...state,
			...history.available[ present ],
			history: {
				...history,
				present: present + 1,
			},
		};
	}
}



function objectToArray(obj) {
	if(typeof obj !== 'object') {
		return [];
	}

	if(Array.isArray(obj)) {
		return obj;
	}

	return Object.entries(obj).map(row => row[ 1 ]);
}


function arrayToObject(arr, key) {
	if(!Array.isArray(arr)) {
		return arr;
	}

	return arr.reduce((all, current) => {
		all[ current[ key ] ] = current;
		return all;
	}, {});
}
