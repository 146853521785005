import store from 'store';



let previousState = {},
	subscriptions = {};

store.subscribe(() => {
	let state = store.getState().chart;
	if(state === previousState) {
		return true;
	}

	for(const property in subscriptions) {
		let config      = subscriptions[ property ],
			resolveKeys = Object.keys(config.resolvePaths),
			current     = resolveKeys.reduce((accumulator, currentValue) => {
				let resolvePath = config.resolvePaths[ currentValue ];
				accumulator[ currentValue ] = resolve(resolvePath, state);
				return accumulator;
			}, {}),
			previous    = resolveKeys.reduce((accumulator, current) => {
				let resolvePath = config.resolvePaths[ current ];
				accumulator[ current ] = resolve(resolvePath, previousState);
				return accumulator;
			}, {}),
			shouldRun   = false;


		for(let i = 0; i < resolveKeys.length; i++) {
			let key = resolveKeys[i];

			if(current[ key ] === null) {
				shouldRun = false;
				break;
			}

			if(current[ key ] !== previous[ key ]) {
				shouldRun = true;
			}
		}


		if(shouldRun) {
			try {
				config.fn(current, state, previousState);
			} catch(err) {
				console.error(err);
			}
		}
	}

	previousState = state;
});


function resolve(pathArray, state) {
	try {
		let data = state;

		for(let i = 0; i < pathArray.length; i++) {
			data = data[ pathArray [ i ] ];
		}

		return data === undefined ? null : data;
	} catch(err) {
		//console.error(err);
		return null;
	}
}


export function resetSVGSubscriptions() {
	previousState = {};
	subscriptions = {};
}


export function subscribe(name, resolvePaths, fn) {
	subscriptions[ name ] = {
		resolvePaths: resolvePaths,
		fn: fn,
	};

	return subscriptions[ name ];
}


export function unsubscribe(name) {
	delete subscriptions[ name ];

	return true;
}


export default subscribe;