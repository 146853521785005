export default {
	// UPPER 100s
	t109: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t108: {
		"scaling": 3,
		"pockets": 6,
		"side": "left",
	},
	t107: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t106: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t104: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t103: {
		"scaling": 3.5,
		"pockets": 4,
		"side": "left",
	},
	t102: {
		"scaling": 4,
		"pockets": 4,
		"side": "left",
	},
	t101: {
		"scaling": 5,
		"pockets": 4,
		"side": "left",
	},

	// UPPER 200s
	t201: {
		"scaling": 4.5,
		"pockets": 4,
		"side": "right",
	},
	t202: {
		"scaling": 4.5,
		"pockets": 4,
		"side": "right",
	},
	t203: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t204: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t206: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t207: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t208: {
		"scaling": 3,
		"pockets": 6,
		"side": "right",
	},
	t209: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},

	// LOWER 400s
	t409: {
		"scaling": 3,
		"pockets": 6,
		"side": "left",
	},
	t408: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t407: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t404: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t403: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t402: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},
	t401: {
		"scaling": 3,
		"pockets": 4,
		"side": "left",
	},

	// LOWER 300s
	t301: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t302: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t303: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t304: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t307: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t308: {
		"scaling": 3,
		"pockets": 4,
		"side": "right",
	},
	t309: {
		"scaling": 3,
		"pockets": 6,
		"side": "right",
	},
};