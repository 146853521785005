import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@mui/material';
import { IntegratedPaging, PagingState, SelectionState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { push } from 'connected-react-router';
import { actions } from './Reports.redux';
import api from 'api';



const ModalTemplateAddNew = () => {
    const dispatch                = useDispatch(),
          [
              baseTemplates,
              setBaseTemplates,
          ]                       = useState([]),
          open                    = useSelector(state => state.reports.showNewTemplateModal),
          [ loading, setLoading ] = useState(false),
          [ title, setTitle ]     = useState(''),
          [ columns ]             = useState([
              { name: 'report_name', title: 'Name' },
          ]),
          [
              selection,
              setSelection
          ]                       = useState([]);


    const handleClose = () => {
        dispatch(actions.setState({ showNewTemplateModal: false }));
    };

    const handleSetSelection = (values) => {
        setSelection(values.slice(-1));
    };


    const handleCreate = async () => {
        setLoading(true);

        try {
            let newTemplate = await api.post('/reports/templates', {
                base_template_id: baseTemplates[selection[0]].report_id,
                template_name: title.trim(),
            });

            dispatch(push(`/reports/edit-layout/${newTemplate.data.template_id}`));
        } catch (err) {
            setLoading(false);
        }

    };


    // Reset template list on close
    useEffect(() => {
        if(open) {
            api.get(`/reports/base-templates`)
                .then(res => setBaseTemplates(res.data.templates))
                .catch(err => console.error(err));
        } else {
            setBaseTemplates([]);
        }
    }, [ open, setBaseTemplates ]);



    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                New Layout
            </DialogTitle>

            <DialogContent>
                <div>
                    Enter a title, and select a starter template to use as the base of your new layout.
                </div>

                <Divider sx={{ marginBottom: '12px', marginTop: '12px' }} />

                <div>
                    <TextField
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>


                <div>
                    <Grid
                        rows={baseTemplates}
                        columns={columns}
                    >
                        <PagingState />
                        <IntegratedPaging />
                        <SelectionState
                            selection={selection}
                            onSelectionChange={handleSetSelection}
                        />
                        <Table />
                        <TableHeaderRow />
                        <TableSelection
                            selectByRowClick
                            highlightRow
                            showSelectionColumn={false}
                        />
                        <PagingPanel
                            pageSizes={[ 5, 10, 20, 30 ]}
                            defaultPageSize={10}
                        />
                    </Grid>
                </div>
            </DialogContent>

            <DialogActions>
                <Button disabled={loading} onClick={handleClose}>
                    Cancel
                </Button>

                <Button
                    disabled={loading || selection.length !== 1 || !title.trim()}
                    onClick={handleCreate}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default ModalTemplateAddNew;
