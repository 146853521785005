import React from 'react';
import MultiSelect from './MutliSelect';
import CustomInput from './CustomInput';
import SimpleCode from './SimpleCode';
import { useSelector } from "react-redux";



const Configurator = () => {
	const step = useSelector(state => state.customCodes.step),
		  type = useSelector(state => state.customCodes.type);

	if(step !== 2) {
		return null;
	}

	if(type === 'multiselect') {
		return <MultiSelect />;
	} else if(type === 'input') {
		return <CustomInput />;
	} else if(type === 'single') {
		return <SimpleCode />;
	}

	return `unknown type`;
};

export default Configurator;