import React from 'react';
import InputBase from '@mui/material/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';
import AppSearchResults from 'Layout/AppSearchResults';
import useStyles from './AppSearch.styles';
import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Code, Search as SearchIcon } from "@mui/icons-material";



const AppSearch = () => {

	let anchor = React.createRef();
	const classes       = useStyles(),
		  dispatch      = useDispatch(),
		  appSearchTerm = useSelector(state => state.layout.appSearchTerm),
		  setSearchTerm = e => dispatch(actions.layout.setSearchTerm(e));




	return (
        <React.Fragment>
			<div className={classes.search} ref={anchor}>
				<div className={classes.searchIcon}>
					<SearchIcon />
				</div>
				<InputBase
					placeholder="Sabertooth Search"
					value={appSearchTerm}
					onChange={setSearchTerm}
					classes={{
						root: classes.inputRoot,
						input: classes.inputInput,
					}}
					endAdornment={
						<InputAdornment position="end">
							<Tooltip title="Advanced Search">
								<IconButton aria-label="Advanced Search" size="large">
									<Code />
								</IconButton>
							</Tooltip>
						</InputAdornment>
					}
				/>
			</div>
			<AppSearchResults anchor={anchor} />
		</React.Fragment>
    );

};




export default AppSearch;