import axios from 'axios';
import Cookies from 'js-cookie';



const apiInstance = axios.create({
	baseURL: 'https://6fyl8swazj.execute-api.us-west-2.amazonaws.com/dev/',
	timeout: 30000,
});

function requestInterceptor(config) {
	let token = Cookies.get('authToken');

	if(token) {
		config.headers[ 'Authorization' ] = `Bearer ${token}`;
	}

	return config;
}

function responseInterceptor(response) {
	try {
		if(response.data.token) {
			Cookies.set('authToken', response.data.token);
		}
	} catch(err) {

	}

	return response;
}


apiInstance.interceptors.response.use(responseInterceptor);
apiInstance.interceptors.request.use(requestInterceptor);


export default apiInstance;
