import React, { useCallback, useState } from 'react';
import ReportsList from './ReportsList';
import TemplatesList from './TemplatesList';
import ContentBlocksList from './ContentBlocksList';
import { Button, ButtonGroup, Grid } from '@mui/material';
import ReportSearch from "./ReportSearch";
import TemplateSearch from './TemplateSearch';
import ContentBlockSearch from './ContentBlockSearch';
import useStyles from './Reports.styles'
import classnames from "classnames";



const Reports = () => {
    // Reports, layouts, contentBlocks
    const [ tab, setTab ] = useState('reports'),
          classes         = useStyles();

    const handleTabChange = useCallback((e) => {
        setTab(e.currentTarget.name)
    }, [ setTab ]);

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12} lg={3} xl={4}>
                    {tab === 'reports' && <ReportSearch />}
                    {tab === 'layouts' && <TemplateSearch />}
                    {tab === 'contentBlocks' && <ContentBlockSearch />}
                </Grid>

                <Grid item xs={12} lg={9} xl={8}>
                    <div className={classes.buttonGroupContainer}>
                        <ButtonGroup fullWidth variant="contained">

                            <Button
                                size="large"
                                name="reports"
                                onClick={handleTabChange}
                                classes={{
                                    root: classnames({
                                        [classes.buttonActive]: tab === 'reports',
                                        [classes.buttonInactive]: tab !== 'reports',
                                    }),
                                }}
                            >
                                Reports
                            </Button>

                            <Button
                                size="large"
                                name="layouts"
                                onClick={handleTabChange}
                                classes={{
                                    root: classnames({
                                        [classes.buttonActive]: tab === 'layouts',
                                        [classes.buttonInactive]: tab !== 'layouts',
                                    }),
                                }}
                            >
                                Layouts
                            </Button>

                            <Button
                                size="large"
                                name="contentBlocks"
                                onClick={handleTabChange}
                                classes={{
                                    root: classnames({
                                        [classes.buttonActive]: tab === 'contentBlocks',
                                        [classes.buttonInactive]: tab !== 'contentBlocks',
                                    }),
                                }}
                            >
                                Content Blocks
                            </Button>

                        </ButtonGroup>
                    </div>

                    {tab === 'reports' && <ReportsList />}
                    {tab === 'layouts' && <TemplatesList />}
                    {tab === 'contentBlocks' && <ContentBlocksList />}
                </Grid>
            </Grid>
        </div>
    );
};


export default Reports;
