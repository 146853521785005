import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions } from 'store';



const SetTitle = memo(({ title }) => {
	const dispatch = useDispatch(),
		  setTitle = (newTitle) => dispatch(actions.layout.setTitle(newTitle));

	useEffect(() => {
		setTitle(title);
	}, [ title ]);


	return null;
});


SetTitle.propTypes = {
	title: PropTypes.string.isRequired,
};



export default SetTitle;
