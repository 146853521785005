import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "pages/Chart/Chart.redux";
import useStyles from './IndiciesDialog.styles';



const IndicesDialog = () => {
	const dispatch = useDispatch(),
		  classes  = useStyles(),
		  values   = useSelector(state => state.chart.indices),
		  open     = useSelector(state => state.chart.toolbar.selectedTool === 'indices');

	const handleClose = () => {
		dispatch(actions.setToolbar({
			selectedButton: null,
			selectedTool: '',
		}));
	};

	const handleInput = (e) => {
		let key   = e.target.name,
			value = Number(e.target.value);

		if(isNaN(value)) {
			return false;
		}

		dispatch(actions.setState({
			indices: {
				...values,
				[ key ]: value,
			},
		}));
	};

	return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
			<DialogTitle>Indices</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Set the indices for this chart below, then click Done.
				</DialogContentText>

				<div className={classes.inputWrapper}>
					<TextField
						label="Plaque"
						type="number"
						value={values.plaque}
						name="plaque"
						onChange={handleInput}
						variant="outlined"
					/>
				</div>

				<div className={classes.inputWrapper}>
					<TextField
						label="Calculus"
						type="number"
						value={values.calculus}
						name="calculus"
						onChange={handleInput}
						variant="outlined"
					/>
				</div>

				<div className={classes.inputWrapper}>
					<TextField
						label="Gingival"
						type="number"
						value={values.gingival}
						name="gingival"
						onChange={handleInput}
						variant="outlined"
					/>
				</div>
			</DialogContent>

			<DialogActions>
				<Button color="primary" variant="contained" onClick={handleClose}>
					Done
				</Button>
			</DialogActions>
		</Dialog>
    );
};



export default IndicesDialog;
