import makeStyles from '@mui/styles/makeStyles';
import { amber, grey } from '@mui/material/colors';



const useStyles = makeStyles(theme => ({
    buttonGroupContainer: {
        maxWidth: 1200,
        margin: '0 auto',
        paddingLeft: 32,
        paddingRight: 32,
    },
    profileLoadingContainer: {
        width: 300,
    },
    editButton: {
        marginTop: 32,
    },
    cancelButton: {
        marginLeft: 16,
        marginTop: 32,
    },
    buttonInactive: {
        backgroundColor: grey[100],
        color: '#000000',
        '&:hover': {
            backgroundColor: amber[300],
        },
    },
    buttonActive: {
        backgroundColor: amber[600],
        color: '#000000',
        '&:hover': {
            backgroundColor: amber[600],
        },
    },
    searchContainer: {
        marginBottom: 64,
    },
    searchButtons: {
        margin: 8,
    },
}));


export default useStyles;