import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../../CustomCodes.redux';
import { Button, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import useStyles from '../../CustomCodes.styles';



const SimpleCode = () => {
	const dispatch     = useDispatch(),
		  category     = useSelector(state => state.customCodes.category),
		  codeName     = useSelector(state => state.customCodes.conf.symbol_name),
		  abbreviation = useSelector(state => state.customCodes.conf.symbol_abbreviation),
		  description  = useSelector(state => state.customCodes.conf.symbol_description),
		  mapTo        = useSelector(state => state.customCodes.conf.map_to),
		  saving       = useSelector(state => state.customCodes.saving),
		  classes      = useStyles();


	const setCodeProperty = property => e => {
		dispatch(actions.setCodeProperty(property, e.target.value));
	};

	// Set up default info if abbreviation is undefined
	useEffect(() => {
		if(codeName === undefined) {
			dispatch(actions.setState({
				conf: {
					symbol_abbreviation: '',
					symbol_name: '',
					symbol_description: '',
					symbol_data_type: 'text',
					symbol_category: category,
					is_approved: false,
					map_to: 'tooth',
					symbol_chart_config: {
						type: 'single',
						render: {
							type: 'text',
							position: 'user',
							scaleType: 'default',
							scaleValue: 1,
							defaultValue: '',
						},
						attachment: 'tooth',
					},
				},
			}));
		}
	}, [ dispatch, codeName, category ]);

	// Don't render until initial setup is finished
	if(codeName === undefined) {
		return null;
	}

	return (
		<div>
			<div className={classes.textInputWrapper}>
				<TextField
					value={codeName}
					label="Custom Code Name"
					className={classes.textInput}
					variant="outlined"
					onChange={setCodeProperty('symbol_name')}
					inputProps={{ maxLength: 80 }}
					InputLabelProps={{ shrink: true }}
				/>
			</div>

			<div className={classes.textInputWrapper}>
				<TextField
					value={abbreviation}
					label="Custom Code Abbreviation"
					className={classes.textInput}
					variant="outlined"
					onChange={setCodeProperty('symbol_abbreviation')}
					inputProps={{ maxLength: 8 }}
					InputLabelProps={{ shrink: true }}
				/>
			</div>

			<div className={classes.textInputWrapper}>
				<TextField
					value={description}
					label="Custom Code Description"
					multiline
					minRows={6}
					maxRows={10}
					className={classes.textInput}
					variant="outlined"
					onChange={setCodeProperty('symbol_description')}
					inputProps={{ maxLength: 1000 }}
					InputLabelProps={{ shrink: true }}
				/>
			</div>

			<div className={classes.textInputWrapper}>
				<FormLabel component="legend">Attach to:</FormLabel>
				<RadioGroup value={mapTo} onChange={setCodeProperty('map_to')}>
					<FormControlLabel value="tooth" control={<Radio />} label="Tooth" />
					<FormControlLabel value="mouth" control={<Radio />} label="Mouth" />
				</RadioGroup>
			</div>

			<div>
				<Button
					variant="contained"
					color="primary"
					onClick={() => dispatch(actions.handleSimpleCodeSave())}
					disabled={saving}
				>
					Save
				</Button>
			</div>
		</div>
	);

};


export default SimpleCode;