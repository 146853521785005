import { alpha } from '@mui/material/styles';



import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	search: {
		position: 'relative',
		border: `2px solid`,
		borderColor: alpha(theme.palette.secondary[ 500 ], 0.5),
		borderRadius: theme.shape.borderRadius * 8,
		backgroundColor: alpha(theme.palette.primary[ 100 ], 0.25),
		'&:hover': {
			backgroundColor: alpha(theme.palette.grey[ 100 ], 0.75),
			borderColor: alpha(theme.palette.secondary[ 500 ], 0.50),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[ theme.breakpoints.up('sm') ]: {
			marginLeft: theme.spacing(3),
			width: 300,
		},
	},
	searchIcon: {
		width: theme.spacing(6),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		opacity: 0.5,
	},
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	inputInput: {
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(6),
		transition: theme.transitions.create('width'),
		width: '100%',
		[ theme.breakpoints.up('md') ]: {
			width: 200,
		},
	},
}));

export default useStyles;