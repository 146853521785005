import api from 'api';


const baseType = 'PATIENTS';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	SET_SEARCH_TERM: `${baseType}/SET_SEARCH_TERM`,
	RESET_SEARCH: `${baseType}/RESET_SEARCH`,
	RESET_ALL: `${baseType}/RESET_ALL`,
};

const initialState = {
	searchTerms: {
		patient_name: '',
		client_name: '',
		patient_breed: '',
		last_visit_start: '',
		last_visit_end: '',
	},
	patients: [],
	errors: [],
	loading: false,
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			console.log(action);
			return { ...state, ...action.data };

		case types.RESET_ALL:
			return { ...initialState };

		case types.RESET_SEARCH:
			return { ...state, searchTerms: { ...initialState.searchTerms } };

		case types.SET_SEARCH_TERM:
			return {
				...state,
				searchTerms: {
					...state.searchTerms,
					[ action.key ]: action.value,
				},
			};

		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	setSearchTerm: (key, value) => ({ type: types.SET_SEARCH_TERM, key, value }),


	resetSearch: () => ({ type: types.RESET_SEARCH }),


	resetAll: () => ({ type: types.RESET_ALL }),


	search: () => async (dispatch, getState) => {
		dispatch(actions.setState({
			loading: true,
			patients: [],
		}));

		try {
			let searchTerms = getState().patients.searchTerms;
			let results = await api.get(`/patients/list?${new URLSearchParams(searchTerms).toString()}`);
			dispatch(actions.setState({
				loading: false,
				patients: results.data.patients || [],
			}));
		} catch(err) {
			dispatch(actions.setState({
				loading: false,
				patients: [],
			}));
		}
	},
};