import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStroke,
	strokeWidth: 3,
	fillColor: colors.defaultFill,
	scaling: 1.68,
	rotation: 180,
	opacity: 1,
};
const defaultTop  = 710;
const defaultBot  = 905;
const canvasWidth = 3000;

function pos(newPos) {
	return canvasWidth / 1000 * newPos;
}

export default [
	// UPPER 100s
	{
		"name": "t109",
		"position": [pos(167), defaultTop-3],
		"options": defaultOptions
	},
	{
		"name": "t108",
		"position": [pos(217), defaultTop-36],
		"options": defaultOptions
	},
	{
		"name": "t107",
		"position": [pos(280), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "t106",
		"position": [pos(315), defaultTop-5],
		"options": defaultOptions
	},
	{
		"name": "t104",
		"position": [pos(375), defaultTop-97],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.15}
	},
	{
		"name": "t103",
		"position": [pos(427), defaultTop-8],
		"options": defaultOptions
	},
	{
		"name": "t102",
		"position": [pos(456), defaultTop+1],
		"options": defaultOptions
	},
	{
		"name": "t101",
		"position": [pos(476), defaultTop+7],
		"options": defaultOptions
	},

	// UPPER 200s
	{
		"name": "t201",
		"position": [pos(498), defaultTop+7],
		"options": defaultOptions
	},
	{
		"name": "t202",
		"position": [pos(523), defaultTop+1],
		"options": defaultOptions
	},
	{
		"name": "t203",
		"position": [pos(552), defaultTop-8],
		"options": defaultOptions
	},
	{
		"name": "t204",
		"position": [pos(600), defaultTop-97],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.15}
	},
	{
		"name": "t206",
		"position": [pos(665), defaultTop-5],
		"options": defaultOptions
	},
	{
		"name": "t207",
		"position": [pos(700), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "t208",
		"position": [pos(765), defaultTop-36],
		"options": defaultOptions
	},
	{
		"name": "t209",
		"position": [pos(810), defaultTop-3],
		"options": defaultOptions
	},

	// LOWER 400s
	{
		"name": "t409",
		"position": [pos(180), defaultBot+35],
		"options": defaultOptions
	},
	{
		"name": "t408",
		"position": [pos(240), defaultBot+35],
		"options": defaultOptions
	},
	{
		"name": "t407",
		"position": [pos(290), defaultBot+28],
		"options": defaultOptions
	},
	{
		"name": "t404",
		"position": [pos(375), defaultBot+90],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.15}
	},
	{
		"name": "t403",
		"position": [pos(427), defaultBot+1],
		"options": defaultOptions
	},
	{
		"name": "t402",
		"position": [pos(456), defaultBot-4],
		"options": defaultOptions
	},
	{
		"name": "t401",
		"position": [pos(476), defaultBot-4],
		"options": defaultOptions
	},

	// LOWER 300s
	{
		"name": "t301",
		"position": [pos(498), defaultBot-4],
		"options": defaultOptions
	},
	{
		"name": "t302",
		"position": [pos(523), defaultBot-4],
		"options": defaultOptions
	},
	{
		"name": "t303",
		"position": [pos(552), defaultBot+1],
		"options": defaultOptions
	},
	{
		"name": "t304",
		"position": [pos(600), defaultBot+90],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.15}
	},
	{
		"name": "t307",
		"position": [pos(667), defaultBot+28],
		"options": defaultOptions
	},
	{
		"name": "t308",
		"position": [pos(718), defaultBot+35],
		"options": defaultOptions
	},
	{
		"name": "t309",
		"position": [pos(780), defaultBot+35],
		"options": defaultOptions
	},
];