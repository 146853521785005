import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../../CustomCodes.redux';
import { Grid, Paper } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";



const grid         = 8,

	  getListStyle = isDraggingOver => ({
		  background: isDraggingOver ? 'lightblue' : 'lightgrey',
		  padding: grid,
		  width: 250,
		  maxHeight: 600,
		  overflowY: 'auto',
	  }),

	  getItemStyle = (isDragging, draggableStyle, type) => {
		  let color;

		  switch(type) {
			  case 'dx':
				  color = '#6d94b3';
				  break;
			  case 'tx':
				  color = '#dc8c8c';
				  break;
			  case 'other':
				  color = '#7ec17a';
				  break;
			  default:
				  color = '#c7c7c7';
		  }



		  if(isDragging) {
			  switch(type) {
				  case 'dx':
					  color = '#8ac0de';
					  break;
				  case 'tx':
					  color = '#efafc1';
					  break;
				  case 'other':
					  color = '#94e08f';
					  break;
				  default:
					  color = '#ffffff';
			  }
		  }

		  return {
			  // some basic styles to make the items look a bit nicer
			  userSelect: 'none',
			  padding: grid * 2,
			  margin: `0 0 ${grid}px 0`,

			  // change background colour if dragging
			  background: color,

			  // styles we need to apply on draggables
			  ...draggableStyle,
		  };
	  },

	  move         = (source, destination, droppableSource, droppableDestination) => {
		  const sourceClone = Array.from(source);
		  const destClone = Array.from(destination);
		  const [ removed ] = sourceClone.splice(droppableSource.index, 1);

		  destClone.splice(droppableDestination.index, 0, removed);

		  const result = {};
		  result[ droppableSource.droppableId ] = sourceClone;
		  result[ droppableDestination.droppableId ] = destClone;

		  return result;
	  },

	  reorder      = (list, startIndex, endIndex) => {

		  const result = Array.from(list);
		  const [ removed ] = result.splice(startIndex, 1);
		  result.splice(endIndex, 0, removed);

		  return result;
	  };


const DragDropContainer = ({ index }) => {
	const dispatch                 = useDispatch(),
		  [ itemDroppableKey ]     = useState(`items_${index}_droppable`),
		  [ selectedDroppableKey ] = useState(`selected_${index}_droppable`),
		  codes                    = useSelector(state => state.customCodes.multiSelectCodes),
		  allSelected              = useSelector(state => state.customCodes.multiSelectAllSelected),
		  options                  = useSelector(state => state.customCodes.conf.symbol_chart_config.options[ index ].codes);

	const [ state, setState ] = useState({
		items: [],
		selected: [],
	});

	console.log('STATE', state)


	console.log(itemDroppableKey, selectedDroppableKey);

	const id2List = {
			  [ itemDroppableKey ]: 'items',
			  [ selectedDroppableKey ]: 'selected',
		  },
		  getList = id => state[ id2List[ id ] ];


	const handleDragEnd = ({ source, destination }) => {
		if(!destination) {
			return;
		}

		if(source.droppableId === destination.droppableId) {
			const items = reorder(
				getList(source.droppableId),
				source.index,
				destination.index,
			);

			let newState = { ...state, items };

			if(source.droppableId === selectedDroppableKey) {
				newState = { ...state, selected: items };
			}

			dispatch(actions.setMultiSelectCodes(index, newState.selected.map(row => row.symbol_id)));
			setState(newState);
		} else {
			const result = move(
				getList(source.droppableId),
				getList(destination.droppableId),
				source,
				destination,
			);
			let newState = {
				items: result[ itemDroppableKey ],
				selected: result[ selectedDroppableKey ],
			};

			dispatch(actions.setMultiSelectCodes(index, newState.selected.map(row => row.symbol_id)));
			setState(newState);
		}
	};


	// Manage local state based on redux state
	useEffect(() => {
		setState({
			items: codes
				.filter(row => !allSelected.includes(row.symbol_id))
				.map(code => ({
					id: `symbol-${code.symbol_id}`,
					content: `${code.symbol_category} - ${code.symbol_name} (${code.symbol_abbreviation})`,
					symbol_id: code.symbol_id,
					symbol_type: code.symbol_category,
				})),

			selected: options
				.map(id => {
					let code = codes.find(row => row.symbol_id === id);
					if(!code) {
						return null;
					}

					return {
						id: `symbol-${code.symbol_id}`,
						content: `${code.symbol_category} - ${code.symbol_name} (${code.symbol_abbreviation})`,
						symbol_id: code.symbol_id,
						symbol_type: code.symbol_category,
					};
				})
				.filter(row => row !== null),
		});
	}, [ setState, codes, options, allSelected ]);


	return (
		<div style={{ width: '100%' }}>
			<Grid container spacing={4}>
				<DragDropContext onDragEnd={handleDragEnd}>
					<Grid item xs={2}>
						<Droppable droppableId={selectedDroppableKey}>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{state.selected.map((item, index) => (
										<Draggable
											key={item.id}
											draggableId={item.id}
											index={index}
										>
											{(provided, snapshot) => (
												<Paper
													elevation={3}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style,
														item.symbol_type,
													)}
												>
													{item.content}
												</Paper>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</Grid>


					<Grid item xs={3}>
						<Droppable droppableId={itemDroppableKey}>
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{state.items.map((item, index) => (
										<Draggable
											key={item.id}
											draggableId={item.id}
											index={index}
										>
											{(provided, snapshot) => (
												<Paper
													elevation={3}
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style,
														item.symbol_type,
													)}
												>
													{item.content}
												</Paper>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</Grid>

				</DragDropContext>
			</Grid>
		</div>
	);

};


export default DragDropContainer;