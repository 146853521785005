import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStroke,
	strokeWidth: 2.5,
	fillColor: colors.defaultFill,
	scaling: 1.35, // 0.7 for shrunk view with numbers?
	rotation: 0
};
const canvasWidth  = 1000;
const canvasHeight = 3000;
const x            = canvasWidth+100;
const y            = canvasHeight;

function pos(newPos, size) {
	return size / 1000 * (size === y ? newPos+35 : newPos);
}

export default [
	// UPPER 100s (left)
	{
		"name": "t101",
		"position": [pos(465, x), pos(47, y)],
		"options": defaultOptions
	},
	{
		"name": "t102",
		"position": [pos(412, x), pos(49, y)],
		"options": defaultOptions
	},
	{
		"name": "t103",
		"position": [pos(362, x), pos(54, y)],
		"options": defaultOptions
	},
	{
		"name": "t104",
		"position": [pos(280, x), pos(65, y)],
		"options": defaultOptions
	},
	{
		"name": "t106",
		"position": [pos(285, x), pos(124, y)],
		"options": defaultOptions
	},
	{
		"name": "t107",
		"position": [pos(253, x), pos(169, y)],
		"options": defaultOptions
	},
	{
		"name": "t108",
		"position": [pos(210, x), pos(228, y)],
		"options": defaultOptions
	},
	{
		"name": "t109",
		"position": [pos(205, x), pos(267, y)],
		"options": defaultOptions
	},

	// UPPER 200s (right)
	{
		"name": "t201",
		"position": [pos(524, x), pos(47, y)],
		"options": defaultOptions
	},
	{
		"name": "t202",
		"position": [pos(575, x), pos(49, y)],
		"options": defaultOptions
	},
	{
		"name": "t203",
		"position": [pos(622, x), pos(54, y)],
		"options": defaultOptions
	},
	{
		"name": "t204",
		"position": [pos(715, x), pos(65, y)],
		"options": defaultOptions
	},
	{
		"name": "t206",
		"position": [pos(712, x), pos(124, y)],
		"options": defaultOptions
	},
	{
		"name": "t207",
		"position": [pos(748, x), pos(169, y)],
		"options": defaultOptions
	},
	{
		"name": "t208",
		"position": [pos(791, x), pos(228, y)],
		"options": defaultOptions
	},
	{
		"name": "t209",
		"position": [pos(797, x), pos(267, y)],
		"options": defaultOptions
	},

	// LOWER 400s (left)
	{
		"name": "t401",
		"position": [pos(468, x), pos(630, y)],
		"options": defaultOptions
	},
	{
		"name": "t402",
		"position": [pos(415, x), pos(628, y)],
		"options": defaultOptions
	},
	{
		"name": "t403",
		"position": [pos(362, x), pos(626, y)],
		"options": defaultOptions
	},
	{
		"name": "t404",
		"position": [pos(274, x), pos(609, y)],
		"options": {...defaultOptions, rotation: -20}
	},
	{
		"name": "t407",
		"position": [pos(287, x), pos(510, y)],
		"options": defaultOptions
	},
	{
		"name": "t408",
		"position": [pos(228, x), pos(463, y)],
		"options": defaultOptions
	},
	{
		"name": "t409",
		"position": [pos(185, x), pos(407, y)],
		"options": defaultOptions
	},

	// LOWER 300s (right)
	{
		"name": "t301",
		"position": [pos(520, x), pos(630, y)],
		"options": defaultOptions
	},
	{
		"name": "t302",
		"position": [pos(571, x), pos(628, y)],
		"options": defaultOptions
	},
	{
		"name": "t303",
		"position": [pos(622, x), pos(626, y)],
		"options": defaultOptions
	},
	{
		"name": "t304",
		"position": [pos(715, x), pos(610, y)],
		"options": {...defaultOptions, rotation: 20}
	},
	{
		"name": "t307",
		"position": [pos(708, x), pos(510, y)],
		"options": defaultOptions
	},
	{
		"name": "t308",
		"position": [pos(768, x), pos(463, y)],
		"options": defaultOptions
	},
	{
		"name": "t309",
		"position": [pos(813, x), pos(407, y)],
		"options": defaultOptions
	},
];