import api from 'api';



const baseType = 'CHARTING_DEFAULTS';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	RESET_ALL: `${baseType}/RESET_ALL`,
	SET_PREF: `${baseType}/SET_PREF`,
};

const initialState = {
	loading: false,
	saving: false,
	symbols_full: [],
	preferences: {
		chart_quick_symbols: [],
		chart_font_size: null,
	},
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };

		case types.RESET_ALL:
			return { ...initialState };

		case types.SET_PREF:
			return {
				...state,
				preferences: {
					...state.preferences,
					[ action.key ]: action.value,
				},
			};

		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	resetAll: () => ({ type: types.RESET_ALL }),

	setPref: (key, value) => ({ type: types.SET_PREF, key, value }),

	loadDefaults: () => async (dispatch, getState) => {
		dispatch(actions.setState({
			loading: true,
		}));

		try {
			let defaults = await api.get(`/clinic-settings/charting-defaults`),
				symbols  = await api.get(`/resources/charting-symbols`);

			dispatch(actions.setState({
				loading: false,
				symbols_full: [
					...symbols.data.diagnostics,
					...symbols.data.treatments,
					...symbols.data.other,
				].sort((a, b) => a.symbol_abbreviation.localeCompare(b.symbol_abbreviation)),
				preferences: defaults.data.preferences,
			}));
		} catch(err) {
			dispatch(actions.setState({
				loading: false,
			}));
		}
	},



	saveQuickSymbols: (symbols) => async (dispatch, getState) => {
		dispatch(actions.setState({
			saving: true,
		}));

		try {
			await api.put(`/clinic-settings/charting-defaults`, {
				pref_name: 'chart_quick_symbols',
				data: symbols,
			});
			dispatch(actions.setState({
				saving: false,
			}));
		} catch(err) {
			dispatch(actions.setState({
				saving: false,
			}));
			console.log(err);
		}
	},


	savePref: (key, value) => async (dispatch, getState) => {
		dispatch(actions.setState({
			saving: true,
		}));

		try {
			await api.put(`/clinic-settings/charting-defaults`, {
				pref_name: key,
				data: value,
			});
			dispatch(actions.setState({
				saving: false,
			}));
		} catch(err) {
			dispatch(actions.setState({
				saving: false,
			}));
			console.log(err);
		}
	},



};