export default [
	[
		"dictionary",
		{
			"#23": [
				"Gradient",
				[
					[
						[
							0.74118,
							0.74118,
							0.74118
						],
						0.833
					],
					[
						[
							0.98824,
							0.98824,
							0.98824
						],
						1
					]
				],
				false
			]
		}
	],
	[
		"Group",
		{
			"name": "Layer_2_1_",
			"applyMatrix": true,
			"children": [
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							1,
							0,
							0,
							1,
							369.5,
							833.1
						],
						"clipMask": true,
						"type": "rectangle",
						"size": [
							739,
							1666.2
						],
						"radius": [
							0,
							0
						]
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									625.8,
									629.4
								],
								[
									10,
									109.9
								],
								[
									-15.3,
									-168
								]
							],
							[
								[
									530,
									333
								],
								[
									4.4,
									132.3
								],
								[
									-2.7,
									-81.5
								]
							],
							[
								[
									479.8,
									143.6
								],
								[
									47.1,
									51.6
								],
								[
									-28.6,
									-31.4
								]
							],
							[
								[
									368.6,
									100.9
								],
								[
									41.2,
									-0.2
								],
								[
									0,
									0
								]
							],
							[
								368.6,
								100.9
							],
							[
								368.6,
								100.9
							],
							[
								368.6,
								100.9
							],
							[
								[
									368.6,
									100.9
								],
								[
									0,
									0
								],
								[
									-41.4,
									-0.2
								]
							],
							[
								[
									257.2,
									143.6
								],
								[
									28.7,
									-31.4
								],
								[
									-47.1,
									51.6
								]
							],
							[
								[
									207,
									333
								],
								[
									2.7,
									-81.5
								],
								[
									-4.4,
									132.3
								]
							],
							[
								[
									111.2,
									629.4
								],
								[
									15.3,
									-168.1
								],
								[
									-10,
									109.9
								]
							],
							[
								[
									162.9,
									857.9
								],
								[
									7.8,
									-66.7
								],
								[
									-16.5,
									140.6
								]
							],
							[
								[
									191.6,
									1260.8
								],
								[
									-92.4,
									-300.1
								],
								[
									21.2,
									69
								]
							],
							[
								[
									217.9,
									1461.9
								],
								[
									-34.3,
									-65
								],
								[
									27.9,
									52.9
								]
							],
							[
								[
									367.5,
									1569.3
								],
								[
									-53.6,
									-1.3
								],
								[
									0.3,
									0
								]
							],
							[
								[
									368.5,
									1569.3
								],
								[
									-0.4,
									0
								],
								[
									0.3,
									0
								]
							],
							[
								[
									369.5,
									1569.3
								],
								[
									-0.4,
									0
								],
								[
									53.6,
									-1.3
								]
							],
							[
								[
									519.1,
									1461.9
								],
								[
									-27.9,
									52.9
								],
								[
									34.3,
									-65
								]
							],
							[
								[
									545.4,
									1260.8
								],
								[
									-21.2,
									69
								],
								[
									92.4,
									-300.1
								]
							],
							[
								[
									574.1,
									857.9
								],
								[
									16.5,
									140.6
								],
								[
									-7.8,
									-66.7
								]
							]
						],
						"closed": true,
						"fillColor": [
							0.8902,
							0.8902,
							0.8902
						]
					}
				],
				[
					"Group",
					{
						"applyMatrix": true,
						"children": [
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												295.8,
												193.7
											],
											[
												18.5,
												-28.9
											],
											[
												-30.6,
												47.8
											]
										],
										[
											[
												260.2,
												346.3
											],
											[
												16.3,
												-73.8
											],
											[
												-17.3,
												78.2
											]
										],
										[
											[
												217.9,
												610.9
											],
											[
												-2,
												-146.9
											],
											[
												1.7,
												119.7
											]
										],
										[
											[
												227.6,
												855.9
											],
											[
												3.8,
												-56.9
											],
											[
												-8.6,
												129.8
											]
										],
										[
											[
												244.9,
												1194.9
											],
											[
												-47.6,
												-219.7
											],
											[
												13.9,
												64.4
											]
										],
										[
											[
												283.2,
												1437.9
											],
											[
												-14.1,
												-62.7
											],
											[
												12.9,
												57.6
											]
										],
										[
											[
												367.6,
												1520.7
											],
											[
												-34.9,
												-1.2
											],
											[
												0.3,
												0
											]
										],
										[
											[
												368.6,
												1520.7
											],
											[
												-0.3,
												0
											],
											[
												0,
												0
											]
										],
										[
											[
												368.6,
												150.4
											],
											[
												0,
												0
											],
											[
												-0.3,
												0
											]
										],
										[
											[
												367.6,
												150.4
											],
											[
												0.3,
												0
											],
											[
												-26.7,
												-0.1
											]
										]
									],
									"closed": true,
									"fillColor": [
										0.98824,
										0.98824,
										0.98824
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												509.7,
												855.9
											],
											[
												8.6,
												129.8
											],
											[
												-3.8,
												-56.9
											]
										],
										[
											[
												519.4,
												610.9
											],
											[
												-1.7,
												119.7
											],
											[
												2.1,
												-146.9
											]
										],
										[
											[
												477.1,
												346.3
											],
											[
												17.3,
												78.3
											],
											[
												-16.3,
												-73.9
											]
										],
										[
											[
												441.5,
												193.7
											],
											[
												30.6,
												47.8
											],
											[
												-18.5,
												-28.9
											]
										],
										[
											[
												369.8,
												150.4
											],
											[
												26.6,
												0
											],
											[
												-0.3,
												0
											]
										],
										[
											[
												368.8,
												150.4
											],
											[
												0.3,
												0
											],
											[
												0,
												0
											]
										],
										[
											[
												368.8,
												1520.7
											],
											[
												0,
												0
											],
											[
												0.3,
												0
											]
										],
										[
											[
												369.8,
												1520.7
											],
											[
												-0.3,
												0
											],
											[
												34.8,
												-1.2
											]
										],
										[
											[
												454.2,
												1437.9
											],
											[
												-13,
												57.6
											],
											[
												14.1,
												-62.7
											]
										],
										[
											[
												492.5,
												1194.9
											],
											[
												-13.9,
												64.4
											],
											[
												47.4,
												-219.7
											]
										]
									],
									"closed": true,
									"fillColor": [
										0.98824,
										0.98824,
										0.98824
									]
								}
							]
						]
					}
				],
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							0.8645,
							-0.5027,
							0.5027,
							0.8645,
							297.02592,
							860.6433
						],
						"type": "ellipse",
						"size": [
							23.6,
							9
						],
						"radius": [
							11.8,
							4.5
						],
						"fillColor": [
							0.85098,
							0.85098,
							0.85098
						]
					}
				],
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							0.5027,
							-0.8645,
							0.8645,
							0.5027,
							441.94418,
							860.4504
						],
						"type": "ellipse",
						"size": [
							9,
							23.6
						],
						"radius": [
							4.5,
							11.8
						],
						"fillColor": [
							0.85098,
							0.85098,
							0.85098
						]
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								325.6,
								207.6
							],
							[
								368.7,
								202
							],
							[
								411.8,
								207.6
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								313.5,
								250.3
							],
							[
								368.7,
								244.7
							],
							[
								423.9,
								250.3
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								308.2,
								293.1
							],
							[
								368.7,
								287.5
							],
							[
								429.2,
								293.1
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								308.2,
								335.8
							],
							[
								368.7,
								330.2
							],
							[
								429.2,
								335.8
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								295.5,
								378.6
							],
							[
								368.7,
								372.9
							],
							[
								441.9,
								378.6
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								285.5,
								421.3
							],
							[
								368.7,
								415.7
							],
							[
								451.9,
								421.3
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								275.5,
								464
							],
							[
								368.7,
								458.4
							],
							[
								461.9,
								464
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								271.5,
								506.8
							],
							[
								368.7,
								501.2
							],
							[
								465.9,
								506.8
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								267.6,
								549.5
							],
							[
								368.7,
								543.9
							],
							[
								469.8,
								549.5
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								262.9,
								592.3
							],
							[
								368.7,
								586.6
							],
							[
								474.5,
								592.3
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								256,
								635
							],
							[
								368.7,
								629.4
							],
							[
								481.4,
								635
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								256.1,
								677.7
							],
							[
								368.7,
								672.1
							],
							[
								481.3,
								677.7
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Group",
					{
						"applyMatrix": true,
						"children": [
							[
								"Group",
								{
									"applyMatrix": true,
									"children": [
										[
											"Path",
											{
												"applyMatrix": false,
												"segments": [
													[
														[
															466.7,
															963.8
														],
														[
															5.3,
															112
														],
														[
															0,
															0
														]
													],
													[
														369.6,
														963.8
													],
													[
														368.7,
														963.8
													],
													[
														[
															271.6,
															963.8
														],
														[
															0,
															0
														],
														[
															-5.3,
															112
														]
													],
													[
														[
															282.7,
															1316.5
														],
														[
															-9.5,
															-178.7
														],
														[
															3.1,
															58
														]
													],
													[
														[
															307.7,
															1443.8
														],
														[
															-17.4,
															-53.4
														],
														[
															14.1,
															43.5
														]
													],
													[
														[
															367.7,
															1503.4
														],
														[
															-27.1,
															-1.1
														],
														[
															0.5,
															0
														]
													],
													[
														[
															369.1,
															1503.3
														],
														[
															-0.5,
															0
														],
														[
															0.5,
															0
														]
													],
													[
														[
															370.5,
															1503.4
														],
														[
															-0.5,
															0
														],
														[
															27.1,
															-1.1
														]
													],
													[
														[
															430.5,
															1443.8
														],
														[
															-14.1,
															43.4
														],
														[
															17.4,
															-53.4
														]
													],
													[
														[
															455.5,
															1316.5
														],
														[
															-3.1,
															58
														],
														[
															9.6,
															-178.8
														]
													]
												],
												"closed": true,
												"fillColor": [
													"gradient",
													[
														"#23"
													],
													[
														369.1493,
														1503.4
													],
													[
														369.1493,
														963.8001
													]
												]
											}
										]
									]
								}
							]
						]
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								368.7,
								1415.7
							],
							[
								362.3,
								818.1
							],
							[
								375.1,
								818.1
							]
						],
						"closed": true,
						"fillColor": [
							0.98824,
							0.98824,
							0.98824
						]
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								369.5,
								92.8
							],
							[
								[
									343.5,
									73.3
								],
								[
									26,
									-0.1
								],
								[
									-29.7,
									0
								]
							],
							[
								[
									204,
									97.7
								],
								[
									57.8,
									-21.3
								],
								[
									-40,
									14.8
								]
							],
							[
								[
									81.5,
									283.7
								],
								[
									15.3,
									-43.7
								],
								[
									-96.7,
									278.2
								]
							],
							[
								[
									77.7,
									1072.9
								],
								[
									-1.4,
									-316.4
								],
								[
									0.9,
									178.3
								]
							],
							[
								[
									207.9,
									1501
								],
								[
									-53.4,
									-89.4
								],
								[
									53.3,
									89.4
								]
							],
							[
								[
									368.5,
									1608.3
								],
								[
									-19.5,
									0
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						]
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								369.5,
								48.5
							],
							[
								[
									190.1,
									86.9
								],
								[
									89.9,
									-45.8
								],
								[
									-36.9,
									18.8
								]
							],
							[
								[
									33,
									395.8
								],
								[
									18.6,
									-145.3
								],
								[
									-41,
									320
								]
							],
							[
								[
									67.3,
									1125.2
								],
								[
									-1.4,
									-342.4
								],
								[
									0.9,
									192.9
								]
							],
							[
								[
									188.2,
									1501.2
								],
								[
									-49.5,
									-98.5
								],
								[
									49.8,
									98.7
								]
							],
							[
								[
									369.2,
									1627
								],
								[
									-19.4,
									0
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						]
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								369.5,
								92.8
							],
							[
								[
									395.5,
									73.3
								],
								[
									-26,
									-0.1
								],
								[
									29.7,
									0
								]
							],
							[
								[
									535,
									97.7
								],
								[
									-57.8,
									-21.3
								],
								[
									40,
									14.8
								]
							],
							[
								[
									657.5,
									283.7
								],
								[
									-15.3,
									-43.7
								],
								[
									96.7,
									278.2
								]
							],
							[
								[
									661.3,
									1072.9
								],
								[
									1.4,
									-316.4
								],
								[
									-0.9,
									178.3
								]
							],
							[
								[
									531.1,
									1501
								],
								[
									53.4,
									-89.4
								],
								[
									-53.3,
									89.4
								]
							],
							[
								[
									368.4,
									1608.3
								],
								[
									19.5,
									0
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						]
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								369.5,
								48.5
							],
							[
								[
									548.9,
									86.9
								],
								[
									-89.8,
									-45.8
								],
								[
									36.9,
									18.8
								]
							],
							[
								[
									706,
									395.8
								],
								[
									-18.5,
									-145.4
								],
								[
									41,
									320
								]
							],
							[
								[
									671.7,
									1125.2
								],
								[
									1.4,
									-342.4
								],
								[
									-0.9,
									192.9
								]
							],
							[
								[
									550.8,
									1501.2
								],
								[
									49.5,
									-98.5
								],
								[
									-49.7,
									98.8
								]
							],
							[
								[
									369.2,
									1627.1
								],
								[
									19.4,
									0
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						]
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									378.2,
									190.3
								],
								[
									6.2,
									0
								],
								[
									0,
									0
								]
							],
							[
								[
									358.8,
									190.3
								],
								[
									0,
									0
								],
								[
									-6.3,
									0
								]
							],
							[
								[
									347.5,
									179
								],
								[
									0,
									6.2
								],
								[
									0,
									0
								]
							],
							[
								[
									347.5,
									171
								],
								[
									0,
									0
								],
								[
									0,
									-6.3
								]
							],
							[
								[
									358.8,
									159.7
								],
								[
									-6.2,
									0
								],
								[
									0,
									0
								]
							],
							[
								368.8,
								158.7
							],
							[
								[
									378.2,
									159.7
								],
								[
									0,
									0
								],
								[
									6.3,
									0
								]
							],
							[
								[
									389.5,
									171
								],
								[
									0,
									-6.2
								],
								[
									0,
									0
								]
							],
							[
								[
									389.5,
									179
								],
								[
									0,
									0
								],
								[
									0,
									6.2
								]
							]
						],
						"closed": true,
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								330.8,
								794.3
							],
							[
								[
									271.4,
									857.6
								],
								[
									12,
									-30.9
								],
								[
									-14.6,
									37.7
								]
							],
							[
								[
									269.8,
									996
								],
								[
									6.2,
									-36.1
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								286.6,
								971.5
							],
							[
								[
									279,
									907.5
								],
								[
									0.1,
									16.7
								],
								[
									-0.1,
									-16.7
								]
							],
							[
								[
									284.2,
									867.5
								],
								[
									-7.7,
									24.8
								],
								[
									7.7,
									-24.8
								]
							],
							[
								[
									369.6,
									835.5
								],
								[
									-24.7,
									0
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									469.4,
									996
								],
								[
									0,
									0
								],
								[
									-6.2,
									-36.1
								]
							],
							[
								[
									467.8,
									857.6
								],
								[
									14.6,
									37.7
								],
								[
									-12,
									-30.9
								]
							],
							[
								408.4,
								794.3
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									369.6,
									835.5
								],
								[
									0,
									0
								],
								[
									24.7,
									0
								]
							],
							[
								[
									455,
									867.5
								],
								[
									-7.7,
									-24.8
								],
								[
									7.7,
									24.8
								]
							],
							[
								[
									460.2,
									907.5
								],
								[
									0.1,
									-16.7
								],
								[
									-0.1,
									16.7
								]
							],
							[
								452.6,
								971.5
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									308.8,
									847.8
								],
								[
									0,
									0
								],
								[
									0,
									90.2
								]
							],
							[
								[
									369.5,
									945.6
								],
								[
									0,
									-16.1
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									369.5,
									945.6
								],
								[
									0,
									0
								],
								[
									0,
									-16.1
								]
							],
							[
								[
									430.2,
									847.8
								],
								[
									0,
									90.2
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								320.5,
								843.7
							],
							[
								[
									369.5,
									913.5
								],
								[
									-53.3,
									1.4
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								355.5,
								836.5
							],
							[
								345,
								907.5
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								418.1,
								843.7
							],
							[
								[
									369.1,
									913.5
								],
								[
									53.3,
									1.4
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								383.1,
								836.5
							],
							[
								393.7,
								907.5
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.75
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									579.2,
									1272.9
								],
								[
									0,
									0
								],
								[
									-23,
									25
								]
							],
							[
								538.9,
								1291.9
							],
							[
								[
									579.2,
									1286.9
								],
								[
									-8,
									10
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									570.7,
									1307.7
								],
								[
									0,
									0
								],
								[
									-9.8,
									0
								]
							],
							[
								538.9,
								1298.4
							],
							[
								[
									559.1,
									1332.2
								],
								[
									3.8,
									-13.8
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									157.8,
									1272.9
								],
								[
									0,
									0
								],
								[
									23,
									25
								]
							],
							[
								198.1,
								1291.9
							],
							[
								[
									157.8,
									1286.9
								],
								[
									8,
									10
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									166.3,
									1307.7
								],
								[
									0,
									0
								],
								[
									9.8,
									0
								]
							],
							[
								198.1,
								1298.4
							],
							[
								[
									177.9,
									1332.2
								],
								[
									-3.8,
									-13.8
								],
								[
									0,
									0
								]
							]
						],
						"strokeColor": [
							0,
							0,
							0
						],
						"strokeWidth": 0.25
					}
				]
			]
		}
	]
]

export const canineTissueConfig = {
	scaling: [1.53, 1.37],
	position: [550, 1100],
	opacity: 0.6,
};