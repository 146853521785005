import makeStyles from '@mui/styles/makeStyles';
import { purple } from "@mui/material/colors";




const useStyles = makeStyles(theme => ({
	buttons: {
		backgroundColor: purple[ 600 ],
		'&:hover': {
			backgroundColor: purple[ 800 ],
		},
		'&:active': {
			backgroundColor: purple[ 800 ],
		},
	},

	textInputWrapper: {
		paddingBottom: 32,
	},

	textInput: {
		width: 500,
	},

}));



export default useStyles;