import { createTheme } from '@mui/material/styles';
import { indigo as primaryColor, deepOrange as secondaryColor, red as errorColor, grey } from '@mui/material/colors';



const theme = createTheme({
    palette: {
        primary: primaryColor,
        secondary: secondaryColor,
        error: errorColor,
        grey: grey,
        type: 'dark',
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    '@media (min-width: 600px)': {
                        minHeight: 56,
                        maxHeight: 56,
                    },
                    minHeight: 56,
                    maxHeight: 56,
                },
            },
        },
    },
});


export default theme;
