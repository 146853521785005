import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Button } from "@mui/material";
import { actions } from './UserManagement.redux';
import { useDispatch } from "react-redux";



const UsersTableCell = (props) => {
	const dispatch = useDispatch();


	switch(props.column.name) {
		case 'is_admin':
			return <Table.Cell {...props}>{props.value ? 'Admin' : 'Standard User'}</Table.Cell>;

		case 'remove':
			return (
				<Table.Cell {...props}>
					{props.row.is_current_user === false &&
					<Button
						color="secondary"
						variant="contained"
						onClick={() => dispatch(actions.setState({
							showDeleteModal: true,
							deleteInfo: { ...props.row },
						}))}
					>
						Remove
					</Button>
					}

					{props.row.is_current_user !== false && <> </>}

				</Table.Cell>
			);
		default:
			return <Table.Cell {...props} />;
	}
};


export default UsersTableCell;
