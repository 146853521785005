import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { CustomPaging, PagingState, SelectionState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import api from 'api';
import { push } from 'connected-react-router';



const NewReportDialog = ({ handleClose }) => {
    const dispatch                  = useDispatch(),
          [ rows, setRows ]         = useState([]),
          [ page, setPage ]         = useState(0),
          [ limit, setLimit ]       = useState(10),
          [ total, setTotal ]       = useState(0),
          [ loading, setLoading ]   = useState(false),
          [ selected, setSelected ] = useState([]),
          [ title, setTitle ]       = useState(''),
          [ columns ]               = useState([
              { name: 'template_name', title: 'Template' },
          ]),
          visitID                   = useSelector(state => state.chart.visit_id);


    const handleSelection = (selection) => {
        setSelected(selection.slice(-1));
    };


    const handleSetPage = (page) => {
        if(loading) {
            return false;
        }

        setPage(page);
    };


    const handleSetLimit = (limit) => {
        if(loading) {
            return false;
        }

        setLimit(limit);
    };


    const handleSetTitle = (e) => {
        setTitle(e.target.value);
    };



    const handleCreateReport = async () => {
        try {
            let result = await api.post('/reports', {
                report_name: title,
                template_id: rows[selected[0]].template_id,
                visit_id: visitID,
            });

            dispatch(push(`/reports/edit/${result.data.urpt_id}`));
        } catch (err) {
            console.error(err);
        }
    };


    useEffect(() => {
        setLoading(true);
        api.get('/reports/templates', {
            params: {
                page_size: limit,
                page_number: page,
            },
        }).then(res => {
            let { templates } = res.data;
            setRows(templates);
            setTotal(templates[0]?.total_records || 0);
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });
    }, [
        visitID,
        page,
        limit,
        setRows,
        setLoading,
        setTotal,
    ]);


    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={handleClose}
        >
            <DialogTitle>Create Report</DialogTitle>

            <DialogContent>
                <div>Select Template:</div>

                <Grid
                    rows={rows}
                    columns={columns}
                >

                    <SelectionState
                        selection={selected}
                        onSelectionChange={handleSelection}
                    />

                    <PagingState
                        currentPage={page}
                        defaultPageSize={10}
                        onCurrentPageChange={handleSetPage}
                        onPageSizeChange={handleSetLimit}
                    />

                    <CustomPaging
                        totalCount={parseInt(total)}
                    />

                    <Table />
                    <TableHeaderRow />

                    <PagingPanel pageSizes={[ 10, 20, 50, 100 ]} />

                    <TableSelection
                        selectByRowClick
                        highlightRow
                        showSelectionColumn={false}
                    />
                </Grid>

                <div>Enter Report Title:</div>
                <div>
                    <TextField
                        placeholder="Report Title"
                        maxLength={100}
                        value={title}
                        onChange={handleSetTitle}
                    />
                </div>


                <div>
                    Upon clicking Create Report, you will be taken to your new report. If you have unsaved work
                    on this chart, click cancel and save your work before creating a report.
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleCreateReport}
                    variant="contained"
                    color="primary"
                    disabled={(title.trim().length === 0 || loading || selected.length === 0)}
                >
                    Create Report
                </Button>

                <Button onClick={handleClose} variant="outlined">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};


export default NewReportDialog;
