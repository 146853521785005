import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStroke,
	strokeWidth: 3,
	fillColor: colors.defaultFill,
	scaling: 1.68,
	rotation: 0
};
const defaultTop  = 280; // 100 to line up with original
const defaultBot  = 1290; // 611 to line up with original
const canvasWidth = 3000;

function pos(newPos) {
	return canvasWidth / 1000 * newPos;
}

export default [
	// UPPER 100s
	{
		"name": "t109",
		"position": [pos(155), defaultTop-7],
		"options": defaultOptions
	},
	{
		"name": "t108",
		"position": [pos(207), defaultTop+5],
		"options": defaultOptions
	},
	{
		"name": "t107",
		"position": [pos(268), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "t106",
		"position": [pos(305), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "t104",
		"position": [pos(345), defaultTop-14],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.15}
	},
	{
		"name": "t103",
		"position": [pos(427), defaultTop-1],
		"options": defaultOptions
	},
	{
		"name": "t102",
		"position": [pos(455), defaultTop-2],
		"options": defaultOptions
	},
	{
		"name": "t101",
		"position": [pos(476), defaultTop],
		"options": defaultOptions
	},

	// UPPER 200s
	{
		"name": "t201",
		"position": [pos(498), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "t202",
		"position": [pos(519), defaultTop-1],
		"options": defaultOptions
	},
	{
		"name": "t203",
		"position": [pos(547), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "t204",
		"position": [pos(627), defaultTop-14],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.15}
	},
	{
		"name": "t206",
		"position": [pos(670), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "t207",
		"position": [pos(707), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "t208",
		"position": [pos(768), defaultTop+4],
		"options": defaultOptions
	},
	{
		"name": "t209",
		"position": [pos(820), defaultTop-6],
		"options": defaultOptions
	},

	// LOWER 400s
	{
		"name": "t409",
		"position": [pos(183), defaultBot-2],
		"options": defaultOptions
	},
	{
		"name": "t408",
		"position": [pos(245), defaultBot+7],
		"options": defaultOptions
	},
	{
		"name": "t407",
		"position": [pos(294), defaultBot+4],
		"options": defaultOptions
	},
	{
		"name": "t404",
		"position": [pos(370), defaultBot+15],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.15}
	},
	{
		"name": "t403",
		"position": [pos(420), defaultBot+4],
		"options": defaultOptions
	},
	{
		"name": "t402",
		"position": [pos(448), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "t401",
		"position": [pos(475.5), defaultBot+3],
		"options": defaultOptions
	},

	// LOWER 300s
	{
		"name": "t301",
		"position": [pos(498), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "t302",
		"position": [pos(525), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "t303",
		"position": [pos(552), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "t304",
		"position": [pos(602), defaultBot+17],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.15}
	},
	{
		"name": "t307",
		"position": [pos(680), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "t308",
		"position": [pos(728), defaultBot+8],
		"options": defaultOptions
	},
	{
		"name": "t309",
		"position": [pos(790), defaultBot-3],
		"options": defaultOptions
	},
];