import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStroke,
	strokeWidth: 3,
	fillColor: colors.defaultFill,
	scaling: 1.68,
	rotation: 0,
	opacity: 1
};
const defaultTop  = 710;
const defaultBot  = 905;
const canvasWidth = 3000;

function pos(newPos) {
	return canvasWidth / 1000 * newPos;
}

export default [
	// UPPER 100s
	{
		"name": "t110",
		"position": [pos(25), defaultTop-5],
		"options": defaultOptions
	},
	{
		"name": "t109",
		"position": [pos(65.5), defaultTop-10],
		"options": defaultOptions
	},
	{
		"name": "t108",
		"position": [pos(131), defaultTop-35],
		"options": defaultOptions
	},
	{
		"name": "t107",
		"position": [pos(198), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "t106",
		"position": [pos(248), defaultTop-14],
		"options": defaultOptions
	},
	{
		"name": "t105",
		"position": [pos(292), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "t104",
		"position": [pos(340), defaultTop-90],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.25}
	},
	{
		"name": "t103",
		"position": [pos(400), defaultTop-24],
		"options": defaultOptions
	},
	{
		"name": "t102",
		"position": [pos(438), defaultTop-6],
		"options": defaultOptions
	},
	{
		"name": "t101",
		"position": [pos(476), defaultTop-6],
		"options": defaultOptions
	},

	// UPPER 200s
	{
		"name": "t201",
		"position": [pos(523), defaultTop-6],
		"options": defaultOptions
	},
	{
		"name": "t202",
		"position": [pos(561), defaultTop-6],
		"options": defaultOptions
	},
	{
		"name": "t203",
		"position": [pos(599), defaultTop-24],
		"options": defaultOptions
	},
	{
		"name": "t204",
		"position": [pos(660), defaultTop-90],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.25}
	},
	{
		"name": "t205",
		"position": [pos(707), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "t206",
		"position": [pos(751), defaultTop-14],
		"options": defaultOptions
	},
	{
		"name": "t207",
		"position": [pos(801), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "t208",
		"position": [pos(868), defaultTop-35],
		"options": defaultOptions
	},
	{
		"name": "t209",
		"position": [pos(934), defaultTop-10],
		"options": defaultOptions
	},
	{
		"name": "t210",
		"position": [pos(974), defaultTop-4],
		"options": defaultOptions
	},

	// LOWER 400s
	{
		"name": "t411",
		"position": [pos(20), defaultBot],
		"options": defaultOptions
	},
	{
		"name": "t410",
		"position": [pos(55), defaultBot],
		"options": defaultOptions
	},
	{
		"name": "t409",
		"position": [pos(111.5), defaultBot+35],
		"options": defaultOptions
	},
	{
		"name": "t408",
		"position": [pos(178), defaultBot+20],
		"options": defaultOptions
	},
	{
		"name": "t407",
		"position": [pos(226), defaultBot+15],
		"options": defaultOptions
	},
	{
		"name": "t406",
		"position": [pos(274), defaultBot+14],
		"options": defaultOptions
	},
	{
		"name": "t405",
		"position": [pos(310), defaultBot],
		"options": defaultOptions
	},
	{
		"name": "t404",
		"position": [pos(359), defaultBot+81],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.25}
	},
	{
		"name": "t403",
		"position": [pos(398), defaultBot+13],
		"options": defaultOptions
	},
	{
		"name": "t402",
		"position": [pos(436), defaultBot+11],
		"options": defaultOptions
	},
	{
		"name": "t401",
		"position": [pos(475.5), defaultBot+6],
		"options": defaultOptions
	},

	// LOWER 300s
	{
		"name": "t301",
		"position": [pos(523), defaultBot+6],
		"options": defaultOptions
	},
	{
		"name": "t302",
		"position": [pos(563), defaultBot+11],
		"options": defaultOptions
	},
	{
		"name": "t303",
		"position": [pos(600), defaultBot+13],
		"options": defaultOptions
	},
	{
		"name": "t304",
		"position": [pos(641), defaultBot+81],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.25}
	},
	{
		"name": "t305",
		"position": [pos(688), defaultBot],
		"options": defaultOptions
	},
	{
		"name": "t306",
		"position": [pos(724), defaultBot+14],
		"options": defaultOptions
	},
	{
		"name": "t307",
		"position": [pos(773), defaultBot+15],
		"options": defaultOptions
	},
	{
		"name": "t308",
		"position": [pos(820.5), defaultBot+20],
		"options": defaultOptions
	},
	{
		"name": "t309",
		"position": [pos(887), defaultBot+35],
		"options": defaultOptions
	},
	{
		"name": "t310",
		"position": [pos(943.5), defaultBot],
		"options": defaultOptions
	},
	{
		"name": "t311",
		"position": [pos(978), defaultBot+1],
		"options": defaultOptions
	},
];