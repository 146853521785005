module.exports = {
	"t110": {
		"paths": [
			"M30.6,109.5c0,0,3.7-11.5,3.7-15.2s0-9.6,4.4-9.3s5.2,8.2,5.6,10.7s2.2,12.6,2.2,12.6l-4.1,16.3L31.7,124      l-4.8-9.3L30.6,109.5z",
			"M33.2,129.5c3.3,3,7.4,5.9,10.7,5.2s10-7.4,7.8-16.3c4.8-11.1,4.8-8.9,5.6-16.7c0.7-7.8,3.3-17.8-3.7-17.8      s-4.8,5.9-6.7,9.6C45,97.3,38.5,115,36.7,115c-1.9,0-2.8-5.1-5.4-10.6c-2.6-5.6-5.6-15.9-8.2-18.2s-6.7-5.2-9.3-3      s-1.5,13,0,17.4s4.1,14.8,5.2,17.4c-4.8,3.3-5.9,5.9-3.3,10.4C18.3,132.9,26.9,138.4,33.2,129.5z"
		],
		"baseLine": {
			"x1": "19.1",
			"x2": "51.7",
			"y1": "118",
			"y2": "118"
		}
	},
	"t109": {
		"paths": [
			"M96.5,108c1.9-0.7,9.9-28.5,9.4-31.1s2.4-4.8,6.1-4.8s6.1,2.2,8.5,6.3c2.4,4.1,6.1,17.4,6.1,17.4l-6.1,22.2      l-19.3,4.8L96.5,108z",
			"M137.9,117.7c10.8,4.8,9.4,21.9-4.7,23s-15.5-3-21.6-10c-12.7,10.4-18.3,10.7-25.9,8.5      c-7.5-2.2-13.6-5.9-6.6-22.2c-7.5-15.3-12.2-23-11.2-29c0.9-5.9,4.7-18.2,10.8-18.2s10.8,1.9,10.8,8.9s6.1,13.7,8.9,19.6      s4.2,15.2,7.1,15.2s3.3-5.9,10.3-17s8.5-24.1,12.2-26.7c3.8-2.6,16-3.7,17.9,4.8S142.1,114.3,137.9,117.7z"
		],
		"baseLine": {
			"x1": "78.8",
			"x2": "137.9",
			"y1": "118",
			"y2": "118"
		}
	},
	"t108": {
		"paths": [
			"M216.3,114.6c0,0,4.9-20.7,4.9-32.4s-1.1-26.2,5.2-28.7c1.9-0.7,4-2,6.4-2c5.4,0,8.4,1.7,10.9,10.6      s5.4,31.6,1.5,42.7c-4,11.1-16.3,14.8-16.3,14.8L216.3,114.6z",
			"M200,148.9c0.5,2.2,4.5,14.2,11.1,23s6,11.1,10.6,11.2c4.5,0.2,50.5-35.6,55-46.2c4.4-10.5,6-18.6,0.3-23.8      c0,0-0.1-4.6,1.3-10.6c1.4-5.9,4.6-10.3,4.8-14.3c0.2-4,1.6-18.6,0.7-24.8c-0.8-6.2-2.4-12-10.1-11.5C266,52.5,263,57.8,261,66      s-25.7,45.9-35.6,45.1c-10.6-0.8-35.7-46.3-35.7-46.3s-2.9-10.9-4.7-15.1c-1.3-3-13.4-9.1-17.9,0.8c-5.2,11.4-5.8,24.8-5.4,30.9      c0.5,7.4,0.3,33.5,3.3,36.9c-6.4,4.6-0.1,33.3,4.4,34.9C173.9,155,200,153.1,200,148.9z"
		],
		"baseLine": {
			"x1": "165.1",
			"x2": "280",
			"y1": "118.4",
			"y2": "118.4"
		}
	},
	"t107": {
		"paths": [
			"M307.2,118.4c-5.8,11,2.7,14.3,6.6,14c-0.3,5.2,4.7,10.7,8.8,7.4c5.8,8.8,8,24.2,14.6,24.7      c7.1-1.4,19.8-14.8,24.2-22.8c6.6-7.7,10.7-8.8,11-13.7s-2.5-5.2-0.8-11.5c1.6-6.3,8.2-29.7,4.9-39s-8-23.4-17-20.6      s-8.5,6.9-7.7,11.5s-3.5,24.3-3.8,30.4c-0.3,6-3.6,14-5.8,13.7c-2.2-0.3-6.4-30.4-6.4-35.3s3-17.3-4.7-20.3s-10.7-3.3-15.9,6.3      S304.5,82.1,307.2,118.4z"
		],
		"baseLine": {
			"x1": "307.2",
			"x2": "371",
			"y1": "118",
			"y2": "118"
		}
	},
	"t106": {
		"paths": [
			"M404.5,116.5c-4.1,6-2.9,11.8,1.9,13.8c2.5,4.3,3.9,1,6.8,2.5c2.3,5,5.2,21.5,11.8,21.7      c6.6,0.2,27.5-19.6,31.6-25c2.6-3.4-1.9-5.6-3.9-9.1c0.2-7,5-25.8,4.5-31.4s-5.2-24.8-13.8-22.9c-8.7,1.9-5.8,10.3-5.6,14.3      s-4.8,24.1-5.6,26c-0.8,1.9-1.4,5.8-2.5,5.8s-4.3-22.5-4.1-26.6s0.6-15.1,0-17.2s-7.4-5-9.7-4.3c-2.3,0.6-9.7,10.5-11.4,24.2      S403.5,113,404.5,116.5z"
		],
		"baseLine": {
			"x1": "452.9",
			"x2": "403.7",
			"y1": "118",
			"y2": "118"
		}
	},
	"t105": {
		"paths": [
			"M512.3,116.4c0,0,6.5,2.2,7.4,6.7c0.9,4.4-3.1,10.9-4.9,13.3s-5.8,7.8-9.5,7.6s-3.5-5.2-4.8-8.2      s-5.6-8.3-1.9-15.4c-1.9-9.8-3-35-3.3-37.6c-0.4-2.6,3-4.4,4.6-4.4c1.7,0,5.4-0.2,7.6,10.2C509.7,99,511,107.1,512.3,116.4z"
		],
		"baseLine": {
			"x1": "515.5",
			"x2": "498.3",
			"y1": "118",
			"y2": "118"
		}
	},
	"t104": {
		"paths": [
			"M564.5,118c4.9,17.5,26,32.5,32.3,46c8.4,18.2,11.7,31.9,11.5,37.8s0.3,15,4.5,14.7      c2.8-0.2,3.3-1.5,10.6-14c7.2-12.5,9.2-45.9,7.9-63.8c-1.4-17.9-19.6-48.4-30.2-60.9c-12.7-15-24-33.1-49-47.1      c-17.3-9.7-37.9-23.2-44-11.8C501.9,30.5,555.2,85,564.5,118z"
		],
		"baseLine": {
			"x1": "625.3",
			"x2": "564.5",
			"y1": "117.9",
			"y2": "118"
		}
	},
	"t103": {
		"paths": [
			"M688,118c-11.7,7.7-9.6,8.4-8.3,11.9c1.2,3.5,7.1-0.2,5.9,9.1c-1.2,9.4-4,30,4.3,31.6      c8.6,1.7,12.8-18.8,15.3-21.5c4.3,1.5,8.9-5.9,10.1-9.4c-0.3-3.7,3-5.4,3-13.1c0-4.8-3.1-4.2-2.8-8.6s4-22.2,0.4-41.7      c-3-16-17.4-31.3-27.9-31.1s-2,20.4,1.6,29C693.8,84.4,691.4,107.6,688,118z"
		],
		"baseLine": {
			"x1": "688",
			"x2": "715.5",
			"y1": "118",
			"y2": "118"
		}
	},
	"t102": {
		"paths": [
			"M759.3,117.9c-5.2,5.4-8.4,14.3-5.2,17.8s3.5-3.5,4.4,2.2c1,5.7,1.7,13.1,7.4,12.6s9.4-10.6,9.1-16.1      c4.2,3.2,5.4-3.7,4.7-7.7s-3.2-8.2-3.2-8.2s0.5-23.5-0.7-36.3s-0.2-17.9-5.6-17.9s-6.7,9-7.7,19.7S760.8,110.7,759.3,117.9z"
		],
		"baseLine": {
			"x1": "759.3",
			"x2": "776.6",
			"y1": "117.9",
			"y2": "117.9"
		}
	},
	"t101": {
		"paths": [
			"M825.2,116.1c-5.2,5.4-7.4,10.9-4.2,14.3c3.2,3.5,2.7-2.5,3.7,3.2s3.5,16.8,9.1,16.3    c5.7-0.5,6.9-8.6,6.7-14.1c4.2,3.2,6.9-2,6.2-5.9c-0.7-4-4.2-13.1-4.2-13.1s1.2-22.5,0-35.3S836.6,69,833.9,69    c-2.7,0-5.9,1.9-6.9,12.5S826.7,108.9,825.2,116.1z"
		],
		"baseLine": {
			"x1": "825.2",
			"x2": "842.5",
			"y1": "118",
			"y2": "118"
		}
	},
	"t210": {
		"paths": [
			"M1718,109.5c0,0-3.7-11.5-3.7-15.2s0-9.6-4.4-9.3s-5.2,8.2-5.6,10.7c-0.4,2.6-2.2,12.6-2.2,12.6l4.1,16.3      l10.7-0.7l4.8-9.3L1718,109.5z",
			"M1715.4,129.5c-3.3,3-7.4,5.9-10.7,5.2c-3.3-0.7-10-7.4-7.8-16.3c-4.8-11.1-4.8-8.9-5.6-16.7      s-3.3-17.8,3.7-17.8s4.8,5.9,6.7,9.6c1.9,3.7,8.3,21.4,10.2,21.4s2.8-5.1,5.4-10.6c2.6-5.6,5.6-15.9,8.2-18.2s6.7-5.2,9.3-3      c2.6,2.2,1.5,13,0,17.4s-4.1,14.8-5.2,17.4c4.8,3.3,5.9,5.9,3.3,10.4C1730.3,132.9,1721.7,138.4,1715.4,129.5z"
		],
		"baseLine": {
			"x1": "1729.5",
			"x2": "1696.9",
			"y1": "118",
			"y2": "118"
		}
	},
	"t209": {
		"paths": [
			"M1652.1,108c-1.9-0.7-9.9-28.5-9.4-31.1s-2.4-4.8-6.1-4.8s-6.1,2.2-8.5,6.3c-2.4,4.1-6.1,17.4-6.1,17.4      l6.1,22.2l19.3,4.8L1652.1,108z",
			"M1610.7,117.7c-10.8,4.8-9.4,21.9,4.7,23s15.5-3,21.6-10c12.7,10.4,18.3,10.7,25.9,8.5      c7.5-2.2,13.6-5.9,6.6-22.2c7.5-15.2,12.2-23,11.3-28.9c-0.9-5.9-4.7-18.2-10.8-18.2s-10.8,1.9-10.8,8.9s-6.1,13.7-8.9,19.6      s-4.2,15.2-7.1,15.2c-2.8,0-3.3-5.9-10.3-17s-8.5-24.1-12.2-26.7s-16-3.7-17.9,4.8S1606.5,114.3,1610.7,117.7z"
		],
		"baseLine": {
			"x1": "1669.9",
			"x2": "1610.7",
			"y1": "118",
			"y2": "118"
		}
	},
	"t208": {
		"paths": [
			"M1531.9,114.2c0,0-4.9-20.7-4.9-32.4s1.1-26.2-5.2-28.7c-1.9-0.7-4-2-6.4-2c-5.4,0-8.4,1.7-10.9,10.6      s-5.4,31.6-1.5,42.7s16.3,14.8,16.3,14.8L1531.9,114.2z",
			"M1548.2,148.5c-0.5,2.2-4.5,14.2-11.1,23s-6,11.1-10.6,11.2s-50.5-35.6-55-46.2s-6-18.6-0.3-23.8      c0,0,0.1-4.6-1.3-10.6c-1.4-5.9-4.6-10.3-4.8-14.3c-0.2-4-1.6-18.6-0.7-24.8c0.8-6.2,2.4-12,10.1-11.5c7.7,0.6,10.7,5.8,12.7,14      c1.9,8.2,25.7,45.9,35.6,45.1c10.6-0.8,35.7-46.3,35.7-46.3s2.9-10.9,4.7-15.1c1.3-3,13.4-9.1,17.9,0.8      c5.2,11.4,5.8,24.8,5.4,30.9c-0.5,7.4-0.3,33.5-3.3,36.9c6.4,4.6,0.1,33.3-4.4,34.9C1574.3,154.6,1548.2,152.7,1548.2,148.5z"
		],
		"baseLine": {
			"x1": "1583.1",
			"x2": "1468.2",
			"y1": "118",
			"y2": "118"
		}
	},
	"t207": {
		"paths": [
			"M1441.4,118.4c5.8,11-2.7,14.3-6.6,14c0.3,5.2-4.7,10.7-8.8,7.4c-5.8,8.8-8,24.2-14.6,24.7      c-7.1-1.4-19.8-14.8-24.2-22.8c-6.6-7.7-10.7-8.8-11-13.7c-0.3-4.9,2.5-5.2,0.8-11.5c-1.6-6.3-8.2-29.7-4.9-39s8-23.4,17-20.6      c9.1,2.7,8.5,6.9,7.7,11.5s3.5,24.3,3.8,30.4c0.3,6,3.6,14,5.8,13.7c2.2-0.3,6.4-30.4,6.4-35.3s-3-17.3,4.7-20.3      s10.7-3.3,15.9,6.3C1438.6,72.7,1444.1,82.1,1441.4,118.4z"
		],
		"baseLine": {
			"x1": "1441.4",
			"x2": "1377.6",
			"y1": "118",
			"y2": "118"
		}
	},
	"t206": {
		"paths": [
			"M1344.1,116.5c4.1,6,2.9,11.8-1.9,13.8c-2.5,4.3-3.9,1-6.8,2.5c-2.3,5-5.2,21.5-11.8,21.7      c-6.6,0.2-27.5-19.6-31.6-25c-2.6-3.4,1.9-5.6,3.9-9.1c-0.2-7-5-25.8-4.5-31.4c0.4-5.6,5.2-24.8,13.8-22.9      c8.7,1.9,5.8,10.3,5.6,14.3c-0.2,3.9,4.8,24.1,5.6,26s1.4,5.8,2.5,5.8c1,0,4.3-22.5,4.1-26.6c-0.2-4.1-0.6-15.1,0-17.2      s7.4-5,9.7-4.3c2.3,0.6,9.7,10.5,11.4,24.2S1345.1,113,1344.1,116.5z"
		],
		"baseLine": {
			"x1": "1295.7",
			"x2": "1344.9",
			"y1": "118",
			"y2": "118"
		}
	},
	"t205": {
		"paths": [
			"M1236.3,116.4c0,0-6.5,2.2-7.4,6.7s3.1,10.9,4.9,13.3s5.8,7.8,9.5,7.6s3.5-5.2,4.8-8.2s5.6-8.3,1.9-15.4      c1.9-9.8,3-35,3.3-37.6c0.4-2.6-3-4.4-4.6-4.4s-5.4-0.2-7.6,10.2C1238.9,99,1237.6,107.1,1236.3,116.4z"
		],
		"baseLine": {
			"x1": "1233.1",
			"x2": "1250.3",
			"y1": "118",
			"y2": "118"
		}
	},
	"t204": {
		"paths": [
			"M1184.1,118.1c-5,17.5-26.1,32.4-32.4,45.9c-8.5,18.1-11.8,31.9-11.7,37.7c0.1,5.9-0.4,15-4.5,14.7      c-2.8-0.2-3.3-1.5-10.5-14s-9.1-45.9-7.7-63.8s19.8-48.4,30.4-60.8c12.8-15,24.1-33,49.1-46.9c17.3-9.6,37.9-23.1,44-11.7      C1247,30.9,1193.5,85.1,1184.1,118.1z"
		],
		"baseLine": {
			"x1": "1123.2",
			"x2": "1184.1",
			"y1": "118.1",
			"y2": "118.1"
		}
	},
	"t203": {
		"paths": [
			"M1060.6,118c11.7,7.7,9.6,8.4,8.3,11.9c-1.2,3.5-7.1-0.2-5.9,9.1c1.2,9.4,4,30-4.3,31.6      c-8.6,1.7-12.8-18.8-15.3-21.5c-4.3,1.5-8.9-5.9-10.1-9.4c0.3-3.7-3-5.4-3-13.1c0-4.8,3.1-4.2,2.8-8.6s-4-22.2-0.4-41.7      c3-16,17.4-31.3,27.9-31.1s2,20.4-1.6,29C1054.8,84.4,1057.2,107.6,1060.6,118z"
		],
		"baseLine": {
			"x1": "1060.6",
			"x2": "1033.1",
			"y1": "118",
			"y2": "118"
		}
	},
	"t202": {
		"paths": [
			"M989.3,118.2c5.2,5.4,8.4,14.3,5.2,17.8s-3.5-3.5-4.4,2.2c-1,5.7-1.7,13.1-7.4,12.6s-9.4-10.6-9.1-16.1      c-4.2,3.2-5.4-3.7-4.7-7.7s3.2-8.2,3.2-8.2s-0.5-23.5,0.7-36.3s0.2-17.9,5.6-17.9s6.7,9,7.7,19.7S987.8,111,989.3,118.2z"
		],
		"baseLine": {
			"x1": "989.3",
			"x2": "972",
			"y1": "118.2",
			"y2": "118.2"
		}
	},
	"t201": {
		"paths": [
			"M923.4,118c5.2,5.4,7.4,10.9,4.2,14.3c-3.2,3.5-2.7-2.5-3.7,3.2s-3.5,16.8-9.1,16.3      c-5.7-0.5-6.9-8.6-6.7-14.1c-4.2,3.2-6.9-2-6.2-5.9c0.7-4,4.2-13.1,4.2-13.1s-1.2-22.5,0-35.3s5.9-12.5,8.6-12.5      s5.9,1.9,6.9,12.5S921.9,110.8,923.4,118z"
		],
		"baseLine": {
			"x1": "923.4",
			"x2": "906.1",
			"y1": "118",
			"y2": "118"
		}
	},
	"t411": {
		"paths": [
			"M15.3,345.5c-4.8-4.5-5-7.8-2.8-10.3c2.3-2.5,11-10.8,15.1-10.5c4,0.3,14.6,4.3,15.1,8.8s2,7,0,10.8      c-2.5,12.5-6.5,26.1-7.3,29.1s-1.5,6-6.8,5.8s-7.5-1.3-7.8-4.8C20.8,374.3,16.6,348.5,15.3,345.5z"
		],
		"baseLine": {
			"x1": "15.3",
			"x2": "42.3",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t410": {
		"paths": [
			"M68.5,345.6c-5.5-8-5.5-12-4-15.6c1.5-3.5,11-2.3,14.3-0.5s4.8,2.5,6.8-0.3s11-10,13.3-9.5      c2.2,0.5,11,7.5,13.6,11.8c-0.5,6-3.8,14.1-6,15.1c1,13.1,5.5,15.8,1.8,23.1c-3.8,7.3-5.8,17.6-11.5,13.6      c-5.8-4-2.8-4.1-2.6-13.1c0.3-9-2.4-20.1-6-20c-4.2,0.2-5.8,7.9-5.3,18s1.8,14.3-4,14.8s-11.8-9.5-11.3-22.1      C68,348.4,68.5,345.6,68.5,345.6z"
		],
		"baseLine": {
			"x1": "68.5",
			"x2": "107.6",
			"y1": "345.6",
			"y2": "345.6"
		}
	},
	"t409": {
		"paths": [
			"M138.7,323.9c0,0,3.6-12.3,9.7-11.5c6.1,0.8,18.6,11,22.8,10.3s-8.6,21.8-8.6,21.8l-23.9-1.3V323.9z",
			"M220.7,399.2c1.4,11.1,4.8,19.6,14.3,20.3s8.7-11.5,10.2-20.1c1.5-8.5-3.6-43.2-4.1-51c2.6-1.3,7.9,0,9.7-8      S239,298,236.5,298s-15.6,8.8-15.6,8.8l-2.3,8c0,0,0,0-1-6.3s-25.6-22.6-31.2-26.1c-5.6-3.5-9-3.9-11.5,4.5      c-2.3,7.7-5.5,27.2-10.6,33.2s-4.1,7.9-5.9,7.9s-1.7,0.3-3.2-2.2s-5.1-0.5-12.8-5s-11.3,0.5-14.8,8.8      c-3.6,8.3-2.6,10.3,6.4,17.1c0,0-2.8,9-3.8,20.6c-1,11.5,2.5,40.8,16.8,40.8c8.1,0,14.9-18.9,23.4-35.2      c6.5-12.4,12-19.3,22-19.3C202.9,353.5,217.4,372.1,220.7,399.2z"
		],
		"baseLine": {
			"x1": "132.1",
			"x2": "248",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t408": {
		"paths": [
			"M274.4,347c-3.7-3.9-5.8-4.1-6.7-7.6c-0.9-3.5,0-5.8,2.3-7.4s-1.2-3.2-0.5-5.3c0.7-2.1,5.8-9.2,9.7-9.4      c3.9-0.2,9,8.3,9,8.3c-1.6-9.9,9.4-27.9,11.5-28.3s32.3,20.3,35.7,26.5s6.9,15.2,2.5,19.6c1.4,13.8,5.8,30.2,4.8,39.4      c-0.9,9.2-5.3,32.7-15.9,31.8c-7.9-0.6-10.1-4.7-9.4-10s0.6-54.3-9.7-54.5c-8.6-0.2-7.4,29-7.6,34.1s-0.9,22.8-1.2,25.8      s-7.4,7.4-10.7,6.5c-3.2-0.9-12.7-14.1-14.3-25.4C272.6,379.7,272.3,351.4,274.4,347z"
		],
		"baseLine": {
			"x1": "272.8",
			"x2": "338.2",
			"y1": "345.4",
			"y2": "345.4"
		}
	},
	"t407": {
		"paths": [
			"M363,347.4c-3.4-4.1-5.5-5.1-4.1-8.5s4.4-5.8,6.8-5.5c-1-7.2,1.4-12,2.7-12s5.5,2,6.1,3.1      c0.7-3.8,7.2-23.2,9.6-22.9c2.4,0.3,21.5,23.9,27.2,25.5c5.6,1.5,7.5,1.9,7.7,5s-3.4,10.6-3.1,13.3c0.3,2.7,5.1,22.4,5.8,31.3      c0.7,8.9-4.8,28.9-11.6,30.4c-6.8,1.5-11.6-0.2-11.6-4.4c0-4.3,0.5-19.1-2.2-31.3c-2.7-12.1-1.8-20-6.2-20      c-4.3,0-4.3,22.5-4.9,28.3s0.5,21.4-0.5,23.7c-1,2.4-5.6,8.7-11.3,3.2s-9.9-13.2-10.8-28C361.8,363.8,360.9,354.4,363,347.4z"
		],
		"baseLine": {
			"x1": "361.9",
			"x2": "415.9",
			"y1": "345.4",
			"y2": "345.4"
		}
	},
	"t406": {
		"paths": [
			"M447.5,347.2c-4.6-7.6-4.3-10.7-0.9-13c3.3-2.2,2.6,0.7,4.3-0.7c1.7-1.5,1.9-3.1,3.5-3.1      c1.7,0,10.5-10.4,12.4-17.2s6.5-9.1,8-9.1s23.1,25.2,24.4,26.3c1.3,1.1,5.4,1.1,4.3,5.4s-1.3,9.1-1.3,9.1s8,31.3,3.7,41.8      s-3.9,18.1-13.7,17.2s-7.1-5.4-6.2-13.3s-2-25.1-2.2-27s-2.6-12.6-6.7-12.1c-3.5,0.5-5.5,12-5.5,15s-0.9,21.2-0.7,28.3      s1.7,7.7-2.7,9.5s-8.9,4.8-13.5-3.1c-4.6-8-6.8-20.4-7-27C447.3,367.3,445.7,354,447.5,347.2z"
		],
		"baseLine": {
			"x1": "446.6",
			"x2": "502.3",
			"y1": "345.6",
			"y2": "345.6"
		}
	},
	"t405": {
		"paths": [
			"M532.6,347c-3.4-4-6.2-4.1-5.1-7.5c1.1-3.4,4.1-6.2,5.5-10.2c1.3-4,2.8-5.5,3.6-5.5s11.5,4.5,13.6,7.7      s-1.1,15.6-2.8,16.2s1.7,17.1-0.2,25.2s-2.6,15.4-7,15.1c-4.3-0.4-5.8-7-5.8-19.4C534.3,356.3,533.4,351,532.6,347z"
		],
		"baseLine": {
			"x1": "531.1",
			"x2": "548.7",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t404": {
		"paths": [
			"M643.8,257.5c-6.8-1.2-4.3,6.7-7.3,27.1c-2.4,16.7-13.7,33.7-25.2,42.6c-3.9,3-6.4,5.8-8.4,11.5      c-3.3,9.3-33.5,50.8-43.1,61.5c-10.1,11.1-33.5,27.4-28.7,36.4c4.6,8.6,37.3-3.6,56.4-13.7s51.2-34.5,66.6-69      c15.5-34.5,9.5-53.1,9.1-56C663,295.1,654.7,259.4,643.8,257.5z"
		],
		"baseLine": {
			"x1": "599.4",
			"x2": "657.7",
			"y1": "345.4",
			"y2": "345.4"
		}
	},
	"t403": {
		"paths": [
			"M687.2,347.7c-3.8-5.8-7.3-15.8-7.3-18.8c2.5-1.5,4.8-3.3,5.8-1.3c0-2,5.3-21.3,7-21.6c1.8-0.3,6.5,2.8,7,5      c5.8-1.8,8.3,0.8,10,1.5c1.8,0.8,1.8,13.8,0.3,15.8c2.8,0.8-0.5,15.8-2.8,18.3c-1.5,5.8-7.8,41.4-7.5,45.2      c0.3,3.8-4,11.8-8.5,12.3s-8.5-7.8-8.3-21.8C683.2,368.2,685.7,353.7,687.2,347.7z"
		],
		"baseLine": {
			"x1": "686.1",
			"x2": "708.2",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t402": {
		"paths": [
			"M750.9,345.5c-1.9-12.7-6.5-17.8-5-18.8s6.8-2.8,7.8-0.5c0.3-3.5,4.5-16.6,8-16.6s11.5,3.3,11.8,5.8      s1.8,7.8,0.8,11.3c3.3,0,4.5,10,2,12.8s-7,4.3-6.5,8.8s-0.3,33.6-3,41.7s-3.8,10.5-7.5,10.5s-8.5-4.5-7.8-14.8      C752.2,375.3,752.7,357.3,750.9,345.5z"
		],
		"baseLine": {
			"x1": "750.9",
			"x2": "770.2",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t401": {
		"paths": [
			"M823.5,345.5c-4.5-10-7.8-18.1-6-19.6s6.3-4,7.3-1.3c1-3.3,6-12,9-11.3s10,9.8,9.8,15.8      c2.5-1.5,4.3,2.8,2.5,6c-1.8,3.3-5,11.3-5.8,11.8s1.8,24.3-1,33.1c-2.8,8.8-4.3,16.6-8.8,15.3s-6.5-5.5-6.5-12.8      S824.7,351.2,823.5,345.5z"
		],
		"baseLine": {
			"x1": "823.5",
			"x2": "841",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t311": {
		"paths": [
			"M1732.1,345.5c4.8-4.5,5-7.8,2.8-10.3c-2.3-2.5-11-10.8-15.1-10.5c-4,0.3-14.6,4.3-15.1,8.8s-2,7,0,10.8      c2.5,12.5,6.5,26.1,7.3,29.1s1.5,6,6.8,5.8s7.5-1.3,7.8-4.8C1726.6,374.3,1730.9,348.5,1732.1,345.5z"
		],
		"baseLine": {
			"x1": "1732.1",
			"x2": "1705.2",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t310": {
		"paths": [
			"M1678.9,345.6c5.5-8,5.5-12,4-15.6c-1.5-3.5-11-2.3-14.3-0.5s-4.8,2.5-6.8-0.3s-11-10-13.3-9.5      c-2.2,0.5-11,7.5-13.6,11.8c0.5,6,3.8,14.1,6,15.1c-1,13.1-5.5,15.8-1.8,23.1c3.8,7.3,5.8,17.6,11.5,13.6      c5.8-4,2.8-4.1,2.6-13.1c-0.3-9,2.4-20.1,6-20c4.2,0.2,5.8,7.9,5.3,18c-0.5,10-1.8,14.3,4,14.8s11.8-9.5,11.3-22.1      S1678.9,345.6,1678.9,345.6z"
		],
		"baseLine": {
			"x1": "1678.9",
			"x2": "1639.8",
			"y1": "345.6",
			"y2": "345.6"
		}
	},
	"t309": {
		"paths": [
			"M1608.7,323.9c0,0-3.6-12.3-9.7-11.5s-18.6,11-22.8,10.3s8.6,21.8,8.6,21.8l23.9-1.3V323.9z",
			"M1526.7,399.2c-1.4,11.1-4.8,19.6-14.3,20.3c-9.5,0.8-8.7-11.5-10.2-20.1c-1.5-8.5,3.6-43.2,4.1-51      c-2.6-1.3-7.9,0-9.7-8c-1.8-8,11.8-42.4,14.3-42.4c2.6,0,15.6,8.8,15.6,8.8l2.3,8c0,0,0,0,1-6.3s25.6-22.6,31.2-26.1      s9-3.9,11.5,4.5c2.3,7.7,5.5,27.2,10.6,33.2s4.1,7.9,5.9,7.9s1.7,0.3,3.2-2.2s5.1-0.5,12.8-5c7.7-4.5,11.3,0.5,14.8,8.8      c3.6,8.3,2.6,10.3-6.4,17.1c0,0,2.8,9,3.8,20.6c1,11.5-2.5,40.8-16.8,40.8c-8.1,0-14.9-18.9-23.4-35.2      c-6.5-12.4-12-19.3-22-19.3C1544.5,353.5,1530,372.1,1526.7,399.2z"
		],
		"baseLine": {
			"x1": "1615",
			"x2": "1499.1",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t308": {
		"paths": [
			"M1473,347c3.7-3.9,5.8-4.1,6.7-7.6s0-5.8-2.3-7.4s1.2-3.2,0.5-5.3c-0.7-2.1-5.8-9.2-9.7-9.4      c-3.9-0.2-9,8.3-9,8.3c1.6-9.9-9.4-27.9-11.5-28.3s-32.3,20.3-35.7,26.5c-3.5,6.2-6.9,15.2-2.5,19.6      c-1.4,13.8-5.8,30.2-4.8,39.4c0.9,9.2,5.3,32.7,15.9,31.8c7.9-0.6,10.1-4.7,9.4-10s-0.6-54.3,9.7-54.5c8.6-0.2,7.4,29,7.6,34.1      s0.9,22.8,1.2,25.8s7.4,7.4,10.7,6.5c3.2-0.9,12.7-14.1,14.3-25.4S1475.1,351.4,1473,347z"
		],
		"baseLine": {
			"x1": "1474.7",
			"x2": "1409.2",
			"y1": "345.4",
			"y2": "345.4"
		}
	},
	"t307": {
		"paths": [
			"M1384.5,347.4c3.4-4.1,5.5-5.1,4.1-8.5s-4.4-5.8-6.8-5.5c1-7.2-1.4-12-2.7-12c-1.4,0-5.5,2-6.1,3.1      c-0.7-3.8-7.2-23.2-9.6-22.9s-21.5,23.9-27.2,25.5c-5.6,1.5-7.5,1.9-7.7,5s3.4,10.6,3.1,13.3c-0.3,2.7-5.1,22.4-5.8,31.3      c-0.7,8.9,4.8,28.9,11.6,30.4s11.6-0.2,11.6-4.4c0-4.3-0.5-19.1,2.2-31.3c2.7-12.1,1.8-20,6.2-20c4.3,0,4.3,22.5,4.9,28.3      s-0.5,21.4,0.5,23.7s5.6,8.7,11.3,3.2s9.9-13.2,10.8-28C1385.7,363.8,1386.5,354.4,1384.5,347.4z"
		],
		"baseLine": {
			"x1": "1386.2",
			"x2": "1331.5",
			"y1": "345.4",
			"y2": "345.4"
		}
	},
	"t306": {
		"paths": [
			"M1299.9,347.1c4.6-7.6,4.3-10.7,0.9-13c-3.3-2.2-2.6,0.7-4.3-0.7c-1.7-1.5-1.9-3.1-3.5-3.1      c-1.7,0-10.5-10.4-12.4-17.2s-6.5-9.1-8-9.1s-23.1,25.2-24.4,26.3c-1.3,1.1-5.4,1.1-4.3,5.4s1.3,9.1,1.3,9.1s-8,31.3-3.7,41.8      s3.9,18.1,13.7,17.2s7.1-5.4,6.2-13.3s2-25.1,2.2-27s2.6-12.6,6.7-12.1c3.5,0.5,5.5,12,5.5,15s0.9,21.2,0.7,28.3      c-0.2,7-1.7,7.7,2.7,9.5c4.4,1.9,8.9,4.8,13.5-3.1c4.6-8,6.8-20.4,7-27C1300.1,367.2,1301.8,353.9,1299.9,347.1z"
		],
		"baseLine": {
			"x1": "1300.8",
			"x2": "1245.1",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t305": {
		"paths": [
			"M1214.8,346.9c3.4-4,6.2-4.1,5.1-7.5s-4.1-6.2-5.5-10.2c-1.3-4-2.8-5.5-3.6-5.5s-11.5,4.5-13.6,7.7      s1.1,15.6,2.8,16.2s-1.7,17.1,0.2,25.2c1.9,8.1,2.6,15.4,7,15.1c4.3-0.4,5.8-7,5.8-19.4C1213.1,356.2,1214,350.9,1214.8,346.9z"
		],
		"baseLine": {
			"x1": "1216.3",
			"x2": "1198.7",
			"y1": "345.4",
			"y2": "345.4"
		}
	},
	"t304": {
		"paths": [
			"M1103.6,257.6c6.8-1.2,4.3,6.7,7.3,27.1c2.4,16.7,13.7,33.7,25.2,42.6c3.9,3,6.4,5.8,8.4,11.5      c3.3,9.3,33.5,50.8,43.1,61.5c10.1,11.1,33.5,27.4,28.7,36.4c-4.6,8.6-37.3-3.6-56.4-13.7s-51.2-34.5-66.6-69      c-15.5-34.5-9.5-53.1-9.1-56C1084.4,295.2,1092.8,259.5,1103.6,257.6z"
		],
		"baseLine": {
			"x1": "1148",
			"x2": "1089.7",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t303": {
		"paths": [
			"M1060.2,347.7c3.8-5.8,7.3-15.8,7.3-18.8c-2.5-1.5-4.8-3.3-5.8-1.3c0-2-5.3-21.3-7-21.6      c-1.8-0.3-6.5,2.8-7,5c-5.8-1.8-8.3,0.8-10,1.5s-1.8,13.8-0.3,15.8c-2.8,0.8,0.5,15.8,2.8,18.3c1.5,5.8,7.8,41.4,7.5,45.2      s4,11.8,8.5,12.3s8.5-7.8,8.3-21.8C1064.3,368.2,1061.7,353.7,1060.2,347.7z"
		],
		"baseLine": {
			"x1": "1061.3",
			"x2": "1039.3",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t302": {
		"paths": [
			"M996.5,345.5c1.9-12.7,6.5-17.8,5-18.8s-6.8-2.8-7.8-0.5c-0.3-3.5-4.5-16.6-8-16.6s-11.5,3.3-11.8,5.8      s-1.8,7.8-0.8,11.3c-3.3,0-4.5,10-2,12.8s7,4.3,6.5,8.8s0.3,33.6,3,41.7s3.8,10.5,7.5,10.5c3.8,0,8.5-4.5,7.8-14.8      C995.2,375.3,994.7,357.3,996.5,345.5z"
		],
		"baseLine": {
			"x1": "996.5",
			"x2": "977.2",
			"y1": "345.5",
			"y2": "345.5"
		}
	},
	"t301": {
		"paths": [
			"M923.9,345.5c4.5-10,7.8-18.1,6-19.6s-6.3-4-7.3-1.3c-1-3.3-6-12-9-11.3s-10,9.8-9.8,15.8      c-2.5-1.5-4.3,2.8-2.5,6c1.8,3.3,5,11.3,5.8,11.8s-1.8,24.3,1,33.1c2.8,8.8,4.3,16.6,8.8,15.3c4.5-1.3,6.5-5.5,6.5-12.8      S922.7,351.2,923.9,345.5z"
		],
		"baseLine": {
			"x1": "923.9",
			"x2": "906.4",
			"y1": "345.5",
			"y2": "345.5"
		}
	}
};