import React from 'react';
import {SvgIcon} from '@mui/material';
import SVGText from './SVGText';

function CustomSVGIcon(props) {
	const paths = props.data;
	const {data, ...newProps} = props;

	return (
		<SvgIcon {...newProps} >
			{paths.map((svgPath, index) => {
				return <path key={index} d={svgPath} />;
			})}
		</SvgIcon>
	);
}


export default function CustomSVG(props) {
	switch(props.type) {
		case 'svg':
			return <CustomSVGIcon {...props} />;
		default:
			return <SVGText {...props} />;

	}
}




