export const types = {
	SET_STATE: 'AUTH/SET_STATE',
};

const initialState = {
	showLoginPage: true,
	showLoginModal: false,
	pending: false,
	showPasswordChange: false,
	showRelog: false,
	errors: [],
	resetToken: null,
	verifyingToken: false,
	showSignupModal: false,
	showClinicSelection: false,
	clinics: [],
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };
		default:
			return state;
	}
}


export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),
};
