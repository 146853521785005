import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, TextField } from "@mui/material";
import useStyles from './Reports.styles';
import { actions } from './Reports.redux';
import DashboardCard from "components/DashboardCard";
import { Search } from "@mui/icons-material";



const PatientSearch = () => {
    const classes       = useStyles(),
          dispatch      = useDispatch(),
          searchTerms   = useSelector(state => state.reports.reportSearch),
          loading       = useSelector(state => state.reports.loading),
          setSearchTerm = (key, value) => dispatch(actions.setSearchTerm('reportSearch', key, value));
    // handleSearch  = () => dispatch(actions.search()),
    //resetSearch   = () => dispatch(actions.resetSearch());


    const handleChange = e => setSearchTerm(e.target.name, e.target.value);

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(actions.setSubState('reports', { pageNumber: 0 }));
        dispatch(actions.loadReports());
    };


    const handleReset = () => {
        dispatch(actions.resetProperty('reportSearch'));
        dispatch(actions.setSubState('reports', { pageNumber: 0 }));
        dispatch(actions.loadReports());
    };


    return (
        <div className={classes.searchContainer}>
            <DashboardCard
                color={'amber'}
                icon={<Search />}
                topContent={"Report Search"}
                topLabel="Enter search criteria below"
                size="xl"
            >
                <form method="post" onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                label="Report Name"
                                variant="outlined"
                                fullWidth
                                value={searchTerms.report_name}
                                name="report_name"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                label="Patient Name"
                                variant="outlined"
                                fullWidth
                                value={searchTerms.patient_name}
                                name="patient_name"
                                onChange={handleChange}
                            />
                        </Grid>


                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                label="Client Name"
                                variant="outlined"
                                fullWidth
                                value={searchTerms.client_name}
                                name="client_name"
                                onChange={handleChange}
                            />
                        </Grid>


                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                type="date"
                                label="Report Date (From)"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                fullWidth
                                value={searchTerms.date_from}
                                name="date_from"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                type="date"
                                label="Report Date (To)"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                fullWidth
                                value={searchTerms.date_to}
                                name="date_to"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.searchButtons}
                                disabled={loading}
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Search
                            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                className={classes.searchButtons}
                                disabled={loading}
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </DashboardCard>
        </div>

    );
};


export default PatientSearch;