import { cx } from '@emotion/css';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Close } from "@mui/icons-material";
import useStyles from './TextDialog.styles';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';



const TextDialog = ({ styleConfig }) => {
	const dispatch = useDispatch(),
		  position = useSelector(state => state.chart.textOptions.screenPosition),
		  classes  = useStyles({ position }),
		  [
			  value,
			  setValue,
		  ]        = useState('');

	const handleChange = (e) => {
		setValue(e.target.value);
	};


	useEffect(() => {
		if(position === null) {
			setValue('');
		}
	}, [ position, setValue ]);


	return (
		<Dialog
			open={position !== null}
			onClose={() => dispatch(actions.resetText())}
			classes={{
				root: cx(classes.container),
				paper: cx(classes.dialogPosition),
			}}
			BackdropProps={{
				invisible: true,
			}}
			PaperProps={{
				elevation: 12,
			}}
		>

			<DialogTitle>
				<IconButton
					className={cx(classes.closeButton)}
					onClick={() => dispatch(actions.resetText())}
					size="large"
				>
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<TextField
					fullWidth
					label="Enter Note"
					value={value}
					onChange={handleChange}
					multiline
					minRows={1}
					maxRows={4}
					autoFocus
					sx={{ marginTop: 1.5 }}
				/>
				<Button
					color="primary"
					variant="contained"
					onClick={() => dispatch(actions.saveText(value))}
					sx={{ marginTop: 1 }}
				>
					Save
				</Button>
			</DialogContent>
		</Dialog>
	);
};


export default TextDialog;
