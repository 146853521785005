import React from 'react';
import CustomCodeList from "./CustomCodeList";
import CustomCodeForm from './CustomCodeForm';
import { useSelector } from "react-redux";



const CustomCodes = () => {
	const showForm = useSelector(state => state.customCodes.showForm);

	if(!showForm) {
		return <CustomCodeList />;
	}

	return <CustomCodeForm />;
};


export default CustomCodes;