import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';
import Login from 'pages/Login';


const WrappedRoute = ({ extras, render, ...route }) => {

	const dispatch       = useDispatch(),
		  setLayoutState = (data) => dispatch(actions.layout.setState(data));

	const showLogin = useSelector(state => state.auth.showLoginPage);


	useEffect(() => {
		let update = {
			useLayout: extras.useLayout,
		};

		if(extras.title !== null) {
			update.title = extras.title;
		}

		setLayoutState(update);
	});


	if(showLogin && extras.private) {
		return <Login />;
	}

	return (
		<Route {...route}>{render}</Route>
	);

};




export default WrappedRoute;