import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStrokeDeciduous,
	strokeWidth: 1,
	fillColor: colors.defaultFillDeciduous,
	scaling: 1.68,
	opacity: 1.0,
	rotation: 0
};
const defaultTop  = 255; // 100 to line up with original
const defaultBot  = 1315; // 611 to line up with original
const canvasWidth = 3000;

function pos(newPos) {
	return canvasWidth / 1000 * newPos;
}

export default [
	// UPPER 100s
	{
		"name": "d508",
		"position": [pos(131), defaultTop+5],
		"options": {...defaultOptions, scaling: 1.6}
	},
	{
		"name": "d507",
		"position": [pos(198), defaultTop],
		"options": {...defaultOptions, scaling:1.37}
	},
	{
		"name": "d506",
		"position": [pos(248), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "d504",
		"position": [pos(298), defaultTop-14+25],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.25}
	},
	{
		"name": "d503",
		"position": [pos(400), defaultTop-1],
		"options": defaultOptions
	},
	{
		"name": "d502",
		"position": [pos(438), defaultTop-2-20],
		"options": defaultOptions
	},
	{
		"name": "d501",
		"position": [pos(476), defaultTop-20],
		"options": defaultOptions
	},

	// UPPER 200s
	{
		"name": "d601",
		"position": [pos(523), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "d602",
		"position": [pos(561), defaultTop-1-20],
		"options": defaultOptions
	},
	{
		"name": "d603",
		"position": [pos(599), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "d604",
		"position": [pos(701), defaultTop-14+25],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.25}
	},
	{
		"name": "d606",
		"position": [pos(751), defaultTop],
		"options": defaultOptions
	},
	{
		"name": "d607",
		"position": [pos(801), defaultTop],
		"options": {...defaultOptions, scaling:1.37}
	},
	{
		"name": "d608",
		"position": [pos(868), defaultTop+4],
		"options": {...defaultOptions, scaling: 1.6}
	},

	// LOWER 400s
	{
		"name": "d808",
		"position": [pos(178), defaultBot+7],
		"options": defaultOptions
	},
	{
		"name": "d807",
		"position": [pos(226), defaultBot+4],
		"options": defaultOptions
	},
	{
		"name": "d806",
		"position": [pos(274), defaultBot+5],
		"options": defaultOptions
	},
	{
		"name": "d804",
		"position": [pos(333), defaultBot+15],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.25}
	},
	{
		"name": "d803",
		"position": [pos(398), defaultBot+4],
		"options": defaultOptions
	},
	{
		"name": "d802",
		"position": [pos(436), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "d801",
		"position": [pos(475.5), defaultBot+3],
		"options": defaultOptions
	},

	// LOWER 300s
	{
		"name": "d701",
		"position": [pos(523), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "d702",
		"position": [pos(563), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "d703",
		"position": [pos(600), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "d704",
		"position": [pos(667), defaultBot+17],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.25}
	},
	{
		"name": "d706",
		"position": [pos(724), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "d707",
		"position": [pos(773), defaultBot+3],
		"options": defaultOptions
	},
	{
		"name": "d708",
		"position": [pos(820.5), defaultBot+8],
		"options": defaultOptions
	},
];