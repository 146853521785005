import React, { useCallback, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
} from '@mui/material';
import { Clear, Delete, Save } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../ClientView.redux';
import { useParams } from 'react-router-dom';
import useStyles from './ClientPatients.styles';



const ClientPatientsEditModal = () => {
    const dispatch      = useDispatch(),
          open          = useSelector(state => (state.clientView.displayModal === 'patients')),
          data          = useSelector(state => state.clientView.patientsEditData),
          errors        = useSelector(state => state.clientView.newPatientErrors),
          loading       = useSelector(state => state.clientView.newPatientLoading),
          handleClose   = () => dispatch(actions.closeModal()),
          formRef       = useRef(),
          { client_id } = useParams(),
          classes       = useStyles();



    const updateData = useCallback((event) => {
        dispatch(actions.editPatientsData(event.target.name, event.target.value));
    }, [ dispatch ]);


    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        dispatch(actions.submitNewPatient({
            ...data,
            client_id: client_id,
        }));
    }, [ dispatch, data, client_id ]);



    return (
        <Dialog
            open={open}
        >
            <DialogTitle>New Patient</DialogTitle>

            <form ref={formRef} method="post" onSubmit={handleSubmit}>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Name</InputLabel>
                        <Input
                            type="text"
                            placeholder="Name"
                            name="patient_name"
                            onChange={updateData}
                            value={data.patient_name}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Gender</InputLabel>
                        <Select
                            value={data.gender}
                            onChange={updateData}
                            name="gender"
                        >
                            <MenuItem value={''}>Select One</MenuItem>
                            <MenuItem value={'male'}>Male</MenuItem>
                            <MenuItem value={'female'}>Female</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Is Altered?</InputLabel>
                        <Select
                            value={data.is_neutered}
                            onChange={updateData}
                            name="is_neutered"
                        >
                            <MenuItem value={''}>Select One</MenuItem>
                            <MenuItem value={'yes'}>Yes</MenuItem>
                            <MenuItem value={'no'}>No</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Breed</InputLabel>
                        <Input
                            type="text"
                            placeholder="Breed"
                            name="breed"
                            onChange={updateData}
                            value={data.breed}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Species</InputLabel>
                        <Select
                            value={data.species}
                            onChange={updateData}
                            name="species"
                        >
                            <MenuItem value={''}>Select One</MenuItem>
                            <MenuItem value={'canine'}>Canine</MenuItem>
                            <MenuItem value={'feline'}>Feline</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel shrink>Date of Birth</InputLabel>
                        <Input
                            type="date"
                            placeholder="Date of Birth"
                            name="date_of_birth"
                            onChange={updateData}
                            value={data.date_of_birth}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Primary Vet</InputLabel>
                        <Input
                            type="text"
                            placeholder="Primary Vet"
                            name="primary_vet"
                            onChange={updateData}
                            value={data.primary_vet}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>Primary Clinic</InputLabel>
                        <Input
                            type="text"
                            placeholder="Primary Clinic"
                            name="primary_clinic"
                            onChange={updateData}
                            value={data.primary_clinic}
                        />
                    </FormControl>

                    {errors.map((err, index) => (
                        <Paper key={index} variant="outlined" elevation={2} className={classes.errorContainer}>
                            {err}
                        </Paper>
                    ))}
                </DialogContent>

                <DialogActions>
                    <Button
                        startIcon={<Save />}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >
                        Save
                    </Button>

                    <Button
                        startIcon={<Clear />}
                        variant="outlined"
                        onClick={handleClose}
                        disabled={loading}
                    >
                        Cancel
                    </Button>


                </DialogActions>
            </form>
        </Dialog>
    );

};


export default ClientPatientsEditModal;
