import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { CustomPaging, PagingState, SelectionState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import api from 'api';



const ReportsDialog = ({ handleClose, handleNewReport }) => {
    const dispatch                = useDispatch(),
          [ rows, setRows ]       = useState([]),
          [ page, setPage ]       = useState(0),
          [ limit, setLimit ]     = useState(10),
          [ total, setTotal ]     = useState(0),
          [ loading, setLoading ] = useState(false),
          [ columns ]             = useState([
              { name: 'urpt_name', title: 'Report Name' },
              { name: 'date_created', title: 'Date Created' },
              { name: 'template_name', title: 'Template' },
          ]),
          visitID                 = useSelector(state => state.chart.visit_id);


    const handleSelection = (selected) => {
        const row = rows[selected[0]];
        dispatch(push(`/reports/edit/${row.urpt_id}`));
    };


    const handleSetPage = (page) => {
        if(loading) {
            return false;
        }

        setPage(page);
    };


    const handleSetLimit = (limit) => {
        if(loading) {
            return false;
        }

        setLimit(limit);
    };


    useEffect(() => {
        setLoading(true);
        api.get('/reports/chart', {
            params: {
                page_size: limit,
                page_number: page,
                visit_id: visitID,
            },
        }).then(res => {
            let { reports } = res.data;
            setRows(reports);
            setTotal(reports[0]?.total_records || 0);
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });
    }, [
        visitID,
        page,
        limit,
        setRows,
        setLoading,
        setTotal,
    ]);


    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={handleClose}
        >
            <DialogTitle>Reports for current chart</DialogTitle>

            <DialogContent>
                <Grid
                    rows={rows}
                    columns={columns}
                >

                    <SelectionState
                        selection={[]}
                        onSelectionChange={handleSelection}
                    />

                    <PagingState
                        currentPage={page}
                        defaultPageSize={10}
                        onCurrentPageChange={handleSetPage}
                        onPageSizeChange={handleSetLimit}
                    />

                    <CustomPaging
                        totalCount={parseInt(total)}
                    />

                    <Table />
                    <TableHeaderRow />

                    <PagingPanel pageSizes={[ 10, 20, 50, 100 ]} />

                    <TableSelection
                        selectByRowClick
                        highlightRow
                        showSelectionColumn={false}
                    />
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                        handleNewReport();
                    }}
                    variant="contained"
                    color="primary"
                >
                    New Report
                </Button>

                <Button onClick={handleClose} variant="outlined">Close</Button>
            </DialogActions>
        </Dialog>
    );
};


export default ReportsDialog;
