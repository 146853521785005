import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStrokeDeciduous,
	strokeWidth: 2.5,
	fillColor: colors.defaultFillDeciduous,
	scaling: 0.85, // 0.7 for shrunk view with numbers?
	opacity: 1.0,
	rotation: 0
};
const canvasWidth  = 1000;
const canvasHeight = 3000;
const x            = canvasWidth+100;
const y            = canvasHeight;

function pos(newPos, size) {
	return size / 1000 * (size === y ? newPos+35 : newPos);
}

export default [
	// UPPER 100s (left)
	{
		"name": "d501",
		"position": [pos(465, x), pos(32, y)],
		"options": defaultOptions
	},
	{
		"name": "d502",
		"position": [pos(407, x), pos(34, y)],
		"options": defaultOptions
	},
	{
		"name": "d503",
		"position": [pos(352, x), pos(39, y)],
		"options": defaultOptions
	},
	{
		"name": "d504",
		"position": [pos(225, x), pos(83, y)],
		"options": defaultOptions
	},
	{
		"name": "d506",
		"position": [pos(240, x), pos(124, y)],
		"options": defaultOptions
	},
	{
		"name": "d507",
		"position": [pos(193, x), pos(169, y)],
		"options": defaultOptions
	},
	{
		"name": "d508",
		"position": [pos(140, x), pos(228, y)],
		"options": defaultOptions
	},

	// UPPER 200s (right)
	{
		"name": "d601",
		"position": [pos(524, x), pos(32, y)],
		"options": defaultOptions
	},
	{
		"name": "d602",
		"position": [pos(580, x), pos(35, y)],
		"options": defaultOptions
	},
	{
		"name": "d603",
		"position": [pos(632, x), pos(39, y)],
		"options": defaultOptions
	},
	{
		"name": "d604",
		"position": [pos(770, x), pos(83, y)],
		"options": defaultOptions
	},
	{
		"name": "d606",
		"position": [pos(757, x), pos(124, y)],
		"options": defaultOptions
	},
	{
		"name": "d607",
		"position": [pos(808, x), pos(169, y)],
		"options": {...defaultOptions},
	},
	{
		"name": "d608",
		"position": [pos(861, x), pos(228, y)],
		"options": defaultOptions
	},

	// LOWER 400s (left)
	{
		"name": "d801",
		"position": [pos(468, x), pos(645, y)],
		"options": defaultOptions
	},
	{
		"name": "d802",
		"position": [pos(410, x), pos(643, y)],
		"options": defaultOptions
	},
	{
		"name": "d803",
		"position": [pos(352, x), pos(641, y)],
		"options": defaultOptions
	},
	{
		"name": "d804",
		"position": [pos(224, x), pos(609, y)],
		"options": {...defaultOptions, rotation: -20}
	},
	{
		"name": "d807",
		"position": [pos(227, x), pos(510, y)],
		"options": defaultOptions
	},
	{
		"name": "d808",
		"position": [pos(168, x), pos(463, y)],
		"options": defaultOptions
	},

	// LOWER 300s (right)
	{
		"name": "d701",
		"position": [pos(520, x), pos(645, y)],
		"options": defaultOptions
	},
	{
		"name": "d702",
		"position": [pos(576, x), pos(643, y)],
		"options": defaultOptions
	},
	{
		"name": "d703",
		"position": [pos(632, x), pos(641, y)],
		"options": defaultOptions
	},
	{
		"name": "d704",
		"position": [pos(765, x), pos(610, y)],
		"options": {...defaultOptions, rotation: 20}
	},
	{
		"name": "d707",
		"position": [pos(768, x), pos(510, y)],
		"options": defaultOptions
	},
	{
		"name": "d708",
		"position": [pos(828, x), pos(463, y)],
		"options": defaultOptions
	},
];