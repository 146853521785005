import api from 'api';
import { push } from 'connected-react-router';



const baseType = 'PATIENTVIEW';

export const types = {
    SET_STATE: `${baseType}/SET_STATE`,
    RESET_ALL: `${baseType}/RESET_ALL`,
    SET_PROFILE_VALUE: `${baseType}/SET_PROFILE_VALUE`,
    CANCEL_EDIT: `${baseType}/CANCEL_EDIT`,
};

const initialState = {
    tab: 'charts',

    profile: {
        patient_name: '',
        client_name: '',
        client_id: '',
        last_visit: '',
        gender: '',
        breed: '',
        is_neutered: '',
        primary_vet: '',
        primary_clinic: '',
        date_of_birth: '',
        species: '',
    },
    profileOriginal: {
        patient_name: '',
        client_name: '',
        client_id: '',
        last_visit: '',
        gender: '',
        breed: '',
        is_neutered: '',
        primary_vet: '',
        primary_clinic: '',
        date_of_birth: '',
        species: '',
    },
    profileLoading: false,
    profileLoaded: false,
    profileSaving: false,
    profileEditing: false,
    profileErrors: [],

    contactMethods: [],
    contactMethodsLoading: false,
    contactMethodsPreviouslyLoaded: false,

    addresses: [],
    addressesLoading: false,
    addressesPreviouslyLoaded: false,

    patients: [],
    patientsLoading: false,
    patientsPreviouslyLoaded: false,

    reports: [],
    reportsLoading: false,
    reportsPreviouslyLoaded: false,

    charts: [],
    chartsLoading: false,
    chartsPreviouslyLoaded: false,

    errors: [],

};


export default (state = initialState, action) => {
    switch(action.type) {
        case types.SET_STATE:
            return { ...state, ...action.data };

        case types.SET_PROFILE_VALUE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    [action.key]: action.value,
                },
            };

        case types.RESET_ALL:
            return { ...initialState };

        case types.CANCEL_EDIT:
            return { ...state, profile: { ...state.profileOriginal }, profileEditing: false };

        default:
            return state;
    }
}

export const actions = {
    setState: (data) => ({ type: types.SET_STATE, data }),


    resetAll: () => ({ type: types.RESET_ALL }),


    cancelEdit: () => ({ type: types.CANCEL_EDIT }),


    setProfileValue: (key, value) => ({ type: types.SET_PROFILE_VALUE, key, value }),


    loadProfile: (patientID) => async (dispatch, getState) => {
        dispatch(actions.setState({
            profileLoading: true,
        }));

        try {
            let results = await api.get(`/patients/profile/${patientID}`);
            dispatch(actions.setState({
                profileLoading: false,
                profileLoaded: true,
                profile: { ...initialState.profile, ...results.data.profile },
                profileOriginal: { ...initialState.profile, ...results.data.profile },
            }));
        } catch (err) {
            dispatch(actions.setState({
                loading: false,
                profileLoaded: true,
                profileErrors: [ 'There was an error loading the requested patient.' ],
            }));
        }
    },


    saveProfile: (patientID) => async (dispatch, getState) => {
        dispatch(actions.setState({
            profileSaving: true,
        }));

        try {
            let results = await api.put(`/patients/profile/${patientID}`, { ...getState().patientView.profile });
            dispatch(actions.setState({
                profileSaving: false,
                profileEditing: false,
                profile: { ...getState().patientView.profile, ...results.data.profile },
                profileOriginal: { ...getState().patientView.profile, ...results.data.profile },
            }));
        } catch (err) {
            dispatch(actions.setState({
                profileSaving: false,
                profileErrors: [ err.response.data.errors || [ 'An error occurred while saving the patient profile' ] ],
            }));
        }
    },


    loadReports: (patientID) => async (dispatch, getState) => {
        dispatch(actions.setState({
            reportsLoading: true,
            reports: [],
        }));

        try {
            let results = await api.get(`/patients/reports/${patientID}`);
            dispatch(actions.setState({
                reports: results.data.reports,
                reportsPreviouslyLoaded: true,
                reportsLoading: false,
            }));
        } catch (err) {
            dispatch(actions.setState({
                reports: [],
                reportsPreviouslyLoaded: true,
                reportsLoading: false,
            }));
        }
    },


    loadPatients: (patientID) => async (dispatch, getState) => {
        dispatch(actions.setState({
            patientsLoading: true,
            patients: [],
        }));

        try {
            let results = await api.get(`/patients/patients/${patientID}`);
            dispatch(actions.setState({
                patients: results.data.patients,
                patientsPreviouslyLoaded: true,
                patientsLoading: false,
            }));
        } catch (err) {
            dispatch(actions.setState({
                patients: [],
                patientsPreviouslyLoaded: true,
                patientsLoading: false,
            }));
        }
    },


    loadCharts: (patientID) => async (dispatch, getState) => {
        dispatch(actions.setState({
            chartsLoading: true,
            charts: [],
        }));

        try {
            let results = await api.get(`/patients/charts/${patientID}`);
            dispatch(actions.setState({
                charts: results.data.charts,
                chartsPreviouslyLoaded: true,
                chartsLoading: false,
            }));
        } catch (err) {
            dispatch(actions.setState({
                charts: [],
                chartsPreviouslyLoaded: true,
                chartsLoading: false,
            }));
        }
    },


    addNewChart: (patientID) => async (dispatch, getState) => {
        dispatch(actions.setState({
            chartsLoading: true,
        }));

        try {
            let results = await api.post(`/charts`, {
                patient_id: patientID,
            });
            dispatch(actions.setState({
                chartsLoading: false,
            }));
            dispatch(push(`/chart/${results.data.visit_id}`));
        } catch (err) {
            console.error(err);
        }
    },


    loadContactMethods: (patientID) => async (dispatch, getState) => {
        dispatch(actions.setState({
            contactMethodsLoading: true,
            contactMethods: [],
        }));

        try {
            let results = await api.get(`/patients/contact-methods/${patientID}`);
            dispatch(actions.setState({
                contactMethods: results.data.contact_methods,
                contactMethodsPreviouslyLoaded: true,
                contactMethodsLoading: false,
            }));
        } catch (err) {
            dispatch(actions.setState({
                contactMethods: [],
                contactMethodsPreviouslyLoaded: true,
                contactMethodsLoading: false,
            }));
        }
    },



    loadAddresses: (patientID) => async (dispatch, getState) => {
        dispatch(actions.setState({
            addressesLoading: true,
            addresses: [],
        }));

        try {
            let results = await api.get(`/patients/addresses/${patientID}`);
            dispatch(actions.setState({
                addresses: results.data.addresses,
                addressesPreviouslyLoaded: true,
                addressesLoading: false,
            }));
        } catch (err) {
            dispatch(actions.setState({
                addresses: [],
                addressesPreviouslyLoaded: true,
                addressesLoading: false,
            }));
        }
    },
};
