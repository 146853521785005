import store from 'store';
import drawLineFromOrigin from './drawLineFromOrigin';
import _ from 'lodash';
import { subscribe } from './subscribe';
import { actions } from 'pages/Chart/Chart.redux';



const canvasName = 'buccal';

export default function(penPal, helper) {
	let hasInitialized = false;

	// TEETH: NORMAL
	subscribe('buccal_teeth', {
		teeth: [ 'teeth' ],
	}, (current, full, previous) => {
		renderTeeth('teeth_buccal', current.teeth);

		setTimeout(() => {
			penPal.sizeCanvas('buccal', 0);
		}, 500);
	});


	// TEETH: LINGUAL/PALATAL
	subscribe('buccal_teethLP', {
		teethLP: [ 'teethLP' ],
	}, (current, full, previous) => {
		renderTeeth('teeth_lingual_palatal', current.teethLP);

		setTimeout(() => {
			penPal.sizeCanvas('buccal', 0);
		}, 500);
	});


	// TEETH: DECIDUOUS
	subscribe('buccal_teethDeciduous', {
		teethDeciduous: [ 'teethDeciduous' ],
	}, (current, full, previous) => {
		renderTeethDeciduous('teeth_buccal_deciduous', current.teethDeciduous);

		setTimeout(() => {
			penPal.sizeCanvas('buccal', 0);
		}, 500);
	});


	// MISSING TEETH
	subscribe('buccal_missingTeeth', {
		teeth: [ 'teeth' ],
		teethLP: [ 'teethLP' ],
		teethDeciduous: [ 'teethDeciduous' ],
		missingTeeth: [ 'missingTeeth' ],
		showMissingAdult: [ 'layers', 'missingTeethAdult' ],
		showMissingDeciduous: [ 'layers', 'missingTeethDeciduous' ],
	}, (current, full, previous) => {
		renderMissingTeeth(
			current.missingTeeth,
			current.teeth,
			current.teethDeciduous,
			{ adult: current.showMissingAdult, deciduous: current.showMissingDeciduous },
		);
	});


	// POCKET DEPTHS
	subscribe('buccal_pocketDepths', {
		teeth: [ 'teeth' ],
		missingTeeth: [ 'missingTeeth' ],
		pocketDepths: [ 'pocketDepths' ],
		deciduous: [ 'teethDeciduous' ],
		adultTeethVis: [ 'layers', 'adultTeeth' ],
		abnormalPocketDepth: [ 'options', 'abnormalPocketDepth' ],
	}, (current, full, previous) => {
		renderPerioDepthLines(current.pocketDepths, full, current.adultTeethVis);
	});


	// SYMBOLS
	subscribe('buccal_symbols', {
		data: [ 'chart_symbols_buccal' ],
		symbols: [ 'symbols' ],
		teeth: [ 'teeth' ],
		teethLP: [ 'teethLP' ],
		teethDeciduous: [ 'teethDeciduous' ],
		adultTeethVis: [ 'layers', 'adultTeeth' ],
		deciduousTeethVis: [ 'layers', 'deciduousTeeth' ],
		treatmentVis: [ 'layers', 'treatments' ],
		diagnosticVis: [ 'layers', 'diagnostics' ],
		missingTeethAdult: [ 'layers', 'missingTeethAdult' ],
		missingTeethDeciduous: [ 'layers', 'missingTeethDeciduous' ],
		missingTeeth: [ 'missingTeeth' ],
		options: [ 'options' ],
	}, (current, full, previous) => {
		renderSymbols({
			data: current.data,
			options: current.options,
			missingTeeth: current.missingTeeth,
			missingTeethAdult: current.missingTeethAdult,
			missingTeethDeciduous: current.missingTeethDeciduous,
			adultTeethVis: current.adultTeethVis,
			deciduousTeethVis: current.deciduousTeethVis,
			treatmentVis: current.treatmentVis,
			diagnosticVis: current.diagnosticVis,
		});
	});


	// DRAWINGS: BUCCAL
	subscribe('buccal_drawings', {
		teeth: [ 'teeth' ],
		drawings: [ 'drawings_buccal' ],
	}, (current, full, previous) => {
		try {
			helper.importDrawings(canvasName, current.drawings);
		} catch(err) {
			console.error(err);
		}
	});


	// SHAPES: BUCCAL
	subscribe('buccal_shapes', {
		teeth: [ 'teeth' ],
		shapes: [ 'shapes_buccal' ],
	}, (current, full, previous) => {
		try {
			helper.importShapes(canvasName, current.shapes);
		} catch(err) {
			console.error(err);
		}
	});

	// TEXT: BUCCAL
	subscribe('text_buccal', {
		text: [ 'text_buccal' ],
	}, (current, full, previous) => {
		try {
			helper.importText(canvasName, current.text);
		} catch(err) {
			console.error(err);
		}
	});


	// LAYER VISIBILITY
	subscribe('buccal_layer_visibility', {
		layers: [ 'layers' ],
	}, (current, full, previous) => {
		helper.updateLayerVisibility(canvasName, current.layers);
	});


	// ACTIVE TOOL
	subscribe('buccal_selected_tool', {
		tool: [ 'toolbar', 'selectedTool' ],
	}, (current, full, previous) => {
		switch(current.tool) {
			case 'markMissing':
				helper.activateTool(canvasName, 'markMissing');
				break;
			case 'drawing':
				helper.activateTool(canvasName, 'drawing');
				break;
			case 'symbolMulti':
			case 'symbolSingle':
				helper.activateTool(canvasName, 'symbols');
				break;
			case 'shapeCircleFilled':
			case 'shapeCircleOutlined':
			case 'shapeRectangleFilled':
			case 'shapeRectangleOutlined':
				helper.activateTool(canvasName, 'shapes');
				break;
			case 'text':
				helper.activateTool(canvasName, 'text');
				break;
			case 'delete':
				helper.activateTool(canvasName, 'delete');
				break;
			case 'move':
				helper.activateTool(canvasName, 'move');
				break;
			case 'rotate':
				helper.activateTool(canvasName, 'rotate');
				break;
			case 'probe':
				helper.activateTool(canvasName, 'probeToothSelection');
				break;
			default:
				helper.activateTool(canvasName, 'dummy');
		}
	});


	// ACTIVE TOOTH
	subscribe('buccal_active_tooth', {
		activeTooth: [ 'probeOptions', 'activeTooth' ],
		tool: [ 'toolbar', 'selectedTool' ],
	}, (current, state, previousState) => {

		// No need to run this if probing mode is not and has not been active
		if(current.tool !== 'probe' && previousState.toolbar.selectedTool !== 'probe') {
			return true;
		}

		highlightActiveTooth(current.activeTooth, previousState.probeOptions.activeTooth, current.tool, state);
	});



	function renderTeeth(layerName, teeth) {
		penPal.canvas.select(canvasName);
		penPal.layer.set(layerName);
		let colors = store.getState().chart.colors;

		for(let i = 0; i < teeth.length; i++) {
			let tooth = teeth[ i ];

			let importObj = {
				children: tooth.paths,
				name: tooth.name,
				position: tooth.position,
				...(tooth.options || {}),
				fillColor: colors.defaultFill,
				strokeColor: colors.defaultStroke,
			};

			penPal.path.import(importObj.name, importObj);
		}
	}



	function renderTeethDeciduous(layerName, teeth) {
		penPal.canvas.select(canvasName);
		penPal.layer.set(layerName);
		let colors = store.getState().chart.colors;

		for(let i = 0; i < teeth.length; i++) {
			let tooth = teeth[ i ];

			let importObj = {
				children: tooth.paths,
				name: tooth.name,
				position: tooth.position,
				...(tooth.options || {}),
				fillColor: colors.defaultFillDeciduous,
				strokeColor: colors.defaultStrokeDeciduous,
			};

			penPal.path.import(importObj.name, importObj);
		}
	}



	function renderSymbols({
		data,
		options,
		missingTeeth,
		missingTeethAdult,
		missingTeethDeciduous,
		adultTeethVis,
		deciduousTeethVis,
		treatmentVis,
		diagnosticVis,
	}) {
		penPal.canvas.select(canvasName);

		// Diagnostics
		if(diagnosticVis) {
			helper.clearLayer(canvasName, 'diagnostics');
			helper.clearLayer(canvasName, 'diagnosticLines');
			penPal.layer.set('diagnostics');
			let dx = data
				.filter(row => row.symbol_type === 'dx')
				.filter(row => {
					if(!row.tooth) {
						return true;
					}

					let toothType      = row.tooth.substring(0, 1).toLowerCase(),
						toothIsMissing = missingTeeth.includes(row.tooth);

					if(toothIsMissing && toothType === 'd' && !missingTeethDeciduous) {
						return false;
					}

					if(toothIsMissing && toothType === 't' && !missingTeethAdult) {
						return false;
					}

					if(toothType === 'd') {
						return deciduousTeethVis;
					}

					return adultTeethVis;
				});

			dx.forEach(row => {
				try {
					renderDiagDefault(row, options);
				} catch(err) {
					console.warn('Diagnostic rendering error:');
					console.warn(err);
				}
			});
			renderGingivalRecessionLine('gr', dx, missingTeeth, adultTeethVis);
		}


		// Treatments
		if(treatmentVis) {
			helper.clearLayer(canvasName, 'treatments');
			helper.clearLayer(canvasName, 'treatmentLines');
			penPal.layer.set('treatments');
			let tx = data
				.filter(row => row.symbol_type === 'tx')
				.filter(row => {
					if(!row.tooth) {
						return true;
					}

					let toothType      = row.tooth.substring(0, 1).toLowerCase(),
						toothIsMissing = missingTeeth.includes(row.tooth);

					if(toothIsMissing && toothType === 'd' && !missingTeethDeciduous) {
						return false;
					}

					if(toothIsMissing && toothType === 't' && !missingTeethAdult) {
						return false;
					}

					if(toothType === 'd') {
						return deciduousTeethVis;
					}

					return adultTeethVis;
				});

			tx.forEach(row => {
				try {
					renderTreatmentDefault(row, options);
				} catch(err) {
					console.warn('Treatment rendering error:');
					console.warn(err);
				}
			});
		}

	}



	function renderDiagDefault(row, options) {
		let lineOpts = {
			strokeColor: options.dxColorAdult,
			strokeWidth: options.codeFontSize / 16,
			toothLayer: null,
		};


		let c     = helper.setActive(canvasName, 'diagnostics'),
			color = (row.tooth && row.tooth.substring(0, 1) === 'd')
				? options.dxColorDeciduous
				: options.dxColorAdult;

		let textConfig = {
			point: row.position,
			justification: 'center',
			content: color === options.dxColorAdult
				? row.render_value
				: row.render_value.toLowerCase(),
			name: row.name,
			fullySelected: false,
			fontSize: options.codeFontSize || 48,
			fontWeight: 'bold',
			fillColor: color,
		};

		/*if(row.actualLayer) {
			lineOpts.toothLayer = row.actualLayer;
		}*/

		const text     = new c.paper.PointText(textConfig),
			  { x, y } = helper.calculateOutOfBoundsSymbolPosition({
				  canvasName, paperItem: text,
			  });

		text.point = new c.paper.Point([ x, y ]);

		try {
			drawLineFromOrigin(
				helper,
				text,
				row,
				'diagnosticLines',
				{ ...lineOpts, strokeColor: color },
			);
		} catch(err) {
			console.error(err);
		}

		helper.setActive(canvasName, 'diagnostics');

		// TODO: Reduce symbol opacity to 30% by default, 15% in fade mode
		//text.opacity = 0.15;

		return text;
	}


	function renderTreatmentDefault(row, options) {
		let lineOpts = {
			strokeColor: options.txColorAdult,
			strokeWidth: options.codeFontSize / 16,
			toothLayer: null,
		};


		let c     = helper.setActive(canvasName, 'treatments'),
			color = (row.tooth && row.tooth.substring(0, 1) === 'd')
				? options.txColorDeciduous
				: options.txColorAdult;

		let textConfig = {
			point: row.position,
			justification: 'center',
			content: color === options.txColorAdult
				? row.render_value
				: row.render_value.toLowerCase(),
			name: row.name,
			fullySelected: false,
			fontSize: options.codeFontSize || 48,
			fontWeight: 'bold',
			fillColor: color,
		};

		/*if(row.actualLayer) {
			lineOpts.toothLayer = row.actualLayer;
		}*/

		const text     = new c.paper.PointText(textConfig),
			  { x, y } = helper.calculateOutOfBoundsSymbolPosition({
				  canvasName, paperItem: text,
			  });

		text.point = new c.paper.Point([ x, y ]);

		try {
			drawLineFromOrigin(
				helper,
				text,
				{
					...row,
					//actualLayer: row.actualLayer.replace('lingualPalatal', 'lingual_palatal'), // TODO: Remove this .replace
				},
				'treatmentLines',
				{ ...lineOpts, strokeColor: color });
		} catch(err) {
			console.error(err);
		}

		helper.setActive(canvasName, 'treatments');

		// TODO: Reduce symbol opacity to 30% by default, 15% in fade mode
		//text.opacity = 0.15;

		return text;
	}



	function renderMissingTeeth(missing, teethSource, teethDeciduous, showMissing) {
		let c = penPal.canvas.select(canvasName);
		let teeth = [
			...teethSource.map(tooth => tooth.name),
			...teethDeciduous.map(tooth => tooth.name),
		];
		let colors = store.getState().chart.colors;

		update('teeth_buccal');
		update('teeth_buccal_deciduous', true);
		update('teeth_lingual_palatal');

		function update(toothLayer, isDeciduous = false) {
			for(let i = 0; i < teeth.length; i++) {
				try {
					let toothName = teeth[ i ],
						tooth     = c.layers[ toothLayer ].paths[ toothName ],
						index     = missing.indexOf(toothName),
						opacity   = 1;

					if(!showMissing[ isDeciduous
						? 'deciduous'
						: 'adult' ]) {
						opacity = 0;
					}

					if(index === -1) {
						_.assign(tooth, {
							opacity: 1,
							fillColor: isDeciduous
								? colors.defaultFillDeciduous
								: colors.defaultFill,
							strokeColor: isDeciduous
								? colors.defaultStrokeDeciduous
								: colors.defaultStroke,
						});
					} else {
						_.assign(tooth, {
							opacity: opacity,
							fillColor: isDeciduous
								? colors.defaultFillLightDeciduous
								: colors.defaultFillLight,
							strokeColor: isDeciduous
								? colors.defaultStrokeLightDeciduous
								: colors.defaultStrokeLight,
						});
					}
				} catch(err) {
					console.error(i, teeth[ i ]);
					console.log(toothLayer, c.layers);
					console.error(err);
				}
			}
		}

		return true;
	}



	function renderGingivalRecessionLine(key, diagData, missing, vis) {
		let layerName     = `lines_${key}`,
			c             = helper.setActive(canvasName, layerName),
			intersections = getIntersections();

		c.layers[ layerName ].layer.remove();
		delete (c.layers[ layerName ]);

		if(!vis) {
			return false;
		}

		let src = diagData;

		// 308 is the gingival recession value
		let recessionValues = diagData
			.filter(row => row.adjust_line === 'gr')
			.reduce((accumulator, current) => {
				accumulator[ current.tooth ] = current.adjust_line_value * (current.adjust_line_multiplier || 1);
				return accumulator;
			}, {});


		c = helper.setActive(canvasName, layerName);
		//if(toothVis.adult) {
		renderLine('upper');
		renderLine('lower');
		//}

		/*if(toothVis.deciduous) {
			renderLine('upperDeciduous');
			renderLine('lowerDeciduous');
		}*/


		function renderLine(side) {
			let invert = side === 'lower',
				ints   = intersections[ key ][ side ],
				keys   = Object.keys(ints),
				len    = keys.length;

			while(len--) {
				let cur   = ints[ keys[ len ] ],
					tooth = keys[ len ],
					val   = recessionValues.hasOwnProperty(tooth)
						? recessionValues[ tooth ]
						: 0;

				if(invert && val !== 0) {
					val *= -1;
				}

				let y         = cur[ 0 ][ 1 ],
					isMissing = missing.indexOf(tooth) !== -1;

				if(isMissing) {
					val = 0;
				}

				let points = {
					start: [ cur[ 0 ][ 0 ], y ],
					mid: [
						((cur[ 1 ][ 0 ] - cur[ 0 ][ 0 ]) / 2) + cur[ 0 ][ 0 ],
						y - (val * 11),
					],
					end: [ cur[ 1 ][ 0 ], y ],
				};

				if(val !== 0) {
					//console.log(points);
				}

				let pathOpts = {
					strokeWidth: 4,
					strokeColor: '#666666',
					opacity: 1,
				};

				if((val < 0 && !invert) || (val > 0 && invert)) {
					pathOpts.fillColor = '#ff9c7b';
				}

				if(!isMissing) {
					let gradientOrigin = invert
						? [ 0, 1275 ]
						: [ 0, 300 ];
					let gradientDestination = invert
						? [ 0, 1308 ]
						: [ 0, 267 ];
					pathOpts.strokeColor = {
						gradient: {
							stops: [ '#666666', '#ddcb00', 'orange', '#c10000' ],
						},
						//origin and destination defines the direction of your gradient. In this case its vertical i.e bottom(blue/cooler) to up(red/warmer) refering to link you sent.
						origin: gradientOrigin, //gradient will start applying from y=200 towards y=0. Adjust this value to get your desired result
						destination: gradientDestination,
					};
				} else {
					pathOpts.dashArray = [ 10, 12 ];
					//pathOpts.opacity = 0.2;
					pathOpts.strokeColor = '#b4b4b4';
				}

				let path = new c.paper.Path({
					segments: [
						points.start,
						points.mid,
						points.end,
					],
					...pathOpts,
				});
				path.smooth({ type: 'continuous' });

				// Draw lines between teeth
				if(len > 0 && len < keys.length) {
					let next = ints[ keys[ len - 1 ] ];
					let points = {
						from: [ next[ 1 ][ 0 ], y ],
						to: [ cur[ 0 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = '#666666';
					line.strokeWidth = 4;
				}

				// Draw leftmost line
				if(len === 0) {
					let points = {
						from: [ 0, y ],
						to: [ cur[ 0 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = '#666666';
					line.strokeWidth = 4;
				}

				// Draw rightmost line
				if(len === keys.length - 1) {
					let points = {
						from: [ 5000, y ],
						to: [ cur[ 1 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = '#666666';
					line.strokeWidth = 4;
				}
			}

		}


	}



	function renderPerioDepthLines(pocketDepths, state, vis) {
		let layerName = 'lines_perio';
		let c = helper.setActive(canvasName, layerName);
		c.layers[ layerName ].layer.remove();
		delete (c.layers[ layerName ]);

		if(!vis) {
			return false;
		}

		let intersections = getIntersections(),
			values        = pocketDepths,
			missing       = state.missingTeeth,
			options       = state.options,
			chartingConf  = state.chartConfig,
			invertSides   = [ 'upper', 'lowerLP' ];

		c = helper.setActive(canvasName, layerName);
		renderLine('upper');
		renderLine('upperLP');
		renderLine('lower');
		renderLine('lowerLP');


		function renderLine(side) {
			const abnormalValue = options.abnormalPocketDepth || 0;

			let invert = invertSides.indexOf(side) !== -1,
				ints   = intersections[ 'perio' ][ side ],
				keys   = Object.keys(ints),
				len    = keys.length;

			while(len--) {
				let cur      = ints[ keys[ len ] ],
					tooth    = keys[ len ],
					existing = values.find(row => row.name === tooth),
					vals     = existing
						? existing.values
						: [ 0 ];

				if(invert) {
					vals = vals.map(depth => {
						return depth * -1;
					});
				}

				let offsets   = {
						upper: 0,
						upperLP: 20,
						lower: 0,
						lowerLP: -20,
					},
					offset    = offsets[ side ],
					y         = cur[ 0 ][ 1 ] + offset,
					isMissing = missing.indexOf(tooth) !== -1;

				/*if(isMissing) {
					vals = [ 0 ];
				}*/

				let points = {
					start: [ cur[ 0 ][ 0 ], y ],
					end: [ cur[ 1 ][ 0 ], y ],
					last: null,
				};

				let distance     = cur[ 1 ][ 0 ] - cur[ 0 ][ 0 ],
					pockets      = chartingConf[ tooth ]
						? chartingConf[ tooth ].pockets
						: 4,
					direction    = chartingConf[ tooth ].side,
					segments     = [ [ ...points.start ] ],
					defaultColor = '#35df2b',
					pathOpts     = {
						strokeWidth: 4,
						strokeColor: defaultColor,
						opacity: 1,
					};


				let pocketsBySide = {
					upper: pockets === 4
						? 3
						: 4,
					upperLP: pockets === 4
						? 1
						: 2,
					lower: pockets === 4
						? 3
						: 4,
					lowerLP: pockets === 4
						? 1
						: 2,
				};

				let renderPockets = pocketsBySide[ side ],
					srcVals;

				if(side === 'upper' || side === 'lower') {
					if(renderPockets === 3) {
						srcVals = [ 0, 1, 2 ];
					} else {
						srcVals = [ 0, 1, 2, 3 ];
					}
				}

				if(side === 'upperLP' || side === 'lowerLP') {
					if(renderPockets === 1) {
						srcVals = [ 3 ];
					} else {
						srcVals = [ 4, 5 ];
					}
				}

				if(isMissing) {
					pathOpts.dashArray = [ 10, 12 ];
					pathOpts.strokeColor = '#bfffb2';
				}

				for(let i = 0; i <= renderPockets; i++) {
					let src = direction === 'right'
							? i
							: renderPockets - 1 - i,
						val = vals[ srcVals[ src ] ] || 0;

					if(Math.abs(val) < abnormalValue) {
						val = 0;
					}

					let step  = distance / renderPockets,
						start = i === 0
							? [ ...points.start ]
							: [ ...points.last ],
						end   = i === (renderPockets)
							? [ ...points.end ]
							: [ start[ 0 ] + step, y + (val * 11) ];

					if(i === 0) {
						end[ 0 ] = end[ 0 ] - (step / 2);
					}

					points.last = end;
					segments.push(end);

					if(i === renderPockets) {
						segments.push(points.end);
					}
				}


				let path = new c.paper.Path({
					segments,
					...pathOpts,
				});
				path.smooth({ type: 'continuous' });

				// Draw lines between teeth
				if(len > 0 && len < keys.length) {
					let next = ints[ keys[ len - 1 ] ];
					let points = {
						from: [ next[ 1 ][ 0 ], y ],
						to: [ cur[ 0 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = defaultColor;
					line.strokeWidth = 4;
				}

				// Draw leftmost line
				if(len === 0) {
					let points = {
						from: [ 0, y ],
						to: [ cur[ 0 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = defaultColor;
					line.strokeWidth = 4;
				}

				// Draw rightmost line
				if(len === keys.length - 1) {
					let points = {
						from: [ 5000, y ],
						to: [ cur[ 1 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = defaultColor;
					line.strokeWidth = 4;
				}
			}

		}

	}



	function getIntersections() {
		penPal.canvas.select(canvasName);
		let intersections = {
			perio: {
				upper: null,
				lower: null,
				upperLP: null,
				lowerLP: null,
				upperDeciduous: null,
				lowerDeciduous: null,
			},
			gr: {
				upper: null,
				lower: null,
				upperDeciduous: null,
				lowerDeciduous: null,
			},
			grlp: {
				upper: null,
				lower: null,
			},
		};

		intersections[ 'gr' ].upper = helper.getIntersections(canvasName, 'teeth_buccal', [ 0, 300 ], [ 3000, 300 ], true);
		intersections[ 'gr' ].lower = helper.getIntersections(canvasName, 'teeth_buccal', [ 0, 1275 ], [ 3000, 1275 ], true);
		intersections[ 'grlp' ].upper = helper.getIntersections(canvasName, 'teeth_lingual_palatal', [ 0, 725 ], [ 3000, 725 ], true);
		intersections[ 'grlp' ].lower = helper.getIntersections(canvasName, 'teeth_lingual_palatal', [ 0, 895 ], [ 3000, 895 ], true);
		intersections[ 'gr' ].upperDeciduous = helper.getIntersections(canvasName, 'teeth_buccal_deciduous', [ 0, 300 ], [ 3000, 300 ], true);
		intersections[ 'gr' ].lowerDeciduous = helper.getIntersections(canvasName, 'teeth_buccal_deciduous', [ 0, 1275 ], [ 3000, 1275 ], true);

		intersections[ 'perio' ].upper = helper.getIntersections(canvasName, 'teeth_buccal', [ 0, 290 ], [ 3000, 290 ], true);
		intersections[ 'perio' ].upperLP = helper.getIntersections(canvasName, 'teeth_lingual_palatal', [ 0, 715 ], [ 3000, 715 ], true);
		intersections[ 'perio' ].lower = helper.getIntersections(canvasName, 'teeth_buccal', [ 0, 1287 ], [ 3000, 1287 ], true);
		intersections[ 'perio' ].lowerLP = helper.getIntersections(canvasName, 'teeth_lingual_palatal', [ 0, 900 ], [ 3000, 900 ], true);
		intersections[ 'perio' ].upperDeciduous = helper.getIntersections(canvasName, 'teeth_buccal_deciduous', [ 0, 290 ], [ 3000, 290 ], true);
		intersections[ 'perio' ].lowerDeciduous = helper.getIntersections(canvasName, 'teeth_buccal_deciduous', [ 0, 1287 ], [ 3000, 1287 ], true);

		return intersections;
	}



	function markMissing(e) {
		try {
			if(!e.item) {
				return false;
			}

			let layers      = store.getState().chart.layers,
				toothLayers = [ 'teeth_buccal', 'teeth_buccal_deciduous', 'teeth_lingual_palatal' ],
				layerVisMap = {
					"teeth_buccal": "adultTeeth",
					"teeth_buccal_deciduous": "deciduousTeeth",
					"teeth_lingual_palatal": "adultTeeth",
				},
				c           = penPal.canvas.select(canvasName),
				layerName   = e.item.layer.name,
				layerVis    = layers[ layerVisMap[ layerName ] ];

			if(!e.item
				|| !e.item.name
				|| !toothLayers.includes(layerName)
				|| !c.layers[ layerName ].paths.hasOwnProperty(e.item.name)
				|| !layerVis) {
				return false;
			}

			let tooth = e.item.name;
			store.dispatch(actions.toggleMissing(tooth));
		} catch(err) {
			console.error(err);
		}
	}


	function probeToothSelection(e) {
		try {
			if(!e.item) {
				return false;
			}

			let layers      = store.getState().chart.layers,
				toothLayers = [ 'teeth_buccal', 'teeth_lingual_palatal' ],
				layerVisMap = {
					"teeth_buccal": "adultTeeth",
					"teeth_lingual_palatal": "adultTeeth",
				},
				c           = penPal.canvas.select(canvasName),
				layerName   = e.item.layer.name,
				layerVis    = layers[ layerVisMap[ layerName ] ];

			if(!e.item
				|| !e.item.name
				|| !toothLayers.includes(layerName)
				|| !c.layers[ layerName ].paths.hasOwnProperty(e.item.name)
				|| !layerVis) {
				return false;
			}

			let tooth = e.item.name;
			store.dispatch(actions.setProbingOptions({
				activePocket: 0,
				activeTooth: tooth,
			}));
		} catch(err) {
			console.error(err);
		}
	}



	function highlightActiveTooth(activeTooth, previousTooth, activeTool, state) {
		let colors = state.colors;
		let shouldHighlight = activeTool === 'probe';
		/*if((tooth === last.tooth || !hasInitialized) && !shouldHighlight) {
		 return false;
		 }*/

		let c = penPal.canvas.select(canvasName);

		fill(
			c.layers[ 'teeth_buccal' ].paths[ previousTooth || activeTooth ],
			c.layers[ 'teeth_buccal' ].paths[ activeTooth ],
		);
		fill(
			c.layers[ 'teeth_lingual_palatal' ].paths[ previousTooth || activeTooth ],
			c.layers[ 'teeth_lingual_palatal' ].paths[ activeTooth ],
		);

		function fill(previous, current) {
			previous.strokeColor = colors.defaultStroke;
			previous.fillColor = colors.defaultFill;
			previous.strokeWidth = 3;
			current.strokeColor = shouldHighlight
				? colors.selectStroke
				: colors.defaultStroke;
			current.fillColor = shouldHighlight
				? colors.selectFill
				: colors.defaultFill;
			current.strokeWidth = shouldHighlight
				? 7
				: 3;
		}
	}



	this.init = function(target) {
		//store.dispatch(actions.resetChart({ Species: 'canine' }));
		//store.dispatch(actions.resetChartingOptions());
		helper.addCanvas(canvasName, target, 3, 1.55);
		hasInitialized = true;
		penPal.canvas.select(canvasName);

		// Tools
		helper.addDummyTool(canvasName);
		helper.addClickTool(canvasName, 'markMissing', markMissing);
		helper.addClickTool(canvasName, 'probeToothSelection', probeToothSelection);
		helper.addDrawingTool(canvasName, helper.saveDrawings);
		helper.addShapeTool(canvasName, helper.saveShapes);
		helper.addDeleteTool(canvasName);
		helper.addMoveTool(canvasName);
		helper.addRotateTool(canvasName);
		helper.addSymbolTool(canvasName);
		helper.addTextTool(canvasName);

		helper.activateTool(canvasName, 'dummy');
	};



	this.destroy = function() {

		if(!penPal.canvas.exists(canvasName)) {
			return false;
		}

		let c = penPal.canvas.select(canvasName);

		c.paper.view.onResize = null;
		penPal.canvas.destroy(canvasName);
	};


}
