import React, { useEffect } from 'react';
import useStyles from "pages/ClinicSettings/CustomCodes/CustomCodes.styles";
import DashboardCard from "components/DashboardCard";
import DataObject from "@mui/icons-material/DataObject";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from './CustomCodes.redux';
import { IntegratedPaging, PagingState, SelectionState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';



const columns = [
	{ name: 'symbol_abbreviation', title: 'Abbreviation' },
	{ name: 'symbol_name', title: 'Name' },
];

const CustomCodeList = () => {
	const classes  = useStyles(),
		  dispatch = useDispatch(),
		  codes    = useSelector(state => state.customCodes.codes);

	const handleAddNew = () => dispatch(actions.setState({ showForm: true }));

	const handleRowClick = selection => {
		let code = codes[ selection[ 0 ] ];
		dispatch(actions.setState({
			step: 2,
			showForm: true,
			category: code.symbol_category,
			type: code.symbol_chart_config.type,
			conf: { ...code },
		}));
	};


	useEffect(() => {
		dispatch(actions.loadCustomCodes());

		return () => dispatch(actions.setState({ codes: [] }));
	}, [ dispatch ]);

	return (
		<DashboardCard
			color={'orange'}
			icon={<DataObject />}
			topContent={"Custom Codes"}
			topLabel={
				<>
					<div>Choose a code to edit or</div>
					<div>
						<Button
							variant="contained"
							size="small"
							color="primary"
							className={classes.buttons}
							onClick={handleAddNew}
						>
							<Add /> Add New
						</Button>
					</div>
				</>
			}
			size={'xl'}
		>
			<Grid
				rows={codes}
				columns={columns}
			>
				<SelectionState
					selection={[]}
					onSelectionChange={handleRowClick}
				/>

				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table />
				<TableHeaderRow />
				<TableSelection
					selectByRowClick
					showSelectionColumn={false}
				/>
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>
		</DashboardCard>
	);
};


export default CustomCodeList;
