import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	flexContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		boxSizing: 'border-box',
		width: '100%',
	},
}));



export default useStyles;