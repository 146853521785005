import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, TextField } from "@mui/material";
import useStyles from './Clients.styles';
import { actions } from './Clients.redux';
import DashboardCard from "components/DashboardCard";
import { Search } from "@mui/icons-material";



const ClientSearch = () => {
	const classes       = useStyles(),
		  dispatch      = useDispatch(),
		  searchTerms   = useSelector(state => state.clients.searchTerms),
		  loading       = useSelector(state => state.clients.loading),
		  setSearchTerm = (key, value) => dispatch(actions.setSearchTerm(key, value)),
		  handleSearch  = () => dispatch(actions.search()),
		  resetSearch   = () => dispatch(actions.resetSearch());


	const handleChange = e => setSearchTerm(e.target.name, e.target.value);

	const handleSubmit = e => {
		e.preventDefault();
		handleSearch();
	};


	return (
		<div className={classes.searchContainer}>
			<DashboardCard
				color={'deepOrange'}
				icon={<Search />}
				topContent={"Client Search"}
				topLabel="Enter search criteria below"
				size="xl"
			>
				<form method="post" onSubmit={handleSubmit}>
					<Grid container spacing={4}>
						<Grid item xs={12} md={6} lg={12} xl={6}>
							<TextField
								label="Client Name"
								variant="outlined"
								fullWidth
								value={searchTerms.client_name}
								name="client_name"
								onChange={handleChange}
							/>
						</Grid>


						<Grid item xs={12} md={6} lg={12} xl={6}>
							<TextField
								label="External ID"
								variant="outlined"
								fullWidth
								value={searchTerms.client_external_id}
								name="client_external_id"
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12} md={6} lg={12} xl={6}>
							<TextField
								label="City"
								variant="outlined"
								fullWidth
								value={searchTerms.address_city}
								name="address_city"
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12} md={6} lg={12} xl={6}>
							<TextField
								label="State"
								variant="outlined"
								fullWidth
								value={searchTerms.address_state}
								name="address_state"
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12} md={6} lg={12} xl={6}>
							<TextField
								type="date"
								label="Last Visit (Start)"
								InputLabelProps={{ shrink: true }}
								variant="outlined"
								fullWidth
								value={searchTerms.last_visit_start}
								name="last_visit_start"
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12} md={6} lg={12} xl={6}>
							<TextField
								type="date"
								label="Last Visit (End)"
								InputLabelProps={{ shrink: true }}
								variant="outlined"
								fullWidth
								value={searchTerms.last_visit_end}
								name="last_visit_end"
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={handleSearch}
								className={classes.searchButtons}
								disabled={loading}
								type="submit"
							>
								Search
							</Button>

							<Button
								variant="outlined"
								color="primary"
								size="large"
								onClick={resetSearch}
								className={classes.searchButtons}
								disabled={loading}
							>
								Reset
							</Button>
						</Grid>

					</Grid>
				</form>
			</DashboardCard>
		</div>

	);
};


export default ClientSearch;