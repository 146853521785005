import React from 'react';
import { Check, Close } from '@mui/icons-material';
import { IconButton, Paper, Popover, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';



const styles = theme => ({
	paper: {
		padding: theme.spacing.unit,
		width: 400,
	},
	textField: {
		width: '100%',
	},
});



class TextEntryBox extends React.Component {

	state = { value: '' };

	updateValue = e => {
		this.setState({ value: e.target.value });
	};

	submit = () => {
		console.log('RUNNING SUBMIT', { ...this.state });
		this.props.callback({ ...this.state });
		this.props.close();
	};


	render() {
		const classes = this.props.classes;
		return (
            <Popover
				anchorEl={this.props.anchor}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "left",
				}}
				open={true}
			>
				<Paper className={classes.paper}>
					<TextField
						type="text"
						style={{ marginRight: 8 }}
						autoFocus={true}
						label="Custom Text"
						onChange={this.updateValue}
						value={this.state.value}
						multiline
						className={classes.textField}
					/>
					<IconButton onClick={this.submit} size="large">
						<Check style={{ color: 'green' }} />
					</IconButton>

					<IconButton onClick={this.props.close} size="large">
						<Close style={{ color: 'red' }} />
					</IconButton>
				</Paper>
			</Popover>
        );
	}
}



export default withStyles(styles)(TextEntryBox);