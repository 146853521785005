import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	editor: {
		width: '100%',
		height: 'calc(100vh - 220px)',
		margin: '8px auto',
	},
}));

export default useStyles;
