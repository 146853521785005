function stripScriptTags(html) {
	let el = document.createElement('div');
	el.innerHTML = html;

	let scripts = el.getElementsByTagName('script'),
		i       = scripts.length;

	while(i--) {
		scripts[ i ].parentNode.removeChild(scripts[ i ]);
	}

	let paragraphs = el.getElementsByTagName('p'),
		j          = paragraphs.length;

	while(j--) {
		if(!paragraphs[ j ].innerHTML) {
			paragraphs[ j ].parentNode.removeChild(paragraphs[ j ]);
		}
	}

	return el.innerHTML;
}


export default stripScriptTags;
