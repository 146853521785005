import makeStyles from '@mui/styles/makeStyles';
import { indigo, deepPurple, green, amber, blueGrey } from '@mui/material/colors';



const colors = {
	contactMethod: blueGrey,
	addresses: indigo,
	patients: green,
	reports: amber,
	visits: deepPurple,
};

const levels = {
	inactiveOff: 100,
	inactiveOn: 200,
	activeOff: 500,
	activeOn: 700,
};

const useStyles = makeStyles(theme => ({
	buttonGroupContainer: {
		maxWidth: 1200,
		margin: '0 auto',
		paddingLeft: 32,
		paddingRight: 32,
	},
	profileLoadingContainer: {
		width: 300,
	},
	editButton: {
		marginTop: 32,
	},
	cancelButton: {
		marginLeft: 16,
		marginTop: 32,
	},


	contactMethodInactive: {
		//backgroundColor: colors.contactMethod[levels.inactiveOff],
		'&:hover': {
			backgroundColor: colors.contactMethod[ levels.inactiveOn ],
		},
	},
	contactMethodActive: {
		backgroundColor: colors.contactMethod[ levels.activeOff ],
		color: '#ffffff',
		'&:hover': {
			backgroundColor: colors.contactMethod[ levels.activeOn ],
		},
	},


	addressesInactive: {
		//backgroundColor: colors.addresses[levels.inactiveOff],
		'&:hover': {
			backgroundColor: colors.addresses[ levels.inactiveOn ],
		},
	},
	addressesActive: {
		backgroundColor: colors.addresses[ levels.activeOff ],
		color: '#ffffff',
		'&:hover': {
			backgroundColor: colors.addresses[ levels.activeOn ],
		},
	},


	patientsInactive: {
		//backgroundColor: colors.patients[levels.inactiveOff],
		'&:hover': {
			backgroundColor: colors.patients[ levels.inactiveOn ],
		},
	},
	patientsActive: {
		backgroundColor: colors.patients[ levels.activeOff ],
		color: '#ffffff',
		'&:hover': {
			backgroundColor: colors.patients[ levels.activeOn ],
		},
	},


	reportsInactive: {
		//backgroundColor: colors.reports[levels.inactiveOff],
		'&:hover': {
			backgroundColor: colors.reports[ levels.inactiveOn ],
		},
	},
	reportsActive: {
		backgroundColor: colors.reports[ 600 ],
		color: '#ffffff',
		'&:hover': {
			backgroundColor: colors.reports[ levels.activeOn ],
		},
	},


	visitsInactive: {
		//backgroundColor: colors.reports[levels.inactiveOff],
		'&:hover': {
			backgroundColor: colors.visits[ levels.inactiveOn ],
		},
	},
	visitsActive: {
		backgroundColor: colors.visits[ levels.activeOff ],
		color: '#ffffff',
		'&:hover': {
			backgroundColor: colors.visits[ levels.activeOn ],
		},
	},

}));



export default useStyles;