module.exports = {
	"t101": {
		"paths": [
			"M850.9,113.8c0,0-1.7,1.8-1.9,3.9c-0.2,2.1,0.8,2.8,0.8,3.6c0,0.8,0,6.2,0.6,6.7c0.6,0.5,4.4,2,6.5,0      c2.1-2,3.6-6.2,3.7-7.2c0.2-1,0-2.8,0.3-4.4c0.3-1.6-0.2-2.5-1-3.4c0-3.2-0.3-5.9,0.5-10c0.8-4.1-0.3-8.6-0.8-11.5      c-0.5-2.9-0.9-7.2-4.6-7.2c-3.6-0.1-3.8,3.8-3.9,4.8c-0.1,1-0.2,5.9-0.4,7.2s0.9,6.2,0.7,7.9C851.2,106,850.6,111.4,850.9,113.8z"
		],
		"baseLine": {
			"x1": "850.8",
			"x2": "860.1",
			"y1": "113.8",
			"y2": "113.8"
		}
	},
	"t102": {
		"paths": [
			"M812.3,116.9c0,0-1.2,1.5-1,3.1c0.2,1.6,0.8,1.6,0.9,2.9c0.1,1.3-0.3,1.8,0.1,3.1c0.3,1.3,1.5,2.6,1.3,3.9      c-0.2,1.3-1.8,5.7,0.7,6.4c2.5,0.7,5.6,0.9,10.4-6.1c4.9-7,1.4-13.5,0.9-14c0-2-0.4-10.3,0.6-15c1-4.7-1.3-16.2-2.1-18.3      c-0.8-2.1-1.9-6.3-6.1-5.5c-4.2,0.8-4.2,4.9-4.3,6.5s-0.7,9.1-0.4,13.2S811.7,113.4,812.3,116.9z"
		],
		"baseLine": {
			"x1": "812.1",
			"x2": "825.7",
			"y1": "116.7",
			"y2": "116.7"
		}
	},
	"t103": {
		"paths": [
			"M762.8,115c0,0-2.8,2-2.8,5.3s3.7,7.6,3.9,9.9c0.2,2.3,1.2,6.6,1.2,9.2s3.7,8.8,6.9,8.3      c3.2-0.6,6.7-2.9,7-8.6s-0.9-7.8,0.3-11.6c1.2-3.8,2.8-5.9,2-8.5c-0.8-2.6-0.8-0.4-1.9-4c2.6-15.9,3.4-20.2,3.1-26.1      c-0.2-5.9,0.9-22.5-5.8-23.1c-6.7-0.7-7.9,5.5-8.3,6.8C768.2,73.8,764,106.7,762.8,115z"
		],
		"baseLine": {
			"x1": "779.5",
			"x2": "762.6",
			"y1": "114.8",
			"y2": "114.8"
		}
	},
	"t104": {
		"paths": [
			"M659.4,106.6c0,0,6.2,15.3,8.4,32.7c2.2,17.9,8.9,40.6,11.2,71.7c2.3,31.2,15.4,5.7,17.1,2.7      s17.5-56.4,10.4-101.4c-6.2-24.4-11.5-43-29.2-70c-16.9-25.6-44.5-27.6-53-24.5c-6.4,2.4,2.5,24,14.1,42.9      C644.2,70.1,652.1,84.4,659.4,106.6z"
		],
		"baseLine": {
			"x1": "661.3",
			"x2": "706.1",
			"y1": "112.2",
			"y2": "112.2"
		}
	},
	"t106": {
		"paths": [
			"M572.1,115.5c0,0-4.8,6.5-3.8,12.1s2.2,4.2,4.8,6.5c2.6,2.2,1.9,4.4,2.3,6c0.4,1.7,3.8,7.6,8.4,3.7      c4.6-3.9,5.9-3.5,5.3-10.5c-0.7-7,2-12.6,1.6-14.6c-0.4-2-1.9-2.6-1.6-4.9c0.3-2.3,1.6-12.1,2.7-17.4c1.1-5.4,0.7-16.2-0.4-21.2      s-1.9-9.5-7-9.5c-5.1,0-5.5,4.7-6.3,7.7c-0.8,3-1.6,17.4-2.7,20.2C574.2,96.3,572.1,113.4,572.1,115.5z"
		],
		"baseLine": {
			"x1": "571.8",
			"x2": "589",
			"y1": "115.5",
			"y2": "115.5"
		}
	},
	"t107": {
		"paths": [
			"M487.4,115c0,0-6,4.5-5.1,8.5c0.9,4,5.8,0.2,7.4,3.1s3.4,12.7,6.7,14.1c3.4,1.3,5.6-4.5,6-3.4      c0.7,10.7,7.2,16.6,7.2,16.6s11.6,19.5,21.6-3c11.3-25.2,8.7-23,8.7-23c3.6,2.9,8.3,4,10.5-2.2S545,115,545,115s6.1-33.8,4.5-45.4      S544,37.9,533.7,41c-10.3,3.1-7.8,8-7.6,9.6c0.2,1.6,1.1,8,0.2,17c-0.9,8.9-2.9,34.3-3.8,37.1c-1.1,3.5-6,6.5-7.6,0.2      c-1.6-6.3-2.9-36.6-2.7-45.6c0.2-8.9,2.7-11.6-0.9-15.2c-3.6-3.6-8-6.7-11-5.4c-2.9,1.3-6.9,7.6-8.7,12.7S484.5,89.3,487.4,115z"
		],
		"baseLine": {
			"x1": "545.3",
			"x2": "487.8",
			"y1": "115",
			"y2": "115"
		}
	},
	"t108": {
		"paths": [
			"M414.6,115.7c0,0,8.5-57.8,8.8-62.4s4.5-9.1,9.5-8.8s7.4,4,8.5,12.1s3.4,35.1,1.5,40.3      c-1.9,5.3-14,19.3-14,19.3l-13.6,2L414.6,115.7z",
			"M365.4,116c0,0-3-38.2,1.2-57.2s6-27.5,12.4-27.7c6.5-0.2,13,22,15.4,29.4c3.2,9.9,16.9,41.2,28.2,41.2      c11.8,0,33.5-53.1,33.5-53.1s4.7-9.1,11-5.9c6.3,3.1,6.9,5.6,5.9,22.6c-1,17-2.2,20.4-4,26.7c-1.8,6.3-5.3,21.9-4.6,23.6      c0.7,1.7,5.5,0.4,6,5.4c0.4,4.9-7.2,27.4-13.2,30.2c-6,2.8-6.9-4.5-9.9-4.7c-3-0.2-24.8,32-29.5,31.2      c-4.7-0.8-11.7-29.5-15.3-29.8c-3.6-0.3-6.7,5.4-10.2,5.3s-28.9-11.6-31.7-14.6C357.6,135.3,351,124.7,365.4,116z"
		],
		"baseLine": {
			"x1": "365.1",
			"x2": "464.7",
			"y1": "115.8",
			"y2": "115.8"
		}
	},
	"t109": {
		"paths": [
			"M323.3,89.2c-2.7-4.7-8.7-10.8-13.3-7.8c-6.1,4,12.3,30.8,12.3,30.8L323.3,89.2z",
			"M321.5,114.4c0,0-4,2-3.5,7.2c0.5,5.2,0.2,4.9,0.3,8.5s-0.2,8.5,6,11.2c6.2,2.7,8.4,3.4,15.3-3.9      c6.9-7.2,3.2-22.9-1.2-23c0,0-1-30.8-1.2-34.8c-0.2-4-1.8-5.2-3.5-3.9c0,0,1.3-4-2.4-4.3c-3.7-0.3-4.3,3.2-4.4,3.2      c-2.1-1.6-4.6-1.2-5.6,1.4c-1,2.6-2.2,15.9-1.7,20.4C320.2,101.2,322.2,111.8,321.5,114.4z"
		],
		"baseLine": {
			"x1": "339",
			"x2": "321.1",
			"y1": "114.7",
			"y2": "114.7"
		}
	},
	"t201": {
		"paths": [
			"M896.6,113.8c0,0,1.7,1.8,1.9,3.9c0.2,2.1-0.8,2.8-0.8,3.6c0,0.8,0,6.2-0.6,6.7c-0.6,0.5-4.4,2-6.5,0      c-2.1-2-3.6-6.2-3.7-7.2s0-2.8-0.3-4.4c-0.3-1.6,0.2-2.5,1-3.4c0-3.2,0.3-5.9-0.5-10c-0.8-4.1,0.3-8.6,0.8-11.5      c0.5-2.9,0.9-7.2,4.6-7.2c3.6-0.1,3.8,3.8,3.9,4.8c0.1,1,0.2,5.9,0.4,7.2s-0.9,6.2-0.7,7.9C896.2,106,896.9,111.4,896.6,113.8z"
		],
		"baseLine": {
			"x1": "896.6",
			"x2": "887.3",
			"y1": "113.8",
			"y2": "113.8"
		}
	},
	"t202": {
		"paths": [
			"M935.2,116.9c0,0,1.2,1.5,1,3.1c-0.2,1.6-0.8,1.6-0.9,2.9c-0.1,1.3,0.3,1.8-0.1,3.1c-0.3,1.3-1.5,2.6-1.3,3.9      c0.2,1.3,1.8,5.7-0.7,6.4s-5.6,0.9-10.4-6.1c-4.9-7-1.4-13.5-0.9-14c0-2,0.4-10.3-0.6-15c-1-4.7,1.3-16.2,2.1-18.3      c0.8-2.1,1.9-6.3,6.1-5.5c4.2,0.8,4.2,4.9,4.3,6.5s0.7,9.1,0.4,13.2S935.7,113.4,935.2,116.9z"
		],
		"baseLine": {
			"x1": "935.3",
			"x2": "921.8",
			"y1": "116.7",
			"y2": "116.7"
		}
	},
	"t203": {
		"paths": [
			"M984.7,115c0,0,2.8,2,2.8,5.3s-3.7,7.6-3.9,9.9c-0.2,2.3-1.2,6.6-1.2,9.2s-3.7,8.8-6.9,8.3      c-3.2-0.6-6.7-2.9-7-8.6c-0.3-5.7,0.9-7.8-0.3-11.6c-1.2-3.8-2.8-5.9-2-8.5s0.8-0.4,1.9-4c-2.6-15.9-3.4-20.2-3.1-26.1      c0.2-5.9-0.9-22.5,5.8-23.1c6.7-0.7,7.9,5.5,8.3,6.8C979.2,73.8,983.4,106.7,984.7,115z"
		],
		"baseLine": {
			"x1": "967.9",
			"x2": "984.8",
			"y1": "114.8",
			"y2": "114.8"
		}
	},
	"t204": {
		"paths": [
			"M1088,106.6c0,0-6.2,15.3-8.4,32.7c-2.2,17.9-8.9,40.6-11.2,71.7s-15.4,5.7-17.1,2.7s-17.5-56.4-10.4-101.4      c6.2-24.4,11.5-43,29.2-70c16.9-25.6,44.5-27.6,53-24.5c6.4,2.4-2.5,24-14.1,42.9C1103.3,70.1,1095.4,84.4,1088,106.6z"
		],
		"baseLine": {
			"x1": "1086.2",
			"x2": "1041.3",
			"y1": "112.2",
			"y2": "112.2"
		}
	},
	"t206": {
		"paths": [
			"M1175.3,115.5c0,0,4.8,6.5,3.8,12.1c-1,5.6-2.2,4.2-4.8,6.5c-2.6,2.2-1.9,4.4-2.3,6c-0.4,1.7-3.8,7.6-8.4,3.7      c-4.6-3.9-5.9-3.5-5.3-10.5c0.7-7-2-12.6-1.6-14.6c0.4-2,1.9-2.6,1.6-4.9c-0.3-2.3-1.6-12.1-2.7-17.4s-0.7-16.2,0.4-21.2      s1.9-9.5,7-9.5s5.5,4.7,6.3,7.7c0.8,3,1.6,17.4,2.7,20.2S1175.3,113.4,1175.3,115.5z"
		],
		"baseLine": {
			"x1": "1175.6",
			"x2": "1158.4",
			"y1": "115.5",
			"y2": "115.5"
		}
	},
	"t207": {
		"paths": [
			"M1260,115c0,0,6,4.5,5.1,8.5c-0.9,4-5.8,0.2-7.4,3.1c-1.6,2.9-3.4,12.7-6.7,14.1s-5.6-4.5-6-3.4      c-0.7,10.7-7.2,16.6-7.2,16.6s-11.6,19.5-21.6-3c-11.3-25.2-8.7-23-8.7-23c-3.6,2.9-8.3,4-10.5-2.2s5.4-10.7,5.4-10.7      s-6.1-33.8-4.5-45.4c1.6-11.6,5.6-31.7,15.9-28.6c10.3,3.1,7.8,8,7.6,9.6c-0.2,1.6-1.1,8-0.2,17c0.9,8.9,2.9,34.3,3.8,37.1      c1.1,3.5,6,6.5,7.6,0.2c1.6-6.3,2.9-36.6,2.7-45.6s-2.7-11.6,0.9-15.2c3.6-3.6,8-6.7,11-5.4c2.9,1.3,6.9,7.6,8.7,12.7      C1257.5,56.7,1262.9,89.3,1260,115z"
		],
		"baseLine": {
			"x1": "1202.1",
			"x2": "1259.7",
			"y1": "115",
			"y2": "115"
		}
	},
	"t208": {
		"paths": [
			"M1332.8,115.7c0,0-8.5-57.8-8.8-62.4c-0.3-4.6-4.5-9.1-9.5-8.8s-7.4,4-8.5,12.1s-3.4,35.1-1.5,40.3      c1.9,5.3,14,19.3,14,19.3l13.6,2L1332.8,115.7z",
			"M1382,116c0,0,3-38.2-1.2-57.2c-4.1-19-6-27.5-12.4-27.7c-6.5-0.2-13,22-15.4,29.4      c-3.2,9.9-16.9,41.2-28.2,41.2c-11.8,0-33.5-53.1-33.5-53.1s-4.7-9.1-11-5.9c-6.3,3.1-6.9,5.6-5.9,22.6c1,17,2.2,20.4,4,26.7      c1.8,6.3,5.3,21.9,4.6,23.6c-0.7,1.7-5.5,0.4-6,5.4c-0.4,4.9,7.2,27.4,13.2,30.2c6,2.8,6.9-4.5,9.9-4.7c3-0.2,24.8,32,29.5,31.2      c4.7-0.8,11.7-29.5,15.3-29.8c3.6-0.3,6.7,5.4,10.2,5.3c3.5-0.1,28.9-11.6,31.7-14.6S1396.4,124.7,1382,116z"
		],
		"baseLine": {
			"x1": "1382.4",
			"x2": "1282.7",
			"y1": "115.8",
			"y2": "115.8"
		}
	},
	"t209": {
		"paths": [
			"M1424.2,89.2c2.7-4.7,8.7-10.8,13.3-7.8c6.1,4-12.3,30.8-12.3,30.8L1424.2,89.2z",
			"M1425.9,114.4c0,0,4,2,3.5,7.2c-0.5,5.2-0.2,4.9-0.3,8.5s0.2,8.5-6,11.2c-6.2,2.7-8.4,3.4-15.3-3.9      c-6.9-7.2-3.2-22.9,1.2-23c0,0,1-30.8,1.2-34.8s1.8-5.2,3.5-3.9c0,0-1.3-4,2.4-4.3c3.7-0.3,4.3,3.2,4.4,3.2      c2.1-1.6,4.6-1.2,5.6,1.4c1,2.6,2.2,15.9,1.7,20.4C1427.2,101.2,1425.2,111.8,1425.9,114.4z"
		],
		"baseLine": {
			"x1": "1408.4",
			"x2": "1426.3",
			"y1": "114.7",
			"y2": "114.7"
		}
	},
	"t301": {
		"paths": [
			"M900.1,333.6c3-5.1,1.2-4.8,1.4-7.6c0.2-2.8,0.7-7.9-5-9.9c-5.6-2-7.5-2.5-11.1,2.4c0.9,5,1.4,8.2,1.2,10.4      c-0.2,2.2,1.5,2.8,1.3,4.2c-0.3,6,3.1,14.4,3.3,19.5s1.8,14.5,2.8,15.9c1,1.4,6.1,4.2,7.7-2.1c1.6-6.3,0.9-19.1,0.1-20.7      S900,335.2,900.1,333.6z"
		],
		"baseLine": {
			"x1": "901",
			"x2": "888.2",
			"y1": "331.3",
			"y2": "331.3"
		}
	},
	"t302": {
		"paths": [
			"M944.2,333.1c2.4-5.2,0.8-6.1,1.1-8.2c0.3-2.2,0.7-6.9-5.7-8.6c-6.4-1.6-6-2.9-8.3-0.9      c-2.4,2.1-1.3,5.3-0.9,8.5s-0.3,7.6,1.6,8.8c0.7,5.1,2.8,15.1,3.4,20.7c0.5,5.6,1.2,14.7,3.9,16.3c2.7,1.5,4.7,1.3,6.6-3.5      c2-4.8,0.2-20.7-0.8-23.7C944.1,339.4,944.3,333.9,944.2,333.1z"
		],
		"baseLine": {
			"x1": "944.4",
			"x2": "931.8",
			"y1": "333",
			"y2": "333"
		}
	},
	"t303": {
		"paths": [
			"M994.6,332.4c2-2.7,1.7-6.5,1.7-9.3c0-2.8,2-8.6-2.2-10.3c-4.1-1.7-4.1-2.7-10.4,0s-5.6,2.2-6.3,3.6      c-0.7,1.4,0.3,4.3,0.4,6.2c0.1,1.8-1.2,7.3,1.5,10.1c0,6.7,2.3,14.7,2.4,19.7s0.9,12.6,1.4,16.9c0.5,4.3,2.1,8.4,6.3,8.5      c4.2,0.1,6.2-6.1,6.3-10.1s1.3-14.9,0.5-20.4S994.3,334,994.6,332.4z"
		],
		"baseLine": {
			"x1": "994.5",
			"x2": "979.5",
			"y1": "332.6",
			"y2": "332.6"
		}
	},
	"t304": {
		"paths": [
			"M1029.3,332.7c-0.9-31.6,2.6-64.1,12.4-90.4c5.5-15,10.1-15.6,11.4-14.3c1.3,1.3,5.2,4.6,4.9,16.3      c-0.3,11.7,11,69.1,22.9,92.3c7.4,14.3,23,45.5,42.1,65.9c15.2,16.3,13.8,29.8,8.9,34.3c-5.6,5.1-26.2,0.8-37.9-7      c-15.3-10.2-20.9-14.1-31.2-26.5C1051.3,389.8,1031.4,360.3,1029.3,332.7z"
		],
		"baseLine": {
			"x1": "1078.2",
			"x2": "1029.4",
			"y1": "330.4",
			"y2": "330.4"
		}
	},
	"t307": {
		"paths": [
			"M1205.5,338.9c-1.8-0.2-2.2,3.7-3.1,7.6c-0.9,3.9-4.2,11.5-5.8,20.4c-1.6,8.9-8.5,38.2-18.3,40.6      s-7.6-14.1-7.3-18.4c0.4-4.3,7.1-53.6,5.5-55.5c-1.1-3.3-3.5-3-2.5-9.1s6.7-8.5,8.4-10.6c1.6-2.2,12.5-27.3,14.7-31.9      c2.2-4.6,5.6-7.6,10.2-0.2c4.5,7.4,8,19.7,10.4,24.1c3.9-1.6,9.1,11.2,13.1,18c1.6,2.7,1.8,6.5-3.6,10.6      c1.8,12.4,3.6,28.4,3.8,34.1s-0.7,33.2-7.3,39.5c-6.5,6.3-6.4,7.2-10.4,2.6c-4-4.6-4.7-2.6-3.3-16.7      C1211.3,379.6,1205.5,338.9,1205.5,338.9z"
		],
		"baseLine": {
			"x1": "1230",
			"x2": "1175.8",
			"y1": "331.3",
			"y2": "331.3"
		}
	},
	"t308": {
		"paths": [
			"M1288,339.8c-1.6-2.7-12.6,35.3-14.5,42.7c-1.9,7.4,1.4,20.4-1.9,24.7c-3.3,4.3-11.4,9.5-16.7,3.9      c-5.2-5.6-7.6-29.9-7.6-40.3s-3.8-33.4-0.5-39c-4.3-8.2-5.7-11.8-3.8-17.8c1.5-4.6,4.7-6.3,6.1-5.6c1.4,0.7,2.2,3.5,4.1,3.3      c1.9-0.2,16.5-25.8,21.5-33s6.7-11.7,12.4-11.1c5.7,0.7,16.6,28.3,19.9,31.1c3.3-3.3,5.9-4.4,9.4-1.5c3.6,2.6,4.5,6.3,9.1,10.6      c2.5,5.2,6,18.2-4.1,23.6c5.2,25.2,4.1,13.9,6.2,30.6c2.1,16.7-0.2,35.4-2.1,41c-1.9,5.6-3.3,14.5-9.5,15.4s-12.9-2.8-13.8-5.4      c-1-2.6-1-12.6-3.8-29.1C1295.4,367.3,1290.8,344.7,1288,339.8z"
		],
		"baseLine": {
			"x1": "1322.4",
			"x2": "1247",
			"y1": "330.6",
			"y2": "330.6"
		}
	},
	"t309": {
		"paths": [
			"M1428.5,329.5c7.9-4.8,7.3-10.8,3.1-19.1c-4.2-8.2-8.1-20.6-8.6-27c-0.7-6.2-1.9-11.8-5-13.8      c-5.8-0.9-23.7,11.2-30.5,17.1c-5.9,5.4-5.9,6.6-8.2,8.2c-3.9-4.9-13.2-11.4-18.4-14.4c-5.2-3-10-9.1-12.6-7.8      c-2.6,1.3-4.2,14.7-3.7,23c0.5,8.2-4.7,18.2-3.7,25.6s1.6,6.1,5.8,11.7c-2.1,24.7,0.5,23.4-2.6,38.2s-0.5,39.9,6.3,46.4      c6.8,6.5,17.3,0.9,21.5-2.2c4.2-3,14.8-49,17.9-56c3.1-6.9,3.1-22.6,5.8-22.6c2.6,0,5.8,8.7,12.1,13.9      c6.3,5.2,14.1,27.8,18.3,33.4c4.2,5.6,6.8,4.3,10.5,3.9c3.7-0.4,2.6-22.1,0-27.8C1433.7,354.7,1426.4,337.3,1428.5,329.5z"
		],
		"baseLine": {
			"x1": "1428.3",
			"x2": "1344.6",
			"y1": "330",
			"y2": "330"
		}
	},
	"t401": {
		"paths": [
			"M848.9,333.6c-3-5.1-1.2-4.8-1.4-7.6c-0.2-2.8-0.7-7.9,5-9.9c5.6-2,7.5-2.5,11.1,2.4c-0.9,5-1.4,8.2-1.2,10.4      c0.2,2.2-1.5,2.8-1.3,4.2c0.3,6-3.1,14.4-3.3,19.5s-1.8,14.5-2.8,15.9c-1,1.4-6.1,4.2-7.7-2.1c-1.6-6.3-0.9-19.1-0.1-20.7      S849,335.2,848.9,333.6z"
		],
		"baseLine": {
			"x1": "847.9",
			"x2": "860.7",
			"y1": "331.3",
			"y2": "331.3"
		}
	},
	"t402": {
		"paths": [
			"M804.7,333.1c-2.4-5.2-0.8-6.1-1.1-8.2c-0.3-2.2-0.7-6.9,5.7-8.6c6.4-1.6,6-2.9,8.3-0.9      c2.4,2.1,1.3,5.3,0.9,8.5s0.3,7.6-1.6,8.8c-0.7,5.1-2.8,15.1-3.4,20.7c-0.5,5.6-1.2,14.7-3.9,16.3c-2.7,1.5-4.7,1.3-6.6-3.5      c-2-4.8-0.2-20.7,0.8-23.7C804.8,339.4,804.6,333.9,804.7,333.1z"
		],
		"baseLine": {
			"x1": "804.5",
			"x2": "817.1",
			"y1": "333",
			"y2": "333"
		}
	},
	"t403": {
		"paths": [
			"M754.3,332.4c-2-2.7-1.7-6.5-1.7-9.3c0-2.8-2-8.6,2.2-10.3c4.1-1.7,4.1-2.7,10.4,0s5.6,2.2,6.3,3.6      c0.7,1.4-0.3,4.3-0.4,6.2c-0.1,1.8,1.2,7.3-1.5,10.1c0,6.7-2.3,14.7-2.4,19.7s-0.9,12.6-1.4,16.9c-0.5,4.3-2.1,8.4-6.3,8.5      c-4.2,0.1-6.2-6.1-6.3-10.1s-1.3-14.9-0.5-20.4S754.6,334,754.3,332.4z"
		],
		"baseLine": {
			"x1": "754.4",
			"x2": "769.4",
			"y1": "332.6",
			"y2": "332.6"
		}
	},
	"t404": {
		"paths": [
			"M719.6,332.7c0.9-31.6-2.6-64.1-12.4-90.4c-5.5-15-10.1-15.6-11.4-14.3c-1.3,1.3-5.2,4.6-4.9,16.3      c0.3,11.7-11,69.1-22.9,92.3c-7.4,14.3-23,45.5-42.1,65.9c-15.2,16.3-13.8,29.8-8.9,34.3c5.6,5.1,26.2,0.8,37.9-7      c15.3-10.2,20.9-14.1,31.2-26.5C697.6,389.8,717.5,360.3,719.6,332.7z"
		],
		"baseLine": {
			"x1": "670.7",
			"x2": "719.5",
			"y1": "330.4",
			"y2": "330.4"
		}
	},
	"t407": {
		"paths": [
			"M543.4,338.9c1.8-0.2,2.2,3.7,3.1,7.6c0.9,3.9,4.2,11.5,5.8,20.4s8.5,38.2,18.3,40.6s7.6-14.1,7.3-18.4      c-0.4-4.3-7.1-53.6-5.5-55.5c1.1-3.3,3.5-3,2.5-9.1c-0.9-6.1-6.7-8.5-8.4-10.6c-1.6-2.2-12.5-27.3-14.7-31.9      c-2.2-4.6-5.6-7.6-10.2-0.2s-8,19.7-10.4,24.1c-3.9-1.6-9.1,11.2-13.1,18c-1.6,2.7-1.8,6.5,3.6,10.6c-1.8,12.4-3.6,28.4-3.8,34.1      s0.7,33.2,7.3,39.5s6.4,7.2,10.4,2.6c4-4.6,4.7-2.6,3.3-16.7C537.6,379.6,543.4,338.9,543.4,338.9z"
		],
		"baseLine": {
			"x1": "518.9",
			"x2": "573.1",
			"y1": "331.3",
			"y2": "331.3"
		}
	},
	"t408": {
		"paths": [
			"M461,339.8c1.6-2.7,12.6,35.3,14.5,42.7s-1.4,20.4,1.9,24.7c3.3,4.3,11.4,9.5,16.7,3.9      c5.2-5.6,7.6-29.9,7.6-40.3s3.8-33.4,0.5-39c4.3-8.2,5.7-11.8,3.8-17.8c-1.5-4.6-4.7-6.3-6.1-5.6c-1.4,0.7-2.2,3.5-4.1,3.3      c-1.9-0.2-16.5-25.8-21.5-33s-6.7-11.7-12.4-11.1c-5.7,0.7-16.6,28.3-19.9,31.1c-3.3-3.3-5.9-4.4-9.4-1.5      c-3.6,2.6-4.5,6.3-9.1,10.6c-2.5,5.2-6,18.2,4.1,23.6c-5.2,25.2-4.1,13.9-6.2,30.6c-2.1,16.7,0.2,35.4,2.1,41      c1.9,5.6,3.3,14.5,9.5,15.4c6.2,0.9,12.9-2.8,13.8-5.4c1-2.6,1-12.6,3.8-29.1S458.1,344.7,461,339.8z"
		],
		"baseLine": {
			"x1": "426.5",
			"x2": "502",
			"y1": "330.6",
			"y2": "330.6"
		}
	},
	"t409": {
		"paths": [
			"M320.5,329.5c-7.9-4.8-7.3-10.8-3.1-19.1c4.2-8.2,8.1-20.6,8.6-27c0.7-6.2,1.9-11.8,5-13.8      c5.8-0.9,23.7,11.2,30.5,17.1c5.9,5.4,5.9,6.6,8.2,8.2c3.9-4.9,13.2-11.4,18.4-14.4c5.2-3,10-9.1,12.6-7.8      c2.6,1.3,4.2,14.7,3.7,23c-0.5,8.2,4.7,18.2,3.7,25.6c-1,7.4-1.6,6.1-5.8,11.7c2.1,24.7-0.5,23.4,2.6,38.2      c3.1,14.7,0.5,39.9-6.3,46.4c-6.8,6.5-17.3,0.9-21.5-2.2c-4.2-3-14.8-49-17.9-56c-3.1-6.9-3.1-22.6-5.8-22.6      c-2.6,0-5.8,8.7-12.1,13.9s-14.1,27.8-18.3,33.4c-4.2,5.6-6.8,4.3-10.5,3.9c-3.7-0.4-2.6-22.1,0-27.8      C315.2,354.7,322.6,337.3,320.5,329.5z"
		],
		"baseLine": {
			"x1": "320.7",
			"x2": "404.4",
			"y1": "330",
			"y2": "330"
		}
	}
};