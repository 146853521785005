import React from 'react';
import ReactDOM from 'react-dom';


export function hideDialogAtMouse(sel) {
	if(!sel) {
		return false;
	}

	sel.style.position = 'fixed';
	sel.style.opacity  = 0;
	sel.style.left     = -100;
	sel.style.top      = -100;
	sel.style.zIndex   = -100;
	ReactDOM.render(<div />, sel);
}


export default function dialogAtMouse(util, sel, e, Component, state = {}) {
	if(!sel) {
		return false;
	}

	let x = e.event.type === 'touchend'
		? e.event.changedTouches[0].clientX
		: e.event.x;

	let y = e.event.type === 'touchend'
		? e.event.changedTouches[0].clientY
		: e.event.y;

	sel.style.display  = 'block';
	sel.style.position = 'fixed';
	sel.style.opacity  = 1;
	sel.style.left     = `${x}px`;
	sel.style.top      = `${y}px`;
	sel.style.zIndex   = 100;
	ReactDOM.render(<Component {...util} state={state} open={true} paperEvent={e} />, sel);
}