import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Button } from '@mui/material';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import defaultTheme from 'styles/theme_default';
import Router from 'Router';
import { Provider } from 'react-redux';
import store from 'store';
import 'typeface-roboto';
import './index.css';



const App = () => {
	return (
		<SnackbarProvider
			maxSnack={3}
			autoHideDuration={4000}
			preventDuplicate={true}
			anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
			action={(snackbarId) =>
				<Button sx={{ color: '#000000' }} onClick={() => closeSnackbar(snackbarId)}>Dismiss</Button>
			}
		>
			<Provider store={store}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={defaultTheme}>
						<CssBaseline />
						<Router />
					</ThemeProvider>
				</StyledEngineProvider>
			</Provider>
		</SnackbarProvider>
	);
};



export default App;
