import api from 'api';



const baseType = 'USERS';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	RESET_ALL: `${baseType}/RESET_ALL`,
	RESET_DELETE: `${baseType}/RESET_DELETE`,
	RESET_INVITE: `${baseType}/RESET_INVITE`,
};

const initialState = {
	users: [],
	loading: false,
	inviteEmail: '',
	showInviteModal: false,
	inviteLoading: false,
	inviteError: null,
	showDeleteModal: false,
	deleteInfo: {},
	deleting: false,
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			console.log(action);
			return { ...state, ...action.data };

		case types.RESET_ALL:
			return { ...initialState };

		case types.RESET_DELETE:
			return { ...state, showDeleteModal: false, deleteInfo: {}, deleting: false };

		case types.RESET_INVITE:
			return { ...state, showInviteModal: false, inviteEmail: '', inviteLoading: false, inviteError: null };

		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	resetAll: () => ({ type: types.RESET_ALL }),


	resetDelete: () => ({ type: types.RESET_DELETE }),


	resetInvite: () => ({ type: types.RESET_INVITE }),


	deleteUser: (link_id) => async (dispatch) => {
		dispatch(actions.setState({ deleting: true }));

		try {
			await api.post('/clinic-settings/delete-user', {
				link_id: link_id,
			});

			dispatch(actions.resetDelete());
			dispatch(actions.loadUsers());

		} catch(err) {
			console.error(err);
			dispatch(actions.resetDelete());
		}
	},



	inviteUser: (email) => async (dispatch) => {
		dispatch(actions.setState({ inviteLoading: true }));

		try {
			await api.post('/clinic-settings/invite-user', {
				email: email,
			});

			dispatch(actions.resetInvite());

		} catch(err) {
			console.error(err);
			dispatch(actions.setState({
				inviteLoading: false,
				inviteError: err.response?.data?.message || 'An unknown error occurred while inviting the user',
			}));
		}
	},



	loadUsers: () => async (dispatch) => {
		try {
			let users = await api.get('/clinic-settings/users');
			dispatch(actions.setState({ users: users.data.users }));
		} catch(err) {
			console.error(err);
		}
	},
};
