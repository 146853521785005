import axios from 'axios';
import store, { actions } from 'store';
import debounce from 'lodash/debounce';



const sendRequest = debounce((term) => {

	let options = {
		method: 'post',
		url: `https://development.sabertooth.io/test/search`,
		withCredentials: true,
		data: { term },
	};

	return axios.request(options)
	.then((res) => {
		store.dispatch(actions.layout.setState({
			appSearchResults: res.data.data,
			appSearchPending: false,
		}));
	}).catch((err) => {
		store.dispatch(actions.layout.setState({
			appSearchResults: [],
			appSearchPending: false,
		}));
	});
}, 400);


export default sendRequest;