import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	icon: {
		fontSize: '32px !important',
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));



export default useStyles;