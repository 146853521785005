import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	inputWrapper: {
		padding: theme.spacing(2),
	},
}));



export default useStyles;