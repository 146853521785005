import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'pages/Chart/Chart.redux';



const useChartAutoSave = () => {
    const dispatch = useDispatch(),
          interval = useSelector(state => state.chart.autoSaveInterval),
          enabled  = useSelector(state => state.chart.autoSaveEnabled);

    const intervalRef = useRef(null);

    useEffect(() => {
        if(enabled) {
            clearInterval(intervalRef.current);
            intervalRef.current = setInterval(() => {
                dispatch(actions.saveChart(true));
            }, interval * 1000);
        }

        if(!enabled) {
            clearInterval(intervalRef.current);
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [ intervalRef, interval, enabled, dispatch ]);

    return [ enabled, interval ];
};

export default useChartAutoSave;