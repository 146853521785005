import React, { useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Pets } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import ClientChartsTableRow from "./ClientChartsTableRow";
import { actions } from '../ClientView.redux';



const columns = [
	{ name: 'date_created', title: 'Date' },
	{ name: 'patient_name', title: 'Name' },
	{ name: 'patient_weight', title: 'Weight' },
	{ name: 'is_neutered', title: 'Neutered' },
	{ name: 'created_by', title: 'Created by' },
];


const ClientCharts = () => {
	const dispatch      = useDispatch(),
		  { client_id } = useParams(),
		  hasLoaded     = useSelector(state => state.clientView.chartsPreviouslyLoaded),
		  loading       = useSelector(state => state.clientView.chartsLoading),
		  charts        = useSelector(state => state.clientView.charts),
		  loadCharts    = (clientID) => dispatch(actions.loadCharts(clientID));


	useEffect(() => {
		if(!hasLoaded && !loading) {
			loadCharts(client_id);
		}

	}, [ loadCharts, client_id, hasLoaded, loading ]);


	return (
		<DashboardCard
			color={'deepPurple'}
			icon={<Pets />}
			topContent={"Charts"}
			topLabel="Click a chart to view or edit"
			size="xl"
		>
			<Grid
				rows={charts}
				columns={columns}
			>
				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table rowComponent={ClientChartsTableRow} />
				<TableHeaderRow />
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>
		</DashboardCard>
	);
};


export default ClientCharts;