import api from 'api';



const baseType = 'FILE_MANAGER';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	RESET_ALL: `${baseType}/RESET_ALL`,
	SET_PAGE: `${baseType}/SET_PAGE`,
	SET_SEARCH_VALUE: `${baseType}/SET_SEARCH_VALUE`,
	SET_BROWSE_OPTIONS: `${baseType}/SET_BROWSE_OPTIONS`,
};

const initialState = {
	open: false,
	page: 'upload',
	quota: null,
	browseOptions: {
		client_name: '',
		patient_name: '',
		report_name: '',
		visit_id: '',
		year: '', //new Date().getFullYear(),
		month: '',
		file_name: '',
	},
	uploadOptions: {},
	uploadKeys: {},
	files: [],
	file_count: 0,
	loading: false,
	loaded: false,
	page_number: 0,
	page_size: 12,
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };

		case types.RESET_ALL:
			return { ...initialState };

		case types.SET_PAGE: {
			if(state.loading) {
				return state;
			}

			return state.page === action.page
				? state
				: {
					...state,
					browseOptions: {
						...initialState.browseOptions,
						visit_id: state.browseOptions.visit_id,
					},
					page: action.page,
					files: [],
					loading: false,
					loaded: false,
					page_number: 0,
					file_count: 0,
				};
		}


		case types.SET_SEARCH_VALUE:
			return {
				...state,
				browseOptions: {
					...state.browseOptions,
					[ action.key ]: action.value,
				},
			};


		case types.SET_BROWSE_OPTIONS:
			return {
				...state,
				browseOptions: {
					...state.browseOptions,
					...action.data,
				},
			};


		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),

	resetAll: () => ({ type: types.RESET_ALL }),

	setPage: (page) => ({ type: types.SET_PAGE, page }),

	setBrowseOptions: (data) => ({ type: types.SET_BROWSE_OPTIONS, data }),
	
	getQuota: () => async (dispatch, getState) => {
		try {
			let result = await api.get('/files/quota');
			dispatch(actions.setState({ quota: `${result.data.quota.quota_used} / ${result.data.quota.quota_total}GB Used` }));
		} catch(err) {
			console.error(err);
		}
	},



	getRecentFiles: (pageNumber) => async (dispatch, getState) => {
		dispatch(actions.setState({
			loading: true,
			loaded: false,
			page_number: pageNumber,
			page_size: 12,
		}));

		try {
			let result = await api.get('/files/recent', {
				params: {
					page_number: pageNumber,
				},
			});

			dispatch(actions.setState({
				files: result.data.files,
				file_count: result.data.file_count,
				loading: false,
				loaded: true,
			}));
		} catch(err) {
			console.error(err);
			dispatch(actions.setState({ loading: false, loaded: true }));
		}
	},



	setSearchValue: (key, value) => ({ type: types.SET_SEARCH_VALUE, key, value }),




	searchFiles: () => async (dispatch, getState) => {
		dispatch(actions.setState({
			loading: true,
			loaded: false,
		}));

		let state  = getState().files,
			params = {
				page_number: state.page_number,
				page_size: state.page_size,
				client_name: state.browseOptions.client_name,
				patient_name: state.browseOptions.patient_name,
				report_name: state.browseOptions.report_name,
				visit_id: state.browseOptions.visit_id,
				year: state.browseOptions.year,
				month: state.browseOptions.month,
				file_name: state.browseOptions.file_name,
			};

		try {
			let result = await api.get('/files/search', {
				params: params,
			});

			dispatch(actions.setState({
				files: result.data.files,
				file_count: result.data.file_count,
				loading: false,
				loaded: true,
			}));
		} catch(err) {
			console.error(err);
			dispatch(actions.setState({ loading: false, loaded: true }));
		}
	},


	searchFilesRelevant: (id_type, id) => async (dispatch, getState) => {
		dispatch(actions.setState({
			loading: true,
			loaded: false,
		}));

		let state  = getState().files,
			params = {
				page_number: state.page_number,
				page_size: state.page_size,
				client_id: null,
				patient_id: null,
				visit_id: null,
				urpt_id: null,
				[ id_type ]: id,
			};

		try {
			let result = await api.get('/files/search-relevant', {
				params: params,
			});

			dispatch(actions.setState({
				files: result.data.files,
				file_count: result.data.file_count,
				loading: false,
				loaded: true,
			}));
		} catch(err) {
			console.error(err);
			dispatch(actions.setState({ loading: false, loaded: true }));
		}
	},



	setSearchPage: (pageNumber) => (dispatch) => {
		dispatch(actions.setState({ page_number: pageNumber }));
		dispatch(actions.searchFiles());
	},
};
