import React, { useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { actions } from './Clients.redux';



const ClientAddModal = () => {
	const dispatch        = useDispatch(),
		  cancelNewClient = () => dispatch(actions.cancelNewClient()),
		  saveNewClient   = () => dispatch(actions.saveNewClient()),
		  updateNewClient = (e) => dispatch(actions.updateNewClient(e));

	const open   = useSelector(state => state.clients.showAddNew),
		  saving = useSelector(state => state.clients.savingClient),
		  values = useSelector(state => state.clients.newClientDetails);

	const formRef = useRef();

	return (
		<Dialog
			open={open}
		>
			<DialogTitle>Add Client</DialogTitle>


			<form ref={formRef}>
				<DialogContent>


					<FormControl fullWidth margin="normal">
						<Input
							type="text"
							placeholder="Client Name"
							name="client_name"
							onChange={updateNewClient}
							value={values.client_name}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<Input
							type="text"
							placeholder="External Software ID"
							name="client_external_id"
							onChange={updateNewClient}
							value={values.client_external_id}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<Input
							type="text"
							placeholder="Primary Vet"
							name="primary_vet"
							onChange={updateNewClient}
							value={values.primary_vet}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<Input
							type="text"
							placeholder="Primary Clinic"
							name="primary_clinic"
							onChange={updateNewClient}
							value={values.primary_clinic}
						/>
					</FormControl>

				</DialogContent>


				<DialogActions>
					<Button disabled={saving} onClick={saveNewClient}>Save</Button>
					<Button disabled={saving} onClick={cancelNewClient}>Cancel</Button>
				</DialogActions>


			</form>
		</Dialog>
	);
};



export default ClientAddModal;
