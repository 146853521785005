import React, { useEffect } from 'react';
import PenPal from 'lib/PenPal';
import SVGHandler from 'lib/svg/Buccal';
import SVGHelper from 'lib/svg/helper';



const Admin = () => {
	const penPal     = new PenPal(),
		  helper     = new SVGHelper(penPal),
		  svgHandler = new SVGHandler(penPal, helper),
		  canvas     = React.createRef();

	useEffect(() => {
		svgHandler.init(canvas.current);

		return () => {
			svgHandler.destroy();
		};
	}, []);

	if(penPal.canvas.exists('buccal')) {
		penPal.sizeCanvas('buccal', 0);
	}

	return (
		<div ref={canvas} />
	);
};



export default Admin;