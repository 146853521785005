import React, { useCallback, useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Add, Pets } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import ClientPatientTableRow from "./ClientPatientTableRow";
import { actions } from '../ClientView.redux';
import { Button } from "@mui/material";
import ClientPatientsEditModal from "./ClientPatientsEditModal";


const columns = [
	{ name: 'patient_name', title: 'Name' },
	{ name: 'species', title: 'Species' },
	{ name: 'breed', title: 'Breed' },
	{ name: 'gender', title: 'Gender' },
	{ name: 'is_neutered', title: 'Altered' },
	{ name: 'primary_clinic', title: 'Primary Clinic' },
	{ name: 'primary_vet', title: 'Primary Vet' },
	//{ name: 'age', title: 'Age' },
];


const ClientPatients = () => {
	const dispatch      = useDispatch(),
		  { client_id } = useParams(),
		  hasLoaded     = useSelector(state => state.clientView.patientsPreviouslyLoaded),
		  loading       = useSelector(state => state.clientView.patientsLoading),
		  patients      = useSelector(state => state.clientView.patients),
		  loadPatients  = (clientID) => dispatch(actions.loadPatients(clientID));


	const handleAddNew = useCallback(() => {
		dispatch(actions.openPatientsEditModal());
	}, [dispatch]);


	useEffect(() => {
		if(!hasLoaded && !loading) {
			loadPatients(client_id);
		}

	}, [ loadPatients, client_id, hasLoaded, loading ]);


	return (
		<DashboardCard
			color={'green'}
			icon={<Pets />}
			topContent={"Patients"}
			topLabel={<div>
				<div>Click a patient to view or edit, or</div>
				<div>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={<Add />}
						onClick={handleAddNew}
					>
						Add New
					</Button>
				</div>
			</div>}
			size="xl"
		>
			<Grid
				rows={patients}
				columns={columns}
			>
				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table rowComponent={ClientPatientTableRow} />
				<TableHeaderRow />
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>

			<ClientPatientsEditModal />
		</DashboardCard>
	);
};


export default ClientPatients;