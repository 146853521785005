export default {
	onMouseDown: function(e, util) {
		util.custom = new util.c.paper.Path({
			segments: [e.point],
			strokeColor: 'green',
			strokeWidth: 15,
			fullySelected: false,
		});
	},
	onMouseDrag: function(e, util) {
		util.custom.add(e.point);
	},
	onMouseUp: function(e, util) {
		util.custom.simplify(15);
	},
};