import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';



const useNavigationWarning = (
	message = 'You have unsaved changes. Are you sure you want to leave this page?',
) => {
	const [ isDirty, setIsDirty ] = useState(false);

	useEffect(() => {
		window.onbeforeunload = isDirty && (() => message);

		return () => {
			window.onbeforeunload = null;
		};
	}, [ isDirty ]);

	const prompt = <Prompt when={isDirty} message={message} />;

	return [ prompt, () => setIsDirty(true), () => setIsDirty(false) ];
};


export default useNavigationWarning;
