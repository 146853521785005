import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import DashboardCard from "components/DashboardCard";
import { FormatPaint } from "@mui/icons-material";
import { CirclePicker } from 'react-color';
import api from 'api';
import { enqueueSnackbar } from 'notistack';



const fetchDefaults = async () => {
	return await api.get(`/clinic-settings/charting-defaults`);
};

const savePreference = async (prefName, prefValue) => {
	return await api.put(`/clinic-settings/charting-defaults`, {
		pref_name: prefName,
		data: prefValue,
	});
};

const colors = [
	'#191970', // midnightblue
	'#0000FF', // blue
	'#4682B4', // steelblue
	'#4B0082', // indigo
	'#800080', // purple
	'#663399', // rebeccapurple
	'#006400', // darkgreen
	'#008000', // green
	'#32CD32', // limegreen
	'#8B0000', // darkred
	'#FF0000', // red
	'#FF4500', // orangered
	'#FFA500', // orange
	'#C71585', // mediumvioletred
	'#800000', // maroon
	'#8B4513', // saddlebrown
	'#A0522D', // sienna
	'#696969', // dimgray
	'#2F4F4F', // darkslategray
	'#000000', // black
];


const ChartAppearance = () => {

	const [ saving, setSaving ]   = useState(false),
		  [ loading, setLoading ] = useState(false),
		  [ prefs, setPrefs ]     = useState({});

	useEffect(() => {
		setLoading(true);
		fetchDefaults().then(result => {
			setPrefs(result?.data?.preferences ?? {});
			setLoading(false);
		}).catch(err => {
			console.error(err);
			setLoading(false);
		});
	}, [ setLoading, setPrefs ]);

	const handleFontSizeChange = async (e) => {
		const selectedFontSize = Number(e.target.value);

		setSaving(true);

		try {
			await savePreference('chart_font_size', selectedFontSize);

			setPrefs(current => ({
				...current,
				chart_font_size: selectedFontSize,
			}));

			enqueueSnackbar('Font size saved.', { variant: 'success', autoHideDuration: 2000 });

		} catch(err) {
			enqueueSnackbar('There was an error saving your font size preference.', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			console.error(err);
		} finally {
			setSaving(false);
		}
	};

	const handleCodeColorChange = async (prefName, value) => {
		setSaving(true);

		try {
			await savePreference(prefName, value);

			setPrefs(current => ({
				...current,
				[ prefName ]: value,
			}));

			enqueueSnackbar('Color saved.', { variant: 'success', autoHideDuration: 2000 });

		} catch(err) {
			enqueueSnackbar('There was an error saving your color preference.', {
				variant: 'error',
				autoHideDuration: 3000,
			});
			console.error(err);
		} finally {
			setSaving(false);
		}
	};

	console.log(prefs);

	return (
		<div>
			<DashboardCard
				color="orange"
				icon={<FormatPaint />}
				topContent="Chart Appearance"
				size="xl"
			>
				<Grid container spacing={4} sx={{ maxWidth: '700px' }}>
					<Grid item xs={12}>
						<FormControl component="fieldset">
							<div>Charting Code font size:</div>
							<RadioGroup
								value={prefs?.chart_font_size ?? 48}
								onChange={handleFontSizeChange}
							>
								<FormControlLabel
									disabled={(loading || saving)}
									value={24}
									control={<Radio />}
									label="Tiny"
								/>
								<FormControlLabel
									disabled={(loading || saving)}
									value={36}
									control={<Radio />}
									label="Small"
								/>
								<FormControlLabel
									disabled={(loading || saving)}
									value={48}
									control={<Radio />}
									label="Medium"
								/>
								<FormControlLabel
									disabled={(loading || saving)}
									value={60}
									control={<Radio />}
									label="Large"
								/>
							</RadioGroup>
						</FormControl>
					</Grid>


					<Grid item xs={12} md={6}>
						<Box
							sx={{
								color: prefs?.chart_dx_color_adult ?? '#000000',
								fontWeight: 700,
								fontSize: '16px',
							}}
						>
							Adult Diagnostic Color:
						</Box>
						<CirclePicker
							width="264px"
							circleSize={32}
							colors={colors}
							color={prefs?.chart_dx_color_adult}
							onChange={(color) => handleCodeColorChange('chart_dx_color_adult', color.hex)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Box
							sx={{
								color: prefs?.chart_dx_color_deciduous ?? '#000000',
								fontWeight: 700,
								fontSize: '16px',
							}}
						>
							Deciduous Diagnostic Color:
						</Box>
						<CirclePicker
							width="264px"
							circleSize={32}
							colors={colors}
							color={prefs?.chart_dx_color_deciduous}
							onChange={(color) => handleCodeColorChange('chart_dx_color_deciduous', color.hex)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Box
							sx={{
								color: prefs?.chart_tx_color_adult ?? '#000000',
								fontWeight: 700,
								fontSize: '16px',
							}}
						>
							Adult Treatment Color:
						</Box>
						<CirclePicker
							width="264px"
							circleSize={32}
							colors={colors}
							color={prefs?.chart_tx_color_adult}
							onChange={(color) => handleCodeColorChange('chart_tx_color_adult', color.hex)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Box
							sx={{
								color: prefs?.chart_tx_color_deciduous ?? '#000000',
								fontWeight: 700,
								fontSize: '16px',
							}}
						>
							Deciduous Treatment Color:
						</Box>
						<CirclePicker
							width="264px"
							circleSize={32}
							colors={colors}
							color={prefs?.chart_tx_color_deciduous}
							onChange={(color) => handleCodeColorChange('chart_tx_color_deciduous', color.hex)}
						/>
					</Grid>

				</Grid>


			</DashboardCard>
		</div>
	);
};


export default ChartAppearance;
