import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Grid as MUIGrid } from '@mui/material';
import useStyles from './ClientView.styles';
import Profile from './Profile';
import { actions } from './ClientView.redux';
import classnames from 'classnames';
import ClientContactMethods from './ClientContactMethods';
import ClientReports from './ClientReports';
import ClientPatients from './ClientPatients';
import ClientCharts from './ClientCharts';
import ClientAddresses from './ClientAddresses';

const columns = [
	{ name: 'client_name', title: 'Name' },
	{ name: 'client_external_id', title: 'External ID' },
	{ name: 'last_visit', title: 'Last Visit' },
	{ name: 'address_city', title: 'City' },
	{ name: 'address_state', title: 'State' },
];


const ClientView = () => {

	const tab      = useSelector(state => state.clientView.tab),
		  dispatch = useDispatch(),
		  classes  = useStyles(),
		  resetAll = () => dispatch(actions.resetAll());


	const handleTabChange = useCallback((e) => {
		if(tab === e.currentTarget.name) {
			return false;
		}

		dispatch(actions.setState({ tab: e.currentTarget.name }));
	}, [ dispatch, tab ]);


	useEffect(() => {
		return () => {
			resetAll();
		};
	}, [ dispatch ]);


	return (
		<MUIGrid container spacing={4}>
			<MUIGrid item xs={12} sm={12} lg={4} xl={3}>
				<Profile />
			</MUIGrid>

			<MUIGrid item xs={12} sm={12} lg={8} xl={9}>
				<div className={classes.buttonGroupContainer}>
					<ButtonGroup fullWidth variant="contained">
						<Button
							size="large"
							name="patients"
							onClick={handleTabChange}
							classes={{
								root: classnames({
									[ classes.patientsActive ]: tab === 'patients',
									[ classes.patientsInactive ]: tab !== 'patients',
								}),
							}}
						>
							Patients
						</Button>

						<Button
							size="large"
							name="charts"
							onClick={handleTabChange}
							classes={{
								root: classnames({
									[ classes.visitsActive ]: tab === 'charts',
									[ classes.visitsInactive ]: tab !== 'charts',
								}),
							}}
						>
							Charts
						</Button>

						<Button
							size="large"
							name="reports"
							onClick={handleTabChange}
							classes={{
								root: classnames({
									[ classes.reportsActive ]: tab === 'reports',
									[ classes.reportsInactive ]: tab !== 'reports',
								}),
							}}
						>
							Reports
						</Button>


						<Button
							size="large"
							name="contactMethods"
							onClick={handleTabChange}
							classes={{
								root: classnames({
									[ classes.contactMethodActive ]: tab === 'contactMethods',
									[ classes.contactMethodInactive ]: tab !== 'contactMethods',
								}),
							}}
						>
							Contact Methods
						</Button>

						<Button
							size="large"
							name="addresses"
							onClick={handleTabChange}
							classes={{
								root: classnames({
									[ classes.addressesActive ]: tab === 'addresses',
									[ classes.addressesInactive ]: tab !== 'addresses',
								}),
							}}
						>
							Addresses
						</Button>

					</ButtonGroup>
				</div>

				{tab === 'contactMethods' && <ClientContactMethods />}
				{tab === 'addresses' && <ClientAddresses />}
				{tab === 'patients' && <ClientPatients />}
				{tab === 'reports' && <ClientReports />}
				{tab === 'charts' && <ClientCharts />}


			</MUIGrid>
		</MUIGrid>
	);

};



export default ClientView;