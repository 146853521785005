import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
    buttonContainer: {
        marginBottom: 32,
    },
    container: {
        width: 500,
        height: 600,
    },
    contentContainer: {
        padding: 8,
    },
    symbolContainer: {
        height: 'calc(100% - 170px)',
        overflow: 'auto',
    },
    centerText: {
        textAlign: 'center',
    },
    compactModeButton: {
        width: 'calc(100% / 3 - 10px)',
        marginLeft: 5,
        marginRight: 5,
    },
    compactModeRow: {
        height: 60,
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    multiDialogCodeName: {
        fontSize: '0.8em',
        maxWidth: 350,
        marginBottom: 0,
        paddingBottom: 0,
    },
}));



export default useStyles;