import React, { useContext, useState } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Snackbar,
} from "@mui/material";
import { Check, Error } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "pages/Chart/Chart.redux";
import useStyles from './SnapshotDialog.styles';
import PenpalContextBuccal from '../PenpalContextBuccal';
import PenpalContextOcclusal from '../PenpalContextOcclusal';
import api from 'api';



const SnapshotDialog = () => {
    const dispatch                        = useDispatch(),
          classes                         = useStyles(),
          open                            = useSelector(state => state.chart.toolbar.selectedTool === 'snapshot'),
          visitID                         = useSelector(state => state.chart.visit_id),
          clientID                        = useSelector(state => state.chart.client_id),
          patientID                       = useSelector(state => state.chart.patient_id),
          [ selected, setSelected ]       = useState({ buccal: false, occlusal: false }),
          [ success, setSuccess ]         = useState({ buccal: null, occlusal: null }),
          [ saving, setSaving ]           = useState(false),
          [ showSuccess, setShowSuccess ] = useState(false),
          penpalBuccal                    = useContext(PenpalContextBuccal),
          penpalOcclusal                  = useContext(PenpalContextOcclusal);

    const handleClose = (event, reason) => {
        if(reason === 'backdropClick') {
            return false;
        }

        setSelected({ buccal: false, occlusal: false });
        setSaving(false);
        setSuccess({ buccal: null, occlusal: null });

        dispatch(actions.setToolbar({
            selectedButton: null,
            selectedTool: '',
        }));
    };


    const handleCheck = (event) => {
        setSelected((current) => ({
            ...current,
            [event.target.name]: event.target.checked,
        }));
    };


    const handleSave = async () => {
        let errors = [];

        setSaving(true);

        if(selected.buccal) {
            try {
                let saveString = penpalBuccal.getSVGString('buccal'),
                    result     = await api.post('/charts/snapshot', {
                        canvas: 'buccal',
                        chart_data: btoa(saveString),
                        client_id: clientID,
                        patient_id: patientID,
                        visit_id: visitID,
                        urpt_id: null,
                    }, {
                        timeout: 120000,
                    });

                setSuccess((current) => ({
                    ...current,
                    buccal: true,
                }));
            } catch (err) {
                console.error(err);
                errors.push(err);
                setSuccess((current) => ({
                    ...current,
                    buccal: false,
                }));
            }
        }

        if(selected.occlusal) {
            try {
                let saveString = penpalOcclusal.getSVGString('occlusal'),
                    result     = await api.post('/charts/snapshot', {
                        canvas: 'occlusal',
                        chart_data: btoa(saveString),
                        client_id: clientID,
                        patient_id: patientID,
                        visit_id: visitID,
                        urpt_id: null,
                    }, {
                        timeout: 120000,
                    });

                setSuccess((current) => ({
                    ...current,
                    occlusal: true,
                }));
            } catch (err) {
                console.error(err);
                errors.push(err);
                setSuccess((current) => ({
                    ...current,
                    occlusal: false,
                }));
            }
        }

        setSaving(false);

        if(!errors.length) {
            setShowSuccess(true);
            handleClose();
        }

    };


    const handleSaveAndGoToReports = async () => {
        try {
            await handleSave();
            dispatch(actions.setState({ reportModalStatus: 'list' }));
        } catch (err) {
            console.error(err);
        }
    };


    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                disableEscapeKeyDown
            >
                <DialogTitle>Save Chart Image</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the image(s) you would like to save, then click Save. The images will be added to your
                        Sabertooth File Manager for use in reports. Snapshots are taken as-is, so any layers you have
                        enabled or disabled will be reflected in the resulting image.
                    </DialogContentText>

                    <div>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={selected.buccal}
                                    name="buccal"
                                    onChange={handleCheck}
                                    disabled={saving || success.buccal}
                                />}
                                label={<>
                                    Buccal
                                    {success.buccal === true &&
                                        <Check style={{ color: 'green' }} />
                                    }
                                    {success.buccal === false &&
                                        <Error style={{ color: 'red' }} />
                                    }
                                </>}
                            />

                            <FormControlLabel
                                control={<Checkbox
                                    checked={selected.occlusal}
                                    name="occlusal"
                                    onChange={handleCheck}
                                    disabled={saving || success.occlusal}
                                />}
                                label={<>
                                    Occlusal
                                    {success.occlusal === true &&
                                        <Check style={{ color: 'green' }} />
                                    }
                                    {success.occlusal === false &&
                                        <Error style={{ color: 'red' }} />
                                    }
                                </>}
                            />
                        </FormGroup>
                    </div>


                </DialogContent>

                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSave}
                        disabled={saving || (!selected.buccal && !selected.occlusal)}
                    >
                        Save
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSaveAndGoToReports}
                        disabled={saving || (!selected.buccal && !selected.occlusal)}
                    >
                        Save & Go to Reports
                    </Button>
                    <Button color="secondary" variant="outlined" disabled={saving} onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>

            </Dialog>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showSuccess}
                autoHideDuration={5000}
                onClose={() => setShowSuccess(false)}
                message="Your snapshots were saved successfully and are available in the Sabertooth File Manager"
            />
        </>
    );
};



export default SnapshotDialog;
