import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions } from './FileManager.redux';



const FileManagerQuota = () => {
	const dispatch = useDispatch(),
		  open     = useSelector(state => state.files.open),
		  quota    = useSelector(state => state.files.quota);


	useEffect(() => {
		if(open && quota === null) {
			dispatch(actions.getQuota());
		}
	}, [ dispatch, quota, open ]);


	return quota;
};


export default FileManagerQuota;
