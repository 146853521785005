import React from 'react';
import { useSelector } from "react-redux";
import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import UsersTableCell from "./UsersTableCell";



const columns = [
	{ name: 'login_name', title: 'Name' },
	{ name: 'login_email', title: 'Email' },
	{ name: 'is_admin', title: 'User Type' },
	{ name: 'remove', title: 'Remove from Clinic' },
];

const UsersTable = () => {
	const users = useSelector(state => state.users.users);


	return (
		<div>
			<Grid
				rows={users}
				columns={columns}
			>
				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table cellComponent={UsersTableCell} />
				<TableHeaderRow />
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>
		</div>
	);
};


export default UsersTable;
