import React from 'react';
import store from 'store';
import { actions } from 'pages/Chart/Chart.redux';
import drawLineFromOrigin from "./drawLineFromOrigin";



function getDrawingMaxSize(canvasName) {
	switch(canvasName) {
		case 'occlusal':
			return 65536; // 64 KB
		case 'buccal':
			return 65536; // 64 KB
		case 'perio':
			return 10240; // 10 KB
		default:
			return 0;
	}
}

export default function(penPal) {

	let app                = this,
		canvases           = {},
		toothLayers        = [
			'teeth_buccal',
			'teeth_buccal_deciduous',
			'teeth_lingual_palatal',
			'teeth_occlusal',
			'teeth_occlusal_deciduous',
		],
		layerSaveFunctions = {
			drawing: 'saveDrawings',
			shapes: 'saveShapes',
			diagnostics: 'saveSymbols',
			treatments: 'saveSymbols',
			text: 'saveText',
		};



	/**
	 * Creates a canvas inside the given element with given X and Y ratios
	 * @param {string} canvasName - The name of the canvas
	 * @param {HTMLElement} el - Element to insert the canvas into
	 * @param {number} X - Width ratio
	 * @param {number} Y - Height ratio
	 * @returns {object}
	 */
	this.addCanvas = function(canvasName, el, X, Y) {
		return canvases[ canvasName ] = {
			activeTool: null,
			activeLayer: null,
			canvas: penPal.canvas.create(canvasName, el, {
				ratioX: X,
				ratioY: Y,
			}),
		};
	};


	this.setActive = function(canvasName, layerName) {
		let c = penPal.canvas.select(canvasName);
		c.paper.activate();
		penPal.layer.set(layerName);
		return c;
	};


	this.clearLayer = function(canvasName, layerName) {
		try {
			let c = canvases[ canvasName ].canvas;
			penPal.layer.set(layerName);
			c.layers[ layerName ].layer.remove();
			delete (c.layers[ layerName ]);
			penPal.layer.set(layerName);
		} catch(err) {
			console.error(err);
		}

	};


	this.activateTool = function(canvasName, toolName) {
		try {
			const c = penPal.canvas.select(canvasName, toolName);

			if(!c.tools[ toolName ]) {
				c.tools.dummy.activate();
				console.log(`Could not find ${toolName}, activated dummy instead.`);
				return true;
			}

			c.tools[ toolName ].activate();
			return true;
		} catch(err) {
			console.error(err);
			return false;
		}
	};



	this.addDummyTool = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.canvas.select(canvasName);
		c.paper.activate();
		penPal.project.createTool('dummy', {});
		canvases[ canvasName ].activeTool = 'dummy';
	};



	this.addClickTool = function(canvasName, toolName, callback) {
		try {
			let c = canvases[ canvasName ].canvas;
			let handlers = {
				onMouseUp: function(e) {
					callback(e);
				},
			};

			penPal.canvas.select(canvasName);
			c.paper.activate();
			penPal.project.createTool(toolName, handlers);
			canvases[ canvasName ].activeTool = toolName;
		} catch(err) {
			console.error(err);
		}
	};



	this.getIntersections = function(canvasName, layerName, from, to, simplify = true) {

		let c = this.setActive(canvasName, 'intersectionTemp');
		let paths = c.layers[ layerName ].paths;
		let intersections = {};
		let line = new c.paper.Path.Line(from, to);

		//line.strokeColor = 'red';

		function findIntersections(path) {
			path.children.forEach(child => {
				let parentName = child.parent.name;
				if(!parentName) {
					return false;
				}

				let clone = child.clone();
				let intersect = clone.getIntersections(line);

				if(intersect.length) {
					if(!intersections.hasOwnProperty(parentName)) {
						intersections[ parentName ] = [];
					}

					intersections[ parentName ].push(intersect);
				}

				clone.remove();
			});
		}

		for(let pathname in paths) {
			let path = paths[ pathname ];
			findIntersections(path);
		}

		line.remove();

		if(simplify) {
			intersections = simplifyIntersections(intersections, c);
		}

		this.setActive(canvasName, layerName);

		return intersections;
	};


	function simplifyIntersections(intersections, c) {
		let ints = {};

		for(let parent in intersections) {
			let cur = intersections[ parent ];
			let first;
			let last;

			cur.forEach(group => {
				group.forEach(points => {
					//console.log('points', points.point)
					let x = points.point.x;
					let y = points.point.y;

					if(!first) {
						first = [ x, y ];
						last = [ x, y ];
						return true;
					}

					if(x > first[ 0 ]) {
						last = [ x, y ];
					} else if(x < first[ 0 ]) {
						first = [ x, y ];
					}
				});
			});

			ints[ parent ] = [ first, last ];

			/*new c.paper.Path.Circle({
			 center: first,
			 radius: 5,
			 fillColor: '#009dec'
			 });
			 new c.paper.Path.Circle({
			 center: last,
			 radius: 5,
			 fillColor: '#009dec'
			 });*/

		}

		return ints;
	}


	this.importDrawings = function(canvasName, drawings, tooth = null) {
		app.clearDrawings(canvasName);

		if(!store.getState().chart.layers.drawings) {
			return false;
		}

		let c = app.setActive(canvasName, 'drawing');
		let drawData = tooth
			? drawings.hasOwnProperty(tooth)
				? drawings[ tooth ]
				: []
			: drawings;



		drawData.forEach(function(data) {
			let p = new c.paper.Path({
				pathData: data.pathData,
				position: [ data.x, data.y ],
				strokeWidth: data.strokeWidth,
				strokeColor: data.color,
				name: data.name,
			});

			return c.layers.drawing.layer.addChild(p);
		});
	};


	this.clearDrawings = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set('drawing');
		c.layers[ 'drawing' ].layer.remove();
		delete (c.layers[ 'drawing' ]);
		penPal.layer.set('drawing');
	};


	this.updateLayerVisibility = function(canvasName, layers) {
		// TODO: Find why toggling layer visibility on buccal is broken without using a delay

		if(!penPal.canvas.exists(canvasName)) {
			return false;
		}

		let timeout = 20;
		//if(canvasName === 'buccal') timeout = 20;
		//let toothVis = app.getToothVisibility();


		setTimeout(() => {
			try {
				let c = app.setActive(canvasName, `teeth_${canvasName}`);
				c.layers[ `teeth_${canvasName}` ].layer.visible = layers.adultTeeth;

				if(canvasName === 'buccal') {
					c.layers[ `teeth_lingual_palatal` ].layer.visible = layers.adultTeeth;
				}


				c = app.setActive(canvasName, `teeth_${canvasName}_deciduous`);
				c.layers[ `teeth_${canvasName}_deciduous` ].layer.visible = layers.deciduousTeeth;

				c = app.setActive(canvasName, 'diagnostics');
				c.layers[ 'diagnostics' ].layer.visible = layers.diagnostics;
				try {
					c.layers[ 'diagnosticLines' ].layer.visible = layers.diagnostics;
				} catch(err) {
				}

				c = app.setActive(canvasName, 'treatments');
				c.layers[ 'treatments' ].layer.visible = layers.treatments;
				try {
					c.layers[ 'treatmentLines' ].layer.visible = layers.treatments;
				} catch(err) {
				}

				c = app.setActive(canvasName, 'text');
				c.layers[ 'text' ].layer.visible = layers.text;

				c = app.setActive(canvasName, 'drawing');
				c.layers[ 'drawing' ].layer.visible = layers.drawings;

				c = app.setActive(canvasName, 'shapes');
				c.layers[ 'shapes' ].layer.visible = layers.shapes;

				if(canvasName === 'occlusal') {
					c = app.setActive(canvasName, 'soft_tissue');
					c.layers[ 'soft_tissue' ].layer.visible = layers.softTissue;

					c = app.setActive(canvasName, 'perio_occlusal');
					c.layers[ 'perio_occlusal' ].layer.visible = (layers.adultTeeth && layers.probingValues);

					c = app.setActive(canvasName, 'bone_view');
					c.layers[ 'bone_view' ].layer.visible = layers.boneView;

					c = app.setActive(canvasName, 'sublingual');
					c.layers[ 'sublingual' ].layer.visible = layers.sublingual;
				}


			} catch(err) {
				console.error(err);
			}
		}, timeout);
	};


	this.addDrawingTool = function(canvasName, callback) {
		let p;
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set('drawing');

		let handlers = {
			'onMouseDown': function(e) {
				penPal.canvas.select(canvasName);
				c.paper.activate();

				penPal.layer.set('drawing');
				let unique = Date.now().toString() + Math.floor(Math.random() * 1000).toString();


				p = new c.paper.Path({
					segments: [ e.point ],
					strokeColor: store.getState().chart.toolbar.color || '#000000',
					strokeWidth: store.getState().chart.toolbar.lineSize || 8,
					fullySelected: false,
					name: `draw_${unique}`,
				});
			},

			'onMouseDrag': function(e) {
				p.add(e.point);
			},

			'onMouseUp': function(e) {
				if(!store.getState().chart.layers.drawings) {
					p.remove();
					// TODO: ADD NOTIFICATION
					console.log('Drawing layer must be enabled');
					return false;
				}

				p.simplify(15);
				if(p.length < 10) {
					p.remove();
				} else {
					let max = getDrawingMaxSize(canvasName);
					let currentSize = JSON.stringify(app.getDrawings(canvasName)).length;

					if(currentSize > max) {
						// TODO: ADD NOTIFICATION
						console.log('Drawings too large');
						p.remove();
						return false;
					}

					if(typeof callback === 'function') {
						callback(canvasName);
					}
				}
			},
		};

		penPal.project.createTool('drawing', handlers);
		canvases[ canvasName ].activeTool = 'drawing';
	};



	this.getDrawings = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set('drawing');
		let children = c.layers[ 'drawing' ].layer.getChildren();

		return children
			.filter(item => Boolean(item.name))
			.map(item => ({
				color: item.strokeColor.toCSS(true),
				x: item.position.x,
				y: item.position.y,
				strokeWidth: item.strokeWidth,
				name: item.name,
				pathData: item.getPathData(),
			}));
	};



	this.saveDrawings = function(canvasName) {
		store.dispatch(actions.setStateWithHistory({
			[ `drawings_${canvasName}` ]: app.getDrawings(canvasName),
		}));
	};



	this.importShapes = function(canvasName) {
		let state = store.getState().chart;
		app.clearLayer(canvasName, 'shapes');

		if(!state.layers.shapes) {
			return false;
		}

		let shapes = state[ `shapes_${canvasName}` ],
			c      = app.setActive(canvasName, 'shapes');

		shapes.forEach(function(data, index) {
			let { type, ...config } = data;
			let info = app.getShapeInfo(type);
			config.fullySelected = false;
			config.applyMatrix = false;
			config = {
				...config,
				data: data,
			};

			return new c.paper.Path[ info.tool ](config);
		});
	};


	this.addShapeTool = function(canvasName, callback) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set('shapes');

		let shape,
			origin = [ 0, 0 ],
			info   = null,
			state  = store.getState().chart;


		let handlers = {
			'onMouseDown': function(e) {
				state = store.getState().chart;

				if(!state.layers.shapes) {
					return false;
				}

				info = app.getShapeInfo();
				penPal.canvas.select(canvasName);
				c.paper.activate();
				penPal.layer.set('shapes');

				shape = new c.paper.Path[ info.tool ]({
					position: [ e.point.x, e.point.y ],
					strokeColor: state.toolbar.color || '#000000',
					strokeWidth: state.toolbar.lineSize || 8, // TODO: ADD THIS
					fullySelected: false,
					size: [ 1, 1 ],
				});

				if(info.fill) {
					shape.fillColor = state.toolbar.color || '#000000';
				}

				origin = [ e.point.x, e.point.y ];
			},

			'onMouseDrag': function(e) {
				if(!state.layers.shapes) {
					return false;
				}
				shape.remove();

				let diffX = e.point.x - origin[ 0 ];
				let diffY = e.point.y - origin[ 1 ];

				shape = new c.paper.Path[ info.tool ]({
					position: [ ...origin ],
					strokeColor: state.toolbar.color || '#000000',
					strokeWidth: state.toolbar.lineSize || 8,
					fullySelected: false,
					size: [ diffX, diffY ],
				});

				if(info.fill) {
					shape.fillColor = state.toolbar.color || '#000000';
				}

			},

			'onMouseUp': function(e) {
				if(!state.layers.shapes) {
					if(shape) {
						shape.remove();
					}
					console.log('Shape layer disabled');
					return false;
				}

				let diffX = e.point.x - origin[ 0 ];
				let diffY = e.point.y - origin[ 1 ];



				if(Math.abs(diffX) < 15 && Math.abs(diffY) < 15) {
					shape.remove();
				} else {
					shape.name = `shape_${Date.now()}`;
					shape.data = {
						name: shape.name,
						type: state.toolbar.selectedTool,
						position: [ ...origin ],
						size: [ diffX, diffY ],
						strokeColor: state.toolbar.color || '#000000',
						strokeWidth: state.toolbar.lineSize || 8,
					};

					if(info.fill) {
						shape.data.fillColor = state.toolbar.color || '#000000';
					}

					if(typeof callback === 'function') {
						callback(canvasName);
					}
				}

				shape = null;
				origin = [ 0, 0 ];
				info = null;
			},
		};

		penPal.project.createTool('shapes', handlers);
		canvases[ canvasName ].activeTool = 'shapes';
	};


	this.getShapeInfo = function(type) {
		let tool = type || store.getState().chart.toolbar.selectedTool;

		switch(tool) {
			case 'shapeRectangleFilled':
			case 'rectangleFill':
				return {
					tool: 'Rectangle',
					fill: true,
				};
			case 'shapeRectangleOutlined':
			case 'rectangleOutline':
				return {
					tool: 'Rectangle',
					fill: false,
				};
			case 'shapeCircleFilled':
			case 'circleFill':
				return {
					tool: 'Ellipse',
					fill: true,
				};
			case 'shapeCircleOutlined':
			case 'circleOutline':
				return {
					tool: 'Ellipse',
					fill: false,
				};
			default:
				return {
					tool: 'Rectangle',
					fill: false,
				};
		}
	};


	this.getShapes = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set('shapes');
		let children = c.layers[ 'shapes' ].layer.getChildren();

		return children.map(shape => ({
			...shape.data,
			rotation: shape.rotation || 0,
			position: [ shape.position.x, shape.position.y ],
		}));
	};



	this.saveShapes = function(canvasName) {
		return store.dispatch(actions.setStateWithHistory({
			[ `shapes_${canvasName}` ]: app.getShapes(canvasName),
		}));
	};



	this.addDeleteTool = function(canvasName,
		validLayers = [ 'drawing', 'shapes', 'diagnostics', 'treatments', 'text' ]) {
		let item       = null,
			lastHit    = null,
			c          = canvases[ canvasName ].canvas,
			project    = c.paper.project,
			hitOptions = {
				segments: true,
				stroke: true,
				fill: true,
				tolerance: 25,
			};

		let handlers = {
			'onMouseMove': function(e) {
				let hitResult = project
					.hitTestAll(e.point, hitOptions)
					.filter(result => validLayers.includes(result.item.layer.name));

				if(hitResult.length) {
					hitResult = hitResult[ 0 ];
				} else {
					hitResult = null;
				}

				if(hitResult) {
					hitResult.item.shadowColor = '#ff0000';
					hitResult.item.shadowBlur = 8;
					hitResult.item.shadowOffset = [ 2, 2 ];
				}

				if(lastHit && hitResult && hitResult.item !== lastHit) {
					lastHit.shadowColor = null;
					lastHit.shadowBlur = null;
					lastHit.shadowOffset = null;
				}

				if(lastHit && !hitResult) {
					lastHit.shadowColor = null;
					lastHit.shadowBlur = null;
					lastHit.shadowOffset = null;
				}

				lastHit = hitResult
					? hitResult.item
					: null;
			},



			'onMouseUp': function(e) {
				if(!lastHit) {
					let hitResult = project
						.hitTestAll(e.point, hitOptions)
						.filter(result => validLayers.includes(result.item.layer.name));

					if(hitResult.length) {
						lastHit = hitResult[ 0 ].item;
					} else {
						return false;
					}
				}

				// Assign the group if part of a group so we can move the whole thing. Layer checks out true as group,
				// so we have to check that it's not a layer.
				if(lastHit.parent instanceof c.paper.Group && !(lastHit.parent instanceof c.paper.Layer)) {
					lastHit = lastHit.parent;
				}

				try {
					app.setActive(canvasName, lastHit.layer.name);

					let layerName = lastHit.layer.name,
						saveFunc  = layerSaveFunctions[ layerName ];



					const removeResult = lastHit.remove();
					console.log({ lastHit, removeResult });
					lastHit = null;

					if(saveFunc && app[ saveFunc ]) {
						app[ saveFunc ](canvasName);
					} else {
						console.log('No save function for layer', layerName);
						console.log(layerSaveFunctions, layerSaveFunctions[ layerName ]);
					}
				} catch(err) {
					lastHit = null;
					return false;
				}
			},
		};

		penPal.project.createTool(`delete`, handlers);
		canvases[ canvasName ].activeTool = `delete`;
	};



	this.addMoveTool = function(
		canvasName,
		validLayers = [ 'drawing', 'shapes', 'diagnostics', 'treatments', 'text' ],
	) {
		let offset     = [ 0, 0 ],
			app        = this,
			moveActive = false,
			lastHit    = null,
			c          = canvases[ canvasName ].canvas,
			project    = c.paper.project,
			hitOptions = {
				segments: true,
				stroke: true,
				fill: true,
				tolerance: 25,
			};

		let handlers = {
			'onMouseMove': function(e) {
				if(moveActive) {
					if(lastHit.layer.name === 'diagnostics') {
						try {
							/* drawLineFromOrigin(app, lastHit, row, 'diagnosticLines', {
								 ...lineOpts,
								 strokeColor: color
							 });*/
						} catch(err) {
							console.error(err);
						}
					}
					return true;
				}

				let hitResult = project
					.hitTestAll(e.point, hitOptions)
					.filter(result => validLayers.includes(result.item.layer.name));

				if(hitResult.length) {
					hitResult = hitResult[ 0 ];
				} else {
					hitResult = null;
				}


				if(hitResult) {
					hitResult.item.shadowColor = '#ff0000';
					hitResult.item.shadowBlur = 8;
					hitResult.item.shadowOffset = [ 2, 2 ];
				}

				if(lastHit && hitResult && hitResult.item !== lastHit) {
					lastHit.shadowColor = null;
					lastHit.shadowBlur = null;
					lastHit.shadowOffset = null;
				}

				if(lastHit && !hitResult) {
					lastHit.shadowColor = null;
					lastHit.shadowBlur = null;
					lastHit.shadowOffset = null;
				}

				lastHit = hitResult
					? hitResult.item
					: null;

			},


			'onMouseDown': function(e) {
				if(!lastHit) {
					let hitResult = project
						.hitTestAll(e.point, hitOptions)
						.filter(result => validLayers.includes(result.item.layer.name));

					if(hitResult.length) {
						lastHit = hitResult[ 0 ].item;
					} else {
						return false;
					}
				}

				moveActive = true;

				// Assign the group if part of a group so we can move the whole thing. Layer checks out true as group,
				// so we have to check that it's not a layer.
				if(lastHit.parent instanceof c.paper.Group && !(lastHit.parent instanceof c.paper.Layer)) {
					lastHit = lastHit.parent;
				}


				try {
					app.setActive(canvasName, lastHit.layer.name);
				} catch(err) {
					return false;
				}

				if(lastHit.name && validLayers.includes(lastHit.layer.name)) {
					offset = [ e.point.x - lastHit.position.x, e.point.y - lastHit.position.y ];
					return true;
				}
				return false;
			},


			'onMouseDrag': function(e) {
				if(!lastHit) {
					return false;
				}

				lastHit.position = [ e.point.x - offset[ 0 ], e.point.y - offset[ 1 ] ];
			},


			'onMouseUp': function(e) {
				if(!lastHit) {
					return false;
				}


				lastHit.shadowColor = null;
				lastHit.shadowBlur = null;
				lastHit.shadowOffset = null;

				let newPos = [ e.point.x - offset[ 0 ], e.point.y - offset[ 1 ] ];
				lastHit.position = newPos;

				if(lastHit.data && lastHit.data.position) {
					lastHit.data.position = newPos;
				}

				try {
					console.log(lastHit);
					let layerName = lastHit.layer.name,
						saveFunc  = layerSaveFunctions[ layerName ];

					lastHit = null;

					if(saveFunc && app[ saveFunc ]) {
						console.log('we savin');
						app[ saveFunc ](canvasName);
					} else {
						console.log('No save function for layer', layerName);
						console.log(layerSaveFunctions, layerSaveFunctions[ layerName ]);
					}
				} catch(err) {
					lastHit = null;
					console.error('Unable to run save function!');
					console.log(err);
				}

				offset = [ 0, 0 ];
				moveActive = false;
			},
		};

		penPal.project.createTool(`move`, handlers);
		canvases[ canvasName ].activeTool = `move`;
	};



	this.addRotateTool = function(canvasName, validLayers = [ 'drawing', 'shapes' ]) {
		let moveActive = true,
			lastHit    = null,
			hitCenter  = null,
			c          = canvases[ canvasName ].canvas,
			project    = c.paper.project,
			hitOptions = {
				segments: true,
				stroke: true,
				fill: true,
				tolerance: 25,
			};

		let handlers = {
			'onMouseMove': function(e) {
				if(!moveActive) {
					return true;
				}

				let hitResult = project
					.hitTestAll(e.point, hitOptions)
					.filter(result => validLayers.includes(result.item.layer.name));

				if(hitResult.length) {
					hitResult = hitResult[ 0 ];
				} else {
					hitResult = null;
				}


				if(hitResult) {
					hitResult.item.shadowColor = '#ff0000';
					hitResult.item.shadowBlur = 8;
					hitResult.item.shadowOffset = [ 2, 2 ];
				}

				if(lastHit && hitResult && hitResult.item !== lastHit) {
					lastHit.shadowColor = null;
					lastHit.shadowBlur = null;
					lastHit.shadowOffset = null;
				}

				if(lastHit && !hitResult) {
					lastHit.shadowColor = null;
					lastHit.shadowBlur = null;
					lastHit.shadowOffset = null;
				}

				lastHit = hitResult
					? hitResult.item
					: null;
			},


			'onMouseDown': function(e) {
				if(!lastHit) {
					let hitResult = project
						.hitTestAll(e.point, hitOptions)
						.filter(result => validLayers.includes(result.item.layer.name));

					if(hitResult.length) {
						lastHit = hitResult[ 0 ].item;
					} else {
						return false;
					}
				}

				try {
					app.setActive(canvasName, lastHit.layer.name);
				} catch(err) {
					console.log(err);
					return false;
				}

				if(lastHit.name && validLayers.includes(lastHit.layer.name)) {
					console.log(lastHit);
					hitCenter = new c.paper.Point(lastHit.position.x, lastHit.position.y);
					return true;
				}
				return false;
			},


			'onMouseDrag': function(e) {
				if(!lastHit || !hitCenter) {
					return false;
				}

				const center  = lastHit.bounds.center,
					  baseVec = new c.paper.Point(
						  center.x - e.lastPoint.x,
						  center.y - e.lastPoint.y,
					  ),
					  nowVec  = new c.paper.Point(
						  center.x - e.point.x,
						  center.y - e.point.y,
					  ),
					  angle   = nowVec.angle - baseVec.angle;

				lastHit.rotate(angle);
			},


			'onMouseUp': function(e) {
				if(!lastHit) {
					return false;
				}

				lastHit.shadowColor = null;
				lastHit.shadowBlur = null;
				lastHit.shadowOffset = null;

				try {
					let layerName = lastHit.layer.name,
						saveFunc  = layerSaveFunctions[ layerName ];

					lastHit = null;

					if(saveFunc && app[ saveFunc ]) {
						app[ saveFunc ](canvasName);
					} else {
						console.log('No save function for layer', layerName);
						console.log(layerSaveFunctions, layerSaveFunctions[ layerName ]);
					}
				} catch(err) {
					lastHit = null;
					console.error('Unable to run save function!');
				}

				lastHit = null;
				hitCenter = null;
			},
		};

		penPal.project.createTool('rotate', handlers);
		canvases[ canvasName ].activeTool = 'rotate';
	};



	this.addSymbolTool = function(canvasName) {
		let c          = canvases[ canvasName ].canvas,
			project    = c.paper.project,
			lastHit    = null,
			fullHit    = null,
			tempLine   = null,
			hitOptions = {
				segments: true,
				stroke: true,
				fill: true,
				tolerance: 8,
			};


		let handlers = {
			'onMouseMove': function(e) {
				try {
					penPal.canvas.select(canvasName);

					let hitResult = project
						.hitTestAll(e.point, hitOptions)
						.filter(result =>
							Boolean(result?.item?.name) &&
							toothLayers.includes(result.item.layer.name) &&
							result.item.layer.visible === true &&
							(result?.item?.opacity || 0) !== 0,
						);

					if(hitResult.length) {
						hitResult = hitResult[ 0 ];
					} else {
						hitResult = null;
					}


					if(hitResult) {
						hitResult.item.shadowColor = '#8000ff';
						hitResult.item.shadowBlur = 16;
						hitResult.item.shadowOffset = [ 0, 0 ];
					}

					if(lastHit && hitResult && hitResult.item !== lastHit) {
						lastHit.shadowColor = null;
						lastHit.shadowBlur = null;
						lastHit.shadowOffset = null;
					}

					if(lastHit && !hitResult) {
						lastHit.shadowColor = null;
						lastHit.shadowBlur = null;
						lastHit.shadowOffset = null;
					}

					fullHit = hitResult;
					lastHit = hitResult
						? hitResult.item
						: null;
				} catch(err) {
					console.error(err);
				}
			},


			'onMouseDown': function(e) {
				try {
					if(!lastHit) {
						let hitResult = project
							.hitTestAll(e.point, hitOptions)
							.filter(result =>
								Boolean(result?.item?.name) &&
								toothLayers.includes(result.item.layer.name) &&
								result.item.layer.visible === true &&
								(result?.item?.opacity || 0) !== 0,
							);

						if(hitResult.length) {
							hitResult = hitResult[ 0 ];
						} else {
							hitResult = null;
						}

						lastHit = hitResult
							? hitResult.item
							: null;
					}

					penPal.canvas.select(canvasName);
					c.paper.activate();

					//console.log('LAST HIT', lastHit);
					//console.log(lastHit.layer.name);

					store.dispatch(actions.setState({
						symbolOptions: {
							...store.getState().chart.symbolOptions,
							canvasDown: [ e.point.x, e.point.y ],
							sourceType: lastHit
								? 'tooth'
								: 'mouth',
							tooth: lastHit
								? (lastHit.name || lastHit.parent.name)
								: null,
						},
					}));

					penPal.layer.set('lines_temp');
					tempLine = new c.paper.Path.Line(e.point, e.point);
					tempLine.strokeColor = '#6b6b6b';
					tempLine.strokeWidth = 2;
				} catch(err) {
					console.error(err);
				}
			},


			onMouseDrag: function(e) {
				try {
					if(tempLine) {
						tempLine.segments[ 1 ].point = e.point;
					}
				} catch(err) {
					console.error(err);
				}
			},



			'onMouseUp': function(e) {
				try {
					penPal.canvas.select(canvasName);
					c.paper.activate();
					penPal.layer.set('symbols');
					//console.log(e)
					let singleMode = store.getState().chart.toolbar.selectedTool === 'symbolSingle';

					store.dispatch(actions.setState({
						symbolOptions: {
							...store.getState().chart.symbolOptions,
							canvas: canvasName,
							layer: lastHit
								? lastHit.layer.name
								: null,
							canvasUp: [ e.point.x, e.point.y ],
							screenPoint: [
								e.event.type === 'touchend'
									? e.event.changedTouches[ 0 ].clientX
									: e.event.x,

								e.event.type === 'touchend'
									? e.event.changedTouches[ 0 ].clientY
									: e.event.y,
							],
						},
					}));

					if(singleMode) {
						store.dispatch(actions.handleSymbolDispatch(
							store.getState().chart.symbolOptions.symbol,
							canvasName,
						));
					}

					if(tempLine) {
						try {
							tempLine.remove();
							tempLine = null;
						} catch(err) {
							console.error(err);
						}
					}
				} catch(err) {
					console.error(err);
				}

				lastHit = null;
			},


		};


		penPal.project.createTool('symbols', handlers);
		canvases[ canvasName ].activeTool = 'symbols';
	};


	this.calculateOutOfBoundsSymbolPosition = function({ canvasName, paperItem }) {
		const c        = canvases[ canvasName ].canvas,
			  viewSize = {
				  width: c.paper.project.view.size.width,
				  height: c.paper.project.view.size.height,
			  };

		let x = paperItem.point.x,
			y = paperItem.point.y;

		// The multipliers are because paper.js doesn't quite get it right with wide/tall letters
		const itemSize = {
			width: paperItem.bounds.size.width * 1.1,
			height: paperItem.bounds.size.height * 1.2,
		};

		// Do not let codes be placed out of bounds, x-axis
		if(x - (itemSize.width / 2) < 0) {
			x = itemSize.width / 2;
		} else if(x + (itemSize.width / 2) > viewSize.width) {
			x = viewSize.width - (itemSize.width / 2);
		}

		// Do not let codes be placed out of bounds, y-axis
		if(y - (itemSize.height / 2) < 0) {
			y = itemSize.height / 2;
		} else if(y + (itemSize.height / 2) > viewSize.height) {
			y = viewSize.height - (itemSize.height / 2);
		}

		return { x, y };
	};


	this.saveSymbols = function(canvasName) {
		const c     = canvases[ canvasName ].canvas,
			  state = store.getState().chart?.[ `chart_symbols_${canvasName}` ],
			  app   = this;

		const diagnostics = c.layers[ 'diagnostics' ]?.layer
			?.getChildren()
			?.reduce((accumulator, current) => {
				accumulator[ current.name ] = current;
				return accumulator;
			}, {});

		const treatments = c.layers[ 'treatments' ]?.layer
			?.getChildren()
			?.reduce((accumulator, current) => {
				accumulator[ current.name ] = current;
				return accumulator;
			}, {});

		const symbols = { ...diagnostics, ...treatments };

		const newState = state
			.filter(row => symbols[ row.name ] !== undefined)
			.map(row => {
				let newData = symbols[ row.name ];

				if(!newData) {
					return row;
				}

				const { x, y } = app.calculateOutOfBoundsSymbolPosition({
					canvasName,
					paperItem: newData,
				});

				if(row.position.x === x && row.position.y === y) {
					return row;
				}

				return {
					...row,
					position: [ x, y ],
				};
			});

		return store.dispatch(actions.setStateWithHistory({
			[ `chart_symbols_${canvasName}` ]: newState,
		}));
	};



	this.getText = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set('text');
		let children = c.layers[ 'text' ].layer.getChildren();

		return children.map(text => ({
			...text.data,
			position: [ text.position.x, text.position.y ],
		}));
	};



	this.addTextTool = function(canvasName) {
		let handlers = {
			'onMouseUp': function(e) {
				let color  = store.getState().chart.toolbar.color || '#000000',
					unique = Date.now().toString() + Math.floor(Math.random() * 1000).toString();

				let textConfig = {
					name: `text_${unique}`,
					content: '',
					color: color,
					position: [ e.point.x, e.point.y ],
				};

				/*function save(res) {
					if(!res.value.trim()) {
						return false;
					}

					textConfig.content = res.value;
					let textState = store.getState().ActiveChart.Text;
					let newState = textState[ canvasName ];
					if(!_.isArray(newState)) newState = [];
					newState = [ ...newState, { ...textConfig } ];

					store.dispatch(actions.saveText(
						canvasName,
						newState,
					));
				}*/
				console.log(e);

				store.dispatch(actions.setState({
					textOptions: {
						canvasPosition: [ e.point.x, e.point.y ],
						screenPosition: [
							e.event.type === 'touchend'
								? e.event.changedTouches[ 0 ].clientX
								: e.event.x,

							e.event.type === 'touchend'
								? e.event.changedTouches[ 0 ].clientY
								: e.event.y,
						],
						canvas: canvasName,
					},
				}));
			},
		};

		penPal.project.createTool('text', handlers);
		canvases[ canvasName ].activeTool = 'text';
	};


	this.importText = function(canvasName) {
		let state = store.getState().chart;
		app.clearLayer(canvasName, 'text');

		if(!state.layers.text) {
			return false;
		}

		let text = state[ `text_${canvasName}` ],
			c    = app.setActive(canvasName, 'text');

		console.log('text', text);
		text.forEach(function(data, index) {
			let { type, ...config } = data;

			let circle    = new c.paper.Shape.Circle({
					center: config.position,
					radius: 30,
					fillColor: config.color || store.getState().chart.toolbar.color || '#000000',
				}),

				pointText = new c.paper.PointText({
					...config,
					justification: 'center',
					position: [ config.position[ 0 ] + 3, config.position[ 1 ] + 5 ],
					fillColor: '#ffffff',
					content: index + 1,
					fontSize: 30,
					fullySelected: false,

				});

			return new c.paper.Group({
				children: [ circle, pointText ],
				applyMatrix: false,
				data: { ...config },
			});

		});
	};


	this.saveText = function(canvasName) {
		return store.dispatch(actions.setStateWithHistory({
			[ `text_${canvasName}` ]: app.getText(canvasName),
		}));
	};



}
