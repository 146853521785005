import React from 'react';
import { useSelector } from "react-redux";



const CustomInput = () => {
	const inputs = useSelector(state => state.customCodes.inputs.options);

	return <div>{inputs.label}</div>;

};


export default CustomInput;