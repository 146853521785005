import React, { useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Description } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import PatientReportTableRow from "./PatientReportTableRow";
import { actions } from '../PatientView.redux';



const columns = [
	{ name: 'date_updated', title: 'Update Date' },
	{ name: 'date_created', title: 'Create Date' },
	{ name: 'urpt_name', title: 'Report Name' },
	{ name: 'template_name', title: 'Template' },
	{ name: 'created_by', title: 'Created By' },
	{ name: 'updated_by', title: 'Updated by' },
];


const PatientReports = () => {
	const dispatch      = useDispatch(),
		  { patient_id } = useParams(),
		  hasLoaded     = useSelector(state => state.patientView.reportsPreviouslyLoaded),
		  loading       = useSelector(state => state.patientView.reportsLoading),
		  reports       = useSelector(state => state.patientView.reports),
		  loadReports   = (patientID) => dispatch(actions.loadReports(patientID));


	useEffect(() => {
		if(!hasLoaded && !loading) {
			loadReports(patient_id);
		}

	}, [ loadReports, patient_id, hasLoaded, loading ]);


	return (
		<DashboardCard
			color={'amber'}
			icon={<Description />}
			topContent={"Reports"}
			topLabel="Click a report to view or edit"
			size="xl"
		>
			<Grid
				rows={reports}
				columns={columns}
			>
				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table rowComponent={PatientReportTableRow} />
				<TableHeaderRow />
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>
		</DashboardCard>
	);
};


export default PatientReports;