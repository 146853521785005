import reduxStore, {actions} from 'store';
import addText from './helpers/addText';
import renderLine from './helpers/drawLineFromOrigin';

let state = reduxStore.getState();
let types = [];

reduxStore.subscribe(() => {
	state = reduxStore.getState();
	//types = state.App.tx;
});

let sel;
let options;
let lineOpts = {
	strokeColor: 'red',
	strokeWidth: 3,
	toothLayer: null,
};


function renderText(helper, opt) {
	let c = helper.setActive(opt.canvas, 'treatments');
	let {item, ...saveData} = opt;

	let vis = helper.getToothVisibility();
	if(saveData.tooth && saveData.tooth.substr(0, 1) === 't' && !vis.adult) return false;
	if(saveData.tooth && saveData.tooth.substr(0, 1) === 'd' && !vis.deciduous) return false;

	let color = (saveData.tooth && saveData.tooth.substr(0, 1) === 't') ? 'red' : '#ef007f';

	let textConfig = {
		point: opt.position,
		justification: 'center',
		content: types[opt.id].abbr,
		name: opt.name,
		fullySelected: false,
		fontSize: 60,
		fontWeight: 'bold',
		fillColor: color,
		data: {...saveData},
	};

	if(saveData.actualLayer) {
		lineOpts.toothLayer = saveData.actualLayer;
	}

	let text = addText(c, textConfig);

	renderLine(helper, text, saveData, 'treatmentLines', {...lineOpts, strokeColor: color});
	helper.setActive(opt.canvas, 'treatments');

	// TODO: Reduce symbol opacity to 30% by default, 15% in fade mode
	//text.opacity = 0.15;

	return text;
}



function renderSymbol(helper, opt) {
	let c = helper.setActive(opt.canvas, 'treatments');
	let {item, ...saveData} = opt;

	let vis = helper.getToothVisibility();
	if(saveData.tooth && saveData.tooth.substr(0, 1) === 't' && !vis.adult) return false;
	if(saveData.tooth && saveData.tooth.substr(0, 1) === 'd' && !vis.deciduous) return false;
	let color = (saveData.tooth && saveData.tooth.substr(0, 1) === 't') ? 'red' : '#ef007f';

	let symbolConfig = {
		position: opt.position,
		fillColor: color,
		scaling: 3,
		name: opt.name,
		data: {...saveData},
	};

	let path = new c.paper.Path(types[opt.id].data);
	path = {...path, ...symbolConfig};

	if(saveData.actualLayer) {
		lineOpts.toothLayer = saveData.actualLayer;
	}

	renderLine(helper, path, opt, 'treatmentLines', {...lineOpts, strokeColor: color});

	return path;
}



function render(helper, opt) {
	if(types[opt.id].type === 'text') {
		return renderText(helper, opt);
	}

	return renderSymbol(helper, opt);
}


export default {
	onMouseDown: function(e, util) {
		let toothVis = util.helper.getToothVisibility();
		if(!toothVis.active && types[sel] && types[sel].mapTo === 'tooth') {
			reduxStore.dispatch(actions.setSnackbar(
				'Use the Layers menu to select only adult or deciduous teeth for this tool.',
				3000,
				true,
			));

			return false;
		}

		util.custom = null;
		state = util.store.getState();
		sel = state.ChartingOptions.ActiveTreatment;

		if(!sel) return false;
		let layer = toothVis.active === 'adult' ? `teeth_${util.canvasName}` : `teeth_${util.canvasName}_deciduous`;
		let validLayers = util.canvasName === 'buccal'
			? [layer, 'teeth_lingualPalatal']
			: [layer];

		util.penPal.layer.set('treatments');
		if(types[sel].mapTo === 'tooth' && (!e.item || !e.item.name || validLayers.indexOf(e.item.layer.name) === -1)) {
			util.store.dispatch(actions.setSnackbar(`${types[sel].name} must be placed on a tooth.`, 2000, true));
			return false;
		}

		let name = `${sel}_${Date.now()}`;
		options = {
			stObj: true,
			canvas: util.canvasName,
			name: name,
			origin: [e.point.x, e.point.y],
			position: [e.point.x, e.point.y],
			id: sel,
			tooth: (types[sel].mapTo === 'tooth' && e.item && e.item.name) ? e.item.name : null,
			actualLayer: types[sel].mapTo === 'tooth' ? e.item.layer.name : null,
		};

		lineOpts.toothLayer = types[sel].mapTo === 'tooth' ? e.item.layer.name : null;

		return util.custom = {obj: render(util.helper, options), item: e.item};
	},
	onMouseDrag: function(e, util) {
		if(!util.custom) {
			return false;
		}

		util.custom.obj.point = [e.point.x, e.point.y];
		options.position  = [e.point.x, e.point.y];
		renderLine(util.helper, util.custom.obj, options, 'treatmentLines', lineOpts);
	},
	onMouseUp: function(e, util) {
		if(!util.custom) {
			return false;
		}

		const {item, ...opt} = options;

		util.store.dispatch(actions.saveTreatments(opt));
		lineOpts.toothLayer = null;
	},
	render: render
};