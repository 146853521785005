import React, { useEffect, useState } from 'react';
import useStyles from './CanvasBuccal.styles';
import BuccalHandler from 'svgHandlers/Buccal';
import SVGHelper from "svgHandlers/helper";
import PenpalContextBuccal from '../PenpalContextBuccal';



const CanvasBuccal = ({ penPal }) => {
    const canvasRef  = React.createRef(),
          classes    = useStyles(),
          [ helper ] = useState(new SVGHelper(penPal)),
          [ svg ]    = useState(new BuccalHandler(penPal, helper));


    useEffect(() => {
        console.log('exists', penPal.canvas.exists('buccal'));
        if(svg) {
            console.log('running canvas init');
            svg.init(canvasRef.current);
        }

        return () => {
            if(svg) {
                console.log('destroying canvas');
                svg.destroy();
            }
        };
    }, []);


    if(penPal && penPal.canvas.exists('buccal')) {
        penPal.sizeCanvas('buccal', 0);
    }


    return (
        <PenpalContextBuccal.Provider value={penPal}>
            <div ref={canvasRef} className={classes.buccalContainer} />
        </PenpalContextBuccal.Provider>
    );

};


export default CanvasBuccal;
