import React from 'react';
import { Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import DashboardCard from "components/DashboardCard";
import DataObject from "@mui/icons-material/DataObject";
import useStyles from '../CustomCodes.styles';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../CustomCodes.redux';
import SelectCategory from './SelectCategory';
import SelectType from './SelectType';
import Configurator from './Configurator';



const CustomCodeForm = () => {
	const classes  = useStyles(),
		  dispatch = useDispatch(),
		  step     = useSelector(state => state.customCodes.step),
		  category = useSelector(state => state.customCodes.category),
		  type     = useSelector(state => state.customCodes.type);

	const handleReset = () => dispatch(actions.resetAll());

	return (
		<DashboardCard
			color={'orange'}
			icon={<DataObject />}
			topContent={"Custom Codes"}
			topLabel={
				<>
					<div>Continue editing your Custom Code or</div>
					<div>
						<Button
							variant="contained"
							size="small"
							color="primary"
							className={classes.buttons}
							onClick={handleReset}
						>
							Return to list
						</Button>
					</div>
				</>
			}
			size={'xl'}
		>
			<Stepper activeStep={step} orientation="vertical">

				<Step>
					<StepLabel>
						{
							step === 0
								? 'Choose Category'
								: `Category: ${getCategoryName(category)}`
						}
					</StepLabel>
					<StepContent>
						<SelectCategory />
					</StepContent>
				</Step>


				<Step>
					<StepLabel>
						{
							step === 1
								? 'Choose Code Type'
								: `Code Type: ${getTypeName(type)}`
						}
					</StepLabel>
					<StepContent>
						<SelectType />
					</StepContent>
				</Step>


				<Step>
					<StepLabel>Configure Custom Code</StepLabel>
					<StepContent>
						<Configurator />
					</StepContent>
				</Step>

			</Stepper>
		</DashboardCard>
	);


};


function getCategoryName(category) {
	switch(category) {
		case 'dx':
			return 'Diagnostic';
		case 'tx':
			return 'Treatment';
		case 'other':
			return 'Other';
		default:
			return 'Unknown';
	}
}


function getTypeName(type) {
	switch(type) {
		case 'multiselect':
			return 'Multi-select';
		case 'simple':
			return 'Simple Code';
		case 'input':
			return 'Custom Input';
		default:
			return 'Unknown';
	}
}


export default CustomCodeForm;
