import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { actions } from './FileManager.redux';



const FileBrowserSearch = () => {
	const dispatch     = useDispatch(),
		  loading      = useSelector(state => state.files.loading),
		  client_name  = useSelector(state => state.files.browseOptions.client_name),
		  patient_name = useSelector(state => state.files.browseOptions.patient_name),
		  report_name  = useSelector(state => state.files.browseOptions.report_name),
		  visit_id     = useSelector(state => state.files.browseOptions.visit_id),
		  year         = useSelector(state => state.files.browseOptions.year),
		  month        = useSelector(state => state.files.browseOptions.month),
		  file_name    = useSelector(state => state.files.browseOptions.file_name);


	const handleFieldChange = property => e => {
		dispatch(actions.setSearchValue(property, e.target.value));
	};


	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} lg={4} xl={3}>
					<TextField
						fullWidth
						label="Client Name"
						variant="filled"
						value={client_name}
						onChange={handleFieldChange('client_name')}
					/>
				</Grid>

				<Grid item xs={12} sm={6} lg={4} xl={3}>
					<TextField
						fullWidth
						label="Patient Name"
						variant="filled"
						value={patient_name}
						onChange={handleFieldChange('patient_name')}
					/>
				</Grid>

				<Grid item xs={12} sm={6} lg={4} xl={3}>
					<TextField
						fullWidth
						label="Report Name"
						variant="filled"
						value={report_name}
						onChange={handleFieldChange('report_name')}
					/>
				</Grid>

				<Grid item xs={12} sm={6} lg={4} xl={3}>
					<TextField
						fullWidth
						label="File Name"
						variant="filled"
						value={file_name}
						onChange={handleFieldChange('file_name')}
					/>
				</Grid>

				<Grid item xs={12} sm={6} lg={4} xl={3}>
					<TextField
						fullWidth
						label="Year"
						variant="filled"
						value={year}
						onChange={handleFieldChange('year')}
						type="number"
					/>
				</Grid>

				<Grid item xs={12} sm={6} lg={4} xl={3}>
					<FormControl style={{ width: '100%' }}>
						<InputLabel>Month</InputLabel>
						<Select
							fullWidth
							variant="filled"
							value={month}
							onChange={handleFieldChange('month')}
						>
							<MenuItem value="1">January</MenuItem>
							<MenuItem value="2">February</MenuItem>
							<MenuItem value="3">March</MenuItem>
							<MenuItem value="4">April</MenuItem>
							<MenuItem value="5">May</MenuItem>
							<MenuItem value="6">June</MenuItem>
							<MenuItem value="7">July</MenuItem>
							<MenuItem value="8">August</MenuItem>
							<MenuItem value="9">September</MenuItem>
							<MenuItem value="10">October</MenuItem>
							<MenuItem value="11">November</MenuItem>
							<MenuItem value="12">December</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6} lg={4} xl={3}>
					<TextField
						fullWidth
						label="Visit ID"
						variant="filled"
						value={visit_id}
						onChange={handleFieldChange('visit_id')}
					/>
				</Grid>

				<Grid item xs={12} sm={6} lg={4} xl={3}>
					<Button
						size="large"
						variant="contained"
						color="primary"
						disabled={loading}
						onClick={() => dispatch(actions.searchFiles())}
					>
						Search
					</Button>
				</Grid>
			</Grid>


		</div>

	);
};


export default FileBrowserSearch;
