import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStrokeDeciduous,
	strokeWidth: 1,
	fillColor: colors.defaultFillDeciduous,
	opacity: 1.0,
	scaling: 1.15,
	rotation: 0
};
const defaultTop  = 280; // 100 to line up with original
const defaultBot  = 1290; // 611 to line up with original
const canvasWidth = 3000;

function pos(newPos) {
	return canvasWidth / 1000 * newPos;
}

export default [
	// UPPER 100s
	{
		"name": "d508",
		"position": [pos(207), defaultTop-15],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.3},
	},
	{
		"name": "d507",
		"position": [pos(258), defaultTop-20],
		"options": {...defaultOptions, scaling: [defaultOptions.scaling*1.5, defaultOptions.scaling*1.3]},
	},
	{
		"name": "d506",
		"position": [pos(305), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "d504",
		"position": [pos(332), defaultTop-14],
		"options": {...defaultOptions, scaling: [defaultOptions.scaling*1.15, defaultOptions.scaling*1.6]}
	},
	{
		"name": "d503",
		"position": [pos(427), defaultTop-21],
		"options": defaultOptions
	},
	{
		"name": "d502",
		"position": [pos(455), defaultTop-22],
		"options": defaultOptions
	},
	{
		"name": "d501",
		"position": [pos(476), defaultTop-20],
		"options": defaultOptions
	},

	// UPPER 200s
	{
		"name": "d601",
		"position": [pos(498), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "d602",
		"position": [pos(519), defaultTop-21],
		"options": defaultOptions
	},
	{
		"name": "d603",
		"position": [pos(547), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "d604",
		"position": [pos(640), defaultTop-14],
		"options": {...defaultOptions, scaling: [defaultOptions.scaling*1.15, defaultOptions.scaling*1.6]}
	},
	{
		"name": "d606",
		"position": [pos(670), defaultTop-20],
		"options": defaultOptions
	},
	{
		"name": "d607",
		"position": [pos(717), defaultTop-20],
		"options": {...defaultOptions, scaling: [defaultOptions.scaling*1.5, defaultOptions.scaling*1.3]}
	},
	{
		"name": "d608",
		"position": [pos(768), defaultTop-16],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.3}
	},

	// LOWER 400s
	{
		"name": "d808",
		"position": [pos(245), defaultBot+27],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.1}
	},
	{
		"name": "d807",
		"position": [pos(294), defaultBot+24],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.1}
	},
	{
		"name": "d804",
		"position": [pos(370), defaultBot+35],
		"options": {...defaultOptions, scaling: [defaultOptions.scaling*1.0, defaultOptions.scaling*1.5]}
	},
	{
		"name": "d803",
		"position": [pos(420), defaultBot+24],
		"options": defaultOptions
	},
	{
		"name": "d802",
		"position": [pos(448), defaultBot+23],
		"options": defaultOptions
	},
	{
		"name": "d801",
		"position": [pos(475.5), defaultBot+23],
		"options": defaultOptions
	},

	// LOWER 300s
	{
		"name": "d701",
		"position": [pos(498), defaultBot+23],
		"options": defaultOptions
	},
	{
		"name": "d702",
		"position": [pos(525), defaultBot+23],
		"options": defaultOptions
	},
	{
		"name": "d703",
		"position": [pos(552), defaultBot+23],
		"options": defaultOptions
	},
	{
		"name": "d704",
		"position": [pos(602), defaultBot+37],
		"options": {...defaultOptions, scaling: [defaultOptions.scaling*1.0, defaultOptions.scaling*1.5]}
	},
	{
		"name": "d707",
		"position": [pos(680), defaultBot+23],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.1}
	},
	{
		"name": "d708",
		"position": [pos(728), defaultBot+28],
		"options": {...defaultOptions, scaling: defaultOptions.scaling*1.1}
	},
];