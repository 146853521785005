import React, { useCallback } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Edit } from '@mui/icons-material';
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { actions } from '../ClientView.redux';



const ClientAddressesEditButton = (props) => {
	const { id, ...rest } = props,
		  dispatch        = useDispatch();

	const openModal = useCallback(() => {
		dispatch(actions.openAddressEditModal(id));
	}, [ dispatch, id ]);

	return (
		<Table.Cell {...rest}>
			<IconButton size="small" onClick={openModal}>
				<Edit />
			</IconButton>
		</Table.Cell>
	);

};


export default ClientAddressesEditButton;