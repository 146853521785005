import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import ClientContactMethodsEditButton from './ClientContactMethodsEditButton';



const ClientContactMethodsCell = (props) => {
	const id = props.tableRow.rowId;

	switch(props.column.name) {
		case 'cm_edit':
			return <ClientContactMethodsEditButton id={id} />;
		default:
			return <Table.Cell {...props} />;
	}
};


export default ClientContactMethodsCell;