import React, { useState } from 'react';
import { useSelector } from "react-redux";
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ThemeProvider,
    StyledEngineProvider,
    Typography,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import useStyles from './FileGrid.styles';
import theme from 'styles/theme_dark';
import bytesToHumanReadable from 'lib/bytesToHumanReadable';



const FileGrid = ({ selectActions = [] }) => {
	const classes                         = useStyles(),
		  files                           = useSelector(state => state.files.files),
		  [ menuOptions, setMenuOptions ] = useState({
			  anchor: null,
			  file_id: null,
		  });

	const handleMenuClose = () => {
		setMenuOptions({
			anchor: null,
			file_id: null,
		});
	};

	const handleMenuOpen = fileID => (e) => {
		setMenuOptions({
			anchor: e.currentTarget,
			file_id: fileID,
		});
	};

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Grid container spacing={4}>
                    {files.map(file => (
                        <Grid item xs={3} key={file.file_id}>

                            <Card className={classes.root}>
                                <CardHeader
                                    action={
                                        <IconButton onClick={handleMenuOpen(file.file_id)} size="large">
                                            <MoreVert />
                                        </IconButton>
                                    }
                                    subheader={file.date_created}
                                />
                                <CardMedia
                                    className={classes.media}
                                    image={file.thumbnail_url}
                                    title={file.file_name}
                                    onClick={handleMenuOpen(file.file_id)}
                                />
                                <CardContent>
                                    <Typography
                                        noWrap
                                        variant="body2"
                                        color="textPrimary"
                                        style={{ whiteSpace: 'pre-wrap' }}
                                    >
                                        {file.file_name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {bytesToHumanReadable(file.file_size)}
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Menu
                                anchorEl={menuOptions.anchor}
                                open={menuOptions.file_id === file.file_id}
                                onClose={handleMenuClose}
                            >
                                {selectActions.map(action => (
                                    <MenuItem
                                        onClick={() => {
                                            action.handler(file);
                                            handleMenuClose();
                                        }}
                                        key={action.name}
                                    >
                                        {action.label}
                                    </MenuItem>
                                ))}
                                {selectActions.length > 0 && <Divider />}
                                <MenuItem
                                    onClick={() => {
                                        navigator.clipboard.writeText(file.file_url);
                                        handleMenuClose();
                                    }}
                                >
                                    Copy Address
                                </MenuItem>
                                <MenuItem onClick={handleMenuClose}>Rename</MenuItem>
                                <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
                            </Menu>
                        </Grid>
                    ))}

                </Grid>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};


export default FileGrid;
