import api from 'api';



const baseType = 'CUSTOMCODES';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	RESET_ALL: `${baseType}/RESET_ALL`,
	SET_PROFILE_VALUE: `${baseType}/SET_PROFILE_VALUE`,
	CANCEL_EDIT: `${baseType}/CANCEL_EDIT`,
};

const initialState = {
	step: 0,
	codes: [],
	showForm: false,
	category: null,
	type: null,
	conf: {},
	multiSelectCodes: [],
	multiSelectExpanded: 0,
	multiSelectAllSelected: [],
	saving: false,
};


export const reducer = (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			return { ...state, ...action.data };


		case types.RESET_ALL:
			return { ...initialState };


		default:
			return state;
	}
};




export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),



	resetAll: () => ({ type: types.RESET_ALL }),



	loadCustomCodes: () => async (dispatch, getState) => {
		try {
			let results = await api.get(`/resources/custom-codes`);
			dispatch(actions.setState({
				codes: results.data.codes,
			}));
		} catch(err) {
			console.error(err);
		}
	},


	loadMultiSelectCodes: () => async (dispatch, getState) => {
		let category = getState().customCodes.category;
		try {
			let codes = await api.get(`/resources/charting-symbols`);

			codes = [
				...(
					(category === 'dx' || category === 'other')
					? codes.data.diagnostics
					: []
				),
				...(
					(category === 'tx' || category === 'other')
					? codes.data.treatments
					: []
				),
				...(
					(category === 'other')
					? codes.data.other
					: []
				),
			].sort((a, b) => a.symbol_abbreviation.localeCompare(b.symbol_abbreviation));

			dispatch(actions.setState({ multiSelectCodes: codes }));

		} catch(err) {
			console.error(err);
		}

	},


	setCodeProperty: (property, value) => (dispatch, getState) => {
		let conf = getState().customCodes.conf;

		dispatch(actions.setState({
			conf: {
				...conf,
				[ property ]: value,
			},
		}));
	},


	setSectionLabel: (index, value) => (dispatch, getState) => {
		let conf = getState().customCodes.conf;

		dispatch(actions.setState({
			conf: {
				...conf,
				symbol_chart_config: {
					...conf.symbol_chart_config,
					options: conf.symbol_chart_config.options.map((option, optionIndex) => {
						if(index === optionIndex) {
							return { ...option, label: value };
						}

						return option;
					}),
				},
			},
		}));
	},


	setMultiSelectCodes: (index, codes) => (dispatch, getState) => {
		let conf = getState().customCodes.conf;

		dispatch(actions.setState({
			conf: {
				...conf,
				symbol_chart_config: {
					...conf.symbol_chart_config,
					options: conf.symbol_chart_config.options.map((option, optionIndex) => {
						if(index === optionIndex) {
							return { ...option, codes: codes };
						}

						return option;
					}),
				},
			},
		}));

		dispatch(actions.generateAllSelected());
	},


	addSection: () => (dispatch, getState) => {
		let conf = getState().customCodes.conf;

		dispatch(actions.setState({
			conf: {
				...conf,
				symbol_chart_config: {
					...conf.symbol_chart_config,
					options: [
						...conf.symbol_chart_config.options,
						{
							label: '',
							codes: [],
						},
					],
				},
			},
			multiSelectExpanded: conf.symbol_chart_config.options.length,
		}));
	},



	handleMultiSelectSave: () => async (dispatch, getState) => {
		let conf = getState().customCodes.conf;

		dispatch(actions.setState({
			saving: true,
		}));

		try {
			await api.put(`/resources/custom-codes`, { code: conf });
			dispatch(actions.resetAll());
		} catch(err) {
			dispatch(actions.setState({
				saving: false,
			}));
		}
	},

	handleSimpleCodeSave: () => async (dispatch, getState) => {
		let conf = getState().customCodes.conf;

		dispatch(actions.setState({
			saving: true,
		}));

		try {
			await api.put(`/resources/custom-codes`, {
				code: {
					...conf,
					symbol_chart_config: {
						type: 'single',
						render: {
							type: 'text',
							position: 'user',
							scaleType: 'default',
							scaleValue: 1,
							defaultValue: conf.symbol_abbreviation,
						},
						attachment: conf.map_to,
					},
				},
			});
			dispatch(actions.resetAll());
		} catch(err) {
			dispatch(actions.setState({
				saving: false,
			}));
		}
	},


	generateAllSelected: () => (dispatch, getState) => {
		let options = getState().customCodes.conf.symbol_chart_config.options;

		dispatch(actions.setState({
			multiSelectAllSelected: options
				.map(row => row.codes)
				.flat(),
		}));
	},




};


export default reducer;