import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
    workspace: {
        marginTop: theme.spacing(1),
        //padding: theme.spacing(1),
        textAlign: 'center',
        overflowY: 'hidden',
        overflowX: 'auto',
        height: 'calc(100vh - 200px)',
        //width: '80%',
    },
    buccalContainer: {
        marginTop: theme.spacing(1),
        width: '100%',
        paddingTop: '51.67%',
        textAlign: 'center',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'white',
        maxHeight: 'calc(100vh - 200px)',
    },
    occlusalContainer: {
        marginTop: theme.spacing(1),
        width: '100%',
        height: 'calc(100vh - 180px)',
        textAlign: 'center',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'white',
    },
    perioContainer: {
        marginTop: theme.spacing(1),
        width: '100%',
        paddingTop: '70%',
        textAlign: 'center',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'white',
    },
    canvasWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: theme.spacing(1),
    },
    buccalCanvasWrapper: {
        maxHeight: 'calc(100vh - 250px)',
    },
    buttonMarginRight: {
        marginRight: theme.spacing(2),
    },
    buttonFloat: {
        //float: 'left',
    },
    probingButton: {
        height: 56,
    },
    probingPad: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
        height: 'auto',
    },
    probingPadWrapper: {
        padding: theme.spacing(1),
    },
    bottomBoxes: {
        height: 200,
        maxHeight: 200,
        overflow: 'auto',
    },
}));



export default useStyles;