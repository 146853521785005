import React, { useCallback, useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Add, LocationOn } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { IntegratedPaging, PagingState } from "@devexpress/dx-react-grid";
import { Grid, PagingPanel, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { actions } from '../ClientView.redux';
import ClientAddressesEditModal from "./ClientAddressesEditModal";
import { Button } from "@mui/material";
import ClientAddressesCell from "./ClientAddressesCell";



const columns = [
	{ name: 'address_edit', title: 'Edit' },
	{ name: 'address_name', title: 'Name' },
	{ name: 'address_line_1', title: 'Line 1' },
	{ name: 'address_line_2', title: 'Line 2' },
	{ name: 'address_city', title: 'City' },
	{ name: 'address_state', title: 'State/Province' },
	{ name: 'address_postal_code', title: 'Postal Code' },
	{ name: 'address_country', title: 'Country' },
	{ name: 'date_created', title: 'Date Added' },
];


const ClientAddresses = () => {
	const dispatch      = useDispatch(),
		  { client_id } = useParams(),
		  hasLoaded     = useSelector(state => state.clientView.addressesPreviouslyLoaded),
		  loading       = useSelector(state => state.clientView.addressesLoading),
		  addresses     = useSelector(state => state.clientView.addresses),
		  loadAddresses = (clientID) => dispatch(actions.loadAddresses(clientID));


	const handleAddNew = useCallback(() => {
		dispatch(actions.openAddressEditModal(null));
	}, [ dispatch ]);

	useEffect(() => {
		if(!hasLoaded && !loading) {
			loadAddresses(client_id);
		}

	}, [ loadAddresses, client_id, hasLoaded, loading ]);


	return (
		<DashboardCard
			color={'indigo'}
			icon={<LocationOn />}
			topContent={"Addresses"}
			topLabel={<div>
				<Button
					variant="contained"
					color="primary"
					size="small"
					startIcon={<Add />}
					onClick={handleAddNew}
				>
					Add New
				</Button>
			</div>}
			size="xl"
		>
			<Grid
				rows={addresses}
				columns={columns}
			>
				<PagingState
					defaultCurrentPage={0}
					defaultPageSize={10}
				/>
				<IntegratedPaging />
				<Table
					cellComponent={ClientAddressesCell}
				/>
				<TableHeaderRow />
				<PagingPanel pageSizes={[ 5, 10, 20, 50 ]} />
			</Grid>

			<ClientAddressesEditModal />
		</DashboardCard>
	);
};


export default ClientAddresses;