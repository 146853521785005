import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	searchContainer: {
		marginBottom: 64,
	},
	searchButtons: {
		margin: 8,
	},
}));



export default useStyles;