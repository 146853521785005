import dialogAtMouse from './helpers/dialogAtMouse';
import addText from './helpers/addText';
import ToothValuePicker from './components/ToothValuePicker';
import store, {actions} from 'store';


const portal = document.getElementById('hidden');
let dx = 'GR';
//let sel;


function render(helper, opt) {
	/*let demo = {
		stObj: true,
		canvas: util.canvasName,
		name: name,
		position: [e.point.x, e.point.y],
		dx: dx,
		tooth: e.item.name,
	};*/

	// Bad save?
	if(!opt.hasOwnProperty('content')) return false;
	let isAdult = opt.tooth.substr(0, 1) === 't';
	let layer = opt.adult ? `teeth_${opt.canvas}` : `teeth_${opt.canvas}_deciduous`;
	let vis = helper.getToothVisibility();

	if(!vis.adult && isAdult) return false;
	if(!vis.deciduous && !isAdult) return false;

	let c = helper.setActive(opt.canvas, 'diagnostics');
	if(c.layers.diagnostics.layer.children[opt.name]) {
		c.layers.diagnostics.layer.children[opt.name].content = `GR${opt.content}`;
		return true;
	}

	let bounds = c.layers[layer].layer.children[opt.tooth].bounds;
	let color = (opt.tooth && opt.tooth.substr(0, 1) === 't') ? 'blue' : '#5b00ef';

	let textConfig = {
		point: [bounds.bottomCenter.x-((opt.content.length+1)*15), bounds.bottomCenter.y+35],
		content: `GR${opt.content}`,
		name: opt.name,
		fullySelected: false,
		fontSize: 60,
		fontWeight: 'bold',
		fillColor: color,
		data: {...opt},
		opacity: 1,
	};

	return addText(c, textConfig);
}


export default {
	onMouseUp: function(e, util) {
		if(util.canvasName !== 'buccal') {
			return false;
		}

		let toothVis = util.helper.getToothVisibility();
		if(!toothVis.active) {
			store.dispatch(actions.setSnackbar(
				'Use the Layers menu to select only adult or deciduous teeth for this tool.',
				3000,
				true,
			));

			return false;
		}

		let layer = toothVis.active === 'adult' ? `teeth_${util.canvasName}` : `teeth_${util.canvasName}_deciduous`;

		if(e.item && e.item.name && e.item.layer.name === layer) {
			e.item.data.originalStroke = e.item.strokeColor.toCSS(true);
			e.item.strokeColor = 'blue';

			dialogAtMouse({...util, dx, render}, portal, e, ToothValuePicker);
		}
	},
	render: render
};