import React from 'react';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../CustomCodes.redux';



const SelectType = () => {
	const dispatch = useDispatch(),
		  value    = useSelector(state => state.customCodes.type),
		  step     = useSelector(state => state.customCodes.step);

	const handleChange = (e) => {
		dispatch(actions.setState({ type: e.target.value }));
	};

	const handleNext = () => {
		dispatch(actions.setState({ step: step + 1 }));
	};


	return (
		<div>
			<div>
				<FormControl component="fieldset">
					<RadioGroup name="gender1" value={value} onChange={handleChange}>
						<FormControlLabel value="single" control={<Radio />} label="Simple Code" />
						<FormControlLabel value="multiselect" control={<Radio />} label="Multi-Select" />
						{/*<FormControlLabel value="input" control={<Radio />} label="Custom Input" />
						*/}
					</RadioGroup>
				</FormControl>
			</div>
			<div>
				<Button
					variant="contained"
					disabled={value === null}
					color="primary"
					onClick={handleNext}
				>
					Next
				</Button>
			</div>
		</div>
	);
};


export default SelectType;