import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions } from './FileManager.redux';
import useStyles from './FileManager.styles';
import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { Close } from "@mui/icons-material";
import FileManagerMenu from './FileManagerMenu';
import FileManagerQuota from './FileManagerQuota';
import RecentFiles from './RecentFiles';
import RelevantFiles from './RelevantFiles';
import FileBrowser from './FileBrowser';
import UploadForm from './UploadForm';



const FileManager = ({
	client_id = null,
	patient_id = null,
	visit_id = null,
	urpt_id = null,
	defaultPage = 'upload',
	selectActions = [],
}) => {
	const classes  = useStyles(),
		  dispatch = useDispatch(),
		  open     = useSelector(state => state.files.open),
		  page     = useSelector(state => state.files.page);


	const handleClose = (e, reason) => {
		if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
			return false;
		}

		dispatch(actions.resetAll());
	};


	useEffect(() => {
		if(defaultPage && open) {
			dispatch(actions.setPage(defaultPage));
		}
	}, [ defaultPage, dispatch, open ]);


	return (
        <Dialog
			open={open}
			scroll={'paper'}
			fullWidth
			maxWidth={'lg'}
			onClose={handleClose}
			classes={{
				paper: classes.fullHeight,
			}}
		>
			<div className={classes.root}>

				<AppBar position="static" color="default" className={classes.appBar}>
					<Toolbar>
						<IconButton
                            className={classes.closeButton}
                            color="inherit"
                            aria-label="Menu"
                            onClick={handleClose}
                            size="large">
							<Close />
						</IconButton>

						<Typography variant="h6" color="inherit" className={classes.grow} noWrap>
							Select or upload a file
						</Typography>

						<Typography variant="subtitle2">
							<FileManagerQuota />
						</Typography>
					</Toolbar>
				</AppBar>
			</div>

			<div className={classes.root}>
				<FileManagerMenu
					client_id={client_id}
					visit_id={visit_id}
					patient_id={patient_id}
					urpt_id={urpt_id}
				/>

				<main className={classes.content}>
					{page === 'upload' && <UploadForm
						client_id={client_id}
						visit_id={visit_id}
						patient_id={patient_id}
						urpt_id={urpt_id}
					/>}
					{page === 'client' &&
						<RelevantFiles id_type="client_id" id={client_id} selectActions={selectActions} />}
					{page === 'patient' &&
						<RelevantFiles id_type="patient_id" id={patient_id} selectActions={selectActions} />}
					{page === 'visit' &&
						<RelevantFiles id_type="visit_id" id={visit_id} selectActions={selectActions} />}
					{page === 'report' &&
						<RelevantFiles id_type="urpt_id" id={urpt_id} selectActions={selectActions} />}
					{page === 'recent' && <RecentFiles selectActions={selectActions} />}
					{page === 'browse' && <FileBrowser
						client_id={client_id}
						visit_id={visit_id}
						patient_id={patient_id}
						urpt_id={urpt_id}
						selectActions={selectActions}
					/>}

				</main>

			</div>

		</Dialog>
    );
};


export default FileManager;
