import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStroke,
	strokeWidth: 2.5,
	fillColor: colors.defaultFill,
	scaling: 1.35, // 0.7 for shrunk view with numbers?
	rotation: 0
};
const canvasWidth  = 1000;
const canvasHeight = 3000;
const x            = canvasWidth+100;
const y            = canvasHeight;

function pos(newPos, size) {
	return size / 1000 * (size === y ? newPos+35 : newPos);
}

export default [
	// UPPER 100s (left)
	{
		"name": "t101",
		"position": [pos(460, x), pos(10, y)],
		"options": defaultOptions
	},
	{
		"name": "t102",
		"position": [pos(400, x), pos(15, y)],
		"options": defaultOptions
	},
	{
		"name": "t103",
		"position": [pos(342, x), pos(30, y)],
		"options": defaultOptions
	},
	{
		"name": "t104",
		"position": [pos(305, x), pos(57, y)],
		"options": defaultOptions
	},
	{
		"name": "t105",
		"position": [pos(310, x), pos(95, y)],
		"options": defaultOptions
	},
	{
		"name": "t106",
		"position": [pos(300, x), pos(125, y)],
		"options": defaultOptions
	},
	{
		"name": "t107",
		"position": [pos(253, x), pos(168, y)],
		"options": defaultOptions
	},
	{
		"name": "t108",
		"position": [pos(218, x), pos(217, y)],
		"options": defaultOptions
	},
	{
		"name": "t109",
		"position": [pos(220, x), pos(269, y)],
		"options": defaultOptions
	},
	{
		"name": "t110",
		"position": [pos(235, x), pos(298, y)],
		"options": defaultOptions
	},

	// UPPER 200s (right)
	{
		"name": "t201",
		"position": [pos(537, x), pos(10, y)],
		"options": defaultOptions
	},
	{
		"name": "t202",
		"position": [pos(597, x), pos(15, y)],
		"options": defaultOptions
	},
	 {
		"name": "t203",
		"position": [pos(656, x), pos(30, y)],
		"options": defaultOptions
	},
	  {
		"name": "t204",
		"position": [pos(691, x), pos(57, y)],
		"options": defaultOptions
	},
	{
		"name": "t205",
		"position": [pos(686, x), pos(95, y)],
		"options": defaultOptions
	},
	{
		"name": "t206",
		"position": [pos(697, x), pos(125, y)],
		"options": defaultOptions
	},
	{
		"name": "t207",
		"position": [pos(743, x), pos(168, y)],
		"options": defaultOptions
	},
	{
		"name": "t208",
		"position": [pos(779, x), pos(217, y)],
		"options": defaultOptions
	},
	{
		"name": "t209",
		"position": [pos(777, x), pos(269, y)],
		"options": defaultOptions
	},
	{
		"name": "t210",
		"position": [pos(762, x), pos(298, y)],
		"options": defaultOptions
	},

	// LOWER 400s (left)
	{
		"name": "t401",
		"position": [pos(468, x), pos(651, y)],
		"options": defaultOptions
	},
	{
		"name": "t402",
		"position": [pos(417, x), pos(648, y)],
		"options": defaultOptions
	},
	{
		"name": "t403",
		"position": [pos(360, x), pos(642, y)],
		"options": defaultOptions
	},
	{
		"name": "t404",
		"position": [pos(321, x), pos(624, y)],
		"options": defaultOptions
	},
	{
		"name": "t405",
		"position": [pos(343, x), pos(589, y)],
		"options": defaultOptions
	},
	{
		"name": "t406",
		"position": [pos(324, x), pos(567, y)],
		"options": defaultOptions
	},
	{
		"name": "t407",
		"position": [pos(301, x), pos(532, y)],
		"options": defaultOptions
	},
	{
		"name": "t408",
		"position": [pos(280, x), pos(490, y)],
		"options": defaultOptions
	},
	{
		"name": "t409",
		"position": [pos(250, x), pos(443, y)],
		"options": defaultOptions
	},
	{
		"name": "t410",
		"position": [pos(225, x), pos(403, y)],
		"options": defaultOptions
	},
	{
		"name": "t411",
		"position": [pos(228, x), pos(380, y)],
		"options": defaultOptions
	},

	// LOWER 300s (right)
	{
		"name": "t301",
		"position": [pos(527, x), pos(651, y)],
		"options": defaultOptions
	},
	{
		"name": "t302",
		"position": [pos(579, x), pos(648, y)],
		"options": defaultOptions
	},
	{
		"name": "t303",
		"position": [pos(637, x), pos(642, y)],
		"options": defaultOptions
	},
	{
		"name": "t304",
		"position": [pos(677, x), pos(624, y)],
		"options": defaultOptions
	},
	{
		"name": "t305",
		"position": [pos(656, x), pos(589, y)],
		"options": defaultOptions
	},
	{
		"name": "t306",
		"position": [pos(671, x), pos(567, y)],
		"options": defaultOptions
	},
	{
		"name": "t307",
		"position": [pos(698, x), pos(532, y)],
		"options": defaultOptions
	},
	{
		"name": "t308",
		"position": [pos(718, x), pos(490, y)],
		"options": defaultOptions
	},
	{
		"name": "t309",
		"position": [pos(745, x), pos(443, y)],
		"options": defaultOptions
	},
	{
		"name": "t310",
		"position": [pos(771, x), pos(403, y)],
		"options": defaultOptions
	},
	{
		"name": "t311",
		"position": [pos(769, x), pos(380, y)],
		"options": defaultOptions
	},
];