module.exports = {
	"t101": {
		"paths": ["M859.8,151.5C859.8,151.5,859.7,151.5,859.8,151.5c-0.7-0.5-1.4-0.6-2.1-0.6c-0.7,0-1.4-0.1-2.1-0.1       c-1.4,0.1-2.8,0.5-4.1,0.9c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.7,1.3-1.8,2.6-1.8,4.1       c-0.2,2.1,0.8,2.8,0.8,3.6c0,0.8,0,6.2,0.6,6.7s4.4,2,6.5,0s3.6-6.2,3.7-7.2c0.1-1.2,0-2.4,0.2-3.6c0.2-1.2,0.4-2.3-0.2-3.5       C860.4,152.1,860.1,151.8,859.8,151.5z"]
	},
	"t102": {
		"paths": ["M825.8,152.2c-1.9-2.9-5.9-4.3-9.2-3.1c-2.3,0.8-4.1,2.8-4.7,5.2c-1.1,4.5,2.1,9.1,1.3,13.6       c-0.2,1.1-0.6,2.3,0,3.3c0.4,0.6,1.1,1,1.9,1.1c1.4,0.3,2.9-0.1,4.2-0.8c1.3-0.7,2.3-1.7,3.3-2.8c1.6-1.7,2.9-3.7,3.8-5.8       c0.9-2.2,1.2-4.5,0.8-6.8c-0.2-1-0.4-1.9-0.8-2.9c-0.2-0.4-0.4-1-0.7-1.4"]
	},
	"t103": {
		"paths": ["M779.3,152.8c-0.2-0.2-0.3-0.3-0.5-0.5c-3.6-3-10.6-1.3-14.5-0.1c-2.6,0.9-4.2,3-4.2,5.7       c0,3.3,3.7,7.6,3.9,9.9s1.2,6.6,1.2,9.2s3.7,8.8,6.9,8.3c3.2-0.6,6.7-2.9,7-8.6s-0.9-7.8,0.3-11.6c0.6-1.8,1.6-3.6,2-5.5       c0.5-2-0.3-4.3-1.4-6.1C779.7,153.4,779.5,153.1,779.3,152.8z"]
	},
	"t104": {
		"paths": ["M691.5,152.6c-0.1-0.2-0.2-0.3-0.4-0.5c-0.5-0.6-1-1.1-1.6-1.5c-2.4-1.7-5.5-2.8-8.1-4.2       c-3-1.6-6.3-2.9-9.6-3.8c-3.5-1.1-7.2-1.8-10.9-1.8c-3.5,0-7.7,0.5-10.9,1.9c-1.9,0.9-3.6,2.4-4.1,4.5c-0.6,2.7,1,5.2,1.7,7.7       c0.7,2.3,1.4,4.7,2,7c1.5,5.7,2.8,11.6,3.5,17.4c2.2,17.9,8.9,40.6,11.2,71.7c2.3,31.2,15.4,5.7,17.1,2.7       c1.2-2.2,1.7-5,2.4-7.3c1.5-5.4,2.8-10.9,4-16.5c3.4-16.2,5.5-32.8,5.8-49.4c0.1-5,0-10.1-0.4-15.1c-0.1-2.2-0.6-4.4-0.6-6.6       C692.7,156.7,692.7,154.4,691.5,152.6z"]
	},
	"t106": {
		"paths": ["M578.2,164c0.1,2,0.8,4,2.2,5.4c1.3,1.2,3.1,1.8,4,3.3c1,1.5,0.7,3.5,1.2,5.3c0.7,2.3,2.9,4,5.3,4.1       c2.7,0.1,5.2-1.8,6.6-4.1c1.3-2.4,1.7-5.2,1.8-7.9c0.1-2.7,0-5.5,0.5-8.1c0.4-2.3,1.3-4.6,0.7-6.8c-0.8-2.7-3.7-4.3-6.5-4.5       c-3.3-0.2-6.3,1-9.5,1.6c-0.7,0.1-1.4,0.3-2.1,0.7c-1,0.6-1.2,1.6-1.7,2.6c-0.4,0.9-0.8,1.8-1.2,2.8       C578.8,160.1,578.1,162.1,578.2,164z"]
	},
	"t107": {
		"paths": ["M549.9,164.9c0.1,0.1,0.2,0.1,0.3,0.2C550.1,164.7,549.9,164.9,549.9,164.9z", "M555,152c-1.2-0.7-2.4-1.3-3.6-1.7c-7.2-2.3-14.8-2.5-22.3-1.7c-4.5,0.4-9,1.2-13.5,1.9       c-4.2,0.7-8.5,1.7-12.7,1.9c-1.8,0.1-3.7,0.1-5.4,0.6c-3,0.9-6,4.1-5.2,7.5c0.9,4,5.8,0.2,7.4,3.1c1.6,2.9,3.4,12.7,6.7,14.1       c3.4,1.3,5.6-4.5,6-3.4c0.7,10.7,7.2,16.6,7.2,16.6s11.6,19.5,21.6-3c8-17.8,9-21.9,9-22.8c-0.1-0.1-0.2-0.1-0.3-0.2       c0,0,0.2-0.2,0.3,0.2c3.6,2.7,8.1,3.7,10.2-2.4C562,158.3,558.8,154.3,555,152z"]
	},
	"t108": {
		"paths": ["M375.5,152.3c0,0-0.1,0-0.1,0.1c-3,2-6.1,4.5-7.8,7.8c-2.4,5-0.7,11,3,14.9c2.8,3,28.2,14.5,31.7,14.6       s6.6-5.6,10.2-5.3s10.6,29,15.3,29.8s26.5-31.4,29.5-31.2c3,0.2,3.9,7.5,9.9,4.7c4.1-1.9,6.5-8.3,8.1-12.1       c2.1-5,4.2-10.4,5-15.8c0.4-2.6-0.2-4.3-2.6-5.8c-6-3.9-12.2-5.5-19-7.2c-7.5-1.9-15.4-2.5-23.1-2.7c-5.5-0.2-11,0-16.5,0.4       c-10.1,0.8-20,2.4-29.9,4.5c-2.5,0.5-4.9,1.1-7.4,1.6C379.8,151,377.1,151.3,375.5,152.3z"]
	},
	"t109": {
		"paths": ["M348.5,151.8c-2.6-1.8-6-2-9.1-1.7c-1.4,0.1-2.8,0.4-4.2,0.8c-1.3,0.3-3,0.7-4.1,1.4       c-0.9,0.6-3.6,2.7-3.2,7c0.5,5.2,0.2,4.9,0.3,8.5c0.1,3.6-0.2,8.5,6,11.2c6.2,2.7,8.4,3.4,15.3-3.9c2.6-2.7,3.7-6.5,3.9-10.2       c0.1-1.9-0.1-3.8-0.5-5.7c-0.4-1.7-0.8-3.8-1.9-5.2c-0.5-0.6-1.1-1.2-1.8-1.7C349,152.1,348.8,151.9,348.5,151.8z"]
	},
	"t201": {
		"paths": ["M897.4,151.5C897.4,151.5,897.5,151.5,897.4,151.5c0.7-0.5,1.4-0.6,2.1-0.6c0.7,0,1.4-0.1,2.1-0.1       c1.4,0.1,2.8,0.5,4.1,0.9c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.7,1.3,1.8,2.6,1.8,4.1c0.2,2.1-0.8,2.8-0.8,3.6       c0,0.8,0,6.2-0.6,6.7s-4.4,2-6.5,0s-3.6-6.2-3.7-7.2c-0.1-1.2,0-2.4-0.2-3.6c-0.2-1.2-0.4-2.3,0.2-3.5       C896.8,152.1,897.1,151.8,897.4,151.5z"]
	},
	"t202": {
		"paths": ["M931.4,152.2c1.9-2.9,5.9-4.3,9.2-3.1c2.3,0.8,4.1,2.8,4.7,5.2c1.1,4.5-2.1,9.1-1.3,13.6       c0.2,1.1,0.6,2.3,0,3.3c-0.4,0.6-1.1,1-1.9,1.1c-1.4,0.3-2.9-0.1-4.2-0.8c-1.3-0.7-2.3-1.7-3.3-2.8c-1.6-1.7-2.9-3.7-3.8-5.8       c-0.9-2.2-1.2-4.5-0.8-6.8c0.2-1,0.4-1.9,0.8-2.9c0.2-0.4,0.4-1,0.7-1.4"]
	},
	"t203": {
		"paths": ["M977.9,152.8c0.2-0.2,0.3-0.3,0.5-0.5c3.6-3,10.6-1.3,14.5-0.1c2.6,0.9,4.2,3,4.2,5.7       c0,3.3-3.7,7.6-3.9,9.9s-1.2,6.6-1.2,9.2s-3.7,8.8-6.9,8.3c-3.2-0.6-6.7-2.9-7-8.6s0.9-7.8-0.3-11.6c-0.6-1.8-1.6-3.6-2-5.5       c-0.5-2,0.3-4.3,1.4-6.1C977.5,153.4,977.7,153.1,977.9,152.8z"]
	},
	"t204": {
		"paths": ["M1065.7,152.6c0.1-0.2,0.2-0.3,0.4-0.5c0.5-0.6,1-1.1,1.6-1.5c2.4-1.7,5.5-2.8,8.1-4.2       c3-1.6,6.3-2.9,9.6-3.8c3.5-1.1,7.2-1.8,10.9-1.8c3.5,0,7.7,0.5,10.9,1.9c1.9,0.9,3.6,2.4,4.1,4.5c0.6,2.7-1,5.2-1.7,7.7       c-0.7,2.3-1.4,4.7-2,7c-1.5,5.7-2.8,11.6-3.5,17.4c-2.2,17.9-8.9,40.6-11.2,71.7c-2.3,31.2-15.4,5.7-17.1,2.7       c-1.2-2.2-1.7-5-2.4-7.3c-1.5-5.4-2.8-10.9-4-16.5c-3.4-16.2-5.5-32.8-5.8-49.4c-0.1-5,0-10.1,0.4-15.1       c0.1-2.2,0.6-4.4,0.6-6.6C1064.5,156.7,1064.6,154.4,1065.7,152.6z"]
	},
	"t206": {
		"paths": ["M1179.1,164c-0.1,2-0.8,4-2.2,5.4c-1.3,1.2-3.1,1.8-4,3.3c-1,1.5-0.7,3.5-1.2,5.3c-0.7,2.3-2.9,4-5.3,4.1       c-2.7,0.1-5.2-1.8-6.6-4.1c-1.3-2.4-1.7-5.2-1.8-7.9c-0.1-2.7,0-5.5-0.5-8.1c-0.4-2.3-1.3-4.6-0.7-6.8c0.8-2.7,3.7-4.3,6.5-4.5       c3.3-0.2,6.3,1,9.5,1.6c0.7,0.1,1.4,0.3,2.1,0.7c1,0.6,1.2,1.6,1.7,2.6c0.4,0.9,0.8,1.8,1.2,2.8       C1178.4,160.1,1179.1,162.1,1179.1,164z"]
	},
	"t207": {
		"paths": ["M1207.3,164.9c-0.1,0.1-0.2,0.1-0.3,0.2C1207.1,164.7,1207.3,164.9,1207.3,164.9z", "M1202.2,152c1.2-0.7,2.4-1.3,3.6-1.7c7.2-2.3,14.8-2.5,22.3-1.7c4.5,0.4,9,1.2,13.5,1.9       c4.2,0.7,8.5,1.7,12.7,1.9c1.8,0.1,3.7,0.1,5.4,0.6c3,0.9,6,4.1,5.2,7.5c-0.9,4-5.8,0.2-7.4,3.1c-1.6,2.9-3.4,12.7-6.7,14.1       c-3.4,1.3-5.6-4.5-6-3.4c-0.7,10.7-7.2,16.6-7.2,16.6s-11.6,19.5-21.6-3c-8-17.8-9-21.9-9-22.8c0.1-0.1,0.2-0.1,0.3-0.2       c0,0-0.2-0.2-0.3,0.2c-3.6,2.7-8.1,3.7-10.2-2.4C1195.2,158.3,1198.4,154.3,1202.2,152z"]
	},
	"t208": {
		"paths": ["M1381.7,152.3c0,0,0.1,0,0.1,0.1c3,2,6.1,4.5,7.8,7.8c2.4,5,0.7,11-3,14.9c-2.8,3-28.2,14.5-31.7,14.6       s-6.6-5.6-10.2-5.3s-10.6,29-15.3,29.8c-4.7,0.8-26.5-31.4-29.5-31.2c-3,0.2-3.9,7.5-9.9,4.7c-4.1-1.9-6.5-8.3-8.1-12.1       c-2.1-5-4.2-10.4-5-15.8c-0.4-2.6,0.2-4.3,2.6-5.8c6-3.9,12.2-5.5,19-7.2c7.5-1.9,15.4-2.5,23.1-2.7c5.5-0.2,11,0,16.5,0.4       c10.1,0.8,20,2.4,29.9,4.5c2.5,0.5,4.9,1.1,7.4,1.6C1377.4,151,1380.1,151.3,1381.7,152.3z"]
	},
	"t209": {
		"paths": ["M1408.7,151.8c2.6-1.8,6-2,9.1-1.7c1.4,0.1,2.8,0.4,4.2,0.8c1.3,0.3,3,0.7,4.1,1.4c0.9,0.6,3.6,2.7,3.2,7       c-0.5,5.2-0.2,4.9-0.3,8.5c-0.1,3.6,0.2,8.5-6,11.2c-6.2,2.7-8.4,3.4-15.3-3.9c-2.6-2.7-3.7-6.5-3.9-10.2       c-0.1-1.9,0.1-3.8,0.5-5.7c0.4-1.7,0.8-3.8,1.9-5.2c0.5-0.6,1.1-1.2,1.8-1.7C1408.2,152.1,1408.4,151.9,1408.7,151.8z"]
	},
	"t301": {
		"paths": ["M896.5,312.9c-2.3-2.7-2-7.5-2.6-11c-0.2-1.1-0.3-2.2-0.1-3.2c1.3-5.4,7.3-3.9,10.9-2.6       c4.8,1.7,5.8,7.2,5.3,11.7c-0.2,2.3-0.5,4.8-2.5,6.1c-2.5,1.7-5.9,1.7-8.7,0.7C897.8,314.1,897.1,313.6,896.5,312.9z"]
	},
	"t302": {
		"paths": ["M938.7,302.2c-0.4-3.2-1.5-6.4,0.9-8.5c2.3-2,1.9-0.7,8.3,0.9c6.4,1.7,6,6.4,5.7,8.6c-0.4,2.8,1,5.3-1,7.9       c-2.3,3.1-8,3.4-11,1.2C938.1,309.7,939.2,305.9,938.7,302.2z"]
	},
	"t303": {
		"paths": ["M986.1,301.1c-0.1-1.9-1.1-4.8-0.4-6.2c0.7-1.4,0-0.9,6.3-3.6s6.3-1.7,10.4,0c4.2,1.7,2.2,7.5,2.2,10.3       c0,4.3,0.1,9.6-4.3,11.7c-3.2,1.6-7.7,2.1-10.9,0.1C985,310.6,986.4,305.4,986.1,301.1z"]
	},
	"t304": {
		"paths": ["M1050.2,323.9c-4.2,0.4-8.1-1.7-11.5-3.9c-1.6-1.1-3-2.4-4.6-3.5c-1.7-1.2-3.5-2.1-4.9-3.7       c-2.5-2.9-1.6-5.5-1.7-8.9c-0.1-3.2,0-6.4,0.1-9.5c0.2-6.6,0.6-13.1,1.2-19.7c1.2-12.6,3.3-25.2,6.6-37.4       c1.3-4.8,2.8-9.6,4.6-14.3c5.5-15,10.1-15.6,11.4-14.3s5.2,4.6,4.9,16.3c-0.1,5.8,1.1,11.8,2,17.5c1.6,9.4,3.5,18.7,5.7,27.9       c1.8,7.4,3.7,14.8,5.9,22c1,3.4,2.1,6.8,3.3,10.2c1.2,3.6,3.8,7,2.7,11c-1.3,4.4-6.4,6.7-10.4,8       C1060.6,323.1,1055.3,323.4,1050.2,323.9z"]
	},
	"t307": {
		"paths": ["M1162.7,311.2c-0.1,0-0.1-0.1-0.2-0.1c-2-1.4-1.6-4.5-1.3-6.5c0.9-6.1,6.7-8.5,8.4-10.6       c1.6-2.2,12.5-27.3,14.7-31.9c2.2-4.6,5.6-7.6,10.2-0.2c4.6,7.4,8,19.7,10.4,24.1c3.9-1.6,9.1,11.2,13.1,18       c2.1,3.5,0.7,7.5-3,9.1c-3.3,1.4-7.9,1.4-11.4,1.7c-4.6,0.4-9.3,0.3-14,0.1c-7.8-0.5-15.5-1.6-23.3-2.6       C1165.1,312.1,1163.8,311.9,1162.7,311.2z"]
	},
	"t308": {
		"paths": ["M1250.2,315.9c-5-0.2-9.9-2.2-14.7-3.5c-0.5-0.2-1.1-0.3-1.6-0.6c-1.8-1.3-3.2-5.9-3.9-8       c-1-3.1-0.9-6.2,0.1-9.3c1.5-4.6,4.7-6.3,6.1-5.6c1.4,0.7,2.2,3.5,4.1,3.3c1.9-0.2,16.5-25.8,21.5-33s6.7-11.7,12.4-11.1       c5.7,0.7,16.6,28.3,19.9,31.1c3.3-3.3,5.9-4.4,9.4-1.5c3.6,2.6,4.5,6.3,9.1,10.6c2.2,2.1,2.7,8.6,2.7,11.4       c0,4.1-1.5,9.4-5.5,11.2c-1.8,0.8-3.8,1-5.7,1.2C1286.1,313.3,1268.4,316.8,1250.2,315.9z"]
	},
	"t309": {
		"paths": ["M1331.8,276.2c-0.5-8.3,1.1-21.7,3.7-23c2.6-1.3,7.4,4.8,12.6,7.8c5.2,3,14.5,9.5,18.4,14.4       c2.3-1.6,2.3-2.8,8.2-8.2c6.8-5.9,24.7-18,30.5-17.1c3.1,2,4.3,7.6,5,13.8c0.5,6.4,4.4,18.8,8.6,27c1.9,3.6,3.9,8.2,2.7,12.3       c-1.1,3.9-4.5,6.6-8.4,7.5c-6.1,1.4-12.5,2-18.6,2.9c-6.4,1-12.9,1.9-19.4,2.4c-8.3,0.7-16.6,0.8-24.8-0.3       c-4.2-0.5-8.3-1.4-12.4-2.5c-6.6-1.8-9-4.8-9.9-11.4C1327.1,294.4,1332.3,284.4,1331.8,276.2z"]
	},
	"t401": {
		"paths": ["M860.7,312.9c2.3-2.7,2-7.5,2.6-11c0.2-1.1,0.3-2.2,0.1-3.2c-1.3-5.4-7.3-3.9-10.9-2.6       c-4.8,1.7-5.8,7.2-5.3,11.7c0.2,2.3,0.5,4.8,2.5,6.1c2.5,1.7,5.9,1.7,8.7,0.7C859.4,314.1,860.1,313.6,860.7,312.9z"]
	},
	"t402": {
		"paths": ["M818.5,302.2c0.4-3.2,1.5-6.4-0.9-8.5c-2.3-2-1.9-0.7-8.3,0.9c-6.4,1.7-6,6.4-5.7,8.6c0.4,2.8-1,5.3,1,7.9       c2.3,3.1,8,3.4,11,1.2C819.1,309.7,818,305.9,818.5,302.2z"]
	},
	"t403": {
		"paths": ["M771.1,301.1c0.1-1.9,1.1-4.8,0.4-6.2c-0.7-1.4,0-0.9-6.3-3.6s-6.3-1.7-10.4,0c-4.2,1.7-2.2,7.5-2.2,10.3       c0,4.3-0.1,9.6,4.3,11.7c3.2,1.6,7.7,2.1,10.9,0.1C772.2,310.6,770.9,305.4,771.1,301.1z"]
	},
	"t404": {
		"paths": ["M707,323.9c4.2,0.4,8.1-1.7,11.5-3.9c1.6-1.1,3-2.4,4.6-3.5c1.7-1.2,3.5-2.1,4.9-3.7       c2.5-2.9,1.6-5.5,1.7-8.9c0.1-3.2,0-6.4-0.1-9.5c-0.2-6.6-0.6-13.1-1.2-19.7c-1.2-12.6-3.3-25.2-6.6-37.4       c-1.3-4.8-2.8-9.6-4.6-14.3c-5.5-15-10.1-15.6-11.4-14.3c-1.3,1.3-5.2,4.6-4.9,16.3c0.1,5.8-1.1,11.8-2,17.5       c-1.6,9.4-3.5,18.7-5.7,27.9c-1.8,7.4-3.7,14.8-5.9,22c-1,3.4-2.1,6.8-3.3,10.2c-1.2,3.6-3.8,7-2.7,11c1.3,4.4,6.4,6.7,10.4,8       C696.7,323.1,701.9,323.4,707,323.9z"]
	},
	"t407": {
		"paths": ["M594.5,311.2c0.1,0,0.1-0.1,0.2-0.1c2-1.4,1.6-4.5,1.3-6.5c-0.9-6.1-6.7-8.5-8.4-10.6       c-1.6-2.2-12.5-27.3-14.7-31.9c-2.2-4.6-5.6-7.6-10.2-0.2c-4.6,7.4-8,19.7-10.4,24.1c-3.9-1.6-9.1,11.2-13.1,18       c-2.1,3.5-0.7,7.5,3,9.1c3.3,1.4,7.9,1.4,11.4,1.7c4.6,0.4,9.3,0.3,14,0.1c7.8-0.5,15.5-1.6,23.3-2.6       C592.1,312.1,593.4,311.9,594.5,311.2z"]
	},
	"t408": {
		"paths": ["M507,315.9c5-0.2,9.9-2.2,14.7-3.5c0.5-0.2,1.1-0.3,1.6-0.6c1.8-1.3,3.2-5.9,3.9-8c1-3.1,0.9-6.2-0.1-9.3       c-1.5-4.6-4.7-6.3-6.1-5.6c-1.4,0.7-2.2,3.5-4.1,3.3c-1.9-0.2-16.5-25.8-21.5-33s-6.7-11.7-12.4-11.1       c-5.7,0.7-16.6,28.3-19.9,31.1c-3.3-3.3-5.9-4.4-9.4-1.5c-3.6,2.6-4.5,6.3-9.1,10.6c-2.2,2.1-2.7,8.6-2.7,11.4       c0,4.1,1.5,9.4,5.5,11.2c1.8,0.8,3.8,1,5.7,1.2C471.1,313.3,488.8,316.8,507,315.9z"]
	},
	"t409": {
		"paths": ["M425.4,276.2c0.5-8.3-1.1-21.7-3.7-23c-2.6-1.3-7.4,4.8-12.6,7.8s-14.5,9.5-18.4,14.4       c-2.3-1.6-2.3-2.8-8.2-8.2c-6.8-5.9-24.7-18-30.5-17.1c-3.1,2-4.3,7.6-5,13.8c-0.5,6.4-4.4,18.8-8.6,27       c-1.9,3.6-3.9,8.2-2.7,12.3c1.1,3.9,4.5,6.6,8.4,7.5c6.1,1.4,12.5,2,18.6,2.9c6.4,1,12.9,1.9,19.4,2.4       c8.3,0.7,16.6,0.8,24.8-0.3c4.2-0.5,8.3-1.4,12.4-2.5c6.6-1.8,9-4.8,9.9-11.4C430.1,294.4,424.9,284.4,425.4,276.2z"]
	}
}