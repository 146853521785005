import React, { useEffect, useState } from 'react';
import useStyles from './CanvasPerio.styles';
import PerioHandler from 'svgHandlers/Perio';
import PenPal from "lib/PenPal";
import SVGHelper from "svgHandlers/helper";



const CanvasPerio = ({ penPal }) => {
	const canvasRef  = React.createRef(),
		  classes    = useStyles(),
		  [ helper ] = useState(new SVGHelper(penPal)),
		  [ svg ]    = useState(new PerioHandler(penPal, helper));
	

	useEffect(() => {
		console.log('exists', penPal.canvas.exists('perio'));
		if(svg) {
			console.log('running canvas init');
			svg.init(canvasRef.current);
		}

		return () => {
			if(svg) {
				console.log('destroying canvas');
				svg.destroy();
			}
		};
	}, []);


	if(penPal.canvas.exists('perio')) {
		penPal.sizeCanvas('perio', 0);
	}


	return (
		<div ref={canvasRef} className={classes.perioContainer} />
	);

};


export default CanvasPerio;
