import makeStyles from '@mui/styles/makeStyles';



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		//display: 'flex',
		height: '100%',
	},
	appBarBackground: {
		background: '#ffffff',
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
		[ theme.breakpoints.up('lg') ]: {
			display: 'none',
		},
	},
	title: {
		display: 'none',
		[ theme.breakpoints.up('sm') ]: {
			display: 'block',
		},
	},
	sectionDesktop: {
		display: 'none',
		[ theme.breakpoints.up('md') ]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[ theme.breakpoints.up('md') ]: {
			display: 'none',
		},
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarOpen: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth - 1}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	appBarClosed: {
		marginLeft: theme.spacing(10),
		width: `calc(100% - calc(${theme.spacing(10)} - 1px))`,
		[ theme.breakpoints.down('lg') ]: {
			marginLeft: 0,
			width: '100%',
		},
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxShadow: '0 1px 24px 0 rgba(0, 0, 0, 0.25)',
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(10),
		[ theme.breakpoints.down('lg') ]: {
			width: 0,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		width: `calc(100% - ${theme.spacing(10)})`,
		marginLeft: theme.spacing(10),
		[ theme.breakpoints.down('lg') ]: {
			width: '100%',
			marginLeft: 0,
		},
	},
	forceScrollbar: {
		overflowY: 'scroll',
	},
}));

export default useStyles;
