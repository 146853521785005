import React, { useEffect, useState } from 'react';
import DashboardCard from "components/DashboardCard";
import { Add, Description } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from './Reports.redux';
import { CustomPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { push } from 'connected-react-router';
import ModalReportAddNew from './ModalReportAddNew';



const RowComponent = (props) => {
    const dispatch = useDispatch();

    return (
        <Table.Row
            {...props}
            onClick={() => dispatch(push(`/reports/edit/${props.row.urpt_id}`))}
        />
    );
};

const ReportsList = () => {

    const dispatch      = useDispatch(),
          loading       = useSelector(state => state.reports.loading),
          {
              rows,
              totalRows,
              lastQuery,
              pageSize,
              pageNumber,
          }             = useSelector(state => state.reports.reports),
          showNewReport = useSelector(state => state.reports.showNewReportModal),
          [ columns ]   = useState([
              { name: 'urpt_name', title: 'Report Title' },
              { name: 'date_created', title: 'Report Date' },
              { name: 'patient_name', title: 'Patient' },
              { name: 'client_name', title: 'Client' },
              { name: 'visit_date', title: 'Visit Date' },
              { name: 'template_name', title: 'Report Template' },
          ]);


    const setCurrentPage = (pageNum) => {
        if(loading) {
            return false;
        }

        dispatch(actions.setSubState('reports', { pageNumber: pageNum }));
        dispatch(actions.loadReports());
    };


    const setPageSize = (value) => {
        console.log(value);
        if(loading) {
            return false;
        }

        dispatch(actions.setSubState('reports', { pageSize: value, pageNumber: 0 }));
        dispatch(actions.loadReports());
    };


    useEffect(() => {
        if(lastQuery === '' && loading === false) {
            dispatch(actions.loadReports());
        }
    }, [ lastQuery, loading, dispatch ]);

    return (
        <DashboardCard
            color={'amber'}
            icon={<Description />}
            topContent={"Reports"}
            topLabel={<div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Add />}
                    onClick={() => dispatch(actions.setState({ showNewReportModal: true }))}
                >
                    Add New
                </Button>
            </div>}
            size="xl"
        >
            <Grid
                rows={rows}
                columns={columns}
            >
                <PagingState
                    currentPage={pageNumber}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <CustomPaging
                    totalCount={parseInt(totalRows)}
                />
                <Table
                    rowComponent={RowComponent}
                />
                <TableHeaderRow />
                <PagingPanel
                    pageSizes={[ 5, 10, 20, 30 ]}
                />
            </Grid>

            {showNewReport && <ModalReportAddNew />}
        </DashboardCard>
    );
};


export default ReportsList;
