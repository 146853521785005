import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
    occlusalContainer: {
        maxWidth: 'calc( (100vh - 200px) / 2)',
        //height: '100%',
        //minHeight: '100%',
        margin: '0 auto'
        //display: 'table',
    },
    occlusalCanvas: {
        aspectRatio: '1 / 2',
    },
}));



export default useStyles;