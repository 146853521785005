import React from 'react';
import { ClinicSettingsContextProvider } from './ClinicSettingsContext';
import ClinicSettings from './ClinicSettings';



const ClinicSettingsWrapper = () => {
	return (
		<ClinicSettingsContextProvider>
			<ClinicSettings />
		</ClinicSettingsContextProvider>
	);
};

export default ClinicSettingsWrapper;
