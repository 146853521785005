import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	InputAdornment,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "pages/Chart/Chart.redux";
import useStyles from './VisitInfoDialog.styles';
import { ExpandMore, CheckBoxOutlineBlank as EmptyCheck } from "@mui/icons-material";



const VisitInfoDialog = () => {
	const dispatch                                  = useDispatch(),
		  classes                                   = useStyles(),
		  [ weightUnitAnchor, setWeightUnitAnchor ] = useState(null),
		  values                                    = useSelector(state => state.chart.visitInfo),
		  options                                    = useSelector(state => state.chart.options),
		  abnormalDepth                             = useSelector(state => state.chart.options.abnormalPocketDepth),
		  open                                      = useSelector(state => state.chart.toolbar.selectedTool === 'visitInfo');

	const handleClose = () => {
		dispatch(actions.setToolbar({
			selectedButton: null,
			selectedTool: '',
		}));
	};

	const handleWeightUnitMenuOpen = (e) => {
		setWeightUnitAnchor(e.currentTarget);
	};

	const handleWeightUnitMenuClose = () => {
		setWeightUnitAnchor(null);
	};

	const handleAbnormalPocketDepthChange = (e) => {
		let value = Number(e.target.value);

		if(isNaN(value)) {
			return false;
		}

		dispatch(actions.setState({
			options: {
				...options,
				abnormalPocketDepth: value,
			},
		}));
	};

	const setWeightUnit = (value) => {
		dispatch(actions.setState({
			visitInfo: {
				...values,
				weightUnit: value,
			},
		}));
	};

	const handleInput = (e) => {
		let key   = e.target.name,
			value = Number(e.target.value);

		if(isNaN(value)) {
			return false;
		}

		dispatch(actions.setState({
			visitInfo: {
				...values,
				[ key ]: value,
			},
		}));
	};

	return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
			<DialogTitle>Visit Info</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Set the visit info for this chart, then click Done.
				</DialogContentText>

				<div className={classes.inputWrapper}>
					<TextField
						label="Weight"
						type="number"
						value={values.weight}
						name="weight"
						onChange={handleInput}
						fullWidth
						variant="outlined"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Button onClick={handleWeightUnitMenuOpen} endIcon={<ExpandMore />}>
										{values.weightUnit}
									</Button>
								</InputAdornment>
							),
						}}
					/>


					<Menu
						anchorEl={weightUnitAnchor}
						keepMounted
						open={Boolean(weightUnitAnchor)}
						onClose={handleWeightUnitMenuClose}
					>
						<MenuItem
							onClick={() => {
								setWeightUnit('lb');
								handleWeightUnitMenuClose();
							}}
						>
							<ListItemText>Pounds</ListItemText>
						</MenuItem>

						<MenuItem
							onClick={() => {
								setWeightUnit('kg');
								handleWeightUnitMenuClose();
							}}
						>
							<ListItemText>Kilograms</ListItemText>
						</MenuItem>
					</Menu>
				</div>


				<div className={classes.inputWrapper}>
					<TextField
						label="Abnormal Pocket Depth"
						type="number"
						value={abnormalDepth}
						onChange={handleAbnormalPocketDepthChange}
						fullWidth
						variant="outlined"
						InputProps={{
							endAdornment: <InputAdornment position="end">mm</InputAdornment>,
						}}
					/>
				</div>


				<div className={classes.inputWrapper}>
					<TextField
						label="Referring Veterinarian"
						type="text"
						value={values.referringVet}
						name="referringVet"
						onChange={handleInput}
						fullWidth
						variant="outlined"
					/>
				</div>


				<div className={classes.inputWrapper}>
					<TextField
						label="Notes"
						type="text"
						value={values.notes}
						name="notes"
						onChange={handleInput}
						multiline
						rows={4}
						maxRows={6}
						fullWidth
						variant="outlined"
					/>
				</div>


			</DialogContent>

			<DialogActions>
				<Button color="primary" variant="contained" onClick={handleClose}>
					Done
				</Button>
			</DialogActions>
		</Dialog>
    );
};



export default VisitInfoDialog;