import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions } from './FileManager.redux';
import FileGrid from './FileGrid';
import PaginationBar from './PaginationBar';



const RelevantFiles = ({ id_type, id, selectActions = [] }) => {
	const dispatch = useDispatch(),
		  open     = useSelector(state => state.files.open),
		  loading  = useSelector(state => state.files.loading),
		  loaded   = useSelector(state => state.files.loaded);


	const handleChangePage = () => {
		dispatch(actions.getRecentFiles(id_type, id));
	};


	useEffect(() => {
		if(open && !loading && !loaded && id) {
			dispatch(actions.searchFilesRelevant(id_type, id));
		}
	}, [ dispatch, loading, loaded, open, id_type, id ]);

	if(loading) {
		return null;
	}

	return (
		<div>
			<FileGrid selectActions={selectActions} />
			<PaginationBar onChangePage={handleChangePage} />
		</div>

	);
};


export default RelevantFiles;
