import React, { useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
} from "@mui/material";
import { actions } from "pages/Chart/Chart.redux";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import useStyles from './SymbolHandler.styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector, useDispatch } from "react-redux";



const width = 300;

const SymbolMultipleChoice = ({ handlerConf, point, symbolID }) => {
    const classes           = useStyles(),
          dispatch          = useDispatch(),
          usePositionStyles = makeStyles({
              dialog: {
                  position: 'fixed',
                  display: 'block',
                  opacity: 1,
                  zIndex: 100,
                  left: (point[0] + width + 72) > window.innerWidth
                      ? window.innerWidth - 72 - width
                      : point[0] - 200,
                  top: (point[1] + 700) > window.innerHeight
                      ? window.innerHeight - 800
                      : point[1] - 50,
              },
          }),
          positionClasses   = usePositionStyles(),
          symbols           = useSelector(state => state.chart.symbols),
          symbolsByID       = useMemo(() => (symbols?.reduce((accumulator, current) => {
              accumulator[current.symbol_id] = current;
              return accumulator;
          }, {})), [ symbols ]),
          canvas            = useSelector(state => state.chart.symbolOptions.canvas),
          symbol            = symbols.find(row => row.symbol_id === symbolID);

    return (
        <Dialog
            open={true}
            onClose={() => dispatch(actions.resetSymbolOptions({ symbol: symbolID }))}
            classes={{
                root: classes.container,
                paper: positionClasses.dialog,
            }}
            BackdropProps={{
                invisible: true,
            }}
            PaperProps={{
                elevation: 12,
            }}
        >
            <DialogTitle>
                {symbol.symbol_name}
                <IconButton
                    className={classes.closeButton}
                    onClick={() => dispatch(actions.resetSymbolOptions({ symbol: symbolID }))}
                    size="large">
                    <Close />
                </IconButton>
            </DialogTitle>


            <div style={{ height: '100%' }}>

                <div className={clsx(classes.contentContainer, classes.symbolContainer)}>

                    {handlerConf.options.map((option, index) => {
                        return (
                            <List
                                key={index}
                                subheader={
                                    <ListSubheader component="div">
                                        {option.label}
                                    </ListSubheader>
                                }
                            >
                                {option.codes.filter(symbolId => symbolsByID[symbolId] !== undefined).map(symbolID => {
                                    const sym = symbolsByID[symbolID];

                                    return (
                                        <ListItem
                                            key={sym.symbol_id}
                                            button
                                            onClick={() => dispatch(actions.handleSymbolDispatch(sym.symbol_id, canvas))}
                                        >
                                            <ListItemText
                                                primary={sym.symbol_abbreviation}
                                                secondary={sym.symbol_name}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        );
                    })}

                </div>


            </div>
        </Dialog>
    );
};


export default SymbolMultipleChoice;