import store, {actions} from 'store';
import colors from './Colors';
import _ from 'lodash';
import getToothInfo from './getToothInfo';



const canvasName = 'buccal';

export default function(penPal, helper) {
	let hasInitialized = false;
	let state = store.getState();
	let data = getToothInfo();
	let intersections = {
		perio: {
			upper: null,
			lower: null,
			upperLP: null,
			lowerLP: null,
			upperDeciduous: null,
			lowerDeciduous: null,
		},
		gr: {
			upper: null,
			lower: null,
			upperDeciduous: null,
			lowerDeciduous: null,
		},
		grlp: {
			upper: null,
			lower: null,
		},
	};

	let last = {
		tooth: null,
		missing: [ ...state.ActiveChart.MissingTeeth ],
		tool: state.ChartingOptions.ActiveTool,
	};


	store.subscribe(function() {
		let newState = store.getState();
		if(state.ActiveChart.Info === newState.ActiveChart.Info) {
			state = newState;
			runUpdateActions();
		}

		state = newState;
	});

	this.init = function(target) {
		//store.dispatch(actions.resetChart({ Species: 'canine' }));
		//store.dispatch(actions.resetChartingOptions());
		helper.addCanvas(canvasName, target, 3, 1.55);

		renderTeeth('teeth_buccal', data.buccalPaths, data.buccalLayout);
		renderTeeth('teeth_buccal_deciduous', data.buccalDeciduousPaths, data.buccalDeciduousLayout);
		renderTeeth('teeth_lingualPalatal', data.lingualPaths, data.lingualLayout);

		helper.addDummyTool(canvasName);
		helper.addTextTool(canvasName, helper.saveText);
		helper.addDrawingTool(canvasName, helper.saveDrawings);
		helper.addSymbolTool(canvasName, helper.saveSymbols);
		helper.addShapeTool(canvasName);
		helper.addClickTool(canvasName, 'markMissing', markMissing);
		helper.addClickTool(canvasName, 'setChartingTooth', setChartingTooth);
		//helper.addCustomTool(canvasName, 'diagnostics', 'diagDefault');
		//helper.addCustomTool(canvasName, 'treatments', 'treatmentDefault');
		helper.addMoveTool(canvasName);
		helper.addDeleteTool(canvasName);

		intersections[ 'gr' ].upper = helper.getIntersections(canvasName, 'teeth_buccal', [ 0, 300 ], [ 3000, 300 ], true);
		intersections[ 'gr' ].lower = helper.getIntersections(canvasName, 'teeth_buccal', [ 0, 1275 ], [ 3000, 1275 ], true);
		intersections[ 'grlp' ].upper = helper.getIntersections(canvasName, 'teeth_lingualPalatal', [ 0, 725 ], [ 3000, 725 ], true);
		intersections[ 'grlp' ].lower = helper.getIntersections(canvasName, 'teeth_lingualPalatal', [ 0, 895 ], [ 3000, 895 ], true);
		intersections[ 'gr' ].upperDeciduous = helper.getIntersections(canvasName, 'teeth_buccal_deciduous', [ 0, 300 ], [ 3000, 300 ], true);
		intersections[ 'gr' ].lowerDeciduous = helper.getIntersections(canvasName, 'teeth_buccal_deciduous', [ 0, 1275 ], [ 3000, 1275 ], true);

		intersections[ 'perio' ].upper = helper.getIntersections(canvasName, 'teeth_buccal', [ 0, 290 ], [ 3000, 290 ], true);
		intersections[ 'perio' ].upperLP = helper.getIntersections(canvasName, 'teeth_lingualPalatal', [ 0, 715 ], [ 3000, 715 ], true);
		intersections[ 'perio' ].lower = helper.getIntersections(canvasName, 'teeth_buccal', [ 0, 1287 ], [ 3000, 1287 ], true);
		intersections[ 'perio' ].lowerLP = helper.getIntersections(canvasName, 'teeth_lingualPalatal', [ 0, 900 ], [ 3000, 900 ], true);
		intersections[ 'perio' ].upperDeciduous = helper.getIntersections(canvasName, 'teeth_buccal_deciduous', [ 0, 290 ], [ 3000, 290 ], true);
		intersections[ 'perio' ].lowerDeciduous = helper.getIntersections(canvasName, 'teeth_buccal_deciduous', [ 0, 1287 ], [ 3000, 1287 ], true);

		runUpdateActions();
		hasInitialized = true;
	};


	function renderTeeth(layerName, paths, layout) {
		penPal.layer.set(layerName);

		layout.forEach(function(item, index) {
			let importObj = {
				children: paths[ item.name ].paths,
				name: item.name,
				position: item.position,
			};

			for(let key in item.options) {
				importObj[ key ] = item.options[ key ];
			}

			penPal.path.import(importObj.name, importObj);
		});
	}


	function highlightActiveTooth() {
		let tooth = state.ChartingOptions.ActiveTooth;
		let shouldHighlight = state.ChartingOptions.ActiveTool === 'probing';
		/*if((tooth === last.tooth || !hasInitialized) && !shouldHighlight) {
		 return false;
		 }*/

		let c = penPal.canvas.select(canvasName);

		fill(
			c.layers[ 'teeth_buccal' ].paths[ last.tooth || tooth ],
			c.layers[ 'teeth_buccal' ].paths[ tooth ],
		);
		fill(
			c.layers[ 'teeth_lingualPalatal' ].paths[ last.tooth || tooth ],
			c.layers[ 'teeth_lingualPalatal' ].paths[ tooth ],
		);

		last.tooth = tooth;

		function fill(previous, current) {
			previous.strokeColor = colors.defaultStroke;
			previous.fillColor = colors.defaultFill;
			previous.strokeWidth = 3;
			current.strokeColor = shouldHighlight ? colors.selectStroke : colors.defaultStroke;
			current.fillColor = shouldHighlight ? colors.selectFill : colors.defaultFill;
			current.strokeWidth = shouldHighlight ? 7 : 3;
		}
	}


	function markMissing(e) {
		let toothVis = helper.getToothVisibility();
		if(!toothVis.active) {
			store.dispatch(actions.setSnackbar(
				'Use the Layers menu to select only adult or deciduous teeth for this tool.',
				3000,
				true,
			));
			return false;
		}


		let toothLayer = toothVis.active === 'adult' ? 'teeth_buccal' : 'teeth_buccal_deciduous';
		let c = penPal.canvas.select(canvasName);

		if(!e.item
			|| !e.item.name
			|| !e.item.layer.name === toothLayer
			|| !c.layers[ toothLayer ].paths.hasOwnProperty(e.item.name)) {
			return false;
		}

		let tooth = e.item.name;
		helper.toggleMissing(canvasName, tooth);
	}


	function renderMissingTeeth() {
		let missing = [ ...state.ActiveChart.MissingTeeth ];
		if(missing === last.missing && hasInitialized) {
			return false;
		}


		let c = penPal.canvas.select(canvasName);
		let teeth = [ ...Object.keys(data.buccalPaths), ...Object.keys(data.buccalDeciduousPaths) ];
		let toothVis = helper.getToothVisibility();
		let both = toothVis.adult && toothVis.deciduous;

		update('teeth_buccal');
		update('teeth_buccal_deciduous', true);
		update('teeth_lingualPalatal');

		function update(toothLayer, isDeciduous = false) {
			for(let i = 0; i < teeth.length; i++) {
				let toothName = teeth[ i ];
				let tooth = c.layers[ toothLayer ].paths[ toothName ];
				let index = missing.indexOf(toothName);


				if(index === -1) {
					_.assign(tooth, {
						opacity: 1,
						fillColor: isDeciduous ? colors.defaultFillDeciduous : colors.defaultFill,
						strokeColor: isDeciduous ? colors.defaultStrokeDeciduous : colors.defaultStroke,
					});
				} else {
					_.assign(tooth, {
						opacity: (isDeciduous && both) ? 0 : 1,
						fillColor: isDeciduous ? colors.defaultFillLightDeciduous : colors.defaultFillLight,
						strokeColor: isDeciduous ? colors.defaultStrokeLightDeciduous : colors.defaultStrokeLight,
					});
				}

			}
		}

		return last.missing = missing;
	}



	function setChartingTooth(e) {
		let toothLayer = 'teeth_buccal';
		let c = penPal.canvas.select(canvasName);

		if(!e.item
			|| !e.item.name
			|| !e.item.layer.name === toothLayer
			|| !c.layers[ toothLayer ].paths.hasOwnProperty(e.item.name)) {
			return false;
		}

		store.dispatch(actions.setActiveChartOption('ActiveIndex', 0));

		return store.dispatch(actions.setChartingOptions({
			ActiveTooth: e.item.name,
			ChartingEnabled: true,
			ChartingType: getActiveTools()[ 0 ],
		}));
	}


	function setMarkupTool() {
		const c = penPal.canvas.select(canvasName);
		const markup = state.ChartingOptions.Markup;

		switch(markup.Tool) {
			case 'draw':
				c.tools.drawing.activate();
				break;
			case 'text':
				c.tools.text.activate();
				break;
			case 'circleOutline':
			case 'circleFill':
			case 'rectangleOutline':
			case 'rectangleFill':
				c.tools.shapes.activate();
				break;
			default:
				c.tools.dummy.activate();
		}
	}


	function updateTool() {
		let c = penPal.canvas.select(canvasName);

		switch(state.ChartingOptions.ActiveTool) {
			case 'markup':
				setMarkupTool();
				break;
			case 'markMissing':
				c.tools.markMissing.activate();
				break;
			case 'probing':
				c.tools.setChartingTooth.activate();
				break;
			case 'diagnostics':
				c.tools.diagnostics.activate();
				break;
			case 'treatments':
				c.tools.treatments.activate();
				break;
			case 'move':
				c.tools.move.activate();
				break;
			case 'delete':
				c.tools.delete.activate();
				break;
			default:
				c.tools.dummy.activate();
		}
	}


	function renderGingivalRecessionLine(key) {
		let layerName = `lines_${key}`;
		let c = helper.setActive(canvasName, layerName);
		c.layers[ layerName ].layer.remove();
		delete (c.layers[ layerName ]);

		let toothVis = helper.getToothVisibility();
		if(!toothVis.adult && !toothVis.deciduous) {
			return false;
		}

		let src = state.ActiveChart.Diagnostics.buccal;
		let missing = state.ActiveChart.MissingTeeth;

		let recessionValues = {};

		// 308 is the gingival recession value
		for(let key in src) {
			if(src[ key ].id === '308') {
				recessionValues[ src[ key ].tooth ] = src[ key ].content;
			}
		}

		c = helper.setActive(canvasName, layerName);
		if(toothVis.adult) {
			renderLine('upper');
			renderLine('lower');
		}

		if(toothVis.deciduous) {
			renderLine('upperDeciduous');
			renderLine('lowerDeciduous');
		}


		function renderLine(side) {
			let invert = side === 'lower';
			let ints = intersections[ key ][ side ];
			let keys = Object.keys(ints);
			let len = keys.length;

			while(len--) {
				let cur = ints[ keys[ len ] ];
				let tooth = keys[ len ];
				let val = recessionValues.hasOwnProperty(tooth) ? recessionValues[ tooth ] : 0;
				if(invert) {
					val *= -1;
				}
				let y = cur[ 0 ][ 1 ];

				let isMissing = missing.indexOf(tooth) !== -1;
				if(isMissing) {
					val = 0;
				}

				let points = {
					start: [ cur[ 0 ][ 0 ], y ],
					mid: [
						((cur[ 1 ][ 0 ] - cur[ 0 ][ 0 ]) / 2) + cur[ 0 ][ 0 ],
						y - (val * 11),
					],
					end: [ cur[ 1 ][ 0 ], y ],
				};

				let pathOpts = {
					strokeWidth: 4,
					strokeColor: '#666666',
					opacity: 1,
				};

				if((val < 0 && !invert) || (val > 0 && invert)) {
					pathOpts.fillColor = '#ff9c7b';
				}

				if(!isMissing) {
					let gradientOrigin = invert ? [ 0, 1275 ] : [ 0, 300 ];
					let gradientDestination = invert ? [ 0, 1308 ] : [ 0, 267 ];
					pathOpts.strokeColor = {
						gradient: {
							stops: [ '#666666', '#ddcb00', 'orange', '#c10000' ],
						},
						//origin and destination defines the direction of your gradient. In this case its vertical i.e bottom(blue/cooler) to up(red/warmer) refering to link you sent.
						origin: gradientOrigin, //gradient will start applying from y=200 towards y=0. Adjust this value to get your desired result
						destination: gradientDestination,
					};
				} else {
					pathOpts.dashArray = [ 10, 12 ];
					//pathOpts.opacity = 0.2;
					pathOpts.strokeColor = '#b4b4b4';
				}

				let path = new c.paper.Path({
					segments: [
						points.start,
						points.mid,
						points.end,
					],
					...pathOpts,
				});
				path.smooth({ type: 'continuous' });

				// Draw lines between teeth
				if(len > 0 && len < keys.length) {
					let next = ints[ keys[ len - 1 ] ];
					let points = {
						from: [ next[ 1 ][ 0 ], y ],
						to: [ cur[ 0 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = '#666666';
					line.strokeWidth = 4;
				}

				// Draw leftmost line
				if(len === 0) {
					let points = {
						from: [ 0, y ],
						to: [ cur[ 0 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = '#666666';
					line.strokeWidth = 4;
				}

				// Draw rightmost line
				if(len === keys.length - 1) {
					let points = {
						from: [ 5000, y ],
						to: [ cur[ 1 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = '#666666';
					line.strokeWidth = 4;
				}
			}

		}


	}




	function renderPerioDepthLines() {
		let layerName = 'lines_perio';
		let c = helper.setActive(canvasName, layerName);
		c.layers[ layerName ].layer.remove();
		delete (c.layers[ layerName ]);

		let toothVis = helper.getToothVisibility();
		if(!toothVis.adult) {
			return false;
		}

		let values = state.ActiveChart.PocketDepths;
		let missing = state.ActiveChart.MissingTeeth;
		let invertSides = [ 'upper', 'lowerLP' ];

		c = helper.setActive(canvasName, layerName);
		renderLine('upper');
		renderLine('upperLP');
		renderLine('lower');
		renderLine('lowerLP');


		function renderLine(side) {
			const abnormalValue = state.ActiveChart.options.abnormalPocketDepth || 0;
			let invert = invertSides.indexOf(side) !== -1;
			let ints = intersections[ 'perio' ][ side ];
			let keys = Object.keys(ints);
			let len = keys.length;
			let chartingConf = data.chartingConfig;

			while(len--) {
				let cur = ints[ keys[ len ] ];
				let tooth = keys[ len ];
				let vals = values.hasOwnProperty(tooth) ? values[ tooth ] : [ 0 ];
				if(invert) {
					vals = vals.map(depth => {
						return depth * -1;
					});
				}

				let offsets = {
					upper: 0,
					upperLP: 20,
					lower: 0,
					lowerLP: -20,
				};
				let offset = offsets[ side ];

				let y = cur[ 0 ][ 1 ] + offset;

				let isMissing = missing.indexOf(tooth) !== -1;
				if(isMissing) {
					vals = [ 0 ];
				}

				let points = {
					start: [ cur[ 0 ][ 0 ], y ],
					end: [ cur[ 1 ][ 0 ], y ],
					last: null,
				};

				let distance = cur[ 1 ][ 0 ] - cur[ 0 ][ 0 ];
				let pockets = chartingConf[ tooth ] ? chartingConf[ tooth ].pockets : 4;
				let direction = chartingConf[ tooth ].side;
				let segments = [ [ ...points.start ] ];
				let defaultColor = '#35df2b';
				let pathOpts = {
					strokeWidth: 4,
					strokeColor: defaultColor,
					opacity: 1,
				};

				let pocketsBySide = {
					upper: pockets === 4 ? 3 : 4,
					upperLP: pockets === 4 ? 1 : 2,
					lower: pockets === 4 ? 3 : 4,
					lowerLP: pockets === 4 ? 1 : 2,
				};

				let renderPockets = pocketsBySide[ side ];
				let srcVals;

				if(side === 'upper' || side === 'lower') {
					if(renderPockets === 3) {
						srcVals = [ 0, 1, 2 ];
					} else {
						srcVals = [ 0, 1, 2, 3 ];
					}
				}

				if(side === 'upperLP' || side === 'lowerLP') {
					if(renderPockets === 1) {
						srcVals = [ 3 ];
					} else {
						srcVals = [ 4, 5 ];
					}
				}

				if(!isMissing) {
					for(let i = 0; i <= renderPockets; i++) {
						let src = direction === 'right' ? i : renderPockets - 1 - i;
						let val = vals[ srcVals[ src ] ] || 0;
						if(Math.abs(val) < abnormalValue) val = 0;

						let step = distance / renderPockets;
						let start = i === 0 ? [ ...points.start ] : [ ...points.last ];
						let end = i === (renderPockets) ? [ ...points.end ] : [ start[ 0 ] + step, y + (val * 11) ];

						if(i === 0) {
							end[ 0 ] = end[ 0 ] - (step / 2);
						}

						points.last = end;
						segments.push(end);

						if(i === renderPockets) {
							segments.push(points.end);
						}
					}
				} else {
					pathOpts.dashArray = [ 10, 12 ];
					//pathOpts.opacity = 0.2;
					pathOpts.strokeColor = '#bfffb2';
					segments = [ points.start, points.end ];
				}

				let path = new c.paper.Path({
					segments,
					...pathOpts,
				});
				path.smooth({ type: 'continuous' });

				// Draw lines between teeth
				if(len > 0 && len < keys.length) {
					let next = ints[ keys[ len - 1 ] ];
					let points = {
						from: [ next[ 1 ][ 0 ], y ],
						to: [ cur[ 0 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = defaultColor;
					line.strokeWidth = 4;
				}

				// Draw leftmost line
				if(len === 0) {
					let points = {
						from: [ 0, y ],
						to: [ cur[ 0 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = defaultColor;
					line.strokeWidth = 4;
				}

				// Draw rightmost line
				if(len === keys.length - 1) {
					let points = {
						from: [ 5000, y ],
						to: [ cur[ 1 ][ 0 ], y ],
					};
					let line = new c.paper.Path(points.from, points.to);
					line.strokeColor = defaultColor;
					line.strokeWidth = 4;
				}
			}

		}


	}




	function getActiveTools() {
		return Object.keys(state.ChartingOptions.ChartingTools).filter(function(toolName) {
			return state.ChartingOptions.ChartingTools[ toolName ];
		}).map(function(toolName) {
			return toolName;
		});
	}


	const runUpdateActions = _.debounce(function() {
		if(!penPal.canvas.exists(canvasName)) {
			return false;
		}

		if(state.ChartingOptions.ActiveTool === 'probing' && last.tool !== 'probing') {
			penPal.resizeAll();
		}

		if(state.ChartingOptions.ActiveTool !== 'probing' && last.tool === 'probing') {
			penPal.resizeAll();
		}

		last.tool = state.ChartingOptions.ActiveTool;

		if(state.ChartingOptions.Hydrated[ canvasName ]) {
			helper.importDrawings(canvasName);
			helper.importText(canvasName);
			//helper.importSymbols(canvasName);
			//helper.importDiagnostics(canvasName, 'diagDefault');
			//helper.importTreatments(canvasName, 'treatmentDefault');
			store.dispatch(actions.setChartingOptions({
				Hydrated: {
					...state.ChartingOptions.Hydrated,
					[ canvasName ]: false,
				},
			}));
		}

		data = getToothInfo();
		updateTool();
		highlightActiveTooth();
		renderMissingTeeth();
		helper.updateLayerVisibility(canvasName);
		helper.importDrawings(canvasName);
		helper.importText(canvasName);
		helper.importShapes(canvasName);
		//helper.importDiagnostics(canvasName, 'diagDefault');
		//helper.importTreatments(canvasName, 'treatmentDefault');
		renderGingivalRecessionLine('gr');
		//renderGingivalRecessionLine('grlp');
		renderPerioDepthLines();
	}, 30);


	this.destroy = function() {

		if(!penPal.canvas.exists(canvasName)) {
			return false;
		}

		let c = penPal.canvas.select(canvasName);
		c.paper.view.onResize = null;
		penPal.canvas.destroy(canvasName);
	};


	this.save = () => {
		let c = penPal.canvas.select(canvasName);
		let svg = c.paper.project.exportSVG({
			asString: true,
		});

		store.dispatch(actions.setActiveChartOption(
			'Images',
			{
				...state.ActiveChart.Images,
				[ canvasName ]: svg,
			},
		));
	};


}