import React from 'react';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import useStyles from './Chart.styles';



const CodeDefinitions = ({ title, symbolType }) => {
    const codes   = useSelector(state => [
              ...(state.chart.chart_symbols_buccal || []),
              ...(state.chart.chart_symbols_occlusal || []),
          ]),
          classes = useStyles();

    let filtered    = codes
            .filter(code => code.symbol_type === symbolType)
            .map(code => `${code.render_value} - ${code.symbol_name}`),
        definitions = [ ...new Set(filtered) ];


    return (
        <Paper elevation={2} style={{ marginTop: 16, padding: 8 }} className={classes.bottomBoxes}>
            <div>
                <strong>{title}</strong>
                <ul>
                    {definitions.map((def, index) => (
                        <li key={index}>
                            <span>{def}</span>
                        </li>
                    ))}
                </ul>
            </div>

        </Paper>
    );
};


export default CodeDefinitions;
