import React from 'react';
import {
	Popover,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
} from '@mui/material';
import CustomSVG from 'components/CustomSVG';
const sel = document.getElementById('hidden');



class MultipleChoice extends React.Component {

	close = () => {
		this.props.paperEvent.item.remove();
	};


	setOption = (index, optionIndex) => e => {
		const {options, data} = this.props.state;
		const typeID = options[index].options[optionIndex];
		data.id      = typeID;
		data.name    = `${typeID}_${Date.now()}`;

		this.props.save(data);
	};


	render() {
		const {options, types} = this.props.state;
		console.log('multi', this.props.state)

		return (
			<Popover
				anchorEl={sel}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				open={this.props.open}
			>

				<List>
					{options.map((item, index) => {
						return(
							<div key={index}>
								<ListItem>
									<ListItemText primary={item.label} />
								</ListItem>
								<Divider />

								{item.options.filter(option => (types.hasOwnProperty(option))).map((option, optionIndex) => {
									console.log('option', option)
									return (
										<ListItem key={optionIndex} button onClick={this.setOption(index, optionIndex)}>
											<ListItemIcon style={{width: 36}}>
												<CustomSVG
													type={types[option].type || 'text'}
													data={types[option].abbr || '???'}
												/>
											</ListItemIcon>
											<ListItemText primary={types[option].name} />
										</ListItem>
									)
								})}
								{index < options.length-1 && <Divider />}
							</div>
						)
					})}
				</List>

			</Popover>
		);
	}
}


export default MultipleChoice;