import React, { useCallback } from 'react';
import { Button, Paper } from "@mui/material";
import { Autorenew, Redo, Save, Undo } from "@mui/icons-material";
import useStyles from './ChartingMenu.styles';
import clsx from 'clsx';
import { enqueueSnackbar } from 'notistack';
import MenuLayers from './MenuLayers';
import MenuTeeth from './MenuTeeth';
import MenuMarkup from './MenuMarkup';
import MenuDeleteMove from "./MenuDeleteMove";
import MenuExtras from './MenuExtras';
import MenuSymbols from './MenuSymbols';
import AllSymbolsDialog from "./AllSymbolsDialog";
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';
import useChartAutoSave from 'hooks/useChartAutoSave';



const ChartingMenu = () => {
    const classes  = useStyles(),
          dispatch = useDispatch(),
          saving   = useSelector(state => state.chart.saving);

    const [ autoSaveEnabled, autoSaveInterval ] = useChartAutoSave();

    const handleToggleAutoSave = useCallback(() => {
        const enabledDisabled = autoSaveEnabled ? 'disabled' : `enabled (saves every ${autoSaveInterval} seconds)`;
        enqueueSnackbar(`Auto-save ${enabledDisabled}.`, {
            variant: autoSaveEnabled ? 'default' : 'success',
            autoHideDuration: autoSaveEnabled ? 1000 : 2000,
        });
        dispatch(actions.setState({ autoSaveEnabled: !autoSaveEnabled }));
    }, [ autoSaveEnabled, autoSaveInterval, dispatch ]);

    return (
        <Paper elevation={2} className={classes.menuContainer}>
            <MenuLayers />
            <MenuTeeth />
            <MenuSymbols />
            <MenuMarkup />
            <MenuExtras />
            <MenuDeleteMove />
            <AllSymbolsDialog />


            <Button
                variant="outlined"
                className={clsx(classes.buttonMarginRight, classes.buttonFloat, classes.buttonSmall)}
                onClick={() => dispatch(actions.undoRedo('UNDO'))}
            >
                <Undo />
            </Button>


            <Button
                variant="outlined"
                className={clsx(classes.buttonMarginRight, classes.buttonFloat, classes.buttonSmall)}
                onClick={() => dispatch(actions.undoRedo('REDO'))}
            >
                <Redo />
            </Button>


            <Button
                variant="outlined"
                disabled={saving}
                className={clsx(classes.buttonFloat, classes.buttonSmall)}
                onClick={() => dispatch(actions.saveChart())}
            >
                <Save />
            </Button>
            <Button
                className={clsx({
                    [classes.buttonFloat]: true,
                    [classes.autoSaveOff]: !autoSaveEnabled,
                    [classes.autoSaveOn]: autoSaveEnabled,
                })}
                onClick={handleToggleAutoSave}
            >
                <Autorenew />
            </Button>

        </Paper>
    );
};



export default ChartingMenu;
