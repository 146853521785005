import _ from 'lodash';
import paper from 'paper';

/*
	PenPal is a paper.js wrapper to make common tasks easier.
	Use it, or don't.
 */

function PenPal() {

	/*
		CANVAS HELPER
	 */

	let canvases    = {},
		app         = this,
		state       = {
			canvas: null,
			layer: null,
		},
		blockResize = false;

	//window.addEventListener("resize", app.resizeAll);

	this.sizeCanvas = function(name, xOverride = null, yOverride = null) {
		if(blockResize && (!xOverride || !yOverride)) return false;

		state.canvas = name;
		let c = canvases[ name ];
		let Y = { parent: 0, canvas: 0 };
		let X = { parent: 0, canvas: 0 };
		let pY = c.parentNode.clientHeight;
		let pX = c.parentNode.clientWidth;
		if(xOverride && yOverride) {
			pY = yOverride;
			pX = xOverride;
		}
		let cRatio = c.x === c.y
			? 1
			: c.x / c.y; // Canvas ratio
		let pRatio = pX === pY
			? 1
			: pX / pY; // Parent node ratio
		let scale = 1;
		let isTall = false;

		if(cRatio === 1) {
			X = Y = pX >= pY
				? { parent: pY, canvas: pY }
				: { parent: pX, canvas: pX };
			scale = (c.x * 1000) / X.canvas;
		} else if(cRatio > 1) {
			X = pRatio > cRatio
				? { parent: pY * cRatio + 'px', canvas: pY * cRatio }
				: { parent: '100%', canvas: pX };
			Y = pRatio > cRatio
				? { parent: '100%', canvas: pY }
				: { parent: pX / cRatio + 'px', canvas: pX / cRatio };
			scale = (c.x * 1000) / X.canvas;
		} else if(cRatio < 1) {
			Y = pRatio < cRatio
				? { parent: pX / cRatio + 'px', canvas: pX / cRatio }
				: { parent: '100%', canvas: pY };
			X = pRatio < cRatio
				? { parent: '100%', canvas: pX }
				: { parent: pY * cRatio + 'px', canvas: pY * cRatio };
			scale = (c.y * 1000) / Y.canvas;
			isTall = true;
		}

		try {
			//console.log('SETTING SIZE', name, X.parent, Y.parent)
			if(xOverride && yOverride) {
				c.div.style.height = yOverride + 'px';
				c.div.style.width = xOverride + 'px';
			} else {
				c.div.style.width = isTall
					? X.parent
					: X.canvas;
				c.div.style.height = isTall
					? Y.parent
					: Y.canvas;
			}

			c.paper.view.setViewSize(X.canvas, Y.canvas);
			c.paper.view.setScaling(1 / scale);
			c.paper.view.setCenter((c.x * 500), (c.y * 500));
		} catch(e) {
			console.error(e);
		}
	};


	this.resizeAll = _.debounce(function() {
		for(let canvasName in canvases) {
			app.sizeCanvas(canvasName);
		}
	}, 200);



	this.canvas = {
		/*
			Creates a canvas element inside parentNode
			Canvas will fill the parent node to the best of its ability while maintaining given ratio
		 */
		create: function(name, parentNode, options) {
			//console.log('MAKIN A CANVAS');
			if(!options.hasOwnProperty('ratioX') || typeof options.ratioX !== 'number') {
				throw new Error('Penpal: canvas.create requires ratioX in options object.');
			}

			if(!options.hasOwnProperty('ratioY') || typeof options.ratioY !== 'number') {
				throw new Error('Penpal: canvas.create requires ratioY in options object.');
			}

			if(!options.hasOwnProperty('scale') || typeof options.scale !== 'number') {
				options.scale = 1;
			}

			if(canvases.hasOwnProperty(name)) {
				throw new Error('Canvas name already exists.');
			}

			state.canvas = name;
			let sizeX = options.ratioX * 1000,
				sizeY = options.ratioY * 1000;

			let div = document.createElement('div');
			div.style.margin = '0 auto';
			div.style.position = 'relative';

			let el = document.createElement('canvas');
			el.setAttribute('width', sizeX);
			el.setAttribute('height', sizeY);
			el.setAttribute('resize', true);
			//el.setAttribute('data-paper-resize', "true");
			el.setAttribute('hidpi', 'on');

			canvases[ name ] = {
				parentNode: parentNode,
				div: div,
				node: el,
				x: options.ratioX,
				y: options.ratioY,
				scale: options.scale,
				sizing: {
					origX: sizeX,
					origY: sizeY,
				},
				layers: {},
				tools: {},
				state: {},
			};

			div.appendChild(el);
			parentNode.appendChild(div);

			let paperscope = new paper.PaperScope();
			paperscope.setup(el);
			canvases[ name ].paper = paperscope;
			app.sizeCanvas(name, 0);

			canvases[ name ].paper.view.onResize = function() {
				// nothing to see here
				//app.sizeCanvas(name, 0);

				app.resizeAll();
			};

			let observer = new ResizeObserver(entries => {
				//el.setAttribute('width', div.clientWidth);
				//el.setAttribute('height', 500);
				app.resizeAll();
			});

			observer.observe(div);


			canvases[ name ].paper.activate();
			canvases[ name ].paper.view.draw();


			return canvases[ name ];
		},


		select: function(name) {
			if(canvases.hasOwnProperty(name)) {
				state.canvas = name;
				canvases[ state.canvas ].paper.activate();
				return canvases[ state.canvas ];
			}

			throw new Error('canvas.select: Canvas ' + name + ' does not exist');
		},

		destroy: function(name) {
			if(!canvases.hasOwnProperty(name)) {
				throw new Error('canvas.select: Canvas ' + name + ' does not exist');
			}

			//canvases[name].parentNode.removeChild(canvases[name].node);
			try {
				canvases[ name ].paper.tools.forEach(tool => {
					tool.remove();
				});

				canvases[ name ].paper.project.remove();
			} catch(err) {
				console.error(err);
			}

			delete (canvases[ name ]);
		},

		exists: function(name) {
			return canvases.hasOwnProperty(name);
		},

		get state() {
			return state;
		},
	};



	this.layer = {
		/*
		Activates a layer if it exists, otherwise creates it
		Created layers are automatically set as active upon creation
		*/
		'set': function(layerName) {
			let c = canvases[ state.canvas ];
			let p = c.paper;
			state.layer = layerName;

			if(c.layers.hasOwnProperty(layerName)) {
				return c.layers[ layerName ].layer.activate();
			}

			let newLayer = new p.Layer({
				name: layerName,
				applyMatrix: true,
			});

			c.layers[ layerName ] = {
				layer: newLayer,
				paths: {},
			};

			return c.layers[ layerName ].layer.activate();
		},

		'hide': function() {
			let c = canvases[ state.canvas ];

			if(c.layers.hasOwnProperty(state.layer)) {
				return c.layers[ state.layer ].layer.visible = false;
			}

			throw new Error('Penpal hideLayer: specified layer name does not exist on the active canvas.');
		},

		'show': function() {
			let c = canvases[ state.canvas ];

			if(c.layers.hasOwnProperty(state.layer)) {
				return c.layers[ state.layer ].layer.visible = true;
			}

			throw new Error('Penpal showLayer: specified layer name does not exist on the active canvas.');
		},
	};



	this.path = {
		'import': function(name, options, subGroup = null) {
			if(!options.children) {
				throw new Error('Penpal path.import: must supply children');
			}

			options.children = options.children.map(function(path) {
				return new canvases[ state.canvas ].paper.Path(path);
			});

			let group = new canvases[ state.canvas ].paper.Group(options);
			canvases[ state.canvas ].paper.view.draw();

			let layerPaths = canvases[ state.canvas ].layers[ state.layer ].paths;

			if(!subGroup || typeof subGroup !== 'string') {
				return layerPaths[ name ] = group;
			} else {
				if(!layerPaths.hasOwnProperty(subGroup)) {
					layerPaths[ subGroup ] = {};
				}
				return layerPaths[ subGroup ][ name ] = group;
			}
		},
	};


	this.toPNG = function(canvasName, maxDimension = 1448) {

		if(!canvases.hasOwnProperty(canvasName)) return Promise.reject('Invalid Canvas Name');
		let c  = canvases[ canvasName ],
			el = c.node,
			x,
			y;

		if(c.x === c.y) {
			x = maxDimension;
			y = maxDimension;
		} else if(c.x > c.y) {
			x = maxDimension;
			y = (c.y / c.x) * maxDimension;
		} else {
			y = maxDimension;
			x = (c.x / c.y) * maxDimension;
		}

		blockResize = true;
		return new Promise((resolve, reject) => {
			let file;
			app.sizeCanvas(canvasName, x, y);

			setTimeout(() => {
				file = el.toDataURL('image/png');
				c.div.style.height = 'auto';
				c.div.style.width = 'auto';
			}, 1000);

			setTimeout(() => {
				app.sizeCanvas(canvasName);
			}, 2000);

			setTimeout(() => {
				blockResize = false;

				// Hacky thing to get the canvas to redraw at proper scaling
				let event = document.createEvent('HTMLEvents');
				event.initEvent('resize', true, false);
				el.dispatchEvent(event);

				return resolve(file);
			}, 3000);
		});

	};


	this.getSVGString = function(canvasName) {
		console.log(canvases[ canvasName ]);
		return canvases[ canvasName ].paper.project.exportSVG({
			bounds: 'view',
			asString: true,
			embedImages: true,
		});
	};


	this.project = {
		'createTool': function(toolName, eventHandlers) {
			state.tool = toolName;
			let c    = canvases[ state.canvas ],
				p    = c.paper,
				tool = c.tools[ toolName ] = new p.Tool();

			for(let eventName in eventHandlers) {
				tool[ eventName ] = eventHandlers[ eventName ];
			}

			tool.activate();
		},

		'setTool': function(toolName) {
			return canvases[ state.canvas ].tools[ state.tool ].activate();
		},

		getProject: function() {
			return canvases[ state.canvas ].paper.project;
		},

	};

}

export default PenPal;
