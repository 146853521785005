import colors from '../Colors';

const defaultOptions = {
	strokeColor: colors.defaultStrokeDeciduous,
	strokeWidth: 1,
	fillColor: colors.defaultFillDeciduous,
	opacity: 1,
	scaling: 1.35,
	rotation: 0
};
const canvasWidth  = 1000;
const canvasHeight = 3000;
const x            = canvasWidth+100;
const y            = canvasHeight;

function pos(newPos, size) {
	return size / 1000 * (size === y ? newPos+35 : newPos);
}

export default [
	// UPPER 100s (left)
	{
		"name": "d501",
		"position": [pos(430, x), pos(-3, y)],
		"options": defaultOptions
	},
	{
		"name": "d502",
		"position": [pos(360, x), pos(7, y)],
		"options": defaultOptions
	},
	{
		"name": "d503",
		"position": [pos(292, x), pos(20, y)],
		"options": defaultOptions
	},
	{
		"name": "d504",
		"position": [pos(261, x), pos(65, y)],
		"options": defaultOptions
	},
	{
		"name": "d506",
		"position": [pos(250, x), pos(123, y)],
		"options": defaultOptions
	},
	{
		"name": "d507",
		"position": [pos(208, x), pos(160, y)],
		"options": {...defaultOptions, scaling: 1.1}
	},
	{
		"name": "d508",
		"position": [pos(148, x), pos(210, y)],
		"options": defaultOptions
	},

	// UPPER 200s (right)
	{
		"name": "d601",
		"position": [pos(567, x), pos(-3, y)],
		"options": defaultOptions
	},
	{
		"name": "d602",
		"position": [pos(637, x), pos(7, y)],
		"options": defaultOptions
	},
	 {
		"name": "d603",
		"position": [pos(706, x), pos(20, y)],
		"options": defaultOptions
	},
	  {
		"name": "d604",
		"position": [pos(735, x), pos(65, y)],
		"options": defaultOptions
	},
	{
		"name": "d606",
		"position": [pos(747, x), pos(123, y)],
		"options": defaultOptions
	},
	{
		"name": "d607",
		"position": [pos(788, x), pos(160, y)],
		"options": {...defaultOptions, scaling: 1.1}
	},
	{
		"name": "d608",
		"position": [pos(849, x), pos(210, y)],
		"options": defaultOptions
	},

	// LOWER 400s (left)
	{
		"name": "d801",
		"position": [pos(468, x), pos(666, y)],
		"options": defaultOptions
	},
	{
		"name": "d802",
		"position": [pos(411, x), pos(664, y)],
		"options": defaultOptions
	},
	{
		"name": "d803",
		"position": [pos(337, x), pos(658, y)],
		"options": defaultOptions
	},
	{
		"name": "d804",
		"position": [pos(251, x), pos(622, y)],
		"options": defaultOptions
	},
	{
		"name": "d806",
		"position": [pos(284, x), pos(567, y)],
		"options": {...defaultOptions, scaling: 1.1}
	},
	{
		"name": "d807",
		"position": [pos(255, x), pos(532, y)],
		"options": {...defaultOptions, scaling: 1.1}
	},
	{
		"name": "d808",
		"position": [pos(230, x), pos(490, y)],
		"options": {...defaultOptions, scaling: 1.0}
	},

	// LOWER 300s (right)
	{
		"name": "d701",
		"position": [pos(527, x), pos(666, y)],
		"options": defaultOptions
	},
	{
		"name": "d702",
		"position": [pos(585, x), pos(664, y)],
		"options": defaultOptions
	},
	{
		"name": "d703",
		"position": [pos(660, x), pos(658, y)],
		"options": defaultOptions
	},
	{
		"name": "d704",
		"position": [pos(747, x), pos(622, y)],
		"options": defaultOptions
	},
	{
		"name": "d706",
		"position": [pos(711, x), pos(567, y)],
		"options": {...defaultOptions, scaling: 1.1}
	},
	{
		"name": "d707",
		"position": [pos(743, x), pos(532, y)],
		"options": {...defaultOptions, scaling: 1.1}
	},
	{
		"name": "d708",
		"position": [pos(768, x), pos(490, y)],
		"options": {...defaultOptions, scaling: 1.0}
	},
];