import makeStyles from '@mui/styles/makeStyles';
import { blue, deepOrange, green, grey } from "@mui/material/colors";



const useStyles = makeStyles(theme => ({
    menuContainer: {
        padding: theme.spacing(0.5),
        textAlign: 'center',
        overflowY: 'hidden',
        overflowX: 'auto',
    },
    buttonMarginRight: {
        marginRight: theme.spacing(2),
    },
    buttonFloat: {
        //float: 'left',
    },
    buttonSmall: {
        minWidth: 32,
        paddingLeft: 8,
        paddingRight: 8,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    activeItem: {
        background: theme.palette.primary[50],
    },
    lineExtraThin: {
        width: 'calc(100% - 16px)',
        height: 1,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    lineThin: {
        width: 'calc(100% - 16px)',
        height: 4,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    lineMedium: {
        width: 'calc(100% - 16px)',
        height: 8,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    lineThick: {
        width: 'calc(100% - 16px)',
        height: 12,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    dxColor: {
        color: blue[900],
    },
    txColor: {
        color: deepOrange[900],
    },
    otherColor: {
        color: green[900],
    },
    symbolButtonGroup: {
        width: '100%',
        padding: 4,
    },
    symbolOtherButton: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    autoSaveOff: {
        color: grey[500],
    },
    autoSaveOn: {
        color: green[500],
    },
}));



export default useStyles;