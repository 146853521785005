import { subscribe, unsubscribe } from './subscribe';
import store from 'store';
import { actions } from 'pages/Chart/Chart.redux';



const canvasName   = 'perio',
      defaultTooth = 't101',
      layers       = {
          tooth: 'tooth',
          values: 'values',
          chart: 'chart',
          chartLabels: 'chartLabels',
      },
      center       = [ 500, 350 ];


export default function(penPal, helper) {
    let hasInitialized = false;


    // TEETH: ADULT
    subscribe('perio_activeTooth', {
        teeth: [ 'teethOcclusal' ],
        activeTooth: [ 'probeOptions', 'activeTooth' ],
        chartConfig: [ 'chartConfig' ]
    }, (current, state, previousState) => {
        renderTooth(current.teeth, current.activeTooth, current.chartConfig, state);
    });


    // TOOL STATE
    subscribe('perio_toolState', {
        toolState: [ 'probeOptions' ],
        chartConfig: [ 'chartConfig' ],
    }, (current, state, previousState) => {
        renderChart(current.toolState, current.chartConfig, state);
    });


    this.init = function(target) {
        if(penPal.canvas.exists(canvasName)) {
            return false;
        }
        hasInitialized = true;

        helper.addCanvas(canvasName, target, 1, 0.7);
        helper.addDummyTool(canvasName);
        let c = penPal.canvas.select(canvasName);
        c.tools.dummy.activate();
        let state = store.getState().chart;
        renderTooth(state.teethOcclusal, state.probeOptions.activeTooth, state.chartConfig, state);
        renderChart(state.probeOptions, state.chartConfig, state);
    };


    this.destroy = function() {
        if(!penPal.canvas.exists(canvasName)) {
            return false;
        }

        let c = penPal.canvas.select(canvasName);
        c.paper.view.onResize = null;
        penPal.canvas.destroy(canvasName);
        hasInitialized = false;
        unsubscribe('perio_activeTooth');
    };


    function renderTooth(teeth, activeTooth, chartConfig, state) {
        let colors = state.colors;

        let c = penPal.canvas.select(canvasName);
        activeTooth = activeTooth || defaultTooth;
        c.paper.activate();

        penPal.layer.set(layers.tooth);

        if(c.layers[layers.tooth].paths.hasOwnProperty('activeTooth')) {
            c.layers[layers.tooth].layer.remove();
            delete (c.layers[layers.tooth]);
            penPal.layer.set(layers.tooth);
        }

        let toothData = teeth.find(row => row.name === activeTooth);

        if(!toothData) {
            console.log('Could not find data for tooth', activeTooth);
            return false;
        }

        let importObj = {
            children: toothData.paths,
            name: 'activeTooth',
            position: center,
            strokeWidth: 5,
            strokeColor: colors.defaultStroke,
            fillColor: colors.defaultFill,
            data: { ...(toothData.data ?? {}) },
            //scaling: data.chartingConfig[activeTooth].scaling
            ...toothData.options,
        };
        //importObj.rotation = 0;

        let tooth = penPal.path.import(importObj.name, importObj);
        tooth.scaling = tooth.bounds.height < tooth.bounds.width
            ? 280 / tooth.bounds.width
            : 280 / tooth.bounds.height;

        // Flip tooth along vertical axis
        if(importObj.data.flipHorizontal) {
            tooth.scale(-1, 1);
        }

        if(importObj.data.flipVertical) {
            tooth.scale(1, -1);
        }
    }


    function renderChart(toolState, chartConfig, state) {
        let colors = state.colors;
        let c = penPal.canvas.select(canvasName);
        c.paper.activate();
        penPal.layer.set(layers.chart);

        let activeTooth = toolState.activeTooth || defaultTooth,
            depths      = state.pocketDepths.find(row => row.name === activeTooth),
            rotation    = chartConfig?.[activeTooth]?.probeValueRotation ?? 180,
            config      = chartConfig?.[activeTooth];

        if(depths) {
            depths = depths.values;
        } else {
            depths = [];
        }

        let tooth        = chartConfig[activeTooth],
            pockets      = tooth.pockets,
            toothLayer   = c.layers[layers.tooth],
            toothPaths   = toothLayer.paths,
            toothBounds  = toothPaths['activeTooth'].bounds,
            activePocket = toolState.activePocket || 0;

        activePocket = activePocket >= tooth.pockets
            ? 0
            : activePocket;


        // Reset the layer
        c.layers[layers.chart].layer.remove();
        delete (c.layers[layers.chart]);
        penPal.layer.set(layers.chart);

        let importBase = {
            children: [],
            name: '',
            strokeColor: colors.defaultStroke,
            strokeWidth: 3,
            fillColor: colors.defaultPocket,
            scaling: 1,
            rotation: 0,
            data: {},
        };

        let actualRadius = toothBounds.size.height > toothBounds.size.width
            ? toothBounds.size.height
            : toothBounds.size.width;

        let radius   = actualRadius / 2 + 150,
            mapFlipX = [],
            mapFlipY = [];

        mapFlipX[4] = [ 2, 1, 0, 3 ];
        mapFlipX[6] = [ 3, 2, 1, 0, 5, 4 ];

        mapFlipY[4] = [ 0, 3, 2, 1 ];
        mapFlipY[6] = [ 0, 5, 4, 3, 2, 1 ];

        for(let i = 0; i < tooth.pockets; i++) {
            let newVal = i;

            if(config?.probeFlipHorizontal) {
                newVal = mapFlipX[pockets][i];
            }
            if(config?.probeFlipVertical) {
                newVal = mapFlipY[pockets][i];
            }

            let x         = center[0] + radius * Math.cos((2 * Math.PI * newVal / tooth.pockets) + (rotation * (Math.PI / 180))),
                y         = center[1] + radius * Math.sin((2 * Math.PI * newVal / tooth.pockets) + (rotation * (Math.PI / 180))),
                importObj = { ...importBase, name: `p${newVal}`, position: [ x, y ] };


            //console.log('i', i, 'x', x, 'y', y);
            let circle = new c.paper.Path.Circle({
                center: new c.paper.Point(x, y),
                radius: 60,
                fillColor: i === activePocket
                    ? colors.selectPocket
                    : colors.defaultPocket,
                data: {
                    pocket: i,
                },
            });

            circle.onMouseUp = e => {
                let pocket = e
                    ? e.target?.data?.pocket
                    : 0;

                if(!pocket) {
                    pocket = 0;
                }

                store.dispatch(actions.setProbingOptions({ activePocket: pocket }));
            };

            let text = new c.paper.PointText(new c.paper.Point(0, 0));
            text.justification = 'center';
            text.fillColor = colors.pocketText;
            text.content = depths[i] || '';
            text.fontSize = 50;
            text.position = [ x, y ];
            text.onMouseUp = e => {
                store.dispatch(actions.setProbingOptions({ activePocket: i }));
            };

            importObj.children.push(circle);
            importObj.children.push(text);
            penPal.path.import(importObj.name, importObj);
        }

        renderLabels(actualRadius, tooth.pockets, activeTooth, state, chartConfig);
    }


    function renderLabels(radius, pockets, tooth, state, chartConfig) {
        let colors = state.colors,
            config = chartConfig?.[tooth];

        let c = helper.setActive(canvasName, layers.chartLabels);
        c.paper.activate();
        penPal.layer.set(layers.chart);
        let toothNum = Number(tooth.substr(1));

        // Reset the layer
        c.layers[layers.chartLabels].layer.remove();
        delete (c.layers[layers.chartLabels]);
        penPal.layer.set(layers.chartLabels);

        radius = radius / 2 + 50;

        let labelsUpper = {
            'four': [ 'M', 'B', 'D', 'P' ],
            'six': [ 'M', 'MB', 'DB', 'D', 'DP', 'MP' ],
        };

        let labelsLower = {
            'four': [ 'M', 'B', 'D', 'L' ],
            'six': [ 'M', 'MB', 'DB', 'D', 'DL', 'ML' ],
        };


        let labelDefs = toothNum < 300
            ? labelsUpper
            : labelsLower;

        let labels;
        switch(pockets) {
            case 4:
                labels = labelDefs['four'];
                break;
            case 6:
                labels = labelDefs['six'];
                break;
            default:
                labels = [];
        }

        let mapFlipX = [],
            mapFlipY = [];

        mapFlipX[4] = [ 2, 1, 0, 3 ];
        mapFlipX[6] = [ 3, 2, 1, 0, 5, 4 ];

        mapFlipY[4] = [ 0, 3, 2, 1 ];
        mapFlipY[6] = [ 0, 5, 4, 3, 2, 1 ];

        for(let i = 0; i < pockets; i++) {
            const rotation = config?.probeValueRotation ?? 180;

            let newVal = i;

            if(config?.probeFlipHorizontal) {
                newVal = mapFlipX[pockets][i];
            }
            if(config?.probeFlipVertical) {
                newVal = mapFlipY[pockets][i];
            }

            let x = center[0] + radius * Math.cos((2 * Math.PI * newVal / pockets) + (rotation * (Math.PI / 180))),
                y = center[1] + radius * Math.sin((2 * Math.PI * newVal / pockets) + (rotation * (Math.PI / 180)));


            let text = new c.paper.PointText(new c.paper.Point(0, 0));
            text.justification = 'center';
            text.fillColor = colors.pocketLabel;
            text.content = labels[i] || '?';
            text.fontSize = 35;
            text.position = [ x, y ];
        }
    }



}
