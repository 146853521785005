import React, { useEffect, useState } from 'react';
import DashboardCard from "components/DashboardCard";
import Chart from '@mui/icons-material/InsertChart';
import PropTypes from 'prop-types';
import api from 'api';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';



const styles = theme => ({
	fullWidth: {
		width: '100%',
	},
});

const RecentReports = ({ size, classes }) => {
	const [ reports, setReports ] = useState([]);
	const [ recentMessage, setRecentMessage ] = useState('Showing the most recent reports');

	useEffect(() => {
		async function fetchReports() {
			const result = await api.get('/reports/list', {
				params: {
					page_size: 10,
					pageSize: 10,
					pageNumber: 0,
				},
			});

			setReports(result.data.reports);

			if(result.data.reports.length === 0) {
				setRecentMessage('No reports to show');
			} else {
				setRecentMessage(`Showing the ${result.data.reports.length} most recent reports`);
			}

		}

		fetchReports().catch(err => console.log(err));
	}, [ setReports ]);

	return (
		<DashboardCard
			color={'amber'}
			icon={<Chart />}
			topContent={"Recent Reports"}
			topLabel={recentMessage}
			size={size}
		>
			<Table className={classes.fullWidth} size="small">
				<TableHead>
					<TableRow>
						<TableCell align="left">Name</TableCell>
						<TableCell align="left">Patient</TableCell>
						<TableCell align="left">Client</TableCell>
						<TableCell align="right">Report Date</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{reports.map(report => (
						<TableRow key={report.urpt_id}>
							<TableCell component="th" scope="row">
								<Link to={`/reports/edit/${report.urpt_id}`}>{report.urpt_name}</Link>
							</TableCell>
							<TableCell align="left">
								{report.patient_name}
							</TableCell>
							<TableCell align="right">{report.client_name}</TableCell>
							<TableCell align="right">{report.date_created}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

		</DashboardCard>
	);
};



RecentReports.defaultProps = {
	size: 'md',
};

RecentReports.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
};


export default withStyles(styles)(RecentReports);
