export default {
	t101: {
		rotation: 0,
		occlusalFlipHorizontal: true,
		data: {},
	},
	t102: {
		rotation: 0,
		occlusalFlipHorizontal: true,
		data: {},
	},
	t103: {
		rotation: -160,
		occlusalFlipHorizontal: true,
		data: {},
	},
	t104: {
		rotation: -70,
		occlusalValueRotation: 90,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t106: {
		rotation: -95,
		occlusalValueRotation: 90,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t107: {
		rotation: -120,
		occlusalValueRotation: 110,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t108: {
		rotation: -116,
		occlusalValueRotation: 110,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t109: {
		rotation: -75,
		occlusalValueRotation: 95,
		occlusalFlipVertical: true,
		data: { flipVertical: true },
	},
	t201: {rotation: 0},
	t202: {rotation: 0},
	t203: {
		rotation: -30,
		occlusalValueRotation: 20,
	},
	t204: {
		rotation: -110,
		occlusalValueRotation: 100,
	},
	t206: {
		rotation: -80,
		occlusalValueRotation: 90,
	},
	t207: {
		rotation: -60,
		occlusalValueRotation: 80,
	},
	t208: {
		rotation: -64,
		occlusalValueRotation: 70,
	},
	t209: {
		rotation: -105,
		occlusalValueRotation: 90,
	},
	t301: {
		rotation: 0,
		data: {flipVertical: true},
		occlusalFlipVertical: true,
	},
	t302: {
		rotation: 0,
		data: {flipVertical: true},
		occlusalFlipVertical: true,
	},
	t303: {
		rotation: 70,
		data: {flipVertical: true},
		occlusalFlipVertical: true,
	},
	t304: {
		rotation: 50,
		occlusalValueRotation: -60,
		occlusalFlipVertical: true,
		data: {flipVertical: true},
	},
	t307: {
		rotation: 70,
		occlusalValueRotation: 110,
		occlusalFlipHorizontal: true,
		data: {flipVertical: true},
	},
	t308: {
		rotation: 70,
		data: {flipVertical: true},
		occlusalValueRotation: 110,
		occlusalFlipHorizontal: true,
	},
	t309: {
		rotation: 70,
		data: {flipVertical: true},
		occlusalValueRotation: 110,
		occlusalFlipHorizontal: true,
	},
	t401: {
		rotation: 180,
		occlusalValueRotation: 180,
	},
	t402: {
		rotation: 180,
		occlusalValueRotation: 180,
	},
	t403: {
		rotation: 110,
		occlusalValueRotation: 180,
	},
	t404: {
		rotation: 135,
		occlusalValueRotation: 240,
		//occlusalFlipHorizontal: true,
	},
	t407: {
		rotation: 110,
		occlusalValueRotation: -110,
	},
	t408: {
		rotation: 110,
		occlusalValueRotation: 250,
	},
	t409: {
		rotation: 110,
		occlusalValueRotation: -115,
	},
};