import api from 'api';
import { push } from 'connected-react-router';


const baseType = 'CLIENTS';

export const types = {
	SET_STATE: `${baseType}/SET_STATE`,
	SET_SEARCH_TERM: `${baseType}/SET_SEARCH_TERM`,
	RESET_SEARCH: `${baseType}/RESET_SEARCH`,
	RESET_ALL: `${baseType}/RESET_ALL`,
};

const initialState = {
	searchTerms: {
		client_name: '',
		client_external_id: '',
		address_city: '',
		address_state: '',
		last_visit_start: '',
		last_visit_end: '',
	},
	clients: [],
	errors: [],
	showAddNew: false,
	savingClient: false,
	newClientDetails: {
		client_name: '',
		client_external_id: '',
		primary_vet: '',
		primary_clinic: '',
	},
	loading: false,
};


export default (state = initialState, action) => {
	switch(action.type) {
		case types.SET_STATE:
			console.log(action);
			return { ...state, ...action.data };

		case types.RESET_ALL:
			return { ...initialState };

		case types.RESET_SEARCH:
			return { ...state, searchTerms: { ...initialState.searchTerms } };

		case types.SET_SEARCH_TERM:
			return {
				...state,
				searchTerms: {
					...state.searchTerms,
					[ action.key ]: action.value,
				},
			};

		default:
			return state;
	}
}

export const actions = {
	setState: (data) => ({ type: types.SET_STATE, data }),


	setSearchTerm: (key, value) => ({ type: types.SET_SEARCH_TERM, key, value }),


	resetSearch: () => ({ type: types.RESET_SEARCH }),


	resetAll: () => ({ type: types.RESET_ALL }),


	showAddNew: () => ({
		type: types.SET_STATE,
		data: {
			showAddNew: true,
			savingClient: false,
			newClientDetails: { ...initialState.newClientDetails },
		},
	}),


	cancelNewClient: () => ({
		type: types.SET_STATE,
		data: {
			showAddNew: false,
			savingClient: false,
			newClientDetails: { ...initialState.newClientDetails },
		},
	}),


	updateNewClient: (e) => (dispatch, getState) => (
		dispatch(actions.setState({
			newClientDetails: {
				...getState().clients.newClientDetails,
				[ e.currentTarget.name ]: e.currentTarget.value,
			},
		}))
	),


	saveNewClient: (e) => async (dispatch, getState) => {
		let data = getState().clients.newClientDetails;

		dispatch(actions.setState({
			savingClient: true,
		}));

		try {
			let results = await api.post(`/clients/create`, data);
			dispatch(actions.cancelNewClient());
			dispatch(push(`/clients/view/${results.data.id}`));
		} catch(err) {
			dispatch(actions.setState({
				savingClient: false,
			}));
			console.error(err.response.data);
		}
	},


	search: () => async (dispatch, getState) => {
		dispatch(actions.setState({
			loading: true,
			clients: [],
		}));

		try {
			let searchTerms = getState().clients.searchTerms;
			let results = await api.get(`/clients/list?${new URLSearchParams(searchTerms).toString()}`);
			dispatch(actions.setState({
				loading: false,
				clients: results.data.clients || [],
			}));
		} catch(err) {
			dispatch(actions.setState({
				loading: false,
				clients: [],
			}));
		}
	},
};