import { css } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';



const useStyles = ({ position }) => {
	const theme = useTheme();

	return useMemo(() => ({
		container: css`
		  width: 800px;
		  height: 80px;
		`,
		dialog: css`
		  width: 800px;
		`,
		contentContainer: css`
		  padding: 8px;
		`,
		closeButton: css`
		  position: absolute;
		  right: ${theme.spacing(1)};
		  top: ${theme.spacing(0.5)};
		  color: ${theme.palette.grey[ 500 ]};
		`,
		dialogPosition: position === null
			? css`
				  position: fixed;
				  display: none;
				  opacity: 0;
				  z-index: -100;
				  left: -500px;
				  top: -500px;
			`
			: css`
				  position: fixed;
				  display: block;
				  opacity: 1;
				  z-index: 100;
				  left: ${position[ 0 ] - 150}px;
				  top: ${position[ 1 ] - 70}px;
			`,
	}), [ position ]);
};



export default useStyles;
