import React, { useCallback, useRef } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Input,
	InputLabel,
	Paper,
} from '@mui/material';
import { Clear, Delete, Save } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../ClientView.redux';
import useStyles from './ClientAddresses.styles';
import { useParams } from "react-router-dom";



const ClientAddressesEditModal = () => {
	const dispatch      = useDispatch(),
		  open          = useSelector(state => (state.clientView.displayModal === 'addresses')),
		  data          = useSelector(state => state.clientView.addressesEditData),
		  errors        = useSelector(state => state.clientView.addressEditErrors),
		  loading       = useSelector(state => state.clientView.addressEditLoading),
		  handleClose   = () => dispatch(actions.closeModal()),
		  isNew         = data.address_id === null,
		  formRef       = useRef(),
		  { client_id } = useParams(),
		  classes       = useStyles();


	const updateData = useCallback((event) => {
		dispatch(actions.editAddressData(event.target.name, event.target.value));
	}, [ dispatch ]);


	const handleSubmit = useCallback((event) => {
		event.preventDefault();
		dispatch(actions.submitAddress({
			...data,
			client_id: client_id,
		}));
	}, [ dispatch, data, client_id ]);


	return (
		<Dialog
			open={open}
		>
			<DialogTitle>{isNew ? 'New Address' : 'Edit Address'}</DialogTitle>

			<form ref={formRef} method="post" onSubmit={handleSubmit}>
				<DialogContent>
					<FormControl fullWidth margin="normal">
						<InputLabel>Name/Label</InputLabel>
						<Input
							type="text"
							placeholder="Name/Label"
							name="address_name"
							onChange={updateData}
							value={data.address_name}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>Address</InputLabel>
						<Input
							type="text"
							placeholder="Address"
							name="address_line_1"
							onChange={updateData}
							value={data.address_line_1}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>Suite, Apartment, Etc</InputLabel>
						<Input
							type="text"
							placeholder="Suite, Apartment, Etc"
							name="address_line_2"
							onChange={updateData}
							value={data.address_line_2}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>City</InputLabel>
						<Input
							type="text"
							placeholder="City"
							name="address_city"
							onChange={updateData}
							value={data.address_city}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>State/Province</InputLabel>
						<Input
							type="text"
							placeholder="State/Province"
							name="address_state"
							onChange={updateData}
							value={data.address_state}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>ZIP/Postal Code</InputLabel>
						<Input
							type="text"
							placeholder="ZIP/Postal Code"
							name="address_postal_code"
							onChange={updateData}
							value={data.address_postal_code}
						/>
					</FormControl>

					<FormControl fullWidth margin="normal">
						<InputLabel>Country</InputLabel>
						<Input
							type="text"
							placeholder="Country"
							name="address_country"
							onChange={updateData}
							value={data.address_country}
						/>
					</FormControl>

					{errors.map((err, index) => (
						<Paper key={index} variant="outlined" elevation={2} className={classes.errorContainer}>
							{err}
						</Paper>
					))}
				</DialogContent>

				<DialogActions>
					<Button
						startIcon={<Save />}
						variant="contained"
						color="primary"
						type="submit"
						disabled={loading}
					>
						Save
					</Button>

					{data.address_id !== null &&
					<Button
						startIcon={<Delete />}
						variant="contained"
						color="secondary"
						disabled={loading}
					>
						Delete
					</Button>
					}

					<Button
						startIcon={<Clear />}
						variant="outlined"
						onClick={handleClose}
						disabled={loading}
					>
						Cancel
					</Button>


				</DialogActions>
			</form>
		</Dialog>
	);

};


export default ClientAddressesEditModal;