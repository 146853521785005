import React from 'react';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import useStyles from './Chart.styles';



const TextNotes = () => {
    const buccalNotes   = useSelector(state => state.chart.text_buccal) || [],
          occlusalNotes = useSelector(state => state.chart.text_occlusal) || [],
          classes       = useStyles();


    return (
        <Paper elevation={2} style={{ marginTop: 16, padding: 8 }} className={classes.bottomBoxes}>
            <div>
                <strong>Buccal:</strong>
                <ul>
                    {buccalNotes.map((note, index) => (
                        <li key={note.name}>
                            <span style={{ color: note.color || '#000000' }}>{index + 1}. {note.content}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div style={{ marginTop: 16 }}>
                <strong>Occlusal:</strong>
                <ul>
                    {occlusalNotes.map((note, index) => (
                        <li key={note.name}>
                            <span style={{ color: note.color || '#000000' }}>{index + 1}. {note.content}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </Paper>
    );
};


export default TextNotes;
