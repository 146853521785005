import makeStyles from '@mui/styles/makeStyles';
import { indigo } from "@mui/material/colors";



const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		width: '100%',
		backgroundColor: '#E5E7E9',
		position: 'relative',
	},
	child: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: 500,
		maxWidth: 800,
		width: '100%',
		//backgroundColor: 'papayawhip',
	},
	fullHeightWidth: {
		width: '100%',
		height: '100%',
	},
	marginBottom: {
		marginBottom: 32,
	},
	inputs: {
		marginBottom: theme.spacing(4),
	},
	error: {
		color: 'red',
	},
	avatar: {
		width: theme.spacing(16),
		height: theme.spacing(16),
		margin: '0 auto',
		fontSize: '4em',
		'&:hover': {
			backgroundColor: indigo[ 500 ],
			cursor: 'pointer',
		},
	},
}));



export default useStyles;
