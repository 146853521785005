import React from 'react';
import { Button } from "@mui/material";
import { Delete, OpenWith, RotateLeft } from "@mui/icons-material";
import useStyles from './ChartingMenu.styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';



const MenuLayers = () => {
	const classes        = useStyles(),
		  dispatch       = useDispatch(),
		  selectedDelete = useSelector(state => state.chart.toolbar.selectedButton === 'delete'),
		  selectedMove   = useSelector(state => state.chart.toolbar.selectedButton === 'move'),
		  selectedRotate = useSelector(state => state.chart.toolbar.selectedButton === 'rotate');

	const setTool = toolName => e => {
		dispatch(actions.setToolbar({ selectedButton: toolName, selectedTool: toolName }));
	};


	return (
		<>
			<Button
				variant="outlined"
				className={clsx(classes.buttonMarginRight, classes.buttonFloat, classes.buttonSmall)}
				color={selectedMove
					? "primary"
					: undefined}
				onClick={setTool('move')}
			>
				<OpenWith />
			</Button>


			<Button
				variant="outlined"
				className={clsx(classes.buttonMarginRight, classes.buttonFloat, classes.buttonSmall)}
				color={selectedRotate
					? "primary"
					: undefined}
				onClick={setTool('rotate')}
			>
				<RotateLeft />
			</Button>


			<Button
				variant="outlined"
				className={clsx({
					[ classes.buttonMarginRight ]: true,
					[ classes.buttonFloat ]: true,
					[ classes.buttonSmall ]: true,
				})}
				color={selectedDelete
					? "primary"
					: undefined}
				onClick={setTool('delete')}
			>
				<Delete />
			</Button>
		</>
	);
};



export default MenuLayers;
