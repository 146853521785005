import React, { useEffect, useState } from 'react';
import WysiwygEditor from '../WysiwygEditor';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../Reports.redux';
import { useParams } from 'react-router-dom';
import TemplateReplacementModal from '../TemplateReplacementModal';
import useNavigationWarning from 'hooks/useNavigationWarning';
import { Button, TextField } from '@mui/material';
import api from 'api';



const EditReport = () => {
	const dispatch                       = useDispatch(),
		  { report_id }                  = useParams(),
		  report                         = useSelector(state => state.reports.report),
		  editorContent                  = useSelector(state => state.reports.editorContent),
		  templates                      = useSelector(state => state.reports.editorTemplates),
		  autofill                       = useSelector(state => state.reports.autofill),
		  [ Prompt, setDirty, setClean ] = useNavigationWarning(),
		  [ saving, setSaving ]          = useState(false);

	const handleChange = (newValue, editor) => {
		setDirty();
		dispatch(actions.updateReportContent(newValue));
	};

	const handleChangeTitle = (e) => {
		setDirty();
		dispatch(actions.setSubState('report', { urpt_name: e.target.value }));
	};

	const handleSave = async () => {
		setSaving(true);
		try {
			await api.put('/reports', {
				urpt_id: report_id,
				urpt_data: {
					main: editorContent,
				},
				urpt_name: report.urpt_name,
			});

			setClean();
		} catch(err) {
			console.error(err);
		}

		setSaving(false);
	};



	useEffect(() => {
		dispatch(actions.load_report(report_id));
	}, [ dispatch, report_id ]);

	useEffect(() => {
		// do nothing
		return () => dispatch(actions.resetAll());
	}, [ dispatch ]);

	if(!report.urpt_data) {
		return null;
	}



	return (
		<div>
			<div>
				<TextField
					label="Report Title"
					value={report.urpt_name ?? ''}
					onChange={handleChangeTitle}
				/>
			</div>

			<WysiwygEditor
				value={editorContent}
				onChange={handleChange}
				templates={templates}
				showReplacementButton={true}
				autofill={autofill}
				fileManagerProps={{
					client_id: report.client_id,
					patient_id: report.patient_id,
					visit_id: report.visit_id,
					urpt_id: report.urpt_id,
					defaultPage: 'report',
				}}
				navItems={[
					{
						key: 'chart',
						location: `/chart/${report.visit_id}`,
						label: 'Chart',
						newTab: false,
					},
					{
						key: 'chartnewtab',
						location: `/chart/${report.visit_id}`,
						label: 'Chart (new tab)',
						newTab: true,
					},
					{
						key: 'patient',
						location: `/patients/view/${report.patient_id}`,
						label: 'Patient Profile',
						newTab: false,
					},
					{
						key: 'patientnewtab',
						location: `/patients/view/${report.patient_id}`,
						label: 'Patient Profile (new tab)',
						newTab: true,
					},
					{
						key: 'client',
						location: `/clients/view/${report.client_id}`,
						label: 'Client Profile',
						newTab: false,
					},
					{
						key: 'clientnewtab',
						location: `/clients/view/${report.client_id}`,
						label: 'Client Profile (new tab)',
						newTab: true,
					},
				]}
			/>

			<TemplateReplacementModal />

			{Prompt}

			<Button
				variant="contained"
				color="primary"
				onClick={handleSave}
				disabled={saving}
			>
				Save
			</Button>
		</div>
	);
};


export default EditReport;
