import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Card,
	CardContent,
	Chip,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Popover,
	Typography,
} from '@mui/material';
import { actions } from 'store';
import { push } from 'connected-react-router';
import useStyles from './AppSearchResults.styles';




const AppSearchResults = ({ anchor }) => {
	const classes        = useStyles(),
		  dispatch       = useDispatch(),
		  results        = useSelector(state => state.layout.appSearchResults),
		  setLayoutState = data => dispatch(actions.layout.setState(data)),
		  pushUrl        = url => dispatch(push(url));

	const setRoute = (url) => e => {
		pushUrl(url);
		resetSearch();
	};

	const resetSearch = () => {
		setLayoutState({
			appSearchTerm: '',
			appSearchResults: null,
			appSearchPending: false,
		});
	};


	const getChipClass = type => {

		switch(type) {
			case 'patient':
				return classes.chipRootGreen;
			case 'client':
				return classes.chipRootOrange;
			case 'report':
				return classes.chipRootIndigo;
			case 'layout':
				return classes.chipRootPurple;
			default:
				return classes.chipRootDefault;
		}
	};


	if(!results) {
		return null;
	}

	if(!results.length) {
		return (
			<Popover
				open
				anchorEl={anchor.current}
				onClose={resetSearch}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Card className={classes.card}>
					<CardContent>
						<Typography variant="caption" color="textSecondary" gutterBottom>
							Search Results
						</Typography>
						<Typography component="p">
							No results found :(
						</Typography>
					</CardContent>
				</Card>
			</Popover>
		);
	}

	return (
		<Popover
			open
			anchorEl={anchor.current}
			onClose={resetSearch}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<Card className={classes.card}>
				<CardContent>
					<Typography variant="caption" color="textSecondary" gutterBottom>
						Search Results
					</Typography>

					<List component="nav">

						{results.map((result, index) => (
							<ListItem dense button key={index} onClick={setRoute(result.url)}>
								<ListItemText primary={result.label} />
								<ListItemSecondaryAction>
									<Chip
										label={result.type}
										className={classes.capitalize}
										classes={{
											root: getChipClass(result.type),
										}}
									/>
								</ListItemSecondaryAction>
							</ListItem>
						))}

					</List>
				</CardContent>
			</Card>
		</Popover>
	);
};




export default AppSearchResults;