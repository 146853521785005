import React, { useState } from 'react';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { BubbleChart, Build, Cancel, Close, HighlightOff } from "@mui/icons-material";
import useStyles from './ChartingMenu.styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';



const MenuTeeth = () => {
	const classes               = useStyles(),
		  [ anchor, setAnchor ] = useState(null),
		  dispatch              = useDispatch(),
		  selected              = useSelector(state => state.chart.toolbar.selectedButton === 'teeth'),
		  tool                  = useSelector(state => state.chart.toolbar.selectedTool),
		  validTools            = [ 'markMissing', 'probe' ];



	const handleClose          = () => setAnchor(null),


		  handleOpen           = (e) => {
			  setAnchor(e.currentTarget);

			  let shouldUpdate = false,
				  update       = {};

			  if(!selected) {
				  shouldUpdate = true;
				  update.selectedButton = 'teeth';
				  update.selectedTool = 'markMissing';
			  }

			  if(selected && validTools.includes(tool)) {
				  shouldUpdate = false;
			  }

			  if(selected && !validTools.includes(tool)) {
				  shouldUpdate = true;
				  update.selectedTool = 'markMissing';
			  }

			  if(shouldUpdate) {
				  dispatch(actions.setToolbar(update));
			  }
		  },


		  toggleTool           = name => e => {
			  dispatch(actions.setToolbar({
				  selectedTool: name,
				  selectedButton: 'teeth',
			  }));
			  setAnchor(null);
		  },

		  handleMarkAllMissing = name => e => {
			  dispatch(actions.setToolbar({
				  selectedTool: 'markMissing',
				  selectedButton: 'teeth',
			  }));

			  dispatch(actions.markAllMissing(name));
			  setAnchor(null);
		  },


		  toolIcons            = {
			  markMissing: <Close />,
			  probe: <BubbleChart />,
		  };


	return (
		<>
			<Button
				variant={selected
					? "contained"
					: "outlined"}
				color={selected
					? "primary"
					: undefined}
				className={clsx(classes.buttonMarginRight, classes.buttonFloat)}
				startIcon={
					toolIcons[ tool ] !== undefined
						? toolIcons[ tool ]
						: <Build />
				}
				onClick={handleOpen}
			>
				Teeth
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}
			>
				<MenuItem
					onClick={toggleTool('markMissing')}
					className={clsx({
						[ classes.activeItem ]: tool === 'markMissing',
					})}
				>
					<ListItemIcon><Close /></ListItemIcon>
					<ListItemText>Mark Missing</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={handleMarkAllMissing('adult')}
				>
					<ListItemIcon><HighlightOff /></ListItemIcon>
					<ListItemText>Mark All Missing (Adult)</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={handleMarkAllMissing('deciduous')}
				>
					<ListItemIcon><Cancel /></ListItemIcon>
					<ListItemText>Mark All Missing (Deciduous)</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={toggleTool('probe')}
					className={clsx({
						[ classes.activeItem ]: tool === 'probe',
					})}
				>
					<ListItemIcon><BubbleChart /></ListItemIcon>
					<ListItemText>Perio Probing</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={toggleTool('indices')}
					className={clsx({
						[ classes.activeItem ]: tool === 'indices',
					})}
				>
					<ListItemIcon><BubbleChart /></ListItemIcon>
					<ListItemText>Indices</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};



export default MenuTeeth;
