import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../../CustomCodes.redux';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import DragDropContainer from './DragDropContainer';
import useStyles from '../../CustomCodes.styles';



const getDefaultConf = (category) => ({
	symbol_abbreviation: '',
	symbol_name: '',
	symbol_description: '',
	symbol_data_type: 'text',
	symbol_category: category,
	symbol_chart_config: {
		type: 'multiselect',
		options: [
			{
				codes: [],
				label: '',
			},
		],
		attachment: 'mouth',
	},
});


const MultiSelect = () => {
	const dispatch = useDispatch(),
		  category = useSelector(state => state.customCodes.category),
		  conf     = useSelector(state => state.customCodes.conf),
		  expanded = useSelector(state => state.customCodes.multiSelectExpanded),
		  options  = conf.symbol_chart_config?.options,
		  classes  = useStyles();


	// Get the multi-select code list
	useEffect(() => {
		dispatch(actions.loadMultiSelectCodes());
	}, [ dispatch ]);

	// Set up the default configuration
	useEffect(() => {
		if(conf.symbol_name === undefined) {
			dispatch(actions.setState({ conf: getDefaultConf(category) }));
		}
	}, [ dispatch, conf, category ]);

	if(!Array.isArray(options)) {
		return null;
	}

	const handleChange = index => (e, isExpanded) => {
		dispatch(actions.setState({
			multiSelectExpanded: expanded === index ? null : index,
		}));
	};

	const setSectionLabel = index => e => {
		dispatch(actions.setSectionLabel(index, e.target.value));
	};


	const setCodeProperty = property => e => {
		dispatch(actions.setCodeProperty(property, e.target.value));
	};



	return (
		<div style={{ width: 2000 }}>
			<div className={classes.textInputWrapper}>
				<TextField
					label="Custom Code Name"
					onChange={setCodeProperty('symbol_name')}
					value={conf.symbol_name}
					inputProps={{ maxLength: 80 }}
					className={classes.textInput}
					variant="outlined"
					InputLabelProps={{ shrink: true }}
				/>
			</div>

			<div className={classes.textInputWrapper}>
				<TextField
					label="Custom Code Abbreviation"
					onChange={setCodeProperty('symbol_abbreviation')}
					value={conf.symbol_abbreviation}
					inputProps={{ maxLength: 8 }}
					variant="outlined"
					InputLabelProps={{ shrink: true }}
				/>
			</div>

			<div className={classes.textInputWrapper}>
				<TextField
					label="Custom Code Description"
					onChange={setCodeProperty('symbol_description')}
					value={conf.symbol_description}
					inputProps={{ maxLength: 1000 }}
					className={classes.textInput}
					variant="outlined"
					InputLabelProps={{ shrink: true }}
				/>
			</div>

			<div className={classes.textInputWrapper}>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => dispatch(actions.addSection())}
				>
					Add Section
				</Button>
			</div>

			<div className={classes.textInputWrapper}>
				{options.map((option, index) => {
					return (
						<Accordion expanded={expanded === index} key={index} onChange={handleChange(index)}>
							<AccordionSummary expandIcon={<ExpandMore />}>
								Section: {option.label}
							</AccordionSummary>
							<AccordionDetails>
								<div style={{ clear: 'both', marginBottom: 100 }}>
									<TextField
										label={"Section Name"}
										value={option.label}
										onChange={setSectionLabel(index)}
										style={{ clear: 'both' }}
										inputProps={{ maxLength: 50 }}
									/>
								</div>
								<div style={{ clear: 'both', marginTop: 100, width: '100%' }}>
									{expanded === index && <DragDropContainer index={index} />}
								</div>
							</AccordionDetails>
						</Accordion>
					);
				})}
			</div>

			<Button
				variant="contained"
				color="primary"
				onClick={() => dispatch(actions.handleMultiSelectSave())}
			>
				Save
			</Button>
		</div>
	);

};


export default MultiSelect;