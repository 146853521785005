import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	root: {
		//width: 200,
		//maxWidth: 200,
	},
	media: {
		height: 0,
		paddingTop: '70%',
		cursor: 'pointer',
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
}));



export default useStyles;
