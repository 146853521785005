module.exports = {
	"t110": {
		"paths": [
			"M82.7,663c-1.5-8-1.5-11.3,3.3-14.4c4.8-3.1,10.3-4.3,14.1-4.9c10.9-1.8,27.5-9.8,36.2,6.3     c3.3,6.2-9,12.3-17.3,19.1c-7.3,6-5.5,11.9-13.7,15.7c-5.6,2.7-11.7-3.3-13.9-7c-0.6-1-3-4.6-3.1-6.8c-0.1-1.1-0.7-2-1.6-2.6     C84.4,667.2,83.2,665.8,82.7,663z",
			"M104.4,659c0,0.1-0.1,3.7,2.6,5.6c-6.8-0.3-12.9,1.9-14.7,3.6c0.7-0.5-2.1-2.9-2.1-2.9     C102.7,665.8,104.4,659,104.4,659z",
			"M115.5,646.5c0,0.8,2.3,11.4,8.2,8.6c-2.1,1.9-4.4,0.9-4.4,0.9C116.9,654.7,115,649.6,115.5,646.5z"
		]
	},
	"t109": {
		"paths": [
			"M53.6,592.5c-4.2-12.5,0.7-18.6,9.4-22.2s17.8-4,24-3.9c18.1,0.2,47-8.1,56.3,20.3     c3.6,10.8-17.9,17.2-33.2,25.9c-13.4,7.6-13.2,14.8-26.4,21.5c-12,6.1-17.6-5.7-20.4-15.1c-2.8-9.5-2-14,1.2-15.7     C59.5,599.1,56.6,601.2,53.6,592.5z",
			"M90.8,590.4c-0.1,0.2-1,5.5,2.8,9.4c-10.1-2.6-26.2,3.1-26.2,3.1l-1.6-3.3C85,602.4,90.8,590.4,90.8,590.4z",
			"M111.7,572.8c-0.1,1.2,1.8,19,12,15.5c-3.7,2.7-7.4,0.8-7.4,0.8C112.7,586.5,110.5,577.7,111.7,572.8z"
		]
	},
	"t108": {
		"paths": [
			"M77.9,538.9c7.8,2,15.4,7,24.7-15.8c9.4-23.2,9.8-40.9,9.8-50.8c0-9.7,1.3-18,9.1-20.8     c4.1-1.5,4.7-8.9,3.1-12.7c-1.6-3.8-5.4-4.7-20-3.1c-10.7,0-16.5-9.4-24-2c-7,6.9-12,48.7-12,58.7     C68.6,502.4,65.9,535.8,77.9,538.9z",
			"M79.7,489.6c0,0,2.2,5.1-2.6,12.4c12.3-4.9,16.7-4.4,19.1-2.3c-0.8-3.2,0.5-4,0.5-4S84.9,497.3,79.7,489.6z"
		]
	},
	"t107": {
		"paths": [
			"M135.6,340.7c6.6-3.9,17.5,3,13,11s-3.1,10.7-5.7,14s-4.5,4.6-5.7,8.3s0.2,9.9-8.1,17s-11.7,18.5-17.7,19.6     s-13.4-2.8-14.8-7.8s3.4-5.7,3.8-12.8c0.4-7,5-21.4,12.7-29.9C120.7,351.8,131.3,343.3,135.6,340.7z",
			"M101.7,380.3v1.5v5.3c0,0,8.6,5,14.5,3.2C108.4,387.4,103.8,387.8,101.7,380.3z"
		]
	},
	"t106": {
		"paths": [
			"M170.5,286.9c1.2,4-4.3,22.9-10.4,26.4s-13.9,4.3-14.6-0.9c-0.7-5.2-1.4-11.7-3.5-16     c-2.1-4.4-2.1-20.4,6.1-28.2c8.2-7.8,10.1-14,10.3-16.5c0.3-2.5,14.6-4.9,15.9,4.2c1.3,9.1-1.8,14.8-1.8,17.1     S168.6,280.6,170.5,286.9z",
			"M148.2,294.6l0.2,1.2l0.6,4.4c0,0,7.7,3.3,12.4,1.1C154.6,299.8,150.8,300.6,148.2,294.6z"
		]
	},
	"t105": {
		"paths": [
			"M160.1,201.2c3.8-5.8,14.4,7.2,15.1,12.2s-1.8,1.4-1.1,4.7c0.7,3.2,3.6,9.3-2.2,11.1     c-5.7,1.8-14.7-1.4-16.2-6.1S157.2,205.6,160.1,201.2z"
		]
	},
	"t104": {
		"paths": [
			"M133.8,86.2c-6.3,5.3,5.8,27.4,9.8,36.6c4.7,11,7.2,43.9,19.4,49.7c9.8,4.7,22.6-5,25.9-17.2     c3.3-12.3,5.1-26.1-19.9-50.5C144.1,80.3,133.8,86.2,133.8,86.2z",
			"M144,91.2c0,0,43,23.3,42.9,57.2C182.2,113.8,144,91.2,144,91.2z"
		]
	},
	"t103": {
		"paths": [
			"M209.5,61c0-2.3-2.1-4.1-4.8-4.1c-0.5,0-1,0.1-1.5,0.2c-2-2.1-4.6-3.5-7.5-4.4c-6.7-1.9-22-11-26.8-0.5     c-1.7,3.7,10.5,10.3,10.8,14.1c0.2,3.8,0.9,21.7,11.7,24.4c10.8,2.6,18.1-5.7,16.6-22.5c-0.1-1.4-0.4-2.6-0.7-3.8     C208.6,63.7,209.5,62.5,209.5,61z"
		]
	},
	"t102": {
		"paths": [
			"M221.4,30.3c-0.1-9.9,7.8-11.5,9-11.2s9.8-2,13.7,6.1c3.9,8.1,3.9,3,1.9,9c-1.5,4.6,1.5,7.4-0.2,11.8     s-7.3,9.9-18.9,7.8c-6.9-14.4-7.6-11.3-8.6-13.3S216.9,30,221.4,30.3z"
		]
	},
	"t101": {
		"paths": [
			"M266.8,17.5c1.4-8.6,8.6-8.9,9.6-8.4s8.9-0.2,11,7.4c2.2,7.7,4.1,4.1,0.2,8.1c-3.8,4.1,1.3,5.4-0.9,9     c-2.2,3.6-7.9,8.9-17.7,5.3c-3.8-13.6-6.1-11.1-6.5-13C262,24,262.9,16.6,266.8,17.5z"
		]
	},
	"t210": {
		"paths": [
			"M525.2,663c1.5-8,1.5-11.3-3.3-14.4c-4.8-3.1-10.3-4.3-14.1-4.9c-10.9-1.8-27.5-9.8-36.2,6.3     c-3.3,6.2,9,12.3,17.3,19.1c7.3,6,5.5,11.9,13.7,15.7c5.6,2.7,11.7-3.3,13.9-7c0.6-1,3-4.6,3.1-6.8c0.1-1.1,0.7-2,1.6-2.6     C523.4,667.2,524.7,665.8,525.2,663z",
			"M503.4,659c0,0.1,0.1,3.7-2.6,5.6c6.8-0.3,12.9,1.9,14.7,3.6c-0.7-0.5,2.1-2.9,2.1-2.9     C505.2,665.8,503.4,659,503.4,659z",
			"M492.3,646.5c0,0.8-2.3,11.4-8.2,8.6c2.1,1.9,4.4,0.9,4.4,0.9C490.9,654.7,492.8,649.6,492.3,646.5z"
		]
	},
	"t209": {
		"paths": [
			"M554.2,592.5c4.2-12.5-0.7-18.6-9.4-22.2c-8.7-3.6-17.8-4-24-3.9c-18.1,0.2-47-8.1-56.3,20.3     c-3.6,10.8,17.9,17.2,33.2,25.9c13.4,7.6,13.2,14.8,26.4,21.5c12,6.1,17.6-5.7,20.4-15.1c2.8-9.5,2-14-1.2-15.7     C548.4,599.1,551.3,601.2,554.2,592.5z",
			"M517,590.4c0.1,0.2,1,5.5-2.8,9.4c10.1-2.6,26.2,3.1,26.2,3.1l1.6-3.3C522.9,602.4,517,590.4,517,590.4z",
			"M496.1,572.8c0.1,1.2-1.8,19-12,15.5c3.7,2.7,7.4,0.8,7.4,0.8C495.2,586.5,497.4,577.7,496.1,572.8z"
		]
	},
	"t208": {
		"paths": [
			"M529.9,538.9c-7.8,2-15.4,7-24.7-15.8c-9.4-23.2-9.8-40.9-9.8-50.8c0-9.7-1.3-18-9.1-20.8     c-4.1-1.5-4.7-8.9-3.1-12.7c1.6-3.8,5.4-4.7,20-3.1c10.7,0,16.5-9.4,24-2c7,6.9,12,48.7,12,58.7     C539.3,502.4,542,535.8,529.9,538.9z",
			"M528.1,489.6c0,0-2.2,5.1,2.6,12.4c-12.3-4.9-16.7-4.4-19.1-2.3c0.7-3.2-0.5-4-0.5-4S522.9,497.3,528.1,489.6     z"
		]
	},
	"t207": {
		"paths": [
			"M472.2,340.7c-6.6-3.9-17.5,3-13,11s3.1,10.7,5.7,14c2.6,3.3,4.5,4.6,5.7,8.3c1.2,3.8-0.2,9.9,8.1,17     c8.3,7.1,11.7,18.5,17.7,19.6s13.4-2.8,14.8-7.8c1.4-5-3.4-5.7-3.8-12.8c-0.4-7-5-21.4-12.7-29.9     C487.1,351.8,476.5,343.3,472.2,340.7z",
			"M506.2,380.3v1.5v5.3c0,0-8.6,5-14.5,3.2C499.4,387.4,504.1,387.8,506.2,380.3z"
		]
	},
	"t206": {
		"paths": [
			"M437.3,286.9c-1.2,4,4.3,22.9,10.4,26.4s13.9,4.3,14.6-0.9c0.7-5.2,1.4-11.7,3.5-16s2.1-20.4-6.1-28.2     c-8.2-7.8-10.1-14-10.3-16.5c-0.3-2.5-14.6-4.9-15.9,4.2c-1.3,9.1,1.8,14.8,1.8,17.1S439.2,280.6,437.3,286.9z",
			"M459.6,294.6l-0.2,1.2l-0.6,4.4c0,0-7.7,3.3-12.4,1.1C453.2,299.8,457,300.6,459.6,294.6z"
		]
	},
	"t205": {
		"paths": [
			"M447.7,201.2c-3.8-5.8-14.4,7.2-15.1,12.2s1.8,1.4,1.1,4.7c-0.7,3.2-3.6,9.3,2.2,11.1     c5.8,1.8,14.7-1.4,16.2-6.1C453.5,218.5,450.6,205.6,447.7,201.2z"
		]
	},
	"t204": {
		"paths": [
			"M474,86.2c6.3,5.3-5.8,27.4-9.8,36.6c-4.7,11-7.2,43.9-19.4,49.7c-9.8,4.7-22.6-5-25.9-17.2     s-5.1-26.1,19.9-50.5S474,86.2,474,86.2z",
			"M463.8,91.2c0,0-43,23.3-42.9,57.2C425.7,113.8,463.8,91.2,463.8,91.2z"
		]
	},
	"t203": {
		"paths": [
			"M398.4,61c0-2.3,2.1-4.1,4.8-4.1c0.5,0,1,0.1,1.5,0.2c2-2.1,4.6-3.5,7.5-4.4c6.7-1.9,22-11,26.8-0.5     c1.7,3.7-10.5,10.3-10.8,14.1c-0.2,3.8-0.9,21.7-11.7,24.4c-10.8,2.6-18.1-5.7-16.6-22.5c0.1-1.4,0.4-2.6,0.7-3.8     C399.2,63.7,398.4,62.5,398.4,61z"
		]
	},
	"t202": {
		"paths": [
			"M386.5,30.3c0.1-9.9-7.8-11.5-9-11.2c-1.2,0.3-9.8-2-13.7,6.1s-3.9,3-1.9,9c1.5,4.6-1.5,7.4,0.2,11.8     c1.7,4.4,7.3,9.9,18.9,7.8c6.9-14.4,7.6-11.3,8.6-13.3C390.5,38.5,390.9,30,386.5,30.3z"
		]
	},
	"t201": {
		"paths": [
			"M341.1,17.5c-1.4-8.6-8.6-8.9-9.6-8.4s-8.9-0.2-11,7.4c-2.2,7.7-4.1,4.1-0.2,8.1c3.8,4.1-1.3,5.4,0.9,9     c2.2,3.6,7.9,8.9,17.7,5.3c3.8-13.6,6.1-11.1,6.5-13C345.8,24,344.9,16.6,341.1,17.5z"
		]
	},
	"t411": {
		"paths": [
			"M95.5,833.1c10.2,3.4,24.9-11.3,26.2,10.3c1,16-5.2,19.6-14.2,20.2s-15.5,0.6-18.3-13.3     C82.5,833.1,95.5,833.1,95.5,833.1z"
		]
	},
	"t410": {
		"paths": [
			"M82.8,898.5c-6.6-10.8-2.9-20.8,2.1-24.3c5.6-4,11.5-3.4,18,1.2c11.2-3.9,14.1-1.7,19.7,7.6     c4.3,6.4,0.2,13.4-2.3,23.4c-4.4,17.9-8.8,15.6-18.2,16.3C92.8,923.3,78.1,915.8,82.8,898.5z",
			"M97.7,894.6c3,1.5,11.7,3,14.9-1.3c-1.5,2.5-1,4.3-0.1,4.9c-3.1-1.6-13.2,0-14.6,1.7     C98.6,898.9,100.8,897.9,97.7,894.6z"
		]
	},
	"t409": {
		"paths": [
			"M150.2,1020.4c-4.7-8.8-1.5-23.8-7.3-23.8c-2.8-17.1-6.9-22.8-8-25.9c-1.5-4.3-5.7-8.2-6.1-24.1     c-0.4-15.8-10.7-15.3-20.7-15.3c0,0-10.1,0-15.2,5.1c-5.1,5.2-4.1,31.5-1.8,43.9c2.2,12.4,11.7,29.7,14.3,37     c2.6,7.4,11,19.4,20.4,21.8c8,2.1,10.6,1.1,19.1-1.9C153.7,1034.2,152.2,1024.2,150.2,1020.4z",
			"M123.1,1009.9c6.5,0.3,14.1,0.1,15.1-1.6c0.8,3.3-0.9,3.9,1.1,5.2c-10.3-1.9-14.9-3-15.6-2.5     C122.9,1011.6,123.1,1009.9,123.1,1009.9z",
			"M94.3,960.2c0,0,22.1,5.1,29.1-2.9c-0.2,3.8,1.8,4.1,1.8,4.1s-28,1.4-30.8,8.5     C94.8,965.3,94.3,960.2,94.3,960.2z",
			"M106.1,931.1l4.5-0.1l-2.7,5.6C107.9,936.6,109.8,933.9,106.1,931.1z"
		]
	},
	"t408": {
		"paths": [
			"M130.7,1048.8c6.3-3.1,9.7-1.6,12.3,1.7c2.6,3.3,12.3,30.5,13,35.2c0.6,4.7-0.1,13.5,0.6,17.3     c0.8,3.8,3.9,14.2,3,18.7c-0.8,4.5,0.7,10.3-7.7,12.9c-8.4,2.6-13.8-2.2-13.8-9c-4.9-9.5-15-27-14.8-34.5s3-19,1.7-28.5     C123.8,1053.1,126,1051.1,130.7,1048.8z",
			"M125.4,1064l0.3,5c0,0,8.8-4.5,12-4C137.7,1065.1,134.1,1062.5,125.4,1064z"
		]
	},
	"t407": {
		"paths": [
			"M150,1211.3c-3.1-8.4-9.5-21.1-9.3-29.2c0.3-8.2,3.9-16.4,4.4-21.8c0.5-5.3,0.6-9.9,2.8-12     c2.2-2.1,14.8-6.8,17.8,0.6c3,7.4,4,12.4,5.3,16.4s3,18,1.1,21.3c-1.9,3.3,1.6,18.4-0.3,24.5s-5.3,11-12.4,10     C152.4,1220,151.2,1217.7,150,1211.3z",
			"M145.2,1159.3l-0.6,4.6c0,0,8.8-2.5,11.6-1.5C156.2,1162.5,153.3,1159.5,145.2,1159.3z"
		]
	},
	"t406": {
		"paths": [
			"M163.6,1237.5c2.7-3.6,6.1-4.1,11.3-4c5.2,0.1,6.6,2.6,9.6,10.4c3,7.8,2.3,13.5,3.3,16.2s3.1,8.1,3,11.9     c-0.1,3.8,1.7,9.1-5.3,12.3c-7,3.2-10.6,3-12.9,0.5c-2.2-2.5,0.2-3.4-2-6.7c-2.3-3.2-8.3-13-9.8-19.1     C159.2,1253,161.5,1241.6,163.6,1237.5z",
			"M172.9,1243.7l-0.7,2.5c0,0,5.1-0.6,6.5,0.2C178.7,1246.4,177.4,1244.5,172.9,1243.7z"
		]
	},
	"t405": {
		"paths": [
			"M188.3,1296.7c4-4.1,11.3-0.5,12.2,12.7c0.9,13.2-2.5,15-9.4,15.9c-6.9,1-15.6-3.9-14.9-11.7     S183.1,1302.1,188.3,1296.7z"
		]
	},
	"t404": {
		"paths": [
			"M189.3,1357.1c9.1-0.1,17.2,10.3,17.6,24.5s-0.6,19.4-5.4,23.4c-4.8,4.1-27.5,14.3-42.1,15.2     c-14.9,0.8-21.6-0.5-21.8-5.3c-0.2-4.8,3.8-13,13.4-17.2c9.6-4.3,16.8-17.1,18.2-21.8C170.8,1371.1,174.9,1357.2,189.3,1357.1z",
			"M201.1,1385c0,0,5.9,18-34.3,27.2C211,1404.7,201.1,1385,201.1,1385z"
		]
	},
	"t403": {
		"paths": [
			"M179.4,1438.9c-0.7-4.9,13.5-14.8,17.9-16c3.7-5.6,4.9-6.4,7-9.9c7.2-0.6,20.4,4.3,18.2,13     c0,0-2.6,12.1-11.6,14.5C197.3,1444.9,180.5,1446.4,179.4,1438.9z"
		]
	},
	"t402": {
		"paths": [
			"M241.6,1423.8c8.2,2.6,16.2,5.9,16,10.7c-0.2,4.8-0.9,6.7,0.2,9.6c-3.7,5.3-2.5,5.6-5.2,7.9     c-2.7,2.3-10.5,7.1-15.2,1.6s-3.8-8.6-5.4-11.3c-1.5-2.7-3-2.1-1.3-6.8C232.4,1430.7,238.4,1430.8,241.6,1423.8z"
		]
	},
	"t401": {
		"paths": [
			"M278.3,1433.1c8.3,0.1,15.9,3.7,16.2,8.2c0.3,4.5,0.2,5.3,1.3,9c-2.8,5.4-1.7,5.6-4,8     c-2.3,2.4-9,7.8-14.1,3.2s-4.6-7.6-6.3-10s-3.1-1.6-2-6.2C270.5,1440.7,273.5,1438.7,278.3,1433.1z"
		]
	},
	"t311": {
		"paths": [
			"M512.3,833.1c-10.2,3.4-24.9-11.3-26.2,10.3c-1,16,5.2,19.6,14.2,20.2s15.5,0.6,18.3-13.3     C525.3,833.1,512.3,833.1,512.3,833.1z"
		]
	},
	"t310": {
		"paths": [
			"M525,898.5c6.6-10.8,2.9-20.8-2.1-24.3c-5.6-4-11.5-3.4-18,1.2c-11.2-3.9-14.1-1.7-19.7,7.6     c-4.3,6.4-0.2,13.4,2.3,23.4c4.4,17.9,8.8,15.6,18.2,16.3C515,923.3,529.7,915.8,525,898.5z",
			"M510.1,894.6c-3,1.5-11.7,3-14.9-1.3c1.5,2.5,1,4.3,0.1,4.9c3.1-1.6,13.2,0,14.6,1.7     C509.3,898.9,507,897.9,510.1,894.6z"
		]
	},
	"t309": {
		"paths": [
			"M457.6,1020.4c4.7-8.8,1.5-23.8,7.3-23.8c2.8-17.1,6.9-22.8,8-25.9c1.5-4.3,5.7-8.2,6.1-24.1     c0.4-15.8,10.7-15.3,20.7-15.3c0,0,10.1,0,15.2,5.1c5.1,5.2,4.1,31.5,1.8,43.9c-2.2,12.4-11.7,29.7-14.3,37     c-2.6,7.4-11,19.4-20.4,21.8c-8,2.1-10.6,1.1-19.1-1.9C454.1,1034.2,455.6,1024.2,457.6,1020.4z",
			"M484.7,1009.9c-6.5,0.3-14.1,0.1-15.1-1.6c-0.8,3.3,0.9,3.9-1.1,5.2c10.3-1.9,14.9-3,15.6-2.5     C484.9,1011.6,484.7,1009.9,484.7,1009.9z",
			"M513.5,960.2c0,0-22.1,5.1-29.1-2.9c0.2,3.8-1.8,4.1-1.8,4.1s28,1.4,30.8,8.5     C513,965.3,513.5,960.2,513.5,960.2z",
			"M501.7,931.1l-4.5-0.1l2.7,5.6C499.9,936.6,498,933.9,501.7,931.1z"
		]
	},
	"t308": {
		"paths": [
			"M477.1,1048.8c-6.3-3.1-9.7-1.6-12.3,1.7c-2.6,3.3-12.3,30.5-13,35.2c-0.6,4.7,0.1,13.5-0.6,17.3     c-0.8,3.8-3.9,14.2-3,18.7c0.8,4.5-0.7,10.3,7.7,12.9s13.8-2.2,13.8-9c4.9-9.5,15-27,14.8-34.5c-0.2-7.5-3-19-1.7-28.5     C484,1053.1,481.8,1051.1,477.1,1048.8z",
			"M482.4,1064l-0.3,5c0,0-8.8-4.5-12-4C470.1,1065.1,473.7,1062.5,482.4,1064z"
		]
	},
	"t307": {
		"paths": [
			"M457.9,1211.3c3.1-8.4,9.5-21.1,9.3-29.2c-0.3-8.2-3.9-16.4-4.4-21.8c-0.5-5.3-0.6-9.9-2.8-12     c-2.2-2.1-14.8-6.8-17.8,0.6c-3,7.4-4,12.4-5.3,16.4c-1.3,4-3,18-1.1,21.3c1.9,3.3-1.6,18.4,0.3,24.5c1.9,6.1,5.3,11,12.4,10     C455.4,1220,456.6,1217.7,457.9,1211.3z",
			"M462.7,1159.3l0.6,4.6c0,0-8.8-2.5-11.6-1.5C451.7,1162.5,454.5,1159.5,462.7,1159.3z"
		]
	},
	"t306": {
		"paths": [
			"M444.3,1237.5c-2.7-3.6-6.1-4.1-11.3-4c-5.2,0.1-6.6,2.6-9.6,10.4c-3,7.8-2.3,13.5-3.3,16.2s-3.1,8.1-3,11.9     c0.1,3.9-1.7,9.1,5.3,12.3c7,3.2,10.6,3,12.9,0.5c2.2-2.5-0.2-3.4,2-6.7c2.3-3.2,8.3-13,9.8-19.1     C448.6,1253,446.3,1241.6,444.3,1237.5z",
			"M434.9,1243.7l0.7,2.5c0,0-5.1-0.6-6.5,0.2C429.2,1246.4,430.4,1244.5,434.9,1243.7z"
		]
	},
	"t305": {
		"paths": [
			"M419.6,1296.7c-4-4.1-11.3-0.5-12.2,12.7c-0.9,13.2,2.5,15,9.4,15.9c6.9,0.9,15.6-3.9,14.9-11.7     C431.1,1305.9,424.8,1302.1,419.6,1296.7z"
		]
	},
	"t304": {
		"paths": [
			"M418.5,1357.1c-9.1-0.1-17.2,10.3-17.6,24.5c-0.4,14.2,0.6,19.4,5.4,23.4c4.8,4.1,27.5,14.3,42.1,15.2     c14.9,0.8,21.6-0.5,21.8-5.3c0.2-4.8-3.8-13-13.4-17.2c-9.6-4.3-16.8-17.1-18.2-21.8C437,1371.1,432.9,1357.2,418.5,1357.1z",
			"M406.7,1385c0,0-5.9,18,34.3,27.2C396.9,1404.7,406.7,1385,406.7,1385z"
		]
	},
	"t303": {
		"paths": [
			"M428.4,1438.9c0.7-4.9-13.5-14.8-17.9-16c-3.7-5.6-4.9-6.4-7-9.9c-7.2-0.6-20.4,4.3-18.2,13     c0,0,2.6,12.1,11.6,14.5C410.5,1444.9,427.4,1446.4,428.4,1438.9z"
		]
	},
	"t302": {
		"paths": [
			"M366.2,1423.8c-8.2,2.6-16.2,5.9-16,10.7c0.2,4.8,0.9,6.7-0.2,9.6c3.7,5.3,2.5,5.6,5.2,7.9     c2.8,2.2,10.5,7.1,15.2,1.6c4.7-5.5,3.8-8.6,5.4-11.3c1.5-2.7,3-2.1,1.3-6.8C375.4,1430.8,369.5,1430.8,366.2,1423.8z"
		]
	},
	"t301": {
		"paths": [
			"M329.6,1434.1c-8.3,0.1-15.9,3.7-16.2,8.2s-0.2,5.3-1.3,9c2.8,5.4,1.7,5.6,4,8s9,7.8,14.1,3.2     c5.1-4.7,4.6-7.6,6.3-10c1.7-2.4,3.1-1.6,2-6.2S334.3,1439.7,329.6,1434.1z"
		]
	}
};