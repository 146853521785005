import React, { useEffect, useState } from 'react';
import {
	Button,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank as EmptyCheck, Clear, Search, Settings } from "@mui/icons-material";
import useStyles from './SymbolTest.styles';
import api from "api";
import clsx from "clsx";
import { List as VirtualList } from 'react-virtualized';
import FileManager from "components/FileManager";



const SymbolTest = () => {
	const classes                                         = useStyles(),
		  [ tab, setTab ]                                 = useState('diagnostics'),
		  [ tooth, setTooth ]                             = useState('t104'),
		  [ mode, setMode ]                               = useState(null),
		  [ search, setSearch ]                           = useState(''),
		  [ symbols, setSymbols ]                         = useState({ diagnostics: [], treatments: [] }),
		  [ filteredSymbols, setFilteredSymbols ]         = useState([]),
		  [ settingsAnchor, setSettingsAnchor ]           = useState(null),
		  [ requireConfirmation, setRequireConfirmation ] = useState(false),
		  [ displayMode, setDisplayMode ]                 = useState('list'),
		  [ searchName, setSearchName ]                   = useState(true),
		  [ selectedCode, setSelectedCode ]               = useState(),
		  [ defaultCodes ]                                = useState([
			  '501', '490', '235', '357', '306', '307',
			  '55', '7', '221', '222', '224', '148',
		  ]);



	const getSymbols = async () => {
		let loaded = await api.get(`resources/charting-symbols`);
		setSymbols(loaded.data);
	};


	const rowRenderer = ({ key, index, style }) => {
		if(displayMode === 'compact') {
			let color = tab === 'diagnostics' ? 'primary' : 'secondary',
				idx   = index * 3;

			const rows = [
				filteredSymbols[ idx ],
				filteredSymbols[ idx + 1 ],
				filteredSymbols[ idx + 2 ],
			].filter(row => row !== undefined);

			return (
				<div className={classes.compactModeRow} key={key} style={style}>
					{rows.map(row => (
						<Button
							key={row.symbol_id}
							className={classes.compactModeButton}
							variant={(requireConfirmation && selectedCode === row.symbol_id) ? 'contained' : 'outlined'}
							color={color}
							onClick={() => {
								if(requireConfirmation) {
									setSelectedCode(row.symbol_id);
								}
							}}
						>
							{row.symbol_abbreviation}
						</Button>
					))}
				</div>
			);
		}

		const row = filteredSymbols[ index ];

		return (
			<ListItem button key={key} style={style}>
				<ListItemText
					primary={row.symbol_abbreviation}
					secondary={row.symbol_name}
				/>
			</ListItem>
		);
	};


	const handleSettingsMenuClose = () => {
		setSettingsAnchor(null);
	};

	useEffect(() => {
		let filteredSymbols;

		if(!search) {
			filteredSymbols = symbols[ tab ]
				.filter(row => row.map_to === mode)
				.filter(row => defaultCodes.includes(row.symbol_id));
		}

		if(search && searchName) {
			filteredSymbols = symbols[ tab ]
				.filter(row => (row.map_to === mode))
				.filter(row => row.symbol_abbreviation.toUpperCase().includes(search.toUpperCase()) || row.symbol_name.toUpperCase().includes(search.toUpperCase()))
				.sort((a, b) => a.symbol_abbreviation.localeCompare(b.symbol_abbreviation, 'en', { ignorePunctuation: true }));
		} else if(search && !searchName) {
			filteredSymbols = symbols[ tab ]
				.filter(row => (row.map_to === mode))
				.filter(row => row.symbol_abbreviation.toUpperCase().includes(search.toUpperCase()))
				.sort((a, b) => a.symbol_abbreviation.localeCompare(b.symbol_abbreviation, 'en', { ignorePunctuation: true }));
		}

		setFilteredSymbols(filteredSymbols);
	}, [ search, symbols, tab, mode, setFilteredSymbols, searchName, setSearchName, defaultCodes ]);

	return (
        <div>
			<div className={classes.buttonContainer}>
				<Button
					onClick={getSymbols}
				>
					Load Symbols
				</Button>
				<Button
					onClick={() => {
						setTooth('t104');
						setMode('tooth');
					}}
				>
					Tooth Mode (t104)
				</Button>
				<Button onClick={() => setMode('mouth')}>
					Mouth Mode
				</Button>
			</div>

			{mode !== null &&
				<>
					<div className={classes.container}>
						<Paper elevation={3} style={{ height: '100%' }}>
							<div className={clsx(classes.contentContainer, classes.centerText)}>
								<Typography variant="h6">
									{mode === 'mouth' && 'Mouth'}
									{mode === 'tooth' && `Tooth ${tooth.substr(1)}`}
								</Typography>
							</div>

							<Tabs
								variant="fullWidth"
								value={tab}
								indicatorColor={tab === 'diagnostics' ? 'primary' : 'secondary'}
								textColor={tab === 'diagnostics' ? 'primary' : 'secondary'}
								onChange={(e, newValue) => setTab(newValue)}

							>
								<Tab label="Diagnostics" value="diagnostics" />
								<Tab label="Treatments" value="treatments" />
							</Tabs>


							<div className={classes.contentContainer}>
								<TextField
									fullWidth
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									placeholder={"Search All Codes"}
									margin="dense"
									variant={"outlined"}
									autoFocus
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<Search />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton component="span" onClick={() => setSearch('')} size="large">
													<Clear />
												</IconButton>

												<IconButton
                                                    component="span"
                                                    onClick={(e) => setSettingsAnchor(e.currentTarget)}
                                                    size="large">
													<Settings />
												</IconButton>
											</InputAdornment>
										),
									}}
								/>

								<Menu
									anchorEl={settingsAnchor}
									keepMounted
									open={Boolean(settingsAnchor)}
									onClose={handleSettingsMenuClose}
								>
									<MenuItem
										onClick={() => {
											setRequireConfirmation(false);
											setDisplayMode('list');
											handleSettingsMenuClose();
										}}
									>
										<ListItemIcon>
											{(displayMode === 'list')
											 ? <CheckBox />
											 : <EmptyCheck />
											}
										</ListItemIcon>
										<ListItemText>List Mode</ListItemText>
									</MenuItem>

									<MenuItem
										onClick={() => {
											setRequireConfirmation(false);
											setDisplayMode('compact');
											handleSettingsMenuClose();
										}}
									>
										<ListItemIcon>
											{(displayMode === 'compact' && !requireConfirmation)
											 ? <CheckBox />
											 : <EmptyCheck />
											}
										</ListItemIcon>
										<ListItemText>Compact Mode</ListItemText>
									</MenuItem>

									<MenuItem
										onClick={() => {
											setRequireConfirmation(true);
											setDisplayMode('compact');
											handleSettingsMenuClose();
										}}
									>
										<ListItemIcon>
											{(displayMode === 'compact' && requireConfirmation)
											 ? <CheckBox />
											 : <EmptyCheck />
											}
										</ListItemIcon>
										<ListItemText>Compact Mode (with confirmation)</ListItemText>

									</MenuItem>

									<MenuItem
										onClick={() => {
											setSearchName(!searchName);
											handleSettingsMenuClose();
										}}
									>
										<ListItemIcon>{searchName ? <CheckBox /> : <EmptyCheck />}</ListItemIcon>
										<ListItemText>Search by Name</ListItemText>
									</MenuItem>
								</Menu>
							</div>


							<div className={clsx(classes.contentContainer, classes.symbolContainer)}>
								<List>
									<VirtualList
										height={390}
										rowHeight={60}
										width={387}
										rowCount={displayMode === 'compact'
												  ? Math.ceil(filteredSymbols.length / 3)
												  : filteredSymbols.length
										}
										rowRenderer={rowRenderer}
									>
									</VirtualList>

								</List>
							</div>

						</Paper>
					</div>
				</>
			}

			<FileManager />

		</div>
    );
};


export default SymbolTest;
