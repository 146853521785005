import React, { useCallback } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../PatientView.redux';



const IsNeutered = () => {
	const dispatch = useDispatch(),
		  value    = useSelector(state => (state.patientView.profile.is_neutered));


	const setProfileValue = useCallback((e) => {
		dispatch(actions.setProfileValue('is_neutered', niceToValue(e.target.value)));
	}, [ dispatch ]);

	return (
		<FormControl fullWidth>
			<Select
				value={valueToNice(value)}
				onChange={setProfileValue}
				variant="outlined"
			>
				<MenuItem value={'Unknown'}>Not Set/Unknown</MenuItem>
				<MenuItem value={'Yes'}>Yes</MenuItem>
				<MenuItem value={'No'}>No</MenuItem>
			</Select>
		</FormControl>
	);
};


function valueToNice(value) {
	switch(value) {
		case false:
			return 'No';
		case true:
			return 'Yes';
		default:
			return 'Unknown';
	}
}


function niceToValue(value) {
	switch(value) {
		case 'No':
			return false;
		case 'Yes':
			return true;
		default:
			return null;
	}
}

export default IsNeutered;
