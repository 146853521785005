import React, { useEffect, useRef, useState } from 'react';
import useStyles from './Login.styles';
import {
    Button,
    Card,
    FormControl,
    FormHelperText,
    Grid,
    Hidden,
    IconButton,
    Input,
    InputAdornment,
    Link,
    Typography,
} from "@mui/material";
import { Email, Lock, LockOpen, Visibility, VisibilityOff } from "@mui/icons-material";
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { actions } from "store";
import classnames from 'classnames';
import api from 'api';
import Cookies from 'js-cookie';



const Login = () => {
    const classes  = useStyles(),
          formRef  = useRef(null),
          dispatch = useDispatch();

    const [ showPassword, setShowPassword ] = useState(false),
          [ email, setEmail ]               = useState(''),
          [ password, setPassword ]         = useState(''),
          [ errors, setErrors ]             = useState({ email: false, password: false }),
          [ loginError, setLoginError ]     = useState(null),
          [ loggingIn, setLoggingIn ]       = useState(false),
          [
              finishedValidation,
              setFinishedValidation
          ]                                 = useState(false);


    const handleToggleShowPassword = e => {
        setShowPassword(!showPassword);
    };


    const handleChangePassword = e => {
        setPassword(e.target.value);
    };


    const handleChangeEmail = e => {
        setEmail(e.target.value);
    };


    const handleSubmit = e => {
        e.preventDefault();
        console.log(!email, !password);

        setLoginError(null);


        setErrors({
            email: !Boolean(email),
            password: !Boolean(password),
        });

        if(email && password) {
            setLoggingIn(true);
            api.post('/auth/login', {
                user: email,
                password,
            }).then(res => {
                setLoggingIn(false);

                dispatch(actions.auth.setState({
                    showLoginPage: false,
                    showClinicSelection: res.data.showClinicSelection,
                    clinics: res.data.clinics,
                }));

            }).catch(err => {
                console.error(err.response);
                setLoginError('Invalid username or password');
                setLoggingIn(false);
            });
        }
    };


    useEffect(() => {
        let token = Cookies.get('authToken');

        if(token) {
            setLoggingIn(true);
            api.get('/auth/validate-token').then(res => {
                dispatch(actions.auth.setState({
                    showLoginPage: false,
                    showClinicSelection: res.data.showClinicSelection,
                    clinics: res.data.clinics,
                }));
            }).catch(err => {
                setLoggingIn(false);
                Cookies.set('authToken', '');
                setLoginError('Session expired, please log in again.');
                setFinishedValidation(true);
            });
        } else {
            setFinishedValidation(true);
        }


    }, [ setLoggingIn, setLoginError, dispatch, setFinishedValidation ]);


    if(!finishedValidation) {
        return null;
    }


    return (
        <div className={classes.root}>
            <div className={classes.child}>
                <Grid container spacing={0} className={classes.fullHeightWidth}>
                    <Hidden mdDown>
                        <Grid item md={5} className={classes.leftCardWrapper}>
                            <Card className={classes.leftCard} raised>
                                <div className={classes.leftCardInner}>
                                    <div>
                                        <LockOpen className={classes.leftCardIcon} />
                                    </div>
                                    <div>
                                        <Typography variant="h4" className={classes.marginBottom}>Login</Typography>
                                    </div>
                                    <div>
                                        <Typography>
                                            Please log in to your account using the form to the right. If you have
                                            forgotten your password, please click <Link
                                            component={RouterLink}
                                            to="/forgot-password"
                                            color="inherit"
                                        >here</Link>.
                                        </Typography>
                                    </div>

                                </div>
                            </Card>
                        </Grid>
                    </Hidden>


                    <Grid item xs={12} md={7} className={classes.rightCardWrapper}>
                        <Card className={classes.rightCard} raised>
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <FormControl fullWidth margin="normal" className={classes.inputs}>
                                    <Input
                                        disabled={loggingIn}
                                        type="text"
                                        placeholder="Email Address"
                                        onChange={handleChangeEmail}
                                        value={email}
                                        error={errors.email}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Email />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText error>{errors.email && 'Email address is required'}</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth margin="normal" className={classes.inputs}>
                                    <Input
                                        disabled={loggingIn}
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Password"
                                        value={password}
                                        onChange={handleChangePassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={handleToggleShowPassword}
                                                    size="large">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText error>{errors.password && 'Password is required'}</FormHelperText>
                                </FormControl>

                                <FormControl fullWidth margin="normal">

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.inputs}
                                        type="submit"
                                        disabled={loggingIn}
                                    >
                                        Log In
                                    </Button>
                                </FormControl>
                            </form>

                            {loginError !== null &&
                                <div className={classnames(classes.inputs, classes.error)}>
                                    {loginError}
                                </div>
                            }

                            <Hidden mdUp>
                                <Link
                                    component={RouterLink}
                                    to="/forgot-password"
                                    color="inherit"
                                >
                                    Forgot password?
                                </Link>
                            </Hidden>


                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

};


export default Login;
