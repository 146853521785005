import React from 'react';
import { useSelector } from "react-redux";



const PerioToothNumber = () => {
	const activeTooth = useSelector(state => state.chart.probeOptions.activeTooth);



	return (
		<div>
			Tooth {activeTooth.substr(1)}
		</div>
	);
};


export default PerioToothNumber;