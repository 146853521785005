import makeStyles from '@mui/styles/makeStyles';
import { pink, green, cyan, blue, deepOrange, indigo, amber, purple, deepPurple } from '@mui/material/colors';



const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		flex: `0 0 ${drawerWidth}px`,
		background: theme.palette.grey[ 50 ],
		height: '100%',
	},
	secondaryColor: {
		backgroundColor: cyan[ 600 ],
		color: '#ffffff',
	},
	pinkAvatar: {
		color: '#ffffff',
		backgroundColor: pink[ 500 ],
	},
	amberAvatar: {
		color: '#ffffff',
		backgroundColor: amber[ 700 ],
	},
	greenAvatar: {
		color: '#ffffff',
		backgroundColor: green[ 500 ],
	},
	patientsAvatar: {
		color: '#ffffff',
		backgroundColor: '#68d554',
	},
	blueAvatar: {
		color: '#ffffff',
		backgroundColor: blue[ 500 ],
	},
	orangeAvatar: {
		color: '#ffffff',
		backgroundColor: deepOrange[ 500 ],
	},
	indigoAvatar: {
		color: '#ffffff',
		backgroundColor: indigo[ 500 ],
	},
	purpleAvatar: {
		color: '#ffffff',
		backgroundColor: purple[ 500 ],
	},
	deepPurpleAvatar: {
		color: '#ffffff',
		backgroundColor: deepPurple[ 500 ],
	},
	menuItem: {
		'&:hover': {
			backgroundColor: theme.palette.grey[ 300 ],
			cursor: 'pointer',
		},
	},
	menuActive: {
		backgroundColor: theme.palette.grey[ 300 ],
	},
	menuText: {
		marginLeft: theme.spacing(2),
	},
}));



export default useStyles;
