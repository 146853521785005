import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
    root: {
        background: '#323232',
        height: '100%',
        overflowX: 'hidden',
        color: '#e0e0e0',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    link: {
        color: '#e0e0e0',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.1)',
        },
    },
}));

export default useStyles;