import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, List, Typography } from "@mui/material";
import useStyles from "./UploadForm.styles";
import { useDropzone } from 'react-dropzone';
import { actions } from 'store';
import api from 'api';
import axios from 'axios';
import UploadFormFileRow from './UploadFormFileRow';



const UploadForm = ({
	client_id,
	patient_id,
	visit_id,
	urpt_id,
}) => {
	const dispatch                    = useDispatch(),
		  loading                     = useSelector(state => state.files.loading),
		  classes                     = useStyles(),
		  [ fileCache, setFileCache ] = useState([]),
		  [ showDone, setShowDone ]   = useState(false),
		  [ showError, setShowError ] = useState(false);

	const onDrop = async (accepted, rejected) => {
		/*
		let url = await api.post('/files/upload-token', {
			files: accepted.map(file => file.name),
		});
		url = url.data;

		console.log(url);

		if(showDone) {
*/
		let files = accepted.map(file => {
			console.log('file', file);

			return {
				name: file.name,
				data: file,
				size: file.size,
				quality: 'original',
				status: 'NONE',
			};
		});

		if(files.length) {
			setFileCache(current => ([
				...current,
				...files,
			]));
		}

		if(showDone) {
			setShowDone(false);
		}

	};


	const {
			  acceptedFiles,
			  getRootProps,
			  getInputProps,
		  } = useDropzone({
		accept: 'image/jpeg,image/png,application/pdf,image/webp',
		onDrop: onDrop,
		maxFiles: 8,
		maxSize: 52428800, // 50 MB
	});


	const handleUpload = async () => {
		let tokens,
			uploadErrors = false;

		dispatch(actions.files.setState({ loading: true }));
		setFileCache(fileCache.map(file => ({ ...file, status: 'WAIT' })));
		setShowError(false);
		setShowDone(false);

		try {
			tokens = await api.post('/files/upload-token', {
				files: fileCache.map(file => ({
					filename: file.name,
					quality: file.quality,
				})),
				metadata: {
					client_id,
					patient_id,
					visit_id,
					urpt_id,
				},
			});
			tokens = tokens.data.tokens;
		} catch(err) {
			console.error(err);
			dispatch(actions.files.setState({ loading: false }));
			return false;
		}

		for(let i = 0; i < tokens.length; i++) {
			let presignedPutUrl = tokens[ i ],
				file            = fileCache[ i ];

			setFileCache(cache => cache.map((file, index) => (
				index === i
					? { ...file, status: 'UPLOADING' }
					: file
			)));

			try {
				await axios.put(presignedPutUrl, file.data);
				console.log('uploaded', file.name);
				setFileCache(cache => cache.map((file, index) => (
					index === i
						? { ...file, status: 'SUCCESS' }
						: file
				)));
			} catch(err) {
				console.error(err);
				setFileCache(cache => cache.map((file, index) => (
					index === i
						? { ...file, status: 'FAIL' }
						: file
				)));
				uploadErrors = true;
			}
		}

		if(uploadErrors) {
			setFileCache(cache => cache.filter(file => file.status === 'FAIL'));
			setShowError(true);
			dispatch(actions.files.setState({ loading: false }));
			return false;
		}

		setFileCache([]);
		setShowDone(true);
		setShowError(false);
		dispatch(actions.files.setState({ loading: false }));
	};


	const handleRemoveFile = (index) => {
		setFileCache([
			...fileCache.slice(0, index),
			...fileCache.slice(index + 1),
		]);
	};


	const handleChangeQuality = (index) => (event) => {
		setFileCache(fileCache.map((file, fileIndex) => {
			if(fileIndex !== index) {
				return file;
			}

			return { ...file, quality: event.target.value };
		}));
	};



	return (
		<div>
			<div {...getRootProps({ className: classes.dropZone })}>
				<input {...getInputProps()} />
				<Typography className={classes.dropZoneText}>
					Click, tap, or drag image here to upload. JPG, PNG, and GIF files accepted. 20 MB max size.
				</Typography>
			</div>

			<div>
				<List>
					{fileCache.map((fileData, index) => (
						<UploadFormFileRow
							key={index}
							index={index}
							file={fileData}
							handleRemoveFile={handleRemoveFile}
							handleChangeQuality={handleChangeQuality}
							loading={loading}
						/>
					))}
				</List>

			</div>

			<div>
				<Button
					type="primary"
					variant="contained"
					onClick={handleUpload}
					disabled={loading || !fileCache.length}
				>
					Upload
				</Button>
			</div>

			{showDone &&
				<div>
					Upload complete! It may take several seconds for your files to become available while we
					process them.
				</div>
			}

			{showError &&
				<div>
					There was an error uploading some of your files. Please review the error messages and try again.
				</div>
			}
		</div>

	);
};


export default UploadForm;
