import React from 'react';
import DashboardCard from "components/DashboardCard";
import { Lock } from "@mui/icons-material";



const Permissions = () => {

	return (
		<div>
			<DashboardCard
				color="orange"
				icon={<Lock />}
				topContent="Permissions"
				size="xl"
			>
				User groups &amp; permissions
			</DashboardCard>
		</div>
	);
};


export default Permissions;
