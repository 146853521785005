import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './Reports.redux';
import { useParams } from "react-router-dom";



const TemplateReplacementModal = () => {
    const dispatch                        = useDispatch(),
          { report_id }                   = useParams(),
          open                            = useSelector(state => state.reports.showTemplateReplacementModal),
          templates                       = useSelector(state => state.reports.editorTemplates),
          templateObject                  = templates.reduce((all, current) => {
              all[current.st_template] = current;
              return all;
          }, {}),
          editorContent                   = useSelector(state => state.reports.editorContent),
          [
              availableReplacements,
              setAvailableReplacements,
          ]                               = useState([]),
          [
              selectedReplacements,
              setSelectedReplacements ]   = useState([]),
          [ tempElement, setTempElement ] = useState(null);


    // Handlers
    const handleSelectionChange = (replacement) => (event) => {
        if(!selectedReplacements.includes(replacement.index)) {
            setSelectedReplacements(([ ...selectedReplacements, replacement.index ]));
        } else {
            let index = selectedReplacements.findIndex(val => val === replacement.index);
            setSelectedReplacements([
                ...selectedReplacements.slice(0, index),
                ...selectedReplacements.slice(index + 1),
            ]);
        }
    };



    const handleClose = () => {
        dispatch(actions.setState({ showTemplateReplacementModal: false }));
        setSelectedReplacements([]);
        setAvailableReplacements([]);
        setTempElement(null);
    };


    const handleReplace = () => {
        let tags = tempElement.querySelectorAll('[data-replacement-index]'),
            i    = tags.length;

        while(i--) {
            let tag              = tags[i],
                replacementIndex = Number(tag.getAttribute('data-replacement-index'));


            if(selectedReplacements.includes(replacementIndex)) {
                let tempDiv = document.createElement('div');
                tempDiv.innerHTML = availableReplacements[replacementIndex].content;
                tag.replaceWith(tempDiv.firstChild);
                tempDiv = null;
            }
        }

        dispatch(actions.updateReportContent(tempElement.innerHTML));
        handleClose();
    };


    const findReplacements = useCallback(() => {
        let el = document.createElement('div');
        el.innerHTML = editorContent;

        let tags        = el.querySelectorAll('[data-st-template]'),
            replaceable = [],
            emptyUpdate = false;


        for(let i = 0; i < tags.length; i++) {
            let tag          = tags[i],
                templateName = tag.getAttribute('data-st-template');

            // Remove empty tags
            if(!tag.children.length && !tag.innerHTML.trim().length) {
                emptyUpdate = true;
                tag.parentNode.removeChild(tag);
                continue;
            }

            replaceable.push({
                templateName: templateName,
                content: templateObject[templateName].content,
                title: templateObject[templateName].title,
                description: templateObject[templateName].description,
                index: i,
            });

            tag.setAttribute('data-replacement-index', String(i));
        }

        if(emptyUpdate) {
            dispatch(actions.updateReportContent(el.innerHTML));
        }
        setAvailableReplacements(replaceable);
        setTempElement(el);
        /*


        dispatch(actions.updateReportContent(el.innerHTML));
        dispatch(actions.setState({ showTemplateReplacementModal: false }));
        */
    }, [ setAvailableReplacements, templateObject ]);



    useEffect(() => {
        if(open) {
            dispatch(actions.load_report(report_id, true));
            findReplacements();
        }

    }, [ open, dispatch ]);


    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Template Replacement
            </DialogTitle>
            <DialogContent>
                {availableReplacements.length === 0 &&
                    <div>
                        No replaceable templates were found.
                    </div>
                }
                {availableReplacements.length > 0 &&
                    <div>
                        <Typography>
                            Below are the replacements available in the order they appear in your report. Check the
                            options you would like to replace, then click the Replace button. Please be aware that any
                            customization you have made to the templates being replaced in this report will be lost.
                        </Typography>
                        {availableReplacements.map(replacement => {

                            return (
                                <div key={replacement.index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedReplacements.includes(replacement.index)}
                                                onChange={handleSelectionChange(replacement)}
                                                color="primary"
                                            />
                                        }
                                        label={replacement.title}
                                    />
                                </div>
                            );
                        })}
                    </div>
                }
            </DialogContent>

            <DialogActions>
                <Button color="primary" onClick={handleReplace} disabled={!selectedReplacements.length}>
                    Replace Selected
                </Button>
                <Button color="secondary" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TemplateReplacementModal;
