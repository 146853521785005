import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper } from "@mui/material";
import clsx from 'clsx';
import useStyles from './Chart.styles';
import ChartingMenu from './ChartingMenu';
import CanvasBuccal from './CanvasBuccal';
import CanvasOcclusal from './CanvasOcclusal';
import CanvasPerio from './CanvasPerio';
import PerioNumpad from './CanvasPerio/PerioNumpad';
import PerioToothNumber from './CanvasPerio/PerioToothNumber';
import IndicesDialog from "./IndicesDialog";
import VisitInfoDialog from './VisitInfoDialog';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './Chart.redux';
import { resetSVGSubscriptions } from "svgHandlers/subscribe";
import SymbolHandler from './SymbolHandler';
import TextDialog from './TextDialog';
import CodeDefinitions from './CodeDefinitions';
import TextNotes from './TextNotes';
import SnapshotDialog from './SnapshotDialog';
import PenpalContextBuccal from './PenpalContextBuccal';
import PenpalContextOcclusal from './PenpalContextOcclusal';
import PenPal from "lib/PenPal";
import useNavigationWarning from 'hooks/useNavigationWarning';
import SetTitle from 'actionComponents/SetTitle';
import dayjs from 'dayjs';



const Chart = () => {
	const classes                        = useStyles(),
		  [ buccalSize, setBuccalSize ]  = useState(9),
		  probingActive                  = useSelector(state => state.chart.toolbar.selectedTool === 'probe'),
		  patientName                    = useSelector(state => state.chart.patient_name),
		  clientName                     = useSelector(state => state.chart.client_name),
		  visitDate                      = useSelector(state => state.chart.visit_date),
		  dispatch                       = useDispatch(),
		  { chart_id }                   = useParams(),
		  [ penPals ]                    = useState({
			  occlusal: new PenPal(),
			  buccal: new PenPal(),
			  perio: new PenPal(),
		  }),
		  [ Prompt, setDirty, setClean ] = useNavigationWarning();

	useEffect(() => {
		dispatch(actions.getChart(chart_id));
	}, [ dispatch, chart_id ]);

	// Set buccal canvas size based on whether or not the probing section is active
	useEffect(() => {
		setBuccalSize(probingActive
			? 6
			: 9);
	}, [ setBuccalSize, probingActive ]);

	useEffect(() => {
		setDirty();

		return () => {
			setClean();
			resetSVGSubscriptions();
			dispatch(actions.resetAll());
		};
	}, []);


	return (
		<PenpalContextBuccal.Provider value={penPals.buccal}>
			<PenpalContextOcclusal.Provider value={penPals.occlusal}>
				<div>
					<SetTitle
						title={patientName
							? `Chart - ${patientName} (${clientName}, ${dayjs(visitDate).format('MM/DD/YYYY')})`
							: 'Chart'}
					/>

					<div>
						<ChartingMenu />
					</div>
					<Grid container spacing={2}>

						<Grid item xs={3}>
							<Paper elevation={2} className={classes.occlusalContainer}>
								<div className={classes.canvasWrapper}>
									<CanvasOcclusal penPal={penPals.occlusal} />
								</div>
							</Paper>
						</Grid>

						<Grid item xs={buccalSize}>
							<Paper elevation={2} className={classes.buccalContainer}>
								<div className={clsx(classes.canvasWrapper, classes.buccalCanvasWrapper)}>
									<CanvasBuccal penPal={penPals.buccal} />
								</div>
							</Paper>

							<Grid container spacing={2}>
								<Grid item xs={4}>
									<TextNotes />
								</Grid>

								<Grid item xs={4}>
									<CodeDefinitions
										title="Diagnostic Code Definitions"
										symbolType="dx"
									/>
								</Grid>

								<Grid item xs={4}>
									<CodeDefinitions
										title="Treatment Code Definitions"
										symbolType="tx"
									/>
								</Grid>
							</Grid>
						</Grid>

						{probingActive &&
							<Grid item xs={3}>
								<Paper elevation={2} className={classes.perioContainer}>
									<div>
										<PerioToothNumber />
									</div>
									<div className={classes.canvasWrapper}>
										<CanvasPerio penPal={penPals.perio} />
									</div>
								</Paper>
								<Paper elevation={2} className={classes.probingPad}>
									<PerioNumpad />
								</Paper>
							</Grid>
						}

					</Grid>

					<IndicesDialog />
					<VisitInfoDialog />
					<SymbolHandler />
					<TextDialog />
					<SnapshotDialog />
				</div>
				{Prompt}
			</PenpalContextOcclusal.Provider>
		</PenpalContextBuccal.Provider>

	);
};



export default Chart;
