import React from 'react';
import useStyles from './ClinicSelection.styles';
import { Avatar, Card, CardContent, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import api from 'api';
import { actions } from 'store/auth.redux';



const ClinicSelection = () => {
	const classes  = useStyles(),
		  dispatch = useDispatch(),
		  clinics  = useSelector(state => state.auth.clinics);


	const handleSetClinic = async (clinic_id) => {
		try {
			let result = await api.post('/auth/select-clinic', {
				clinic_id: clinic_id,
			});

			dispatch(actions.setState({ showClinicSelection: result.data.showClinicSelection }));

		} catch(err) {

		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.child}>
				<Grid container spacing={4}>
					{clinics.map(clinic => (
						<Grid item key={clinic.clinic_id} xs={6} md={4} lg={3} xl={3}>
							<Card>
								<CardContent>
									<div>
										<Avatar
											className={classes.avatar}
											onClick={() => handleSetClinic(clinic.clinic_id)}
										>
											{
												clinic.clinic_name
													.split(' ')
													.slice(0, 3)
													.map(part => part[ 0 ].toUpperCase())
													.join('')
											}
										</Avatar>
									</div>
									<div>
										{clinic.clinic_name}
									</div>
								</CardContent>
							</Card>

						</Grid>
					))}
				</Grid>
			</div>
		</div>
	);
};

export default ClinicSelection;
