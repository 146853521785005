import makeStyles from '@mui/styles/makeStyles';
import { indigo, lightGreen, orange, purple } from "@mui/material/colors";



const useStyles = makeStyles(theme => ({
	card: {
		minWidth: 260,
	},
	chipRootDefault: {
		height: 20,
	},
	chipRootGreen: {
		height: 20,
		backgroundColor: lightGreen[ 'A200' ],
	},
	chipRootOrange: {
		height: 20,
		backgroundColor: orange[ 'A200' ],
	},
	chipRootIndigo: {
		height: 20,
		backgroundColor: indigo[ 'A100' ],
	},
	chipRootPurple: {
		height: 20,
		backgroundColor: purple[ 'A100' ],
	},
	capitalize: {
		textTransform: 'capitalize',
	},
}));

export default useStyles;