import { createTheme } from '@mui/material/styles';
import {
	indigo as primaryColor,
	deepOrange as secondaryColor,
	red as errorColor,
	grey,
	green,
} from '@mui/material/colors';



const theme = createTheme({
	palette: {
		primary: primaryColor,
		secondary: secondaryColor,
		grey: {
			main: grey[ 400 ],
			dark: grey[ 400 ],
		},
		green: green,
		error: errorColor,
		//type: 'dark',
	},
	typography: {
		useNextVariants: true,
	},
	components: {
		MuiToolbar: {
			styleOverrides: {
				regular: {
					'@media (min-width: 600px)': {
						minHeight: 56,
						maxHeight: 56,
					},
					minHeight: 56,
					maxHeight: 56,
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: { root: { paddingTop: `8px !important` } },
		},
	},
});


export default theme;
