import React, { useEffect, useState } from 'react';
import useStyles from './CanvasOcclusal.styles';
import OcclusalHandler from 'svgHandlers/Occlusal';
import SVGHelper from "svgHandlers/helper";
import PenpalContextOcclusal from '../PenpalContextOcclusal';



const CanvasOcclusal = ({ penPal }) => {
    const canvasRef  = React.createRef(),
          classes    = useStyles(),
          [ helper ] = useState(new SVGHelper(penPal)),
          [ svg ]    = useState(new OcclusalHandler(penPal, helper));


    useEffect(() => {
        //console.log('exists', penPal.canvas.exists('occlusal'));
        if(svg) {
            console.log('running canvas init');
            svg.init(canvasRef.current);
        }

        return () => {
            if(svg) {
                console.log('destroying canvas');
                svg.destroy();
            }
        };
    }, []);


    if(penPal && penPal.canvas.exists('occlusal')) {
        penPal.sizeCanvas('occlusal', 0);
    }



    return (
        <PenpalContextOcclusal.Provider value={penPal}>
            <div className={classes.occlusalContainer}>
                <div ref={canvasRef} className={classes.occlusalCanvas} />
            </div>
        </PenpalContextOcclusal.Provider>
    );

};


export default CanvasOcclusal;
