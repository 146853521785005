import React from 'react';
import createRoute from 'Router/createRoute';
import Home from 'pages/Home';
import Clients from 'pages/Clients';
import ClientView from 'pages/ClientView';
import Patients from 'pages/Patients';
import PatientView from 'pages/PatientView';
import ForgotPassword from 'pages/ForgotPassword';
import Chart from 'pages/Chart';
import Admin from 'pages/Admin';
import SymbolTest from "pages/SymbolTest";
import ClinicSettings from 'pages/ClinicSettings';
import Reports from 'pages/Reports';
import AddReport from 'pages/Reports/AddReport';
import EditReport from 'pages/Reports/EditReport';
import EditTemplate from 'pages/Reports/EditTemplate';
import EditContentBlock from 'pages/Reports/EditContentBlock';
import Logout from 'pages/Logout';



export const routes = [

	createRoute({
		path: '/',
		exact: true,
		render: <Home />,
		title: 'Home',
		private: true,
	}),

	createRoute({
		path: '/forgot-password',
		exact: true,
		render: <ForgotPassword />,
		title: 'Forgot Password',
		private: false,
		useLayout: false,
	}),

	createRoute({
		path: '/clients',
		exact: true,
		render: <Clients />,
		title: 'Clients',
		private: true,
	}),

	createRoute({
		path: '/clients/view/:client_id',
		exact: true,
		render: <ClientView />,
		title: 'Clients',
		private: true,
	}),

	createRoute({
		path: '/patients',
		exact: true,
		render: <Patients />,
		title: 'Patients',
		private: true,
	}),

	createRoute({
		path: '/patients/view/:patient_id',
		exact: true,
		render: <PatientView />,
		title: 'Patients',
		private: true,
	}),


	createRoute({
		path: '/reports',
		exact: true,
		render: <Reports />,
		title: 'Reports',
		private: true,
	}),

	createRoute({
		path: '/reports/edit/:report_id',
		exact: true,
		render: <EditReport />,
		title: 'Reports',
		private: true,
	}),

	createRoute({
		path: '/reports/editor',
		exact: true,
		render: <AddReport />,
		title: 'Report Editor',
		private: true,
	}),

	createRoute({
		path: '/reports/edit-layout/:templateID',
		exact: true,
		render: <EditTemplate />,
		title: 'Layout Editor',
		private: true,
	}),


	createRoute({
		path: '/reports/edit-content-block/:contentBlockID',
		exact: true,
		render: <EditContentBlock />,
		title: 'Content Block Editor',
		private: true,
	}),



	createRoute({
		path: '/chart/:chart_id',
		exact: true,
		render: <Chart />,
		title: 'Chart',
		private: true,
	}),

	createRoute({
		path: '/clinic-settings',
		exact: false,
		render: <ClinicSettings />,
		title: 'Clinic Settings',
		private: true,
	}),

	createRoute({
		path: '/logout',
		exact: true,
		render: <Logout />,
		title: 'Log Out',
		private: true,
	}),

	createRoute({
		path: '/_symboltest',
		exact: true,
		render: <SymbolTest />,
		title: 'Alt Symbol Mode Test',
		private: true,
	}),


	createRoute({
		path: '/_admin',
		exact: true,
		render: <Admin />,
		title: 'Chart',
		private: true,
	}),

];
