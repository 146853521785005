import React, {PureComponent} from 'react';
import withStyles from '@mui/styles/withStyles';
import {
	Popover,
	Tabs,
	Tab,
	AppBar,
	Button,
} from '@mui/material';
import RootCanalOne from './RootCanalOne';
import RootCanalTwo from './RootCanalTwo';
import RootCanalThree from './RootCanalThree';

const sel = document.getElementById('hidden');

const styles = theme => ({
	tabRoot: {
		minWidth: 100,
	},
	body: {
		marginTop: 16,
		padding: 16,
	},
	root: {
		maxWidth: 400,
		width: 400,
	},
});


class RootCanal extends PureComponent {

	state = {
		tab: 'one',
		altMode: false,
		restoration: '',
		data: [
			['','','','',''],
			['','','','',''],
			['','','','',''],
		],
	};

	handleTabChange = (event, value) => {
		this.setState({tab: value});
	};


	update = data => {
		this.setState({...data});
	};


	save = () => {
		const {data} = this.props.state;
		data.roots   = this.state.tab;
		data.altMode = this.state.altMode;
		data.restoration = this.state.restoration;

		switch(this.state.tab) {
			case 'one':
				data.values = [this.state.data[0], [], []];
				data.roots = 1;
				break;
			case 'two':
				data.values = [this.state.data[0], this.state.data[1], []];
				data.roots = 2;
				break;
			default:
				data.values = [...this.state.data];
				data.roots = 3;
		}

		this.props.save(data);
	};


	render() {
		const {tab}     = this.state;
		const {classes} = this.props;


		return (
			<Popover
				classes={{paper: classes.root}}
				anchorEl={sel}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "left",
				}}
				open={true}
			>

				<AppBar position="static">
					<Tabs fullWidth value={tab} onChange={this.handleTabChange}>
						<Tab classes={{root: classes.tabRoot}} label="1 Root" value="one" />
						<Tab classes={{root: classes.tabRoot}} label="2 Roots" value="two" />
						<Tab classes={{root: classes.tabRoot}} label="3 Roots" value="three" />
					</Tabs>
				</AppBar>

				<div className={classes.body}>
					{tab === 'one' && <RootCanalOne data={this.state} update={this.update} />}
					{tab === 'two' && <RootCanalTwo data={this.state} update={this.update} />}
					{tab === 'three' && <RootCanalThree data={this.state} update={this.update} />}
				</div>

				<div className={classes.body}>
					<Button variant="contained" color="secondary" onClick={this.save}>Done</Button>
				</div>

			</Popover>
		);
	}
}


export default withStyles(styles)(RootCanal);