import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	bar: {
		width: '100%',
		height: 50,
		alignContent: 'center',
		alignItems: 'center',
		margin: 16,
		textAlign: "center",
	},
	select: {
		minWidth: 70,
	},
}));



export default useStyles;
