import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { CustomPaging, PagingState, SelectionState } from '@devexpress/dx-react-grid';
import {
    Grid as DXGrid,
    PagingPanel,
    Table,
    TableHeaderRow,
    TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import { useDispatch } from 'react-redux';
import { actions } from './Reports.redux';
import api from "../../api";
import { push } from "connected-react-router";



const ChartSearch = ({
    loading,
    selected,
    setSelected,
    values,
    setValues,
    rows,
    rowTotal,
    page,
    setPage,
}) => {
    const [ columns ] = useState([
        { name: 'patient_name', title: 'Patient' },
        { name: 'client_name', title: 'Client' },
        { name: 'date_created', title: 'Chart Date' },
    ]);

    const handleChange = (e) => {
        if(loading) {
            return false;
        }

        setValues((current => ({
            ...current,
            [e.target.name]: e.target.value,
        })));
    };

    return (
        <div>
            <div>
                <Grid container spacing={4} sx={{ marginTop: '4px' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            label="Patient Name"
                            variant="outlined"
                            fullWidth
                            value={values.patient_name}
                            name="patient_name"
                            onChange={handleChange}
                        />
                    </Grid>


                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            label="Client Name"
                            variant="outlined"
                            fullWidth
                            value={values.client_name}
                            name="client_name"
                            onChange={handleChange}
                        />
                    </Grid>


                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            type="date"
                            label="Report Date (From)"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            fullWidth
                            value={values.date_from}
                            name="date_from"
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            type="date"
                            label="Report Date (To)"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            fullWidth
                            value={values.date_to}
                            name="date_to"
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </div>


            <div>
                <DXGrid
                    rows={rows}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={setPage}
                        pageSize={10}
                    />
                    <CustomPaging
                        totalCount={rowTotal}
                    />
                    <SelectionState
                        selection={selected}
                        onSelectionChange={setSelected}
                    />
                    <Table />
                    <TableHeaderRow />
                    <TableSelection
                        selectByRowClick
                        highlightRow
                        showSelectionColumn={false}
                    />
                    <PagingPanel
                        pageSizes={[ 10 ]}
                    />
                </DXGrid>
            </div>
        </div>
    );
};


const SelectTitleAndTemplate = ({
    title,
    setTitle,
    rows,
    totalRows,
    page,
    setPage,
    selected,
    setSelected,
}) => {
    const [ columns ] = useState([
        { name: 'template_name', title: 'Layout Name' },
        { name: 'date_created', title: 'Date Created' },
    ]);

    return (
        <div>
            <div>
                <TextField
                    label="Report Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>

            <div>
                <DXGrid
                    rows={rows}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={setPage}
                        pageSize={10}
                    />
                    <SelectionState
                        selection={selected}
                        onSelectionChange={setSelected}
                    />
                    <CustomPaging
                        totalCount={totalRows}
                    />
                    <Table />
                    <TableHeaderRow />
                    <TableSelection
                        selectByRowClick
                        highlightRow
                        showSelectionColumn={false}
                    />
                    <PagingPanel
                        pageSizes={[ 10 ]}
                    />
                </DXGrid>
            </div>

        </div>
    );
};


const ModalReportAddNew = () => {
    const dispatch                              = useDispatch(),
          [ loading, setLoading ]               = useState(false),
          [ selected, setSelected ]             = useState([]),
          [ rows, setRows ]                     = useState([]),
          [ rowTotal, setRowTotal ]             = useState(0),
          [ page, setPage ]                     = useState(0),
          [ layoutSelected, setLayoutSelected ] = useState([]),
          [ layoutRows, setLayoutRows ]         = useState([]),
          [ layoutRowTotal, setLayoutRowTotal ] = useState(0),
          [ layoutPage, setLayoutPage ]         = useState(0),
          [ title, setTitle ]                   = useState(''),
          [ visitID, setVisitID ]               = useState(null),
          [ layoutID, setLayoutID ]             = useState(null),
          [ screen, setScreen ]                 = useState('search'),
          [ values, setValues ]                 = useState({
              patient_name: '',
              client_name: '',
              date_from: '',
              date_to: '',
          });


    const handleSelection = (selection) => {
        const selectedRow = selection.slice(-1);
        setSelected(selectedRow);

        if(selectedRow.length) {
            setVisitID(rows[selectedRow[0]].visit_id);
        }
    };

    const handleClose = () => {
        dispatch(actions.setState({ showNewReportModal: false }));
    };


    const handleReset = () => {
        setValues({
            patient_name: '',
            client_name: '',
            date_from: '',
            date_to: '',
        });

        handleSearch(true);
    };

    const handleSearch = async (reset = false) => {
        const query = new URLSearchParams({
            page_size: 10,
            page_number: page,
            ...values.patient_name.trim()
                ? { patient_name: values.patient_name.trim() }
                : {},
            ...values.client_name.trim()
                ? { client_name: values.client_name.trim() }
                : {},
            ...values.date_from.trim()
                ? { date_from: values.date_from }
                : {},
            ...values.date_to.trim()
                ? { date_to: values.date_to }
                : {},
        });

        setLoading(true);

        try {
            let result = await api.get('/charts/search', { params: query });
            setRows(result.data.charts);
            setRowTotal(result.data.chart_count);
        } catch (err) {
            console.log(err);
        }

        setLoading(false);
        setSelected([]);
        setVisitID(null);

        if(reset && page !== 0) {
            setPage(0);
        } else if(reset && page === 0) {
            return handleSearch();
        }
    };


    const handleLayoutSearch = async (reset = false) => {
        const query = new URLSearchParams({
            page_size: 10,
            page_number: page,
        });

        setLoading(true);

        try {
            let result = await api.get('/reports/templates', { params: query });
            setLayoutRows(result.data.templates);
            setLayoutRowTotal(result.data.templates.length > 0
                ? Number(result.data.templates[0].total_records)
                : 0
            );
        } catch (err) {
            console.log(err);
        }

        setLoading(false);
        setLayoutSelected([]);
        setLayoutID(null);

        if(reset) {
            setLayoutPage(0);
        }
    };


    const handleLayoutSelection = (selection) => {
        const selectedRow = selection.slice(-1);
        setLayoutSelected(selectedRow);

        if(selectedRow.length) {
            setLayoutID(layoutRows[selectedRow[0]].template_id);
        }
    };



    const handleCreateReport = async () => {
        setLoading(true);
        try {
            let result = await api.post('/reports', {
                report_name: title,
                template_id: layoutID,
                visit_id: visitID,
            });

            setLoading(false);
            dispatch(push(`/reports/edit/${result.data.urpt_id}`));
        } catch (err) {
            setLoading(false);
            console.error(err);
        }
    };


    useEffect(() => {
        if(screen === 'search') {
            handleSearch();
        }
    }, [ page, screen ]);

    useEffect(() => {
        if(screen === 'title') {
            handleLayoutSearch();
        }
    }, [ layoutPage, screen ]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={handleClose}
        >
            <DialogTitle>Create Report</DialogTitle>

            <DialogContent>
                {screen === 'search' &&
                    <>
                        <div>
                            Search and select a chart to base your report off of below.
                        </div>

                        <div>
                            <ChartSearch
                                loading={loading}
                                setLoading={setLoading}
                                selected={selected}
                                setSelected={handleSelection}
                                values={values}
                                setValues={setValues}
                                rows={rows}
                                rowTotal={rowTotal}
                                page={page}
                                setPage={setPage}
                            />
                        </div>
                    </>
                }

                {screen === 'title' &&
                    <>
                        <div>
                            Enter a title for your report and select a layout to use, then click Create.
                        </div>

                        <div>
                            <SelectTitleAndTemplate
                                title={title}
                                setTitle={setTitle}
                                rows={layoutRows}
                                totalRows={layoutRowTotal}
                                page={layoutPage}
                                setPage={setLayoutPage}
                                selected={layoutSelected}
                                setSelected={handleLayoutSelection}
                            />
                        </div>
                    </>

                }
            </DialogContent>


            <DialogActions>
                <Button onClick={handleClose} variant="outlined">Cancel</Button>

                {screen === 'search' &&
                    <>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleReset}
                            disabled={loading}
                        >
                            Reset
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loading}
                            onClick={() => handleSearch(true)}
                        >
                            Search
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            disabled={loading || !visitID}
                            onClick={() => setScreen('title')}
                        >
                            Next
                        </Button>
                    </>
                }

                {screen === 'title' &&
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading || !visitID || !layoutID || !title.trim()}
                        onClick={handleCreateReport}
                    >
                        Create
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};


export default ModalReportAddNew;
