import React from 'react';
import DashboardCard from "components/DashboardCard";
import { CreditCard } from "@mui/icons-material";



const Billing = () => {

	return (
		<div>
			<DashboardCard
				color="orange"
				icon={<CreditCard />}
				topContent="Billing"
				size="xl"
			>
				Subscription selection and payment method entry
			</DashboardCard>
		</div>
	);
};


export default Billing;
