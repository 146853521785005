import React from 'react';
import {actions} from 'store';
import {Check, Close,} from '@mui/icons-material';
import {
	IconButton,
	Popover,
	TextField,
	Paper,
} from '@mui/material';
import {hideDialogAtMouse} from '../helpers/dialogAtMouse';
//import diagnoses from '../../config/ChartingView/Diagnoses';

const sel = document.getElementById('hidden');

class ToothValuePicker extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			value: props.store.getState().ActiveChart.Diagnostics.hasOwnProperty(props.paperEvent.item.name)
				? props.store.getState().ActiveChart.GingivalRecession[props.paperEvent.item.name][0]
				: ''
		};
	}


	close = () => {
		this.props.paperEvent.item.strokeColor = this.props.paperEvent.item.data.originalStroke;
		hideDialogAtMouse(sel);
	};


	confirm = () => {
		let opts = {
			stObj: true,
			canvas: 'buccal',
			name: `${this.props.dx}_${this.props.paperEvent.item.name}`,
			tooth: this.props.paperEvent.item.name,
			id: this.props.store.getState().ChartingOptions.ActiveDiagnosis,
			content: this.state.value,
			adult: this.props.helper.getToothVisibility().active === 'adult',
		};

		this.props.store.dispatch(actions.saveDiagnostics(opts));
		this.props.paperEvent.item.strokeColor = this.props.paperEvent.item.data.originalStroke;
		this.props.render(this.props.helper, opts);
		hideDialogAtMouse(sel);
		console.log(this.props.store.getState())
	};


	updateValue = (e) => {
		this.setState({value: e.target.value});
	};

m
	render() {
		return (
            <Popover
				anchorEl={document.getElementById('buccal-container')}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "left",
				}}
				open={this.props.open}
			>
				<Paper style={{padding: 4}}>
					<TextField
						type="number"
						style={{marginRight: 8}}
						autoFocus={true}
						placeholder="Enter Value"
						onChange={this.updateValue}
						value={this.state.value}
						label="Gingival Recession Value"
					/>
					<IconButton onClick={this.confirm} size="large">
						<Check style={{color: 'green'}} />
					</IconButton>

					<IconButton onClick={this.close} size="large">
						<Close style={{color: 'red'}} />
					</IconButton>
				</Paper>
			</Popover>
        );
	}
}


export default ToothValuePicker;