import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';




const useStyles = makeStyles(theme => ({
	errorContainer: {
		backgroundColor: red[ 50 ],
		borderColor: red[ 200 ],
		color: red[ 800 ],
		margin: theme.spacing(1),
		padding: theme.spacing(1),
	},
}));



export default useStyles;