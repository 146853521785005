import React, { useState } from 'react';
import { Button, ButtonGroup, Divider, ListItemText, Menu, MenuItem } from "@mui/material";
import { BlurOn } from "@mui/icons-material";
import useStyles from './ChartingMenu.styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';



const MenuSymbols = () => {
	const classes               = useStyles(),
		  dispatch              = useDispatch(),
		  selected              = useSelector(state => state.chart.toolbar.selectedButton === 'symbols'),
		  tool                  = useSelector(state => state.chart.toolbar.selectedTool),
		  activeSymbol          = useSelector(state => state.chart.symbolOptions.symbol),
		  activeSymbolData      = useSelector(state => activeSymbol !== null
			  ? state.chart.symbols.find(symbol => symbol.symbol_id === activeSymbol)
			  : null),
		  symbolList            = useSelector(state => state.chart.symbolsMenu),
		  symbolShowDx          = useSelector(state => state.chart.toolbar.symbolShowDx),
		  symbolShowTx          = useSelector(state => state.chart.toolbar.symbolShowTx),
		  symbolShowOther       = useSelector(state => state.chart.toolbar.symbolShowOther),
		  [ anchor, setAnchor ] = useState(null),
		  validTools            = [ 'symbolMulti', 'symbolSingle' ];


	const toggleSymbolVisibility = name => e => {
		let values = { symbolShowDx, symbolShowTx, symbolShowOther };
		dispatch(actions.setToolbar({
			[ name ]: !values[ name ],
		}));
	};



	const handleOpen = (e) => {
		setAnchor(e.currentTarget);

		let shouldUpdate = false,
			update       = {};

		if(!selected) {
			shouldUpdate = true;
			dispatch(actions.resetSymbolOptions());
			update.selectedButton = 'symbols';
			update.selectedTool = 'symbolMulti';
		}

		if(selected && validTools.includes(tool)) {
			shouldUpdate = false;
		}

		if(selected && !validTools.includes(tool)) {
			shouldUpdate = true;
			update.selectedTool = 'symbolMulti';
		}

		if(shouldUpdate) {
			dispatch(actions.setToolbar(update));
		}
	};


	const setMultiSymbolMode = () => {
		dispatch(actions.setToolbar({
			selectedButton: 'symbols',
			selectedTool: 'symbolMulti',
		}));

		dispatch(actions.resetSymbolOptions());

		handleClose();
	};


	const setSymbol = (symbolID) => {
		dispatch(actions.setToolbar({
			selectedButton: 'symbols',
			selectedTool: 'symbolSingle',
		}));

		dispatch(actions.resetSymbolOptions({
			symbol: symbolID,
		}));

		handleClose();
	};


	const setViewAllCodes = () => {
		dispatch(actions.setToolbar({
			showAllSymbolsDialog: true,
		}));

		handleClose();
	};


	const handleClose = () => setAnchor(null);


	return (
		<>
			<Button
				variant={selected
					? "contained"
					: "outlined"}
				color={selected
					? "primary"
					: undefined}
				className={clsx(classes.buttonMarginRight, classes.buttonFloat)}
				startIcon={<BlurOn />}
				onClick={handleOpen}
			>
				Dx/Tx
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}
			>
				<div className={classes.symbolButtonGroup}>
					<ButtonGroup color={undefined} style={{ width: '100%' }}>
						<Button
							disableElevation
							variant={symbolShowDx
								? 'contained'
								: undefined}
							color={symbolShowDx
								? 'primary'
								: undefined}
							onClick={toggleSymbolVisibility('symbolShowDx')}
							style={{ width: '33.3%' }}
						>
							Dx
						</Button>

						<Button
							disableElevation
							variant={symbolShowTx
								? 'contained'
								: undefined}
							color={symbolShowTx
								? 'secondary'
								: undefined}
							onClick={toggleSymbolVisibility('symbolShowTx')}
							style={{ width: '33.3%' }}
						>
							Tx
						</Button>

						<Button
							disableElevation
							variant={symbolShowOther
								? 'contained'
								: undefined}
							color={symbolShowOther
								? 'secondary'
								: undefined}
							className={clsx({
								[ classes.symbolOtherButton ]: symbolShowOther,
							})}
							onClick={toggleSymbolVisibility('symbolShowOther')}
							style={{ width: '33.4%' }}
						>
							Other
						</Button>
					</ButtonGroup>
				</div>

				<Divider />

				<MenuItem
					onClick={setMultiSymbolMode}
					className={clsx({
						[ classes.activeItem ]: tool === 'symbolMulti',
					})}
				>
					<ListItemText>Multi-Code Mode</ListItemText>
				</MenuItem>

				<Divider />

				<MenuItem
					onClick={setViewAllCodes}
				>
					<ListItemText>View All Codes {
						activeSymbolData
							? `(Current: ${activeSymbolData.symbol_abbreviation})`
							: null
					}</ListItemText>
				</MenuItem>

				<Divider />

				{symbolList
					.filter(row => (
						(symbolShowDx && row.symbol_category === 'dx')
						|| (symbolShowTx && row.symbol_category === 'tx')
						|| (symbolShowOther && row.symbol_category === 'other')
					))
					.map(row => (
						<MenuItem
							key={row.symbol_id}
							onClick={() => setSymbol(row.symbol_id)}
							className={clsx({
								[ classes.activeItem ]: (tool === 'symbolSingle' && activeSymbol === row.symbol_id),
								[ classes.dxColor ]: row.symbol_category === 'dx',
								[ classes.txColor ]: row.symbol_category === 'tx',
								[ classes.otherColor ]: row.symbol_category === 'other',
							})}
						>
							<ListItemText>({row.symbol_abbreviation}) {row.symbol_name}</ListItemText>
						</MenuItem>
					))}


			</Menu>
		</>

	);
};



export default MenuSymbols;
