export default [
	[
		"dictionary",
		{
			"#15": [
				"Gradient",
				[
					[
						[
							0.74118,
							0.74118,
							0.74118
						],
						0.833
					],
					[
						[
							0.98824,
							0.98824,
							0.98824
						],
						1
					]
				],
				false
			]
		}
	],
	[
		"Group",
		{
			"name": "Layer_1",
			"applyMatrix": true,
			"children": [
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							1,
							0,
							0,
							1,
							604.35,
							768.2
						],
						"clipMask": true,
						"type": "rectangle",
						"size": [
							1208.7,
							1536.4
						],
						"radius": [
							0,
							0
						]
					}
				],
				[
					"Group",
					{
						"name": "Layer_2_1_",
						"applyMatrix": true,
						"children": [
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												894.2,
												740.1
											],
											[
												0,
												123
											],
											[
												0,
												-44
											]
										],
										[
											[
												902.4,
												568.4
											],
											[
												11.4,
												96.6
											],
											[
												-17.4,
												-147.7
											]
										],
										[
											[
												813.5,
												305.1
											],
											[
												5,
												116.2
											],
											[
												-3.1,
												-71.6
											]
										],
										[
											[
												761.7,
												156.7
											],
											[
												53.7,
												45.3
											],
											[
												-32.7,
												-27.6
											]
										],
										[
											[
												606,
												111.6
											],
											[
												47.2,
												-0.2
											],
											[
												-47.2,
												-0.2
											]
										],
										[
											[
												450.4,
												156.7
											],
											[
												32.7,
												-27.6
											],
											[
												-53.7,
												45.3
											]
										],
										[
											[
												398.6,
												305.1
											],
											[
												3.1,
												-71.6
											],
											[
												-5,
												116.2
											]
										],
										[
											[
												309.7,
												568.4
											],
											[
												17.4,
												-147.6
											],
											[
												-11.4,
												96.5
											]
										],
										[
											[
												317.9,
												740.1
											],
											[
												0,
												-44.1
											],
											[
												0,
												123
											]
										],
										[
											[
												403.5,
												1209.6
											],
											[
												-180.8,
												-393.4
											],
											[
												27.2,
												59.3
											]
										],
										[
											[
												414.7,
												1398.6
											],
											[
												-25.5,
												-64.4
											],
											[
												21.6,
												54.7
											]
										],
										[
											[
												604.8,
												1461.6
											],
											[
												-61.1,
												-1.1
											],
											[
												0.3,
												0
											]
										],
										[
											[
												605.9,
												1461.6
											],
											[
												-0.4,
												0
											],
											[
												0,
												0
											]
										],
										[
											606,
											1461.6
										],
										[
											[
												606.1,
												1461.6
											],
											[
												0,
												0
											],
											[
												0.5,
												0
											]
										],
										[
											[
												607.2,
												1461.6
											],
											[
												-0.3,
												0
											],
											[
												61.1,
												-1.1
											]
										],
										[
											[
												797.3,
												1398.6
											],
											[
												-21.6,
												54.7
											],
											[
												25.5,
												-64.3
											]
										],
										[
											[
												808.5,
												1209.6
											],
											[
												-27.2,
												59.3
											],
											[
												180.9,
												-393.4
											]
										]
									],
									"closed": true,
									"fillColor": [
										0.8902,
										0.8902,
										0.8902
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												787.9,
												568.4
											],
											[
												45.6,
												95.7
											],
											[
												-35.9,
												-87.4
											]
										],
										[
											[
												745.3,
												326.1
											],
											[
												6.7,
												146.9
											],
											[
												-9.7,
												-71.9
											]
										],
										[
											[
												715.3,
												199.8
											],
											[
												30.6,
												40.4
											],
											[
												-18.5,
												-24.4
											]
										],
										[
											[
												604.4,
												175
											],
											[
												26.7,
												-0.1
											],
											[
												-0.1,
												0
											]
										],
										[
											[
												604,
												175
											],
											[
												0.1,
												0
											],
											[
												-0.2,
												0
											]
										],
										[
											[
												603.4,
												175
											],
											[
												0.2,
												0
											],
											[
												-0.1,
												0
											]
										],
										[
											[
												603,
												175
											],
											[
												0.1,
												0
											],
											[
												-26.7,
												-0.1
											]
										],
										[
											[
												492.1,
												199.8
											],
											[
												18.5,
												-24.4
											],
											[
												-30.6,
												40.4
											]
										],
										[
											[
												462.1,
												326.1
											],
											[
												9.7,
												-72
											],
											[
												-6.7,
												146.9
											]
										],
										[
											[
												419.5,
												568.4
											],
											[
												35.9,
												-87.4
											],
											[
												-45.6,
												95.7
											]
										],
										[
											[
												409.1,
												822
											],
											[
												10.4,
												-79
											],
											[
												-8.6,
												109.6
											]
										],
										[
											[
												444.1,
												1078
											],
											[
												-43,
												-69.1
											],
											[
												53.2,
												98
											]
										],
										[
											[
												511.7,
												1348
											],
											[
												-14.1,
												-53
											],
											[
												12.9,
												48.6
											]
										],
										[
											[
												603.1,
												1392.3
											],
											[
												-34.9,
												-1
											],
											[
												0.1,
												0
											]
										],
										[
											[
												603.5,
												1392.3
											],
											[
												-0.1,
												0
											],
											[
												0.2,
												0
											]
										],
										[
											[
												604.1,
												1392.3
											],
											[
												-0.2,
												0
											],
											[
												0.1,
												0
											]
										],
										[
											[
												604.5,
												1392.3
											],
											[
												-0.1,
												0
											],
											[
												34.9,
												-1
											]
										],
										[
											[
												695.9,
												1348
											],
											[
												-12.9,
												48.6
											],
											[
												14.1,
												-53
											]
										],
										[
											[
												763.5,
												1078
											],
											[
												-53.2,
												98
											],
											[
												43,
												-69.1
											]
										],
										[
											[
												798.5,
												822
											],
											[
												8.6,
												109.6
											],
											[
												-10.6,
												-79
											]
										]
									],
									"closed": true,
									"fillColor": [
										0.98824,
										0.98824,
										0.98824
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											731.6,
											227
										],
										[
											[
												672.1,
												221
											],
											[
												21.6,
												-0.7
											],
											[
												-21.6,
												0.7
											]
										],
										[
											[
												603.2,
												232.2
											],
											[
												12.9,
												0
											],
											[
												-12.9,
												0
											]
										],
										[
											[
												534.6,
												221
											],
											[
												21.6,
												0.6
											],
											[
												-21.6,
												-0.6
											]
										],
										[
											475.1,
											227
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											741.3,
											285
										],
										[
											[
												688.7,
												275
											],
											[
												19.3,
												0.8
											],
											[
												-19.3,
												-0.8
											]
										],
										[
											[
												603.7,
												285
											],
											[
												9.6,
												0
											],
											[
												-9.7,
												0
											]
										],
										[
											[
												518.9,
												275
											],
											[
												19.3,
												-0.8
											],
											[
												-19.3,
												0.8
											]
										],
										[
											466.3,
											285
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											710.8,
											194.7
										],
										[
											[
												685.5,
												190
											],
											[
												12.1,
												0.9
											],
											[
												-12.1,
												-0.9
											]
										],
										[
											[
												603.8,
												195.8
											],
											[
												12.9,
												0
											],
											[
												-12.9,
												0
											]
										],
										[
											[
												522.4,
												190
											],
											[
												12.1,
												-0.9
											],
											[
												-12.1,
												0.9
											]
										],
										[
											497.1,
											194.7
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											750.8,
											425.4
										],
										[
											[
												710.7,
												411.6
											],
											[
												20.9,
												2.1
											],
											[
												-20.9,
												-2.1
											]
										],
										[
											[
												603.6,
												403.6
											],
											[
												23.5,
												0
											],
											[
												-23.5,
												0
											]
										],
										[
											[
												496.8,
												411.6
											],
											[
												20.9,
												-2.1
											],
											[
												-20.9,
												2.1
											]
										],
										[
											456.7,
											425.4
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											746.2,
											349.7
										],
										[
											[
												703.7,
												341.3
											],
											[
												16.4,
												1.8
											],
											[
												-16.4,
												-1.8
											]
										],
										[
											[
												603.4,
												342.5
											],
											[
												23.1,
												0
											],
											[
												-23.1,
												0
											]
										],
										[
											[
												503.4,
												341.3
											],
											[
												16.5,
												-1.8
											],
											[
												-16.4,
												1.8
											]
										],
										[
											460.9,
											349.7
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											759.4,
											487.5
										],
										[
											[
												703,
												461.1
											],
											[
												24.7,
												0.9
											],
											[
												-24.6,
												-0.9
											]
										],
										[
											[
												603.2,
												452.7
											],
											[
												17.5,
												0
											],
											[
												-17.5,
												0
											]
										],
										[
											[
												503.7,
												461.1
											],
											[
												24.6,
												-0.9
											],
											[
												-24.6,
												0.9
											]
										],
										[
											447.3,
											487.5
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											781.3,
											552.1
										],
										[
											[
												710.6,
												528.5
											],
											[
												29.4,
												3
											],
											[
												-29.4,
												-3
											]
										],
										[
											[
												603.6,
												523.5
											],
											[
												22,
												0
											],
											[
												-22,
												0
											]
										],
										[
											[
												496.9,
												528.5
											],
											[
												29.4,
												-3
											],
											[
												-29.4,
												3
											]
										],
										[
											426.2,
											552.1
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Group",
								{
									"applyMatrix": true,
									"children": [
										[
											"Group",
											{
												"applyMatrix": true,
												"children": [
													[
														"Path",
														{
															"applyMatrix": false,
															"segments": [
																[
																	[
																		726.9,
																		871.3
																	],
																	[
																		6.5,
																		107.3
																	],
																	[
																		0,
																		0
																	]
																],
																[
																	606.2,
																	871.3
																],
																[
																	605.1,
																	871.3
																],
																[
																	[
																		484.4,
																		871.3
																	],
																	[
																		0,
																		0
																	],
																	[
																		-6.6,
																		107.3
																	]
																],
																[
																	[
																		498.2,
																		1209.1
																	],
																	[
																		-11.8,
																		-171.1
																	],
																	[
																		3.9,
																		55.6
																	]
																],
																[
																	[
																		529.3,
																		1331
																	],
																	[
																		-21.7,
																		-51.1
																	],
																	[
																		17.5,
																		41.7
																	]
																],
																[
																	[
																		603.9,
																		1388.1
																	],
																	[
																		-33.7,
																		-1.1
																	],
																	[
																		0.6,
																		0
																	]
																],
																[
																	[
																		605.6,
																		1388
																	],
																	[
																		-0.6,
																		0
																	],
																	[
																		0.6,
																		0
																	]
																],
																[
																	[
																		607.3,
																		1388.1
																	],
																	[
																		-0.6,
																		0
																	],
																	[
																		33.7,
																		-1.1
																	]
																],
																[
																	[
																		681.9,
																		1331
																	],
																	[
																		-17.6,
																		41.6
																	],
																	[
																		21.6,
																		-51.1
																	]
																],
																[
																	[
																		713,
																		1209.1
																	],
																	[
																		-3.9,
																		55.5
																	],
																	[
																		11.9,
																		-171.2
																	]
																]
															],
															"closed": true,
															"fillColor": [
																"gradient",
																[
																	"#15"
																],
																[
																	605.6285,
																	1388.1
																],
																[
																	605.6285,
																	871.3
																]
															]
														}
													]
												]
											}
										]
									]
								}
							],
							[
								"Shape",
								{
									"applyMatrix": false,
									"matrix": [
										0.9682,
										-0.2501,
										0.2501,
										0.9682,
										373.09171,
										894.88477
									],
									"type": "ellipse",
									"size": [
										27.6,
										82.8
									],
									"radius": [
										13.8,
										41.4
									],
									"fillColor": [
										0.74902,
										0.74902,
										0.74902
									]
								}
							],
							[
								"Shape",
								{
									"applyMatrix": false,
									"matrix": [
										0.2501,
										-0.9682,
										0.9682,
										0.2501,
										835.48295,
										895.0804
									],
									"type": "ellipse",
									"size": [
										82.8,
										27.6
									],
									"radius": [
										41.4,
										13.8
									],
									"fillColor": [
										0.74902,
										0.74902,
										0.74902
									]
								}
							],
							[
								"Shape",
								{
									"applyMatrix": false,
									"matrix": [
										0.6819,
										-0.7314,
										0.7314,
										0.6819,
										515.97594,
										776.06349
									],
									"type": "ellipse",
									"size": [
										43.2,
										13.2
									],
									"radius": [
										21.6,
										6.6
									],
									"fillColor": [
										0.85098,
										0.85098,
										0.85098
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											562,
											685.5
										],
										[
											[
												491,
												756.2
											],
											[
												17.6,
												-45.1
											],
											[
												-17.6,
												45.1
											]
										],
										[
											[
												482.5,
												916.6
											],
											[
												4.2,
												-60.1
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											512.6,
											890.7
										],
										[
											[
												491.5,
												820.3
											],
											[
												-0.5,
												32.7
											],
											[
												0.4,
												-27.3
											]
										],
										[
											[
												508.8,
												770
											],
											[
												-9.1,
												10.7
											],
											[
												18,
												-21.1
											]
										],
										[
											[
												605.8,
												746
											],
											[
												-27.9,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												536.1,
												754.2
											],
											[
												0,
												0
											],
											[
												0,
												86.5
											]
										],
										[
											[
												605.7,
												871.3
											],
											[
												0.3,
												-26.6
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											550.3,
											750.7
										],
										[
											[
												605.7,
												820.3
											],
											[
												-60.3,
												1.6
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												605.8,
												763.2
											],
											[
												0,
												0
											],
											[
												0.2,
												16.4
											]
										],
										[
											[
												570.9,
												809.4
											],
											[
												0,
												-44
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Shape",
								{
									"applyMatrix": false,
									"matrix": [
										0.7314,
										-0.6819,
										0.6819,
										0.7314,
										695.58673,
										776.1131
									],
									"type": "ellipse",
									"size": [
										13.2,
										43.2
									],
									"radius": [
										6.6,
										21.6
									],
									"fillColor": [
										0.85098,
										0.85098,
										0.85098
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												729.3,
												916.6
											],
											[
												0,
												0
											],
											[
												-4.2,
												-60.1
											]
										],
										[
											[
												720.8,
												756.2
											],
											[
												17.5,
												45
											],
											[
												-17.6,
												-45
											]
										],
										[
											649.8,
											685.5
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												605.9,
												746
											],
											[
												0,
												0
											],
											[
												27.9,
												0
											]
										],
										[
											[
												702.9,
												770
											],
											[
												-17.9,
												-21.2
											],
											[
												9,
												10.6
											]
										],
										[
											[
												720.2,
												820.3
											],
											[
												-0.4,
												-27.3
											],
											[
												0.5,
												32.7
											]
										],
										[
											699.1,
											890.7
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												606.1,
												871.3
											],
											[
												0,
												0
											],
											[
												-0.3,
												-26.6
											]
										],
										[
											[
												675.7,
												754.2
											],
											[
												0,
												86.5
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												606.1,
												820.2
											],
											[
												0,
												0
											],
											[
												60.2,
												1.6
											]
										],
										[
											661.5,
											750.6
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												640.9,
												809.4
											],
											[
												0,
												0
											],
											[
												0,
												-44
											]
										],
										[
											[
												606,
												763.2
											],
											[
												-0.3,
												16.4
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.75
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											605.5,
											42.5
										],
										[
											[
												577.7,
												54
											],
											[
												27.8,
												-0.1
											],
											[
												-31.8,
												0
											]
										],
										[
											[
												428.4,
												77.2
											],
											[
												61.9,
												-20.2
											],
											[
												-42.8,
												14
											]
										],
										[
											[
												297.4,
												243.9
											],
											[
												16.3,
												-41.5
											],
											[
												-103.4,
												264.2
											]
										],
										[
											[
												253.3,
												993.6
											],
											[
												-1.5,
												-300.6
											],
											[
												0.9,
												169.4
											]
										],
										[
											[
												362.6,
												1400.4
											],
											[
												-57.2,
												-85
											],
											[
												57.1,
												85
											]
										],
										[
											[
												604.4,
												1502.3
											],
											[
												-20.8,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											605.5,
											20.4
										],
										[
											[
												413.5,
												56.9
											],
											[
												96.2,
												-43.5
											],
											[
												-39.4,
												17.9
											]
										],
										[
											[
												245.5,
												350.3
											],
											[
												19.9,
												-138
											],
											[
												-43.8,
												304
											]
										],
										[
											[
												242.2,
												1043.2
											],
											[
												-1.5,
												-325.2
											],
											[
												0.9,
												183.2
											]
										],
										[
											[
												341.6,
												1400.4
											],
											[
												-53,
												-93.6
											],
											[
												53.2,
												93.9
											]
										],
										[
											[
												605.2,
												1520
											],
											[
												-20.8,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											605.5,
											42.5
										],
										[
											[
												633.3,
												54
											],
											[
												-27.8,
												-0.1
											],
											[
												31.8,
												0
											]
										],
										[
											[
												782.6,
												77.2
											],
											[
												-61.9,
												-20.2
											],
											[
												42.8,
												14
											]
										],
										[
											[
												913.6,
												243.9
											],
											[
												-16.3,
												-41.5
											],
											[
												103.4,
												264.2
											]
										],
										[
											[
												957.7,
												993.6
											],
											[
												1.5,
												-300.6
											],
											[
												-0.9,
												169.4
											]
										],
										[
											[
												848.4,
												1400.4
											],
											[
												57.2,
												-85
											],
											[
												-57.1,
												85
											]
										],
										[
											[
												604.4,
												1502.3
											],
											[
												20.8,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											605.5,
											20.4
										],
										[
											[
												797.5,
												56.9
											],
											[
												-96.1,
												-43.5
											],
											[
												39.4,
												17.9
											]
										],
										[
											[
												965.6,
												350.3
											],
											[
												-19.8,
												-138
											],
											[
												43.8,
												304
											]
										],
										[
											[
												968.9,
												1043.2
											],
											[
												1.5,
												-325.2
											],
											[
												-0.9,
												183.2
											]
										],
										[
											[
												869.5,
												1400.4
											],
											[
												53,
												-93.6
											],
											[
												-53.2,
												93.9
											]
										],
										[
											[
												605.2,
												1520
											],
											[
												20.8,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									]
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												369.4,
												1228.8
											],
											[
												0,
												0
											],
											[
												25.8,
												22
											]
										],
										[
											411.7,
											1242.8
										],
										[
											[
												371.1,
												1242.7
											],
											[
												9.1,
												9
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												382.1,
												1262.3
											],
											[
												0,
												0
											],
											[
												9.7,
												-1.2
											]
										],
										[
											412.5,
											1249.2
										],
										[
											[
												396.5,
												1285.2
											],
											[
												-5.4,
												-13.3
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												840.2,
												1241.6
											],
											[
												0,
												0
											],
											[
												-9.1,
												9
											]
										],
										[
											799.6,
											1241.7
										],
										[
											[
												841.9,
												1227.7
											],
											[
												-25.9,
												22
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												814.7,
												1284.1
											],
											[
												0,
												0
											],
											[
												5.4,
												-13.2
											]
										],
										[
											798.7,
											1248.1
										],
										[
											[
												829.1,
												1261.2
											],
											[
												-9.7,
												-1.2
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												804,
												615.1
											],
											[
												0,
												0
											],
											[
												-18.3,
												-20.2
											]
										],
										[
											[
												708.1,
												574.8
											],
											[
												21.5,
												6
											],
											[
												-21.5,
												-6
											]
										],
										[
											603.8,
											568.5
										],
										[
											[
												499.5,
												574.8
											],
											[
												21.5,
												-6
											],
											[
												-21.5,
												6
											]
										],
										[
											[
												403.6,
												615.1
											],
											[
												18.3,
												-20.2
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											502.5,
											595.5
										],
										[
											[
												603.1,
												607.2
											],
											[
												-37.3,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											474.7,
											605.7
										],
										[
											[
												603.4,
												628.4
											],
											[
												-44.5,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											705.1,
											595.5
										],
										[
											[
												603.2,
												607.2
											],
											[
												37.3,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											733.2,
											605.7
										],
										[
											[
												603,
												628.4
											],
											[
												44.5,
												0
											],
											[
												0,
												0
											]
										]
									],
									"strokeColor": [
										0,
										0,
										0
									],
									"strokeWidth": 0.25
								}
							]
						]
					}
				],
				[
					"Group",
					{
						"name": "feline_occlusal",
						"applyMatrix": true,
						"visible": false,
						"children": [
							[
								"Group",
								{
									"name": "feline_occlusal_lower_2_",
									"applyMatrix": true,
									"children": [
										[
											"Group",
											{
												"name": "fourthQuadrant_2_",
												"applyMatrix": true,
												"children": [
													[
														"Group",
														{
															"name": "feline_occlusal_lower_401_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					577.2,
																					1404.2
																				],
																				[
																					-7.8,
																					12.7
																				],
																				[
																					9.6,
																					-0.6
																				]
																			],
																			[
																				[
																					586.8,
																					1403.6
																				],
																				[
																					0,
																					0
																				],
																				[
																					4.8,
																					12
																				]
																			],
																			[
																				[
																					592.8,
																					1418.6
																				],
																				[
																					1.2,
																					-7.2
																				],
																				[
																					-1.2,
																					7.2
																				]
																			],
																			[
																				[
																					586.8,
																					1428.8
																				],
																				[
																					8.4,
																					-3.6
																				],
																				[
																					-8.4,
																					3.6
																				]
																			],
																			[
																				[
																					574.8,
																					1427.6
																				],
																				[
																					2.4,
																					3
																				],
																				[
																					-2.4,
																					-2.9
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_402_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					527.9,
																					1424.1
																				],
																				[
																					-3,
																					-4.2
																				],
																				[
																					10.8,
																					2.4
																				]
																			],
																			[
																				[
																					552.5,
																					1423.5
																				],
																				[
																					-1.2,
																					6
																				],
																				[
																					1.2,
																					-6
																				]
																			],
																			[
																				[
																					545.3,
																					1401.3
																				],
																				[
																					4.8,
																					1.8
																				],
																				[
																					-4.8,
																					-1.8
																				]
																			],
																			[
																				[
																					533.9,
																					1401.3
																				],
																				[
																					1.2,
																					-2.4
																				],
																				[
																					-1.2,
																					2.3
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_403_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					484.1,
																					1416.9
																				],
																				[
																					-1.8,
																					-5.4
																				],
																				[
																					1.5,
																					4.6
																				]
																			],
																			[
																				[
																					508.7,
																					1417.5
																				],
																				[
																					-3.6,
																					6
																				],
																				[
																					3.6,
																					-6
																				]
																			],
																			[
																				[
																					509.9,
																					1399.5
																				],
																				[
																					2.4,
																					4.8
																				],
																				[
																					-2.4,
																					-4.8
																				]
																			],
																			[
																				[
																					499.1,
																					1391.7
																				],
																				[
																					3,
																					0
																				],
																				[
																					-3,
																					0
																				]
																			],
																			[
																				[
																					482.9,
																					1397.7
																				],
																				[
																					0,
																					-4.8
																				],
																				[
																					0,
																					4.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_404_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					419.8,
																					1347.8
																				],
																				[
																					10.2,
																					-21.7
																				],
																				[
																					-2.1,
																					4.5
																				]
																			],
																			[
																				[
																					413.8,
																					1359.2
																				],
																				[
																					12,
																					-5.4
																				],
																				[
																					-12,
																					5.4
																				]
																			],
																			[
																				[
																					377.8,
																					1373
																				],
																				[
																					2.4,
																					-9
																				],
																				[
																					-3.3,
																					12.2
																				]
																			],
																			[
																				[
																					409,
																					1406.4
																				],
																				[
																					-13.2,
																					-4.8
																				],
																				[
																					13.2,
																					4.8
																				]
																			],
																			[
																				[
																					448.7,
																					1403.6
																				],
																				[
																					-14.8,
																					8.1
																				],
																				[
																					10.5,
																					-5.7
																				]
																			],
																			[
																				[
																					469.1,
																					1369.4
																				],
																				[
																					0.6,
																					9.6
																				],
																				[
																					-0.6,
																					-9.6
																				]
																			],
																			[
																				[
																					455.3,
																					1340
																				],
																				[
																					7.2,
																					2.4
																				],
																				[
																					-7.3,
																					-2.4
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_407_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					412,
																					1122.5
																				],
																				[
																					0,
																					0
																				],
																				[
																					-15.6,
																					12
																				]
																			],
																			[
																				[
																					409.6,
																					1155.5
																				],
																				[
																					-5.4,
																					-6.6
																				],
																				[
																					5.4,
																					6.6
																				]
																			],
																			[
																				[
																					427,
																					1191.5
																				],
																				[
																					-6.6,
																					-7.8
																				],
																				[
																					6.6,
																					7.8
																				]
																			],
																			[
																				[
																					456.4,
																					1203.5
																				],
																				[
																					-9,
																					3.6
																				],
																				[
																					9,
																					-3.6
																				]
																			],
																			[
																				[
																					463,
																					1168.7
																				],
																				[
																					10.2,
																					24
																				],
																				[
																					-10.2,
																					-24
																				]
																			],
																			[
																				[
																					427.6,
																					1117
																				],
																				[
																					12.6,
																					-0.6
																				],
																				[
																					-12.6,
																					0.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					414.1,
																					1138.2
																				],
																				[
																					12.4,
																					-5.4
																				],
																				[
																					1.6,
																					1.6
																				]
																			],
																			[
																				415.7,
																				1139.8
																			],
																			[
																				[
																					433.9,
																					1132.6
																				],
																				[
																					-11.4,
																					0
																				],
																				[
																					-2,
																					-1.4
																				]
																			],
																			[
																				433.2,
																				1130.1
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_408_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					408.4,
																					1104.4
																				],
																				[
																					-16.3,
																					7.3
																				],
																				[
																					11,
																					-4.9
																				]
																			],
																			[
																				[
																					421,
																					1078
																				],
																				[
																					1.8,
																					10.2
																				],
																				[
																					-1.8,
																					-10.2
																				]
																			],
																			[
																				[
																					410.8,
																					1048
																				],
																				[
																					2.4,
																					14.4
																				],
																				[
																					-2.4,
																					-14.4
																				]
																			],
																			[
																				[
																					392.8,
																					1004.7
																				],
																				[
																					9.6,
																					7.9
																				],
																				[
																					-9.6,
																					-7.8
																				]
																			],
																			[
																				[
																					359.2,
																					1005.9
																				],
																				[
																					5.4,
																					-6.6
																				],
																				[
																					-5.4,
																					6.6
																				]
																			],
																			[
																				[
																					354.4,
																					1040.7
																				],
																				[
																					-6.6,
																					-15.6
																				],
																				[
																					6.6,
																					15.6
																				]
																			],
																			[
																				[
																					372.4,
																					1076.7
																				],
																				[
																					-7.8,
																					-6
																				],
																				[
																					4.1,
																					21.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					362.6,
																					1033.9
																				],
																				[
																					0,
																					0
																				],
																				[
																					10.2,
																					0
																				]
																			],
																			[
																				[
																					382.4,
																					1029.1
																				],
																				[
																					-4.2,
																					3
																				],
																				[
																					-0.6,
																					5.4
																				]
																			],
																			[
																				[
																					384.8,
																					1035.7
																				],
																				[
																					-0.6,
																					-0.6
																				],
																				[
																					-10.8,
																					-0.6
																				]
																			],
																			[
																				[
																					363.2,
																					1039.3
																				],
																				[
																					3.6,
																					-4.2
																				],
																				[
																					1.6,
																					-4.1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					387.1,
																					1086.1
																				],
																				[
																					12.6,
																					2.5
																				],
																				[
																					2,
																					0.7
																				]
																			],
																			[
																				388.9,
																				1089
																			],
																			[
																				[
																					409,
																					1080.2
																				],
																				[
																					-3.4,
																					7
																				],
																				[
																					-1.6,
																					-1.4
																				]
																			],
																			[
																				[
																					407.2,
																					1077.3
																				],
																				[
																					0,
																					0
																				],
																				[
																					-3,
																					6.3
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_409_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					371.7,
																					931.1
																				],
																				[
																					0,
																					0
																				],
																				[
																					14.4,
																					17.4
																				]
																			],
																			[
																				[
																					387.3,
																					976.8
																				],
																				[
																					7.8,
																					-10.3
																				],
																				[
																					-7.8,
																					10.3
																				]
																			],
																			[
																				[
																					353.7,
																					986.4
																				],
																				[
																					13.8,
																					6
																				],
																				[
																					-13.8,
																					-6
																				]
																			],
																			[
																				[
																					330.9,
																					955.2
																				],
																				[
																					4.8,
																					7.8
																				],
																				[
																					-4.8,
																					-7.8
																				]
																			],
																			[
																				[
																					309.9,
																					889.7
																				],
																				[
																					-5.4,
																					21.6
																				],
																				[
																					5.4,
																					-21.6
																				]
																			],
																			[
																				[
																					339.9,
																					874.1
																				],
																				[
																					-13.2,
																					-9.6
																				],
																				[
																					13.2,
																					9.6
																				]
																			],
																			[
																				[
																					357.9,
																					903.5
																				],
																				[
																					-3,
																					-14.4
																				],
																				[
																					3,
																					14.4
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					357.6,
																					926.3
																				],
																				[
																					-5.1,
																					9
																				],
																				[
																					1.8,
																					6.9
																				]
																			],
																			[
																				[
																					366.6,
																					941
																				],
																				[
																					0,
																					0
																				],
																				[
																					-7,
																					-1.4
																				]
																			],
																			[
																				[
																					343.5,
																					943.7
																				],
																				[
																					4.9,
																					-7.2
																				],
																				[
																					-0.3,
																					-3
																				]
																			],
																			[
																				[
																					339,
																					939.5
																				],
																				[
																					3.8,
																					1.2
																				],
																				[
																					11.8,
																					2
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													]
												]
											}
										],
										[
											"Group",
											{
												"name": "thirdQuadrant_2_",
												"applyMatrix": true,
												"children": [
													[
														"Group",
														{
															"name": "feline_occlusal_lower_301_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					631.6,
																					1404.2
																				],
																				[
																					7.8,
																					12.7
																				],
																				[
																					-9.6,
																					-0.6
																				]
																			],
																			[
																				[
																					622,
																					1403.6
																				],
																				[
																					0,
																					0
																				],
																				[
																					-4.8,
																					12
																				]
																			],
																			[
																				[
																					616,
																					1418.6
																				],
																				[
																					-1.2,
																					-7.2
																				],
																				[
																					1.2,
																					7.2
																				]
																			],
																			[
																				[
																					622,
																					1428.8
																				],
																				[
																					-8.4,
																					-3.6
																				],
																				[
																					8.4,
																					3.6
																				]
																			],
																			[
																				[
																					634,
																					1427.6
																				],
																				[
																					-2.4,
																					3
																				],
																				[
																					2.4,
																					-2.9
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_302_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					680.8,
																					1424.1
																				],
																				[
																					3,
																					-4.2
																				],
																				[
																					-10.8,
																					2.4
																				]
																			],
																			[
																				[
																					656.2,
																					1423.5
																				],
																				[
																					1.2,
																					6
																				],
																				[
																					-1.2,
																					-6
																				]
																			],
																			[
																				[
																					663.4,
																					1401.3
																				],
																				[
																					-4.8,
																					1.8
																				],
																				[
																					4.8,
																					-1.8
																				]
																			],
																			[
																				[
																					674.8,
																					1401.3
																				],
																				[
																					-1.2,
																					-2.4
																				],
																				[
																					1.2,
																					2.3
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_303_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					724.7,
																					1416.9
																				],
																				[
																					1.8,
																					-5.4
																				],
																				[
																					-1.5,
																					4.6
																				]
																			],
																			[
																				[
																					700.1,
																					1417.5
																				],
																				[
																					3.6,
																					6
																				],
																				[
																					-3.6,
																					-6
																				]
																			],
																			[
																				[
																					698.9,
																					1399.5
																				],
																				[
																					-2.4,
																					4.8
																				],
																				[
																					2.4,
																					-4.8
																				]
																			],
																			[
																				[
																					709.7,
																					1391.7
																				],
																				[
																					-3,
																					0
																				],
																				[
																					3,
																					0
																				]
																			],
																			[
																				[
																					725.9,
																					1397.7
																				],
																				[
																					0,
																					-4.8
																				],
																				[
																					0,
																					4.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_304_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					789,
																					1347.8
																				],
																				[
																					-10.3,
																					-21.7
																				],
																				[
																					2.1,
																					4.5
																				]
																			],
																			[
																				[
																					795,
																					1359.2
																				],
																				[
																					-12,
																					-5.4
																				],
																				[
																					12,
																					5.4
																				]
																			],
																			[
																				[
																					831,
																					1373
																				],
																				[
																					-2.4,
																					-9
																				],
																				[
																					3.3,
																					12.2
																				]
																			],
																			[
																				[
																					799.8,
																					1406.4
																				],
																				[
																					13.2,
																					-4.8
																				],
																				[
																					-13.2,
																					4.8
																				]
																			],
																			[
																				[
																					760.1,
																					1403.6
																				],
																				[
																					14.8,
																					8.1
																				],
																				[
																					-10.5,
																					-5.7
																				]
																			],
																			[
																				[
																					739.7,
																					1369.4
																				],
																				[
																					-0.6,
																					9.6
																				],
																				[
																					0.6,
																					-9.6
																				]
																			],
																			[
																				[
																					753.5,
																					1340
																				],
																				[
																					-7.2,
																					2.4
																				],
																				[
																					7.2,
																					-2.4
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_307_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					796.8,
																					1122.5
																				],
																				[
																					0,
																					0
																				],
																				[
																					15.6,
																					12
																				]
																			],
																			[
																				[
																					799.2,
																					1155.5
																				],
																				[
																					5.4,
																					-6.6
																				],
																				[
																					-5.4,
																					6.6
																				]
																			],
																			[
																				[
																					781.8,
																					1191.5
																				],
																				[
																					6.6,
																					-7.8
																				],
																				[
																					-6.6,
																					7.8
																				]
																			],
																			[
																				[
																					752.4,
																					1203.5
																				],
																				[
																					9,
																					3.6
																				],
																				[
																					-9,
																					-3.6
																				]
																			],
																			[
																				[
																					745.8,
																					1168.7
																				],
																				[
																					-10.2,
																					24
																				],
																				[
																					10.2,
																					-24
																				]
																			],
																			[
																				[
																					781.2,
																					1117
																				],
																				[
																					-12.6,
																					-0.6
																				],
																				[
																					12.6,
																					0.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					794.6,
																					1138.2
																				],
																				[
																					-12.4,
																					-5.4
																				],
																				[
																					-1.6,
																					1.6
																				]
																			],
																			[
																				793,
																				1139.8
																			],
																			[
																				[
																					774.8,
																					1132.6
																				],
																				[
																					11.4,
																					0
																				],
																				[
																					2,
																					-1.4
																				]
																			],
																			[
																				775.5,
																				1130.1
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_308_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					800.4,
																					1104.4
																				],
																				[
																					16.2,
																					7.3
																				],
																				[
																					-11,
																					-4.9
																				]
																			],
																			[
																				[
																					787.8,
																					1078
																				],
																				[
																					-1.8,
																					10.2
																				],
																				[
																					1.8,
																					-10.2
																				]
																			],
																			[
																				[
																					798,
																					1048
																				],
																				[
																					-2.4,
																					14.4
																				],
																				[
																					2.4,
																					-14.4
																				]
																			],
																			[
																				[
																					816,
																					1004.7
																				],
																				[
																					-9.6,
																					7.9
																				],
																				[
																					9.6,
																					-7.8
																				]
																			],
																			[
																				[
																					849.6,
																					1005.9
																				],
																				[
																					-5.4,
																					-6.6
																				],
																				[
																					5.4,
																					6.6
																				]
																			],
																			[
																				[
																					854.4,
																					1040.7
																				],
																				[
																					6.6,
																					-15.6
																				],
																				[
																					-6.6,
																					15.6
																				]
																			],
																			[
																				[
																					836.4,
																					1076.7
																				],
																				[
																					7.8,
																					-6
																				],
																				[
																					-4.2,
																					21.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					846.1,
																					1033.9
																				],
																				[
																					0,
																					0
																				],
																				[
																					-10.2,
																					0
																				]
																			],
																			[
																				[
																					826.3,
																					1029.1
																				],
																				[
																					4.2,
																					3
																				],
																				[
																					0.6,
																					5.4
																				]
																			],
																			[
																				[
																					823.9,
																					1035.7
																				],
																				[
																					0.6,
																					-0.6
																				],
																				[
																					10.8,
																					-0.6
																				]
																			],
																			[
																				[
																					845.5,
																					1039.3
																				],
																				[
																					-3.6,
																					-4.2
																				],
																				[
																					-1.6,
																					-4.1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					821.6,
																					1086.1
																				],
																				[
																					-12.6,
																					2.5
																				],
																				[
																					-2,
																					0.7
																				]
																			],
																			[
																				819.8,
																				1089
																			],
																			[
																				[
																					799.7,
																					1080.2
																				],
																				[
																					3.4,
																					7
																				],
																				[
																					1.6,
																					-1.4
																				]
																			],
																			[
																				[
																					801.5,
																					1077.3
																				],
																				[
																					0,
																					0
																				],
																				[
																					3,
																					6.3
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_309_2_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					837,
																					931.1
																				],
																				[
																					0,
																					0
																				],
																				[
																					-14.4,
																					17.4
																				]
																			],
																			[
																				[
																					821.4,
																					976.8
																				],
																				[
																					-7.8,
																					-10.3
																				],
																				[
																					7.8,
																					10.3
																				]
																			],
																			[
																				[
																					855,
																					986.4
																				],
																				[
																					-13.8,
																					6
																				],
																				[
																					13.8,
																					-6
																				]
																			],
																			[
																				[
																					877.8,
																					955.2
																				],
																				[
																					-4.8,
																					7.8
																				],
																				[
																					4.8,
																					-7.8
																				]
																			],
																			[
																				[
																					898.8,
																					889.7
																				],
																				[
																					5.4,
																					21.6
																				],
																				[
																					-5.4,
																					-21.6
																				]
																			],
																			[
																				[
																					868.8,
																					874.1
																				],
																				[
																					13.2,
																					-9.6
																				],
																				[
																					-13.2,
																					9.6
																				]
																			],
																			[
																				[
																					850.8,
																					903.5
																				],
																				[
																					3,
																					-14.4
																				],
																				[
																					-3,
																					14.4
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					851.1,
																					926.3
																				],
																				[
																					5.1,
																					9
																				],
																				[
																					-1.8,
																					6.9
																				]
																			],
																			[
																				[
																					842.1,
																					941
																				],
																				[
																					0,
																					0
																				],
																				[
																					7,
																					-1.4
																				]
																			],
																			[
																				[
																					865.2,
																					943.7
																				],
																				[
																					-4.9,
																					-7.2
																				],
																				[
																					0.3,
																					-3
																				]
																			],
																			[
																				[
																					869.7,
																					939.5
																				],
																				[
																					-3.8,
																					1.2
																				],
																				[
																					-11.8,
																					2
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													]
												]
											}
										]
									]
								}
							],
							[
								"Group",
								{
									"name": "feline_occlusal_upper",
									"applyMatrix": true,
									"children": [
										[
											"Group",
											{
												"name": "firstQuadrant",
												"applyMatrix": true,
												"children": [
													[
														"Group",
														{
															"name": "feline_occlusal_upper_101",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				573.6,
																				162.3
																			],
																			[
																				566.6,
																				147.7
																			],
																			[
																				[
																					567.7,
																					135.5
																				],
																				[
																					-1.3,
																					2.3
																				],
																				[
																					1.4,
																					-2.3
																				]
																			],
																			[
																				[
																					576.3,
																					130.3
																				],
																				[
																					-4.3,
																					-0.2
																				],
																				[
																					4.3,
																					0.2
																				]
																			],
																			[
																				[
																					590.5,
																					137.1
																				],
																				[
																					-3.6,
																					-8.6
																				],
																				[
																					3.6,
																					8.6
																				]
																			],
																			[
																				[
																					589.8,
																					152.6
																				],
																				[
																					2.5,
																					-4.5
																				],
																				[
																					-4.1,
																					8.8
																				]
																			],
																			[
																				585.7,
																				161.4
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_102",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				529.7,
																				164.1
																			],
																			[
																				[
																					522.9,
																					136.2
																				],
																				[
																					-2.9,
																					9.7
																				],
																				[
																					19.6,
																					-2.9
																				]
																			],
																			[
																				[
																					551.5,
																					135.5
																				],
																				[
																					-5.2,
																					-3.4
																				],
																				[
																					5.2,
																					3.4
																				]
																			],
																			[
																				[
																					551.7,
																					151.7
																				],
																				[
																					2.1,
																					-4.9
																				],
																				[
																					-2,
																					5
																				]
																			],
																			[
																				[
																					537.7,
																					165.7
																				],
																				[
																					7,
																					0.6
																				],
																				[
																					-6.9,
																					-0.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_103",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					479.7,
																					157.1
																				],
																				[
																					-2.9,
																					-6.3
																				],
																				[
																					0,
																					0
																				]
																			],
																			[
																				482.2,
																				166.8
																			],
																			[
																				[
																					495.7,
																					174.9
																				],
																				[
																					-12.8,
																					1.1
																				],
																				[
																					12.8,
																					-1.1
																				]
																			],
																			[
																				[
																					511,
																					169.3
																				],
																				[
																					-4.3,
																					5.8
																				],
																				[
																					4.3,
																					-5.9
																				]
																			],
																			[
																				[
																					514.4,
																					156.2
																				],
																				[
																					2,
																					6.6
																				],
																				[
																					-2,
																					-6.5
																				]
																			],
																			[
																				[
																					511.5,
																					146.7
																				],
																				[
																					1.3,
																					3.9
																				],
																				[
																					-1.4,
																					-3.8
																				]
																			],
																			[
																				[
																					499.3,
																					138.6
																				],
																				[
																					8.4,
																					-1.1
																				],
																				[
																					-8.3,
																					1.1
																				]
																			],
																			[
																				[
																					482.6,
																					145.6
																				],
																				[
																					3,
																					-2.9
																				],
																				[
																					-2.9,
																					2.9
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_104",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					400.4,
																					221.8
																				],
																				[
																					0.2,
																					4.9
																				],
																				[
																					-1.5,
																					-33.3
																				]
																			],
																			[
																				[
																					404.5,
																					158.7
																				],
																				[
																					-2.2,
																					6.8
																				],
																				[
																					2.3,
																					-7.2
																				]
																			],
																			[
																				[
																					426.8,
																					121.5
																				],
																				[
																					-6.5,
																					0.5
																				],
																				[
																					6.1,
																					-0.4
																				]
																			],
																			[
																				[
																					437.5,
																					169.3
																				],
																				[
																					-1.9,
																					-9.1
																				],
																				[
																					2.5,
																					12
																				]
																			],
																			[
																				[
																					451.8,
																					226.5
																				],
																				[
																					-7.3,
																					-18.2
																				],
																				[
																					7.2,
																					18
																				]
																			],
																			[
																				[
																					434.7,
																					278.1
																				],
																				[
																					29.9,
																					1.6
																				],
																				[
																					-23.4,
																					-1.3
																				]
																			],
																			[
																				[
																					405.9,
																					247.5
																				],
																				[
																					1.5,
																					6
																				],
																				[
																					-1.7,
																					-6.1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_106",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				424.5,
																				356.9
																			],
																			[
																				[
																					415.5,
																					342.5
																				],
																				[
																					-4.3,
																					10.8
																				],
																				[
																					0,
																					-11.5
																				]
																			],
																			[
																				[
																					432.2,
																					324.5
																				],
																				[
																					-7.9,
																					0
																				],
																				[
																					7.9,
																					0
																				]
																			],
																			[
																				[
																					455.2,
																					336
																				],
																				[
																					-0.9,
																					-8.8
																				],
																				[
																					0.9,
																					8.8
																				]
																			],
																			[
																				[
																					444.2,
																					355.8
																				],
																				[
																					6.3,
																					-4.3
																				],
																				[
																					-6.4,
																					4.3
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				442.5,
																				326.1
																			],
																			[
																				[
																					445,
																					336.7
																				],
																				[
																					1.3,
																					-3.2
																				],
																				[
																					3.4,
																					-6.8
																				]
																			],
																			[
																				[
																					446.6,
																					327.5
																				],
																				[
																					0.7,
																					0.9
																				],
																				[
																					-0.7,
																					-1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				430.4,
																				357.4
																			],
																			[
																				[
																					440.3,
																					350.9
																				],
																				[
																					-1.6,
																					3.6
																				],
																				[
																					-1.4,
																					5.9
																				]
																			],
																			[
																				436,
																				358.8
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_107",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					372,
																					487.4
																				],
																				[
																					-1.3,
																					-9.5
																				],
																				[
																					0.9,
																					6.2
																				]
																			],
																			[
																				[
																					396.8,
																					498.9
																				],
																				[
																					-4.5,
																					4
																				],
																				[
																					4.5,
																					-4.1
																				]
																			],
																			[
																				[
																					404.7,
																					487.9
																				],
																				[
																					-2.5,
																					1.8
																				],
																				[
																					2.5,
																					-1.8
																				]
																			],
																			[
																				[
																					436.5,
																					450.1
																				],
																				[
																					-1.4,
																					10.3
																				],
																				[
																					1.4,
																					-10.4
																				]
																			],
																			[
																				[
																					436.7,
																					417
																				],
																				[
																					0,
																					2.2
																				],
																				[
																					0,
																					-2.2
																				]
																			],
																			[
																				[
																					437.4,
																					405.5
																				],
																				[
																					-1.4,
																					4.5
																				],
																				[
																					1.4,
																					-4.5
																				]
																			],
																			[
																				[
																					417.1,
																					391.5
																				],
																				[
																					9.7,
																					-1.3
																				],
																				[
																					-9.7,
																					1.4
																				]
																			],
																			[
																				[
																					395.7,
																					410
																				],
																				[
																					2.7,
																					-9.9
																				],
																				[
																					-2.7,
																					9.9
																				]
																			],
																			[
																				[
																					382.2,
																					444.9
																				],
																				[
																					4.3,
																					-7.2
																				],
																				[
																					-4.3,
																					7.1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				436.9,
																				409.4
																			],
																			[
																				[
																					421.4,
																					413.7
																				],
																				[
																					6.5,
																					3.4
																				],
																				[
																					10.1,
																					3.8
																				]
																			],
																			[
																				436.9,
																				412.8
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				393.2,
																				415.7
																			],
																			[
																				[
																					411.2,
																					411.9
																				],
																				[
																					-4.5,
																					-1.8
																				],
																				[
																					-11.5,
																					3.6
																				]
																			],
																			[
																				[
																					391.8,
																					420
																				],
																				[
																					1.2,
																					-2.5
																				],
																				[
																					-1.1,
																					2.5
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					393,
																					455.9
																				],
																				[
																					7.2,
																					5.4
																				],
																				[
																					0,
																					0
																				]
																			],
																			[
																				390.5,
																				461.5
																			],
																			[
																				[
																					408.5,
																					466.5
																				],
																				[
																					-6.5,
																					-10
																				],
																				[
																					2.9,
																					-4.3
																				]
																			],
																			[
																				411.4,
																				462.2
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_108",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					329.7,
																					629.8
																				],
																				[
																					-13.3,
																					-7
																				],
																				[
																					8.6,
																					4.5
																				]
																			],
																			[
																				[
																					363,
																					618.1
																				],
																				[
																					-18.2,
																					23.4
																				],
																				[
																					18.2,
																					-23.4
																				]
																			],
																			[
																				[
																					391.8,
																					561.8
																				],
																				[
																					-5.1,
																					10.8
																				],
																				[
																					5.2,
																					-10.8
																				]
																			],
																			[
																				[
																					411,
																					540.4
																				],
																				[
																					-9.7,
																					4.3
																				],
																				[
																					9.7,
																					-4.3
																				]
																			],
																			[
																				[
																					418.7,
																					524.6
																				],
																				[
																					1.3,
																					3.6
																				],
																				[
																					-1.4,
																					-3.6
																				]
																			],
																			[
																				[
																					390.3,
																					515.4
																				],
																				[
																					23.4,
																					-2.1
																				],
																				[
																					-8.1,
																					-10.6
																				]
																			],
																			[
																				[
																					361,
																					506.2
																				],
																				[
																					6.8,
																					-8.6
																				],
																				[
																					-6.8,
																					8.6
																				]
																			],
																			[
																				[
																					352.9,
																					526.3
																				],
																				[
																					0.2,
																					-2.1
																				],
																				[
																					-2,
																					2
																				]
																			],
																			[
																				[
																					331.5,
																					572.8
																				],
																				[
																					2.7,
																					-11.7
																				],
																				[
																					-2.7,
																					11.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				398.4,
																				549.3
																			],
																			[
																				[
																					386.4,
																					536.4
																				],
																				[
																					3.3,
																					0.9
																				],
																				[
																					9.3,
																					3.9
																				]
																			],
																			[
																				[
																					402.9,
																					546
																				],
																				[
																					-5.4,
																					0
																				],
																				[
																					-3.6,
																					2.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				353.3,
																				523.1
																			],
																			[
																				[
																					375.8,
																					530.8
																				],
																				[
																					-15.5,
																					-2.1
																				],
																				[
																					-16.4,
																					-0.1
																				]
																			],
																			[
																				[
																					351.1,
																					529.9
																				],
																				[
																					2.1,
																					-1.6
																				],
																				[
																					1.6,
																					-4.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					388.3,
																					513.5
																				],
																				[
																					0.8,
																					2.5
																				],
																				[
																					0,
																					0
																				]
																			],
																			[
																				390.2,
																				515.3
																			],
																			[
																				393.5,
																				514.8
																			],
																			[
																				[
																					387.8,
																					523
																				],
																				[
																					0.9,
																					-4
																				],
																				[
																					1,
																					-7.4
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					343.7,
																					579.8
																				],
																				[
																					3.9,
																					10.3
																				],
																				[
																					0,
																					0
																				]
																			],
																			[
																				[
																					337.3,
																					593.7
																				],
																				[
																					7.5,
																					-7.4
																				],
																				[
																					15.8,
																					-2.4
																				]
																			],
																			[
																				[
																					360.3,
																					596.3
																				],
																				[
																					-2.2,
																					-3.2
																				],
																				[
																					0,
																					-3.9
																				]
																			],
																			[
																				362,
																				591.8
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_109",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					341.2,
																					663.2
																				],
																				[
																					-0.8,
																					-5
																				],
																				[
																					1,
																					6.6
																				]
																			],
																			[
																				[
																					361.1,
																					673.2
																				],
																				[
																					-5.2,
																					1.2
																				],
																				[
																					5.3,
																					-1.2
																				]
																			],
																			[
																				[
																					373.1,
																					669.5
																				],
																				[
																					-5.6,
																					1.2
																				],
																				[
																					5.6,
																					-1.2
																				]
																			],
																			[
																				[
																					384.7,
																					656.1
																				],
																				[
																					5,
																					6
																				],
																				[
																					-3.2,
																					-6.2
																				]
																			],
																			[
																				[
																					367.1,
																					651.2
																				],
																				[
																					3.6,
																					1.6
																				],
																				[
																					-3.6,
																					-1.5
																				]
																			],
																			[
																				[
																					354.9,
																					652.4
																				],
																				[
																					2.4,
																					-1.7
																				],
																				[
																					-2.4,
																					1.7
																				]
																			],
																			[
																				[
																					346.3,
																					656.4
																				],
																				[
																					2.7,
																					-1.1
																				],
																				[
																					-2.7,
																					1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													]
												]
											}
										],
										[
											"Group",
											{
												"name": "secondQuadrant",
												"applyMatrix": true,
												"children": [
													[
														"Group",
														{
															"name": "feline_occlusal_upper_209",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					867.5,
																					663.2
																				],
																				[
																					0.8,
																					-5
																				],
																				[
																					-1,
																					6.6
																				]
																			],
																			[
																				[
																					847.6,
																					673.2
																				],
																				[
																					5.2,
																					1.2
																				],
																				[
																					-5.3,
																					-1.2
																				]
																			],
																			[
																				[
																					835.6,
																					669.5
																				],
																				[
																					5.6,
																					1.2
																				],
																				[
																					-5.6,
																					-1.3
																				]
																			],
																			[
																				[
																					824,
																					656
																				],
																				[
																					-5,
																					6
																				],
																				[
																					3.2,
																					-6.2
																				]
																			],
																			[
																				[
																					841.6,
																					651.1
																				],
																				[
																					-3.6,
																					1.6
																				],
																				[
																					3.6,
																					-1.5
																				]
																			],
																			[
																				[
																					853.8,
																					652.3
																				],
																				[
																					-2.4,
																					-1.7
																				],
																				[
																					2.4,
																					1.7
																				]
																			],
																			[
																				[
																					862.4,
																					656.3
																				],
																				[
																					-2.7,
																					-1.1
																				],
																				[
																					2.7,
																					1.1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_208",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					879.1,
																					629.8
																				],
																				[
																					13.3,
																					-7
																				],
																				[
																					-8.6,
																					4.5
																				]
																			],
																			[
																				[
																					845.8,
																					618.1
																				],
																				[
																					18.2,
																					23.4
																				],
																				[
																					-18.2,
																					-23.4
																				]
																			],
																			[
																				[
																					817,
																					561.8
																				],
																				[
																					5.1,
																					10.8
																				],
																				[
																					-5.2,
																					-10.8
																				]
																			],
																			[
																				[
																					797.8,
																					540.4
																				],
																				[
																					9.7,
																					4.3
																				],
																				[
																					-9.7,
																					-4.3
																				]
																			],
																			[
																				[
																					790.1,
																					524.6
																				],
																				[
																					-1.3,
																					3.6
																				],
																				[
																					1.4,
																					-3.6
																				]
																			],
																			[
																				[
																					818.5,
																					515.4
																				],
																				[
																					-23.4,
																					-2.1
																				],
																				[
																					8.1,
																					-10.6
																				]
																			],
																			[
																				[
																					847.8,
																					506.2
																				],
																				[
																					-6.8,
																					-8.6
																				],
																				[
																					6.8,
																					8.6
																				]
																			],
																			[
																				[
																					855.9,
																					526.3
																				],
																				[
																					-0.2,
																					-2.1
																				],
																				[
																					2,
																					2
																				]
																			],
																			[
																				[
																					877.3,
																					572.8
																				],
																				[
																					-2.7,
																					-11.7
																				],
																				[
																					2.7,
																					11.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				810.3,
																				549.3
																			],
																			[
																				[
																					822.3,
																					536.4
																				],
																				[
																					-3.3,
																					0.9
																				],
																				[
																					-9.3,
																					3.9
																				]
																			],
																			[
																				[
																					805.8,
																					546
																				],
																				[
																					5.4,
																					0
																				],
																				[
																					3.6,
																					2.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				855.4,
																				523.1
																			],
																			[
																				[
																					832.9,
																					530.8
																				],
																				[
																					15.5,
																					-2.1
																				],
																				[
																					16.4,
																					-0.1
																				]
																			],
																			[
																				[
																					857.6,
																					529.9
																				],
																				[
																					-2.1,
																					-1.6
																				],
																				[
																					-1.6,
																					-4.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					820.4,
																					513.5
																				],
																				[
																					-0.8,
																					2.5
																				],
																				[
																					0,
																					0
																				]
																			],
																			[
																				818.5,
																				515.3
																			],
																			[
																				815.2,
																				514.8
																			],
																			[
																				[
																					820.9,
																					523
																				],
																				[
																					-0.9,
																					-4
																				],
																				[
																					-1,
																					-7.4
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					865.1,
																					579.8
																				],
																				[
																					-4,
																					10.3
																				],
																				[
																					0,
																					0
																				]
																			],
																			[
																				[
																					871.5,
																					593.7
																				],
																				[
																					-7.5,
																					-7.4
																				],
																				[
																					-15.8,
																					-2.4
																				]
																			],
																			[
																				[
																					848.5,
																					596.3
																				],
																				[
																					2.2,
																					-3.2
																				],
																				[
																					0,
																					-3.9
																				]
																			],
																			[
																				846.8,
																				591.8
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_207",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					836.7,
																					487.4
																				],
																				[
																					1.4,
																					-9.5
																				],
																				[
																					-0.9,
																					6.2
																				]
																			],
																			[
																				[
																					811.9,
																					498.9
																				],
																				[
																					4.5,
																					4
																				],
																				[
																					-4.5,
																					-4.1
																				]
																			],
																			[
																				[
																					804,
																					487.9
																				],
																				[
																					2.5,
																					1.8
																				],
																				[
																					-2.5,
																					-1.8
																				]
																			],
																			[
																				[
																					772.2,
																					450.1
																				],
																				[
																					1.4,
																					10.3
																				],
																				[
																					-1.4,
																					-10.4
																				]
																			],
																			[
																				[
																					772,
																					417
																				],
																				[
																					0,
																					2.2
																				],
																				[
																					0,
																					-2.2
																				]
																			],
																			[
																				[
																					771.3,
																					405.5
																				],
																				[
																					1.4,
																					4.5
																				],
																				[
																					-1.4,
																					-4.5
																				]
																			],
																			[
																				[
																					791.6,
																					391.5
																				],
																				[
																					-9.7,
																					-1.3
																				],
																				[
																					9.7,
																					1.4
																				]
																			],
																			[
																				[
																					813,
																					410
																				],
																				[
																					-2.7,
																					-9.9
																				],
																				[
																					2.7,
																					9.9
																				]
																			],
																			[
																				[
																					826.5,
																					444.9
																				],
																				[
																					-4.3,
																					-7.2
																				],
																				[
																					4.4,
																					7.1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				771.8,
																				409.4
																			],
																			[
																				[
																					787.3,
																					413.7
																				],
																				[
																					-6.5,
																					3.4
																				],
																				[
																					-10.1,
																					3.8
																				]
																			],
																			[
																				771.8,
																				412.8
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				815.5,
																				415.7
																			],
																			[
																				[
																					797.5,
																					411.9
																				],
																				[
																					4.5,
																					-1.8
																				],
																				[
																					11.5,
																					3.6
																				]
																			],
																			[
																				[
																					816.9,
																					420
																				],
																				[
																					-1.2,
																					-2.5
																				],
																				[
																					1.1,
																					2.5
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					815.8,
																					455.9
																				],
																				[
																					-7.2,
																					5.4
																				],
																				[
																					0,
																					0
																				]
																			],
																			[
																				818.3,
																				461.5
																			],
																			[
																				[
																					800.3,
																					466.5
																				],
																				[
																					6.5,
																					-10
																				],
																				[
																					-2.9,
																					-4.3
																				]
																			],
																			[
																				797.4,
																				462.2
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_206",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				784.2,
																				356.9
																			],
																			[
																				[
																					793.2,
																					342.5
																				],
																				[
																					4.3,
																					10.8
																				],
																				[
																					0,
																					-11.5
																				]
																			],
																			[
																				[
																					776.5,
																					324.5
																				],
																				[
																					7.9,
																					0
																				],
																				[
																					-7.9,
																					0
																				]
																			],
																			[
																				[
																					753.5,
																					336
																				],
																				[
																					0.9,
																					-8.8
																				],
																				[
																					-0.9,
																					8.8
																				]
																			],
																			[
																				[
																					764.5,
																					355.8
																				],
																				[
																					-6.3,
																					-4.3
																				],
																				[
																					6.4,
																					4.3
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				766.2,
																				326.1
																			],
																			[
																				[
																					763.7,
																					336.7
																				],
																				[
																					-1.3,
																					-3.2
																				],
																				[
																					-3.4,
																					-6.8
																				]
																			],
																			[
																				[
																					762.1,
																					327.5
																				],
																				[
																					-0.7,
																					0.9
																				],
																				[
																					0.7,
																					-1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				778.4,
																				357.4
																			],
																			[
																				[
																					768.5,
																					350.9
																				],
																				[
																					1.6,
																					3.6
																				],
																				[
																					1.4,
																					5.9
																				]
																			],
																			[
																				772.8,
																				358.8
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_204",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					808.3,
																					221.8
																				],
																				[
																					-0.2,
																					4.9
																				],
																				[
																					1.5,
																					-33.3
																				]
																			],
																			[
																				[
																					804.2,
																					158.7
																				],
																				[
																					2.2,
																					6.8
																				],
																				[
																					-2.3,
																					-7.2
																				]
																			],
																			[
																				[
																					781.9,
																					121.5
																				],
																				[
																					6.5,
																					0.5
																				],
																				[
																					-6.1,
																					-0.4
																				]
																			],
																			[
																				[
																					771.2,
																					169.3
																				],
																				[
																					1.9,
																					-9.1
																				],
																				[
																					-2.5,
																					12
																				]
																			],
																			[
																				[
																					756.9,
																					226.5
																				],
																				[
																					7.3,
																					-18.2
																				],
																				[
																					-7.2,
																					18
																				]
																			],
																			[
																				[
																					774,
																					278.1
																				],
																				[
																					-29.9,
																					1.6
																				],
																				[
																					23.4,
																					-1.3
																				]
																			],
																			[
																				[
																					802.8,
																					247.5
																				],
																				[
																					-1.5,
																					6
																				],
																				[
																					1.7,
																					-6.1
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_203",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					729,
																					157.1
																				],
																				[
																					3,
																					-6.3
																				],
																				[
																					0,
																					0
																				]
																			],
																			[
																				726.5,
																				166.8
																			],
																			[
																				[
																					713,
																					174.9
																				],
																				[
																					12.8,
																					1.1
																				],
																				[
																					-12.8,
																					-1.1
																				]
																			],
																			[
																				[
																					697.7,
																					169.3
																				],
																				[
																					4.3,
																					5.8
																				],
																				[
																					-4.3,
																					-5.9
																				]
																			],
																			[
																				[
																					694.3,
																					156.2
																				],
																				[
																					-2,
																					6.6
																				],
																				[
																					2,
																					-6.5
																				]
																			],
																			[
																				[
																					697.2,
																					146.7
																				],
																				[
																					-1.3,
																					3.9
																				],
																				[
																					1.4,
																					-3.8
																				]
																			],
																			[
																				[
																					709.4,
																					138.6
																				],
																				[
																					-8.4,
																					-1.1
																				],
																				[
																					8.3,
																					1.1
																				]
																			],
																			[
																				[
																					726.1,
																					145.6
																				],
																				[
																					-3,
																					-2.9
																				],
																				[
																					2.9,
																					2.9
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_202",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				679,
																				164.1
																			],
																			[
																				[
																					685.8,
																					136.2
																				],
																				[
																					2.9,
																					9.7
																				],
																				[
																					-19.6,
																					-2.9
																				]
																			],
																			[
																				[
																					657.2,
																					135.5
																				],
																				[
																					5.2,
																					-3.4
																				],
																				[
																					-5.2,
																					3.4
																				]
																			],
																			[
																				[
																					657,
																					151.7
																				],
																				[
																					-2.1,
																					-4.9
																				],
																				[
																					2,
																					5
																				]
																			],
																			[
																				[
																					671,
																					165.7
																				],
																				[
																					-7,
																					0.6
																				],
																				[
																					6.9,
																					-0.7
																				]
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_201",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				635.1,
																				162.3
																			],
																			[
																				642.1,
																				147.7
																			],
																			[
																				[
																					641,
																					135.5
																				],
																				[
																					1.3,
																					2.3
																				],
																				[
																					-1.3,
																					-2.3
																				]
																			],
																			[
																				[
																					632.4,
																					130.3
																				],
																				[
																					4.3,
																					-0.2
																				],
																				[
																					-4.3,
																					0.2
																				]
																			],
																			[
																				[
																					618.2,
																					137.1
																				],
																				[
																					3.6,
																					-8.6
																				],
																				[
																					-3.6,
																					8.6
																				]
																			],
																			[
																				[
																					618.9,
																					152.6
																				],
																				[
																					-2.5,
																					-4.5
																				],
																				[
																					4.1,
																					8.8
																				]
																			],
																			[
																				623,
																				161.4
																			]
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549
																		]
																	}
																]
															]
														}
													]
												]
											}
										]
									]
								}
							]
						]
					}
				]
			]
		}
	]
]


export const felineTissueConfig = {
	scaling: [1.37, 1.37],
	position: [550, 1100],
	opacity: 0.6,
};