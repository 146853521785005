import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions } from './UserManagement.redux';



const UserDeleteModal = () => {
	const dispatch = useDispatch(),
		  open     = useSelector(state => state.users.showDeleteModal),
		  info     = useSelector(state => state.users.deleteInfo),
		  deleting = useSelector(state => state.users.deleting);

	return (
		<Dialog open={open}>
			<DialogTitle>
				Confirm User Removal
			</DialogTitle>
			<DialogContent>
				Are you sure you want to remove {info.login_name} ({info.login_email})? The user will no longer have
				access to your clinic on Sabertooth Charting, but all work they have done will remain as-is.
				You can re-invite this user at any time.
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => dispatch(actions.deleteUser(info.link_id))}
					disabled={deleting}
				>
					Remove User
				</Button>


				<Button
					variant="outlined"
					onClick={() => dispatch(actions.resetDelete())}
					disabled={deleting}
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};


export default UserDeleteModal;
