import React from 'react';
import { useSelector } from "react-redux";
import { IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from "@mui/icons-material";
import useStyles from './PaginationBar.styles';



const PageinationBar = ({ onChangePage }) => {
	const classes    = useStyles(),
		  fileCount  = useSelector(state => state.files.file_count),
		  files      = useSelector(state => state.files.files),
		  pageNumber = useSelector(state => state.files.page_number),
		  pageCount  = useSelector(state => Math.ceil(state.files.file_count / state.files.page_size));

	// Out of range error on select if there are no pages
	if(!fileCount) {
		return null;
	}

	const handlePageSelect = (e) => {
		if(e.target.value === pageNumber) {
			return false;
		}

		onChangePage(e.target.value);
	};



	return (
        <div className={classes.bar}>
			<span>
				Showing items {(pageNumber * files.length) + 1} - {((pageNumber + 1) * files.length) > fileCount ? fileCount : ((pageNumber + 1) * files.length)} of {fileCount}
			</span>
			<Tooltip title="First Page">
				<span>
					<IconButton disabled={pageNumber === 0} onClick={() => onChangePage(0)} size="large">
						<FirstPage />
					</IconButton>
				</span>
			</Tooltip>

			<Tooltip title="Previous Page">
				<span>
					<IconButton
                        disabled={pageNumber === 0}
                        onClick={() => onChangePage(pageNumber - 1)}
                        size="large">
						<NavigateBefore />
					</IconButton>
				</span>
			</Tooltip>


			<Select value={pageNumber} className={classes.select} variant="outlined" onChange={handlePageSelect}>
				{Array.from(Array(pageCount)).map((row, index) => (
					<MenuItem
						key={index}
						value={index}
					>
						{index + 1}
					</MenuItem>
				))}
			</Select>


			<Tooltip title="Next Page">
				<span>
					<IconButton
                        disabled={pageNumber === pageCount - 1}
                        onClick={() => onChangePage(pageNumber + 1)}
                        size="large">
						<NavigateNext />
					</IconButton>
				</span>
			</Tooltip>

			<Tooltip title="Last Page">
				<span>
					<IconButton
                        disabled={pageNumber === pageCount - 1}
                        onClick={() => onChangePage(pageCount - 1)}
                        size="large">
						<LastPage />
					</IconButton>
				</span>
			</Tooltip>
		</div>
    );
};


export default PageinationBar;
