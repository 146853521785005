import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import { actions } from 'store';



const Logout = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		Cookies.remove('authToken');
		dispatch(actions.auth.setState({
			showLoginPage: true,
		}));
		dispatch(push('/'));
	});

	return null;
};

export default Logout;
