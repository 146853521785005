import React, { useEffect, useState } from 'react';
import DashboardCard from "components/DashboardCard";
import StorageIcon from '@mui/icons-material/Storage';
import PropTypes from 'prop-types';
import api from 'api';



const Storage = (props) => {
	const [ label, setLabel ] = useState('Loading information...');
	const [ message, setMessage ] = useState(' ');

	useEffect(() => {
		async function fetchQuota() {
			const result = await api.get('/files/quota');
			const quota = result.data.quota;
			const percentage = (Number(quota.quota_used) / Number(quota.quota_total)) * 100;

			setLabel(`${quota.quota_used} of ${quota.quota_total}GB Used`);
			setMessage(`You're using ${percentage.toFixed(1)}% of your storage.`);
		}

		fetchQuota().catch(err => console.log(err));
	}, [ setLabel, setMessage ]);

	return (
		<DashboardCard
			color={'lightBlue'}
			icon={<StorageIcon />}
			topLabel={label}
			topContent={"Storage"}
			size={props.size}
		>
			{message}
		</DashboardCard>
	);
};


Storage.defaultProps = {
	size: 'xs',
};

Storage.propTypes = {
	size: PropTypes.oneOf([ 'xs', 'sm', 'md', 'lg', 'xl' ]).isRequired,
};


export default Storage;
