import React, { useCallback, useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Edit, Person } from "@mui/icons-material";
import { Button, Grid, Link, MenuItem, Select, Skeleton, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './PatientView.redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useStyles from './PatientView.styles';
import dayjs from 'dayjs';
import DateOfBirth from './PatientViewInputs/DateOfBirth';
import IsNeutered from './PatientViewInputs/IsNeutered';
import uppercaseFirstLetter from 'lib/uppercaseFirstLetter';



const Profile = () => {
    const dispatch       = useDispatch(),
          classes        = useStyles(),
          { patient_id } = useParams(),
          loading        = useSelector(state => state.patientView.profileLoading),
          loaded         = useSelector(state => state.patientView.profileLoaded),
          editing        = useSelector(state => state.patientView.profileEditing),
          profile        = useSelector(state => state.patientView.profile),
          saving         = useSelector(state => state.patientView.profileSaving);


    const loadProfile = useCallback((patientID) => {
        dispatch(actions.loadProfile(patientID));
    }, [ dispatch ]);


    const handleEdit = useCallback(() => {
        dispatch(actions.setState({ profileEditing: true }));
    }, [ dispatch ]);


    const handleCancelEdit = useCallback(() => {
        dispatch(actions.cancelEdit());
    }, [ dispatch ]);


    const setProfileValue = useCallback((e) => {
        dispatch(actions.setProfileValue(e.target.name, e.target.value));
    }, [ dispatch ]);


    const handleSave = useCallback(() => {
        dispatch(actions.saveProfile(patient_id));
    }, [ dispatch, patient_id ]);


    const handleSetAge = useCallback((e) => {
        const { value } = e.target;

        if(!value) {
            dispatch(actions.setProfileValue('date_of_birth', ''));
            return false;
        }

        dispatch(actions.setProfileValue(
            'date_of_birth',
            dayjs().subtract(value, 'years').format('YYYY-MM-DD')
        ));
    }, [ dispatch ]);


    const generateInputProps = (name) => ({
        variant: 'outlined',
        value: profile[name],
        name: name,
        onChange: setProfileValue,
        fullWidth: true,
    });



    useEffect(() => {
        if(!loading && !loaded) {
            loadProfile(patient_id);
        }
    }, [ loadProfile, loading, loaded, patient_id ]);

    let genderLetter  = String(profile.gender)
            .substring(0, 1)
            .toUpperCase(),
        alteredLetter = '';

    if(profile.is_neutered) {
        alteredLetter = genderLetter === 'M'
            ? 'N'
            : 'S';
    }


    return (
        <DashboardCard
            color={'green'}
            icon={<Person />}
            topContent={loading
                ? <Skeleton
                    variant="rectangular"
                    width={200}
                    height={30}
                    style={{
                        marginLeft: 'auto',
                        marginRight: 0,
                        width: 200,
                    }}
                />
                : editing
                    ? <TextField
                        {...generateInputProps('patient_name')}
                    />
                    : profile.patient_name
            }
            topLabel={`${genderLetter}${alteredLetter} / ${profile.species || 'Species Not Set'} / ${profile.breed || 'Breed Not Set'} / ${profile.age_pretty}`}
            size="xl"
        >

            {(!loaded && loading) &&
                <div className={classes.profileLoadingContainer}>
                    <Skeleton animation="wave" height={30} />
                    <Skeleton animation="wave" height={30} />
                    <Skeleton animation="wave" height={30} />
                    <Skeleton animation="wave" height={30} />
                </div>
            }


            {(loaded && !loading) &&
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2">Gender</Typography>
                        {!editing &&
                            <Typography variant="body1" gutterBottom>
                                {uppercaseFirstLetter(profile.gender)}
                            </Typography>
                        }
                        {editing &&
                            <Select
                                {...generateInputProps('gender')}
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                            </Select>
                        }
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Altered?</Typography>
                        {!editing &&
                            <Typography
                                variant="body1"
                                gutterBottom
                            >{getNeuteredStatus(profile.is_neutered)}</Typography>
                        }
                        {editing &&
                            <IsNeutered />
                        }
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Breed</Typography>
                        {!editing &&
                            <Typography variant="body1" gutterBottom>{profile.breed || '(Not set)'}</Typography>
                        }
                        {editing &&
                            <TextField
                                {...generateInputProps('breed')}
                            />
                        }
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Species</Typography>
                        {!editing &&
                            <Typography
                                variant="body1"
                                gutterBottom
                            >
                                {uppercaseFirstLetter(profile.species) || '(Not set)'}
                            </Typography>
                        }
                        {editing &&
                            <Select
                                {...generateInputProps('species')}
                            >
                                <MenuItem value="canine">Canine</MenuItem>
                                <MenuItem value="feline">Feline</MenuItem>
                            </Select>
                        }
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">
                            {editing
                                ? 'Age (years)'
                                : 'Age'
                            }
                        </Typography>
                        {!editing &&
                            <Typography variant="body1" gutterBottom>
                                {profile.age_pretty}
                            </Typography>
                        }
                        {editing &&
                            <TextField
                                {...generateInputProps('age')}
                                type="number"
                                onChange={handleSetAge}
                            />
                        }
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Date of Birth</Typography>
                        {!editing &&
                            <Typography variant="body1" gutterBottom>
                                {
                                    profile.date_of_birth
                                        ? dayjs(profile.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY')
                                        : '(Not Set)'
                                }
                            </Typography>
                        }
                        {editing &&
                            <DateOfBirth />
                        }
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Primary Clinic</Typography>
                        {!editing &&
                            <Typography
                                variant="body1"
                                gutterBottom
                            >
                                {profile.primary_clinic || '(Not set)'}
                            </Typography>
                        }
                        {editing &&
                            <TextField
                                {...generateInputProps('primary_clinic')}
                            />
                        }
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Primary Vet</Typography>
                        {!editing &&
                            <Typography variant="body1" gutterBottom>{profile.primary_vet || '(Not set)'}</Typography>
                        }
                        {editing &&
                            <TextField
                                {...generateInputProps('primary_vet')}
                            />
                        }
                    </Grid>


                    <Grid item xs={6}>
                        <Typography variant="body2">Last Visit</Typography>
                        <Typography variant="body1" gutterBottom>
                            {
                                profile.last_visit
                                    ? dayjs(profile.last_visit, 'YYYY-MM-DD').format('MMMM D, YYYY')
                                    : 'Never'
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2">Client</Typography>

                        <Typography variant="body1" gutterBottom>
                            <Link
                                component={RouterLink}
                                to={`/clients/view/${profile.client_id}`}
                                color="inherit"
                            >
                                {profile.client_name}
                            </Link>
                        </Typography>
                    </Grid>


                    <Grid item xs={12}>
                        {!editing &&
                            <div>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    className={classes.editButton}
                                    startIcon={<Edit />}
                                    onClick={handleEdit}
                                >
                                    Edit
                                </Button>
                            </div>
                        }

                        {editing &&
                            <div>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    className={classes.editButton}
                                    startIcon={<Edit />}
                                    onClick={handleSave}
                                    disabled={saving}
                                >
                                    Save
                                </Button>

                                <Button
                                    size="large"
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.cancelButton}
                                    startIcon={<Edit />}
                                    onClick={handleCancelEdit}
                                    disabled={saving}
                                >
                                    Cancel
                                </Button>

                            </div>
                        }
                    </Grid>
                </Grid>
            }
        </DashboardCard>
    );
};



function getNeuteredStatus(status) {
    switch(status) {
        case false:
            return 'No';
        case true:
            return 'Yes';
        default:
            return '(Not Set)';
    }
}

export default Profile;
