import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions } from './FileManager.redux';
import FileGrid from './FileGrid';
import PaginationBar from './PaginationBar';



const RecentFiles = ({ selectActions = [] }) => {
	const dispatch = useDispatch(),
		  open     = useSelector(state => state.files.open),
		  loading  = useSelector(state => state.files.loading),
		  loaded   = useSelector(state => state.files.loaded);


	const handleChangePage = (pageNumber) => {
		dispatch(actions.getRecentFiles(pageNumber));
	};


	useEffect(() => {
		if(open && !loading && !loaded) {
			dispatch(actions.getRecentFiles(0));
		}
	}, [ dispatch, loading, loaded, open ]);

	if(loading) {
		return null;
	}

	return (
		<div>
			<FileGrid selectActions={selectActions} />
			<PaginationBar onChangePage={handleChangePage} />
		</div>

	);
};


export default RecentFiles;
