import React, { useState } from 'react';
import { AppBar, Badge, Drawer, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import {
    AccountCircle,
    ChevronLeft,
    Fullscreen,
    Mail as MailIcon,
    Menu as MenuIcon,
    MoreVert as MoreIcon,
    Notifications as NotificationsIcon,
} from '@mui/icons-material';
import classNames from 'classnames';
import Navigation from './Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store';
import AppSearch from './AppSearch';
import useStyles from './Layout.styles';
import ClinicSelection from 'pages/ClinicSelection';



const Layout = ({ children }) => {
    const [ state, setState ] = useState({
        anchorEl: null,
        mobileMoreAnchorEl: null,
    });

    const dispatch   = useDispatch(),
          toggleMenu = () => dispatch(actions.layout.toggleMenu()),
          classes    = useStyles();

    const menuOpen            = useSelector(state => (state.layout.menuOpenTemporary || state.layout.menuOpen)),
          title               = useSelector(state => state.layout.title),
          useLayout           = useSelector(state => state.layout.useLayout),
          showLogin           = useSelector(state => state.auth.showLoginPage),
          showClinicSelection = useSelector(state => state.auth.showClinicSelection);


    const handleProfileMenuOpen = event => {
        setState({ ...state, anchorEl: event.currentTarget });
    };


    const handleMenuClose = () => {
        setState({ ...state, anchorEl: null });
        handleMobileMenuClose();
    };


    const handleMobileMenuOpen = event => {
        setState({ ...state, mobileMoreAnchorEl: event.currentTarget });
    };


    const handleMobileMenuClose = () => {
        setState({ ...state, mobileMoreAnchorEl: null });
    };


    const toggleFullscreen = event => {
        let element = document.body;
        if (event instanceof HTMLElement) {
            element = event;
        }

        let isFullscreen          = document.webkitIsFullScreen || document.mozFullScreen || false;
        element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () {
            return false;
        };
        document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () {
            return false;
        };

        isFullscreen
            ? document.cancelFullScreen()
            : element.requestFullScreen();
    };


    const renderMenu = () => {
        if (!useLayout) return null;

        return (
            <Menu
                anchorEl={state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(state.anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            </Menu>
        );
    };


    const renderMobileMenu = () => {
        const { mobileMoreAnchorEl } = state;
        const isMobileMenuOpen       = Boolean(mobileMoreAnchorEl);

        if (!useLayout) return null;

        return (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            >
                <MenuItem>
                    <IconButton color="inherit" size="large">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <p>Messages</p>
                </MenuItem>
                <MenuItem>
                    <IconButton color="inherit" size="large">
                        <Badge badgeContent={11} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <p>Notifications</p>
                </MenuItem>
                <MenuItem onClick={handleProfileMenuOpen}>
                    <IconButton color="inherit" size="large">
                        <AccountCircle />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        );
    };


    const { anchorEl } = state;
    const isMenuOpen   = Boolean(anchorEl);


    if (!useLayout || showLogin) {
        return (
            <div className={classes.root}>
                {children}
            </div>
        );
    }

    if (showClinicSelection) {
        return (
            <div className={classes.root}>
                <ClinicSelection />
            </div>
        );
    }


    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                color="default"
                classes={{ colorDefault: classes.appBarBackground }}
                elevation={2}
                className={classNames(classes.appBar, {
                    [classes.appBarOpen]: menuOpen,
                    [classes.appBarClosed]: !menuOpen,
                })}
            >
                <Toolbar>
                    <Tooltip title="Toggle Menu">
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={toggleMenu}
                            className={classes.menuButton}
                            size="large">
                            {menuOpen
                                ? <ChevronLeft />
                                : <MenuIcon />}
                        </IconButton>
                    </Tooltip>

                    <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                        {title}
                    </Typography>

                    <div className={classes.grow} />
                    <AppSearch />
                    <div>
                        <Tooltip title="Toggle Fullscreen">
                            <IconButton color="inherit" onClick={toggleFullscreen} size="large">
                                <Fullscreen />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.sectionDesktop}>
                        <Tooltip title="Notifications">
                            <IconButton color="inherit" size="large">
                                <Badge badgeContent={17} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Account Options">
                            <IconButton
                                aria-owns={isMenuOpen
                                    ? 'material-appbar'
                                    : undefined}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                                size="large">
                                <AccountCircle />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            size="large">
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            {renderMenu()}
            {renderMobileMenu()}


            <Drawer
                variant="permanent"
                className={classNames(classes.drawer, {
                    [classes.drawerOpen]: menuOpen,
                    [classes.drawerClose]: !menuOpen,
                })}
                classes={{
                    paper: classNames({
                        [classes.drawerOpen]: menuOpen,
                        [classes.drawerClose]: !menuOpen,
                    }),
                }}
                open={menuOpen}
            >
                <Navigation />
            </Drawer>


            <main
                className={classNames({
                    [classes.content]: useLayout,
                })}
            >
                <div className={classNames({ [classes.toolbar]: useLayout })} />
                {children}
            </main>

        </div>
    );
};


export default Layout;
