import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank as EmptyCheck, Clear, Close, Search, Settings } from "@mui/icons-material";
import useStyles from './SymbolHandler.styles';
import clsx from "clsx";
import { List as VirtualList } from 'react-virtualized';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';
import makeStyles from '@mui/styles/makeStyles';



const SymbolMultiDialog = ({ styleConfig }) => {
    const classes             = useStyles(),
          dispatch            = useDispatch(),
          tab                 = useSelector(state => state.chart.symbolMultiDialogOptions.tab), // setTab
          tooth               = useSelector(state => state.chart.symbolOptions.tooth), // setTooth
          mode                = useSelector(state => state.chart.symbolOptions.sourceType),
          canvasName          = useSelector(state => state.chart.symbolOptions.canvas),
          symbols             = useSelector(state => state.chart.symbols),
          requireConfirmation = useSelector(state => state.chart.symbolMultiDialogOptions.requireConfirmation),
          displayMode         = useSelector(state => state.chart.symbolMultiDialogOptions.displayMode), // setDisplayMode
          stayOnTab           = useSelector(state => state.chart.symbolMultiDialogOptions.stayOnTab),
          searchName          = useSelector(state => state.chart.symbolMultiDialogOptions.searchName), // setSearchName
          selectedCode        = useSelector(state => state.chart.symbolMultiDialogOptions.selectedCode), // setSelectedCode
          defaultCodes        = useSelector(state => state.chart.symbolMultiDialogOptions.defaultCodes),
          [
              search,
              setSearch,
          ]                   = useState(''),
          [
              filteredSymbols,
              setFilteredSymbols,
          ]                   = useState(symbols.filter(row => (row.symbol_category === tab && row.map_to === mode))),
          [
              settingsAnchor,
              setSettingsAnchor,
          ]                   = useState(null),
          [
              searchAllCodesText,
              setSearchAllCodesText
          ]                   = useState('Search All Codes'),
          [
              viewAllCodes,
              setViewAllCodes
          ]                   = useState(false),
          [
              viewAllCodesText,
              setViewAllCodesText
          ]                   = useState('');

    const usePositionStyles = makeStyles({
              dialog: styleConfig,
          }),
          positionClasses   = usePositionStyles();



    // SETTERS
    const setOption  = useCallback((option, value) => {
              dispatch(actions.setSymbolMultiDialog({ [option]: value }));
          }, [ dispatch ]),

          saveSymbol = useCallback((symbol_id) => {
              dispatch(actions.handleSymbolDispatch(symbol_id, canvasName));
          }, [ dispatch, canvasName ]);


    const handleSearch = useCallback((e) => {
        setSearch(e.target.value);

        if(tab !== 'all' && !stayOnTab) {
            setOption('tab', 'all');
        }
    }, [ setSearch, setOption, tab, stayOnTab ]);



    const rowRenderer = ({ key, index, style }) => {
        if(displayMode === 'compact') {
            let color = tab === 'dx' ? 'primary' : 'secondary',
                idx   = index * 3;

            const rows = [
                filteredSymbols[idx],
                filteredSymbols[idx + 1],
                filteredSymbols[idx + 2],
            ].filter(row => row !== undefined);

            return (
                <div className={classes.compactModeRow} key={key} style={style}>
                    {rows.map(row => (
                        <Button
                            key={row.symbol_id}
                            className={classes.compactModeButton}
                            variant={(requireConfirmation && selectedCode === row.symbol_id) ? 'contained' : 'outlined'}
                            color={color}
                            onClick={() => {
                                if(requireConfirmation) {
                                    if(selectedCode === row.symbol_id) {
                                        saveSymbol(row.symbol_id);
                                    } else {
                                        setOption('selectedCode', row.symbol_id);
                                    }
                                } else {
                                    saveSymbol(row.symbol_id);
                                }
                            }}
                        >
                            {row.symbol_abbreviation}
                        </Button>
                    ))}
                </div>
            );
        }

        const row = filteredSymbols[index];

        return (
            <ListItem button key={key} style={style} onClick={() => saveSymbol(row.symbol_id)}>
                <ListItemText
                    primary={row.symbol_abbreviation}
                    secondary={row.symbol_name}
                />
            </ListItem>
        );
    };


    const handleSettingsMenuClose = () => {
        setSettingsAnchor(null);
    };


    // Set search placeholder text
    useEffect(() => {
        const base = viewAllCodes
            ? 'Switch to my default'
            : 'Switch to all';
        let type = '';

        switch(tab) {
            case 'dx':
                type = 'diagnostic';
                break;
            case 'tx':
                type = 'treatment';
                break;
            case 'other':
                type = 'other';
                break;
            default:
                type = '';
        }

        if(!type) {
            setViewAllCodesText(`${base} codes`);
        } else {
            setViewAllCodesText(`${base} ${type} codes`);
        }
    }, [ viewAllCodes, setViewAllCodesText, tab ]);


    // Set view all button text
    useEffect(() => {
        if(stayOnTab && tab === 'dx') {
            setSearchAllCodesText('Search all diagnostic codes');
        } else if(stayOnTab && tab === 'tx') {
            setSearchAllCodesText('Search all treatment codes');
        } else if(stayOnTab && tab === 'other') {
            setSearchAllCodesText('Search all other codes');
        } else {
            setSearchAllCodesText('Search all codes');
        }
    }, [ setSearchAllCodesText, tab, stayOnTab ]);


    // Filter
    useEffect(() => {
        let filtered;

        if(!search && tab !== 'all') {
            if(viewAllCodes) {
                filtered = symbols
                    .filter(row => (row.symbol_category === tab && row.map_to === mode));
            } else {
                filtered = defaultCodes
                    .map(id => symbols.find(row => row.symbol_id === id))
                    .filter(row => Boolean(row))
                    .filter(row => (row.symbol_category === tab && row.map_to === mode));
            }

        }

        if(!search && tab === 'all') {
            filtered = symbols.filter(row => row.map_to === mode);
        }

        if(search && searchName) {
            filtered = symbols
                .filter(row => ((tab === 'all' || row.symbol_category === tab) && row.map_to === mode))
                .filter(row => row.symbol_abbreviation.toUpperCase().includes(search.toUpperCase()) || row.symbol_name.toUpperCase().includes(search.toUpperCase()))
                .sort((a, b) => a.symbol_abbreviation.localeCompare(b.symbol_abbreviation, 'en', { ignorePunctuation: true }));
        } else if(search && !searchName) {
            filtered = symbols
                .filter(row => ((tab === 'all' || row.symbol_category) === tab && row.map_to === mode))
                .filter(row => row.symbol_abbreviation.toUpperCase().includes(search.toUpperCase()))
                .sort((a, b) => a.symbol_abbreviation.localeCompare(b.symbol_abbreviation, 'en', { ignorePunctuation: true }));
        }

        setFilteredSymbols(filtered);
    }, [ search, symbols, tab, mode, setFilteredSymbols, searchName, defaultCodes, viewAllCodes ]);

    return (
        <Dialog
            open={true}
            onClose={() => dispatch(actions.resetSymbolOptions())}
            classes={{
                root: classes.container,
                paper: positionClasses.dialog,
            }}
            BackdropProps={{
                invisible: true,
            }}
            PaperProps={{
                elevation: 12,
            }}
        >

            <DialogTitle>
                <span>
                    Placing code on {' '}
                    {mode === 'mouth' && 'Mouth'}
                    {mode === 'tooth' && `Tooth ${tooth.substr(1)}`}
                </span>
                <IconButton
                    className={classes.closeButton}
                    onClick={() => dispatch(actions.resetSymbolOptions())}
                    size="large">
                    <Close />
                </IconButton>
            </DialogTitle>


            <div style={{ height: '100%' }}>
                <Tabs
                    value={tab}
                    TabIndicatorProps={{ sx: { backgroundColor: 'green', color: 'green' } }}
                    //indicatorColor={tab === 'dx' ? 'primary' : 'green'}
                    //textColor={tab === 'dx' ? 'primary' : 'green'}
                    onChange={(e, newValue) => setOption('tab', newValue)}
                >
                    <Tab label="Diagnostics" value="dx" />
                    <Tab label="Treatments" value="tx" />
                    <Tab label="Other" value="other" />
                    <Tab label="All" value="all" />
                </Tabs>


                <div className={classes.contentContainer}>
                    <TextField
                        fullWidth
                        value={search}
                        onChange={handleSearch}
                        placeholder={searchAllCodesText}
                        margin="dense"
                        variant={"outlined"}
                        autoFocus
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton component="span" onClick={() => setSearch('')} size="large">
                                        <Clear />
                                    </IconButton>

                                    <IconButton
                                        component="span"
                                        onClick={(e) => setSettingsAnchor(e.currentTarget)}
                                        size="large">
                                        <Settings />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {(displayMode === 'compact' && requireConfirmation === true && selectedCode !== null) &&
                        <DialogContentText className={classes.multiDialogCodeName}>
                            {symbols.find(symbol => symbol.symbol_id === selectedCode)?.symbol_name}
                        </DialogContentText>
                    }


                    <Menu
                        anchorEl={settingsAnchor}
                        keepMounted
                        open={Boolean(settingsAnchor)}
                        onClose={handleSettingsMenuClose}
                    >
                        <MenuItem
                            onClick={() => {
                                setOption('requireConfirmation', false);
                                setOption('displayMode', 'list');
                                //handleSettingsMenuClose();
                            }}
                        >
                            <ListItemIcon>
                                {(displayMode === 'list')
                                    ? <CheckBox />
                                    : <EmptyCheck />
                                }
                            </ListItemIcon>
                            <ListItemText>List Mode</ListItemText>
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setOption('requireConfirmation', false);
                                setOption('displayMode', 'compact');
                                //handleSettingsMenuClose();
                            }}
                        >
                            <ListItemIcon>
                                {(displayMode === 'compact' && !requireConfirmation)
                                    ? <CheckBox />
                                    : <EmptyCheck />
                                }
                            </ListItemIcon>
                            <ListItemText>Compact Mode</ListItemText>
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setOption('requireConfirmation', true);
                                setOption('displayMode', 'compact');
                                //handleSettingsMenuClose();
                            }}
                        >
                            <ListItemIcon>
                                {(displayMode === 'compact' && requireConfirmation)
                                    ? <CheckBox />
                                    : <EmptyCheck />
                                }
                            </ListItemIcon>
                            <ListItemText>Compact Mode (with confirmation)</ListItemText>

                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setOption('searchName', !searchName);
                                //handleSettingsMenuClose();
                            }}
                        >
                            <ListItemIcon>{searchName ? <CheckBox /> : <EmptyCheck />}</ListItemIcon>
                            <ListItemText>Search by Name</ListItemText>
                        </MenuItem>

                        <MenuItem
                            onClick={() => {
                                setOption('stayOnTab', !stayOnTab);
                                //handleSettingsMenuClose();
                            }}
                        >
                            <ListItemIcon>{stayOnTab ? <CheckBox /> : <EmptyCheck />}</ListItemIcon>
                            <ListItemText>Stay on tab when searching</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>


                <div className={clsx(classes.contentContainer, classes.symbolContainer)}>
                    <List>
                        <VirtualList
                            height={390}
                            rowHeight={60}
                            width={487}
                            rowCount={displayMode === 'compact'
                                ? Math.ceil(filteredSymbols.length / 3)
                                : filteredSymbols.length
                            }
                            rowRenderer={rowRenderer}
                        >
                        </VirtualList>

                    </List>
                </div>
                {tab !== 'all' &&
                    <Button sx={{ width: '100%', backgroundColor: '#F7F8FC' }}
                            onClick={() => setViewAllCodes(!viewAllCodes)}>
                        {viewAllCodesText}
                    </Button>
                }
            </div>
        </Dialog>
    );
};


export default SymbolMultiDialog;
