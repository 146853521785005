import React, { useEffect, useCallback } from 'react';
import { Button, Grid } from '@mui/material';
import useStyles from '../Chart.styles';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';



const PerioNumpad = () => {
    const classes      = useStyles(),
          dispatch     = useDispatch(),
          instant      = useSelector(state => state.chart.probeOptions.instantEntry),
          enteredValue = useSelector(state => state.chart.probeOptions.enteredValue);

    const handleModeChange = () => {
        dispatch(actions.setProbingOptions({ instantEntry: !instant, enteredValue: 0 }));
    };

    const handleSetValue = (e) => {
        dispatch(actions.setProbingValue(Number(e.currentTarget.getAttribute('data-value'))));
    };

    const handleClear = (e) => {
        dispatch(actions.setProbingOptions({ enteredValue: 0 }));
    };

    const handleConfirm = (e) => {
        dispatch(actions.confirmProbingValue());
    };

    const handleKeypress = useCallback((e) => {
        let elem = document.querySelector(':focus');
        if(elem) {
            elem.blur();
        }
        let validKeys   = [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, ' ', 'Enter' ],
            advanceKeys = [ ' ', 'Enter' ];


        if(!validKeys.includes(e.key)) {
            return false;
        }

        if(advanceKeys.includes(e.key) && !instant) {
            dispatch(actions.confirmProbingValue());
        }

        if(advanceKeys.includes(e.key) && instant) {
            dispatch(actions.advanceProbing());
        }

        if(!advanceKeys.includes(e.key)) {
            dispatch(actions.setProbingValue(Number(e.key)));
        }
    }, [ dispatch, instant ]);


    // TODO: REACTIVATE ME
    /*useEffect(() => {
        document.addEventListener("keypress", handleKeypress);
        let elem = document.querySelector(':focus');
        if(elem) {
            elem.blur();
        }

        return () => {
            console.log('removing');
            document.removeEventListener('keypress', handleKeypress);
        };
    }, [handleKeypress]);*/

    return (
        <div className={classes.probingPadWrapper}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={1}
                        onClick={handleSetValue}
                    >
                        1
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={2}
                        onClick={handleSetValue}
                    >
                        2
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={3}
                        onClick={handleSetValue}
                    >
                        3
                    </Button>
                </Grid>


                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={4}
                        onClick={handleSetValue}
                    >
                        4
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={5}
                        onClick={handleSetValue}
                    >
                        5
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={6}
                        onClick={handleSetValue}
                    >
                        6
                    </Button>
                </Grid>


                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={7}
                        onClick={handleSetValue}
                    >
                        7
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={8}
                        onClick={handleSetValue}
                    >
                        8
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={9}
                        onClick={handleSetValue}
                    >
                        9
                    </Button>
                </Grid>


                <Grid item xs={4} />
                <Grid item xs={4}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        data-value={0}
                        onClick={handleSetValue}
                    >
                        0
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    {!instant &&
                        <Grid item xs={12}>
                            <Button
                                size="large"
                                fullWidth
                                className={classes.probingButton}
                                variant="outlined"
                                color="primary"
                                onClick={handleClear}
                                disabled={!Boolean(enteredValue)}
                            >
                                Clear
                            </Button>
                        </Grid>
                    }
                </Grid>


                {!instant &&
                    <Grid item xs={12}>
                        <Button
                            size="large"
                            fullWidth
                            className={classes.probingButton}
                            variant="contained"
                            color="primary"
                            onClick={handleConfirm}
                        >
                            Confirm: {enteredValue}
                        </Button>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Button
                        size="large"
                        fullWidth
                        className={classes.probingButton}
                        color={instant ? 'primary' : 'secondary'}
                        variant="outlined"
                        onClick={handleModeChange}
                    >
                        {instant ? 'Pocket over 9mm' : 'Pocket under 9mm'}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};


export default PerioNumpad;