import React from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Place } from '@mui/icons-material';
import DashboardCard from 'components/DashboardCard';
import { Link } from 'react-router-dom';



const ContactInfo = () => {
	return (
		<DashboardCard
			color={'orange'}
			icon={<Place />}
			topContent={"Address and Contact Info"}
			size="xl"
		>
			<Grid container spacing={2} sx={{ marginTop: 1 }}>
				<Grid item xs={12} lg={6}>
					<Typography variant="body1">Address</Typography>
					<Typography variant="body2">
						Please enter the address of your clinic below. This address is able to be included in reports.
					</Typography>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField placeholder="Address Line 1" label="Address Line 1" sx={{ width: '428px' }} />
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField placeholder="Address Line 2" label="Address Line 2" sx={{ width: '428px' }} />
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField placeholder="City" label="City" sx={{ marginRight: 1 }} />
						<TextField placeholder="State/Province" label="State/Province" />
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: 1 }}>
						<TextField placeholder="ZIP/Postal Code" label="ZIP/Postal Code" sx={{ marginRight: 1 }} />
						<TextField placeholder="Country" label="Country" />
					</Grid>

				</Grid>

				<Grid item xs={12} lg={6}>
					<Typography variant="body1">Phone Number</Typography>
					<Typography variant="body2">
						Please enter the phone number for your clinic. This number is able to be included in reports.
					</Typography>
					<TextField placeholder="Enter your clinic's phone number" label="Phone number" />
				</Grid>

				<Grid item xs={12} lg={6} sx={{ marginTop: 2 }}>
					<Typography variant="body1">Email Address</Typography>
					<Typography variant="body2">
						Please enter the email address for your clinic. This email address is able to be included in
						reports.<br />
						Visit the <Link to="/clinic-settings/billing">billing settings</Link> page to set a billing
						email address.
					</Typography>
					<TextField
						placeholder="Enter your clinic's email address"
						label="Email address"
						sx={{ width: '428px' }}
					/>
				</Grid>

				<Grid item xs={12} sx={{ marginTop: 2 }}>
					<Button variant="contained">Save Contact Details</Button>
				</Grid>
			</Grid>

		</DashboardCard>
	);
};

export default ContactInfo;
