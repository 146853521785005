import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, TextField } from "@mui/material";
import useStyles from './Reports.styles';
import { actions } from './Reports.redux';
import DashboardCard from "components/DashboardCard";
import { Search } from "@mui/icons-material";



const TemplateSearch = () => {
    const classes       = useStyles(),
          dispatch      = useDispatch(),
          searchTerms   = useSelector(state => state.reports.templateSearch),
          loading       = useSelector(state => state.reports.loading),
          setSearchTerm = (key, value) => dispatch(actions.setSearchTerm('templateSearch', key, value));


    const handleChange = e => setSearchTerm(e.target.name, e.target.value);

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(actions.setSubState('templates', { pageNumber: 0 }));
        dispatch(actions.loadTemplates());
    };


    const handleReset = () => {
        if(!searchTerms.template_name) {
            return false;
        }

        setSearchTerm('template_name', '');
        dispatch(actions.setSubState('templates', { pageNumber: 0 }));
        dispatch(actions.loadTemplates());
    };


    return (
        <div className={classes.searchContainer}>
            <DashboardCard
                color={'amber'}
                icon={<Search />}
                topContent={"Layout Search"}
                topLabel="Enter search criteria below"
                size="xl"
            >
                <form method="post" onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                label="Layout Name"
                                variant="outlined"
                                fullWidth
                                value={searchTerms.template_name}
                                name="template_name"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.searchButtons}
                                disabled={loading}
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Search
                            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                className={classes.searchButtons}
                                disabled={loading}
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </DashboardCard>
        </div>

    );
};


export default TemplateSearch;