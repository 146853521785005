import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import pathologySymbols from '../paths/pathology_symbols';
import { actions } from 'store';
import customTools from './customTools/';
import { ChatBubble } from '../icons';
import TextEntryBox from './customTools/components/TextEntryBox';
import store from 'store';
import { toolLayerKeys, toolLayers } from './toolLayerConf';



function getDrawingMaxSize(canvasName) {
	switch(canvasName) {
		case 'occlusal':
			return 65536; // 64 KB
		case 'buccal':
			return 65536; // 64 KB
		case 'charting':
			return 10240; // 10 KB
		default:
			return 0;
	}
}

export default function(penPal) {

	let app = this;
	let state = store.getState();
	let lastState = store.getState();
	let lastData = {
		'occlusal': {},
		'buccal': {},
		'charting': {},
	};

	const hiddenContainer = document.getElementById('hidden');

	store.subscribe(function() {
		lastState = state;
		state = store.getState();
	});

	let canvases = {},
		layers   = {
			drawing: 'drawing',
			symbols: 'symbols',
			text: 'text',
			diagnostics: 'diagnostics',
			treatments: 'treatments',
			shapes: 'shapes',
		};

	this.getVis = function (key, canvasName) {
		let visObj = state.ActiveChart.Visibility,
			lastVisObj = lastState.ActiveChart.Visibility;

		let vis = {
			adult: visObj.Adult[canvasName],
			deciduous: visObj.Deciduous[canvasName],
			adultLast: lastVisObj.Adult[canvasName],
			deciduousLast: lastVisObj.Deciduous[canvasName],
			current: visObj[key][canvasName],
			last: lastVisObj[key][canvasName],
		};

		vis.layerVisChanged = (vis.adult !== vis.adultLast) || (vis.deciduous !== vis.deciduousLast);
		vis.dataVisChanged = vis.current === vis.last;
		vis.changed = vis.layerVisChanged || vis.dataVisChanged;

		return vis;
	};

	this.hideHiddenContainer = function() {
		hiddenContainer.style.position = 'fixed';
		hiddenContainer.style.opacity = 0;
		hiddenContainer.style.left = -100;
		hiddenContainer.style.top = -100;
		hiddenContainer.style.zIndex = -100;
		ReactDOM.render(<div />, hiddenContainer);
	};


	this.showHiddenContainer = function(e, Component, callback) {
		let x = e.event.type === 'touchend'
				? e.event.changedTouches[ 0 ].clientX
				: e.event.x;

		let y = e.event.type === 'touchend'
				? e.event.changedTouches[ 0 ].clientY
				: e.event.y;

		hiddenContainer.style.display = 'block';
		hiddenContainer.style.position = 'fixed';
		hiddenContainer.style.opacity = 1;
		hiddenContainer.style.left = `${x}px`;
		hiddenContainer.style.top = `${y}px`;
		hiddenContainer.style.zIndex = 1000;
		ReactDOM.render(<Component
			paperEvent={e}
			close={this.hideHiddenContainer}
			anchor={hiddenContainer}
			callback={callback}
		/>, hiddenContainer);
	};


	/**
	 * Creates a canvas inside the given element with given X and Y ratios
	 * @param {string} canvasName - The name of the canvas
	 * @param {HTMLElement} el - Element to insert the canvas into
	 * @param {number} X - Width ratio
	 * @param {number} Y - Height ratio
	 * @returns {object}
	 */
	this.addCanvas = function(canvasName, el, X, Y) {
		return canvases[ canvasName ] = {
			activeTool: null,
			activeLayer: null,
			canvas: penPal.canvas.create(canvasName, el, {
				ratioX: X,
				ratioY: Y,
			}),
		}
	};


	/**
	 * Sets tool on given canvas as active
	 * @param {string} canvasName
	 * @param {string} toolName
	 * @returns {*} - Whatever paperjs returns for this
	 */
	this.setTool = function(canvasName, toolName) {
		return canvases[ canvasName ].tools[ toolName ].activate();
	};

	/**
	 * Registers free-drawing tool on given canvas
	 * @param {string} canvasName - Name of canvas to register tool on
	 * @param {function} callback - Callback to run when path is successfully added
	 */
	this.addDrawingTool = function(canvasName, callback) {
		let p;
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.drawing);

		let handlers = {
			'onMouseDown': function(e) {
				penPal.canvas.select(canvasName);
				c.paper.activate();

				penPal.layer.set(layers.drawing);
				let unique = Date.now().toString() + Math.floor(Math.random() * 1000).toString();


				p = new c.paper.Path({
					segments: [ e.point ],
					strokeColor: state.ChartingOptions.Markup.Color || '#000000',
					strokeWidth: state.ChartingOptions.Markup.Size || 5,
					fullySelected: false,
					name: `draw_${unique}`,
				});
			},

			'onMouseDrag': function(e) {
				p.add(e.point);
			},

			'onMouseUp': function(e) {
				if(!app.getVis('Drawings', canvasName).current) {
					p.remove();
					store.dispatch(actions.setSnackbar(
						'You must turn on the Drawings layer to add drawings.', 3000, true,
					));
					return false;
				}

				p.simplify(15);
				if(p.length < 10) {
					p.remove();
				} else {
					let max = getDrawingMaxSize(canvasName);
					let currentSize = JSON.stringify(app.getDrawings(canvasName)).length;

					if(currentSize > max) {
						store.dispatch(actions.setSnackbar(
							'Drawing storage limit for this canvas has been exceeded.',
							3000,
							true,
						));
						p.remove();
						return false;
					}

					if(typeof callback === 'function') {
						callback(canvasName);
					}
				}
			},
		};

		penPal.project.createTool(layers.drawing, handlers);
		canvases[ canvasName ].activeTool = layers.drawing;
	};


	this.getShapeInfo = function(tool) {
		let info = {
			tool: null,
			fill: false,
		};
		if(!tool) tool = state.ChartingOptions.Markup.Tool;

		switch(tool) {
			case 'circleFill':
				info = {
					tool: 'Ellipse',
					fill: true,
				};
				break;
			case 'circleOutline':
				info = {
					tool: 'Ellipse',
					fill: false,
				};
				break;
			case 'rectangleFill':
				info = {
					tool: 'Rectangle',
					fill: true,
				};
				break;
			case 'rectangleOutline':
				info = {
					tool: 'Rectangle',
					fill: false,
				};
				break;
			default:
				info = {
					tool: 'Rectangle',
					fill: false,
				};
				break;
		}

		return info;
	};



	this.addShapeTool = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.shapes);
		let shape;
		let origin = [ 0, 0 ];
		let info = null;



		let handlers = {
			'onMouseDown': function(e) {

				info = app.getShapeInfo();
				penPal.canvas.select(canvasName);
				c.paper.activate();
				penPal.layer.set(layers.shapes);

				shape = new c.paper.Path[ info.tool ]({
					position: [ e.point.x, e.point.y ],
					strokeColor: state.ChartingOptions.Markup.Color || '#000000',
					strokeWidth: state.ChartingOptions.Markup.Size || 5,
					fullySelected: false,
					size: [ 1, 1 ],
				});

				if(info.fill) {
					shape.fillColor = state.ChartingOptions.Markup.Color || '#000000';
				}

				origin = [ e.point.x, e.point.y ];
			},

			'onMouseDrag': function(e) {
				shape.remove();

				let diffX = e.point.x - origin[ 0 ];
				let diffY = e.point.y - origin[ 1 ];

				shape = new c.paper.Path[ info.tool ]({
					position: [ ...origin ],
					strokeColor: state.ChartingOptions.Markup.Color || '#000000',
					strokeWidth: state.ChartingOptions.Markup.Size || 8,
					fullySelected: false,
					size: [ diffX, diffY ],
				});

				if(info.fill) {
					shape.fillColor = state.ChartingOptions.Markup.Color || '#000000';
				}

				//shape.data.size = [1/(lastScale[0] - scaleX), 1/(lastScale[1] - scaleY)];

			},

			'onMouseUp': function(e) {
				let diffX = e.point.x - origin[ 0 ];
				let diffY = e.point.y - origin[ 1 ];

				if(!app.getVis('Shapes', canvasName).current) {
					shape.remove();
					store.dispatch(actions.setSnackbar(
						'You must turn on the Shapes layer to add shapes.', 3000, true,
					));
					return false;
				}

				if(Math.abs(diffX) < 20 && Math.abs(diffY) < 20) {
					shape.remove();
				} else {
					let unique = Date.now().toString() + Math.floor(Math.random() * 1000).toString();
					shape.name = `shape_${unique}`;
					shape.data = {
						name: `shape_${unique}`,
						type: state.ChartingOptions.Markup.Tool,
						position: [ ...origin ],
						size: [ diffX, diffY ],
						strokeColor: state.ChartingOptions.Markup.Color || '#000000',
						strokeWidth: state.ChartingOptions.Markup.Size || 5,
					};

					if(info.fill) {
						shape.data.fillColor = state.ChartingOptions.Markup.Color || '#000000';
					}

					let shapeState = store.getState().ActiveChart.Shapes;
					let newState = shapeState[ canvasName ];
					if(!_.isArray(newState)) newState = [];
					newState = [ ...newState, { ...shape.data } ];

					store.dispatch(actions.saveShape(
						canvasName,
						newState,
					));
				}

				shape = null;
				origin = [ 0, 0 ];
				info = null;
			},
		};

		penPal.project.createTool(layers.shapes, handlers);
		canvases[ canvasName ].activeTool = layers.shapes;
	};



	this.addTextTool = function(canvasName) {
		let handlers = {
			'onMouseUp': function(e) {
				if(!app.getVis('Text', canvasName).current) {
					store.dispatch(actions.setSnackbar(
						'You must turn on the Text layer to add text.', 3000, true,
					));
					return false;
				}

				let color = state.ChartingOptions.Markup.Color || '#000000';
				let unique = Date.now().toString() + Math.floor(Math.random() * 1000).toString();
				let textConfig = {
					name: `text_${unique}`,
					content: '',
					color: color,
					position: [ e.point.x, e.point.y ],
				};

				function save(res) {
					if(!res.value.trim()) {
						return false;
					}

					textConfig.content = res.value;
					let textState = store.getState().ActiveChart.Text;
					let newState = textState[ canvasName ];
					if(!_.isArray(newState)) newState = [];
					newState = [ ...newState, { ...textConfig } ];

					store.dispatch(actions.saveText(
						canvasName,
						newState,
					));
				}

				app.showHiddenContainer(e, TextEntryBox, save);
			},
		};

		penPal.project.createTool(layers.text, handlers);
		canvases[ canvasName ].activeTool = layers.text;
	};




	this.addSymbolTool = function(canvasName, callback) {
		let c = canvases[ canvasName ].canvas;

		let handlers = {
			'onMouseUp': function(e) {
				if(!state.ChartingOptions.ActiveSymbol) {
					return false;
				}

				let toothVis = app.getToothVisibility();
				if(!toothVis.active) {
					store.dispatch(actions.setSnackbar(
						'Use the Layers menu to select only adult or deciduous teeth for this tool.',
						3000,
						true,
					));

					return false;
				}

				penPal.canvas.select(canvasName);
				c.paper.activate();
				penPal.layer.set(layers.symbols);

				let unique = Date.now().toString() + Math.floor(Math.random() * 1000).toString();
				let symbolConfig = {
					position: [ e.point.x, e.point.y ],
					fillColor: 'black',
					scaling: 4,
					name: `symbol_${unique}`,
					data: {
						icon: state.ChartingOptions.ActiveSymbol,
						color: 'black',
						scale: 4,
					},
				};

				app.addSymbol(canvasName, state.ChartingOptions.ActiveSymbol, symbolConfig);

				if(typeof callback === 'function') {
					callback(canvasName);
				}
			},
		};


		penPal.project.createTool(layers.symbols, handlers);
		canvases[ canvasName ].activeTool = layers.symbols;
	};


	this.addSymbol = function(canvasName, symbolName, symbolConfig) {
		let c = canvases[ canvasName ].canvas;
		penPal.canvas.select(canvasName);
		c.paper.activate();
		penPal.layer.set(layers.symbols);

		let symbol = new c.paper.Path(pathologySymbols[ symbolName ]);
		_.assign(symbol, symbolConfig);

		return symbol;
	};


	this.addClickTool = function(canvasName, toolName, callback) {
		let c = canvases[ canvasName ].canvas;
		let handlers = {
			onMouseUp: function(e) {
				callback(e);
			},
		};

		penPal.canvas.select(canvasName);
		c.paper.activate();
		penPal.project.createTool(toolName, handlers);
		canvases[ canvasName ].activeTool = toolName;
	};


	this.addCustomTool = function(canvasName, toolName, defaultTool) {
		let util = {
			c: canvases[ canvasName ].canvas,
			helper: app,
			penPal: penPal,
			store: store,
			canvasName: canvasName,
			custom: null,
		};

		let handlers = {
			onMouseDown: function(e) {
				//console.log(canvasName, toolName);
				// This doesn't always get reset, so let's reset it.
				util.custom = null;

				let cTool = state.ChartingOptions.CustomTool;
				let callbacks = cTool
								? customTools.hasOwnProperty(cTool) ? customTools[ cTool ] : customTools[ defaultTool ]
								: customTools[ defaultTool ];

				if(callbacks.hasOwnProperty('onMouseDown') && typeof callbacks[ 'onMouseDown' ] === 'function') {
					callbacks[ 'onMouseDown' ](e, util);
				}
			},
			onMouseUp: function(e) {
				let cTool = state.ChartingOptions.CustomTool;
				let callbacks = cTool
								? customTools.hasOwnProperty(cTool) ? customTools[ cTool ] : customTools[ defaultTool ]
								: customTools[ defaultTool ];

				if(callbacks.hasOwnProperty('onMouseUp') && typeof callbacks[ 'onMouseUp' ] === 'function') {
					callbacks[ 'onMouseUp' ](e, util);
				}
			},
			onMouseDrag: function(e) {
				let cTool = state.ChartingOptions.CustomTool;
				let callbacks = cTool
								? customTools.hasOwnProperty(cTool) ? customTools[ cTool ] : customTools[ defaultTool ]
								: customTools[ defaultTool ];

				if(callbacks.hasOwnProperty('onMouseDrag') && typeof callbacks[ 'onMouseDrag' ] === 'function') {
					callbacks[ 'onMouseDrag' ](e, util);
				}
			},
		};

		penPal.canvas.select(canvasName);
		util.c.paper.activate();
		penPal.project.createTool(toolName, handlers);
		canvases[ canvasName ].activeTool = toolName;
	};


	this.addDummyTool = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.canvas.select(canvasName);
		c.paper.activate();
		penPal.project.createTool('dummy', {});
		canvases[ canvasName ].activeTool = 'dummy';
	};


	this.getDrawings = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.drawing);
		let children = c.layers[ layers.drawing ].layer.getChildren();

		return children.filter(function(draw) {
			return !!draw.name;
		}).map(function(draw) {
			return {
				color: draw.strokeColor.toCSS(true),
				x: draw.position.x,
				y: draw.position.y,
				strokeWidth: draw.strokeWidth,
				name: draw.name,
				pathData: draw.getPathData(),
			}
		});
		/*
		 let c = canvases[canvasName].canvas;
		 penPal.layer.set(layers.drawing);

		 let children = c.layers.drawing.layer.children;

		 return children.map(function(child) {
		 return child.exportJSON();
		 });*/
	};


	this.addMoveTool = function(canvasName) {
		let item = null;
		let offset = [ 0, 0 ];

		let handlers = {
			'onMouseDown': function(e) {
				try {
					app.setActive(canvasName, e.item.layer.name);
				} catch(e) {
					return false;
				}

				if(e.item && e.item.name && toolLayerKeys.indexOf(e.item.layer.name) !== -1) {
					offset = [ e.point.x - e.item.position.x, e.point.y - e.item.position.y ];
					return item = e.item;
				}
				return false;
			},

			'onMouseDrag': function(e) {
				if(!item) {
					return false;
				}

				let newPos = [ e.point.x - offset[ 0 ], e.point.y - offset[ 1 ] ];
				item.position = newPos;

			},

			'onMouseUp': function(e) {
				if(!item) {
					return false;
				}

				let newPos = [ e.point.x - offset[ 0 ], e.point.y - offset[ 1 ] ];

				item.position = newPos;
				if(item.data.position) {
					item.data.position = newPos;
				}

				try {
					//console.log(item.layer.name, toolLayers[item.layer.name]);
					app[ toolLayers[ item.layer.name ].func ](canvasName);
				} catch(err) {
					//console.log(item.layer.name, toolLayers[item.layer.name], app);
					//console.log(app[toolLayers[item.layer.name].func]);
					console.error('Unable to run save function!');
				}

				item = null;
				offset = [ 0, 0 ];
			},
		};

		penPal.project.createTool(`move`, handlers);
		canvases[ canvasName ].activeTool = `move`;
	};


	this.addDeleteTool = function(canvasName) {
		let item = null;

		let handlers = {
			'onMouseDown': function(e) {
				try {
					app.setActive(canvasName, e.item.layer.name);
				} catch(e) {
					return false;
				}

				if(e.item && e.item.name && toolLayerKeys.indexOf(e.item.layer.name) !== -1) {
					return item = e.item;
				}
				return false;
			},
			'onMouseUp': function(e) {
				if(!item) {
					return false;
				}

				let layerName = item.layer.name;
				item.remove();

				try {
					app[ toolLayers[ layerName ].func ](canvasName);
				} catch(err) {
					console.error('Unable to run save function!');
				}
			},
		};

		penPal.project.createTool(`delete`, handlers);
		canvases[ canvasName ].activeTool = `delete`;
	};


	this.getText = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.text);
		let children = c.layers[ layers.text ].layer.getChildren();
		console.log('get', c);
		console.log(c.layers[ layers.text ]);

		return children.filter(function(text) {
			if(!text.content) return false;
			return !!text.content.trim().length;
		}).map(function(text) {
			return {
				name: text.name,
				content: text.data.content,
				color: text.data.color,
				position: [ text.position.x, text.position.y ],
			};
		});
	};


	this.getShapes = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.shapes);
		let children = c.layers[ layers.shapes ].layer.getChildren();

		return children.map(function(shape) {
			return {
				...shape.data,
				position: [ shape.position.x, shape.position.y ],
			};
		});
	};


	this.getSymbols = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.symbols);
		let children = c.layers[ layers.symbols ].layer.getChildren();


		return children.map(function(symbol) {
			return {
				icon: symbol.data.icon,
				color: symbol.data.color,
				x: symbol.position.x,
				y: symbol.position.y,
				scale: symbol.data.scale,
				name: symbol.name,
			}
		});
	};


	this.clearLayer = function(canvasName, layerName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layerName);
		c.layers[ layerName ].layer.remove();
		delete(c.layers[ layerName ]);
		penPal.layer.set(layerName);
	};


	this.clearDrawings = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.drawing);
		c.layers[ layers.drawing ].layer.remove();
		delete(c.layers[ layers.drawing ]);
		penPal.layer.set(layers.drawing);
	};


	this.clearText = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.text);
		c.layers[ layers.text ].layer.remove();
		delete(c.layers[ layers.text ]);
		penPal.layer.set(layers.text);
	};



	this.clearSymbols = function(canvasName) {
		let c = canvases[ canvasName ].canvas;
		penPal.layer.set(layers.symbols);
		c.layers[ layers.symbols ].layer.remove();
		delete(c.layers[ layers.symbols ]);
		penPal.layer.set(layers.symbols);
	};


	this.saveDrawings = function(canvasName) {
		store.dispatch(actions.saveDrawing(
			canvasName,
			app.getDrawings(canvasName),
		));
	};


	this.saveText = function(canvasName) {
		return store.dispatch(actions.saveText(
			canvasName,
			app.getText(canvasName),
		));
	};


	this.saveShapes = function(canvasName) {
		return store.dispatch(actions.saveShape(
			canvasName,
			app.getShapes(canvasName),
		));
	};


	this.saveDiagnostics = function(canvasName, tooth = null) {
		let existing = store.getState().ActiveChart.Diagnostics;
		let c = app.setActive(canvasName, layers.diagnostics);
		let children = c.layers[ layers.diagnostics ].layer.children;
		let update = {};

		for(let i = 0; i < children.length; i++) {
			let name = children[ i ].name;
			if(!name) continue;
			update[ name ] = { ...children[ i ].data };
		}

		store.dispatch(actions.setActiveChartOption(
			'Diagnostics',
			{
				...existing,
				[ canvasName ]: update,
			},
		));
	};


	this.saveTreatments = function(canvasName, tooth = null) {
		let existing = store.getState().ActiveChart.Treatments;
		let c = app.setActive(canvasName, layers.treatments);
		let children = c.layers[ layers.treatments ].layer.children;
		let update = {};

		for(let i = 0; i < children.length; i++) {
			let name = children[ i ].name;
			if(!name) continue;
			update[ name ] = { ...children[ i ].data };
		}

		store.dispatch(actions.setActiveChartOption(
			'Treatments',
			{
				...existing,
				[ canvasName ]: update,
			},
		));
	};


	this.saveSymbols = function(canvasName, tooth = null) {
		return store.dispatch(actions.saveSymbols(
			canvasName,
			'Diagnostics',
			app.getSymbols(canvasName),
			tooth,
		));
	};


	this.setActive = function(canvasName, layerName) {
		let c = penPal.canvas.select(canvasName);
		c.paper.activate();
		penPal.layer.set(layerName);
		return c;
	};


	this.toggleMissing = function(canvasName, tooth) {
		let c = penPal.canvas.select(canvasName);
		let missing = state.ActiveChart.MissingTeeth;
		let index = missing.indexOf(tooth);

		c.paper.activate();

		if(index === -1) {
			return store.dispatch(actions.setActiveChartOption(
				'MissingTeeth',
				[ ...missing, tooth ],
			));
		} else {
			let newMissing = [ ...missing ];
			newMissing.splice(index, 1);

			return store.dispatch(actions.setActiveChartOption(
				'MissingTeeth',
				newMissing,
			));
		}
	};



	this.importDrawings = function(canvasName, tooth = null) {
		let drawings = state.ActiveChart.Drawings[ canvasName ];
		let vis = app.getVis('Drawings', canvasName);
		let dataUnchanged = lastData[ canvasName ].Drawings === drawings;

		if(!vis.current && !vis.changed) {
			return false;
		}

		if(vis.current && dataUnchanged && !vis.changed) {
			return false;
		}

		lastData[ canvasName ].Drawings = drawings;

		app.clearDrawings(canvasName);
		let c = app.setActive(canvasName, layers.drawing);
		let drawData = tooth
					   ? drawings.hasOwnProperty(tooth) ? drawings[ tooth ] : []
					   : drawings;



		drawData.forEach(function(data) {
			let p = new c.paper.Path({
				pathData: data.pathData,
				position: [ data.x, data.y ],
				strokeWidth: data.strokeWidth,
				strokeColor: data.color,
				name: data.name,
			});

			return c.layers.drawing.layer.addChild(p);
		});
	};



	this.importText = function(canvasName) {
		let text = state.ActiveChart.Text[ canvasName ];
		let vis = app.getVis('Text', canvasName);
		let dataUnchanged = lastData[ canvasName ].Text === text;

		if(!vis.current && !vis.changed) {
			return false;
		}

		if(vis.current && dataUnchanged && !vis.changed) {
			return false;
		}

		lastData[ canvasName ].Text = text;

		app.clearText(canvasName);
		let c = app.setActive(canvasName, layers.text);

		text.forEach(function(data, index) {
			let textConfig = {
				position: [ ...data.position ],
				content: (index + 1).toString(),
				fullySelected: false,
				fontSize: 38,
				name: data.name,
				fillColor: data.color,
				data: {
					position: [ ...data.position ],
					content: data.content,
					color: data.color,
				},
			};

			let symbolConfig = {
				position: [ ...data.position ],
				fillColor: '#ffffff',
				strokeColor: data.color,
				strokeWidth: 6,
				scaling: 3.5,
				opacity: 1,
				data: {
					position: [ ...data.position ],
					content: data.content,
					color: data.color,
				},
			};

			let symbol = new c.paper.Path(ChatBubble);
			_.assign(symbol, symbolConfig);

			let text = new c.paper.PointText(textConfig);
			text.position = [
				text.position.x - text.bounds.width / 2,
				text.position.y + text.bounds.height / 6,
			];

			return text;
		});

	};


	this.importShapes = function(canvasName) {
		let shapes = state.ActiveChart.Shapes[ canvasName ];
		let vis = app.getVis('Shapes', canvasName);
		let dataUnchanged = lastData[ canvasName ].Shapes === shapes;

		if(!vis.current && !vis.changed) {
			return false;
		}

		if(vis.current && dataUnchanged && !vis.changed) {
			return false;
		}

		lastData[ canvasName ].Shapes = shapes;


		app.clearLayer(canvasName, layers.shapes);
		let c = app.setActive(canvasName, layers.shapes);

		shapes.forEach(function(data, index) {
			let { type, ...config } = data;
			let info = app.getShapeInfo(type);
			config.fullySelected = false;
			config = {
				...config,
				data: data,
			};

			return new c.paper.Path[ info.tool ](config);
		});
	};




	this.importSymbols = function(canvasName, tooth) {
		let symbols = state.ActiveChart.Diagnostics[ canvasName ];
		if(!app.getVis('Diagnostics', canvasName) || lastData[ canvasName ].Diagnostics === symbols) {
			return false;
		}
		lastData[ canvasName ].Diagnostics = symbols;

		app.clearSymbols(canvasName);
		let c = app.setActive(canvasName, layers.symbols);
		let symbolData = tooth
						 ? symbols.hasOwnProperty(tooth) ? symbols[ tooth ] : []
						 : symbols;

		symbolData.forEach(function(data) {
			let symbolConfig = {
				position: [ data.x, data.y ],
				fillColor: data.color,
				scaling: data.scale,
				name: data.name,
				data: {
					icon: data.icon,
					color: data.color,
					scale: data.scale,
				},
			};

			let symbol = new c.paper.Path(pathologySymbols[ data.icon ]);
			_.assign(symbol, symbolConfig);
		});

	};


	this.importDiagnostics = function(canvasName, defaultTool) {
		let d = state.ActiveChart.Diagnostics[ canvasName ];
		let vis = app.getVis('Diagnostics', canvasName);
		let dataUnchanged = lastData[ canvasName ].Diagnostics === d;

		if(!vis.current && !vis.changed) {
			return false;
		}

		if(vis.current && dataUnchanged && !vis.changed) {
			return false;
		}

		lastData[ canvasName ].Diagnostics = d;

		this.clearLayer(canvasName, layers.diagnostics);
		this.clearLayer(canvasName, 'diagnosticLines');
		this.setActive(canvasName, layers.diagnostics);
		let diagnoses = state.App.dx;

		for(let i in d) {
			let value = d[ i ];
			let diagnosis = diagnoses[ value.id ];
			let tool = diagnosis.custom || defaultTool;

			if(value.hasOwnProperty('item')) {
				delete(value.item);
			}

			if(!customTools.hasOwnProperty(tool)) {
				console.log('Custom tool handler', tool, 'not found.');
				return false;
			}

			if(!customTools[ tool ].hasOwnProperty('render')) {
				console.log('Custom tool handler', tool, 'does not have render property.');
				return false;
			}

			try {
				customTools[ tool ].render(app, value);
			} catch(err) {
				console.error(err);
			}
		}
	};


	this.importTreatments = function(canvasName, defaultTool) {
		let d = state.ActiveChart.Treatments[ canvasName ];
		let vis = app.getVis('Treatments', canvasName);
		let dataUnchanged = lastData[ canvasName ].Treatments === d;

		if(!vis.current && !vis.changed) {
			return false;
		}

		if(vis.current && dataUnchanged && !vis.changed) {
			return false;
		}

		lastData[ canvasName ].Treatments = d;

		this.clearLayer(canvasName, layers.treatments);
		this.clearLayer(canvasName, 'treatmentLines');
		this.setActive(canvasName, layers.treatments);
		let treatments = state.App.tx;

		for(let i in d) {
			let value = d[ i ];
			let treatment = treatments[ value.id ];
			let tool = treatment.custom || defaultTool;

			if(value.hasOwnProperty('item')) {
				delete(value.item);
			}

			if(!customTools.hasOwnProperty(tool)) {
				console.log('Custom tool handler', tool, 'not found.');
				return false;
			}

			if(!customTools[ tool ].hasOwnProperty('render')) {
				console.log('Custom tool handler', tool, 'does not have render property.');
				return false;
			}

			customTools[ tool ].render(app, value);
		}
	};


	this.getToothVisibility = function() {
		let vis = state.ActiveChart.Visibility;
		let response = {
			adult: vis.Adult.buccal,
			deciduous: vis.Deciduous.buccal,
			active: null,
		};

		if(response.adult && !response.deciduous) {
			response.active = 'adult';
		} else if(!response.adult && response.deciduous) {
			response.active = 'deciduous';
		}

		return response;
	};


	this.updateLayerVisibility = function(canvasName) {
		// TODO: Find why toggling layer visibility on buccal is broken without using a delay

		if(!penPal.canvas.exists(canvasName)) {
			return false;
		}

		let timeout = 0;
		if(canvasName === 'buccal') timeout = 20;
		let vis = state.ActiveChart.Visibility;
		let toothVis = app.getToothVisibility();


		setTimeout(() => {
			try {
				let c = app.setActive(canvasName, `teeth_${canvasName}`);
				c.layers[ `teeth_${canvasName}` ].layer.visible = vis.Adult[ canvasName ];
				if(canvasName === 'buccal') {
					c.layers[ `teeth_lingualPalatal` ].layer.visible = vis.Adult[ canvasName ];
				}

				c = app.setActive(canvasName, `teeth_${canvasName}_deciduous`);
				c.layers[ `teeth_${canvasName}_deciduous` ].layer.visible = vis.Deciduous[ canvasName ];

				c = app.setActive(canvasName, layers.diagnostics);
				c.layers[ layers.diagnostics ].layer.visible = vis.Diagnostics[ canvasName ];
				try {c.layers[ 'diagnosticLines' ].layer.visible = vis.Diagnostics[ canvasName ]} catch(err){}

				c = app.setActive(canvasName, layers.treatments);
				c.layers[ layers.treatments ].layer.visible = vis.Treatments[ canvasName ];
				try{c.layers[ 'treatmentLines' ].layer.visible = vis.Treatments[ canvasName ]} catch(err){}

				c = app.setActive(canvasName, layers.text);
				c.layers[ layers.text ].layer.visible = vis.Text[ canvasName ];

				c = app.setActive(canvasName, layers.drawing);
				c.layers[ layers.drawing ].layer.visible = vis.Drawings[ canvasName ];

				c = app.setActive(canvasName, layers.shapes);
				c.layers[ layers.shapes ].layer.visible = vis.Shapes[ canvasName ];

				if(canvasName === 'occlusal') {
					c = app.setActive(canvasName, 'soft_tissue');
					c.layers[ 'soft_tissue' ].layer.visible = vis.SoftTissue[ canvasName ];

					c = app.setActive(canvasName, 'perio_occlusal');
					c.layers[ 'perio_occlusal' ].layer.visible = (toothVis.adult && vis.Probing[ canvasName ]);

					c = app.setActive(canvasName, 'bone_view');
					c.layers[ 'bone_view' ].layer.visible = vis.BoneView[ canvasName ];
				}
			} catch(err) {
				console.log(err)
			}
		}, timeout);
	};


	this.getIntersections = function(canvasName, layerName, from, to, simplify = true) {
		let c = this.setActive(canvasName, 'intersectionTemp');
		let paths = c.layers[ layerName ].paths;
		let intersections = {};
		let line = new c.paper.Path.Line(from, to);

		//line.strokeColor = 'red';

		function findIntersections(path) {
			path.children.forEach(child => {
				let parentName = child.parent.name;
				if(!parentName) {
					return false;
				}

				let clone = child.clone();
				let intersect = clone.getIntersections(line);

				if(intersect.length) {
					if(!intersections.hasOwnProperty(parentName)) {
						intersections[ parentName ] = [];
					}

					intersections[ parentName ].push(intersect);
				}

				clone.remove();
			});
		}

		for(let pathname in paths) {
			let path = paths[ pathname ];
			findIntersections(path);
		}

		line.remove();

		if(simplify) {
			intersections = simplifyIntersections(intersections, c);
		}

		this.setActive(canvasName, layerName);

		return intersections;
	};


	function simplifyIntersections(intersections, c) {
		let ints = {};

		for(let parent in intersections) {
			let cur = intersections[ parent ];
			let first;
			let last;

			cur.forEach(group => {
				group.forEach(points => {
					//console.log('points', points.point)
					let x = points.point.x;
					let y = points.point.y;

					if(!first) {
						first = [ x, y ];
						last = [ x, y ];
						return true;
					}

					if(x > first[ 0 ]) {
						last = [ x, y ];
					} else if(x < first[ 0 ]) {
						first = [ x, y ];
					}
				});
			});

			ints[ parent ] = [ first, last ];

			/*new c.paper.Path.Circle({
			 center: first,
			 radius: 5,
			 fillColor: '#009dec'
			 });
			 new c.paper.Path.Circle({
			 center: last,
			 radius: 5,
			 fillColor: '#009dec'
			 });*/

		}

		return ints;
	}


}