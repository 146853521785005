import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, List, ListItem, ListItemText, Paper } from '@mui/material';
import { AccessTime, Assessment, CloudUpload, LocalHospital, Person, Pets, Photo } from '@mui/icons-material';
import useStyles from './FileManagerMenu.styles';
import clsx from 'clsx';
import { actions } from './FileManager.redux';



function makeMenuItems({ client_id, visit_id, patient_id, urpt_id }) {
	return [
		{
			name: 'upload',
			avatar: <CloudUpload />,
			className: 'secondaryColor',
			primary: 'Upload',
			secondary: null,
		},
		...(urpt_id === null
				? []
				: [ {
					name: 'report',
					avatar: <Assessment />,
					className: 'indigoAvatar',
					primary: 'Report',
					secondary: 'Current Report',
				} ]
		),
		...(visit_id === null
				? []
				: [ {
					name: 'visit',
					avatar: <LocalHospital />,
					className: 'deepPurpleAvatar',
					primary: 'Chart',
					secondary: 'Current Chart',
				} ]
		),
		...(patient_id === null
				? []
				: [ {
					name: 'patient',
					avatar: <Pets />,
					className: 'patientsAvatar',
					primary: 'Patient',
					secondary: 'Current Patient',
				} ]
		),
		...(client_id === null
				? []
				: [ {
					name: 'client',
					avatar: <Person />,
					className: 'blueAvatar',
					primary: 'Client',
					secondary: 'Current Client',
				} ]
		),
		{
			name: 'recent',
			avatar: <AccessTime />,
			className: 'indigoAvatar',
			primary: 'Recent',
			secondary: 'Recently Uploaded',
		},
		{
			name: 'browse',
			avatar: <Photo />,
			primary: 'Browse',
			secondary: 'Browse Uploaded Images',
		},
	];
}




const FileManagerMenu = ({
	client_id,
	visit_id,
	patient_id,
	urpt_id,
}) => {
	const classes  = useStyles(),
		  dispatch = useDispatch(),
		  page     = useSelector(state => state.files.page),
		  [
			  menuItems,
			  setMenuItems,
		  ]        = useState(makeMenuItems({ client_id, visit_id, patient_id, urpt_id }));

	useEffect(() => {
		setMenuItems(makeMenuItems({ client_id, visit_id, patient_id, urpt_id }));
	}, [ client_id, patient_id, visit_id, urpt_id ]);


	return (
		<Paper
			className={classes.drawer}
			elevation={4}
		>
			<List>
				{menuItems.map((item) => {
					let textProps = {
							primary: item.primary,
							secondary: item.secondary || undefined,
							className: classes.menuText,
						},
						itemProps = {
							className: clsx(classes.menuItem, page === item.name && classes.menuActive),
							onClick: () => dispatch(actions.setPage(item.name)),
						};


					return (
						<div key={item.name}>
							<ListItem {...itemProps}>
								<Avatar className={classes[ item.className ]}>
									{item.avatar}
								</Avatar>
								<ListItemText {...textProps} />
							</ListItem>
						</div>
					);
				})}
			</List>
		</Paper>
	);
};




export default FileManagerMenu;
