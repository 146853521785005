import React from 'react';
import {
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Select,
} from '@mui/material';
import { Check, Delete, Error, Person, Schedule } from '@mui/icons-material';
import bytesToHumanReadable from 'lib/bytesToHumanReadable';



const UploadFormFileRow = ({ index, file, handleRemoveFile, handleChangeQuality, loading }) => {
	return (
        <ListItem>
			<ListItemIcon>
				<Person />
			</ListItemIcon>

			<ListItemText
				primary={file.name}
				secondary={bytesToHumanReadable(file.size)}
			/>

			<ListItemSecondaryAction>
				<Grid container spacing={2}>

					{file.status === 'NONE' &&
						<>
							<Grid item xs={8}>
								<FormControl variant="outlined">
									<InputLabel shrink htmlFor={`quality-input-${index}`}>Quality</InputLabel>
									<Select
										value={file.quality}
										variant="outlined"
										label="Quality"
										native
										inputProps={{
											id: `quality-input-${index}`,
										}}
										onChange={handleChangeQuality(index)}
										disabled={loading}
									>
										<option value="original">Original</option>
										<option value="high">High</option>
										<option value="medium">Medium</option>
										<option value="low">Low</option>
									</Select>
								</FormControl>
							</Grid>

							<Grid item xs={4}>
								<IconButton
                                    onClick={() => handleRemoveFile(index)}
                                    color="secondary"
                                    disabled={loading}
                                    size="large">
									<Delete />
								</IconButton>
							</Grid>
						</>
					}

					{file.status === 'WAIT' && <Schedule />}

					{file.status === 'UPLOADING' &&
						<Grid item xs={12}>
							<CircularProgress />
						</Grid>
					}

					{file.status === 'FAIL' && <Error />}

					{file.status === 'SUCCESS' && <Check />}

				</Grid>
			</ListItemSecondaryAction>
		</ListItem>
    );
};


export default UploadFormFileRow;
