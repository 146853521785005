const toolLayers = {
	drawing: {
		keyType: 'index',
		func: 'saveDrawings',
	},
	text: {
		keyType: 'index',
		func: 'saveText',
	},
	symbols: {
		keyType: 'index',
		func: 'saveSymbols',
	},
	diagnostics: {
		keyType: 'name',
		func: 'saveDiagnostics',
	},
	treatments: {
		keyType: 'name',
		func: 'saveTreatments',
	},
	shapes: {
		keyType: 'index',
		func: 'saveShapes',
	},
};


const toolLayerKeys = Object.keys(toolLayers);

export default toolLayers;
export { toolLayers };
export { toolLayerKeys };