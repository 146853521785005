import React, { useCallback, useEffect } from 'react';
import DashboardCard from "components/DashboardCard";
import { Edit, Person } from "@mui/icons-material";
import { Skeleton } from '@mui/material';
import { Button, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './ClientView.redux';
import { useParams } from 'react-router-dom';
import useStyles from './ClientView.styles';



const Profile = () => {
	const dispatch      = useDispatch(),
		  classes       = useStyles(),
		  { client_id } = useParams(),
		  loading       = useSelector(state => state.clientView.profileLoading),
		  loaded        = useSelector(state => state.clientView.profileLoaded),
		  editing       = useSelector(state => state.clientView.profileEditing),
		  profile       = useSelector(state => state.clientView.profile),
		  saving        = useSelector(state => state.clientView.profileSaving);


	const loadProfile = useCallback((clientID) => {
		dispatch(actions.loadProfile(clientID));
	}, [ dispatch ]);


	const handleEdit = useCallback(() => {
		dispatch(actions.setState({ profileEditing: true }));
	}, [ dispatch ]);


	const handleCancelEdit = useCallback(() => {
		dispatch(actions.cancelEdit());
	}, [ dispatch ]);


	const setProfileValue = useCallback((e) => {
		dispatch(actions.setProfileValue(e.target.name, e.target.value));
	}, [ dispatch ]);


	const handleSave = useCallback(() => {
		dispatch(actions.saveProfile(client_id));
	}, [ dispatch, client_id ]);


	const generateInputProps = (name) => ({
		variant: 'outlined',
		value: profile[ name ],
		name: name,
		onChange: setProfileValue,
	});



	useEffect(() => {
		if(!loading && !loaded) {
			loadProfile(client_id);
		}
	}, [ loadProfile, loading, loaded, client_id ]);


	return (
        <DashboardCard
			color={'deepOrange'}
			icon={<Person />}
			topContent={loading
						? <Skeleton
							variant="rectangular"
							width={200}
							height={30}
							style={{
								marginLeft: 'auto',
								marginRight: 0,
								width: 200,
							}}
						/>
						: editing
						  ? <TextField
							  {...generateInputProps('client_name')}
						  />
						  : profile.client_name
			}
			topLabel="Client Profile"
			size="xl"
		>

			{(!loaded && loading) &&
			<div className={classes.profileLoadingContainer}>
				<Skeleton animation="wave" height={30} />
				<Skeleton animation="wave" height={30} />
				<Skeleton animation="wave" height={30} />
				<Skeleton animation="wave" height={30} />
			</div>
			}


			{(loaded && !loading) &&
			<div>
				<Typography variant="h6">Last Visit</Typography>
				<Typography variant="subtitle2" gutterBottom>{profile.last_visit}</Typography>


				<Typography variant="h6">External ID</Typography>
				{!editing &&
				<Typography variant="subtitle2" gutterBottom>{profile.client_external_id || '(Not set)'}</Typography>
				}
				{editing &&
				<TextField
					{...generateInputProps('client_external_id')}
				/>
				}


				<Typography variant="h6">Primary Vet</Typography>
				{!editing &&
				<Typography variant="subtitle2" gutterBottom>{profile.primary_vet || '(Not set)'}</Typography>
				}
				{editing &&
				<TextField
					{...generateInputProps('primary_vet')}
				/>
				}


				<Typography variant="h6">Primary Clinic</Typography>
				{!editing &&
				<Typography variant="subtitle2" gutterBottom>{profile.primary_clinic || '(Not set)'}</Typography>
				}
				{editing &&
				<TextField
					{...generateInputProps('primary_clinic')}
				/>
				}


				{!editing &&
				<div>
					<Button
						size="large"
						variant="contained"
						color="primary"
						className={classes.editButton}
						startIcon={<Edit />}
						onClick={handleEdit}
					>
						Edit
					</Button>
				</div>
				}

				{editing &&
				<div>
					<Button
						size="large"
						variant="contained"
						color="primary"
						className={classes.editButton}
						startIcon={<Edit />}
						onClick={handleSave}
						disabled={saving}
					>
						Save
					</Button>

					<Button
						size="large"
						variant="outlined"
						color="secondary"
						className={classes.cancelButton}
						startIcon={<Edit />}
						onClick={handleCancelEdit}
						disabled={saving}
					>
						Cancel
					</Button>

				</div>
				}
			</div>
			}
		</DashboardCard>
    );
};


export default Profile;