import React from 'react';
import WysiwygEditor from './WysiwygEditor';



const AddReport = () => {
	return (
		<WysiwygEditor />
	);
};


export default AddReport;
