import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import { actions } from "pages/Chart/Chart.redux";
import { Close } from "@mui/icons-material";
import clsx from "clsx";
import useStyles from './SymbolHandler.styles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "react-redux";
import SymbolInputInteger from './Inputs/SymbolInputInteger';



const width = 300;

const SymbolInput = ({ handlerConf, point, symbolID }) => {
    const classes           = useStyles(),
          dispatch          = useDispatch(),
          [ values ]        = useState(handlerConf.layout.reduce((accumulator, current) => {
              accumulator[current.valueName] = current.defaultValue;
              return accumulator;
          }, {})),
          usePositionStyles = makeStyles({
              dialog: {
                  position: 'fixed',
                  display: 'block',
                  opacity: 1,
                  zIndex: 100,
                  left: (point[0] + width + 32) > window.innerWidth
                      ? window.innerWidth - 72 - width
                      : point[0] - 200,
                  top: (point[1] + 700) > window.innerHeight
                      ? window.innerHeight - 800
                      : point[1] - 50,
              },
          }),
          positionClasses   = usePositionStyles(),
          symbol            = useSelector(state => state.chart.symbols.find(sym => sym.symbol_id === symbolID)),
          options           = useSelector(state => state.chart.symbolOptions),
          canvas            = options.canvas;

    useEffect(() => {
        dispatch(actions.setState({
            symbolHandlerData: values,
        }));
    }, [ dispatch, values ]);


    return (
        <Dialog
            open={true}
            onClose={() => dispatch(actions.resetSymbolOptions({ symbol: symbolID }))}
            classes={{
                root: classes.container,
                paper: positionClasses.dialog,
            }}
            BackdropProps={{
                invisible: true,
            }}
            PaperProps={{
                elevation: 12,
            }}
        >

            <DialogTitle>
                <IconButton
                    className={classes.closeButton}
                    onClick={() => dispatch(actions.resetSymbolOptions({ symbol: symbolID }))}
                    size="large">
                    <Close />
                </IconButton>
            </DialogTitle>


            <div style={{ height: '100%' }}>

                <div className={clsx(classes.contentContainer, classes.symbolContainer)}>

                    {handlerConf.layout.map((layout) => {
                        return (
                            <SymbolInputInteger
                                key={layout.valueName}
                                inputConf={layout}
                            />
                        );
                    })}

                </div>

                <Button
                    onClick={() => {
                        dispatch(actions.saveSymbol(symbol, options, canvas));
                    }}
                >Done</Button>
            </div>
        </Dialog>
    );
};


export default SymbolInput;