import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	buttonContainer: {
		marginBottom: 32,
	},
	container: {
		width: 400,
		height: 600,
	},
	contentContainer: {
		padding: 8,
	},
	symbolContainer: {
		height: 'calc(100% - 170px)',
		overflow: 'auto',
	},
	centerText: {
		textAlign: 'center',
	},
	compactModeButton: {
		width: 'calc(100% / 3 - 10px)',
		marginLeft: 5,
		marginRight: 5,
	},
	compactModeRow: {
		height: 60,
		width: '100%',
	}
}));



export default useStyles;