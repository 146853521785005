import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@mui/material';
import { push } from 'connected-react-router';
import { actions } from './Reports.redux';
import api from 'api';



const ModalContentBlockAddNew = () => {
    const dispatch                = useDispatch(),

          open                    = useSelector(state => state.reports.showNewContentBlockModal),
          [ loading, setLoading ] = useState(false),
          [ title, setTitle ]     = useState('');


    const handleClose = () => {
        setTitle('');
        dispatch(actions.setState({ showNewContentBlockModal: false }));
    };


    const handleCreate = async () => {
        setLoading(true);

        try {
            let newContentBlock = await api.post('/reports/content-blocks', {
                string_name: title.trim(),
            });

            dispatch(push(`/reports/edit-content-block/${newContentBlock.data.string_id}`));
        } catch (err) {
            setLoading(false);
        }

    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                New Content Block
            </DialogTitle>

            <DialogContent>
                <div>
                    Enter a title, then click Create.
                </div>

                <Divider sx={{ marginBottom: '12px', marginTop: '12px' }} />

                <div>
                    <TextField
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
            </DialogContent>

            <DialogActions>
                <Button disabled={loading} onClick={handleClose}>
                    Cancel
                </Button>

                <Button
                    disabled={loading || !title.trim()}
                    onClick={handleCreate}
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default ModalContentBlockAddNew;
