import makeStyles from '@mui/styles/makeStyles';



const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		width: '100%',
		backgroundColor: '#E5E7E9',
		position: 'relative',
	},
	child: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		height: 500,
		maxWidth: 800,
		width: '100%',
		//backgroundColor: 'papayawhip',
	},
	leftCard: {
		width: '100%',
		height: '100%',
		background: 'linear-gradient(130deg, rgba(26,126,157,1) 0%, rgba(26,126,59,1) 100%)',
		zIndex: 100,
	},
	leftCardInner: {
		width: 'calc(100% - 32px)',
		marginLeft: 16,
		marginRight: 16,
		marginTop: 32,
		color: '#ffffff',
		textAlign: 'center',
	},
	leftCardIcon: {
		fontSize: '7em',
		marginBottom: 32,
		borderRadius: '50%',
		border: '4px white solid',
		padding: 16,
	},
	leftCardWrapper: {
		zIndex: 100,
	},

	rightCard: {
		padding: 16,
		[ theme.breakpoints.down('md') ]: {
			height: '100%',
			width: 'calc(100% - 32px)',
			marginLeft: 16,
		},
		[ theme.breakpoints.up('md') ]: {
			width: '100%',
			marginTop: 16,
			marginLeft: -8,
			paddingLeft: 72,
			paddingRight: 64,
			paddingTop: 32,
			height: 'calc(100% - 32px)',
		},
		background: theme.palette.grey[ 100 ],
	},
	rightCardInner: {
		width: 'calc(100% - 32px)',
		marginLeft: 16,
		marginRight: 16,
		marginTop: 32,
		color: '#ffffff',
		textAlign: 'center',
	},
	rightCardWrapper: {
		zIndex: 50,
	},
	fullHeightWidth: {
		width: '100%',
		height: '100%',
	},
	marginBottom: {
		marginBottom: 32,
	},
	inputs: {
		marginBottom: theme.spacing(4),
	},
}));



export default useStyles;