import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { actions } from '../Chart.redux';
import {
	SelectionState,
	PagingState,
	IntegratedPaging,
	SortingState,
	IntegratedSorting,
	FilteringState,
	IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
	Grid,
	Table,
	TableHeaderRow,
	TableSelection,
	PagingPanel,
	TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';



const AllSymbolsDialog = () => {
	const dispatch    = useDispatch(),
		  showDialog  = useSelector(state => state.chart.toolbar.showAllSymbolsDialog),
		  symbols     = useSelector(state => state.chart.symbols || []),
		  [ columns ] = useState([
			  { name: 'symbol_abbreviation', title: 'Abbreviation' },
			  { name: 'symbol_category', title: 'Category' },
			  { name: 'symbol_name', title: 'Name' },
		  ]);

	const handleClose     = () => dispatch(actions.setToolbar({ showAllSymbolsDialog: false })),
		  handleSelection = (selected) => {
			  dispatch(actions.setToolbar({
				  selectedButton: 'symbols',
				  selectedTool: 'symbolSingle',
			  }));

			  dispatch(actions.resetSymbolOptions({
				  symbol: symbols[ selected[ 0 ] ].symbol_id,
			  }));

			  handleClose();
		  };
	
	return (
		<Dialog
			fullWidth={true}
			maxWidth="lg"
			open={showDialog}
			onClose={handleClose}
		>
			<DialogTitle>All Codes</DialogTitle>

			<DialogContent>
				<Grid
					rows={symbols}
					columns={columns}
				>
					<FilteringState defaultFilters={[]} />
					<IntegratedFiltering />

					<SelectionState
						selection={[]}
						onSelectionChange={handleSelection}
					/>

					<SortingState
						defaultSorting={[ { columnName: 'symbol_abbreviation', direction: 'asc' } ]}
					/>
					<IntegratedSorting />

					<PagingState
						defaultCurrentPage={0}
						defaultPageSize={10}
					/>
					<IntegratedPaging />

					<Table />
					<TableHeaderRow showSortingControls />

					<PagingPanel pageSizes={[ 10, 20, 50, 100 ]} />

					<TableFilterRow />

					<TableSelection
						selectByRowClick
						highlightRow
						showSelectionColumn={false}
					/>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};


export default AllSymbolsDialog;
