import React from 'react';
import { useSelector } from "react-redux";
import SymbolMultiDialog from "./SymbolMultiDialog";
import SymbolMultipleChoice from './SymbolMultipleChoice';
import SymbolInput from './SymbolInput';



const SymbolHandler = () => {

    const active      = useSelector(state => {
              let { toolbar, symbolOptions } = state.chart;
              return toolbar.selectedButton === 'symbols' && symbolOptions.screenPoint !== null;
          }),
          tool        = useSelector(state => state.chart.toolbar.selectedTool),
          symbolID    = useSelector(state => state.chart.symbolOptions.symbol),
          point       = useSelector(state => state.chart.symbolOptions.screenPoint),
          handlerConf = useSelector(state => state.chart.symbolOptions.handlerConf),
          width       = 503;

    if(!active) {
        return null;
    }


    if(handlerConf !== null) {
        switch(handlerConf.type) {
            case 'multiselect':
                return <SymbolMultipleChoice
                    handlerConf={handlerConf}
                    point={point}
                    symbolID={symbolID}
                />;
            case 'input':
                return <SymbolInput
                    handlerConf={handlerConf}
                    point={point}
                    symbolID={symbolID}
                />;
            default:
                return null;
        }

    }



    if(tool === 'symbolMulti') {
        const styleConfig = {
            position: 'fixed',
            display: 'block',
            opacity: 1,
            zIndex: 100,
            left: (point[0] + width + 72) > window.innerWidth
                ? window.innerWidth - 72 - width
                : point[0] - 200,
            top: (point[1] + 700) > window.innerHeight
                ? window.innerHeight - 800
                : point[1] - 50,
        };

        return (
            <SymbolMultiDialog
                styleConfig={styleConfig}
            />
        );
    }

    return <div>yes</div>;

};


export default SymbolHandler;