import React from 'react';

function SVGText(props) {
	let {type, data, ...newProps} = props;

	return (
		<svg
			{...newProps}
			height="36px"
			viewBox={`0 0 ${props.data.length*12} 24`}
		>
			<style>
				{'.small { font: bold 16px sans-serif; }'}
			</style>
			<text x="0" y="18" className="small">
				{props.data}
			</text>
		</svg>
	)
}




export default SVGText;