import React, { useState } from 'react';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { CheckBoxOutlineBlank as EmptyCheck, CheckBox, Layers } from "@mui/icons-material";
import useStyles from './ChartingMenu.styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';



const MenuLayers = () => {
	const classes               = useStyles(),
		  [ anchor, setAnchor ] = useState(null),
		  dispatch              = useDispatch(),
		  layers                = useSelector(state => state.chart.layers);

	const handleClose = () => setAnchor(null),
		  handleOpen  = (e) => setAnchor(e.currentTarget),
		  toggleLayer = name => e => {
			  dispatch(actions.toggleLayer(name));
		  };


	return (
		<>
			<Button
				variant="outlined"
				className={clsx(classes.buttonMarginRight, classes.buttonFloat)}
				startIcon={<Layers />}
				onClick={handleOpen}
			>
				Layers
			</Button>
			<Menu
				anchorEl={anchor}
				open={Boolean(anchor)}
				onClose={handleClose}
			>
				<MenuItem onClick={toggleLayer('adultTeeth')}>
					<ListItemIcon>{layers.adultTeeth
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Adult Teeth</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('deciduousTeeth')}>
					<ListItemIcon>{layers.deciduousTeeth
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Deciduous Teeth</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('missingTeethAdult')}>
					<ListItemIcon>{layers.missingTeethAdult
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Missing Teeth (Adult)</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('missingTeethDeciduous')}>
					<ListItemIcon>{layers.missingTeethDeciduous
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Missing Teeth (Deciduous)</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('diagnostics')}>
					<ListItemIcon>{layers.diagnostics
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Diagnostics</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('treatments')}>
					<ListItemIcon>{layers.treatments
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Treatments</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('text')}>
					<ListItemIcon>{layers.text
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Text</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('drawings')}>
					<ListItemIcon>{layers.drawings
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Drawings</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('shapes')}>
					<ListItemIcon>{layers.shapes
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Shapes</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('softTissue')}>
					<ListItemIcon>{layers.softTissue
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Soft Tissue</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('boneView')}>
					<ListItemIcon>{layers.boneView
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Bone View</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('sublingual')}>
					<ListItemIcon>{layers.sublingual
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Sublingual</ListItemText>
				</MenuItem>

				<MenuItem onClick={toggleLayer('probingValues')}>
					<ListItemIcon>{layers.probingValues
						? <CheckBox />
						: <EmptyCheck />}</ListItemIcon>
					<ListItemText>Probing Values</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};



export default MenuLayers;
