import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import useStyles from './DashboardCardIconHeader.styles';



const DashboardCardIconHeader = React.memo(
	({
		 icon,
	 }) => {

		const classes = useStyles();
		let clone = React.cloneElement(icon, {
			className: classnames({
				[ classes.icon ]: true,
			}),
		});


		return (
			<div>
			<span className={classes.message}>
				{clone}
			</span>
			</div>
		);

	},
);


DashboardCardIconHeader.propTypes = {
	icon: PropTypes.PropTypes.node.isRequired,
};

export default DashboardCardIconHeader;