import { assign } from 'lodash';

function renderLine(helper, item, opt, layer, lineOpt) {
	if(!opt.tooth) {
		return false;
	}

	//console.log(item.name, opt.canvas);
	let c = helper.setActive(opt.canvas, layer);
	let paths = c.layers[layer].paths;

	if(paths[opt.name]) {
		paths[opt.name].remove();
		delete(paths[opt.name]);
	}

	let toothLayer = lineOpt.toothLayer ? c.layers[lineOpt.toothLayer] : c.layers[`teeth_${opt.canvas}`];
	let tooth = toothLayer.paths[opt.tooth];

	/*
	if(isPointInside(tooth, opt.position)) {
		return true;
	}
	*/

	let nearest = findNearestBoundary(opt.position, tooth);
	let linePoint;

	// Adjust the line so it doesn't go through the destination target
	switch(nearest.name) {
		case 'bottomCenter':
			linePoint = [item.bounds.topCenter.x, item.bounds.topCenter.y];
			break;
		case 'leftCenter':
			linePoint = [item.bounds.rightCenter.x, item.bounds.rightCenter.y];
			break;
		case 'rightCenter':
			linePoint = [item.bounds.leftCenter.x, item.bounds.leftCenter.y];
			break;
		case 'topCenter':
			linePoint = [item.bounds.bottomCenter.x, item.bounds.bottomCenter.y];
			break;
		default:
			linePoint = opt.position;
	}

	let line = new c.paper.Path.Line(linePoint, opt.origin);
	line.name = opt.name;
	paths[opt.name] = line;
	assign(line, lineOpt);

	if(line.length < 70) {
		line.remove();
		return true;
	}
	return line;
}


// eslint-disable-next-line
function isPointInside(item, coords) {
	if(!item || !item.bounds) {
		return false;
	}

	let x = coords[0];
	let y = coords[1];

	return (
		x > item.bounds.left
		&& x < item.bounds.right
		&& y > item.bounds.top
		&& y < item.bounds.bottom
	);
}


function getDistance(from, to) {
	return Math.sqrt( Math.pow(to[0] - from[0], 2) + Math.pow(to[1] - from[1], 2) );
}


function findNearestBoundary(point, item) {
	if(!item || !item.bounds) {
		return false;
	}

	let bounds = [
		//'bottomLeft',
		//'bottomRight',
		'bottomCenter',
		//'topLeft',
		//'topRight',
		'topCenter',
		'leftCenter',
		'rightCenter'
	];

	let nearest = null;
	let boundsCount = bounds.length;

	while(boundsCount--) {
		let distance = getDistance(
			point,
			[item.bounds[bounds[boundsCount]].x, item.bounds[bounds[boundsCount]].y]
		);

		if(!nearest || distance < nearest.distance) {
			nearest = {
				distance,
				name: bounds[boundsCount],
				point: [
					item.bounds[bounds[boundsCount]].x,
					item.bounds[bounds[boundsCount]].y
				],
			};
		}
	}

	return nearest;
}


export default renderLine;