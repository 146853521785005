import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Grid as MUIGrid } from '@mui/material';
import useStyles from './PatientView.styles';
import Profile from './Profile';
import { actions } from './PatientView.redux';
import classnames from 'classnames';
import PatientReports from './PatientReports';
import PatientCharts from './PatientCharts';



const PatientView = () => {

    const tab      = useSelector(state => state.patientView.tab),
          dispatch = useDispatch(),
          classes  = useStyles(),
          resetAll = () => dispatch(actions.resetAll());


    const handleTabChange = useCallback((e) => {
        if(tab === e.currentTarget.name) {
            return false;
        }

        dispatch(actions.setState({ tab: e.currentTarget.name }));
    }, [ dispatch, tab ]);


    useEffect(() => {
        return () => {
            resetAll();
        };
    }, [ dispatch ]);


    return (
        <MUIGrid container spacing={4}>
            <MUIGrid item xs={12} sm={12} lg={4} xl={3}>
                <Profile />
            </MUIGrid>

            <MUIGrid item xs={12} sm={12} lg={8} xl={9}>
                <div className={classes.buttonGroupContainer}>
                    <ButtonGroup fullWidth variant="contained">
                        <Button
                            size="large"
                            name="charts"
                            color="grey"
                            onClick={handleTabChange}
                            classes={{
                                root: classnames({
                                    [classes.visitsActive]: tab === 'charts',
                                    [classes.visitsInactive]: tab !== 'charts',
                                }),
                            }}
                        >
                            Charts
                        </Button>

                        <Button
                            size="large"
                            name="reports"
                            onClick={handleTabChange}
                            color="grey"
                            classes={{
                                root: classnames({
                                    [classes.reportsActive]: tab === 'reports',
                                    [classes.reportsInactive]: tab !== 'reports',
                                }),
                            }}
                        >
                            Reports
                        </Button>
                    </ButtonGroup>
                </div>

                {tab === 'charts' && <PatientCharts />}
                {tab === 'reports' && <PatientReports />}

            </MUIGrid>
        </MUIGrid>
    );

};



export default PatientView;