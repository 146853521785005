export default [
	[
		"dictionary",
		{
			"#7": [
				"Gradient",
				[
					[
						[
							0.87843,
							0.87843,
							0.87843,
						],
						0,
					],
					[
						[
							0.94902,
							0.94902,
							0.94902,
						],
						0.5231,
					],
					[
						[
							0.87843,
							0.87843,
							0.87843,
						],
						1,
					],
				],
				false,
			],
			"#9": [
				"Gradient",
				[
					[
						[
							0.87843,
							0.87843,
							0.87843,
						],
						0,
					],
					[
						[
							0.94902,
							0.94902,
							0.94902,
						],
						0.5231,
					],
					[
						[
							0.87843,
							0.87843,
							0.87843,
						],
						1,
					],
				],
				false,
			],
		},
	],

	[
		"Group",
		{
			"name": "Layer_1",
			"applyMatrix": true,
			"children": [
				[
					"Shape",
					{
						"applyMatrix": false,
						"matrix": [
							1,
							0,
							0,
							1,
							397.35,
							736.2,
						],
						"clipMask": true,
						"type": "rectangle",
						"size": [
							794.7,
							1472.4,
						],
						"radius": [
							0,
							0,
						],
					},
				],
				[
					"Path",
					{
						"applyMatrix": false,
						"segments": [
							[
								[
									774.3,
									640,
								],
								[
									8,
									15.3,
								],
								[
									-8,
									-15.3,
								],
							],
							[
								[
									697,
									669.5,
								],
								[
									10,
									-30,
								],
								[
									-10,
									30,
								],
							],
							[
								[
									638.5,
									711.5,
								],
								[
									59,
									6,
								],
								[
									-10.9,
									-1.1,
								],
							],
							[
								[
									635.5,
									734.2,
								],
								[
									-12,
									-2.7,
								],
								[
									12,
									2.7,
								],
							],
							[
								[
									668.1,
									772.6,
								],
								[
									7.5,
									-27,
								],
								[
									-7.5,
									27,
								],
							],
							[
								[
									403.2,
									1282,
								],
								[
									110,
									0,
								],
								[
									-3.3,
									5.3,
								],
							],
							[
								[
									397.6,
									1294.3,
								],
								[
									0.7,
									-2.9,
								],
								[
									-0.7,
									-2.9,
								],
							],
							[
								[
									392,
									1282,
								],
								[
									3.4,
									5.3,
								],
								[
									-110,
									0,
								],
							],
							[
								[
									127.1,
									772.6,
								],
								[
									7.5,
									27,
								],
								[
									-7.5,
									-27,
								],
							],
							[
								[
									159.7,
									734.2,
								],
								[
									-12,
									2.7,
								],
								[
									12,
									-2.7,
								],
							],
							[
								[
									156.7,
									711.5,
								],
								[
									10.9,
									-1.1,
								],
								[
									-59,
									6,
								],
							],
							[
								[
									98.2,
									669.5,
								],
								[
									10,
									30,
								],
								[
									-10,
									-30,
								],
							],
							[
								[
									21,
									640,
								],
								[
									8,
									-15.3,
								],
								[
									-8,
									15.3,
								],
							],
							[
								[
									52.3,
									712,
								],
								[
									0.7,
									-44.2,
								],
								[
									-0.7,
									44.2,
								],
							],
							[
								[
									175.7,
									1056.7,
								],
								[
									-43.8,
									-92.6,
								],
								[
									48.3,
									102.3,
								],
							],
							[
								[
									216.5,
									1308,
								],
								[
									44.6,
									-49.3,
								],
								[
									-20.7,
									23,
								],
							],
							[
								[
									228.5,
									1363.3,
								],
								[
									-25.3,
									-48,
								],
								[
									25.3,
									48,
								],
							],
							[
								397.5,
								1407,
							],
							[
								397.6,
								1407,
							],
							[
								[
									566.6,
									1363.3,
								],
								[
									-25.3,
									48,
								],
								[
									25.3,
									-48,
								],
							],
							[
								[
									578.6,
									1308,
								],
								[
									20.7,
									23,
								],
								[
									-44.6,
									-49.3,
								],
							],
							[
								[
									620.4,
									1056.7,
								],
								[
									-48.3,
									102.3,
								],
								[
									43.8,
									-92.6,
								],
							],
							[
								[
									742.8,
									712,
								],
								[
									0.7,
									44.2,
								],
								[
									-0.5,
									-44.2,
								],
							],
						],
						"closed": true,
						"fillColor": [
							0.87843,
							0.87843,
							0.87843,
						],
						"strokeColor": [
							0,
							0,
							0,
						],
					},
				],
				[
					"Group",
					{
						"name": "Layer_2_1_",
						"applyMatrix": true,
						"children": [
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											[
												676.6,
												588.9,
											],
											[
												7.9,
												21.1,
											],
											[
												-7.9,
												-21.1,
											],
										],
										[
											[
												621.5,
												446.5,
											],
											[
												15,
												29.5,
											],
											[
												-15,
												-29.5,
											],
										],
										[
											[
												585,
												322.5,
											],
											[
												9.5,
												26.5,
											],
											[
												-9.5,
												-26.5,
											],
										],
										[
											[
												555.6,
												212,
											],
											[
												32.9,
												-2,
											],
											[
												-32.9,
												2,
											],
										],
										[
											[
												509.5,
												130.7,
											],
											[
												20,
												19.3,
											],
											[
												-18.5,
												-17.8,
											],
										],
										[
											[
												397.7,
												114.4,
											],
											[
												12.4,
												-0.3,
											],
											[
												-12.4,
												-0.3,
											],
										],
										[
											[
												285.9,
												130.7,
											],
											[
												18.5,
												-17.9,
											],
											[
												-19.9,
												19.3,
											],
										],
										[
											[
												239.9,
												212,
											],
											[
												32.9,
												2,
											],
											[
												-32.9,
												-2,
											],
										],
										[
											[
												210.5,
												322.5,
											],
											[
												9.5,
												-26.5,
											],
											[
												-9.5,
												26.5,
											],
										],
										[
											[
												174,
												446.5,
											],
											[
												15,
												-29.5,
											],
											[
												-15,
												29.5,
											],
										],
										[
											[
												118.9,
												588.9,
											],
											[
												7.9,
												-21.1,
											],
											[
												-7.9,
												21.1,
											],
										],
										[
											[
												132.3,
												670,
											],
											[
												-40.7,
												-19,
											],
											[
												40.7,
												19,
											],
										],
										[
											[
												194.5,
												688,
											],
											[
												-3.2,
												7,
											],
											[
												6.2,
												14,
											],
										],
										[
											[
												243.7,
												732,
											],
											[
												-7,
												2,
											],
											[
												7,
												-2,
											],
										],
										[
											[
												286.9,
												685,
											],
											[
												-18.4,
												-1,
											],
											[
												18.4,
												1,
											],
										],
										[
											[
												335,
												777,
											],
											[
												1,
												-29,
											],
											[
												10,
												1,
											],
										],
										[
											345,
											778,
										],
										[
											[
												397.8,
												675.1,
											],
											[
												-46.2,
												2.2,
											],
											[
												46.2,
												2.2,
											],
										],
										[
											450.5,
											778,
										],
										[
											[
												460.5,
												777,
											],
											[
												-10,
												1,
											],
											[
												-1,
												-29,
											],
										],
										[
											[
												508.6,
												685,
											],
											[
												-18.4,
												1,
											],
											[
												18.4,
												-1,
											],
										],
										[
											[
												551.8,
												732,
											],
											[
												-7,
												-2,
											],
											[
												7,
												2,
											],
										],
										[
											[
												591,
												688,
											],
											[
												-6.2,
												14,
											],
											[
												3.2,
												7,
											],
										],
										[
											[
												663.2,
												670,
											],
											[
												-40.7,
												19,
											],
											[
												40.7,
												-19,
											],
										],
									],
									"closed": true,
									"fillColor": [
										0.87843,
										0.87843,
										0.87843,
									],
									"strokeColor": [
										0,
										0,
										0,
									],
								},
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											397.5,
											1295.2,
										],
										[
											397.1,
											1299.8,
										],
										[
											398.4,
											1301.5,
										],
										[
											395.1,
											1306.8,
										],
										[
											399.8,
											1306.8,
										],
										[
											[
												399.8,
												1314.1,
											],
											[
												0.3,
												-3,
											],
											[
												-0.3,
												3,
											],
										],
										[
											397.1,
											1318.8,
										],
										[
											[
												397.1,
												1320.8,
											],
											[
												-0.3,
												-1.7,
											],
											[
												0.3,
												1.7,
											],
										],
										[
											399.9,
											1325.8,
										],
										[
											399.9,
											1329.1,
										],
										[
											397.5,
											1331.1,
										],
										[
											397.5,
											1333.1,
										],
										[
											399.9,
											1341.1,
										],
										[
											[
												395.1,
												1345.1,
											],
											[
												1,
												-1.3,
											],
											[
												-1,
												1.3,
											],
										],
										[
											397.1,
											1350.1,
										],
										[
											399.9,
											1352.4,
										],
										[
											397.1,
											1355.4,
										],
										[
											[
												394.9,
												1358.7,
											],
											[
												-0.5,
												-2.4,
											],
											[
												0.5,
												2.4,
											],
										],
										[
											397.4,
											1366.4,
										],
										[
											399.9,
											1368.7,
										],
										[
											397.4,
											1372.2,
										],
										[
											397.4,
											1377,
										],
										[
											394.9,
											1380.4,
										],
										[
											397.4,
											1383.8,
										],
										[
											397.1,
											1390.7,
										],
										[
											394.8,
											1393.1,
										],
										[
											397.1,
											1397.3,
										],
										[
											397.4,
											1399.9,
										],
										[
											397.4,
											1407,
										],
									],
									"strokeColor": [
										0,
										0,
										0,
									],
								},
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											353,
											227.4,
										],
										[
											276,
											585.7,
										],
										[
											297.5,
											586.9,
										],
										[
											375.7,
											242.5,
										],
									],
									"fillColor": [
										"gradient",
										[
											"#7",
										],
										[
											310.9573,
											405.8613,
										],
										[
											342.7445,
											412.0402,
										],
									],
								},
							],
							[
								"Path",
								{
									"applyMatrix": false,
									"segments": [
										[
											441.7,
											227.4,
										],
										[
											517.7,
											585.7,
										],
										[
											496.2,
											586.9,
										],
										[
											419,
											242.5,
										],
									],
									"fillColor": [
										"gradient",
										[
											"#9",
										],
										[
											482.7604,
											405.9551,
										],
										[
											451.9377,
											411.9465,
										],
									],
								},
							],
							[
								"Shape",
								{
									"applyMatrix": false,
									"matrix": [
										0.9985,
										-0.0543,
										0.0543,
										0.9985,
										429.38975,
										236.19361,
									],
									"type": "ellipse",
									"size": [
										22.6,
										60.8,
									],
									"radius": [
										11.3,
										30.4,
									],
									"fillColor": [
										1,
										1,
										1,
									],
								},
							],
							[
								"Shape",
								{
									"applyMatrix": false,
									"matrix": [
										0.9985,
										-0.0543,
										0.0543,
										0.9985,
										506.88838,
										586.28492,
									],
									"type": "ellipse",
									"size": [
										21.4,
										14.8,
									],
									"radius": [
										10.7,
										7.4,
									],
									"fillColor": [
										1,
										1,
										1,
									],
								},
							],
							[
								"Shape",
								{
									"applyMatrix": false,
									"matrix": [
										0.0543,
										-0.9985,
										0.9985,
										0.0543,
										364.40049,
										236.25327,
									],
									"type": "ellipse",
									"size": [
										60.8,
										22.6,
									],
									"radius": [
										30.4,
										11.3,
									],
									"fillColor": [
										1,
										1,
										1,
									],
								},
							],
							[
								"Shape",
								{
									"applyMatrix": false,
									"matrix": [
										0.0543,
										-0.9985,
										0.9985,
										0.0543,
										286.8265,
										586.25732,
									],
									"type": "ellipse",
									"size": [
										14.8,
										21.4,
									],
									"radius": [
										7.4,
										10.7,
									],
									"fillColor": [
										1,
										1,
										1,
									],
								},
							],
						],
					},
				],
				[
					"Group",
					{
						"name": "feline_occlusal",
						"applyMatrix": true,
						"visible": false,
						"children": [
							[
								"Group",
								{
									"name": "feline_occlusal_lower",
									"applyMatrix": true,
									"children": [
										[
											"Group",
											{
												"name": "fourthQuadrant",
												"applyMatrix": true,
												"children": [
													[
														"Group",
														{
															"name": "feline_occlusal_lower_401",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					370.2,
																					1384.2,
																				],
																				[
																					-7.8,
																					12.7,
																				],
																				[
																					9.6,
																					-0.6,
																				],
																			],
																			[
																				[
																					379.8,
																					1383.6,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					4.8,
																					12,
																				],
																			],
																			[
																				[
																					385.8,
																					1398.6,
																				],
																				[
																					1.2,
																					-7.2,
																				],
																				[
																					-1.2,
																					7.2,
																				],
																			],
																			[
																				[
																					379.8,
																					1408.8,
																				],
																				[
																					8.4,
																					-3.6,
																				],
																				[
																					-8.4,
																					3.6,
																				],
																			],
																			[
																				[
																					367.8,
																					1407.6,
																				],
																				[
																					2.4,
																					3,
																				],
																				[
																					-2.4,
																					-2.9,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_402",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					320.9,
																					1404.1,
																				],
																				[
																					-3,
																					-4.2,
																				],
																				[
																					10.8,
																					2.4,
																				],
																			],
																			[
																				[
																					345.5,
																					1403.5,
																				],
																				[
																					-1.2,
																					6,
																				],
																				[
																					1.2,
																					-6,
																				],
																			],
																			[
																				[
																					338.3,
																					1381.3,
																				],
																				[
																					4.8,
																					1.8,
																				],
																				[
																					-4.8,
																					-1.8,
																				],
																			],
																			[
																				[
																					326.9,
																					1381.3,
																				],
																				[
																					1.2,
																					-2.4,
																				],
																				[
																					-1.2,
																					2.3,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_403",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					277.1,
																					1396.9,
																				],
																				[
																					-1.8,
																					-5.4,
																				],
																				[
																					1.5,
																					4.6,
																				],
																			],
																			[
																				[
																					301.7,
																					1397.5,
																				],
																				[
																					-3.6,
																					6,
																				],
																				[
																					3.6,
																					-6,
																				],
																			],
																			[
																				[
																					302.9,
																					1379.5,
																				],
																				[
																					2.4,
																					4.8,
																				],
																				[
																					-2.4,
																					-4.8,
																				],
																			],
																			[
																				[
																					292.1,
																					1371.7,
																				],
																				[
																					3,
																					0,
																				],
																				[
																					-3,
																					0,
																				],
																			],
																			[
																				[
																					275.9,
																					1377.7,
																				],
																				[
																					0,
																					-4.8,
																				],
																				[
																					0,
																					4.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_404",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					212.8,
																					1327.8,
																				],
																				[
																					10.2,
																					-21.7,
																				],
																				[
																					-2.1,
																					4.5,
																				],
																			],
																			[
																				[
																					206.8,
																					1339.2,
																				],
																				[
																					12,
																					-5.4,
																				],
																				[
																					-12,
																					5.4,
																				],
																			],
																			[
																				[
																					170.8,
																					1353,
																				],
																				[
																					2.4,
																					-9,
																				],
																				[
																					-3.3,
																					12.2,
																				],
																			],
																			[
																				[
																					202,
																					1386.4,
																				],
																				[
																					-13.2,
																					-4.8,
																				],
																				[
																					13.2,
																					4.8,
																				],
																			],
																			[
																				[
																					241.7,
																					1383.6,
																				],
																				[
																					-14.8,
																					8.1,
																				],
																				[
																					10.5,
																					-5.7,
																				],
																			],
																			[
																				[
																					262.1,
																					1349.4,
																				],
																				[
																					0.6,
																					9.6,
																				],
																				[
																					-0.6,
																					-9.6,
																				],
																			],
																			[
																				[
																					248.3,
																					1320,
																				],
																				[
																					7.2,
																					2.4,
																				],
																				[
																					-7.3,
																					-2.4,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_407",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					205,
																					1102.5,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					-15.6,
																					12,
																				],
																			],
																			[
																				[
																					202.6,
																					1135.5,
																				],
																				[
																					-5.4,
																					-6.6,
																				],
																				[
																					5.4,
																					6.6,
																				],
																			],
																			[
																				[
																					220,
																					1171.5,
																				],
																				[
																					-6.6,
																					-7.8,
																				],
																				[
																					6.6,
																					7.8,
																				],
																			],
																			[
																				[
																					249.4,
																					1183.5,
																				],
																				[
																					-9,
																					3.6,
																				],
																				[
																					9,
																					-3.6,
																				],
																			],
																			[
																				[
																					256,
																					1148.7,
																				],
																				[
																					10.2,
																					24,
																				],
																				[
																					-10.2,
																					-24,
																				],
																			],
																			[
																				[
																					220.6,
																					1097,
																				],
																				[
																					12.6,
																					-0.6,
																				],
																				[
																					-12.6,
																					0.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					207.1,
																					1118.2,
																				],
																				[
																					12.4,
																					-5.4,
																				],
																				[
																					1.6,
																					1.6,
																				],
																			],
																			[
																				208.7,
																				1119.8,
																			],
																			[
																				[
																					226.9,
																					1112.6,
																				],
																				[
																					-11.4,
																					0,
																				],
																				[
																					-2,
																					-1.4,
																				],
																			],
																			[
																				226.2,
																				1110.1,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_408",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					201.4,
																					1084.4,
																				],
																				[
																					-16.3,
																					7.3,
																				],
																				[
																					11,
																					-4.9,
																				],
																			],
																			[
																				[
																					214,
																					1058,
																				],
																				[
																					1.8,
																					10.2,
																				],
																				[
																					-1.8,
																					-10.2,
																				],
																			],
																			[
																				[
																					203.8,
																					1028,
																				],
																				[
																					2.4,
																					14.4,
																				],
																				[
																					-2.4,
																					-14.4,
																				],
																			],
																			[
																				[
																					185.8,
																					984.7,
																				],
																				[
																					9.6,
																					7.9,
																				],
																				[
																					-9.6,
																					-7.8,
																				],
																			],
																			[
																				[
																					152.2,
																					985.9,
																				],
																				[
																					5.4,
																					-6.6,
																				],
																				[
																					-5.4,
																					6.6,
																				],
																			],
																			[
																				[
																					147.4,
																					1020.7,
																				],
																				[
																					-6.6,
																					-15.6,
																				],
																				[
																					6.6,
																					15.6,
																				],
																			],
																			[
																				[
																					165.4,
																					1056.7,
																				],
																				[
																					-7.8,
																					-6,
																				],
																				[
																					4.1,
																					21.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					155.6,
																					1013.9,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					10.2,
																					0,
																				],
																			],
																			[
																				[
																					175.4,
																					1009.1,
																				],
																				[
																					-4.2,
																					3,
																				],
																				[
																					-0.6,
																					5.4,
																				],
																			],
																			[
																				[
																					177.8,
																					1015.7,
																				],
																				[
																					-0.6,
																					-0.6,
																				],
																				[
																					-10.8,
																					-0.6,
																				],
																			],
																			[
																				[
																					156.2,
																					1019.3,
																				],
																				[
																					3.6,
																					-4.2,
																				],
																				[
																					1.6,
																					-4.1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					180.1,
																					1066.1,
																				],
																				[
																					12.6,
																					2.5,
																				],
																				[
																					2,
																					0.7,
																				],
																			],
																			[
																				181.9,
																				1069,
																			],
																			[
																				[
																					202,
																					1060.2,
																				],
																				[
																					-3.4,
																					7,
																				],
																				[
																					-1.6,
																					-1.4,
																				],
																			],
																			[
																				[
																					200.2,
																					1057.3,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					-3,
																					6.3,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_409",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					164.7,
																					911.1,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					14.4,
																					17.4,
																				],
																			],
																			[
																				[
																					180.3,
																					956.8,
																				],
																				[
																					7.8,
																					-10.3,
																				],
																				[
																					-7.8,
																					10.3,
																				],
																			],
																			[
																				[
																					146.7,
																					966.4,
																				],
																				[
																					13.8,
																					6,
																				],
																				[
																					-13.8,
																					-6,
																				],
																			],
																			[
																				[
																					123.9,
																					935.2,
																				],
																				[
																					4.8,
																					7.8,
																				],
																				[
																					-4.8,
																					-7.8,
																				],
																			],
																			[
																				[
																					102.9,
																					869.7,
																				],
																				[
																					-5.4,
																					21.6,
																				],
																				[
																					5.4,
																					-21.6,
																				],
																			],
																			[
																				[
																					132.9,
																					854.1,
																				],
																				[
																					-13.2,
																					-9.6,
																				],
																				[
																					13.2,
																					9.6,
																				],
																			],
																			[
																				[
																					150.9,
																					883.5,
																				],
																				[
																					-3,
																					-14.4,
																				],
																				[
																					3,
																					14.4,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					150.6,
																					906.3,
																				],
																				[
																					-5.1,
																					9,
																				],
																				[
																					1.8,
																					6.9,
																				],
																			],
																			[
																				[
																					159.6,
																					921,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					-7,
																					-1.4,
																				],
																			],
																			[
																				[
																					136.5,
																					923.7,
																				],
																				[
																					4.9,
																					-7.2,
																				],
																				[
																					-0.3,
																					-3,
																				],
																			],
																			[
																				[
																					132,
																					919.5,
																				],
																				[
																					3.8,
																					1.2,
																				],
																				[
																					11.8,
																					2,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
												],
											},
										],
										[
											"Group",
											{
												"name": "thirdQuadrant",
												"applyMatrix": true,
												"children": [
													[
														"Group",
														{
															"name": "feline_occlusal_lower_301",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					424.6,
																					1384.2,
																				],
																				[
																					7.8,
																					12.7,
																				],
																				[
																					-9.6,
																					-0.6,
																				],
																			],
																			[
																				[
																					415,
																					1383.6,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					-4.8,
																					12,
																				],
																			],
																			[
																				[
																					409,
																					1398.6,
																				],
																				[
																					-1.2,
																					-7.2,
																				],
																				[
																					1.2,
																					7.2,
																				],
																			],
																			[
																				[
																					415,
																					1408.8,
																				],
																				[
																					-8.4,
																					-3.6,
																				],
																				[
																					8.4,
																					3.6,
																				],
																			],
																			[
																				[
																					427,
																					1407.6,
																				],
																				[
																					-2.4,
																					3,
																				],
																				[
																					2.4,
																					-2.9,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_302",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					473.8,
																					1404.1,
																				],
																				[
																					3,
																					-4.2,
																				],
																				[
																					-10.8,
																					2.4,
																				],
																			],
																			[
																				[
																					449.2,
																					1403.5,
																				],
																				[
																					1.2,
																					6,
																				],
																				[
																					-1.2,
																					-6,
																				],
																			],
																			[
																				[
																					456.4,
																					1381.3,
																				],
																				[
																					-4.8,
																					1.8,
																				],
																				[
																					4.8,
																					-1.8,
																				],
																			],
																			[
																				[
																					467.8,
																					1381.3,
																				],
																				[
																					-1.2,
																					-2.4,
																				],
																				[
																					1.2,
																					2.3,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_303",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					517.7,
																					1396.9,
																				],
																				[
																					1.8,
																					-5.4,
																				],
																				[
																					-1.5,
																					4.6,
																				],
																			],
																			[
																				[
																					493.1,
																					1397.5,
																				],
																				[
																					3.6,
																					6,
																				],
																				[
																					-3.6,
																					-6,
																				],
																			],
																			[
																				[
																					491.9,
																					1379.5,
																				],
																				[
																					-2.4,
																					4.8,
																				],
																				[
																					2.4,
																					-4.8,
																				],
																			],
																			[
																				[
																					502.7,
																					1371.7,
																				],
																				[
																					-3,
																					0,
																				],
																				[
																					3,
																					0,
																				],
																			],
																			[
																				[
																					518.9,
																					1377.7,
																				],
																				[
																					0,
																					-4.8,
																				],
																				[
																					0,
																					4.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_304",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					582,
																					1327.8,
																				],
																				[
																					-10.3,
																					-21.7,
																				],
																				[
																					2.1,
																					4.5,
																				],
																			],
																			[
																				[
																					588,
																					1339.2,
																				],
																				[
																					-12,
																					-5.4,
																				],
																				[
																					12,
																					5.4,
																				],
																			],
																			[
																				[
																					624,
																					1353,
																				],
																				[
																					-2.4,
																					-9,
																				],
																				[
																					3.3,
																					12.2,
																				],
																			],
																			[
																				[
																					592.8,
																					1386.4,
																				],
																				[
																					13.2,
																					-4.8,
																				],
																				[
																					-13.2,
																					4.8,
																				],
																			],
																			[
																				[
																					553.1,
																					1383.6,
																				],
																				[
																					14.8,
																					8.1,
																				],
																				[
																					-10.5,
																					-5.7,
																				],
																			],
																			[
																				[
																					532.7,
																					1349.4,
																				],
																				[
																					-0.6,
																					9.6,
																				],
																				[
																					0.6,
																					-9.6,
																				],
																			],
																			[
																				[
																					546.5,
																					1320,
																				],
																				[
																					-7.2,
																					2.4,
																				],
																				[
																					7.2,
																					-2.4,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_307",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					589.8,
																					1102.5,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					15.6,
																					12,
																				],
																			],
																			[
																				[
																					592.2,
																					1135.5,
																				],
																				[
																					5.4,
																					-6.6,
																				],
																				[
																					-5.4,
																					6.6,
																				],
																			],
																			[
																				[
																					574.8,
																					1171.5,
																				],
																				[
																					6.6,
																					-7.8,
																				],
																				[
																					-6.6,
																					7.8,
																				],
																			],
																			[
																				[
																					545.4,
																					1183.5,
																				],
																				[
																					9,
																					3.6,
																				],
																				[
																					-9,
																					-3.6,
																				],
																			],
																			[
																				[
																					538.8,
																					1148.7,
																				],
																				[
																					-10.2,
																					24,
																				],
																				[
																					10.2,
																					-24,
																				],
																			],
																			[
																				[
																					574.2,
																					1097,
																				],
																				[
																					-12.6,
																					-0.6,
																				],
																				[
																					12.6,
																					0.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					587.6,
																					1118.2,
																				],
																				[
																					-12.4,
																					-5.4,
																				],
																				[
																					-1.6,
																					1.6,
																				],
																			],
																			[
																				586,
																				1119.8,
																			],
																			[
																				[
																					567.8,
																					1112.6,
																				],
																				[
																					11.4,
																					0,
																				],
																				[
																					2,
																					-1.4,
																				],
																			],
																			[
																				568.5,
																				1110.1,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_308",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					593.4,
																					1084.4,
																				],
																				[
																					16.2,
																					7.3,
																				],
																				[
																					-11,
																					-4.9,
																				],
																			],
																			[
																				[
																					580.8,
																					1058,
																				],
																				[
																					-1.8,
																					10.2,
																				],
																				[
																					1.8,
																					-10.2,
																				],
																			],
																			[
																				[
																					591,
																					1028,
																				],
																				[
																					-2.4,
																					14.4,
																				],
																				[
																					2.4,
																					-14.4,
																				],
																			],
																			[
																				[
																					609,
																					984.7,
																				],
																				[
																					-9.6,
																					7.9,
																				],
																				[
																					9.6,
																					-7.8,
																				],
																			],
																			[
																				[
																					642.6,
																					985.9,
																				],
																				[
																					-5.4,
																					-6.6,
																				],
																				[
																					5.4,
																					6.6,
																				],
																			],
																			[
																				[
																					647.4,
																					1020.7,
																				],
																				[
																					6.6,
																					-15.6,
																				],
																				[
																					-6.6,
																					15.6,
																				],
																			],
																			[
																				[
																					629.4,
																					1056.7,
																				],
																				[
																					7.8,
																					-6,
																				],
																				[
																					-4.2,
																					21.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					639.1,
																					1013.9,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					-10.2,
																					0,
																				],
																			],
																			[
																				[
																					619.3,
																					1009.1,
																				],
																				[
																					4.2,
																					3,
																				],
																				[
																					0.6,
																					5.4,
																				],
																			],
																			[
																				[
																					616.9,
																					1015.7,
																				],
																				[
																					0.6,
																					-0.6,
																				],
																				[
																					10.8,
																					-0.6,
																				],
																			],
																			[
																				[
																					638.5,
																					1019.3,
																				],
																				[
																					-3.6,
																					-4.2,
																				],
																				[
																					-1.6,
																					-4.1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					614.6,
																					1066.1,
																				],
																				[
																					-12.6,
																					2.5,
																				],
																				[
																					-2,
																					0.7,
																				],
																			],
																			[
																				612.8,
																				1069,
																			],
																			[
																				[
																					592.7,
																					1060.2,
																				],
																				[
																					3.4,
																					7,
																				],
																				[
																					1.6,
																					-1.4,
																				],
																			],
																			[
																				[
																					594.5,
																					1057.3,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					3,
																					6.3,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_lower_309",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					630,
																					911.1,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					-14.4,
																					17.4,
																				],
																			],
																			[
																				[
																					614.4,
																					956.8,
																				],
																				[
																					-7.8,
																					-10.3,
																				],
																				[
																					7.8,
																					10.3,
																				],
																			],
																			[
																				[
																					648,
																					966.4,
																				],
																				[
																					-13.8,
																					6,
																				],
																				[
																					13.8,
																					-6,
																				],
																			],
																			[
																				[
																					670.8,
																					935.2,
																				],
																				[
																					-4.8,
																					7.8,
																				],
																				[
																					4.8,
																					-7.8,
																				],
																			],
																			[
																				[
																					691.8,
																					869.7,
																				],
																				[
																					5.4,
																					21.6,
																				],
																				[
																					-5.4,
																					-21.6,
																				],
																			],
																			[
																				[
																					661.8,
																					854.1,
																				],
																				[
																					13.2,
																					-9.6,
																				],
																				[
																					-13.2,
																					9.6,
																				],
																			],
																			[
																				[
																					643.8,
																					883.5,
																				],
																				[
																					3,
																					-14.4,
																				],
																				[
																					-3,
																					14.4,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					644.1,
																					906.3,
																				],
																				[
																					5.1,
																					9,
																				],
																				[
																					-1.8,
																					6.9,
																				],
																			],
																			[
																				[
																					635.1,
																					921,
																				],
																				[
																					0,
																					0,
																				],
																				[
																					7,
																					-1.4,
																				],
																			],
																			[
																				[
																					658.2,
																					923.7,
																				],
																				[
																					-4.9,
																					-7.2,
																				],
																				[
																					0.3,
																					-3,
																				],
																			],
																			[
																				[
																					662.7,
																					919.5,
																				],
																				[
																					-3.8,
																					1.2,
																				],
																				[
																					-11.8,
																					2,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
												],
											},
										],
									],
								},
							],
							[
								"Group",
								{
									"name": "feline_occlusal_upper",
									"applyMatrix": true,
									"children": [
										[
											"Group",
											{
												"name": "firstQuadrant",
												"applyMatrix": true,
												"children": [
													[
														"Group",
														{
															"name": "feline_occlusal_upper_101",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				371.6,
																				142.3,
																			],
																			[
																				364.6,
																				127.7,
																			],
																			[
																				[
																					365.7,
																					115.5,
																				],
																				[
																					-1.3,
																					2.3,
																				],
																				[
																					1.4,
																					-2.3,
																				],
																			],
																			[
																				[
																					374.3,
																					110.3,
																				],
																				[
																					-4.3,
																					-0.2,
																				],
																				[
																					4.3,
																					0.2,
																				],
																			],
																			[
																				[
																					388.5,
																					117.1,
																				],
																				[
																					-3.6,
																					-8.6,
																				],
																				[
																					3.6,
																					8.6,
																				],
																			],
																			[
																				[
																					387.8,
																					132.6,
																				],
																				[
																					2.5,
																					-4.5,
																				],
																				[
																					-4.1,
																					8.8,
																				],
																			],
																			[
																				383.7,
																				141.4,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_101_1_",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				411.8,
																				141.4,
																			],
																			[
																				[
																					407.7,
																					132.6,
																				],
																				[
																					4.1,
																					8.8,
																				],
																				[
																					-2.5,
																					-4.5,
																				],
																			],
																			[
																				[
																					407,
																					117.1,
																				],
																				[
																					-3.6,
																					8.6,
																				],
																				[
																					3.6,
																					-8.6,
																				],
																			],
																			[
																				[
																					421.2,
																					110.3,
																				],
																				[
																					-4.3,
																					0.2,
																				],
																				[
																					4.3,
																					-0.2,
																				],
																			],
																			[
																				[
																					429.8,
																					115.5,
																				],
																				[
																					-1.4,
																					-2.3,
																				],
																				[
																					1.3,
																					2.3,
																				],
																			],
																			[
																				430.9,
																				127.7,
																			],
																			[
																				423.9,
																				142.3,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_102",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				327.7,
																				144.1,
																			],
																			[
																				[
																					320.9,
																					116.2,
																				],
																				[
																					-2.9,
																					9.7,
																				],
																				[
																					19.6,
																					-2.9,
																				],
																			],
																			[
																				[
																					349.5,
																					115.5,
																				],
																				[
																					-5.2,
																					-3.4,
																				],
																				[
																					5.2,
																					3.4,
																				],
																			],
																			[
																				[
																					349.7,
																					131.7,
																				],
																				[
																					2.1,
																					-4.9,
																				],
																				[
																					-2,
																					5,
																				],
																			],
																			[
																				[
																					335.7,
																					145.7,
																				],
																				[
																					7,
																					0.6,
																				],
																				[
																					-6.9,
																					-0.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_103",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					277.7,
																					137.1,
																				],
																				[
																					-2.9,
																					-6.3,
																				],
																				[
																					0,
																					0,
																				],
																			],
																			[
																				280.2,
																				146.8,
																			],
																			[
																				[
																					293.7,
																					154.9,
																				],
																				[
																					-12.8,
																					1.1,
																				],
																				[
																					12.8,
																					-1.1,
																				],
																			],
																			[
																				[
																					309,
																					149.3,
																				],
																				[
																					-4.3,
																					5.8,
																				],
																				[
																					4.3,
																					-5.9,
																				],
																			],
																			[
																				[
																					312.4,
																					136.2,
																				],
																				[
																					2,
																					6.6,
																				],
																				[
																					-2,
																					-6.5,
																				],
																			],
																			[
																				[
																					309.5,
																					126.7,
																				],
																				[
																					1.3,
																					3.9,
																				],
																				[
																					-1.4,
																					-3.8,
																				],
																			],
																			[
																				[
																					297.3,
																					118.6,
																				],
																				[
																					8.4,
																					-1.1,
																				],
																				[
																					-8.3,
																					1.1,
																				],
																			],
																			[
																				[
																					280.6,
																					125.6,
																				],
																				[
																					3,
																					-2.9,
																				],
																				[
																					-2.9,
																					2.9,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_104",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					193.4,
																					201.8,
																				],
																				[
																					0.2,
																					4.9,
																				],
																				[
																					-1.5,
																					-33.3,
																				],
																			],
																			[
																				[
																					197.5,
																					138.7,
																				],
																				[
																					-2.2,
																					6.8,
																				],
																				[
																					2.3,
																					-7.2,
																				],
																			],
																			[
																				[
																					219.8,
																					101.5,
																				],
																				[
																					-6.5,
																					0.5,
																				],
																				[
																					6.1,
																					-0.4,
																				],
																			],
																			[
																				[
																					230.5,
																					149.3,
																				],
																				[
																					-1.9,
																					-9.1,
																				],
																				[
																					2.5,
																					12,
																				],
																			],
																			[
																				[
																					244.8,
																					206.5,
																				],
																				[
																					-7.3,
																					-18.2,
																				],
																				[
																					7.2,
																					18,
																				],
																			],
																			[
																				[
																					227.7,
																					258.1,
																				],
																				[
																					29.9,
																					1.6,
																				],
																				[
																					-23.4,
																					-1.3,
																				],
																			],
																			[
																				[
																					198.9,
																					227.5,
																				],
																				[
																					1.5,
																					6,
																				],
																				[
																					-1.7,
																					-6.1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_106",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				217.5,
																				336.9,
																			],
																			[
																				[
																					208.5,
																					322.5,
																				],
																				[
																					-4.3,
																					10.8,
																				],
																				[
																					0,
																					-11.5,
																				],
																			],
																			[
																				[
																					225.2,
																					304.5,
																				],
																				[
																					-7.9,
																					0,
																				],
																				[
																					7.9,
																					0,
																				],
																			],
																			[
																				[
																					248.2,
																					316,
																				],
																				[
																					-0.9,
																					-8.8,
																				],
																				[
																					0.9,
																					8.8,
																				],
																			],
																			[
																				[
																					237.2,
																					335.8,
																				],
																				[
																					6.3,
																					-4.3,
																				],
																				[
																					-6.4,
																					4.3,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				235.5,
																				306.1,
																			],
																			[
																				[
																					238,
																					316.7,
																				],
																				[
																					1.3,
																					-3.2,
																				],
																				[
																					3.4,
																					-6.8,
																				],
																			],
																			[
																				[
																					239.6,
																					307.5,
																				],
																				[
																					0.7,
																					0.9,
																				],
																				[
																					-0.7,
																					-1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				223.4,
																				337.4,
																			],
																			[
																				[
																					233.3,
																					330.9,
																				],
																				[
																					-1.6,
																					3.6,
																				],
																				[
																					-1.4,
																					5.9,
																				],
																			],
																			[
																				229,
																				338.8,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_107",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					165,
																					467.4,
																				],
																				[
																					-1.3,
																					-9.5,
																				],
																				[
																					0.9,
																					6.2,
																				],
																			],
																			[
																				[
																					189.8,
																					478.9,
																				],
																				[
																					-4.5,
																					4,
																				],
																				[
																					4.5,
																					-4.1,
																				],
																			],
																			[
																				[
																					197.7,
																					467.9,
																				],
																				[
																					-2.5,
																					1.8,
																				],
																				[
																					2.5,
																					-1.8,
																				],
																			],
																			[
																				[
																					229.5,
																					430.1,
																				],
																				[
																					-1.4,
																					10.3,
																				],
																				[
																					1.4,
																					-10.4,
																				],
																			],
																			[
																				[
																					229.7,
																					397,
																				],
																				[
																					0,
																					2.2,
																				],
																				[
																					0,
																					-2.2,
																				],
																			],
																			[
																				[
																					230.4,
																					385.5,
																				],
																				[
																					-1.4,
																					4.5,
																				],
																				[
																					1.4,
																					-4.5,
																				],
																			],
																			[
																				[
																					210.1,
																					371.5,
																				],
																				[
																					9.7,
																					-1.3,
																				],
																				[
																					-9.7,
																					1.4,
																				],
																			],
																			[
																				[
																					188.7,
																					390,
																				],
																				[
																					2.7,
																					-9.9,
																				],
																				[
																					-2.7,
																					9.9,
																				],
																			],
																			[
																				[
																					175.2,
																					424.9,
																				],
																				[
																					4.3,
																					-7.2,
																				],
																				[
																					-4.3,
																					7.1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				229.9,
																				389.4,
																			],
																			[
																				[
																					214.4,
																					393.7,
																				],
																				[
																					6.5,
																					3.4,
																				],
																				[
																					10.1,
																					3.8,
																				],
																			],
																			[
																				229.9,
																				392.8,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				186.2,
																				395.7,
																			],
																			[
																				[
																					204.2,
																					391.9,
																				],
																				[
																					-4.5,
																					-1.8,
																				],
																				[
																					-11.5,
																					3.6,
																				],
																			],
																			[
																				[
																					184.8,
																					400,
																				],
																				[
																					1.2,
																					-2.5,
																				],
																				[
																					-1.1,
																					2.5,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					186,
																					435.9,
																				],
																				[
																					7.2,
																					5.4,
																				],
																				[
																					0,
																					0,
																				],
																			],
																			[
																				183.5,
																				441.5,
																			],
																			[
																				[
																					201.5,
																					446.5,
																				],
																				[
																					-6.5,
																					-10,
																				],
																				[
																					2.9,
																					-4.3,
																				],
																			],
																			[
																				204.4,
																				442.2,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_108",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					122.7,
																					609.8,
																				],
																				[
																					-13.3,
																					-7,
																				],
																				[
																					8.6,
																					4.5,
																				],
																			],
																			[
																				[
																					156,
																					598.1,
																				],
																				[
																					-18.2,
																					23.4,
																				],
																				[
																					18.2,
																					-23.4,
																				],
																			],
																			[
																				[
																					184.8,
																					541.8,
																				],
																				[
																					-5.1,
																					10.8,
																				],
																				[
																					5.2,
																					-10.8,
																				],
																			],
																			[
																				[
																					204,
																					520.4,
																				],
																				[
																					-9.7,
																					4.3,
																				],
																				[
																					9.7,
																					-4.3,
																				],
																			],
																			[
																				[
																					211.7,
																					504.6,
																				],
																				[
																					1.3,
																					3.6,
																				],
																				[
																					-1.4,
																					-3.6,
																				],
																			],
																			[
																				[
																					183.3,
																					495.4,
																				],
																				[
																					23.4,
																					-2.1,
																				],
																				[
																					-8.1,
																					-10.6,
																				],
																			],
																			[
																				[
																					154,
																					486.2,
																				],
																				[
																					6.8,
																					-8.6,
																				],
																				[
																					-6.8,
																					8.6,
																				],
																			],
																			[
																				[
																					145.9,
																					506.3,
																				],
																				[
																					0.2,
																					-2.1,
																				],
																				[
																					-2,
																					2,
																				],
																			],
																			[
																				[
																					124.5,
																					552.8,
																				],
																				[
																					2.7,
																					-11.7,
																				],
																				[
																					-2.7,
																					11.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				191.4,
																				529.3,
																			],
																			[
																				[
																					179.4,
																					516.4,
																				],
																				[
																					3.3,
																					0.9,
																				],
																				[
																					9.3,
																					3.9,
																				],
																			],
																			[
																				[
																					195.9,
																					526,
																				],
																				[
																					-5.4,
																					0,
																				],
																				[
																					-3.6,
																					2.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				146.3,
																				503.1,
																			],
																			[
																				[
																					168.8,
																					510.8,
																				],
																				[
																					-15.5,
																					-2.1,
																				],
																				[
																					-16.4,
																					-0.1,
																				],
																			],
																			[
																				[
																					144.1,
																					509.9,
																				],
																				[
																					2.1,
																					-1.6,
																				],
																				[
																					1.6,
																					-4.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					181.3,
																					493.5,
																				],
																				[
																					0.8,
																					2.5,
																				],
																				[
																					0,
																					0,
																				],
																			],
																			[
																				183.2,
																				495.3,
																			],
																			[
																				186.5,
																				494.8,
																			],
																			[
																				[
																					180.8,
																					503,
																				],
																				[
																					0.9,
																					-4,
																				],
																				[
																					1,
																					-7.4,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					136.7,
																					559.8,
																				],
																				[
																					3.9,
																					10.3,
																				],
																				[
																					0,
																					0,
																				],
																			],
																			[
																				[
																					130.3,
																					573.7,
																				],
																				[
																					7.5,
																					-7.4,
																				],
																				[
																					15.8,
																					-2.4,
																				],
																			],
																			[
																				[
																					153.3,
																					576.3,
																				],
																				[
																					-2.2,
																					-3.2,
																				],
																				[
																					0,
																					-3.9,
																				],
																			],
																			[
																				155,
																				571.8,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_109",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					134.2,
																					643.2,
																				],
																				[
																					-0.8,
																					-5,
																				],
																				[
																					1,
																					6.6,
																				],
																			],
																			[
																				[
																					154.1,
																					653.2,
																				],
																				[
																					-5.2,
																					1.2,
																				],
																				[
																					5.3,
																					-1.2,
																				],
																			],
																			[
																				[
																					166.1,
																					649.5,
																				],
																				[
																					-5.6,
																					1.2,
																				],
																				[
																					5.6,
																					-1.2,
																				],
																			],
																			[
																				[
																					177.7,
																					636.1,
																				],
																				[
																					5,
																					6,
																				],
																				[
																					-3.2,
																					-6.2,
																				],
																			],
																			[
																				[
																					160.1,
																					631.2,
																				],
																				[
																					3.6,
																					1.6,
																				],
																				[
																					-3.6,
																					-1.5,
																				],
																			],
																			[
																				[
																					147.9,
																					632.4,
																				],
																				[
																					2.4,
																					-1.7,
																				],
																				[
																					-2.4,
																					1.7,
																				],
																			],
																			[
																				[
																					139.3,
																					636.4,
																				],
																				[
																					2.7,
																					-1.1,
																				],
																				[
																					-2.7,
																					1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
												],
											},
										],
										[
											"Group",
											{
												"name": "secondQuadrant",
												"applyMatrix": true,
												"children": [
													[
														"Group",
														{
															"name": "feline_occlusal_upper_209",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					660.5,
																					643.2,
																				],
																				[
																					0.8,
																					-5,
																				],
																				[
																					-1,
																					6.6,
																				],
																			],
																			[
																				[
																					640.6,
																					653.2,
																				],
																				[
																					5.2,
																					1.2,
																				],
																				[
																					-5.3,
																					-1.2,
																				],
																			],
																			[
																				[
																					628.6,
																					649.5,
																				],
																				[
																					5.6,
																					1.2,
																				],
																				[
																					-5.6,
																					-1.3,
																				],
																			],
																			[
																				[
																					617,
																					636,
																				],
																				[
																					-5,
																					6,
																				],
																				[
																					3.2,
																					-6.2,
																				],
																			],
																			[
																				[
																					634.6,
																					631.1,
																				],
																				[
																					-3.6,
																					1.6,
																				],
																				[
																					3.6,
																					-1.5,
																				],
																			],
																			[
																				[
																					646.8,
																					632.3,
																				],
																				[
																					-2.4,
																					-1.7,
																				],
																				[
																					2.4,
																					1.7,
																				],
																			],
																			[
																				[
																					655.4,
																					636.3,
																				],
																				[
																					-2.7,
																					-1.1,
																				],
																				[
																					2.7,
																					1.1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_208",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					672.1,
																					609.8,
																				],
																				[
																					13.3,
																					-7,
																				],
																				[
																					-8.6,
																					4.5,
																				],
																			],
																			[
																				[
																					638.8,
																					598.1,
																				],
																				[
																					18.2,
																					23.4,
																				],
																				[
																					-18.2,
																					-23.4,
																				],
																			],
																			[
																				[
																					610,
																					541.8,
																				],
																				[
																					5.1,
																					10.8,
																				],
																				[
																					-5.2,
																					-10.8,
																				],
																			],
																			[
																				[
																					590.8,
																					520.4,
																				],
																				[
																					9.7,
																					4.3,
																				],
																				[
																					-9.7,
																					-4.3,
																				],
																			],
																			[
																				[
																					583.1,
																					504.6,
																				],
																				[
																					-1.3,
																					3.6,
																				],
																				[
																					1.4,
																					-3.6,
																				],
																			],
																			[
																				[
																					611.5,
																					495.4,
																				],
																				[
																					-23.4,
																					-2.1,
																				],
																				[
																					8.1,
																					-10.6,
																				],
																			],
																			[
																				[
																					640.8,
																					486.2,
																				],
																				[
																					-6.8,
																					-8.6,
																				],
																				[
																					6.8,
																					8.6,
																				],
																			],
																			[
																				[
																					648.9,
																					506.3,
																				],
																				[
																					-0.2,
																					-2.1,
																				],
																				[
																					2,
																					2,
																				],
																			],
																			[
																				[
																					670.3,
																					552.8,
																				],
																				[
																					-2.7,
																					-11.7,
																				],
																				[
																					2.7,
																					11.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				603.3,
																				529.3,
																			],
																			[
																				[
																					615.3,
																					516.4,
																				],
																				[
																					-3.3,
																					0.9,
																				],
																				[
																					-9.3,
																					3.9,
																				],
																			],
																			[
																				[
																					598.8,
																					526,
																				],
																				[
																					5.4,
																					0,
																				],
																				[
																					3.6,
																					2.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				648.4,
																				503.1,
																			],
																			[
																				[
																					625.9,
																					510.8,
																				],
																				[
																					15.5,
																					-2.1,
																				],
																				[
																					16.4,
																					-0.1,
																				],
																			],
																			[
																				[
																					650.6,
																					509.9,
																				],
																				[
																					-2.1,
																					-1.6,
																				],
																				[
																					-1.6,
																					-4.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					613.4,
																					493.5,
																				],
																				[
																					-0.8,
																					2.5,
																				],
																				[
																					0,
																					0,
																				],
																			],
																			[
																				611.5,
																				495.3,
																			],
																			[
																				608.2,
																				494.8,
																			],
																			[
																				[
																					613.9,
																					503,
																				],
																				[
																					-0.9,
																					-4,
																				],
																				[
																					-1,
																					-7.4,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					658.1,
																					559.8,
																				],
																				[
																					-4,
																					10.3,
																				],
																				[
																					0,
																					0,
																				],
																			],
																			[
																				[
																					664.5,
																					573.7,
																				],
																				[
																					-7.5,
																					-7.4,
																				],
																				[
																					-15.8,
																					-2.4,
																				],
																			],
																			[
																				[
																					641.5,
																					576.3,
																				],
																				[
																					2.2,
																					-3.2,
																				],
																				[
																					0,
																					-3.9,
																				],
																			],
																			[
																				639.8,
																				571.8,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_207",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					629.7,
																					467.4,
																				],
																				[
																					1.4,
																					-9.5,
																				],
																				[
																					-0.9,
																					6.2,
																				],
																			],
																			[
																				[
																					604.9,
																					478.9,
																				],
																				[
																					4.5,
																					4,
																				],
																				[
																					-4.5,
																					-4.1,
																				],
																			],
																			[
																				[
																					597,
																					467.9,
																				],
																				[
																					2.5,
																					1.8,
																				],
																				[
																					-2.5,
																					-1.8,
																				],
																			],
																			[
																				[
																					565.2,
																					430.1,
																				],
																				[
																					1.4,
																					10.3,
																				],
																				[
																					-1.4,
																					-10.4,
																				],
																			],
																			[
																				[
																					565,
																					397,
																				],
																				[
																					0,
																					2.2,
																				],
																				[
																					0,
																					-2.2,
																				],
																			],
																			[
																				[
																					564.3,
																					385.5,
																				],
																				[
																					1.4,
																					4.5,
																				],
																				[
																					-1.4,
																					-4.5,
																				],
																			],
																			[
																				[
																					584.6,
																					371.5,
																				],
																				[
																					-9.7,
																					-1.3,
																				],
																				[
																					9.7,
																					1.4,
																				],
																			],
																			[
																				[
																					606,
																					390,
																				],
																				[
																					-2.7,
																					-9.9,
																				],
																				[
																					2.7,
																					9.9,
																				],
																			],
																			[
																				[
																					619.5,
																					424.9,
																				],
																				[
																					-4.3,
																					-7.2,
																				],
																				[
																					4.4,
																					7.1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				564.8,
																				389.4,
																			],
																			[
																				[
																					580.3,
																					393.7,
																				],
																				[
																					-6.5,
																					3.4,
																				],
																				[
																					-10.1,
																					3.8,
																				],
																			],
																			[
																				564.8,
																				392.8,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				608.5,
																				395.7,
																			],
																			[
																				[
																					590.5,
																					391.9,
																				],
																				[
																					4.5,
																					-1.8,
																				],
																				[
																					11.5,
																					3.6,
																				],
																			],
																			[
																				[
																					609.9,
																					400,
																				],
																				[
																					-1.2,
																					-2.5,
																				],
																				[
																					1.1,
																					2.5,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					608.8,
																					435.9,
																				],
																				[
																					-7.2,
																					5.4,
																				],
																				[
																					0,
																					0,
																				],
																			],
																			[
																				611.3,
																				441.5,
																			],
																			[
																				[
																					593.3,
																					446.5,
																				],
																				[
																					6.5,
																					-10,
																				],
																				[
																					-2.9,
																					-4.3,
																				],
																			],
																			[
																				590.4,
																				442.2,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_206",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				577.2,
																				336.9,
																			],
																			[
																				[
																					586.2,
																					322.5,
																				],
																				[
																					4.3,
																					10.8,
																				],
																				[
																					0,
																					-11.5,
																				],
																			],
																			[
																				[
																					569.5,
																					304.5,
																				],
																				[
																					7.9,
																					0,
																				],
																				[
																					-7.9,
																					0,
																				],
																			],
																			[
																				[
																					546.5,
																					316,
																				],
																				[
																					0.9,
																					-8.8,
																				],
																				[
																					-0.9,
																					8.8,
																				],
																			],
																			[
																				[
																					557.5,
																					335.8,
																				],
																				[
																					-6.3,
																					-4.3,
																				],
																				[
																					6.4,
																					4.3,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				559.2,
																				306.1,
																			],
																			[
																				[
																					556.7,
																					316.7,
																				],
																				[
																					-1.3,
																					-3.2,
																				],
																				[
																					-3.4,
																					-6.8,
																				],
																			],
																			[
																				[
																					555.1,
																					307.5,
																				],
																				[
																					-0.7,
																					0.9,
																				],
																				[
																					0.7,
																					-1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				571.4,
																				337.4,
																			],
																			[
																				[
																					561.5,
																					330.9,
																				],
																				[
																					1.6,
																					3.6,
																				],
																				[
																					1.4,
																					5.9,
																				],
																			],
																			[
																				565.8,
																				338.8,
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_204",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					601.3,
																					201.8,
																				],
																				[
																					-0.2,
																					4.9,
																				],
																				[
																					1.5,
																					-33.3,
																				],
																			],
																			[
																				[
																					597.2,
																					138.7,
																				],
																				[
																					2.2,
																					6.8,
																				],
																				[
																					-2.3,
																					-7.2,
																				],
																			],
																			[
																				[
																					574.9,
																					101.5,
																				],
																				[
																					6.5,
																					0.5,
																				],
																				[
																					-6.1,
																					-0.4,
																				],
																			],
																			[
																				[
																					564.2,
																					149.3,
																				],
																				[
																					1.9,
																					-9.1,
																				],
																				[
																					-2.5,
																					12,
																				],
																			],
																			[
																				[
																					549.9,
																					206.5,
																				],
																				[
																					7.3,
																					-18.2,
																				],
																				[
																					-7.2,
																					18,
																				],
																			],
																			[
																				[
																					567,
																					258.1,
																				],
																				[
																					-29.9,
																					1.6,
																				],
																				[
																					23.4,
																					-1.3,
																				],
																			],
																			[
																				[
																					595.8,
																					227.5,
																				],
																				[
																					-1.5,
																					6,
																				],
																				[
																					1.7,
																					-6.1,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_203",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				[
																					517,
																					137.1,
																				],
																				[
																					3,
																					-6.3,
																				],
																				[
																					0,
																					0,
																				],
																			],
																			[
																				514.5,
																				146.8,
																			],
																			[
																				[
																					501,
																					154.9,
																				],
																				[
																					12.8,
																					1.1,
																				],
																				[
																					-12.8,
																					-1.1,
																				],
																			],
																			[
																				[
																					485.7,
																					149.3,
																				],
																				[
																					4.3,
																					5.8,
																				],
																				[
																					-4.3,
																					-5.9,
																				],
																			],
																			[
																				[
																					482.3,
																					136.2,
																				],
																				[
																					-2,
																					6.6,
																				],
																				[
																					2,
																					-6.5,
																				],
																			],
																			[
																				[
																					485.2,
																					126.7,
																				],
																				[
																					-1.3,
																					3.9,
																				],
																				[
																					1.4,
																					-3.8,
																				],
																			],
																			[
																				[
																					497.4,
																					118.6,
																				],
																				[
																					-8.4,
																					-1.1,
																				],
																				[
																					8.3,
																					1.1,
																				],
																			],
																			[
																				[
																					514.1,
																					125.6,
																				],
																				[
																					-3,
																					-2.9,
																				],
																				[
																					2.9,
																					2.9,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
													[
														"Group",
														{
															"name": "feline_occlusal_upper_202",
															"applyMatrix": true,
															"children": [
																[
																	"Path",
																	{
																		"applyMatrix": false,
																		"segments": [
																			[
																				467,
																				144.1,
																			],
																			[
																				[
																					473.8,
																					116.2,
																				],
																				[
																					2.9,
																					9.7,
																				],
																				[
																					-19.6,
																					-2.9,
																				],
																			],
																			[
																				[
																					445.2,
																					115.5,
																				],
																				[
																					5.2,
																					-3.4,
																				],
																				[
																					-5.2,
																					3.4,
																				],
																			],
																			[
																				[
																					445,
																					131.7,
																				],
																				[
																					-2.1,
																					-4.9,
																				],
																				[
																					2,
																					5,
																				],
																			],
																			[
																				[
																					459,
																					145.7,
																				],
																				[
																					-7,
																					0.6,
																				],
																				[
																					6.9,
																					-0.7,
																				],
																			],
																		],
																		"closed": true,
																		"fillColor": [
																			1,
																			1,
																			1,
																		],
																		"strokeColor": [
																			0.13725,
																			0.12157,
																			0.12549,
																		],
																	},
																],
															],
														},
													],
												],
											},
										],
									],
								},
							],
						],
					},
				],
			],
		},
	],
]
export const felineBoneConfig = {
	scaling: [ 1.37, 1.37 ],
	position: [ 550, 1100 ],
	opacity: 0.6,
};