import React, { useEffect } from 'react';
import UsersTable from './UsersTable';
import UserDeleteModal from './UserDeleteModal';
import UserInviteModal from './UserInviteModal';
import { actions } from './UserManagement.redux';
import { useDispatch } from "react-redux";
import DashboardCard from "components/DashboardCard";
import { Add, Person } from "@mui/icons-material";
import { Button } from "@mui/material";



const UserManagement = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(actions.loadUsers());

		return () => dispatch(actions.resetAll());
	}, [ dispatch ]);

	return (
		<div>
			<DashboardCard
				color={'orange'}
				icon={<Person />}
				topContent={"Users"}
				topLabel={<div>
					<div>
						<Button
							variant="contained"
							color="primary"
							size="small"
							startIcon={<Add />}
							onClick={() => dispatch(actions.setState({ showInviteModal: true }))}
						>
							Add User
						</Button>
					</div>
				</div>}
				size="xl"
			>
				<UsersTable />
			</DashboardCard>

			<UserDeleteModal />
			<UserInviteModal />
		</div>
	);
};


export default UserManagement;
