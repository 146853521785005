export default {
	"t110": {
		"paths": [
			"M33.5,204c-2.4,0.8-4.4,0.9-6.4,0.8l4.1-7.9l10.7-0.6l2,7.9C40.3,203.9,36.8,202.9,33.5,204z",
			"M33.5,204c-4.8,1.6-10.2,0.3-15-0.9c-0.6-0.2-1.9-1.6-2.4-2.1c-0.8-0.7-1.5-1.6-1.9-2.6c-0.8-2,0-4.2,1-6      c2.6-4.5,12-7.2,17.5-17.2c3.3-3,7.4,10.2,10.7,10.9c2.5,0.5,4.6,2.9,5.9,4.9c1.2,1.8,1.9,3.8,2.1,6c0.2,1.5,0.6,4.7-0.5,5.9      c-1.8,2-6.2,1.3-8.4,1C39.5,203.6,36.4,203,33.5,204z"
		]
	},
	"t109": {
		"paths": [
			"M98.6,204.6l2.1-6.6l19.3,4.8l1.3,4.6C113.7,206.4,106.5,204.2,98.6,204.6z",
			"M135.9,203.5c-1.4,0.8-2.8,1.5-4.2,2.3c-1.1,0.7-2.9,2.1-4.3,2.1c-12.3,0-22.6-5.6-35.8-1.9      c-2.2,0.5-4.4,0.2-6.5-0.3c-1-0.2-2.1-0.6-3.1-0.9c-1.3-0.4-1.5-0.4-1.9-1.6c-1.1-3.4-2.6-6.6-2.6-10.3c0-4.6,1.3-6.8,5.4-8.4      c1.1-0.4,1.9-6.6,2.1-7.6c1.1-6.3,2.7-9.8,9.6-9.7c5.9,0.1,11.2,4.3,15.5,7.9c5.8-6.6,15.4,2.7,21.4,6.6      c4.3,2.8,9.6,3.5,10.8,8.5c1.1,4.5-0.7,9.4-4.5,12.1C137.1,202.8,136.5,203.2,135.9,203.5z"
		]
	},
	"t108": {
		"paths": [
			"M219,205l9.4-3.7c0,0,2,0.6,4.6,2c-0.3,0.1-0.7,0.1-1,0.2C227.6,204.2,223.3,204.7,219,205z",
			"M280.1,198c-0.3-4.8-2.1-9.6-3.9-14c-4.5-10.6-50.5-46.4-55-46.2c-4.6,0.1-4,2.4-10.6,11.2      c-6.6,8.8-10.6,20.8-11.1,23c0-3.5-11.4-4.2-13.5-4.4c-11.1-1.2-19.6-0.1-22.8,12c-1.1,4.3-1.3,8.9-1.4,13.3      c-0.1,2.5-0.1,5.2,1.3,7.3c1.2,1.7,3.1,2.7,5.1,3.1c5.2,1,11.2,0.5,16.6,0.9c5.9,0.5,11.8,1.1,17.7,1.2c9.7,0.2,20,0.1,29.5-1.9      c7.6-1.6,15.4-0.3,22.9,1.3c3.9,0.8,7.7,1.8,11.6,2.5c3.2,0.6,8.1,2.5,10.7-0.1C279.5,204.8,280.3,201.1,280.1,198z"
		]
	},
	"t107": {
		"paths": [
			"M363.3,183.6c-1.5-3.5-3.6-6.9-6.1-9.9c-4.9-6.2-12.3-15.7-20.6-17.3c-4.2,0.3-6.2,6-7.6,9.2      c-2.2,5.2-4.3,10.2-7,15.3c-2.2-0.8-4.8-0.4-6.5,1.1c-1.8,1.5-2.6,4-2.1,6.3c-1.1-0.3-2.2,0.5-2.6,1.5s-0.4,2.1-0.4,3.2      c0.1,3.5,0.4,7.5,3.2,9.6c3.3,2.5,9.3,1.5,13.3,2c5.2,0.6,10.2,0.5,15.4,0.5c6.5,0,12.9-1.5,19.3-1.5c1.7,0,3.7-0.4,4.6-1.9      c0.5-0.9,0.5-1.9,0.5-2.9C366.5,193.5,365.4,188.3,363.3,183.6z"
		]
	},
	"t106": {
		"paths": [
			"M424.3,163.3c-6.6,0.2-9.2,15.5-11.5,20.5c-2.9,1.5-3,0.4-5.5,4.7c-0.4,0.7-3.6,3.4-4.2,4.3      c-0.8,1-1.2,2.3-1.4,3.5c-0.2,1.5,0,7.3,2.9,7.6c6.7,0.8,10.5-1.8,17.2-1.6c3.4,0.1,8.1,1.2,11.5,1.2c3.1,0,6.7-0.5,9.7,0.1      c2.4,0.5,4.3,2.3,6.7,0.9c1.7-1,2.7-2.9,3-4.8c0.3-1.9,0-3.9-0.4-5.8c-0.6-3-1.3-6-2.7-8.8c-2.5-4.8-8-8.3-12.1-11.6      C434.1,171,428.7,163.1,424.3,163.3z"
		]
	},
	"t105": {
		"paths": [
			"M519.2,197.8c-0.4,2-1.8,3.5-3.4,4.6c-2.9,2.1-6.8,2.5-10.1,1.3c-1.9-0.7-3.7-1.7-5.5-2.8      c-3.7-2.2-4.2-4.6-3.3-8.8c0.6-2.6,2-4.7,3.1-7.1c1.3-3,1.1-8,4.8-8.2c3.7-0.2,7.7,5.2,9.5,7.6      C516.1,186.9,520.1,193.4,519.2,197.8z"
		]
	},
	"t104": {
		"paths": [
			"M630.8,182.2c-0.4,5.9-2.6,11.3-4.3,16.9c-0.6,1.9-1.5,3.9-3.1,5c-2.3,1.5-5.8,2.2-8.4,3.1      c-3.5,1.2-7.1,2.3-10.7,3.3c-7,1.8-14.4,2.9-21.7,2.1c-5.4-0.6-11.5-2.9-15.6-6.6c-0.8-0.7-1.5-1.5-1.9-2.5      c-0.9-2.3,0.2-4.8,1.5-6.8c3.6-5.3,6.8-10.5,11-15.4c4.5-5.3,9.2-10.3,13.5-15.8c2-2.6,4.1-5.4,5.4-8.4      c8.4-18.2,11.7-31.9,11.5-37.8c-0.2-5.9,0.3-15,4.5-14.7c2.8,0.2,3.3,1.5,10.6,14C630.1,130.9,632.1,164.3,630.8,182.2z"
		]
	},
	"t103": {
		"paths": [
			"M714.7,194.2c0.2,7.3-4.2,12.8-12.7,11.6c-2.2-0.3-4.5-0.9-6.7-1.3c-4.7-1-10-2.1-13.1-6.8      c-0.9-1.3-1.4-2.9-1.2-4.5c0.3-2.4,1.4-9.2,2.7-11.2c1.5-2.4,1.8-8.7,1.8-11.7c-0.2-7.4-5.6-17.7,5-20.1c4.5-1,6.7,3.3,8.2,7.4      c1.9,5.1,4.8,10.3,7.7,15c2.6,4.1,3.5,7.8,5.8,12.2C713.7,188.1,714.6,191.3,714.7,194.2z"
		]
	},
	"t102": {
		"paths": [
			"M765.3,170.9c-2.4-0.6-4.8,0.8-6.6,5.5c-0.9,2.6-1.2,5.3-1.5,8c-2.6,4-2.7,9.3-1.4,13.7      c1.2,4.1,3.6,4.6,7.5,5.7c4.1,1.2,5.8,3.5,7.5-1.2c1.4-4,2.4-8.4,2.8-12.6C774.3,183.5,773.3,172.8,765.3,170.9z"
		]
	},
	"t101": {
		"paths": [
			"M841.3,182.6c-1-6-3.3-13.2-8-13.6c-11.2-1-8.9,26.4-7.7,32.2c0.1,0.7,0.3,1.4,0.8,1.9      c1.7,1.7,8.8,1.3,10.9,0.9c5.2-0.9,4.8-11.3,4.6-15.1C841.9,187.4,841.7,185.1,841.3,182.6z"
		]
	},
	"t210": {
		"paths": [
			"M1714,202.4c2.4,0.8,4.4,0.9,6.4,0.8l-4.1-7.9l-10.7-0.6l-2,7.9C1707.1,202.3,1710.7,201.3,1714,202.4z",
			"M1714,202.4c4.8,1.6,10.2,0.3,15-0.9c0.6-0.2,1.9-1.6,2.4-2.1c0.8-0.7,1.5-1.6,1.9-2.6c0.8-2,0-4.2-1-6    c-2.6-4.5-12-7.2-17.5-17.2c-3.3-3-7.4,10.2-10.7,10.9c-2.5,0.5-4.6,2.9-5.9,4.9c-1.2,1.8-1.9,3.8-2.1,6c-0.2,1.5-0.6,4.7,0.5,5.9    c1.8,2,6.2,1.3,8.4,1C1708,202.1,1711,201.5,1714,202.4z"
		]
	},
	"t209": {
		"paths": [
			"M1648.9,203.1l-2.1-6.6l-19.3,4.8l-1.3,4.6C1633.8,204.8,1641,202.7,1648.9,203.1z",
			"M1611.5,202c1.4,0.8,2.8,1.5,4.2,2.3c1.1,0.7,2.9,2.1,4.3,2.1c12.3,0,22.6-5.6,35.8-1.9    c2.2,0.5,4.4,0.2,6.5-0.3c1-0.2,2.1-0.6,3.1-0.9c1.3-0.4,1.5-0.4,1.9-1.6c1.1-3.4,2.6-6.6,2.6-10.3c0-4.6-1.3-6.8-5.4-8.4    c-1.1-0.4-1.9-6.6-2.1-7.6c-1.1-6.3-2.7-9.8-9.6-9.7c-5.9,0.1-11.2,4.3-15.5,7.9c-5.8-6.6-15.4,2.7-21.4,6.6    c-4.3,2.8-9.6,3.5-10.8,8.5c-1.1,4.5,0.7,9.4,4.5,12.1C1610.4,201.3,1610.9,201.6,1611.5,202z"
		]
	},
	"t208": {
		"paths": [
			"M1528.5,203.5l-9.4-3.7c0,0-2,0.6-4.6,2c0.3,0.1,0.7,0.1,1,0.2C1519.9,202.7,1524.2,203.2,1528.5,203.5z",
			"M1467.3,196.4c0.3-4.8,2.1-9.6,3.9-14c4.5-10.6,50.5-46.4,55-46.2c4.6,0.1,4,2.4,10.6,11.2    c6.6,8.8,10.6,20.8,11.1,23c0-3.5,11.4-4.2,13.5-4.4c11.1-1.2,19.6-0.1,22.8,12c1.1,4.3,1.3,8.9,1.4,13.3c0.1,2.5,0.1,5.2-1.3,7.3    c-1.2,1.7-3.1,2.7-5.1,3.1c-5.2,1-11.2,0.5-16.6,0.9c-5.9,0.5-11.8,1.1-17.7,1.2c-9.7,0.2-20,0.1-29.5-1.9    c-7.6-1.6-15.4-0.3-22.9,1.3c-3.9,0.8-7.7,1.8-11.6,2.5c-3.2,0.6-8.1,2.5-10.7-0.1C1468,203.3,1467.2,199.6,1467.3,196.4z"
		]
	},
	"t207": {
		"paths": [
			"M1384.1,182.1c1.5-3.5,3.6-6.9,6.1-9.9c4.9-6.2,12.3-15.7,20.6-17.3c4.2,0.3,6.2,6,7.6,9.2    c2.2,5.2,4.3,10.2,7,15.3c2.2-0.8,4.8-0.4,6.5,1.1c1.8,1.5,2.6,4,2.1,6.3c1.1-0.3,2.2,0.5,2.6,1.5c0.5,1,0.4,2.1,0.4,3.2    c-0.1,3.5-0.4,7.5-3.2,9.6c-3.3,2.5-9.3,1.5-13.3,2c-5.2,0.6-10.2,0.5-15.4,0.5c-6.5,0-12.9-1.5-19.3-1.5c-1.7,0-3.7-0.4-4.6-1.9    c-0.5-0.9-0.5-1.9-0.5-2.9C1381,192,1382.1,186.8,1384.1,182.1z"
		]
	},
	"t206": {
		"paths": [
			"M1323.2,161.7c6.6,0.2,9.2,15.5,11.5,20.5c2.9,1.5,3,0.4,5.5,4.7c0.4,0.7,3.6,3.4,4.2,4.3    c0.8,1,1.2,2.3,1.4,3.5c0.2,1.5,0,7.3-2.9,7.6c-6.7,0.8-10.5-1.8-17.2-1.6c-3.4,0.1-8.1,1.2-11.5,1.2c-3.1,0-6.7-0.5-9.7,0.1    c-2.4,0.5-4.3,2.3-6.7,0.9c-1.7-1-2.7-2.9-3-4.8s0-3.9,0.4-5.8c0.6-3,1.3-6,2.7-8.8c2.5-4.8,8-8.3,12.1-11.6    C1313.3,169.5,1318.7,161.6,1323.2,161.7z"
		]
	},
	"t205": {
		"paths": [
			"M1228.3,196.2c0.4,2,1.8,3.5,3.4,4.6c2.9,2.1,6.8,2.5,10.1,1.3c1.9-0.7,3.7-1.7,5.5-2.8    c3.7-2.2,4.2-4.6,3.3-8.8c-0.6-2.6-2-4.7-3.1-7.1c-1.3-3-1.1-8-4.8-8.2c-3.7-0.2-7.7,5.2-9.5,7.6    C1231.4,185.3,1227.4,191.8,1228.3,196.2z"
		]
	},
	"t204": {
		"paths": [
			"M1116.7,180.6c0.4,5.9,2.6,11.3,4.3,16.9c0.6,1.9,1.5,3.9,3.1,5c2.3,1.5,5.8,2.2,8.4,3.1    c3.5,1.2,7.1,2.3,10.7,3.3c7,1.8,14.4,2.9,21.7,2.1c5.4-0.6,11.5-2.9,15.6-6.6c0.8-0.7,1.6-1.5,1.9-2.5c0.9-2.3-0.2-4.8-1.5-6.8    c-3.6-5.3-6.8-10.5-11-15.4c-4.5-5.3-9.2-10.3-13.5-15.8c-2-2.6-4.1-5.4-5.4-8.4c-8.4-18.2-11.7-31.9-11.5-37.8    c0.2-5.9-0.3-15-4.5-14.7c-2.8,0.2-3.3,1.5-10.6,14C1117.4,129.3,1115.4,162.7,1116.7,180.6z"
		]
	},
	"t203": {
		"paths": [
			"M1032.8,192.7c-0.2,7.3,4.2,12.8,12.7,11.6c2.2-0.3,4.5-0.9,6.7-1.3c4.7-1,10-2.1,13.1-6.8    c0.9-1.3,1.4-2.9,1.2-4.5c-0.3-2.4-1.4-9.2-2.7-11.2c-1.5-2.4-1.8-8.7-1.8-11.7c0.2-7.4,5.6-17.7-5-20.1c-4.5-1-6.7,3.3-8.2,7.4    c-1.9,5.1-4.8,10.3-7.7,15c-2.6,4.1-3.5,7.8-5.8,12.2C1033.7,186.5,1032.9,189.8,1032.8,192.7z"
		]
	},
	"t202": {
		"paths": [
			"M982.2,169.4c2.4-0.6,4.8,0.8,6.6,5.5c0.9,2.6,1.2,5.3,1.5,8c2.6,4,2.7,9.3,1.4,13.7    c-1.2,4.1-3.6,4.6-7.5,5.7c-4.1,1.2-5.8,3.5-7.5-1.2c-1.4-4-2.4-8.4-2.8-12.6C973.2,182,974.2,171.3,982.2,169.4z"
		]
	},
	"t201": {
		"paths": [
			"M906.2,181.1c1-6,3.3-13.2,8-13.6c11.2-1,8.9,26.4,7.7,32.2c-0.1,0.7-0.3,1.4-0.8,1.9    c-1.7,1.7-8.8,1.3-10.9,0.9c-5.2-0.9-4.8-11.3-4.6-15.1C905.6,185.9,905.8,183.6,906.2,181.1z"
		]
	},
	"t411": {
		"paths": [
			"M42.3,262.6c-0.1-0.3-0.3-0.5-0.5-0.7c-0.8-1-2.1-1.5-3.4-1.9c-4.7-1.2-9.5-1.6-14.3-1.2      c-3.4,0.3-7.6,0.6-10.1,3.2c-2.5,2.5-5.7,7.7-2,9.7c10.3,5.5,12.4,16.9,17.5,11.9c6.6-6.5,12.2-5.7,12.7-10.2      C42.6,270.2,44.2,265.7,42.3,262.6z"
		]
	},
	"t410": {
		"paths": [
			"M106,260.4c-4.2-3.1-10.3-2.2-15.1-1.4c-6.4,1-12.6,2.5-19.1,2.6c-1.3,0-2.6,0-3.7,0.4      c-2.7,1-3.8,5.4-4.5,7.9c-0.6,2.4-0.6,4.8,0.4,7c1.5,3.5,8.5,3.3,11.8,1.5c3.3-1.8,5.7-2.7,7.7,0.1s12.6,9.2,14.9,8.7      c2.2-0.5,11-7.5,13.6-11.8c-0.4-4.7-1.9-11.9-5.8-14.9C106.1,260.5,106.1,260.5,106,260.4z"
		]
	},
	"t409": {
		"paths": [
			"M250.2,266.6c-0.4-2.6-1.7-5-4.6-6.6c-1.1-0.6-2.2-1-3.4-1.4c-0.9-0.3-1.9-0.6-2.8-0.9      c-14.3-4.3-29.8,2.1-44.4,1.2c-10.3-1.4-20.7-0.4-31,0.8c-10,1.1-20,1.6-30.1,2.1c-1.1,0.1-2.2,0.1-3.2,0.5      c-2,0.8-4.6,4.2-5.3,6.2c-1,3,0.4,6.2,1.6,8.9c3.5,8.3,8,14.4,15.7,9.9c2.3-1.3,5.1-0.9,7.7-1.1c2.8-0.2,6-0.5,8.8-0.1      c1.9,0.3,2.8,0.7,4.4,1.7c2.6,1.5,3.8,3.2,5.5,5.6c2,2.7,3.8,5.6,5.2,8.7c2.1,4.7,3.6,9.5,5.1,14.4c2.5,8.4,8.9,9.3,14.5,5.7      c3.3-2.1,6.3-4.6,9.2-7.1c3.2-2.8,4.9-6.6,6.7-10.3c1.6-3.3,3.9-6.8,8.2-6.1c1.2,0.2,2.3,0.7,3.4,1.3c1.1,0.5,2.2,1.1,3.3,1.6      c1.1,0.5,2.1,0.5,3.3,0.7c2.3,0.4,4.7,1.4,7.1,1c2.1-0.3,4-1.7,5.3-3.4c2.5-3.2,3.7-7.3,5-11.1c1.5-4.2,2.8-8.4,3.8-12.7      C250,273,250.7,269.6,250.2,266.6z"
		]
	},
	"t408": {
		"paths": [
			"M337.2,263.4c-3-2.7-9-3.3-12.7-3.9c-5.3-0.9-10.6-1.1-16-1.1c-10.6,0.1-21.1,1.5-31.7,1.8      c-1.2,0-2.5,0.1-3.7,0.5c-2.3,0.8-5.4,4.4-6.1,6.8c-0.3,1-0.6,2.2-0.5,3.3c0.1,1,0.6,2.1,0.8,3.2c0.6,2.7,1.4,5.3,2.5,7.8      c1,2.1,2.3,4,4,5.5c1.7,1.5,3.9,2.5,6.2,2.5c0.4,0,1.1-0.6,1.6-0.6c0.7,0,1.3,0.2,1.9,0.5c0.6,0.4,1.1,0.9,1.6,1.3      c1.2,1.2,2.3,2.7,3.4,4.1c2.4,3.2,4.6,6.6,6.8,10c0.7,1.1,2.3,4.3,3.7,4.5c2.1,0.4,27.9-21.3,35.7-26.5      c4.7-3.1,7.4-14.2,2.8-19.3C337.5,263.7,337.4,263.6,337.2,263.4z"
		]
	},
	"t407": {
		"paths": [
			"M362.1,260.8c-2.8,1.5-5,4.1-3.7,7.3c1.4,3.4,8.2,17.5,9.5,17.5c1.3,0,3.2,0.5,5.5,1.6      c2.3,1.1,7.8,18.5,10.2,18.2c2.4-0.3,21.5-23.9,27.2-25.5c5.6-1.5,7.5-1.9,7.7-5c0.3-4-1.1-10.8-4.7-13.2      c-1.8-1.2-4-1.4-6.1-1.6c-13.3-1.3-27.9-4.1-41.1-0.8C365.3,259.5,363.6,260,362.1,260.8z"
		]
	},
	"t406": {
		"paths": [
			"M446.3,261.5c-1.6,1.4-2.5,4.3-2.7,6.2c-0.2,2.2,0.7,3.9,2.5,5.1c3.3,2.2,2.6-0.7,4.3,0.7      c1.7,1.5,1.9,3.1,3.5,3.1c1.7,0,10.5,10.4,12.4,17.2c1.9,6.8,6.5,9.1,8,9.1c1.5,0,23.1-25.2,24.4-26.3c1.1-0.9,3-1,3.9-2.1      c1.6-2.1,0.5-6.9-0.3-9.2c-0.3-0.9-0.7-1.8-1.3-2.5c-1.6-1.9-4.4-2.2-6.9-2.1c-6.1,0.1-12.1-1.2-18.2-1.5      c-8.6-0.5-16.7,0.1-25.2,1.1c-1.3,0.2-2.7,0.3-3.8,0.9C446.7,261.2,446.5,261.3,446.3,261.5z"
		]
	},
	"t405": {
		"paths": [
			"M532.3,260.4c-3,1.5-6.3,4.1-5.3,7.1c1.1,3.4,4.1,6.2,5.5,10.2c1.3,4,2.8,5.5,3.6,5.5s13.7-6.1,15-9.8      c0.8-2.3,0.7-5.7,0.3-8c-0.4-2.3-2.1-3.8-4.2-5.3c-1.1-0.8-2.5-0.9-3.9-1c-2.6-0.2-5.3-0.3-7.9,0.2      C534.5,259.5,533.4,259.9,532.3,260.4z"
		]
	},
	"t404": {
		"paths": [
			"M655.4,259.3c-1.4-1.4-3.3-2.4-5.2-3.1c-3.6-1.3-7.6-2.2-11.2-3.1c-4.1-1.1-8.2-1.8-12.4-2.1      c-8-0.6-15,1.8-22,5.4c-2.2,1.1-4.3,2.9-4.6,5.4c-0.2,1.8,0.5,3.5,1.3,5.1c2.6,4.8,4.3,10.5,9.5,13.1      c11.5,8.9,22.8,25.9,25.2,42.6c3,20.4,0.5,28.3,7.3,27.1c10.9-1.9,19.2-37.6,19.4-40.4c0.3-4.7,1.5-9.1,1.5-13.9      c-0.1-6.4-0.9-13.2-2.8-19.4c-1.4-4.8-1.8-11.1-4.9-15.2C656.2,260.1,655.8,259.7,655.4,259.3z"
		]
	},
	"t403": {
		"paths": [
			"M710.3,278.7c0.2-3.3,0-6.6-0.5-9.8c-0.5-3-1.1-6.1-3.3-8.3c-0.3-0.3-0.5-0.5-0.8-0.8      c-2.2-1.8-4.9-2.7-7.7-3.1c-2.9-0.4-5.9-0.4-8.6,0.9c-5.1,2.3-5.9,7.8-5.3,12.7c0.6,5.1,2.1,10.2,4.4,14.8      c1.4,2.8,2.7,5.7,4.6,8.1c2.4,3.1,5.4,3.8,9.4,3.7c1.6,0,3.2-0.3,4.3-1.4c1-1,1.4-2.5,1.7-3.9      C709.4,287.5,710.1,283.1,710.3,278.7z"
		]
	},
	"t402": {
		"paths": [
			"M772.3,263.1c-1.7-2.7-4.8-5.4-7.8-5.6c-3.9-0.3-11.6,2.3-13.8,5.9c-2.2,3.5-0.2,9.5,0.6,13.2      c1.2,4.9,2.8,9.7,4.8,14.4c1.2,2.7,2.8,6,6,6.4c3,0.4,9.1-1.7,9.9-4.9c0.6-2.2,0.3-5.1,0.6-7.4c0.4-2.7-0.3-7.1,0.1-9.8      c0.6-4.2,2-6.8,0.3-10.7C772.8,264,772.6,263.5,772.3,263.1z"
		]
	},
	"t401": {
		"paths": [
			"M838.7,261.5c-0.4-0.8-1.1-1.9-1.8-2.5c-1.1-0.9-2-1.6-3.4-1.6c-4.6,0.1-8.5,1.1-10.9,5.9      c-1.1,2.1-0.7,5.1-0.7,7.4c0,2.8,0.3,5.7,1,8.5c2.8,11.8,5.5,15.4,16.1,13.1c3.3-0.7,5.6-6.3,6-9.7c0.3-3.1-2.2-9.1-2.8-12.3      C841.6,267.4,839.9,264.2,838.7,261.5z"
		]
	},
	"t311": {
		"paths": [
			"M1705.1,261.1c0.1-0.3,0.3-0.5,0.5-0.7c0.8-1,2.1-1.5,3.4-1.9c4.7-1.2,9.5-1.6,14.3-1.2    c3.4,0.3,7.6,0.6,10.1,3.2c2.5,2.5,5.7,7.7,2,9.7c-10.3,5.5-12.4,16.9-17.5,11.9c-6.6-6.5-12.2-5.7-12.7-10.2    C1704.9,268.7,1703.3,264.2,1705.1,261.1z"
		]
	},
	"t310": {
		"paths": [
			"M1641.5,258.9c4.2-3.1,10.3-2.2,15.1-1.4c6.4,1,12.6,2.5,19.1,2.6c1.3,0,2.6,0,3.7,0.4c2.7,1,3.8,5.4,4.5,7.9    c0.6,2.4,0.6,4.8-0.4,7c-1.5,3.5-8.5,3.3-11.8,1.5s-5.7-2.7-7.7,0.1s-12.6,9.2-14.9,8.7c-2.2-0.5-11-7.5-13.6-11.8    c0.4-4.7,1.9-11.9,5.8-14.9C1641.3,259,1641.4,258.9,1641.5,258.9z"
		]
	},
	"t309": {
		"paths": [
			"M1497.3,265.1c0.4-2.6,1.7-5,4.6-6.6c1.1-0.6,2.2-1,3.4-1.4c0.9-0.3,1.9-0.6,2.8-0.9    c14.3-4.3,29.8,2.1,44.4,1.2c10.3-1.4,20.7-0.4,31,0.8c10,1.1,20,1.6,30.1,2.1c1.1,0.1,2.2,0.1,3.2,0.5c2,0.8,4.6,4.2,5.3,6.2    c1,3-0.4,6.2-1.6,8.9c-3.5,8.3-8,14.4-15.7,9.9c-2.3-1.3-5.1-0.9-7.7-1.1c-2.8-0.2-6-0.5-8.8-0.1c-1.9,0.3-2.8,0.7-4.4,1.7    c-2.6,1.5-3.8,3.2-5.5,5.6c-2,2.7-3.8,5.6-5.2,8.7c-2.1,4.7-3.6,9.5-5.1,14.4c-2.5,8.4-8.9,9.3-14.5,5.7c-3.3-2.1-6.3-4.6-9.2-7.1    c-3.2-2.8-4.9-6.6-6.7-10.3c-1.6-3.3-3.9-6.8-8.2-6.1c-1.2,0.2-2.3,0.7-3.4,1.3c-1.1,0.5-2.2,1.1-3.3,1.6    c-1.1,0.5-2.1,0.5-3.3,0.7c-2.3,0.4-4.7,1.4-7.1,1c-2.1-0.3-4-1.7-5.3-3.4c-2.5-3.2-3.7-7.3-5-11.1c-1.5-4.2-2.8-8.4-3.8-12.7    C1497.4,271.5,1496.8,268.1,1497.3,265.1z"
		]
	},
	"t308": {
		"paths": [
			"M1410.2,261.9c3-2.7,9-3.3,12.7-3.9c5.3-0.9,10.6-1.1,16-1.1c10.6,0.1,21.1,1.5,31.7,1.8    c1.2,0,2.5,0.1,3.7,0.5c2.3,0.8,5.4,4.4,6.1,6.8c0.3,1,0.6,2.2,0.5,3.3c-0.1,1-0.6,2.1-0.8,3.2c-0.6,2.7-1.4,5.3-2.5,7.8    c-1,2.1-2.3,4-4,5.5c-1.7,1.5-3.9,2.5-6.2,2.5c-0.4,0-1.1-0.6-1.6-0.6c-0.7,0-1.3,0.2-1.9,0.5c-0.6,0.4-1.1,0.9-1.6,1.3    c-1.2,1.2-2.3,2.7-3.4,4.1c-2.4,3.2-4.6,6.6-6.8,10c-0.7,1.1-2.3,4.3-3.7,4.5c-2.1,0.4-27.9-21.3-35.7-26.5    c-4.7-3.1-7.4-14.2-2.8-19.3C1409.9,262.2,1410.1,262,1410.2,261.9z"
		]
	},
	"t307": {
		"paths": [
			"M1385.4,259.3c2.8,1.5,5,4.1,3.7,7.3c-1.4,3.4-8.2,17.5-9.5,17.5s-3.2,0.5-5.5,1.6    c-2.3,1.1-7.8,18.5-10.2,18.2c-2.4-0.3-21.5-23.9-27.2-25.5c-5.6-1.5-7.5-1.9-7.7-5c-0.3-4,1.1-10.8,4.7-13.2    c1.8-1.2,4-1.4,6.1-1.6c13.3-1.3,27.9-4.1,41.1-0.8C1382.2,257.9,1383.9,258.5,1385.4,259.3z"
		]
	},
	"t306": {
		"paths": [
			"M1301.2,260c1.6,1.4,2.5,4.3,2.7,6.2c0.2,2.2-0.7,3.9-2.5,5.1c-3.3,2.2-2.6-0.7-4.3,0.7    c-1.7,1.5-1.9,3.1-3.5,3.1c-1.7,0-10.5,10.4-12.4,17.2c-1.9,6.8-6.5,9.1-8,9.1c-1.5,0-23.1-25.2-24.4-26.3c-1.1-0.9-3-1-3.9-2.1    c-1.6-2.1-0.5-6.9,0.3-9.2c0.3-0.9,0.7-1.8,1.3-2.5c1.6-1.9,4.4-2.2,6.9-2.1c6.1,0.1,12.1-1.2,18.2-1.5c8.6-0.5,16.7,0.1,25.2,1.1    c1.3,0.2,2.7,0.3,3.8,0.9C1300.8,259.6,1301,259.8,1301.2,260z"
		]
	},
	"t305": {
		"paths": [
			"M1215.2,258.9c3,1.5,6.3,4.1,5.3,7.1c-1.1,3.4-4.1,6.2-5.5,10.2c-1.3,4-2.8,5.5-3.6,5.5    c-0.8,0-13.7-6.1-15-9.8c-0.8-2.3-0.7-5.7-0.3-8c0.4-2.3,2.1-3.8,4.2-5.3c1.1-0.8,2.5-0.9,3.9-1c2.6-0.2,5.3-0.3,7.9,0.2    C1213,258,1214,258.3,1215.2,258.9z"
		]
	},
	"t304": {
		"paths": [
			"M1092.1,257.8c1.4-1.4,3.3-2.4,5.2-3.1c3.6-1.3,7.6-2.2,11.2-3.1c4.1-1.1,8.2-1.8,12.4-2.1    c8-0.6,15,1.8,22,5.4c2.2,1.1,4.3,2.9,4.6,5.4c0.2,1.8-0.5,3.5-1.3,5.1c-2.6,4.8-4.3,10.5-9.5,13.1c-11.5,8.9-22.8,25.9-25.2,42.6    c-3,20.4-0.5,28.3-7.3,27.1c-10.9-1.9-19.2-37.6-19.4-40.4c-0.3-4.7-1.5-9.1-1.5-13.9c0.1-6.4,0.9-13.2,2.8-19.4    c1.4-4.8,1.8-11.1,4.9-15.2C1091.3,258.6,1091.7,258.2,1092.1,257.8z"
		]
	},
	"t303": {
		"paths": [
			"M1037.2,277.2c-0.2-3.3,0-6.6,0.5-9.8c0.5-3,1.1-6.1,3.3-8.3c0.3-0.3,0.5-0.5,0.8-0.8    c2.2-1.8,4.9-2.7,7.7-3.1c2.9-0.4,5.9-0.4,8.6,0.9c5.1,2.3,5.9,7.8,5.3,12.7c-0.6,5.1-2.1,10.2-4.4,14.8c-1.4,2.8-2.7,5.7-4.6,8.1    c-2.4,3.1-5.4,3.8-9.4,3.7c-1.6,0-3.2-0.3-4.3-1.4c-1-1-1.4-2.5-1.7-3.9C1038.1,286,1037.4,281.6,1037.2,277.2z"
		]
	},
	"t302": {
		"paths": [
			"M975.2,261.6c1.7-2.7,4.8-5.4,7.8-5.6c3.9-0.3,11.6,2.3,13.8,5.9c2.2,3.5,0.2,9.5-0.6,13.2    c-1.2,4.9-2.8,9.7-4.8,14.4c-1.2,2.7-2.8,6-6,6.4c-3,0.4-9.1-1.7-9.9-4.9c-0.6-2.2-0.3-5.1-0.6-7.4c-0.4-2.7,0.3-7.1-0.1-9.8    c-0.6-4.2-2-6.8-0.3-10.7C974.7,262.5,974.9,262,975.2,261.6z"
		]
	},
	"t301": {
		"paths": [
			"M908.8,260c0.4-0.8,1.1-1.9,1.8-2.5c1.1-0.9,2-1.6,3.4-1.6c4.6,0.1,8.5,1.1,10.9,5.9c1.1,2.1,0.7,5.1,0.7,7.4    c0,2.8-0.3,5.7-1,8.5c-2.8,11.8-5.5,15.4-16.1,13.1c-3.3-0.7-5.6-6.3-6-9.7c-0.3-3.1,2.2-9.1,2.8-12.3    C905.8,265.9,907.6,262.6,908.8,260z"
		]
	}
};