import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
	Grid,
	Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';




const styles = theme => ({
	input: {
		maxWidth: 60,
		border: `1px ${theme.palette.grey[400]} solid`,
		borderRadius: 4,
		height: 16,
		padding: 4,
	},
	textarea: {
		width: 'calc(100% - 16px)',
		border: `1px ${theme.palette.grey[400]} solid`,
		borderRadius: 4,
		height: 16,
		padding: 4,
		minHeight: 48,
		resize: 'none',
	},
});




class RootCanalOne extends PureComponent {

	update = (col, key) => e => {
		const data = [...this.props.data.data];
		const orig = [...this.props.data.data];
		data[col] = [...data[col]];
		orig[col] = [...data[col]];
		data[col][key] = e.target.value;

		if(col === 0 && (key === 3 || key === 4)) {
			if(orig[1][key] === orig[0][key]) {
				data[1][key] = e.target.value;
			}

			if(orig[2][key] === orig[0][key]) {
				data[2][key] = e.target.value;
			}
		}
		this.props.update({data: data});
	};


	updateRestoration = e => {
		this.props.update({restoration: e.target.value});
	};



	render() {
		const {classes} = this.props;
		const data = this.props.data.data;

		return (
			<Grid container spacing={16}>
				<Grid item xs={12}>
					<Grid container spacing={8}>
						<Grid item xs={3}>
							<Typography>WL (mm)</Typography>
						</Grid>
						<Grid item xs={3}>
							<input className={classes.input} onChange={this.update(0, 0)} value={data[0][0]} />
						</Grid>
					</Grid>
				</Grid>


				<Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<Typography>FAS (iso)</Typography>
						</Grid>
						<Grid item xs={3}>
							<input className={classes.input} onChange={this.update(0, 1)} value={data[0][1]} />
						</Grid>
					</Grid>
				</Grid>


				<Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<Typography>MC (iso)</Typography>
						</Grid>
						<Grid item xs={3}>
							<input className={classes.input} onChange={this.update(0, 2)} value={data[0][2]} />
						</Grid>
					</Grid>
				</Grid>


				<Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<Typography>Cement</Typography>
						</Grid>
						<Grid item xs={3}>
							<input className={classes.input} onChange={this.update(0, 3)} value={data[0][3]} />
						</Grid>
					</Grid>
				</Grid>


				<Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<Typography>Backfill</Typography>
						</Grid>
						<Grid item xs={3}>
							<input className={classes.input} onChange={this.update(0, 4)} value={data[0][4]}/>
						</Grid>
					</Grid>
				</Grid>


				<Grid item xs={12}>
					<Grid container spacing={0}>
						<Grid item xs={3}>
							<Typography>Restoration</Typography>
						</Grid>
						<Grid item xs={9}>
							<textarea rows="3" className={classes.textarea} value={this.props.data.restoration} onChange={this.updateRestoration} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}



RootCanalOne.propTypes = {
	data: PropTypes.object.isRequired,
	update: PropTypes.func.isRequired,
};

export default withStyles(styles)(RootCanalOne);