import React, { useState } from 'react';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Create, Gesture, Lens, LensOutlined, Stop, StopOutlined, TextFields } from "@mui/icons-material";
import useStyles from './ChartingMenu.styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import { actions } from '../Chart.redux';
import { SwatchesPicker } from 'react-color';



const MenuMarkup = () => {
    const classes               = useStyles(),
          [ anchor, setAnchor ] = useState(null),
          dispatch              = useDispatch(),
          selected              = useSelector(state => state.chart.toolbar.selectedButton === 'markup'),
          tool                  = useSelector(state => state.chart.toolbar.selectedTool),
          color                 = useSelector(state => state.chart.toolbar.color),
          lineSize              = useSelector(state => state.chart.toolbar.lineSize),
          validTools            = [
              'drawing',
              'text',
              'shapeRectangleOutlined',
              'shapeRectangleFilled',
              'shapeCircleOutlined',
              'shapeCircleFilled',
          ];



    const handleClose       = () => setAnchor(null),


          handleOpen        = (e) => {
              setAnchor(e.currentTarget);

              let shouldUpdate = false,
                  update       = {};

              if(!selected) {
                  shouldUpdate = true;
                  update.selectedButton = 'markup';
                  update.selectedTool = 'drawing';
              }

              if(selected && validTools.includes(tool)) {
                  shouldUpdate = false;
              }

              if(selected && !validTools.includes(tool)) {
                  shouldUpdate = true;
                  update.selectedTool = 'drawing';
              }

              if(shouldUpdate) {
                  dispatch(actions.setToolbar(update));
              }
          },


          toggleTool        = name => e => {
              dispatch(actions.setToolbar({
                  selectedTool: name,
                  selectedButton: 'markup',
              }));
              //setAnchor(null);
          },


          toolIcons         = {
              drawing: <Create />,
              text: <TextFields />,
              shapeRectangleOutlined: <StopOutlined />,
              shapeRectangleFilled: <Stop />,
              shapeCircleOutlined: <LensOutlined />,
              shapeCircleFilled: <Lens />,
          },


          setLineSize       = size => e => {
              dispatch(actions.setToolbar({
                  lineSize: size,
              }));
          },


          handleColorChange = (color, event) => {
              dispatch(actions.setToolbar({
                  color: color.hex,
              }));
          };


    return (
        <>
            <Button
                variant={selected
                    ? "contained"
                    : "outlined"}
                color={selected
                    ? "primary"
                    : undefined}
                style={selected
                    ? { background: color }
                    : undefined}
                className={clsx(classes.buttonMarginRight, classes.buttonFloat)}
                startIcon={
                    toolIcons[tool] !== undefined
                        ? toolIcons[tool]
                        : <Gesture />
                }
                onClick={handleOpen}
            >
                Markup
            </Button>
            <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={toggleTool('drawing')}
                    className={clsx({
                        [classes.activeItem]: tool === 'drawing',
                    })}
                >
                    <ListItemIcon><Create /></ListItemIcon>
                    <ListItemText>Draw</ListItemText>
                </MenuItem>


                <MenuItem
                    onClick={toggleTool('text')}
                    className={clsx({
                        [classes.activeItem]: tool === 'text',
                    })}
                >
                    <ListItemIcon><TextFields /></ListItemIcon>
                    <ListItemText>Text Note</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={toggleTool('shapeRectangleOutlined')}
                    className={clsx({
                        [classes.activeItem]: tool === 'shapeRectangleOutlined',
                    })}
                >
                    <ListItemIcon><StopOutlined /></ListItemIcon>
                    <ListItemText>Rectangle (Outlined)</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={toggleTool('shapeRectangleFilled')}
                    className={clsx({
                        [classes.activeItem]: tool === 'shapeRectangleFilled',
                    })}
                >
                    <ListItemIcon><Stop /></ListItemIcon>
                    <ListItemText>Rectangle (Filled)</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={toggleTool('shapeCircleOutlined')}
                    className={clsx({
                        [classes.activeItem]: tool === 'shapeCircleOutlined',
                    })}
                >
                    <ListItemIcon><LensOutlined /></ListItemIcon>
                    <ListItemText>Circle (Outlined)</ListItemText>
                </MenuItem>

                <MenuItem
                    onClick={toggleTool('shapeCircleFilled')}
                    className={clsx({
                        [classes.activeItem]: tool === 'shapeCircleFilled',
                    })}
                >
                    <ListItemIcon><Lens /></ListItemIcon>
                    <ListItemText>Circle (Filled)</ListItemText>
                </MenuItem>

                <Divider />

                <MenuItem
                    onClick={setLineSize(2)}
                    className={clsx({
                        [classes.nested]: true,
                        [classes.activeItem]: lineSize === 2,
                    })}
                >
                    <div className={classes.lineExtraThin} style={{ background: color }} />
                </MenuItem>

                <MenuItem
                    onClick={setLineSize(8)}
                    className={clsx({
                        [classes.nested]: true,
                        [classes.activeItem]: lineSize === 8,
                    })}
                >
                    <div className={classes.lineThin} style={{ background: color }} />
                </MenuItem>


                <MenuItem
                    onClick={setLineSize(16)}
                    className={clsx({
                        [classes.nested]: true,
                        [classes.activeItem]: lineSize === 16,
                    })}
                >
                    <div className={classes.lineMedium} style={{ background: color }} />
                </MenuItem>

                <MenuItem
                    onClick={setLineSize(24)}
                    className={clsx({
                        [classes.nested]: true,
                        [classes.activeItem]: lineSize === 24,
                    })}
                >
                    <div className={classes.lineThick} style={{ background: color }} />
                </MenuItem>

                <Divider />

                <SwatchesPicker
                    style={{ overflow: 'hidden' }}
                    color={color}
                    onChange={handleColorChange}
                    colors={[
                        [ "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3" ],
                        [ "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39" ],
                        [ "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b" ],
                    ]}
                    width={300}
                />

            </Menu>
        </>
    );
};



export default MenuMarkup;
