import React from 'react';
import { useDispatch } from "react-redux";
import { actions } from './FileManager.redux';
import FileGrid from './FileGrid';
import PaginationBar from './PaginationBar';
import FileBrowserSearch from "./FileBrowserSearch";



const FileBrowser = ({ selectActions = [] }) => {
	const dispatch = useDispatch();


	const handleChangePage = (pageNumber) => {
		dispatch(actions.setSearchPage(pageNumber));
	};


	return (
		<div>
			<FileBrowserSearch />
			<FileGrid selectActions={selectActions} />
			<PaginationBar onChangePage={handleChangePage} />
		</div>

	);
};


export default FileBrowser;
