import makeStyles from '@mui/styles/makeStyles';


// TODO: Figure out why this makes the canvas blurry
const useStyles = makeStyles(theme => ({
	buccalContainer: {
		//width: '100%',
		//height: '100%',
		//minHeight: '100%',
		//display: 'table',
		//maxHeight: 'calc(100vh - 200px)',
	},
}));



export default useStyles;
